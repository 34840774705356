var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.importType === "badi"
                        ? _vm.$t("import_badi")
                        : _vm.importType === "survey"
                        ? _vm.$t("import_survey")
                        : _vm.$t("import_quiz")
                    ) +
                    " "
                ),
              ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c("h6", [_vm._v(_vm._s(_vm.$t("upload")))]),
                _c(
                  "vue-dropzone",
                  {
                    ref: "importExcel",
                    attrs: {
                      id: "dropzone",
                      options: _vm.importExcelOptions,
                      "use-custom-slot": true,
                    },
                    on: {
                      "vdropzone-sending": _vm.importSQfromExcelSend,
                      "vdropzone-error": _vm.dropFailed,
                      "vdropzone-success": _vm.importSQEnded,
                    },
                  },
                  [
                    _c("div", { staticClass: "dropzone-custom-content" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "34",
                            height: "36",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                fill: "#727D92",
                                "fill-rule": "nonzero",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M15.5 28l.3.3h.1l.2.1h.1l.2.1a1.6 1.6 0 0 0 .6 0H17.3l.2-.1.1-.1.3-.2 9.5-9.5a1.7 1.7 0 1 0-2.3-2.4L18.4 23V1.7a1.7 1.7 0 1 0-3.3 0v21.2l-6.8-6.7A1.7 1.7 0 1 0 6 18.6l9.5 9.5z",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M31.7 21.6c-1 0-1.7.7-1.7 1.6v7.3c0 1-.8 2-1.9 2H5.3c-1 0-2-1-2-2v-7.3a1.7 1.7 0 1 0-3.3 0v7.3c0 2.9 2.4 5.3 5.3 5.3H28c3 0 5.2-2.4 5.2-5.3v-7.3c0-.9-.7-1.6-1.6-1.6",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _c("h5", { staticClass: "dropzone-custom-title" }, [
                        _vm._v(" " + _vm._s(_vm.$t("drag_drop_msg")) + " "),
                      ]),
                    ]),
                  ]
                ),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.importType === "badi"
                        ? _vm.$t("import_badi_sub_msg")
                        : _vm.importType === "survey"
                        ? _vm.$t("import_survey_sub_msg")
                        : _vm.$t("import_quiz_sub_msg")
                    ),
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }