<template>
  <div class="page">
    <app-header
      child-header-class="container"
      is-child-header-left-actions
      :child-header-navigation="userSubNavigation"
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
      <dropdown
        class-name="filter"
        v-if="isVibonsAdmin"
        :filter-on="the_filter.only_active_customer_users"
      >
        <template slot="icon">
          <i
            class="fa fa-filter button button--shadow button--type-icon size-sm d-flex align-items-center"
            style="cursor: pointer;color: rgb(114, 125, 146);"
          />
        </template>
        <template slot="body">
          <div class="d-block p-2">
            <div
              class="d-block"
            >
              <label
                for="filter-only_active_customer_users"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  id="filter-only_active_customer_users"
                  :true-value="true"
                  :false-value="false"
                  v-model="the_filter.only_active_customer_users"
                >
                {{ $t('only_active_customer_users') }}
              </label>
            </div>
          </div>
        </template>
      </dropdown>
      <template v-slot:right>
        <button
          class="button button--secondary text-size-xs size-sm button--icon"
          @click="addCommunicationModal()"
        >
          <icon-container
            name="plusfull"
            view-box="0 0 448 512"
            :width="15"
            :height="16"
            color="#FFF"
            :is-icon-class="true"
          >
            <icon-plusfull />
          </icon-container>
          {{ $t('add_new_communication') }}
        </button>
      </template>
    </app-header>

    <div class="container">
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="post"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="customer_name_slot"
            slot-scope="props"
          >
            <div v-if="isVibonsAdmin">
              {{ getCustomerName(props.rowData.as_customer_id) }}
            </div>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <button
              class="ui button edit"
              @click="onAction('edit-item', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                name="edit"
                color="#727d92"
              >
                <icon-edit />
              </icon-container>
            </button>
            <button
              class="ui button delete"
              @click="onAction('delete-item', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                name="trash"
                color="#727d92"
              >
                <icon-trash />
              </icon-container>
            </button>
            <div
              class="dropdown"
              @mouseover="dropdownAction(`three${props.rowIndex}`)"
              @mouseleave="hideDropdownAction"
            >
              <span class="card--actions dropdown__toggle">
                <icon-container
                  :width="20"
                  :height="20"
                  view-box="0 0 24 24"
                  name="threedots"
                  color="#727d92"
                >
                  <icon-threedots />
                </icon-container>
              </span>

              <div
                class="dropdown__menu"
                :ref="`three${props.rowIndex}`"
                v-if="dropdownIndex === 'three' + props.rowIndex"
                v-on-clickaway="hideDropdownAction"
              >
                <span
                  class="dropdown__button button"
                  @click="onAction('show-users', props.rowData)"
                >
                  <svg-icon
                    class="ml-n1"
                    type="mdi"
                    width="21"
                    color="#727d92"
                    height="23"
                    :path="getImgUrl('mdiYoutubeTv')"
                  />
                  {{ $t('user_list') }}
                </span>
                <span
                  class="dropdown__button button"
                  @click="onAction('download-report', props.rowData)"
                >
                  <icon-container
                    width="13"
                    height="14"
                    name="Download Communication Report"
                    color="#727d92"
                  >
                    <icon-download />
                  </icon-container>
                  {{ $t('download_report') }}
                </span>
              </div>
            </div>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { mapState } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import IconPlusFull from '@/components/icons/PlusFull.vue';
import IconThreedots from '@/components/icons/Threedots.vue';
import IconEdit from '@/components/icons/Edit.vue';
import { directive as onClickaway } from 'vue-clickaway';
import IconTrash from '@/components/icons/Trash.vue';
import AppHeader from '@/components/AppHeader.vue';
import { API_COMMUNICATION } from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import Dropdown from '@/components/elements/Dropdown.vue';
import IconDownload from '@/components/icons/Download.vue';
import SvgIcon from '@jamescoyle/vue-icon';
import {
  mdiAccountGroup,
} from '@mdi/js';
import VuetablePagination from '../components/CustomVuetable/VuetablePagination.vue';

console.log('Customers');

export default {
  name: 'Customers',
  mixins: [myTableMixin],
  data() {
    return {
      the_filter: {
        only_active_customer_users: false,
      },
    };
  },

  directives: {
    'on-clickaway': onClickaway,
  },
  components: {
    'icon-container': IconContainer,
    'icon-plusfull': IconPlusFull,
    'icon-edit': IconEdit,
    'icon-trash': IconTrash,
    'app-header': AppHeader,
    'icon-threedots': IconThreedots,
    'vue-table': Vuetable,
    'icon-download': IconDownload,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
    Dropdown,
    SvgIcon,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'Communication') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
    the_filter: {
      handler(filter) {
        if (filter.only_active_customer_users) {
          this.$set(this.moreParams, 'customer_id', this.$store.state.auth.customerId);
        } else {
          this.$delete(this.moreParams, 'customer_id');
        }
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),
    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },
    customerListFromCache() {
      return this.$store.state.auth.customerList;
    },
    apiUrl() {
      if (API_COMMUNICATION) {
        return `${API_COMMUNICATION}/list`;
      }
      return null;
    },

    refreshPage: {
      get() {
        return this.$store.state.modal.refreshPage;
      },
      set(newValue) {
        this.$store.state.modal.refreshPage = newValue;
      },
    },

    pageToRefresh: {
      get() {
        return this.$store.state.modal.pageToRefresh;
      },

      set(newValue) {
        this.$store.state.modal.pageToRefresh = newValue;
      },
    },
    userSubNavigation() {
      return [

      ];
    },
    fields() {
      if (this.isVibonsAdmin) {
        return [{
          name: 'title',
          title: this.$t('title'),
          sortField: 'title',
          width: '35%',
        },
        {
          name: 'customer_name_slot',
          title: this.$t('customer_name'),
          width: '25%',
        },
        {
          name: 'recipient_count',
          title: this.$t('recipient_count'),
          width: '15%',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '25%',
          titleClass: 'center',
          dataClass: 'center',
        }];
      }
      return [{
        name: 'title',
        title: this.$t('title'),
        sortField: 'title',
        width: '50%',
      },
      {
        name: 'recipient_count',
        title: this.$t('recipient_count'),
        width: '25%',
      },
      {
        name: 'actions',
        title: this.$t('actions'),
        width: '25%',
        titleClass: 'center',
        dataClass: 'center',
      }];
    },
  },

  methods: {
    getCustomerName(customerId) {
      if (this.customerListFromCache) {
        const customer = this.customerListFromCache.find(c => c.id === customerId);
        if (customer && customer.name) {
          return customer.name;
        }
      }

      return '';
    },
    getImgUrl() {
      return mdiAccountGroup;
    },
    addCommunicationModal() {
      const obj = {
        modalType: 'modalAddCommunication',
        pageToRefresh: 'Communication',
      };
      this.$helpers.toggleModal(obj);
    },
    onAction(action, data) {
      const communicationIdToEdit = data.id;
      const customerIdToEdit = data.as_customer_id;
      this.idHolder = communicationIdToEdit;
      if (!communicationIdToEdit) {
        return;
      }
      if (action === 'delete-item' && !this.snotifyQueueList.includes(communicationIdToEdit)) {
        this.snotifyQueueList.push(communicationIdToEdit);
        this.$snotify.confirm(this.$t('communication_delete_msg_body', { name: data.title }), this.$t('communication_delete_msg_title'), {
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          buttons: [
            {
              text: this.$t('yes'),
              action: (toast) => {
                this.$store.dispatch('modal/DELETE_COMMUNICATION', { id: communicationIdToEdit, customer_id: data.as_customer_id }).then(() => {
                  this.$refs.myvuetable.refresh();
                });
                this.$snotify.remove(toast.id);
              },
              bold: false,
            },
            {
              text: this.$t('no'),
              action: (toast) => {
                this.deleteCancel(toast, this.idHolder);
              },
            },
          ],
        }).on('shown', (toast) => {
          this.toastHolder = toast;
          document.addEventListener('keydown', this.eventHandler, false);
        }).on('hidden', (toast) => {
          this.toastHolder = toast;
          document.removeEventListener('keydown', this.eventHandler, false);
        });
      } else if (action === 'edit-item') {
        const obj = {
          modalType: 'modalAddCommunication',
          communicationIdToEdit,
          customerIdToEdit,
          tabPane: 'mainTab',
          editMode: true,
        };
        this.$helpers.toggleModal(obj);
      } else if (action === 'download-report') {
        const obj = {
          modalType: 'modalReportDownload',
          reportDownloadUrl: `${API_COMMUNICATION}/report`,
          dateOnly: false,
          omitDate: true,
          reportModalTitle: this.$t('communication_report_download'),
          reportDownloadRequest: 'post',
          reportDownloadPayload: {
            id: communicationIdToEdit,
          },
        };
        this.$helpers.toggleModal(obj);
      } else if (action === 'show-users') {
        const obj = {
          modalType: 'modalCommunicaitonItemUsers',
          pageToRefresh: 'Communication',
          communicationTitle: data.title,
          communicationIdToEdit,
          customerIdToEdit: data.as_customer_id,
          launchedFrom: 'communication',
          tabPane: 'mainTab',
        };
        this.$helpers.toggleModal(obj);
      }
    },
  },
};
</script>
