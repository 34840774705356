<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.2"
    :class="isIconClass ? 'icon' : ''"
    :style="rotate ? `transform: rotate(${rotate}deg);margin: 4px 0 0 -10px;`:''"
    role="presentation"
    preserveAspectRatio="xMidYMid meet"
    :color="color"
    :width="width"
    :height="height"
    :viewBox="myviewBox"
    :aria-labelledby="name"
    data-drop-effect="none"
  >
    <slot />
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },

    width: {
      type: [Number, String],
      default: 15,
    },

    height: {
      type: [Number, String],
      default: 15,
    },

    isIconClass: {
      type: Boolean,
      default: true,
    },

    color: {
      type: String,
      default: 'currentColor',
    },

    viewBox: {
      type: [Number, String],
      default: '',
    },
    preserveAspectRatio: {
      type: String,
      default: 'none',
    },
    rotate: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    myviewBox() {
      if (this.viewBox) {
        return this.viewBox;
      }
      return `0 0 ${this.width} ${this.height}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  display: inline-flex;
  position: relative;
  fill: currentColor;

  &--size-small {
    width: $sizeSmall;
    height: $sizeSmall;
  }

  &.icon--radius {
    box-shadow: 0 2px 4px 0 rgba(114, 125, 146, 0.2);
    background-color: $white;
    border-radius: 50%;
  }

  &.icon--radius-border {
    border: 1px solid #e3e5e9;
    background-color: #fff;
    border-radius: 50%;
  }
}
</style>
