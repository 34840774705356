var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page page--fullscreen p-login" }, [
    _c("div", { staticClass: "c-login" }, [
      _c("div", { staticClass: "c-login__title" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/journey-logo-blue.svg"),
            alt: "Journey",
          },
        }),
        _c("h2", [_vm._v(_vm._s(_vm.$t("login_title")))]),
      ]),
      _c(
        "div",
        { staticClass: "card", class: { "is-loading": _vm.isLoading } },
        [
          _c(
            "form",
            {
              staticClass: "card__body",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.validateBeforeSubmit.apply(null, arguments)
                },
              },
            },
            [
              _vm.authStatus === "error"
                ? _c("div", { staticClass: "form-group" }, [
                    _c("p", { staticClass: "error" }, [
                      _vm._v(" " + _vm._s(_vm.$t("login_error_msg")) + " "),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "form-group input",
                  class: { "is-danger": _vm.errors.has("email") },
                },
                [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "" } },
                    [_vm._v(_vm._s(_vm.$t("email_address")))]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|email",
                        expression: "'required|email'",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      placeholder: _vm.$t("email_placeholder"),
                      autocomplete: "email",
                      name: "email",
                    },
                    domProps: { value: _vm.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.email = $event.target.value
                      },
                    },
                  }),
                  _vm.errors.has("email")
                    ? _c("span", { staticClass: "help is-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("email"))),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "form-group input",
                  class: {
                    "is-danger": _vm.errors.has("password"),
                    "is-closed": _vm.forgotPassword,
                  },
                },
                [
                  _c(
                    "label",
                    { staticClass: "form-label", attrs: { for: "" } },
                    [_vm._v(_vm._s(_vm.$t("password")))]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    ref: "passwordRef",
                    staticClass: "form-control",
                    attrs: {
                      type: "password",
                      placeholder: _vm.$t("password_placehodler"),
                      autocomplete: "password",
                      name: "password",
                    },
                    domProps: { value: _vm.password },
                    on: {
                      focus: _vm.showBadge,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.password = $event.target.value
                      },
                    },
                  }),
                  _vm.errors.has("password")
                    ? _c("span", { staticClass: "help is-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("password"))),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                { staticClass: "form-group d-flex justify-content-center" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "button button--primary size-lg",
                      class: { "is-forgot-password": _vm.forgotPassword },
                      attrs: { type: "submit" },
                    },
                    [
                      _c("div", { staticClass: "text-container" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("sign_in")))]),
                        _c("span", [_vm._v(_vm._s(_vm.$t("reset_password")))]),
                      ]),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "form-group text-center" }, [
                _vm.forgotPassword == null
                  ? _c(
                      "a",
                      {
                        staticClass: "forgot-password",
                        attrs: { href: "#" },
                        on: { click: _vm.forgotPass },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("forgot_password")))])]
                    )
                  : _vm._e(),
                _vm.forgotPassword
                  ? _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            _vm.forgotPassword = null
                          },
                        },
                      },
                      [
                        _c(
                          "icon-container",
                          {
                            attrs: {
                              width: 40,
                              height: 18,
                              name: "Go Back",
                              color: "#727d92",
                            },
                          },
                          [_c("icon-goback")],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }