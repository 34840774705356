const subtypeList = {
  0: 'UNKNOWN',
  1: 'INFOGRAPHIC',
  10: 'QUIZ',
  11: 'SURVEY',
  12: 'CHECKLIST',
  2: 'EXTERNAL_VIDEO',
  3: 'INTERNAL_VIDEO',
  36: 'LINK',
  37: 'PODCAST',
  4: 'EXTERNAL_ARTICLE',
  41: 'REQUEST',
  42: 'CHAPTER',
  43: 'FILE_DOWNLOAD',
  44: 'SCORM',
  45: 'EXTERNAL_CDN_VIDEO',
  5: 'INTERNAL_ARTICLE',
  6: 'QUOTES',
  7: 'BOOK_SUGGESTION',
  9: 'FLIPBOOK',
};
export default subtypeList;
