var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "currentColor" } }, [
    _c("path", {
      attrs: {
        d: "M215.037 36.846c-49.129-49.128-129.063-49.128-178.191 0-49.127 49.127-49.127 129.063 0 178.19 24.564 24.564 56.83 36.846 89.096 36.846s64.531-12.282 89.096-36.846c49.126-49.127 49.126-129.063-.001-178.19zM49.574 202.309C7.465 160.2 7.465 91.683 49.574 49.574c21.055-21.054 48.711-31.582 76.367-31.582s55.313 10.527 76.367 31.582c42.109 42.109 42.109 110.626 0 152.735-42.109 42.108-110.625 42.108-152.734 0z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M194.823 116.941H57.059c-4.971 0-9 4.029-9 9s4.029 9 9 9h137.764c4.971 0 9-4.029 9-9s-4.029-9-9-9z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }