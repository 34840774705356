var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(" " + _vm._s(_vm.$t("set_new_password")) + " "),
              ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "contentPassword" },
                      },
                      [_vm._v(_vm._s(_vm.$t("new_password")))]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|min:6",
                          expression: "'required|min:6'",
                        },
                      ],
                      ref: "password",
                      staticClass: "form-control",
                      class: {
                        "is-danger": _vm.errors.has("newpassword.password"),
                      },
                      attrs: {
                        type: "text",
                        "data-vv-name": "password",
                        "data-vv-as": _vm.$t("new_password"),
                        "data-vv-scope": "newpassword",
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                    _vm.errors.has("newpassword.password")
                      ? _c("span", { staticClass: "help is-danger" }, [
                          _vm._v(
                            _vm._s(_vm.errors.first("newpassword.password"))
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "contentPasswordAgain" },
                      },
                      [_vm._v(_vm._s(_vm.$t("new_password_again")))]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.passwordagain,
                          expression: "passwordagain",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|min:6|confirmed:password",
                          expression: "'required|min:6|confirmed:password'",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-danger": _vm.errors.has(
                          "newpassword.passwordagain"
                        ),
                      },
                      attrs: {
                        type: "text",
                        "data-vv-name": "passwordagain",
                        "data-vv-as": _vm.$t("new_password_again"),
                        "data-vv-scope": "newpassword",
                      },
                      domProps: { value: _vm.passwordagain },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.passwordagain = $event.target.value
                        },
                      },
                    }),
                    _vm.errors.has("newpassword.passwordagain")
                      ? _c("span", { staticClass: "help is-danger" }, [
                          _vm._v(
                            _vm._s(
                              _vm.errors.first("newpassword.passwordagain")
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.items.length > 0,
                      expression: "errors.items.length > 0",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c("div", { staticClass: "alert alert--card alert--error" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
                    _c(
                      "ul",
                      _vm._l(_vm.errors, function (error, index) {
                        return _c("li", { key: index }, [
                          _vm._v(" " + _vm._s(error.msg) + " "),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "button button--primary",
                    attrs: { type: "button", disabled: _vm.isPending },
                    on: {
                      click: function ($event) {
                        return _vm.setPassword()
                      },
                    },
                  },
                  [
                    !_vm.isPending
                      ? _c("span", [_vm._v(" " + _vm._s(_vm.$t("set")) + " ")])
                      : _vm._e(),
                    _vm.isPending
                      ? _c("div", { staticClass: "lds-ellipsis" }, [
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                        ])
                      : _vm._e(),
                  ]
                ),
                _c("div", { staticClass: "sentmail" }, [
                  _vm._v(" " + _vm._s(_vm.$t("or")) + " "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.sentEmail()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("send_reset_password")))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }