var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "currentColor" } }, [
    _c("path", {
      attrs: {
        d: "M19.1 14l-2.1.7v-2.3c0-.6-.4-1-1-1s-1 .4-1 1v2.3l-2.1-.7c-.5-.2-1.1.1-1.3.6-.2.5.1 1.1.6 1.3l2.1.7-1.3 1.8c-.3.4-.2 1.1.2 1.4.2.1.4.2.6.2.3 0 .6-.1.8-.4l1.3-1.8 1.3 1.8c.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-.9.2-1.4l-1.3-1.8 2.1-.7c.5-.2.8-.7.6-1.3 0-.5-.5-.8-1.1-.6zM8.1 14l-2.1.7v-2.3c0-.6-.4-1-1-1s-1 .4-1 1v2.3L1.9 14c-.5-.2-1.1.1-1.3.6-.2.5.1 1.1.6 1.3l2.1.7L2 18.4c-.3.4-.2 1.1.2 1.4.3.1.5.2.7.2.3 0 .6-.1.8-.4L5 17.8l1.3 1.8c.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-.9.2-1.4l-1.3-1.8 2.1-.7c.5-.2.8-.7.6-1.3-.1-.5-.6-.8-1.2-.6zM31.4 14.6a1 1 0 00-1.3-.6l-2.1.7v-2.3c0-.6-.4-1-1-1s-1 .4-1 1v2.3l-2.1-.7c-.5-.2-1.1.1-1.3.6-.2.5.1 1.1.6 1.3l2.1.7-1.3 1.8c-.3.4-.2 1.1.2 1.4.2.1.4.2.6.2.3 0 .6-.1.8-.4l1.3-1.8 1.3 1.8c.2.3.5.4.8.4.2 0 .4-.1.6-.2.4-.3.5-.9.2-1.4l-1.3-1.8 2.1-.7c.7-.2 1-.7.8-1.3z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }