<template>
  <div class="flipbox">
    <div class="listing form-label">
      <icon-container
        width="18"
        height="10"
        view-box="0 0 18 12"
        name="threelines"
        color="#727d92"
        :is-icon-class="false"
      >
        <icon-threelines />
      </icon-container> {{ (findex + 1) + $t('of') + totalPages }}
    </div>
    <div
      id="flip"
      class="flips"
    >
      <v-stage
        :config="config"
        ref="stageRef"
      >
        <v-layer>
          <v-rect
            :config="background"
          />
          <v-rect
            :config="contentTextRect"
            ref="contentTextRectRef"
          />
          <v-text
            :config="contentText"
            ref="contextTextRef"
            @click="editText"
          />
          <v-group
            ref="coverImageGroupRef"
          >
            <v-rect
              :config="coverImageRect"
              ref="coverImageRectRef"
            />

            <v-image
              :config="coverImagePlaceholder"
              ref="coverImagePlaceholderRef"
            />
            <v-text :config="coverImagePlaceholderText" />
          </v-group>
          <v-image
            :config="coverImage"
            ref="coverImageRef"
          />
          <v-text
            :config="coverSubImagePlaceholderText"
            ref="coverSubImagePlaceholderTextRef"
          />
          <v-image
            :config="coverSubImage"
          />
        </v-layer>
      </v-stage>
      <textarea
        tabindex="-1"
        ref="flipBodyTextRef"
        v-model="bodyText"
        maxlength="50"
        rows="3"
        @blur="textBlur = true"
        v-on-clickaway="textBlur ? hideMe : null"
        class="edittitle"
      />
      <div
        ref="counterRef"
        class="letter-counter"
      >
        {{ letterCount }}
      </div>
      <div
        class="drop display-inline align-center"
        @dragover.prevent
        @drop="onDrop"
      >
        <input
          tabindex="-1"
          type="file"
          name="image"
          @change="onUpload"
        >
      </div>
      <div
        v-show="loading"
        class="vloader"
      >
        <svg
          version="1.1"
          id="loader-1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 40 40"
          enable-background="new 0 0 40 40"
          xml:space="preserve"
        >
          <path
            opacity="0.2"
            fill="#000"
            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
            s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
            c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
          />
          <path
            fill="#000"
            d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
            C22.32,8.481,24.301,9.057,26.013,10.047z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 20 20"
              to="360 20 20"
              dur="0.5s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
    </div>
    <div
      class="progressing"
      v-if="isFileUploading && !getUploadStatus(findex)"
    >
      <progress-bar
        class="progressbarline"
        bar-color="#4a4"
        v-if="getUploadProgress(findex) < 101 && !getUploadStatus(findex)"
        size="small"
        :font-size="10"
        :val="getUploadProgress(findex)"
        text-position="middle"
        text-fg-color="#4558FB"
        text-align="center"
        :text="$t('rate_info', { rate: getUploadProgress(findex) })"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProgressBar from 'vue-simple-progress';
import IconContainer from '@/components/elements/Icon.vue';
import IconThreeLines from '@/components/icons/Threelines.vue';
import onClickaway from '@/helpers/directives/clickaway';
import flipbookMixin from '../../helpers/flipbookMixin.vue';

export default {
  name: 'CoverFlip',
  mixins: [flipbookMixin],
  data() {
    return {
      textBlur: false,
      config: {
        width: 288,
        height: 512,
        pixelRatio: 3.75,
      },
      background: {
        x: 0,
        y: 0,
        width: 288,
        height: 512,
        cornerRadius: 6,
        fillLinearGradientStartPoint: { x: 0, y: 0 },
        fillLinearGradientEndPoint: { x: 0, y: 512 },
        fillLinearGradientColorStops: [0, '#272F70', 0.63, '#1273BA'],
      },
      contentTextRect: {
        x: 26,
        y: 36,
        width: 234,
        height: 97,
        opacity: 1,
        cornerRadius: 6,
        stroke: 'rgba(255, 255, 255, 0.5)',
        strokeWidth: 1,
        dash: [5, 5],
        dashEnabled: true,
      },

      contentText: {
        x: 26,
        y: 36,
        width: 234,
        height: 110,
        fontSize: 22,
        opacity: 0.5,
        fontFamily: 'Circular',
        ellipsis: false,
        wrap: 'word',
        fill: '#fff',
        text: this.bodyText,
        align: 'center',
        verticalAlign: 'middle',
      },

      coverImage: {
        x: 26,
        y: 148,
        width: 234,
        height: 144,
        image: null,
        visible: false,
        scaleX: 1,
        scaleY: 1,
      },

      coverImageRect: {
        x: 26,
        y: 148,
        width: 234,
        height: 144,
        cornerRadius: 6,
        stroke: 'rgba(255, 255, 255, 0.5)',
        strokeWidth: 1,
        dash: [5, 5],
        dashEnabled: true,
      },

      coverImagePlaceholder: {
        x: 131,
        y: 200,
        image: null,
        width: 27,
        height: 27,
      },
      coverImagePlaceholderText: {
        x: 26,
        y: 240,
        width: 234,
        fontSize: 10,
        opacity: 0.5,
        fill: '#fff',
        fontFamily: 'Circular',
        text: this.$t('drag_or_upload'),
        align: 'center',
      },
      coverSubImage: {
        x: 0,
        y: 380,
        image: null,
      },
      coverSubImagePlaceholderText: {
        width: 234,
        fontSize: 12,
        height: this.userLang === 'en' ? 20 : 40,
        opacity: 0.5,
        fill: '#fff',
        fontFamily: 'Circular',
        align: 'center',
        text: this.$t('swipe_up_down'),
        x: 26,
        y: this.userLang === 'en' ? 365 : 355,
      },
    };
  },

  watch: {
    textBlur: {
      handler(val) {
        if (val) {
          this.validateText();
        }
      },
      immediate: true,
    },
  },

  computed: {
    letterCount() {
      return (50 - this.bodyText.length);
    },
    totalPages() {
      return this.total;
    },
    uploadingFiles() {
      return this.$store.state.modal.uploadFileStatus;
    },
    ...mapGetters({
      getUploadStatus: 'modal/getFileUploadStatus',
      isFileUploading: 'modal/isFileUploading',
      getUploadProgress: 'modal/getFileUploadProgress',
      flipsData: 'modal/getFlipsData',
    }),
  },

  components: {
    'icon-container': IconContainer,
    'icon-threelines': IconThreeLines,
    ProgressBar,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  mounted() {
    setTimeout(() => {
      this.coverSubImagePlaceholderText.y = this.userLang === 'en' ? 365 : 355;
      this.$refs.stageRef.getStage().draw();
    }, 1000);
    this.launched();
  },

  methods: {
    async launched() {
      const vm = this;
      console.log('page:', vm.findex, 'Image src:', vm.flipsData[vm.findex].datam.image?.src);
      this.bodyText = this.bodyText ? this.bodyText : this.$t('add_title_here');
      if (vm.flipsData[vm.findex].datam.image && vm.flipsData[vm.findex].datam.image.src) {
        vm.loading = true;
        const newImage = new Image();
        newImage.crossOrigin = 'Anonymous';
        newImage.onload = () => {
          vm.coverImage.image = newImage;
          vm.updateCoverImage();
          if (vm.$refs.stageRef) vm.$refs.stageRef.getStage().draw();
          vm.loading = false;
        };
        // newImage.onerror = (e) => {
        //   console.log('eeee4', e);
        //   newImage.src = 'https://s3-spaced-learning-prod.s3.amazonaws.com/assets/flipbook_page_image_place_holder.png';
        // };
        newImage.src = vm.flipsData[vm.findex].datam.image.src;
      } else {
        const newImage = new Image();
        newImage.crossOrigin = 'Anonymous';
        newImage.onload = () => {
          vm.coverImagePlaceholder.image = newImage;
        };
        // newImage.onerror = (e) => {
        //   console.log('eeee5', e);
        //   newImage.src = 'https://s3-spaced-learning-prod.s3.amazonaws.com/assets/flipbook_page_image_place_holder.png';
        // };
        // eslint-disable-next-line global-require
        newImage.src = require('@/assets/img/bg-image.png');
      }

      const newImage2 = new Image();
      newImage2.crossOrigin = 'Anonymous';
      newImage2.onload = () => {
        vm.coverSubImage.image = newImage2;
        vm.updateCoverImage(); // needs for after swap sorting
      };
      // newImage2.onerror = (e) => {
      //   console.log('eeee6', e);
      //   newImage2.src = 'https://s3-spaced-learning-prod.s3.amazonaws.com/assets/flipbook_page_image_place_holder.png';
      // };
      // eslint-disable-next-line global-require
      const e = require('@/assets/img/finger.svg');
      newImage2.src = await vm.normalizeSVG(e, { width: 288, height: 128 });
    },

    handleMouseEnter(e) {
      const stage = e.target.getStage();
      stage.container().style.cursor = this.cursor;
    },

    handleMouseLeave(e) {
      const stage = e.target.getStage();
      stage.container().style.cursor = 'default';
    },
  },
};
</script>

<style lang="scss" scoped>
.flipbox {
  margin-top: 20px;
  .listing {
    margin-left: 10px;
    display: none;
    align-items: center;
    line-height: 15px!important;
    svg {
      margin-right: 5px;
    }
  }
  &:hover {
     margin-top: 0;
    .listing {
      display: flex;
    }
  }
}
#flip {
  position: relative;
}

::v-deep .edittitle {
  display: none;
  line-height: 1;
  position: absolute;
  top: 36px;
  text-align: center;
  left: 26px;
  font-size: 22px;
  font-family: 'Circular';
  resize: none;
  letter-spacing: -0.4px;
  color: rgba(255, 255,255, 0.5);
  width: 234px;
  height: 97px;
  background: transparent;
  border: 2px dashed rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  outline: none;
  cursor:text;
}
.letter-counter {
  display: none;
  position:absolute;
  top: 110px;
  left: 240px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5)
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 234px;
  height: 144px;
  left: 0;
  top: 0;
  border-radius: 6px;
  cursor: pointer;
}

.align-center {
  text-align: center;
}

.hidden {
  display: none !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.drop {
  background-color: transparent;
  height: 144px;
  width: 234px;
  position: absolute;
  left:26px;
  top: 148px;
  z-index: 10202;
}
.button-handle {
  cursor: move;
}
.progressing{
  display: relative;
  top: 50%;
  justify-content: center;
}
.progressbarline {
    align-self: center;
    display: block;
    position: relative;
    z-index: 100000;
    width: inherit;
    padding: 10px;
    text-align: center;
}
.vue-simple-progress,
.vue-simple-progress-bar {
  min-width: 150px;
  border-radius: 15px !important;
  height: 12;
}
.vloader {
  display: flex;
  left: calc(50% - 15px);
  position: absolute;
  top: calc(50% - 15px);
  z-index: 10;
  svg path,
  svg rect{
  fill: #f27299;
  }
}
</style>
