<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ journeyName + '-' + $t('user_list') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>

          <div class="modal-body">
            <div class="form-group">
              <journey-users
                is-sub-component-mode
                :journey-customer-id="journeyCustomerId"
                @closeModal="closeModal"
              />
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="button button--primary"
                @click="closeModal"
              >
                <span>
                  {{ $t('close') }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import JourneyUsersTemplate from '@/container/JourneyUsers.vue';

export default {
  name: 'ModalJourneyItemUsers',

  data() {
    return {

    };
  },

  watch: {

  },

  components: {
    'journey-users': JourneyUsersTemplate,
  },

  computed: {
    ...mapState([
      'modal',
    ]),
    journeyName: {
      get() {
        return this.$store.state.modal.journeyName;
      },
      set(newValue) {
        this.$store.state.modal.journeyName = newValue;
      },
    },
    journeyCustomerId: {
      get() {
        return this.$store.state.modal.journeyCustomerId;
      },
      set(val) {
        this.$store.state.modal.journeyCustomerId = val;
      },
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/toggleModal');
      this.$store.commit('modal/REFRESH_PAGE', true);
    },
  },
};
</script>
<style scoped>
.modal .modal-body {
    padding: 15px 0px 30px;
    margin: 0 20px;
    min-width: 750px;
}
</style>
