var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.$t("edit_group")
                        : _vm.$t("add_new_group")
                    ) +
                    " "
                ),
              ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.pageOptions === "edit-name" ||
                        _vm.pageOptions === "new-group",
                      expression:
                        "pageOptions === 'edit-name' || pageOptions === 'new-group'",
                    },
                  ],
                  staticClass: "form-group",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      attrs: { for: "contentTitle" },
                    },
                    [_vm._v(_vm._s(_vm.$t("group_name_uppercase")))]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.groupName,
                        expression: "groupName",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "form-control",
                    class: {
                      "is-danger": _vm.errors.has("groupadd.groupname"),
                    },
                    attrs: {
                      type: "text",
                      id: "groupName",
                      name: "Group Name",
                      "data-vv-name": "groupname",
                      "data-vv-scope": "groupadd",
                      placeholder: _vm.$t("group_placeholder"),
                    },
                    domProps: { value: _vm.groupName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.groupName = $event.target.value
                      },
                    },
                  }),
                  _vm.errors.has("groupadd.groupname")
                    ? _c("span", { staticClass: "help is-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("groupadd.groupname"))),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm.pageOptions === "new-group"
                ? _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "contentTitle" },
                            },
                            [_vm._v(_vm._s(_vm.$t("group_users")))]
                          ),
                          _c("vue-tags-input", {
                            attrs: {
                              tags: _vm.users,
                              "autocomplete-items": _vm.groupUsers,
                              "add-only-from-autocomplete": true,
                              "delete-on-backspace": false,
                              placeholder: _vm.$t("adduser_placeholder"),
                            },
                            on: {
                              "tags-changed": _vm.inputUpdateUsers,
                              "before-deleting-tag": _vm.deleteUsers,
                            },
                            model: {
                              value: _vm.user,
                              callback: function ($$v) {
                                _vm.user = $$v
                              },
                              expression: "user",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "button button--primary",
                    attrs: { type: "button", disabled: _vm.isPending },
                    on: {
                      click: function ($event) {
                        _vm.editMode ? _vm.updateGroup() : _vm.addGroup()
                      },
                    },
                  },
                  [
                    !_vm.isPending
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.editMode
                                  ? _vm.$t("update_button")
                                  : _vm.$t("add")
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.isPending
                      ? _c("div", { staticClass: "lds-ellipsis" }, [
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }