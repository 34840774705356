var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal", class: { "is-loading": _vm.formSending } },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(" " + _vm._s(_vm.$t("setup_ed_demo_title")) + " "),
              ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "tab-content" }, [
                !_vm.codePresent
                  ? _c("div", { staticClass: "tab-pane" }, [
                      _c("div", { staticClass: "action-header" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "demoType" },
                            },
                            [_vm._v(_vm._s(_vm.$t("demo_type")))]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "check-button",
                                  class: {
                                    active: _vm.demoType === "development",
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.demoType,
                                        expression: "demoType",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      value: "development",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.demoType,
                                        "development"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.demoType = "development"
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("development")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "check-button",
                                  class: {
                                    active: _vm.demoType === "onboarding",
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.demoType,
                                        expression: "demoType",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      value: "onboarding",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.demoType,
                                        "onboarding"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.demoType = "onboarding"
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("onboarding")) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "demoLanguage" },
                            },
                            [_vm._v(_vm._s(_vm.$t("demo_language")))]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "check-button",
                                  class: { active: _vm.demoLanguage === "en" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.demoLanguage,
                                        expression: "demoLanguage",
                                      },
                                    ],
                                    attrs: { type: "radio", value: "en" },
                                    domProps: {
                                      checked: _vm._q(_vm.demoLanguage, "en"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.demoLanguage = "en"
                                      },
                                    },
                                  }),
                                  _vm._v(" " + _vm._s(_vm.$t("lang_en")) + " "),
                                ]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "check-button",
                                  class: { active: _vm.demoLanguage === "tr" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.demoLanguage,
                                        expression: "demoLanguage",
                                      },
                                    ],
                                    attrs: { type: "radio", value: "tr" },
                                    domProps: {
                                      checked: _vm._q(_vm.demoLanguage, "tr"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.demoLanguage = "tr"
                                      },
                                    },
                                  }),
                                  _vm._v(" " + _vm._s(_vm.$t("lang_tr")) + " "),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "managerName" },
                          },
                          [_vm._v(_vm._s(_vm.$t("manager_name_")))]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.managerName,
                              expression: "managerName",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|min:2|max:300",
                              expression: "'required|min:2|max:300'",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-danger": _vm.errors.has("setup.managername"),
                          },
                          attrs: {
                            type: "text",
                            id: "managerName",
                            "data-vv-scope": "setup",
                            "data-vv-name": "managername",
                            "data-vv-as": _vm.$t("manager_name"),
                          },
                          domProps: { value: _vm.managerName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.managerName = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("setup.managername"),
                                expression: "errors.has('setup.managername')",
                              },
                            ],
                            staticClass: "help is-danger",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.errors.first("setup.managername"))
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "employeeName" },
                          },
                          [_vm._v(_vm._s(_vm.$t("employee_name_")))]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.employeeName,
                              expression: "employeeName",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|min:2|max:300",
                              expression: "'required|min:2|max:300'",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-danger": _vm.errors.has("setup.employeename"),
                          },
                          attrs: {
                            type: "text",
                            id: "employeeName",
                            "data-vv-scope": "setup",
                            "data-vv-name": "employeename",
                            "data-vv-as": _vm.$t("employee_name"),
                          },
                          domProps: { value: _vm.employeeName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.employeeName = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("setup.employeename"),
                                expression: "errors.has('setup.employeename')",
                              },
                            ],
                            staticClass: "help is-danger",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.errors.first("setup.employeename"))
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.items.length > 0,
                              expression: "errors.items.length > 0",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "alert alert--card alert--error" },
                            [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("error_msg_title"))),
                              ]),
                              _c(
                                "ul",
                                _vm._l(_vm.errors, function (error, index) {
                                  return _c("li", { key: index }, [
                                    _vm._v(" " + _vm._s(error.msg) + " "),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary no-wrap",
                            attrs: { type: "button", disabled: _vm.isPending },
                            on: {
                              click: function ($event) {
                                return _vm.setupEdDemo()
                              },
                            },
                          },
                          [
                            !_vm.isPending
                              ? _c("span", [
                                  _vm._v(" " + _vm._s(_vm.$t("create")) + " "),
                                ])
                              : _vm._e(),
                            _vm.isPending
                              ? _c("div", { staticClass: "lds-ellipsis" }, [
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.codePresent
                  ? _c("div", { staticClass: "tab-pane" }, [
                      _c("div", { staticClass: "container" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _c("div", { staticClass: "form-group codex" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "managerLoginCode" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("manager_login_code")) + " "
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "pin-numbers" }, [
                                _c("span", { staticClass: "pinbox" }, [
                                  _vm._v(_vm._s(_vm.managerLoginCode[0])),
                                ]),
                                _c("span", { staticClass: "pinbox" }, [
                                  _vm._v(_vm._s(_vm.managerLoginCode[1])),
                                ]),
                                _c("span", { staticClass: "dash" }, [
                                  _vm._v(_vm._s("-")),
                                ]),
                                _c("span", { staticClass: "pinbox" }, [
                                  _vm._v(_vm._s(_vm.managerLoginCode[2])),
                                ]),
                                _c("span", { staticClass: "pinbox" }, [
                                  _vm._v(_vm._s(_vm.managerLoginCode[3])),
                                ]),
                                _vm.managerLoginCode[4]
                                  ? _c("span", { staticClass: "pinbox" }, [
                                      _vm._v(_vm._s(_vm.managerLoginCode[4])),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("div", { staticClass: "modal-footer" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "button button--primary no-wrap",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openWebApp()
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("open_web_app")) + " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-6" }, [
                            _c("div", { staticClass: "form-group codex" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "employeeLoginCode" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("employee_login_code")) + " "
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "pin-numbers" }, [
                                _c("span", { staticClass: "pinbox" }, [
                                  _vm._v(_vm._s(_vm.employeeLoginCode[0])),
                                ]),
                                _c("span", { staticClass: "pinbox" }, [
                                  _vm._v(_vm._s(_vm.employeeLoginCode[1])),
                                ]),
                                _c("span", { staticClass: "dash" }, [
                                  _vm._v(_vm._s("-")),
                                ]),
                                _c("span", { staticClass: "pinbox" }, [
                                  _vm._v(_vm._s(_vm.employeeLoginCode[2])),
                                ]),
                                _c("span", { staticClass: "pinbox" }, [
                                  _vm._v(_vm._s(_vm.employeeLoginCode[3])),
                                ]),
                                _vm.employeeLoginCode[4]
                                  ? _c("span", { staticClass: "pinbox" }, [
                                      _vm._v(_vm._s(_vm.employeeLoginCode[4])),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("div", { staticClass: "modal-footer" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "button button--primary no-wrap",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openReporting()
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("open_reporting")) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }