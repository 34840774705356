var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.$t("edit_user")
                        : _vm.$t("add_new_user")
                    ) +
                    " "
                ),
              ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c("nav", { staticClass: "navigation navigation--secondary" }, [
              _c("div", { staticClass: "container" }, [
                _c(
                  "ul",
                  {
                    staticClass: "navigation__list nav",
                    attrs: { id: "userAddTab", role: "tablist" },
                  },
                  [
                    _c("li", { staticClass: "navigation__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: { active: _vm.tabPane === "mainTab" },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("mainTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("main_tab")) + " ")]
                      ),
                    ]),
                    _c("li", { staticClass: "navigation__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: {
                            active: _vm.tabPane === "advancedSettingsTab",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("advancedSettingsTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("advanced_tab")) + " ")]
                      ),
                    ]),
                    _c("li", { staticClass: "navigation__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: { active: _vm.tabPane === "otherSettingsTab" },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("otherSettingsTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("other_tab")) + " ")]
                      ),
                    ]),
                    _c("li", { staticClass: "navigation__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: { active: _vm.tabPane === "relatedUsersTab" },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("relatedUsersTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("related_users")) + " ")]
                      ),
                    ]),
                    _vm.editMode
                      ? _c("li", { staticClass: "navigation__item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "navigation__link",
                              class: {
                                active: _vm.tabPane === "groupsJourneysTab",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.tabNavigation("groupsJourneysTab")
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("groups_n_journeys")) + " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "tab-content" }, [
                _vm.tabPane === "mainTab"
                  ? _c("div", { staticClass: "tab-pane" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "contentTitle" },
                          },
                          [_vm._v(_vm._s(_vm.$t("name_uppercase")))]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.userName,
                              expression: "userName",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-danger": _vm.errors.has("adduser.name"),
                          },
                          attrs: {
                            type: "text",
                            id: "userName",
                            "data-vv-scope": "adduser",
                            name: "name",
                            placeholder: _vm.$t("name_placeholder"),
                          },
                          domProps: { value: _vm.userName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.userName = $event.target.value
                            },
                          },
                        }),
                        _vm.errors.has("adduser.name")
                          ? _c("span", { staticClass: "help is-danger" }, [
                              _vm._v(_vm._s(_vm.errors.first("adduser.name"))),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "contentTitle" },
                          },
                          [_vm._v(_vm._s(_vm.$t("surname_uppercase")))]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.userSurname,
                              expression: "userSurname",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-danger": _vm.errors.has("adduser.surname"),
                          },
                          attrs: {
                            type: "text",
                            id: "userSurname",
                            name: "surname",
                            "data-vv-scope": "adduser",
                            placeholder: _vm.$t("surname_placeholder"),
                          },
                          domProps: { value: _vm.userSurname },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.userSurname = $event.target.value
                            },
                          },
                        }),
                        _vm.errors.has("adduser.surname")
                          ? _c("span", { staticClass: "help is-danger" }, [
                              _vm._v(
                                _vm._s(_vm.errors.first("adduser.surname"))
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "contentTitle" },
                          },
                          [_vm._v(_vm._s(_vm.$t("email_uppercase")))]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.userEmail,
                              expression: "userEmail",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-danger": _vm.errors.has("adduser.e-mail"),
                          },
                          attrs: {
                            type: "text",
                            id: "userEmail",
                            "data-vv-scope": "adduser",
                            name: "e-mail",
                            placeholder: _vm.$t("email_placeholder"),
                          },
                          domProps: { value: _vm.userEmail },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.userEmail = $event.target.value
                            },
                          },
                        }),
                        _vm.errors.has("adduser.e-mail")
                          ? _c("span", { staticClass: "help is-danger" }, [
                              _vm._v(
                                _vm._s(_vm.errors.first("adduser.e-mail"))
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.items.length > 0,
                              expression: "errors.items.length > 0",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "alert alert--card alert--error" },
                            [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("error_msg_title"))),
                              ]),
                              _c(
                                "ul",
                                _vm._l(_vm.errors, function (error, index) {
                                  return _c("li", { key: index }, [
                                    _vm._v(" " + _vm._s(error.msg) + " "),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: { type: "button", disabled: _vm.isPending },
                            on: {
                              click: function ($event) {
                                _vm.editMode ? _vm.updateUser() : _vm.addUser()
                              },
                            },
                          },
                          [
                            !_vm.isPending
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.editMode
                                          ? _vm.$t("update_button")
                                          : _vm.$t("add")
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            _vm.isPending
                              ? _c("div", { staticClass: "lds-ellipsis" }, [
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.tabPane === "advancedSettingsTab"
                  ? _c("div", { staticClass: "tab-pane" }, [
                      _vm.isVibonsAdmin
                        ? _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-label",
                                      attrs: { for: "customer" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("customer").toUpperCase())
                                      ),
                                    ]
                                  ),
                                  _c("multi-select", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass:
                                      "selectbox selectbox--secondary",
                                    class: {
                                      "is-danger":
                                        _vm.errors.has("adduser.customer"),
                                    },
                                    attrs: {
                                      "track-by": "id",
                                      label: "name",
                                      "clear-on-select": "",
                                      "deselect-label": "",
                                      "select-label": "",
                                      options: _vm.customerOptions,
                                      "data-vv-scope": "adduser",
                                      "data-vv-name": "customer",
                                    },
                                    model: {
                                      value: _vm.selectCustomer,
                                      callback: function ($$v) {
                                        _vm.selectCustomer = $$v
                                      },
                                      expression: "selectCustomer",
                                    },
                                  }),
                                  _vm.errors.has("adduser.customer")
                                    ? _c(
                                        "span",
                                        { staticClass: "help is-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                "adduser.customer"
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "manager" },
                            },
                            [_vm._v(_vm._s(_vm.$t("manager")))]
                          ),
                          _c("vue-tags-input", {
                            staticClass: "add-manager",
                            attrs: {
                              tags: _vm.managers,
                              "max-tags": 1,
                              "autocomplete-items": _vm.managersToAdd,
                              "add-only-from-autocomplete": true,
                              "delete-on-backspace": false,
                              placeholder: _vm.managerSelected
                                ? ""
                                : _vm.$t("addmanager_placeholder"),
                            },
                            on: {
                              "tags-changed": _vm.inputUpdateManagers,
                              "before-adding-tag": _vm.managerTagAdded,
                              "before-deleting-tag": _vm.managerTagDeleted,
                              "max-tags-reached": _vm.managerTagMax,
                            },
                            model: {
                              value: _vm.manager,
                              callback: function ($$v) {
                                _vm.manager = $$v
                              },
                              expression: "manager",
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.managerSelected,
                                expression: "managerSelected",
                              },
                            ],
                            attrs: {
                              type: "hidden",
                              "data-vv-scope": "add",
                              "data-vv-name": "manager",
                              "data-vv-as": _vm.$t("manager"),
                            },
                            domProps: { value: _vm.managerSelected },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.managerSelected = $event.target.value
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("employee_no")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.userEmployeeNo,
                                  expression: "userEmployeeNo",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "userName",
                                placeholder: _vm.$t("employee_placeholder"),
                              },
                              domProps: { value: _vm.userEmployeeNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.userEmployeeNo = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("the_title")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.title,
                                  expression: "title",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "title",
                                placeholder: _vm.$t("title_placeholder"),
                              },
                              domProps: { value: _vm.title },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.title = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("division_")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.division,
                                  expression: "division",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "division",
                                placeholder: _vm.$t("division_placeholder"),
                              },
                              domProps: { value: _vm.division },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.division = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6 mt-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("unit_")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.unit,
                                  expression: "unit",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "unit",
                                placeholder: _vm.$t("unit_placeholder_"),
                              },
                              domProps: { value: _vm.unit },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.unit = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6 mt-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("phone_number")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "min:7",
                                  expression: "'min:7'",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.phone_number,
                                  expression: "phone_number",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "phone_number",
                                "data-vv-scope": "adduser",
                                "data-vv-name": "phone_number",
                                placeholder: _vm.$t("phone_number_placeholder"),
                              },
                              domProps: { value: _vm.phone_number },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.phone_number = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "contentTitle" },
                                },
                                [_vm._v(_vm._s(_vm.$t("timezone")))]
                              ),
                              _c("multi-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "selectbox selectbox--secondary",
                                class: {
                                  "is-danger":
                                    _vm.errors.has("adduser.timezone"),
                                },
                                attrs: {
                                  "clear-on-select": "",
                                  "deselect-label": "",
                                  "select-label": "",
                                  options: _vm.timezoneOptions,
                                  "data-vv-scope": "adduser",
                                  "data-vv-name": "timezone",
                                },
                                model: {
                                  value: _vm.selectedTimezone,
                                  callback: function ($$v) {
                                    _vm.selectedTimezone = $$v
                                  },
                                  expression: "selectedTimezone",
                                },
                              }),
                              _vm.errors.has("adduser.timezone")
                                ? _c(
                                    "span",
                                    { staticClass: "help is-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("adduser.timezone")
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12 col-md-6" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "contentTitle" },
                                },
                                [_vm._v(_vm._s(_vm.$t("language")))]
                              ),
                              _c("multi-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "selectbox selectbox--secondary",
                                class: {
                                  "is-danger":
                                    _vm.errors.has("adduser.language"),
                                },
                                attrs: {
                                  "track-by": "value",
                                  label: "value",
                                  "clear-on-select": "",
                                  "deselect-label": "",
                                  "select-label": "",
                                  "data-vv-scope": "adduser",
                                  options: _vm.languageOptions,
                                  "data-vv-name": "language",
                                },
                                model: {
                                  value: _vm.selectLanguages,
                                  callback: function ($$v) {
                                    _vm.selectLanguages = $$v
                                  },
                                  expression: "selectLanguages",
                                },
                              }),
                              _vm.errors.has("adduser.language")
                                ? _c(
                                    "span",
                                    { staticClass: "help is-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("adduser.language")
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12 col-md-6" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "contentTitle" },
                                },
                                [_vm._v(_vm._s(_vm.$t("user_type")))]
                              ),
                              _c("multi-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "selectbox selectbox--secondary",
                                class: {
                                  "is-danger":
                                    _vm.errors.has("adduser.usertype"),
                                },
                                attrs: {
                                  "track-by": "name",
                                  label: "name",
                                  "clear-on-select": "",
                                  "deselect-label": "",
                                  "select-label": "",
                                  options: _vm.roleOptions,
                                  "data-vv-name": "usertype",
                                  "data-vv-scope": "adduser",
                                  "data-vv-value-path": "name",
                                },
                                model: {
                                  value: _vm.selectRoles,
                                  callback: function ($$v) {
                                    _vm.selectRoles = $$v
                                  },
                                  expression: "selectRoles",
                                },
                              }),
                              _vm.errors.has("adduser.usertype")
                                ? _c(
                                    "span",
                                    { staticClass: "help is-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("adduser.usertype")
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group mb-4" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-md-6" }, [
                            _c("div", { staticClass: "swicth-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sendWelcomeEmail,
                                    expression: "sendWelcomeEmail",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "sendWelcomeEmail",
                                  "true-value": 1,
                                  "false-value": 0,
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.sendWelcomeEmail)
                                    ? _vm._i(_vm.sendWelcomeEmail, null) > -1
                                    : _vm._q(_vm.sendWelcomeEmail, 1),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.sendWelcomeEmail,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? 1 : 0
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.sendWelcomeEmail = $$a.concat([
                                            $$v,
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.sendWelcomeEmail = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.sendWelcomeEmail = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "swicth-label",
                                  attrs: { for: "sendWelcomeEmail" },
                                },
                                [_vm._v(_vm._s(_vm.$t("send_welcome_email_")))]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-12 col-md-6" }, [
                            _c("div", { staticClass: "swicth-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.activeUser,
                                    expression: "activeUser",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "activeUser",
                                  "true-value": 1,
                                  "false-value": 0,
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.activeUser)
                                    ? _vm._i(_vm.activeUser, null) > -1
                                    : _vm._q(_vm.activeUser, 1),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.activeUser,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? 1 : 0
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.activeUser = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.activeUser = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.activeUser = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "swicth-label",
                                  attrs: { for: "activeUser" },
                                },
                                [_vm._v(_vm._s(_vm.$t("active_user")))]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: { type: "button" },
                            on: { click: _vm.saveUser },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.tabPane === "otherSettingsTab"
                  ? _c("div", { staticClass: "tab-pane" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6 mt-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("team")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.team,
                                  expression: "team",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "team",
                                placeholder: _vm.$t("team_placeholder"),
                              },
                              domProps: { value: _vm.team },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.team = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6 mt-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("personnel_group")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.personnel_group,
                                  expression: "personnel_group",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "personnel_group",
                                placeholder: _vm.$t(
                                  "personnel_group_placeholder"
                                ),
                              },
                              domProps: { value: _vm.personnel_group },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.personnel_group = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6 mt-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("company_name")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.company_name,
                                  expression: "company_name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "company_name",
                                placeholder: _vm.$t("company_name_placeholder"),
                              },
                              domProps: { value: _vm.company_name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.company_name = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6 mt-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("business_name")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.business_name,
                                  expression: "business_name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "business_name",
                                placeholder: _vm.$t(
                                  "business_name_placeholder"
                                ),
                              },
                              domProps: { value: _vm.business_name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.business_name = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6 mt-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("business_group")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.business_group,
                                  expression: "business_group",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "business_group",
                                placeholder: _vm.$t(
                                  "business_group_placeholder"
                                ),
                              },
                              domProps: { value: _vm.business_group },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.business_group = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6 mt-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("position")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.position,
                                  expression: "position",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "position",
                                placeholder: _vm.$t("position_placeholder"),
                              },
                              domProps: { value: _vm.position },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.position = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6 mt-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("operation")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.operation,
                                  expression: "operation",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "operation",
                                placeholder: _vm.$t("operation_placeholder"),
                              },
                              domProps: { value: _vm.operation },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.operation = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6 mt-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("work_station")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.work_station,
                                  expression: "work_station",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "work_station",
                                placeholder: _vm.$t("work_station_placeholder"),
                              },
                              domProps: { value: _vm.work_station },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.work_station = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "col-6 mt-3" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "contentTitle" },
                              },
                              [_vm._v(_vm._s(_vm.$t("personal_email")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.personal_email,
                                  expression: "personal_email",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|email",
                                  expression: "'required|email'",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-danger": _vm.errors.has("adduser.e-mail"),
                              },
                              attrs: {
                                type: "text",
                                id: "personal_email",
                                "data-vv-scope": "adduser",
                                name: "e-mail",
                                placeholder: _vm.$t(
                                  "personal_email_placeholder"
                                ),
                              },
                              domProps: { value: _vm.personal_email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.personal_email = $event.target.value
                                },
                              },
                            }),
                            _vm.errors.has("adduser.e-mail")
                              ? _c("span", { staticClass: "help is-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("adduser.e-mail"))
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-6 mt-3" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "employment_start_date" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("employment_start_date"))
                                  ),
                                ]
                              ),
                              _c("date-picker", {
                                class: {
                                  "is-danger": _vm.errors.has(
                                    "communication.employment_start_date"
                                  ),
                                },
                                attrs: {
                                  type: "date",
                                  id: "employment_start_date",
                                  "value-type": "YYYY-MM-DD 00:00:00",
                                  "first-day-of-week":
                                    _vm.userLang === "en" ? 7 : 1,
                                  lang: _vm.userLang,
                                  format:
                                    _vm.userLang === "en"
                                      ? "MM.DD.YYYY"
                                      : "DD.MM.YYYY",
                                  placeholder: _vm.$t(
                                    "employment_start_date_placeholder"
                                  ),
                                },
                                model: {
                                  value: _vm.employment_start_date,
                                  callback: function ($$v) {
                                    _vm.employment_start_date = $$v
                                  },
                                  expression: "employment_start_date",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.items.length > 0,
                              expression: "errors.items.length > 0",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "alert alert--card alert--error" },
                            [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("error_msg_title"))),
                              ]),
                              _c(
                                "ul",
                                _vm._l(_vm.errors, function (error, index) {
                                  return _c("li", { key: index }, [
                                    _vm._v(" " + _vm._s(error.msg) + " "),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: { type: "button" },
                            on: { click: _vm.saveUser },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.tabPane === "relatedUsersTab"
                  ? _c("div", { staticClass: "tab-pane" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "manager" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("role_manager")))]
                                ),
                                _c("vue-tags-input", {
                                  staticClass: "manager",
                                  attrs: {
                                    id: "manager",
                                    tags: _vm.managerusers,
                                    "max-tags": 1,
                                    "autocomplete-items": _vm.manageruserToAdd,
                                    "add-only-from-autocomplete": true,
                                    "delete-on-backspace": false,
                                    placeholder: _vm.$t(
                                      "select_user_placeholder"
                                    ),
                                  },
                                  on: {
                                    "before-adding-tag":
                                      _vm.relatedUserTagAdded,
                                    "before-deleting-tag":
                                      _vm.relatedUserTagDeleted,
                                    "max-tags-reached": _vm.managerTagMax,
                                  },
                                  model: {
                                    value: _vm.manageruser,
                                    callback: function ($$v) {
                                      _vm.manageruser = $$v
                                    },
                                    expression: "manageruser",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "role_buddy" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("role_buddy")))]
                                ),
                                _c("vue-tags-input", {
                                  staticClass: "buddy",
                                  attrs: {
                                    id: "role_buddy",
                                    tags: _vm.buddyusers,
                                    "max-tags": 1,
                                    "autocomplete-items": _vm.buddyuserToAdd,
                                    "add-only-from-autocomplete": true,
                                    "delete-on-backspace": false,
                                    placeholder: _vm.$t(
                                      "select_user_placeholder"
                                    ),
                                  },
                                  on: {
                                    "max-tags-reached": _vm.managerTagMax,
                                    "before-adding-tag":
                                      _vm.relatedUserTagAdded,
                                    "before-deleting-tag":
                                      _vm.relatedUserTagDeleted,
                                  },
                                  model: {
                                    value: _vm.buddyuser,
                                    callback: function ($$v) {
                                      _vm.buddyuser = $$v
                                    },
                                    expression: "buddyuser",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "role_hrbp" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("role_hrbp")))]
                                ),
                                _c("vue-tags-input", {
                                  staticClass: "hrbp",
                                  attrs: {
                                    id: "role_hrbp",
                                    tags: _vm.hrbpusers,
                                    "max-tags": 1,
                                    "autocomplete-items": _vm.hrbpuserToAdd,
                                    "add-only-from-autocomplete": true,
                                    "delete-on-backspace": false,
                                    placeholder: _vm.$t(
                                      "select_user_placeholder"
                                    ),
                                  },
                                  on: {
                                    "max-tags-reached": _vm.managerTagMax,
                                    "before-adding-tag":
                                      _vm.relatedUserTagAdded,
                                    "before-deleting-tag":
                                      _vm.relatedUserTagDeleted,
                                  },
                                  model: {
                                    value: _vm.hrbpuser,
                                    callback: function ($$v) {
                                      _vm.hrbpuser = $$v
                                    },
                                    expression: "hrbpuser",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "role_other" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("role_other")))]
                                ),
                                _c("vue-tags-input", {
                                  staticClass: "other",
                                  attrs: {
                                    id: "role_other",
                                    tags: _vm.otherusers,
                                    "max-tags": 1,
                                    "autocomplete-items": _vm.otheruserToAdd,
                                    "add-only-from-autocomplete": true,
                                    "delete-on-backspace": false,
                                    placeholder: _vm.$t(
                                      "select_user_placeholder"
                                    ),
                                  },
                                  on: {
                                    "max-tags-reached": _vm.managerTagMax,
                                    "before-adding-tag":
                                      _vm.relatedUserTagAdded,
                                    "before-deleting-tag":
                                      _vm.relatedUserTagDeleted,
                                  },
                                  model: {
                                    value: _vm.otheruser,
                                    callback: function ($$v) {
                                      _vm.otheruser = $$v
                                    },
                                    expression: "otheruser",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.items.length > 0,
                              expression: "errors.items.length > 0",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "alert alert--card alert--error" },
                            [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("error_msg_title"))),
                              ]),
                              _c(
                                "ul",
                                _vm._l(_vm.errors, function (error, index) {
                                  return _c("li", { key: index }, [
                                    _vm._v(" " + _vm._s(error.msg) + " "),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: { type: "button" },
                            on: { click: _vm.saveUser },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.tabPane === "groupsJourneysTab" && _vm.editMode
                  ? _c("div", { staticClass: "tab-pane" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              { staticClass: "table-responsive" },
                              [
                                _vm.tableIsLoading
                                  ? _c("div", { staticClass: "lds-dual-ring" })
                                  : _vm._e(),
                                _c("vue-table", {
                                  ref: "myvuetable",
                                  class: { "table-fade": _vm.tableIsLoading },
                                  attrs: {
                                    "api-url": _vm.apiUrl,
                                    "http-fetch": _vm.myFetch,
                                    "api-mode": true,
                                    "pagination-path": "meta",
                                    "http-method": "get",
                                    "append-params": _vm.moreParams,
                                    "http-options": {
                                      headers: {
                                        Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                                      },
                                    },
                                    fields: _vm.fields,
                                    "no-data-template": _vm.$t("no_data_info"),
                                    css: _vm.css.table,
                                    "per-page": _vm.perPage,
                                    "query-params": _vm.makeQueryParams,
                                  },
                                  on: {
                                    "vuetable:loading": function ($event) {
                                      _vm.tableIsLoading = true
                                    },
                                    "vuetable:loaded": function ($event) {
                                      _vm.tableIsLoading = false
                                    },
                                    "vuetable:load-error":
                                      _vm.vuetableLoadError,
                                    "vuetable:pagination-data":
                                      _vm.onPaginationData,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "name",
                                        fn: function (props) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(props.rowData.name) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: "activation_date",
                                        fn: function (props) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      props.rowData
                                                        .activation_date
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: "actions",
                                        fn: function (props) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex",
                                                staticStyle: {
                                                  "max-width": "90px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "ui button delete",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onAction(
                                                          "groups-delete-item",
                                                          props.rowData,
                                                          props.rowIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "icon-container",
                                                      {
                                                        attrs: {
                                                          width: 14,
                                                          height: 14,
                                                          "view-box":
                                                            "0 0 20 20",
                                                          name: "trash",
                                                          color: "#727d92",
                                                        },
                                                      },
                                                      [_c("icon-trash")],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "ui button",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onAction(
                                                          "goto-group",
                                                          props.rowData,
                                                          props.rowIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "icon-container",
                                                      {
                                                        attrs: {
                                                          name: "search",
                                                          width: 14,
                                                          height: 14,
                                                          color: "#727d92",
                                                          "view-box":
                                                            "0 0 14 14",
                                                        },
                                                      },
                                                      [_c("icon-search")],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2740380695
                                  ),
                                }),
                                _c(
                                  "div",
                                  { staticClass: "pagination__info" },
                                  [
                                    _c("vuetable-pagination-info", {
                                      ref: "paginationInfo",
                                      class: {
                                        "table-fade": _vm.tableIsLoading,
                                      },
                                      attrs: {
                                        "info-template":
                                          _vm.$t("pagination_info"),
                                        "no-data-template":
                                          _vm.$t("no_data_info"),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "pagination__items" },
                                      [
                                        _c("vuetable-pagination", {
                                          ref: "pagination",
                                          attrs: { css: _vm.css.pagination },
                                          on: {
                                            "vuetable-pagination:change-page":
                                              _vm.onChangePage,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              { staticClass: "table-responsive" },
                              [
                                _vm.secondTableIsLoading
                                  ? _c("div", { staticClass: "lds-dual-ring" })
                                  : _vm._e(),
                                _c("vue-table", {
                                  ref: "mysecondvuetable",
                                  class: {
                                    "table-fade": _vm.secondTableIsLoading,
                                  },
                                  attrs: {
                                    "api-url": _vm.secondApiUrl,
                                    "api-mode": true,
                                    "pagination-path": "meta",
                                    "http-method": "get",
                                    "append-params": _vm.moreParams,
                                    "http-options": {
                                      headers: {
                                        Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                                      },
                                    },
                                    fields: _vm.secondfields,
                                    "no-data-template": _vm.$t("no_data_info"),
                                    css: _vm.css.table,
                                    "per-page": _vm.perPage,
                                    "query-params": _vm.makeQueryParams,
                                  },
                                  on: {
                                    "vuetable:loading": function ($event) {
                                      _vm.secondTableIsLoading = true
                                    },
                                    "vuetable:loaded": function ($event) {
                                      _vm.secondTableIsLoading = false
                                    },
                                    "vuetable:pagination-data":
                                      _vm.onSecondPaginationData,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "name",
                                        fn: function (props) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(props.rowData.name) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: "activation_date",
                                        fn: function (props) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      props.rowData
                                                        .activation_date
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: "actions",
                                        fn: function (props) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex",
                                                staticStyle: {
                                                  "max-width": "90px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "ui button delete",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onAction(
                                                          "journey-delete-item",
                                                          props.rowData,
                                                          props.rowIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "icon-container",
                                                      {
                                                        attrs: {
                                                          width: 14,
                                                          height: 14,
                                                          name: "trash",
                                                          color: "#727d92",
                                                          "view-box":
                                                            "0 0 20 20",
                                                        },
                                                      },
                                                      [_c("icon-trash")],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass: "ui button",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onAction(
                                                          "goto-journey",
                                                          props.rowData,
                                                          props.rowIndex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "icon-container",
                                                      {
                                                        attrs: {
                                                          name: "search",
                                                          width: 14,
                                                          height: 14,
                                                          color: "#727d92",
                                                          "view-box":
                                                            "0 0 14 14",
                                                        },
                                                      },
                                                      [_c("icon-search")],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1945183460
                                  ),
                                }),
                                _c(
                                  "div",
                                  { staticClass: "pagination__info" },
                                  [
                                    _c("vuetable-pagination-info", {
                                      ref: "secondPaginationInfo",
                                      class: {
                                        "table-fade": _vm.secondTableIsLoading,
                                      },
                                      attrs: {
                                        "info-template":
                                          _vm.$t("pagination_info"),
                                        "no-data-template":
                                          _vm.$t("no_data_info"),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "pagination__items" },
                                      [
                                        _c("vuetable-pagination", {
                                          ref: "secondPagination",
                                          attrs: { css: _vm.css.pagination },
                                          on: {
                                            "vuetable-pagination:change-page":
                                              _vm.onSecondChangePage,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }