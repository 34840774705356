var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M1.37575 12.5152H13.5682V6.28046H1.37575V12.5152ZM14.2561 13.9042H0.688019C0.308066 13.9042 0 13.5936 0 13.2097V5.58603C0 5.20217 0.308066 4.8916 0.688019 4.8916H14.2561C14.6359 4.8916 14.9441 5.20217 14.9441 5.58603V13.2097C14.9441 13.5936 14.6359 13.9042 14.2561 13.9042V13.9042Z",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M12.4002 3.88998H2.54446C2.16451 3.88998 1.85645 3.57955 1.85645 3.19555C1.85645 2.81155 2.16451 2.50098 2.54446 2.50098H12.4002C12.78 2.50098 13.088 2.81155 13.088 3.19555C13.088 3.57955 12.78 3.88998 12.4002 3.88998",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M10.0962 1.53158H4.84803C4.46822 1.53158 4.16016 1.22101 4.16016 0.83715C4.16016 0.45315 4.46822 0.142578 4.84803 0.142578H10.0962C10.476 0.142578 10.7841 0.45315 10.7841 0.83715C10.7841 1.22101 10.476 1.53158 10.0962 1.53158",
        fill: "currentColor",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }