<template>
  <span>
    <select
      class="switch-customer"
      name="customer"
      @change="changeCustomer"
    >
      <option
        v-for="customer in listOfCustomers"
        :key="customer.id"
        :selected="isCurrentCustomer(customer.id)"
        :value="customer.id"
      >
        {{ customer.name }}
      </option>
    </select>
  </span>
</template>
<script>
import API from '@/services/';
import {
  API_GET_CUSTOMER_LIST_LIGHT,
} from '@/helpers/config';

export default {
  name: 'SwitchCustomer',

  data() {
    return {
      listOfCustomers: [],
    };
  },

  computed: {
    currentCustomer() {
      return this.$store.state.auth.customerId;
    },
    customerListFromCache() {
      return this.$store.state.auth.customerList;
    },
  },

  mounted() {
    this.getCustomerList();
  },

  methods: {

    getCustomerList() {
      if (this.customerListFromCache && this.customerListFromCache.length === 0) {
        console.log('sürekli bu customer list yukleniyorrr!');
        const formData = { params: {} };
        formData.params.page = 1;
        formData.params.per_page = 1000;
        formData.params.order = 'asc';
        formData.params.sort = 'name';
        API.get(API_GET_CUSTOMER_LIST_LIGHT, formData).then((response) => {
          this.listOfCustomers = response.data;
          this.$store.commit('auth/set_customer_list', response.data);
        });
      } else {
        this.listOfCustomers = [...this.customerListFromCache];
      }
    },

    isCurrentCustomer(id) {
      return id === this.currentCustomer;
    },

    changeCustomer(e) {
      const customerId = e.target.value;
      const customerName = this.listOfCustomers.filter(item => item.id === Number(customerId));
      let name = null;
      if (customerName) {
        ([{ name }] = customerName);
      }
      if (name) {
        this.$store.commit('auth/setCustomerName', name);
      }
      if (customerId) {
        this.$store.commit('auth/setCustomerId', Number(customerId));
      }
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },

  },
};
</script>
<style  scoped>
.switch-customer {
  margin-left: 5px;
  display: block;
  width: 150px;
}
</style>
