<template>
  <div class="page mb-4 pb-4">
    <app-header
      v-if="!propUserId"
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>
    <main class="container mb-4 pb-4">
      <div class="vuetable-wrapper">
        <div
          class="mb-4 navigation text-capitalize d-flex items-center"
          v-if="$route.params.journeyId && !propUserId"
        >
          <router-link
            :to="{ path: `/dashboard/journeys/users/${$route.params.journeyId}`, query: {journey: journeyName}}"
          >
            <icon-container
              :width="40"
              :height="18"
              name="Go Back"
              color="#727d92"
            >
              <icon-goback />
            </icon-container>
          </router-link> <div class="d-flex flex-column">
            <span>{{ userName }} {{ userSurname }}</span>
            <span>{{ userEmployeeNo }}</span>
            <span>{{ userEmail }}</span>
          </div>
        </div>
        <div
          v-if="tableIsLoading"
          class="lds-dual-ring"
        />
        <div
          class="table-responsive"
          v-if="userId"
        >
          <vue-table
            :class="{ 'table-fade': tableIsLoading }"
            ref="myvuetable"
            :api-url="apiUrl"
            :http-fetch="myFetch"
            :api-mode="true"
            @vuetable:loading="tableIsLoading = true"
            @vuetable:loaded="tableIsLoading = false"
            @vuetable:load-error="vuetableLoadError"
            pagination-path="meta"
            http-method="post"
            :append-params="moreParams"
            :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
            :fields="fields"
            :no-data-template="$t('no_data_info')"
            :css="css.table"
            :per-page="perPage"
            :query-params="makeQueryParams"
            @vuetable:pagination-data="onPaginationData"
          >
            <template
              slot="name"
              slot-scope="props"
            >
              <router-link
                v-if="$route.params.journeyId && !propUserId"
                :to="{ path: `/dashboard/journeys/users/${$route.params.journeyId}/user/${userId}/${props.rowData.id}`, query: {journey:$route.query.journey, name: userName,
                                                                                                                                surname:userSurname, employee_no: userEmployeeNo, email: userEmail}}"
              >
                {{ props.rowData.name }}
              </router-link>
              <div
                v-else
                class="pointer-action"
                @click="popUp(props.rowData)"
              >
                {{ props.rowData.name }}
              </div>
            </template>
            <template
              slot="actions"
              slot-scope="props"
            >
              <button
                class="ui button"
                @click="onAction('user-menu', props.rowData)"
              >
                <icon-container
                  :width="20"
                  :height="20"
                  view-box="0 0 24 24"
                  name="threedots"
                  color="#727d92"
                >
                  <icon-threedots />
                </icon-container>
              </button>
            </template>
          </vue-table>
          <div class="pagination__info">
            <vuetable-pagination-info
              :class="{ 'table-fade': tableIsLoading }"
              ref="paginationInfo"
              :info-template="$t('pagination_info')"
              :no-data-template="$t('no_data_info')"
            />
            <div class="pagination__items">
              <vuetable-pagination
                ref="pagination"
                :css="css.pagination"
                @vuetable-pagination:change-page="onChangePage"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import { mapState } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import IconThreedots from '@/components/icons/Threedots.vue';
import IconGoBack from '@/components/icons/GoBack.vue';
import AppHeader from '@/components/AppHeader.vue';
import { API_REPORT_JOURNEY_USER_JOURNEYS } from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'ReportJourneyUserJourneys',
  mixins: [myTableMixin],
  props: {
    propUserId: {
      type: String,
      default: null,
      required: false,
    },
    propUserName: {
      type: String,
      default: null,
      required: false,
    },
    propUserSurname: {
      type: String,
      default: null,
      required: false,
    },
    propUserEmployeeNo: {
      type: String,
      default: null,
      required: false,
    },
    propUserEmail: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      moreParams: {
        user_id: this.$route.params.userId ? this.$route.params.userId : this.propUserId,
      },
      journeyName: this.$route.query.journey,
      userName: this.$route.query.name ? this.$route.query.name : this.propUserName,
      userSurname: this.$route.query.surname ? this.$route.query.surname : this.propUserSurname,
      userEmployeeNo: this.$route.query.employee_no ? this.$route.query.employee_no : this.propUserEmployeeNo,
      userEmail: this.$route.query.email ? this.$route.query.email : this.propUserEmail,
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-threedots': IconThreedots,
    'icon-goback': IconGoBack,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'ReportJourneys') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),
    userId() {
      return this.$route.params.userId ? this.$route.params.userId : this.propUserId;
    },
    apiUrl() {
      if (API_REPORT_JOURNEY_USER_JOURNEYS) {
        return `${API_REPORT_JOURNEY_USER_JOURNEYS}`;
      }
      return null;
    },

    customerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },

    userSubNavigation() {
      return [
        {
          text: this.$t('users'),
          url: `/dashboard/journeys/users/${this.$route.params.journeyId}`,
          query: { journey: this.journeyName },
        },
        {
          text: this.$t('contents_subtitle'),
          url: `/dashboard/journeys/contents/${this.$route.params.journeyId}`,
          query: { journey: this.journeyName },
        },
      ];
    },

    fields() {
      return [
        {
          name: 'name',
          title: this.$t('journey_name'),
          sortField: 'journeys.name',
          width: '30%',
        },
        {
          name: 'assigned_item_count',
          title: this.$t('assigned_item_count'),
          sortField: 'assigned_item_count',
          width: '10%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'opened_item_count',
          title: this.$t('opened_item_count'),
          sortField: 'opened_item_count',
          width: '10%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'completed_item_count',
          title: this.$t('completed_items_count'),
          sortField: 'completed_item_count',
          width: '10%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'activation_date',
          title: this.$t('activation_date'),
          sortField: 'journeys.activation_date',
          width: '17%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'end_date',
          title: this.$t('end_date'),
          sortField: 'end_date',
          width: '15%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'completed_at',
          title: this.$t('completed_at'),
          sortField: 'completed_at',
          width: '10%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '6%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        }];
    },
  },
  methods: {
    popUp(data) {
      const obj = {
        modalType: 'modalJourneyUserJourneyInside',
        modalData: {
          journeyId: data.id,
          journeyName: data.name,
          userId: this.userId,
          userName: this.userName,
          userSurname: this.userSurname,
          userEmail: this.userEmail,
          userEmployeeNo: this.userEmployeeNo,
        },
      };
      this.$helpers.toggleModal(obj);
    },
    onAction(action, data) {
      const userId = data.id;
      if (action === 'user-menu') {
        if (userId) {
          console.log('User action to do'); // TODO: complete dropdown action menu
        }
      }
    },
  },
};
</script>
<style scoped>
.pointer-action {
  cursor: pointer;
  color: #007bff;
}
</style>
