var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        fill: "currentColor",
        "fill-rule": "evenodd",
        d: "M17.8 12.14H2.2a2.2 2.2 0 010-4.38h15.6a2.2 2.2 0 010 4.38z",
        "clip-rule": "evenodd",
      },
    }),
    _c("path", {
      attrs: {
        fill: "currentColor",
        "fill-rule": "evenodd",
        d: "M10.05 20a2.2 2.2 0 01-2.2-2.2V2.2a2.2 2.2 0 014.4 0v15.6a2.2 2.2 0 01-2.2 2.2z",
        "clip-rule": "evenodd",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }