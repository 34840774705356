var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "is-child-header-left-actions": "",
            "child-header-navigation": _vm.userSubNavigation,
            "add-pin": "",
          },
        },
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.tableIsLoading
          ? _c("div", { staticClass: "lds-dual-ring" })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c("vue-table", {
              ref: "myvuetable",
              class: { "table-fade": _vm.tableIsLoading },
              attrs: {
                "api-url": _vm.apiUrl,
                "http-fetch": _vm.myFetch,
                "api-mode": true,
                "pagination-path": "meta",
                "http-method": "post",
                "append-params": _vm.moreParams,
                "http-options": {
                  headers: {
                    Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                  },
                },
                fields: _vm.fields,
                "no-data-template": _vm.$t("no_data_info"),
                css: _vm.css.table,
                "per-page": _vm.perPage,
                "query-params": _vm.makeQueryParams,
              },
              on: {
                "vuetable:loading": function ($event) {
                  _vm.tableIsLoading = true
                },
                "vuetable:loaded": function ($event) {
                  _vm.tableIsLoading = false
                },
                "vuetable:load-error": _vm.vuetableLoadError,
                "vuetable:pagination-data": _vm.onPaginationData,
              },
              scopedSlots: _vm._u([
                {
                  key: "get_privacy_approval",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          props.rowData.get_privacy_approval === 0
                            ? _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "cross",
                                    rotate: 45,
                                    "view-box": "0 0 20 20",
                                    color: "#142B58",
                                    "is-icon-class": false,
                                  },
                                },
                                [_c("icon-cross")],
                                1
                              )
                            : _vm._e(),
                          props.rowData.get_privacy_approval === 1
                            ? _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "check",
                                    "view-box": "0 0 512 512",
                                    "is-icon-class": false,
                                  },
                                },
                                [_c("icon-check")],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "sso_uri",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "d-inline-block text-truncate",
                          staticStyle: { "max-width": "220px" },
                          attrs: { title: props.rowData.sso_uri },
                        },
                        [_vm._v(" " + _vm._s(props.rowData.sso_uri) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "groups",
                  fn: function (props) {
                    return [
                      _c("div", { staticClass: "d-inline-block" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              props.rowData.groups[0] === "0"
                                ? `-`
                                : props.rowData.groups.join(",")
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "journeys",
                  fn: function (props) {
                    return [
                      _c("div", { staticClass: "d-inline-block" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              props.rowData.journeys[0] === "0"
                                ? `-`
                                : props.rowData.journeys.join(",")
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "actions",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "ui button edit",
                          on: {
                            click: function ($event) {
                              return _vm.onAction("edit-item", props.rowData)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 20,
                                height: 20,
                                name: "edit",
                                color: "#727d92",
                              },
                            },
                            [_c("icon-edit")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "ui button delete",
                          on: {
                            click: function ($event) {
                              return _vm.onAction("delete-item", props.rowData)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 20,
                                height: 20,
                                name: "trash",
                                color: "#727d92",
                              },
                            },
                            [_c("icon-trash")],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              { staticClass: "pagination__info" },
              [
                _c("vuetable-pagination-info", {
                  ref: "paginationInfo",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "info-template": _vm.$t("pagination_info"),
                    "no-data-template": _vm.$t("no_data_info"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "pagination__items" },
                  [
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }