var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        d: "M12 4.5C7.30558 4.5 3.5 8.30558 3.5 13C3.5 15.3447 4.44841 17.4665 5.98432 19.0051C6.27696 19.2983 6.27655 19.7732 5.9834 20.0658C5.69025 20.3584 5.21538 20.358 4.92274 20.0649C3.11755 18.2565 2 15.7581 2 13C2 7.47715 6.47715 3 12 3C17.5228 3 22 7.47715 22 13C22 15.7581 20.8824 18.2565 19.0773 20.0649C18.7846 20.358 18.3097 20.3584 18.0166 20.0658C17.7235 19.7732 17.723 19.2983 18.0157 19.0051C19.5516 17.4665 20.5 15.3447 20.5 13C20.5 8.30558 16.6944 4.5 12 4.5Z",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        d: "M12 8C9.23858 8 7 10.2386 7 13C7 14.3818 7.55952 15.6315 8.46617 16.5372C8.7592 16.83 8.75943 17.3049 8.46667 17.5979C8.17392 17.8909 7.69905 17.8912 7.40601 17.5984C6.22927 16.4228 5.5 14.7958 5.5 13C5.5 9.41015 8.41015 6.5 12 6.5C15.5899 6.5 18.5 9.41015 18.5 13C18.5 14.7958 17.7707 16.4228 16.594 17.5984C16.301 17.8912 15.8261 17.8909 15.5333 17.5979C15.2406 17.3049 15.2408 16.83 15.5338 16.5372C16.4405 15.6315 17 14.3818 17 13C17 10.2386 14.7614 8 12 8Z",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        d: "M12 10.5C10.6193 10.5 9.5 11.6193 9.5 13C9.5 14.3807 10.6193 15.5 12 15.5C13.3807 15.5 14.5 14.3807 14.5 13C14.5 11.6193 13.3807 10.5 12 10.5ZM11 13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13Z",
        fill: "currentColor",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }