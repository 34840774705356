<template>
  <div class="page">
    <app-header
      child-header-class="container"
      :child-header-navigation="childHeaderNavigation"
      is-child-header-left-actions
      :total-users="null"
      :journey-edit-icon="null"
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
      <dropdown
        class-name="filter"
        :filter-on="the_filter.only_active_customer_users"
      >
        <template slot="icon">
          <i
            class="fa fa-filter button button--shadow button--type-icon size-sm d-flex align-items-center"
            style="cursor: pointer;color: rgb(114, 125, 146);"
          />
        </template>
        <template slot="body">
          <div class="d-block p-2">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <label
                for="filter-only_active_customer_users"
                class="checkbox"
                v-if="isVibonsAdmin"
              >
                <input
                  type="checkbox"
                  id="filter-only_active_customer_users"
                  :true-value="true"
                  :false-value="false"
                  v-model="the_filter.only_active_customer_users"
                >
                {{ $t('only_active_customer_users') }}
              </label>
              <label
                for="filter-completed"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  id="filter-completed"
                  :true-value="'completed'"
                  :false-value="''"
                  v-model="the_filter.status"
                >
                {{ $t('completed') }}
              </label>
              <label
                for="filter-active"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  id="filter-active"
                  :true-value="'active'"
                  :false-value="''"
                  v-model="the_filter.status"
                >
                {{ $t('active') }}
              </label>
            </div>
          </div>
        </template>
      </dropdown>
    </app-header>
    <main class="page__body">
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="container-fluid container-cards">
        <div class="container">
          <div class="table-responsive">
            <vue-table
              :class="{ 'table-fade': tableIsLoading }"
              ref="myvuetable"
              :api-url="apiUrl"
              :http-fetch="myFetch"
              :api-mode="true"
              @vuetable:loading="tableIsLoading = true"
              @vuetable:loaded="tableIsLoading = false"
              @vuetable:load-error="vuetableLoadError"
              pagination-path="meta"
              http-method="get"
              :append-params="moreParams"
              :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
              :fields="fields"
              :no-data-template="$t('no_data_info')"
              :css="css.table"
              :per-page="perPage"
              :query-params="makeQueryParams"
              @vuetable:pagination-data="onPaginationData"
            >
              <template
                slot="name-slot"
                slot-scope="props"
              >
                <div>
                  <span>{{ nameGenerated(props) }}</span>
                </div>
              </template>
              <template
                slot="status-slot"
                slot-scope="props"
              >
                <div v-if="props.rowData.is_active">
                  <span class="status-active">{{ $t('active') }}</span>
                </div>
                <div v-else>
                  <span class="status-pasive">{{ $t('completed_') }}</span>
                </div>
              </template>
              <template
                slot="customer-slot"
                slot-scope="props"
              >
                <span>{{ props.rowData.customer }}</span>
              </template>
              <template
                slot="activation_date-slot"
                slot-scope="props"
              >
                <div v-if="props.rowData.activation_date">
                  <span>{{ formatDate(props.rowData.activation_date) }}</span>
                </div>
              </template>
              <template
                slot="user_count"
                slot-scope="props"
              >
                <span>
                  <router-link
                    :to="{
                      name: 'JourneyUsers',
                      params: {
                        journeyId: props.rowData.id,
                        title: props.rowData.name
                      }}"
                  >
                    {{ props.rowData.user_count }}
                  </router-link>
                </span>
              </template>
              <template
                slot="action-slot"
                slot-scope="props"
              >
                <div class="button-group center action-button">
                  <div
                    class="dropdown"
                    @click="onAction('edit-content', props.rowData)"
                  >
                    <span style="cursor: pointer">
                      <icon-container
                        width="20"
                        height="20"
                        view-box="0 0 24 24"
                        name="edit"
                        color="#727d92"
                      >
                        <icon-edit />
                      </icon-container>
                    </span>
                  </div>

                  <div
                    class="dropdown ml-2 mr-2"
                    @mouseover="dropdownToggle(`plus${props.rowIndex}`)"
                    @mouseleave="hideDropdown"
                  >
                    <span class="card--actions dropdown__toggle">
                      <icon-container
                        width="18"
                        height="18"
                        view-box="0 0 24 24"
                        name="cplus"
                        color="#727d92"
                      >
                        <icon-circleplus />
                      </icon-container>
                    </span>

                    <div
                      class="dropdown__menu"
                      :ref="`plus${props.rowIndex}`"
                      v-if="dropdownIndex === 'plus' + props.rowIndex"
                      v-on-clickaway="hideDropdown"
                    >
                      <span
                        class="dropdown__button button"
                        @click="onAction('add-user', props.rowData)"
                      >
                        <icon-container
                          :width="18"
                          :height="18"
                          name="user"
                          color="#727d92"
                        >
                          <icon-user />
                        </icon-container>
                        {{ $t('add_user') }}
                      </span>

                      <span
                        class="dropdown__button button"
                        @click="onAction('add-group', props.rowData)"
                      >
                        <icon-container
                          :width="18"
                          :height="18"
                          view-box="0 0 24 24"
                          name="group"
                          color="#727d92"
                        >
                          <icon-group />
                        </icon-container>
                        {{ $t('add_group') }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="dropdown"
                    @mouseover="dropdownToggle(`three${props.rowIndex}`)"
                    @mouseleave="hideDropdown"
                  >
                    <span class="card--actions dropdown__toggle">
                      <icon-container
                        :width="20"
                        :height="20"
                        view-box="0 0 24 24"
                        name="threedots"
                        color="#727d92"
                      >
                        <icon-threedots />
                      </icon-container>
                    </span>

                    <div
                      class="dropdown__menu"
                      :ref="`three${props.rowIndex}`"
                      v-if="dropdownIndex === 'three' + props.rowIndex"
                      v-on-clickaway="hideDropdown"
                    >
                      <span
                        class="dropdown__button button"
                        @click="onAction('notification', props.rowData)"
                      >
                        <icon-container
                          :width="18"
                          :height="18"
                          view-box="0 0 24 24"
                          name="edit"
                          color="#727d92"
                        >
                          <icon-bell />
                        </icon-container>
                        {{ $t('notification_button') }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="onAction('download-activity', props.rowData)"
                      >
                        <icon-container
                          width="13"
                          height="14"
                          name="Download Activity"
                          color="#727d92"
                        >
                          <icon-download />
                        </icon-container>
                        {{ $t('download_activity') }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="exportContentListReport(props.rowData)"
                      >
                        <icon-container
                          name="content"
                          view-box="0 0 13 14"
                          color="#727d92"
                          :is-icon-class="false"
                        >
                          <icon-download />
                        </icon-container>
                        {{ $t("download_journey_content_report") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="onAction('delete-item', props.rowData)"
                      >
                        <icon-container
                          :width="18"
                          :height="18"
                          view-box="0 0 24 24"
                          name="trash"
                          color="#727d92"
                        >
                          <icon-trash />
                        </icon-container>
                        {{ $t('delete') }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </vue-table>
            <div class="pagination__info">
              <vuetable-pagination-info
                :class="{ 'table-fade': tableIsLoading }"
                ref="paginationInfo"
                :info-template="$t('pagination_info')"
                :no-data-template="$t('no_data_info')"
              />
              <div class="pagination__items">
                <vuetable-pagination
                  ref="pagination"
                  :css="css.pagination"
                  @vuetable-pagination:change-page="onChangePage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable import/no-unresolved */
import { mapGetters } from 'vuex';
import { directive as onClickaway } from 'vue-clickaway';
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconEdit from '@/components/icons/Edit.vue';
import IconBell from '@/components/icons/Bell.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconDownload from '@/components/icons/Download.vue';
import IconUser from '@/components/icons/User.vue';
import IconGroup from '@/components/icons/Group.vue';
import IconThreedots from '@/components/icons/Threedots.vue';
import IconCirclePlus from '@/components/icons/CirclePlus.vue';

import API from '@/services/';
import {
  API_JOURNEY_FILTERED,
  API_POST_JOURNEY,
  // API_JOURNEY_USER_ACTIVITY,
  API_JOB_JOURNEY_USER_ACTIVITY,
  API_JOURNEY_REPORT,
  API_JOURNEY_UPDATE,
  API_SERVER_TIME_FORMAT,
} from '@/helpers/config';
import AppHeader from '@/components/AppHeader.vue';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import Dropdown from '@/components/elements/Dropdown.vue';
import VuetablePagination from '../components/CustomVuetable/VuetablePagination.vue';

const moment = require('moment');

console.log('JourneyPlanned');

export default {
  name: 'Journeyplanned',
  mixins: [myTableMixin],
  components: {
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'icon-container': IconContainer,
    'icon-edit': IconEdit,
    'icon-bell': IconBell,
    'icon-trash': IconTrash,
    'icon-download': IconDownload,
    'icon-user': IconUser,
    'icon-group': IconGroup,
    'icon-threedots': IconThreedots,
    'icon-circleplus': IconCirclePlus,
    'filter-perpage': FilterPerpage,
    Dropdown,
  },

  data() {
    return {
      moreParams: {
      },
      the_filter: {
        only_active_customer_users: false,
        status: '',
      },
    };
  },
  beforeDestroy() {
    console.log('Journeyplanned before destroyed');
    this.$refs.myvuetable.resetData();
  },
  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'Journeyplanned') {
        this.$refs.myvuetable.refresh();
        this.refreshPage = false;
        this.pageToRefresh = null;
      }
    },
    the_filter: {
      handler(filter) {
        if (filter.only_active_customer_users) {
          this.$set(this.moreParams, 'customer_id', this.$store.state.auth.customerId);
        }
        if (!filter.only_active_customer_users) {
          this.$delete(this.moreParams, 'customer_id');
        }
        if (filter.status) {
          this.$set(this.moreParams, 'status', filter.status);
        }
        if (!filter.status) {
          this.$delete(this.moreParams, 'status');
        }
      },
      immediate: true,
      deep: true,
    },
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  computed: {

    ...mapGetters({
      userLang: 'auth/userLang',
    }),

    apiUrl() {
      if (API_JOURNEY_FILTERED) {
        return `${API_JOURNEY_FILTERED}`;
      }
      return null;
    },

    fields() {
      const pathTemp = [
        {
          name: 'name-slot',
          title: this.$t('journey_name'),
          width: '45%',
          sortField: 'name',
        },
        {
          name: 'content_count',
          title: this.$t('contents_subtitle'),
          width: '10%',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'user_count',
          title: this.$t('users'),
          width: '7%',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'activation_date-slot',
          title: this.$t('activation_date'),
          width: '13%',
          sortField: 'activation_date',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'status-slot',
          title: this.$t('status'),
          width: '10%',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'action-slot',
          title: this.$t('actions'),
          width: '15%',
          titleClass: 'center',
          dataClass: 'center',
        },
      ];
      if (this.isVibonsAdmin) {
        const index = pathTemp.length - 1;

        pathTemp.splice(index, 0,
          {
            name: 'customer-slot',
            title: this.$t('customer_'),
            width: '5%',
            titleClass: 'center',
            dataClass: 'center',
          });
      }
      return pathTemp;
    },

    currentCustomerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },

    childHeaderNavigation() {
      const pathsTemp = [
        {
          text: this.$t('catalog'),
          url: '/',
        },
        {
          text: this.$t('journey_group'),
          url: '/journeygroup',
        },
        {
          text: this.$t('planned_journey'),
          url: '/plannedjourney',
        },
      ];
      // if (this.isVibonsAdmin) {
      //   pathsTemp.push(
      //    {
      //      text: this.$t('planned_journey'),
      //      url: '/plannedjourney',
      //    },
      //  );
      // }
      return pathsTemp;
    },
  },

  activated() {
    this.$refs.myvuetable.refresh();
  },

  methods: {
    exportContentListReport(data) {
      const itemId = data.id;
      const obj = {
        modalType: 'modalReportDownload',
        reportDownloadUrl: `${API_JOURNEY_REPORT}/${itemId}`,
        reportDownloadRequest: 'get',
        dateOnly: false,
        reportDownloadPayload: {},
      };
      this.$helpers.toggleModal(obj);
    },

    onAction(action, data) {
      const itemId = data.id;
      this.idHolder = itemId;
      this.apiUrlHolder = API_POST_JOURNEY;

      if (!itemId) {
        return;
      }

      if (action === 'delete-item' && !this.snotifyQueueList.includes(itemId)) {
        this.snotifyQueueList.push(itemId);
        this.$snotify.confirm(this.$t('journey_delete_msg_body', { name: data.name }), this.$t('journey_delete_msg_title'), {
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          buttons: [
            {
              text: this.$t('yes'),
              action: (toast) => {
                this.deleteConfirm(toast, this.idHolder, this.apiUrlHolder);
              },
              bold: false,
            },
            {
              text: this.$t('no'),
              action: (toast) => {
                this.deleteCancel(toast, this.idHolder);
              },
            },
          ],
        }).on('shown', (toast) => {
          this.toastHolder = toast;
          document.addEventListener('keydown', this.eventHandler, false);
        }).on('hidden', (toast) => {
          this.toastHolder = toast;
          document.removeEventListener('keydown', this.eventHandler, false);
        });
      }

      if (action === 'edit-content') {
        this.$router.push({ path: `/journey/detail/${itemId}` });
      }

      if (action === 'edit-setting') {
        const obj = {
          modalType: 'modalJourney',
          journeyId: itemId,
          tabPane: 'mainTab',
          launchedFrom: 'Journeyplanned',
          pageToRefresh: 'Journeyplanned',
          editMode: true,
        };
        this.$helpers.toggleModal(obj);
      }

      if (action === 'notification') {
        const obj = {
          modalType: 'modalNotification',
          journeyId: itemId,
          notificationType: 'journey',
        };
        this.$helpers.toggleModal(obj);
      }

      if (action === 'download-activity') {
        this.downloadReport(data);
      }

      if (action === 'add-user') {
        const obj = {
          modalType: 'modalJourneyAddUser',
          journeyId: itemId,
          journeyCustomerId: data.customer_id,
          pageToRefresh: 'Journeyplanned',
        };
        this.$helpers.toggleModal(obj);
      }

      if (action === 'add-group') {
        const obj = {
          modalType: 'modalJourneyAddGroup',
          journeyId: itemId,
          journeyCustomerId: data.customer_id,
          pageToRefresh: 'Journeyplanned',
        };
        this.$helpers.toggleModal(obj);
      }
    },

    updatedJourney(data) {
      const item = data;
      const time = moment().format(API_SERVER_TIME_FORMAT);
      item.activation_date = time;

      const formData = new FormData();
      formData.append('journey_id', item.id); // eslint-disable-next-line
      formData.append('name', `${item.name} copy`);
      formData.append('activation_date', item.activation_date);
      formData.append('lang', item.lang);
      formData.append('is_list_on_the_catalog_enabled', 1);

      API.post(API_JOURNEY_UPDATE, formData)
        .then((response) => {
          if (response.status === 200) {
            if (item.name.search('copy') !== -1 || item.name.search('copy') === -1) { // eslint-disable-next-line
                item.name = `${item.name} copy`;
            }
            this.$refs.myvuetable.refresh();
            this.tableIsLoading = false;
            this.$snotify.success(response.data.message);
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
          this.tableIsLoading = false;
        });
    },

    downloadReport(data) {
      const itemId = data.id;
      const obj = {
        modalType: 'modalReportDownload',
        reportDownloadUrl: `${API_JOB_JOURNEY_USER_ACTIVITY}`,
        dateOnly: false,
        reportDownloadPayload: {
          customer_id: this.currentCustomerId,
          journey_id: itemId,
        },
      };
      this.$helpers.toggleModal(obj);
    },

    formatDate(value) {
      let fmt = null;
      let date = null;
      if (value) {
        if (this.userLang === 'en') {
          fmt = 'MM.DD.YYYY';
        }
        if (this.userLang === 'tr') {
          fmt = 'DD.MM.YYYY';
        }
        date = moment(value, API_SERVER_TIME_FORMAT).format(fmt);
      }
      return date;
    },
  },
};
</script>

  <style lang="scss" scoped>
  .status-active {
    color: rgb(8, 206, 25);
    font-weight: 600;
  }

  .select-perpage {
    vertical-align: middle;
    display: inline-block;
  }
  button.ui.button {
    padding: 8px 3px 8px 10px;
    margin-top: 1px;
    margin-bottom: 1px;
    }

  ::v-deep div.action-button {
    margin: 10px!important;
  }
  </style>
