<template>
  <div class="page">
    <app-header
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>
    <div class="container">
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="post"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="name"
            slot-scope="props"
          >
            <span>
              {{ props.rowData.name }}
            </span>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <span class="justify-content-center">
              <router-link
                :to="{
                  name: 'ManagerUserDevReport',
                  params: {
                    userId: props.rowData.id,
                    title: props.rowData.first_name + ' ' + props.rowData.last_name,
                  }}"
              >
                <icon-container
                  :width="20"
                  :height="20"
                  view-box="0 -52 512 512"
                  name="table"
                  color="#727d92"
                >
                  <icon-table />
                </icon-container>
              </router-link>
            </span>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import myTableMixin from '@/helpers/myTableMixin.vue';

import IconContainer from '@/components/elements/Icon.vue';
import IconTable from '@/components/icons/Table.vue';
import AppHeader from '@/components/AppHeader.vue';
import {
  API_GET_MANAGERS,
} from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import VuetablePagination from '../components/CustomVuetable/VuetablePagination.vue';

console.log('Managers');

export default {
  name: 'Managers',
  mixins: [myTableMixin],
  data() {
    return {
      moreParams: {
        customer_id: this.$store.state.auth.customerId,
      },
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-table': IconTable,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'Managers') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 1000);
      }
    },
  },

  computed: {
    ...mapState(['modal']),
    apiUrl() {
      if (API_GET_MANAGERS) {
        return `${API_GET_MANAGERS}`;
      }
      return null;
    },

    fields() {
      return [
        {
          name: 'icon',
          title: '',
          width: '5%',
        },
        {
          name: 'first_name',
          title: this.$t('first_name'),
          sortField: 'first_name',
          width: '15%',
        },
        {
          name: 'last_name',
          title: this.$t('last_name'),
          sortField: 'last_name',
          width: '15%',
        },
        {
          name: 'email',
          title: this.$t('email'),
          sortField: 'email',
          width: '50%',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '15%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        }];
    },

    userSubNavigation() {
      return [
        {
          text: this.$t('users'),
          url: '/users',
        },
        {
          text: this.$t('groups'),
          url: '/groups',
        },
        {
          text: this.$t('managers'),
          url: '/managers',
        },
        {
          text: this.$t('buddies'),
          url: '/buddies',
        },
      ];
    },
  },

  methods: {

  },
};
</script>
