import Vue from 'vue';
import axios from 'axios';
import { API_BASE_URL } from '@/helpers/config';

const API = axios.create({
  baseURL: API_BASE_URL,
  timeout: 300000,
});

API.interceptors.request.use(async (config) => {
  const $config = config;
  const $token = Vue.prototype.$store.state.auth.token;

  if ($token) {
    $config.headers.Authorization = `Bearer ${$token}`;
    $config.headers.common.Authorization = `Bearer ${$token}`;
  }
  return $config;
}, error => Promise.reject(error));

API.interceptors.response.use(
  async response => response,
  (error) => {
    if (error && error.response && error.response.status === 403) {
      localStorage.removeItem(process.env.VUE_APP_STORE_PREFIX);
      Vue.prototype.$store.dispatch('auth/reset');
      localStorage.setItem('hasAuthIssue', true);
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export default API;
