<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{'is-loading': formSending }"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ $t('groupname', {name: groupName}) }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="tab-content">
              <div class="tab-pane">
                <div class="form-group">
                  <label
                    for="journey"
                    class="form-label"
                  >{{ $t('which_journey_remove') }}</label>
                  <vue-tags-input
                    :class="{ 'is-danger': errors.has('remove.journey') }"
                    v-model="journey"
                    :tags="journeys"
                    @tags-changed="inputUpdateJourneys"
                    :autocomplete-items="journeysToRemove"
                    :add-only-from-autocomplete="true"
                    :delete-on-backspace="false"
                    :placeholder="$t('search_journey_placeholder')"
                  />
                  <input
                    type="hidden"
                    data-vv-scope="remove"
                    data-vv-name="journey"
                    :data-vv-as="$t('journeys')"
                    v-model="journeysSelected"
                    v-validate="'required'"
                  >
                  <span
                    v-if="errors.has('remove.journey')"
                    class="help is-danger"
                  >{{ errors.first('remove.journey') }}</span>
                </div>
                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t('error_msg_title') }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary no-wrap"
                    @click="removeJourney()"
                    :disabled="isPending"
                  >
                    <span
                      v-if="!isPending"
                    >
                      {{ $t('remove') }}
                    </span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vueTagsInput from '@johmun/vue-tags-input';
import moment from 'moment';
import API from '@/services/';
import {
  API_JOURNEY_USER_GROUPS,
  API_JOURNEY_FILTERED,
  API_SERVER_TIME_FORMAT,
} from '@/helpers/config';

export default {
  name: 'ModalJourneyRemoveGroup',
  inject: ['$validator'],

  data() {
    return {
      isPending: false,
      formSending: false,
      journeysToRemove: [],
      journey: '',
      journeys: [],
      debounce: null,
      journeysSelected: null,
    };
  },

  components: {
    vueTagsInput,
  },
  watch: {
    journey: 'getJourneys',
    journeys() {
      if (this.journeys && this.journeys.length > 0) {
        this.journeysSelected = this.journeys[0].text;
      } else {
        this.journeysSelected = null;
      }
      this.$nextTick(() => {
        this.$validator.validate('remove.journey', this.journeysSelected);
      });
    },
  },
  computed: {
    groupId() {
      return this.$store.state.modal.groupId;
    },
    groupName() {
      return this.$store.state.modal.groupName;
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
    dateFormat() {
      if (this.userLang === 'en') {
        return 'MM.DD.YYYY';
      }
      if (this.userLang === 'tr') {
        return 'DD.MM.YYYY';
      }
      return 'MM.DD.YYYY';
    },
  },

  methods: {

    closeModal() {
      this.$store.commit('modal/toggleModal');
    },

    inputUpdateJourneys(newTags) {
      this.journeys = newTags;
    },

    getJourneys() {
      this.journeysToRemove = [];

      const formData = { params: {} };
      formData.params.per_page = 10000;
      formData.params.search = this.journey;
      formData.params.customer_id = this.customerId;

      if (this.journey.length < 2) {
        return;
      }
      const searchInput = document.querySelector('.ti-new-tag-input');
      searchInput.classList.add('loading-icon');
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        API.get(API_JOURNEY_FILTERED, formData)
          .then((response) => {
            this.journeysToRemove = response.data.data.map(a => (
              {
                text: `${a.id}-${a.name}-[${moment(a.activation_date, API_SERVER_TIME_FORMAT).format(this.dateFormat)}]`,
                id: `${a.id}`,
              }
            ));
            searchInput.classList.remove('loading-icon');
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            searchInput.classList.remove('loading-icon');
          });
      }, 300);
    },

    removeJourney() {
      this.$validator.validate('remove.journey').then((result) => {
        if (result) {
          if (this.journeysToRemove) {
            this.formSending = true;
            this.isPending = true;
            const self = this;
            const successMsg = self.$t('removed_success');
            this.journeys.forEach((item) => {
              API.delete(API_JOURNEY_USER_GROUPS, {
                data: {
                  journey_id: item.id,
                  group_id: self.groupId,
                },
              })
                .then((response) => {
                  if (response.status === 200) {
                    self.$snotify.success(successMsg);
                    this.$store.commit('modal/REFRESH_PAGE', true);
                  }
                })
                .catch((error) => {
                  self.formSending = false;
                  self.isPending = false;
                  self.$helpers.displayError(error);
                });
            });
            if (self.isPending) {
              self.closeModal();
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check {
  .form-label {
    font-size: 0.9rem;
    margin-left: 1rem;
    margin-bottom: 0;
    color: #142b58;
  }
}
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}
.modal-body {
  h4 {
    color: #727d92;
  }
}
.blur {
  filter: blur(3px);
}
.no-wrap {
  white-space: nowrap;
}
</style>
