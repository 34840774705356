var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d: "m20.57 14.941-.826-.937a.087.087 0 0 1-.056.019H4v12.681H6.563c.018 0 .034.005.045.012a.051.051 0 0 1 .015.011.053.053 0 0 1-.015.012.094.094 0 0 1-.045.011h-5.25a.094.094 0 0 1-.046-.011.053.053 0 0 1-.015-.012.051.051 0 0 1 .015-.011.094.094 0 0 1 .045-.012H3.875V1.276a.045.045 0 0 1 .017-.015.094.094 0 0 1 .045-.011c.019 0 .034.005.046.011A.045.045 0 0 1 4 1.276v2.52h15.688c.026 0 .046.01.056.018l.827-.937m0 12.064-.827-.937a.034.034 0 0 0 .004-.004l-3.346-4.326-.592-.765.592-.765 3.346-4.326a.04.04 0 0 0-.004-.004l.827-.937m0 12.064a1.245 1.245 0 0 0 .167-1.705L17.39 8.91l3.347-4.327a1.245 1.245 0 0 0-.167-1.705m0 12.064a1.337 1.337 0 0 1-.884.332H5.25V25.454h1.313c.469 0 .902.243 1.136.637a1.24 1.24 0 0 1 0 1.273A1.32 1.32 0 0 1 6.563 28h-5.25a1.32 1.32 0 0 1-1.137-.636 1.24 1.24 0 0 1 0-1.273 1.32 1.32 0 0 1 1.136-.637h1.313V1.273c0-.455.25-.875.656-1.103a1.349 1.349 0 0 1 1.313 0c.406.228.656.648.656 1.103L6.5 2.545h13.188c.326 0 .641.119.883.332",
      stroke: "currentColor",
      "stroke-width": "2.5",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }