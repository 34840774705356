<template>
  <label
    :for="id"
    class="single-choice"
    :disabled="isReadonly"
    :class="{'selected': isSelected }"
  >
    <input
      :type="isMultiple ? 'checkbox': 'radio'"
      :value="option"
      :id="id"
      :disabled="isReadonly"
      :checked="isSelected ? true:false"
      @change="changed"
      :name="`radio-input-${qId}`"
    >
    {{ option }}
  </label>
</template>

<script>
export default {
  props: {
    option: {
      required: true,
      type: String,
    },
    id: {
      type: Number,
      required: true,
    },
    qId: {
      type: Number,
      required: true,
    },
    isSelected: {
      required: false,
      type: [Number, Boolean],
      default: 0,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changed($event) {
      this.$parent.$emit('input', $event.target.value);
      this.$emit('selected', $event.target.id);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  cursor: pointer;
}
input[type="radio"]{
    visibility:hidden;
}
</style>
