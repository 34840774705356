<template>
  <div class="page">
    <modal-add-more-to-journey-group v-if="modal.modalVisible && modal.modalType === 'modalAddMoreToJourneyGroup'" />
    <app-header
      :child-header-navigation="childHeaderNavigation"
      is-child-header-left-actions
    >
      <div class="button-search ml-4">
        <div class="search-box">
          <span
            v-if="filterText"
            class="icon icon-reset"
            @click="resetFilter"
          >
            <icon-container
              name="reset"
              view-box="0 0 20 20"
            >
              <icon-reset />
            </icon-container>
          </span>
          <span
            @click="doFilter()"
            class="icon-search"
          >
            <icon-container
              name="search"
              :width="14"
              :height="14"
              color="#727d92"
              view-box="0 0 14 14"
            >
              <icon-search />
            </icon-container>
          </span>
          <input
            type="text"
            ref="searchBox"
            class="form-control"
            v-sanitize
            :placeholder="$t('search_placeholder')"
            v-model="filterText"
            @keydown="doFilter()"
          >
        </div>
      </div>
      <template v-slot:right>
        <button
          class="button button--secondary text-size-xs size-sm button--icon"
          @click="addMoreToJourneygroup()"
        >
          <icon-container
            name="plusfull"
            view-box="0 0 448 512"
            :width="15"
            :height="16"
            color="#FFF"
            :is-icon-class="true"
          >
            <icon-plusfull />
          </icon-container>
          {{ $t('add_more_to_journey_group') }}
        </button>
      </template>
    </app-header>
    <main class="page__body">
      <!-- <div
        v-if="isLoading"
        class="lds-dual-ring"
      /> -->
      <div class="container-fluid container-cards mb-4">
        <div class="d-block mb-1 ml-4">
          <label
            class="h3 journey-title d-flex align-center"
          >
            <router-link
              class="mr-4"
              :to="{
                name: 'journeyGroup',
              }"
            >
              <icon-container
                :width="40"
                :height="18"
                name="Go Back"
                color="#727d92"
              >
                <icon-goback />
              </icon-container>
            </router-link>
            {{
              $t('journey_group')
            }}
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            {{ " : " }}
            {{
              $route.params.title
            }} </label>
        </div>
        <div class="row mx-4">
          <transition-group
            name="slide-in"
            tag="div"
            class="row margin-left-15"
            :style="{ '--total': journeyList ? journeyList.length : 0 }"
          >
            <div
              v-for="(item, index) in journeyList"
              :key="`${item.id}-${index}`"
              class="card card--catalog"
              :class="{ is_public: item.is_public }"
              :style="{ '--i': index }"
            >
              <div
                v-if="isItemNew(item.activation_date)"
                class="ribbon"
              >
                <span>{{ $t("new") }}</span>
              </div>
              <router-link
                :to="{
                  name: 'JourneyItems',
                  params: {
                    journeyId: item.id || item.options,
                    title: item.name,
                  },
                }"
              >
                <figure
                  class="card__media_home"
                  :style="{ backgroundImage: `url('${item.thumbnail_image}')` }"
                />
                <div class="card__body">
                  <h3
                    class="card__title"
                    :title="item.name"
                  >
                    {{ item.name | truncate }}
                  </h3>
                </div>
              </router-link>
              <div class="card__footer">
                <span
                  @click.prevent="modalAssign(item)"
                  class="button button--border button--border-light text-size-xs size-sm button--icon"
                  role="button"
                >
                  <span class="icon">
                    <icon-container
                      name="assign"
                      view-box="0 0 14 14"
                      :is-icon-class="false"
                    >
                      <icon-assign />
                    </icon-container>
                  </span>
                  {{ $t("assign") }}
                </span>
                <div
                  class="dropdown"
                  @mouseover="dropdownToggle(index, 'Private')"
                  @mouseleave="hideDropdown"
                >
                  <span class="card--actions dropdown__toggle">
                    <icon-container
                      name="threedotsvertical"
                      :width="5"
                      :height="22"
                      view-box="0 0 5 22"
                    >
                      <icon-threedotsvertical />
                    </icon-container>
                  </span>
                  <div
                    class="dropdown__menu"
                    :ref="`dropdownBox${index}Private`"
                    v-if="dropdownIndex === index + 'Private'"
                    v-on-clickaway="hideDropdown"
                  >
                    <span
                      class="dropdown__button button"
                      @click="createPacketCode(item.id)"
                    >
                      <icon-container
                        name="password"
                        :width="22"
                        :height="22"
                        view-box="0 0 32 32"
                        color="#727d92"
                      >
                        <icon-password />
                      </icon-container>
                      {{ $t("create_packet_code") }}
                    </span>
                    <span
                      class="dropdown__button button"
                      @click="requestAssignedUserReport(item)"
                    >
                      <icon-container
                        name="content"
                        view-box="0 0 18 17"
                        :is-icon-class="false"
                      >
                        <icon-dashboard />
                      </icon-container>
                      {{ $t("request_assigned_user_report") }}
                    </span>
                    <span
                      class="dropdown__button button"
                      @click="exportContentListReport"
                    >
                      <icon-container
                        name="content"
                        view-box="0 0 15 14"
                        :is-icon-class="false"
                      >
                        <icon-download />
                      </icon-container>
                      {{ $t("download_journey_content_report") }}
                    </span>
                    <span
                      class="dropdown__button button"
                      @click="editJourney(item.id, 'mainTab')"
                    >
                      <icon-container
                        name="setting"
                        view-box="0 0 15 15"
                      >
                        <icon-setting />
                      </icon-container>
                      {{ $t("settings") }}
                    </span>
                    <span
                      class="dropdown__button button"
                      @click="removeFromJourneyGroup(index, item)"
                      v-if="!canNotEdit(item.is_public)"
                    >
                      <icon-container
                        name="remove"
                        :width="16"
                        :height="16"
                        color="#727d92"
                        view-box="0 0 300 300"
                      >
                        <icon-remove />
                      </icon-container>
                      {{ $t("remove_from_journey_group") }}
                    </span>
                    <span
                      class="dropdown__button button"
                      @click="deleteJourney(index, item)"
                      v-if="!canNotEdit(item.is_public)"
                    >
                      <icon-container
                        name="trash"
                        view-box="0 0 24 24"
                      >
                        <icon-trash />
                      </icon-container>
                      {{ $t("delete") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
          <Observer
            v-if="!isLoading"
            @intersect="intersected"
          />
          <div
            v-if="isLoading"
            class="lds-dual-ring"
          />
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { directive as onClickaway } from 'vue-clickaway';
import IconContainer from '@/components/elements/Icon.vue';
import IconSetting from '@/components/icons/Setting.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconSearch from '@/components/icons/Search.vue';
import IconReset from '@/components/icons/Reset.vue';
import IconRemove from '@/components/icons/Remove.vue';
import IconAssign from '@/components/icons/Assign.vue';
import IconDashboard from '@/components/icons/Dashboard.vue';
import IconDownload from '@/components/icons/Download.vue';
import IconPassword from '@/components/icons/Password.vue';
import IconThreedotsVertical from '@/components/icons/ThreedotsVertical.vue';
import IconGoBack from '@/components/icons/GoBack.vue';
import AppHeader from '@/components/AppHeader.vue';
import ModalAddMoreToJourneyGroup from '@/container/modal/ModalAddMoreToJourneyGroup.vue';
import IconPlusFull from '@/components/icons/PlusFull.vue';
import Observer from '@/components/Observer.vue';

import API from '@/services/';
import {
  API_JOURNEY_GROUP_JOURNEYS,
  API_USER_GROUP_JOURNEY_REPORT,
  API_JOURNEY_UPDATE,
  API_POST_JOURNEY,
  API_JOURNEY_REPORT,
  API_SERVER_TIME_FORMAT,
} from '@/helpers/config';

export default {
  name: 'JourneyGroupJourneys',

  components: {
    'app-header': AppHeader,
    'icon-container': IconContainer,
    'icon-setting': IconSetting,
    'icon-trash': IconTrash,
    'icon-remove': IconRemove,
    'icon-reset': IconReset,
    'icon-download': IconDownload,
    'icon-search': IconSearch,
    'icon-goback': IconGoBack,
    'icon-password': IconPassword,
    'icon-plusfull': IconPlusFull,
    'icon-dashboard': IconDashboard,
    'icon-assign': IconAssign,
    'icon-threedotsvertical': IconThreedotsVertical,
    'modal-add-more-to-journey-group': ModalAddMoreToJourneyGroup,
    Observer,
  },

  data() {
    return {
      isDropdown: null,
      dropdownIndex: null,
      journeyName: 'all',
      journeyList: null,
      journeyTotalPage: null,
      currentPage: 1,
      perPage: 10,
      notificationTitle: '',
      notificationBody: '',
      timer: null,
      snotifyQueueList: [],
      filterText: '',
      searchFor: null,
      privateJourneyTabActive: true,
      publicJourneyTabActive: true,
      isLoading: false,
      idHolder: null,
      toastHolder: null,
      debounce: null,
    };
  },

  deactivated() {
    console.log('Journeygroupjourneys has been deactivated');
  },

  beforeDestroy() {
    console.log('Journeygroupjourneys has been before destroyed');
    this.journeyList = null;
    this.timer = null;
    this.snotifyQueueList = [];
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  filters: {
    truncate(input) {
      return input.length > 32 ? `${input.substring(0, 30)}...` : input;
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),

    childHeaderNavigation() {
      const pathsTemp = [
        {
          text: this.$t('catalog'),
          url: '/',
        },
        {
          text: this.$t('journey_group'),
          url: '/journeygroup',
        },
        {
          text: this.$t('planned_journey'),
          url: '/plannedjourney',
        },
      ];
      // if (this.isVibonsAdmin) {
      //   pathsTemp.push(
      //    {
      //      text: this.$t('planned_journey'),
      //      url: '/plannedjourney',
      //    },
      //  );
      // }
      return pathsTemp;
    },

    refreshPage: {
      get() {
        return this.$store.state.modal.refreshPage;
      },

      set(newValue) {
        this.$store.state.modal.refreshPage = newValue;
      },
    },

    pageToRefresh: {
      get() {
        return this.$store.state.modal.pageToRefresh;
      },

      set(newValue) {
        this.$store.state.modal.pageToRefresh = newValue;
      },
    },

    currentCustomerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },

    formData() {
      const postData = { params: {} };
      postData.params.per_page = 1000;
      postData.params.customer_id = this.currentCustomerId;
      if (this.searchFor) {
        postData.params.search = this.searchFor;
      }
      return postData;
    },
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'JourneyGroupJourneys') {
        this.getJourneys();
        this.refreshPage = false;
        this.pageToRefresh = null;
      }
    },

    currentCustomerId: {
      handler(newVal) {
        if (newVal) {
          this.getJourneys();
        }
      },
      immediate: true,
    },
  },

  methods: {
    hideDropdown() {
      this.isDropdown = null;
      this.timer = setTimeout(() => {
        this.dropdownIndex = null;
      }, 750);
    },

    isItemNew(itemDate) {
      const today = moment().startOf('day');
      const theDate = moment(itemDate, API_SERVER_TIME_FORMAT);
      const diffDays = today.diff(theDate, 'days', false);
      if (diffDays <= 15) {
        return true;
      }
      return false;
    },

    canNotEdit(item) {
      if (!item) {
        return false;
      }
      if (this.isVibonsAdmin) {
        return false;
      }
      return true;
    },

    dropdownAction(value) {
      this.isDropdown = value;
    },

    dropdownToggle(index, type) {
      clearTimeout(this.timer);
      this.dropdownIndex = index + type;
      setTimeout(() => {
        const elem = this.$refs[`dropdownBox${index}${type}`].length > 0
          ? this.$refs[`dropdownBox${index}${type}`][0]
          : null;
        if (elem) {
          const isOut = this.$helpers.isOutOfScreen(elem);
          if (isOut.right) {
            elem.classList.add('right');
          } else if (isOut.left) {
            elem.classList.add('left');
          } else if (isOut.bottom) {
            elem.classList.add('bottomjourney');
          } else if (isOut.top) {
            elem.classList.add('top');
          }
        }
      }, 1);
    },

    doFilter() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchFor = this.filterText;
        this.getJourneys();
      }, 300);
    },

    resetFilter() {
      this.searchFor = null;
      this.filterText = '';
      this.getJourneys();
    },
    createPacketCode(itemId) {
      const obj = {
        modalType: 'modalPackageCode',
        launchedFrom: 'Journey',
        journeyId: itemId,
      };
      this.$helpers.toggleModal(obj);
    },

    requestAssignedUserReport(data) {
      const itemId = data.id;
      const obj = {
        modalType: 'modalReportDownload',
        reportDownloadUrl: `${API_USER_GROUP_JOURNEY_REPORT}`,
        dateOnly: false,
        reportDownloadPayload: {
          customer_id: this.currentCustomerId,
          journey_id: itemId,
        },
      };
      this.$helpers.toggleModal(obj);
    },
    exportContentListReport(data) {
      const itemId = data.id;
      const obj = {
        modalType: 'modalReportDownload',
        reportDownloadUrl: `${API_JOURNEY_REPORT}/${itemId}`,
        reportDownloadRequest: 'get',
        dateOnly: false,
        reportDownloadPayload: {},
      };
      this.$helpers.toggleModal(obj);
    },
    updatedJourney(data) {
      const item = data;
      this.hideDropdown();
      const time = moment().format(API_SERVER_TIME_FORMAT);
      item.activation_date = time;

      const formData = new FormData();
      formData.append('journey_id', item.id); // eslint-disable-next-line
      formData.append("name", `${item.name} copy`);
      formData.append('activation_date', item.activation_date);
      formData.append('lang', item.lang);
      formData.append('is_list_on_the_catalog_enabled', 1);
      formData.append('is_welcome_notifications_enabled', 0);

      API.post(API_JOURNEY_UPDATE, formData)
        .then((response) => {
          if (response.status === 200) {
            if (item.name.search('copy') !== -1 || item.name.search('copy') === -1) {
              // eslint-disable-next-line
              item.name = `${item.name} copy`;
            }
            const newItem = {
              id: item.id,
              name: item.name,
              content_count: item.total_content_count,
              total_content_count: item.total_content_count,
              thumbnail_image: item.template_thumbnail_image,
              is_public: item.is_public,
              activation_date: item.activation_date,
            };

            this.journeyList.unshift(newItem);
            window.scrollTo(0, 0);
            this.$snotify.success(response.data.message);
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },
    removeFromJourneyGroup(index, item) {
      const { id } = item;
      this.idHolder = id;
      this.hideDropdown();
      if (!this.snotifyQueueList.includes(id)) {
        this.snotifyQueueList.push(id);
        this.$snotify
          .confirm(
            this.$t('journey_group_remove_msg_body', { name: item.name }),
            this.$t('journey_delete_msg_title'),
            {
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              buttons: [
                {
                  text: this.$t('yes'),
                  action: (toast) => {
                    this.removeConfirm(toast, id);
                  },
                  bold: false,
                },
                {
                  text: this.$t('no'),
                  action: (toast) => {
                    this.deleteCancel(toast, id);
                  },
                },
              ],
            },
          )
          .on('shown', (toast) => {
            this.toastHolder = toast;
            document.addEventListener('keydown', this.eventHandler, false);
          })
          .on('hidden', (toast) => {
            this.toastHolder = toast;
            document.removeEventListener('keydown', this.eventHandler, false);
          });
      }
    },
    deleteJourney(index, item) {
      const { id } = item;
      this.idHolder = id;
      this.hideDropdown();
      if (!this.snotifyQueueList.includes(id)) {
        this.snotifyQueueList.push(id);
        this.$snotify
          .confirm(
            this.$t('journey_delete_msg_body', { name: item.name }),
            this.$t('journey_delete_msg_title'),
            {
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              buttons: [
                {
                  text: this.$t('yes'),
                  action: (toast) => {
                    this.deleteConfirm(toast, id);
                  },
                  bold: false,
                },
                {
                  text: this.$t('no'),
                  action: (toast) => {
                    this.deleteCancel(toast, id);
                  },
                },
              ],
            },
          )
          .on('shown', (toast) => {
            this.toastHolder = toast;
            document.addEventListener('keydown', this.eventHandler, false);
          })
          .on('hidden', (toast) => {
            this.toastHolder = toast;
            document.removeEventListener('keydown', this.eventHandler, false);
          });
      }
    },
    intersected() {
      if (!this.isLoading && this.journeyTotalPage) {
        const nextPage = this.currentPage + 1;
        const p = this.journeyTotalPage / this.perPage || 1;
        if (nextPage <= Math.ceil(p)) {
          this.getJourneys(nextPage);
        }
        console.log('intersected nextPage:', nextPage, 'this.journeyTotalPage:', this.journeyTotalPage);
      }
    },
    getJourneys(page = 1) {
      if (this.currentCustomerId) {
        this.currentPage = page;
        if (page === 1) {
          this.journeyList = null;
        }
        this.isLoading = true;
        API.get(`${API_JOURNEY_GROUP_JOURNEYS}/${this.$route.params.journeyGroupId}?per_page=${this.perPage}&page=${page}`)
          .then((response) => {
            if (response.status === 200) {
              if (page === 1) {
                this.journeyList = response.data.data;
                this.journeyTotalPage = response.data.meta.total;
              } else {
                this.journeyList = this.journeyList.concat(response.data.data);
                this.journeyTotalPage = response.data.meta.total;
              }
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            this.isLoading = false;
          });
      }
    },

    addMoreToJourneygroup() {
      const obj = {
        modalType: 'modalAddMoreToJourneyGroup',
        journeyGroupId: this.$route.params.journeyGroupId,
        pageToRefresh: 'JourneyGroupJourneys',
      };
      this.$helpers.toggleModal(obj);
    },

    editJourney(itemid, tabPane = 'mainTab') {
      if (itemid) {
        const obj = {
          modalType: 'modalJourney',
          journeyId: itemid,
          launchedFrom: 'Journey',
          pageToRefresh: 'Journey',
          tabPane,
          editMode: true,
        };
        this.$helpers.toggleModal(obj);
      }
    },

    modalAssign(item) {
      const id = item.id || item.options;
      let modalType = 'modalAssign';
      if (
        item
        && item.journey_type
        && (item.journey_type === 2 || item.journey_type === 'ON_BOARDING')
      ) {
        modalType = 'modalOnboardingAssign';
      }
      console.log('Assigning journey item info:', item, id, modalType);
      this.$store.commit('modal/setJourneyId', id);
      this.$helpers.toggleModal(modalType);
    },

    removeConfirm(toast, id) {
      this.$snotify.remove(toast.id);
      const snotifyIndex = this.snotifyQueueList.indexOf(id);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }

      API.delete(`${API_JOURNEY_GROUP_JOURNEYS}`, {
        data: {
          journey_id: id,
          group_id: this.$route.params.journeyGroupId,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.$snotify.success(response.data.message);
            const elementPos = this.journeyList.map(elm => elm.id).indexOf(id);
            this.journeyList.splice(elementPos, 1);
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    deleteConfirm(toast, id) {
      this.$snotify.remove(toast.id);
      const snotifyIndex = this.snotifyQueueList.indexOf(id);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      API.delete(`${API_POST_JOURNEY}/${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.$snotify.success(response.data.message);
            const elementPos = this.journeyList.map(elm => elm.id).indexOf(id);
            this.journeyList.splice(elementPos, 1);
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    deleteCancel(toast, id) {
      const snotifyIndex = this.snotifyQueueList.indexOf(id);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.$snotify.remove(toast.id);
    },

    eventHandler(event) {
      event.preventDefault();
      const key = event.key || event.keyCode;
      if (key === 'Enter' || key === 13 || key === 'Y' || key === 'E') {
        this.deleteConfirm(this.toastHolder, this.idHolder);
      } else if (key === 'Escape' || key === 'Esc' || key === 27 || key === 'N' || key === 'H') {
        this.deleteCancel(this.toastHolder, this.idHolder);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card__footer span.button:hover {
  cursor: pointer !important;
}

.filter-active {
  background-color: #2dcf85;
  svg path {
    fill: #fff;
  }
}
.button-search {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .search-box {
    position: relative;
    width: 100%;
    input {
      background: #ffffff;
      padding-left: 20px;
      padding-right: 40px;
      height: 36px;
      color: #333;
      min-width: 205px;
      max-width: 300px;
    }
  }
  .icon-search {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
  }
  .icon-reset {
    position: absolute;
    left: 5px;
    top: 15px;
    cursor: pointer;

    svg {
      width: 13px;
      height: 13px;
    }
  }
}
.journey-title {
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  color: #727d92;
  padding: 10px 0;
  margin-bottom: 10px;
}

.collapse {
  margin-bottom: 2px;
  label {
    cursor: pointer;
  }
}
.collapse .collapse-header {
  margin-bottom: 10px;
  position: relative;
}

.collapse .collapse-header::before {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23727d92' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
  display: inline-block;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 0.8em;
  padding-right: 3px;
}
.collapse.is-active .collapse-header::before {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.margin-left-15 {
  margin-left: -15px;
}
</style>
