var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c(
                "h5",
                {
                  staticClass: "modal-title d-inline-block text-truncate",
                  staticStyle: { "max-width": "380px" },
                },
                [_vm._v(" " + _vm._s(_vm.journeyItemDetail.name) + " ")]
              ),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c("nav", { staticClass: "navigation navigation--secondary" }, [
              _c("div", { staticClass: "container" }, [
                _c(
                  "ul",
                  {
                    staticClass: "navigation__list nav",
                    attrs: { id: "contentCreateTab", role: "tablist" },
                  },
                  [
                    _c("li", { staticClass: "navigation__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: { active: _vm.tabPane === "statisticsTab" },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("statisticsTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("statistics")) + " ")]
                      ),
                    ]),
                    _c("li", { staticClass: "navigation__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: {
                            active: _vm.tabPane === "usersCompletedTab",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("usersCompletedTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("users_completed")) + " ")]
                      ),
                    ]),
                    _c("li", { staticClass: "navigation__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: {
                            active: _vm.tabPane === "downloadReportTab",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("downloadReportTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("download_report")) + " ")]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "tab-content" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabPane === "statisticsTab",
                        expression: "tabPane === 'statisticsTab'",
                      },
                    ],
                    staticClass: "tab-pane",
                  },
                  [
                    _c("div", { staticClass: "form form--horizontal" }, [
                      _c(
                        "div",
                        { staticClass: "form-group form-group--cards" },
                        [
                          _c(
                            "figure",
                            { staticClass: "highcharts-figure" },
                            [
                              _c("highcharts", {
                                ref: "completionGauge",
                                staticClass: "chart-container",
                                attrs: { options: _vm.completionChartOptions },
                              }),
                              _c("highcharts", {
                                ref: "scoreGauge",
                                staticClass: "chart-container",
                                attrs: { options: _vm.scoreChartOptions },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button button--primary",
                          attrs: { type: "button" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("close")) + " "),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm.tabPane === "usersCompletedTab"
                  ? _c("div", { staticClass: "tab-pane" }, [
                      _c("div", { staticClass: "form form--horizontal" }, [
                        _c(
                          "div",
                          { staticClass: "form-group form-group--cards" },
                          [
                            _c("div", { staticClass: "top-group" }, [
                              _c(
                                "div",
                                [
                                  _c("filter-perpage", {
                                    on: {
                                      searchFor: _vm.setSearchFor,
                                      perPage: _vm.setPerPage,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "container" }, [
                              _c(
                                "div",
                                { staticClass: "table-responsive" },
                                [
                                  _vm.tableIsLoading
                                    ? _c("div", {
                                        staticClass: "lds-dual-ring",
                                      })
                                    : _vm._e(),
                                  _c("vue-table", {
                                    ref: "myvuetable",
                                    class: { "table-fade": _vm.tableIsLoading },
                                    attrs: {
                                      "api-url": _vm.apiUrl,
                                      "http-fetch": _vm.myFetch,
                                      "api-mode": true,
                                      "pagination-path": "meta",
                                      "http-method": "get",
                                      "append-params": _vm.moreParams,
                                      "http-options": {
                                        headers: {
                                          Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                                        },
                                      },
                                      fields: _vm.fields,
                                      "no-data-template":
                                        _vm.$t("no_data_info"),
                                      css: _vm.css.table,
                                      "per-page": _vm.perPage,
                                      "query-params": _vm.makeQueryParams,
                                    },
                                    on: {
                                      "vuetable:loading": function ($event) {
                                        _vm.tableIsLoading = true
                                      },
                                      "vuetable:loaded": function ($event) {
                                        _vm.tableIsLoading = false
                                      },
                                      "vuetable:load-error":
                                        _vm.vuetableLoadError,
                                      "vuetable:pagination-data":
                                        _vm.onPaginationData,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "completion_date",
                                          fn: function (props) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getCurrentLocaleDate(
                                                      props.rowData
                                                        .completion_date
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "actions",
                                          fn: function (props) {
                                            return [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "ui button delete",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getUserQuizResult(
                                                        props.rowData.user_id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "icon-container",
                                                    {
                                                      attrs: {
                                                        name: "search",
                                                        width: 14,
                                                        height: 14,
                                                        color: "#727d92",
                                                        "view-box": "0 0 14 14",
                                                        "is-icon-class": false,
                                                      },
                                                    },
                                                    [_c("icon-search")],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4134953875
                                    ),
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "pagination__info" },
                                    [
                                      _c("vuetable-pagination-info", {
                                        ref: "paginationInfo",
                                        class: {
                                          "table-fade": _vm.tableIsLoading,
                                        },
                                        attrs: {
                                          "info-template":
                                            _vm.$t("pagination_info"),
                                          "no-data-template":
                                            _vm.$t("no_data_info"),
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "pagination__items" },
                                        [
                                          _c("vuetable-pagination", {
                                            ref: "pagination",
                                            attrs: { css: _vm.css.pagination },
                                            on: {
                                              "vuetable-pagination:change-page":
                                                _vm.onChangePage,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: { type: "button" },
                            on: { click: _vm.closeModal },
                          },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("close")) + " "),
                            ]),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.tabPane === "downloadReportTab"
                  ? _c("div", { staticClass: "tab-pane" }, [
                      _c("div", { staticClass: "form form--horizontal" }, [
                        _c(
                          "div",
                          { staticClass: "form-group form-group--cards" },
                          [
                            _c("div", { staticClass: "row form-label" }, [
                              _c("div", { staticClass: "col-6 my-4" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.currentReport,
                                      expression: "currentReport",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    value: "generalquiz",
                                    id: "generalquiz",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.currentReport,
                                      "generalquiz"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.currentReport = "generalquiz"
                                    },
                                  },
                                }),
                                _c("label", {
                                  staticClass: "ml-3",
                                  attrs: { for: "generalquiz" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t("general_quiz_report")
                                    ),
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "col-6 my-4" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.currentReport,
                                      expression: "currentReport",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    value: "quizdetail",
                                    id: "quizdetail",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.currentReport,
                                      "quizdetail"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.currentReport = "quizdetail"
                                    },
                                  },
                                }),
                                _c("label", {
                                  staticClass: "ml-3",
                                  attrs: { for: "quizdetail" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t("quiz_detail_report")
                                    ),
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "col-6 mb-4" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.currentUsers,
                                      expression: "currentUsers",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    value: "onlyjourneyitemusers",
                                    id: "onlyjourneyitemusers",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.currentUsers,
                                      "onlyjourneyitemusers"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.currentUsers = "onlyjourneyitemusers"
                                    },
                                  },
                                }),
                                _c("label", {
                                  staticClass: "ml-3",
                                  attrs: { for: "onlyjourneyitemusers" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t("only_item_users")
                                    ),
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "col-6 mb-4" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.currentUsers,
                                      expression: "currentUsers",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    value: "allfirmusers",
                                    id: "allfirmusers",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.currentUsers,
                                      "allfirmusers"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.currentUsers = "allfirmusers"
                                    },
                                  },
                                }),
                                _c("label", {
                                  staticClass: "ml-3",
                                  attrs: { for: "allfirmusers" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.$t("all_firm_users")),
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "col-6 mb-4" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.isFirstResult,
                                      expression: "isFirstResult",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    id: "isFirstResult1",
                                  },
                                  domProps: {
                                    value: 1,
                                    checked: _vm._q(_vm.isFirstResult, 1),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.isFirstResult = 1
                                    },
                                  },
                                }),
                                _c("label", {
                                  staticClass: "ml-3",
                                  attrs: { for: "isFirstResult1" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t("is_first_result_1")
                                    ),
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "col-6 mb-4" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.isFirstResult,
                                      expression: "isFirstResult",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    id: "isFirstResult0",
                                  },
                                  domProps: {
                                    value: 0,
                                    checked: _vm._q(_vm.isFirstResult, 0),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.isFirstResult = 0
                                    },
                                  },
                                }),
                                _c("label", {
                                  staticClass: "ml-3",
                                  attrs: { for: "isFirstResult0" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t("is_first_result_0")
                                    ),
                                  },
                                }),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: { type: "button" },
                            on: { click: _vm.downloadReport },
                          },
                          [
                            !_vm.isPending
                              ? _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("download")) + " "
                                  ),
                                ])
                              : _vm._e(),
                            _vm.isPending
                              ? _c("div", { staticClass: "lds-ellipsis" }, [
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "modal__mobile-preview",
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "flag" },
              [
                _c(
                  "icon-container",
                  {
                    attrs: {
                      name: "flag",
                      width: 224,
                      height: 21,
                      "view-box": "0 0 224 21",
                      "is-icon-class": false,
                    },
                  },
                  [_c("icon-flag")],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "mobile-content bluered-bg" }, [
              _vm.isLoading
                ? _c("div", { staticClass: "lds-dual-ring-mobile_preview" })
                : _vm._e(),
              _vm.questions && _vm.questions.length
                ? _c("div", { staticClass: "mobile-content__quiz" }, [
                    _c(
                      "div",
                      { staticClass: "quiz-preview--container" },
                      [
                        _vm._l(
                          _vm.questions,
                          function (questionPreview, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "quiz-preview--question",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "quiz-preview--progress" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.questions
                                            ? parseInt(
                                                (100 / _vm.questions.length) *
                                                  (index + 1),
                                                10
                                              ) +
                                                "% " +
                                                _vm.$t("completed_")
                                            : ""
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "quiz-progress-bar" },
                                      [
                                        _c("span", {
                                          style: {
                                            width:
                                              (100 / _vm.questions.length) *
                                                (index + 1) +
                                              "%",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("h5", {
                                  domProps: {
                                    innerHTML: _vm._s(questionPreview.question),
                                  },
                                }),
                                questionPreview.type === "standard" &&
                                !questionPreview.choices[0].image
                                  ? _c(
                                      "ul",
                                      _vm._l(
                                        questionPreview.choices,
                                        function (choice, scindex) {
                                          return _c("li", { key: scindex }, [
                                            choice.choice_text
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "d-flex p-2",
                                                    class: {
                                                      "is-correct":
                                                        choice.is_correct == 1,
                                                      "is-wrong":
                                                        choice.is_selected &&
                                                        !choice.is_correct,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "optiontick",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$helpers.choiceLetter(
                                                                scindex
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          choice.choice_text
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                questionPreview.type === "standard" &&
                                questionPreview.choices[0].image
                                  ? _c(
                                      "ul",
                                      { staticClass: "row" },
                                      _vm._l(
                                        questionPreview.choices,
                                        function (choice, ccindex) {
                                          return _c(
                                            "li",
                                            {
                                              key: ccindex,
                                              staticClass: "col-6 px-1",
                                            },
                                            [
                                              choice.image
                                                ? _c(
                                                    "picture",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-column",
                                                      class: {
                                                        "is-correct":
                                                          choice.is_correct ==
                                                          1,
                                                        "is-wrong":
                                                          choice.is_selected &&
                                                          !choice.is_correct,
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: choice.image,
                                                          alt: "Choice Image",
                                                        },
                                                      }),
                                                      choice.choice_text
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex p-2",
                                                              staticStyle: {
                                                                "mix-blend-mode":
                                                                  "difference",
                                                                "font-size":
                                                                  "11px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    choice.choice_text
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                questionPreview.type === "pairs"
                                  ? _c(
                                      "ul",
                                      {
                                        staticClass: "row",
                                        attrs: { id: "pair-choices" },
                                      },
                                      _vm._l(
                                        questionPreview.choices,
                                        function (choice, cindex) {
                                          return _c("li", { key: cindex }, [
                                            choice.choice_text
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        choice.choice_text
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "quiz-preview--button" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "button button--secondary size-sm",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.previewQuestionNext(
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("next")) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _c("div", { staticClass: "quiz-preview--question" }, [
                          _c("div", { staticClass: "quiz-finalpage" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex final-score flex-column justify-content-center",
                              },
                              [
                                _c("span", { staticClass: "score-point" }, [
                                  _vm._v(_vm._s(_vm.$t("score_point"))),
                                ]),
                                _c("div", { staticClass: "your-score" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("your_score")) + " "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "completed-text text-center mt-4",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("quiz_completed")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center text-center mt-4",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("thanks_feedback")) + " "
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "quiz-preview--button" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button button--secondary size-sm",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.previewQuestionNext(-1)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }