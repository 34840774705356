var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "none" } }, [
    _c("path", {
      attrs: {
        fill: "#fff",
        stroke: "#727D92",
        d: "M33.62 18.6L36.64 9l2.74 9.6H49l-7.7 5.8 2.75 9.6-7.41-5.81-7.7 5.81 2.75-9.6L24 18.6h9.62z",
      },
    }),
    _c("rect", {
      attrs: {
        width: "74",
        height: "43",
        x: ".5",
        y: ".5",
        stroke: "#727D92",
        "stroke-opacity": ".5",
        rx: "5.5",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }