var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      fill: "#currentColor",
      "fill-rule": "evenodd",
      d: "M.98432618 8.40069903c-.06157466.06030769-.11656109.12695022-.1641991.19891402-.0078552.01241629-.01292307.02584616-.02052488.03800905-.03395476.05549322-.06613575.112-.09096833.17281448-.01292308.03142082-.0200181.06461539-.03015385.09704978-.01368326.04333031-.02990045.08564706-.03902262.13100452-.01520362.07829864-.02393163.15735747-.02393163.23666969v.00684162c0 .07956562.008728.15887783.02393163.23717648.00912217.04535746.02533936.0876742.03902262.13075113.01013575.03243439.01723077.06562896.03015385.09755656.02483258.06056108.05701357.11681448.09071493.1720543.00760181.01266968.01266968.02609954.02103168.03851583.04738461.0719638.10237104.13860634.1639457.19891403l7.09907692 7.09704977c.48144796.48195476 1.26139366.48195476 1.74106787 0 .24072398-.24072398.36133937-.55442533.36133937-.87040724 0-.3157285-.12061539-.62993665-.36133937-.87066063l-5.0037647-5.00224434H39.1611588c.6811221 0 1.2317466-.55087783 1.2317466-1.23174661 0-.68112217-.5506245-1.232-1.2317466-1.232H4.82070627l5.0037647-5.00224437c.24072398-.240724.36133937-.5546787.36133937-.8704072 0-.3159819-.12061539-.6299367-.36133937-.8706606-.47967421-.481448-1.25961991-.481448-1.74106787 0L.98432618 8.40069903z",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }