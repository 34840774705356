<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{ 'is-loading': formSending }"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ editMode ? $t("edit_communication") : $t("add_new_communication") }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="tab-content">
              <div
                class="tab-pane"
                v-if="tabPane === 'mainTab'"
              >
                <div
                  class="form-group scrollBodyContent"
                >
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="name"
                          class="form-label required"
                        >{{ $t("title_") }}</label>
                        <input
                          :class="{ 'is-danger': errors.has('communication.title') }"
                          type="text"
                          class="form-control"
                          id="title"
                          v-model="title"
                          data-vv-scope="communication"
                          data-vv-name="title"
                          :data-vv-as="$t('title_')"
                          :placeholder="$t('title_placeholder_')"
                          v-validate="'required|min:10|max:500'"
                        >
                        <span
                          v-if="errors.has('communication.title')"
                          class="help is-danger"
                        >{{
                          errors.first("communication.title")
                        }}</span>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="cbody"
                          class="form-label"
                        >
                          {{ $t('body') }}
                        </label>
                        <vue-editor
                          id="cbody"
                          :editor-toolbar="customToolbar"
                          v-model="body"
                          data-vv-name="body"
                          data-vv-scope="communication"
                          :data-vv-as="$t('body')"
                          v-validate="'max:1000'"
                        />
                        <span
                          v-if="errors.has('communication.body')"
                          class="help is-danger"
                        >{{ errors.first('communication.body') }}</span>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="mail_body"
                          class="form-label"
                        >
                          {{ $t('mail_body') }}
                        </label>
                        <textarea
                          :class="{ 'is-danger': errors.has('communication.mail_body') }"
                          id="mail_body"
                          class="form-control"
                          v-model="mail_body"
                          data-vv-name="mail_body"
                          data-vv-scope="communication"
                          :data-vv-as="$t('mail_body')"
                          v-validate="'max:500'"
                        />
                        <span
                          v-if="errors.has('communication.mail_body')"
                          class="help is-danger"
                        >{{ errors.first('communication.mail_body') }}</span>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="push_body"
                          class="form-label"
                        >
                          {{ $t('push_body') }}
                        </label>
                        <textarea
                          :class="{ 'is-danger': errors.has('communication.push_body') }"
                          id="push_body"
                          class="form-control"
                          v-model="push_body"
                          data-vv-name="push_body"
                          data-vv-scope="communication"
                          :data-vv-as="$t('push_body')"
                          v-validate="'max:500'"
                        />
                        <span
                          v-if="errors.has('communication.push_body')"
                          class="help is-danger"
                        >{{ errors.first('communication.push_body') }}</span>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label
                          for="files"
                          class="form-label"
                        >
                          {{ $t('upload_images') }}
                        </label>
                        <vue-upload-multiple-image
                          id="imageuploading"
                          ref="dragdropbox"
                          :drop-text="$t('drag_drop')"
                          :drag-text="$t('drag_drop')"
                          :browse-text="$t('or_select')"
                          :primary-text="''"
                          :popup-text="''"
                          :mark-is-primary-text="''"
                          :show-primary="false"
                          :show-edit="false"
                          :max-image="10"
                          @upload-success="uploadImageSuccess"
                          @before-remove="beforeRemove"
                          @limit-exceeded="limitExceededImage"
                          :data-images="images"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label
                              for="expire_date"
                              class="form-label"
                            >{{ $t('expire_date') }}</label>
                            <date-picker
                              :class="{ 'is-danger': errors.has('communication.expire_date') }"
                              v-model="expire_date"
                              type="date"
                              id="expire_date"
                              value-type="YYYY-MM-DD 00:00:00"
                              :first-day-of-week="userLang === 'en' ? 7 : 1"
                              :lang="userLang"
                              :format="userLang === 'en' ? 'MM.DD.YYYY' : 'DD.MM.YYYY'"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <div class="swicth-checkbox">
                              <input
                                type="checkbox"
                                v-model="is_active"
                                id="is_active"
                                :true-value="1"
                                :false-value="0"
                              >
                              <label
                                class="swicth-label"
                                for="is_active"
                              >{{
                                $t("is_active")
                              }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t("error_msg_title") }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary"
                    @click="
                      editMode
                        ? processCommunication('UPDATE_COMMUNICATION')
                        : processCommunication('CREATE_COMMUNICATION')
                    "
                    :disabled="isPending"
                  >
                    <span v-if="!isPending">{{ editMode ? $t("update_button") : $t("add") }}</span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import { VueEditor } from 'vue2-editor';
import VueUploadMultipleImage from 'vue-upload-multiple-image';

import API from '@/services/';
import {
  API_SERVER_TIME_FORMAT,
  API_USER_GROUP_DATATABLES,
  API_USER_DATATABLES,
} from '@/helpers/config';

export default {
  name: 'ModalAddCommunication',
  inject: ['$validator'],

  data() {
    return {
      communicationIdToEdit: null,
      title: '',
      body: '',
      mail_body: '',
      push_body: '',
      is_active: 1,
      files: [],
      images: [],
      expire_date: null,
      user: '',
      users: [],
      debounce: null,
      usersSelected: null,
      listUsers: [],
      isLoadingUsers: false,
      listGroups: [],
      group: '',
      groups: [],
      groupsSelected: null,
      isPending: false,
      formSending: false,
      tabPane: 'mainTab',
      user_group_recipients: 0,
      user_recipients: 0,
      customer_id: this.$store.state.auth.customerId,
      remove_files: [],
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean'],
      ],
    };
  },

  components: {
    'date-picker': DatePicker,
    VueEditor,
    VueUploadMultipleImage,
  },

  computed: {
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
    customerId() {
      return this.$store.state.auth.customerId;
    },
    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },
    editMode() {
      return this.$store.state.modal.editMode;
    },
    modalData() {
      return this.$store.state.modal.modalData;
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
    multiProcessProgressBar: {
      get() {
        return this.$store.state.modal.multiProcessProgressBar;
      },
      set(newValue) {
        this.$store.state.modal.multiProcessProgressBar = newValue;
      },
    },
  },

  watch: {
    user: 'getUsers',
    group: 'getGroups',
    customerId: {
      handler(val) {
        if (val) {
          this.customer_id = val;
        }
      },
      immediate: true,
    },
    async modalData(newValue) {
      const $newValue = newValue;
      if (this.editMode && $newValue) {
        this.title = $newValue.title;
        this.body = $newValue.body;
        this.push_body = $newValue.push_body;
        this.mail_body = $newValue.mail_body;
        this.is_active = $newValue.is_active;
        if ($newValue.expire_date) {
          this.expire_date = moment($newValue.expire_date).format(API_SERVER_TIME_FORMAT);
        }
        if ($newValue.file_urls) {
          this.images = $newValue.file_urls.map((f, i) => (
            {
              path: `${f}`,
              name: $newValue.files[i],
            }));
        }
        if ($newValue.data) {
          if ($newValue.data.user_group_recipients) {
            this.user_group_recipients = $newValue.data.user_group_recipients.length;
          }
          if ($newValue.data.user_recipients) {
            this.user_recipients = $newValue.data.user_recipients.length;
          }
        }
        this.multiProcessProgressBar = false;
      }
    },
  },

  mounted() {
    if (this.customerId) {
      this.customer_id = this.customerId;
    }
    if (this.customerId && this.editMode) {
      this.communicationIdToEdit = this.$store.state.modal.communicationIdToEdit;
      this.putInEditMode();
      this.multiProcessProgressBar = true;
    }
    const dictEN = {
      custom: {
        body: {
          max: 'Your message content is too long.',
        },
      },
    };
    const dictTR = {
      custom: {
        body: {
          max: 'Mesaj içeriğiniz fazla uzun.',
        },
      },
    };

    this.$validator.localize('en', dictEN);
    this.$validator.localize('tr', dictTR);
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/toggleModal');
    },
    uploadImageSuccess(formData, index, fileList) {
      console.log('data', formData, index, fileList);
      console.log(formData.get('file'));
      const file = formData.get('file');
      if (file.type.includes('image')) {
        this.files.push(file);
      } else {
        this.$refs.dragdropbox.images.splice(index, 1);
      }
    },
    beforeRemove(index, done, fileList) {
      console.log('index', index, fileList);
      this.$snotify.confirm(this.$t('image_delete_msg_body'), this.$t('image_delete_msg_title'), {
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        buttons: [
          {
            text: this.$t('yes'),
            action: (toast) => {
              if (this.editMode) {
                this.remove_files.push(fileList[index]);
                this.files.splice(index, 1);
              } else {
                this.files.splice(index, 1);
              }
              done();
              this.$snotify.remove(toast.id);
            },
            bold: false,
          },
          {
            text: this.$t('no'),
            action: (toast) => {
              console.log('denied');
              this.$snotify.remove(toast.id);
            },
          },
        ],
      });
    },
    limitExceededImage(amount) {
      console.log('limitExceededImage', amount);
    },
    inputUpdateUsers(newTags) {
      this.users = newTags;
    },
    inputUpdateGroups(newTags) {
      this.groups = newTags;
    },
    getUsers() {
      this.isLoadingUsers = true;
      this.listUsers = [];
      const userFormData = { params: {} };
      userFormData.params.per_page = 10000;
      userFormData.params.customer_id = this.$store.state.auth.customerId;
      userFormData.params.search = this.user;

      if (this.user.length < 2) {
        return;
      }
      const searchInput = document.querySelector('.taguser .ti-new-tag-input');
      searchInput.classList.add('loading-icon');
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        API.get(API_USER_DATATABLES, userFormData)
          .then((response) => {
            this.listUsers = response.data.data.map(a => (
              {
                text: `${a.first_name} ${a.last_name} [ ${a.email} ]`,
                id: `${a.id}`,
              }
            ));
            this.isLoadingUsers = false;
            searchInput.classList.remove('loading-icon');
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            searchInput.classList.remove('loading-icon');
          });
      }, 300);
    },

    getGroups() {
      this.listGroups = [];

      const formData = { params: {} };
      formData.params.per_page = 10000;
      formData.params.search = this.group;
      formData.params.customer_id = this.$store.state.auth.customerId;

      if (this.group.length < 2) {
        return;
      }
      const searchInput = document.querySelector('.taggroup .ti-new-tag-input');
      searchInput.classList.add('loading-icon');
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        API.get(API_USER_GROUP_DATATABLES, formData)
          .then((response) => {
            this.listGroups = response.data.data.map(a => (
              {
                text: `${a.name}`,
                id: `${a.id}`,
              }
            ));
            searchInput.classList.remove('loading-icon');
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            searchInput.classList.remove('loading-icon');
          });
      }, 300);
    },

    deleteUsers(deleteTag) {
      if (!this.editMode) {
        deleteTag.deleteTag(deleteTag.index);
      } else {
        /**
         * TODO: they'll be move to under modal.js store. check later.
         */
        const groupID = this.groupId;
        const userID = deleteTag.tag.id;
        console.log('groupId:', groupID, 'UserId:', userID);
      }
    },

    tabNavigation(value) {
      this.tabPane = value;
    },

    processCommunication(type) {
      this.$validator.validateAll('communication').then((result) => {
        if (result) {
          this.formSending = true;
          this.isPending = true;
          this.$store.commit('modal/setPageToRefresh', 'Communication');
          const self = this;
          const successMsg = type === 'CREATE_COMMUNICATION'
            ? this.$t('communication_added_success')
            : this.$t('communication_updated_success');
          this.$store.dispatch(`modal/${type}`, self.$data).then(() => {
            if (!self.formError) {
              self.$snotify.success(successMsg);
              self.$store.commit('modal/toggleModal');
            } else {
              self.isPending = false;
              self.formError = false;
              self.formSending = false;
            }
          });
        }
      });
    },

    putInEditMode() {
      this.$store.dispatch('modal/GET_COMMUNICATION');
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}
.modal-body {
  h4 {
    color: #727d92;
  }
}
.blur {
  filter: blur(3px);
}
.pagination__items {
  width: 100%;
  text-align: center;
}
.card--upload {
  height: 280px;
  &.logo {
    height: 90px;
    width: 160px;
  }
}

::v-deep .media-object__media svg {
  position: absolute;
  pointer-events: none;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
}
.button-kolayik {
  position: absolute;
  display: inline-flex;
  right: 18px;
  margin-top: 3.5px;
}
::v-deep .ql-editor {
    min-height: 100px;
}
::v-deep .ql-container.ql-snow {
    border-radius: 4px;
    border: 1px solid rgba(114, 125, 146, 0.25);
    background: #fff;
    font-size: 14px;
}
::v-deep .ql-toolbar.ql-snow {
    border-radius: 4px;
    border: 1px solid rgba(114, 125, 146, 0.25);
    background: #fff;
    font-size: 14px;
}
::v-deep #imageuploading .modal-container {
    justify-content: center;
    align-items: center;
    display: flex;
  }
::v-deep .vue-lightbox-close {
  z-index: 1;
}
::v-deep .scrollBodyContent {
  // max-height: 65vh;
  // overflow-y: auto;
  // overflow-x: hidden;
  .row {
    padding: 0 20px 0 0;
  }
}
::v-deep .quillWrapper .ql-container {
    max-height: 300px;
    overflow-y: auto;
}
</style>
