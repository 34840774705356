<template>
  <div class="page dashboard">
    <app-header
      child-header-class="container-fluid"
      :child-header-navigation="dashboardSubNavigation"
      is-child-header-left-actions
      :child-title="childTitle"
    />
    <div class="container-fluid">
      <div class="row">
        <!--region center-->
        <div
          class="col-12 col-md-6"
        >
          <div class="chart_holder">
            <div style="width:20px;">
              <span
                class="vtooltip info-icon-left"
                v-tooltip.right="$t('info_active_user_ratio')"
              >
                <icon-container
                  name="infocircle"
                  view-box="0 0 512 512"
                  :is-icon-class="false"
                >
                  <icon-infocircle />
                </icon-container>
              </span>
            </div>
            <h5>{{ this.$t('active_user_ratio').toUpperCase() }}</h5>
            <highcharts
              class="chart"
              :options="activeUsersRatioCharts"
              :update-args="updateArgs"
            />
          </div>
        </div>
        <!--endregion-->
        <!--region center-->
        <div
          class="col-12 col-md-6"
        >
          <div class="chart_holder">
            <div style="width:20px;">
              <span
                class="vtooltip info-icon-left"
                v-tooltip.right="$t('info_average_time_spent')"
              >
                <icon-container
                  name="infocircle"
                  view-box="0 0 512 512"
                  :is-icon-class="false"
                >
                  <icon-infocircle />
                </icon-container>
              </span>
            </div>
            <h5>{{ $t('average_time_spent').toUpperCase() }}</h5>
            <highcharts
              class="chart"
              :options="averageTimeSpentRatioCharts"
              :update-args="updateArgs"
            />
          </div>
        </div>
        <!--endregion-->
      </div>
      <div class="row">
        <!--region center-->
        <div
          class="col-12 col-md-6"
        >
          <div class="chart_holder">
            <div style="width:20px;">
              <span
                class="vtooltip info-icon-left"
                v-tooltip.right="$t('info_app_download_ratio')"
              >
                <icon-container
                  name="infocircle"
                  view-box="0 0 512 512"
                  :is-icon-class="false"
                >
                  <icon-infocircle />
                </icon-container>
              </span>
            </div>
            <h5>{{ $t('app_download_ratio').toUpperCase() }}</h5>
            <highcharts
              class="chart"
              :options="appDownloadRatioCharts"
              :update-args="updateArgs"
            />
          </div>
        </div>
        <!--endregion-->
        <!--region center-->
        <div
          class="col-12 col-md-6"
        >
          <div class="chart_holder">
            <div style="width:20px;">
              <span
                class="vtooltip info-icon-left"
                v-tooltip.right="$t('info_number_of_contents_created')"
              >
                <icon-container
                  name="infocircle"
                  view-box="0 0 512 512"
                  :is-icon-class="false"
                >
                  <icon-infocircle />
                </icon-container>
              </span>
            </div>
            <h5>{{ $t('number_of_contents_created').toUpperCase() }}</h5>
            <highcharts
              class="chart"
              :options="createdContentRatioCharts"
              :update-args="updateArgs"
            />
          </div>
        </div>
        <!--endregion-->
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import moment from 'moment';
import { directive as onClickaway } from 'vue-clickaway';
import API from '@/services/';
import { API_REPORTS_CUSTOMER_USAGE_SUMMARY } from '@/helpers/config';
import IconContainer from '@/components/elements/Icon.vue';
import IconInfoCircle from '@/components/icons/InfoCircle.vue';

import AppHeader from '@/components/AppHeader.vue';

export default {
  name: 'DashboardCustomerDetail',
  components: {
    'app-header': AppHeader,
    'icon-container': IconContainer,
    'icon-infocircle': IconInfoCircle,
  },
  data() {
    return {
      data: [],
      updateArgs: [true, true, { duration: 2500 }],
      activeUsersRatioCharts: {
        title: false,
        chart: {
          type: 'areaspline',
        },
        xAxis: {
          title: false,
          categories: '',
          labels: {
            style: {
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: '#727D92',
            },
          },
        },
        yAxis: {
          min: 0,
          max: 100,
          title: false,
          labels: {
            style: {
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#727D92',
            },
            formatter() {
              return `${this.value}` === '0' ? '0' : `%${this.value}`;
            },
          },
        },
        tooltip: {
          outside: true,
          shared: true,
          style: {
            fontFamily: 'Work Sans, sans-serif',
            fontSize: '14px',
            color: '#727D92',
          },
          formatter() {
            return `${this.x}: %${this.y}`;
          },
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.15,
            color: '#4355FB',
            dataLabels: {
              enabled: false,
              color: '#4355FB',
            },
            series: {
              connectNulls: true,
            },
            marker: {
              enabled: false,
              symbol: 'circle',
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: [{
          showInLegend: false,
          data: '',
          lineColor: '#4355FB',
          lineWidth: 5,
          cursor: 'pointer',
        }],
        credits: {
          enabled: false,
        },
      },

      averageTimeSpentRatioCharts: {
        title: false,
        chart: {
          type: 'areaspline',
        },
        xAxis: {
          title: false,
          categories: '',
          labels: {
            style: {
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: '#727D92',
            },
          },
        },
        yAxis: {
          tickInterval: 3600 * 1000,
          type: 'datetime',
          title: false,
          labels: {
            style: {
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#727D92',
            },

            formatter() {
              let ret = Highcharts.dateFormat('%H:%M', this.value);
              if (/23:00/.test(ret)) {
                ret = '23:59';
              }
              return ret;
            },
          },
        },
        tooltip: {
          outside: true,
          shared: true,
          style: {
            fontFamily: 'Work Sans, sans-serif',
            fontSize: '14px',
            color: '#727D92',
          },
          formatter() {
            return `${this.y}` === '0' ? `${this.x} - 0` : `${this.x} - ${Math.trunc(moment.duration(this.y).asHours())}:${moment.duration(this.y).minutes()}`;
          },
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.15,
            color: '#4355FB',
            dataLabels: {
              enabled: false,
              color: '#4355FB',
            },
            series: {
              connectNulls: true,
            },
            marker: {
              enabled: false,
              symbol: 'circle',
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: [{
          showInLegend: false,
          data: '',
          lineColor: '#4355FB',
          lineWidth: 5,
          cursor: 'pointer',
        }],
        credits: {
          enabled: false,
        },
      },

      appDownloadRatioCharts: {
        title: false,
        chart: {
          type: 'areaspline',
        },
        xAxis: {
          title: false,
          categories: '',
          labels: {
            style: {
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: '#727D92',
            },
          },
        },
        yAxis: {
          min: 0,
          max: 100,
          title: false,
          labels: {
            style: {
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#727D92',
            },
            formatter() {
              return `${this.value}` === '0' ? '0' : `%${this.value}`;
            },
          },
        },
        tooltip: {
          outside: true,
          shared: true,
          style: {
            fontFamily: 'Work Sans, sans-serif',
            fontSize: '14px',
            color: '#727D92',
          },
          formatter() {
            return `${this.x}: %${this.y}`;
          },
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.15,
            color: '#4355FB',
            dataLabels: {
              enabled: false,
              color: '#4355FB',
            },
            series: {
              connectNulls: true,
            },
            marker: {
              enabled: false,
              symbol: 'circle',
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: [{
          showInLegend: false,
          data: '',
          lineColor: '#4355FB',
          lineWidth: 5,
          cursor: 'pointer',
        }],
        credits: {
          enabled: false,
        },
      },

      createdContentRatioCharts: {
        title: false,
        chart: {
          type: 'areaspline',
        },
        xAxis: {
          title: false,
          categories: '',
          labels: {
            style: {
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: '#727D92',
            },
          },
        },
        yAxis: {

          title: false,
          labels: {
            style: {
              fontFamily: 'Work Sans, sans-serif',
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#727D92',
            },
            formatter() {
              return `${this.value}`;
            },
          },
        },
        tooltip: {
          outside: true,
          shared: true,
          style: {
            fontFamily: 'Work Sans, sans-serif',
            fontSize: '14px',
            color: '#727D92',
          },
          formatter() {
            return `${this.x}: ${this.y}`;
          },
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.15,
            color: '#4355FB',
            dataLabels: {
              enabled: false,
              color: '#4355FB',
            },
            series: {
              connectNulls: true,
            },
            marker: {
              enabled: false,
              symbol: 'circle',
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: [{
          showInLegend: false,
          data: '',
          lineColor: '#4355FB',
          lineWidth: 5,
          cursor: 'pointer',
        }],
        credits: {
          enabled: false,
        },
      },
    };
  },

  activated() {
    this.getCustomerUsageSummary();
  },

  computed: {

    childTitle() {
      if (this.$store.state.modal.modalData && this.$store.state.modal.modalData.name) {
        return `${this.$store.state.modal.modalData.name}`;
      }
      return '';
    },

    userLang() {
      if (this.$store.state.auth.customerInfo) {
        return this.$store.state.auth.customerInfo.lang;
      }
      return 'en';
    },

    customerId() {
      return this.$route.params.customerId;
    },

    dashboardSubNavigation() {
      return [
        {
          text: this.$t('overview'),
          url: '/dashboard/overview',
        },
        {
          text: this.$t('journeys'),
          url: '/dashboard/journeys',
        },
        {
          text: this.$t('journeys_v2'),
          url: '/dashboard/journeys_v2',
        },
        {
          text: this.$t('users'),
          url: '/dashboard/users',
        },
        {
          text: this.$t('quiz_survey'),
          url: '/dashboard/quiz-survey',
        },
        {
          text: this.$t('customers_sub'),
          url: '/dashboard/customers',
        },
      ];
    },
  },

  methods: {

    getCustomerUsageSummary() {
      return new Promise(async (resolve, reject) => {
        const postData = new FormData();
        postData.append('length', '30');
        await API.post(`${API_REPORTS_CUSTOMER_USAGE_SUMMARY}/${this.customerId}`, postData).then((response) => {
        // region Data
          let data;

          if (!this.dash.isEmpty(response, true)) {
            if (this.dash.has(response, 'data')) {
              if (!this.dash.isEmpty(response.data, true)) {
              // eslint-disable-next-line prefer-destructuring
                data = response.data.data;
                data.reverse();
                let weekSlugList = this.dash.map(data, 'week_start');
                if (this.userLang && weekSlugList) {
                  weekSlugList = weekSlugList.map(date => this.$helpers.getUserLangBasedDateTimeFormat(date, this.userLang, 'weekslug'));
                }
                // set Charts xAxis
                if (this.dash.size(weekSlugList) > 0) {
                  const intervalX = Math.ceil(this.dash.size(weekSlugList) / 10);
                  /* if (intervalX < 6) {
                    intervalX = 6;
                  } */
                  this.activeUsersRatioCharts.xAxis.tickInterval = intervalX;
                  this.activeUsersRatioCharts.xAxis.categories = weekSlugList;

                  this.averageTimeSpentRatioCharts.xAxis.tickInterval = intervalX;
                  this.averageTimeSpentRatioCharts.xAxis.categories = weekSlugList;

                  this.appDownloadRatioCharts.xAxis.tickInterval = intervalX;
                  this.appDownloadRatioCharts.xAxis.categories = weekSlugList;

                  this.createdContentRatioCharts.xAxis.tickInterval = intervalX;
                  this.createdContentRatioCharts.xAxis.categories = weekSlugList;
                }
                // setting chart's yAxis

                const averageTimeSpentRatioList = [];
                const activeUsersRatioList = [];
                const mobileDownloadRatioList = [];
                const createdContentRatioList = [];

                this.dash.map(data, (item) => {
                  averageTimeSpentRatioList.push(moment.duration(item.average_time_spent).asMilliseconds());
                  activeUsersRatioList.push(parseInt((item.last_month_active_users / item.active_users_since_today) * 100, 10));
                  mobileDownloadRatioList.push(parseInt(item.mobile_download_rate, 10));
                  createdContentRatioList.push(parseInt(item.created_content_count, 10));
                });

                if (this.dash.size(activeUsersRatioList) > 0) {
                  this.activeUsersRatioCharts.series[0].data = activeUsersRatioList;
                }

                if (this.dash.size(averageTimeSpentRatioList) > 0) {
                  this.averageTimeSpentRatioCharts.series[0].data = averageTimeSpentRatioList;
                }

                if (this.dash.size(mobileDownloadRatioList) > 0) {
                  this.appDownloadRatioCharts.series[0].data = mobileDownloadRatioList;
                }

                if (this.dash.size(createdContentRatioList) > 0) {
                  this.createdContentRatioCharts.series[0].data = createdContentRatioList;
                }
                // setting ends here
              }
              resolve();
            }
            resolve();
          }
        // endregion
        }).catch((error) => {
          this.$helpers.displayError(error);
          reject(error);
        });
      });
    },
  },
  directives: {
    'on-clickaway':
    onClickaway,
  },
};
// endregion
</script>

<!--suppress HtmlUnknownAttribute, CssInvalidPseudoSelector-->
<style lang="scss" scoped>

.chart_holder {
    background-color: #FFF !important;
    border-radius: 8px;
    padding: 20px;
    margin: 20px;
     h5{
       font-size: 16px;
        color: #727D92;
        text-align: center;
        font-weight: bold;
        margin-bottom: 25px;
        opacity: 0.5;
     }
}
  .blur {
    filter: blur(3px);
  }

  ::v-deep .center {
    text-align: center;
    justify-content: center;
  }

// Arrow Animation
@keyframes bouncearrowup {
  0% { top: 0; } 50% { top: -20px; } 100% { top: 0; }
}

// Arrow
#arrowup {
  position: relative;
  margin: 0 auto;
  text-align: center;
  animation: bouncearrowup 3s infinite;
    svg {
      transition: .3s ease-in-out;
  }
}

// Arrow Animation
@keyframes bouncearrowdown {
  0% { top: 0; } 50% { top: 20px; } 100% { top: 0; }
}

// Arrow
#arrowdown {
  position: relative;
  margin: 0 auto;
  text-align: center;
  animation: bouncearrowdown 3s infinite;
    svg {
      transition: .3s ease-in-out;
  }
}
// Arrow Animation
@keyframes bouncedash {
  0% { left: 0; } 50% { left: 20px; } 100% { left: 0; }
}

// Arrow
#dash {
  position: relative;
  margin: 0 auto;
  text-align: center;
  color: #727D92;
  animation: bouncedash 3s infinite;
  bottom: -45px;
    svg {
      transition: .3s ease-in-out;
  }
}
.icon-item {
  width: 20px;
  margin: 0 auto;
}
</style>
