import { render, staticRenderFns } from "./ModalCommunicationAddUser.vue?vue&type=template&id=260c6bec&scoped=true"
import script from "./ModalCommunicationAddUser.vue?vue&type=script&lang=js"
export * from "./ModalCommunicationAddUser.vue?vue&type=script&lang=js"
import style0 from "./ModalCommunicationAddUser.vue?vue&type=style&index=0&id=260c6bec&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "260c6bec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/journey/workspace/journeyAdmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('260c6bec')) {
      api.createRecord('260c6bec', component.options)
    } else {
      api.reload('260c6bec', component.options)
    }
    module.hot.accept("./ModalCommunicationAddUser.vue?vue&type=template&id=260c6bec&scoped=true", function () {
      api.rerender('260c6bec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/container/modal/ModalCommunicationAddUser.vue"
export default component.exports