var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      staticClass: "switch-language",
      attrs: { name: "language" },
      on: { change: _vm.changeLanguage },
    },
    _vm._l(_vm.supportedLanguages, function (lang) {
      return _c(
        "option",
        {
          key: lang,
          class: { "is-selected": _vm.isCurrentLanguage(lang) },
          domProps: { selected: _vm.isCurrentLanguage(lang), value: lang },
        },
        [_vm._v(" " + _vm._s(lang) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }