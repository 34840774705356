<template>
  <div class="page">
    <app-header
      child-header-class="container"
      is-child-header-left-actions
      :child-header-navigation="userSubNavigation"
      add-pin
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>

    <div class="container">
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="post"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="get_privacy_approval"
            slot-scope="props"
          >
            <div class="text-center">
              <icon-container
                v-if="props.rowData.get_privacy_approval === 0"
                name="cross"
                :rotate="45"
                view-box="0 0 20 20"
                color="#142B58"
                :is-icon-class="false"
              >
                <icon-cross />
              </icon-container>
              <icon-container
                v-if="props.rowData.get_privacy_approval === 1"
                name="check"
                view-box="0 0 512 512"
                :is-icon-class="false"
              >
                <icon-check />
              </icon-container>
            </div>
          </template>
          <template
            slot="sso_uri"
            slot-scope="props"
          >
            <div
              class="d-inline-block text-truncate"
              style="max-width: 220px;"
              :title="props.rowData.sso_uri"
            >
              {{ props.rowData.sso_uri }}
            </div>
          </template>
          <template
            slot="groups"
            slot-scope="props"
          >
            <div
              class="d-inline-block"
            >
              {{ props.rowData.groups[0] === '0' ? `-` : props.rowData.groups.join(',') }}
            </div>
          </template>
          <template
            slot="journeys"
            slot-scope="props"
          >
            <div
              class="d-inline-block"
            >
              {{ props.rowData.journeys[0] === '0' ? `-` : props.rowData.journeys.join(',') }}
            </div>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <button
              class="ui button edit"
              @click="onAction('edit-item', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                name="edit"
                color="#727d92"
              >
                <icon-edit />
              </icon-container>
            </button>
            <button
              class="ui button delete"
              @click="onAction('delete-item', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                name="trash"
                color="#727d92"
              >
                <icon-trash />
              </icon-container>
            </button>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { mapState } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import IconEdit from '@/components/icons/Edit.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconCheck from '@/components/icons/Check.vue';
import IconCross from '@/components/icons/Cross.vue';
import AppHeader from '@/components/AppHeader.vue';
import { API_PINS, API_PINS_LIST } from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import VuetablePagination from '../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'Pins',
  mixins: [myTableMixin],
  data() {
    return {

    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-edit': IconEdit,
    'icon-trash': IconTrash,
    'icon-check': IconCheck,
    'icon-cross': IconCross,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'Pins') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),

    apiUrl() {
      if (API_PINS_LIST) {
        return `${API_PINS_LIST}`;
      }
      return null;
    },

    refreshPage: {
      get() {
        return this.$store.state.modal.refreshPage;
      },
      set(newValue) {
        this.$store.state.modal.refreshPage = newValue;
      },
    },

    pageToRefresh: {
      get() {
        return this.$store.state.modal.pageToRefresh;
      },

      set(newValue) {
        this.$store.state.modal.pageToRefresh = newValue;
      },
    },
    userSubNavigation() {
      return [
        {
          text: this.$t('customers'),
          url: '/customers',
        },
        {
          text: this.$t('pins'),
          url: '/pins',
        },
      ];
    },
    fields() {
      return [{
        name: 'pin_code',
        title: this.$t('pin'),
        width: '10%',
      },
      {
        name: 'customer_name',
        title: this.$t('customer'),
        width: '15%',
      },
      {
        name: 'get_privacy_approval',
        title: this.$t('privacy_approval'),
        dataClass: 'text-center',
        titleClass: 'text-center',
        width: '5%',
      },
      {
        name: 'sso_uri',
        title: this.$t('sso_uri'),
        width: '25%',
      },
      {
        name: 'groups',
        title: this.$t('groups'),
        dataClass: 'text-center',
        titleClass: 'text-center',
        width: '10%',
      },
      {
        name: 'journeys',
        title: this.$t('journeys'),
        dataClass: 'text-center',
        titleClass: 'text-center',
        width: '10%',
      },
      {
        name: 'actions',
        title: this.$t('actions'),
        width: '15%',
        titleClass: 'center',
        dataClass: 'center',
      }];
    },
  },

  methods: {
    onAction(action, data) {
      const pinID = data.id;
      this.idHolder = pinID;
      this.apiUrlHolder = API_PINS;
      if (!pinID) {
        return;
      }
      if (action === 'delete-item' && !this.snotifyQueueList.includes(pinID)) {
        this.snotifyQueueList.push(pinID);
        this.$snotify.confirm(this.$t('pin_delete_msg_body', { pin: data.pin_code }), this.$t('pin_delete_msg_title'), {
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          buttons: [
            {
              text: this.$t('yes'),
              action: (toast) => {
                this.deleteConfirm(toast, this.idHolder, this.apiUrlHolder);
              },
              bold: false,
            },
            {
              text: this.$t('no'),
              action: (toast) => {
                this.deleteCancel(toast, this.idHolder);
              },
            },
          ],
        }).on('shown', (toast) => {
          this.toastHolder = toast;
          document.addEventListener('keydown', this.eventHandler, false);
        }).on('hidden', (toast) => {
          this.toastHolder = toast;
          document.removeEventListener('keydown', this.eventHandler, false);
        });
      } else if (action === 'edit-item') {
        const obj = {
          modalType: 'modalAddPin',
          pinIdToEdit: pinID,
          pinModalData: data,
          tabPane: 'mainTab',
          pageToRefresh: 'Pins',
          editMode: true,
        };
        this.$helpers.toggleModal(obj);
      }
    },
  },
};
</script>
