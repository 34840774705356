var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        fill: "#fff",
        d: "M10.38.25C4.88.25 0 3.5 0 7.25c0 2.5 1.38 4.63 3.75 5.88v.12c-.13 1.63-1.13 2.13-1.13 2.13L.38 16.5h2.5a9.22 9.22 0 006.38-2.38h1c5.38 0 9.75-3.12 9.75-7C20 3.25 15.75.25 10.37.25zm-.13 12.63h-1.5l-.25.24c-.63.63-2 1.76-4.13 2.13a4.6 4.6 0 00.63-2.5v-.38l-.38-.12c-2.25-1.13-3.37-2.88-3.37-5 0-3 4.38-5.75 9.13-5.75 4.62 0 8.37 2.5 8.37 5.75 0 3-3.88 5.63-8.5 5.63z",
      },
    }),
    _c("path", {
      attrs: {
        fill: "#fff",
        d: "M.63 6.5l2.5-3.13 2.5-1.87 5-.63 5.62 1.25 2.5 3.13.63 2.5-2.5 3.75-3.75 1.88H9.37L5 15.88H2.5l1.25-1.26.63-1.87-2.5-1.88-1.25-2.5V6.5z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }