<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{'is-loading': formSending }"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ editMode ? $t('edit_group') : $t('add_new_group') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div
              class="form-group"
              v-show="pageOptions === 'edit-name' || pageOptions === 'new-group'"
            >
              <label
                for="contentTitle"
                class="form-label"
              >{{ $t('group_name_uppercase') }}</label>
              <input
                :class="{ 'is-danger': errors.has('groupadd.groupname') }"
                type="text"
                class="form-control"
                id="groupName"
                v-model="groupName"
                name="Group Name"
                data-vv-name="groupname"
                data-vv-scope="groupadd"
                :placeholder="$t('group_placeholder')"
                v-validate="'required'"
              >
              <span
                v-if="errors.has('groupadd.groupname')"
                class="help is-danger"
              >{{ errors.first('groupadd.groupname') }}</span>
            </div>
            <div
              class="form-group"
              v-if="pageOptions === 'new-group'"
            >
              <div class="row">
                <div class="col-12">
                  <label
                    for="contentTitle"
                    class="form-label"
                  >{{ $t('group_users') }}</label>
                  <vue-tags-input
                    v-model="user"
                    :tags="users"
                    @tags-changed="inputUpdateUsers"
                    @before-deleting-tag="deleteUsers"
                    :autocomplete-items="groupUsers"
                    :add-only-from-autocomplete="true"
                    :delete-on-backspace="false"
                    :placeholder="$t('adduser_placeholder')"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="button button--primary"
                @click="editMode ? updateGroup() : addGroup()"
                :disabled="isPending"
              >
                <span
                  v-if="!isPending"
                >
                  {{ editMode ? $t('update_button') : $t('add') }}
                </span>
                <div
                  class="lds-ellipsis"
                  v-if="isPending"
                >
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import vueTagsInput from '@johmun/vue-tags-input';

import API from '@/services/';
import {
  API_USER_DATATABLES,
  API_USER_GROUP_USER,
} from '@/helpers/config';

export default {
  name: 'ModalAddGroup',
  inject: ['$validator'],

  data() {
    return {
      isPending: false,
      formSending: false,
      group_id: null,
      groupName: '',
      user: '',
      users: [],
      debounce: null,
      groupUsers: [],
      isLoadingUsers: false,
    };
  },

  components: {
    vueTagsInput,
  },

  computed: {
    groupId() {
      return this.$store.state.modal.groupId;
    },
    editMode() {
      return this.$store.state.modal.editMode;
    },
    modalData() {
      return this.$store.state.modal.modalData;
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
    pageOptions() {
      return this.$store.state.modal.pageOptions;
    },
  },

  watch: {
    user: 'getUsers',
    modalData(newValue) {
      const $newValue = newValue;
      if (this.editMode && $newValue) {
        this.groupName = $newValue.name;
        if ($newValue.groupUsers) {
          $newValue.groupUsers.forEach((user) => {
            this.users.push({ text: `${user.name}`, id: user.id });
          });
        }
      }
    },
  },
  mounted() {
    if (this.groupId && this.editMode) {
      this.group_id = this.groupId;
      this.putInEditMode();
    }
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/toggleModal');
    },

    addGroup() {
      this.$validator.validateAll('groupadd').then((result) => {
        if (result) {
          this.formSending = true;
          this.isPending = true;
          const self = this;
          const successMsg = this.$t('group_added_success');

          this.$store.dispatch('modal/CREATE_GROUP', self.$data).then(() => {
            if (!self.formError) {
              self.$snotify.success(successMsg);
            } else {
              self.isPending = false;
              self.formError = false;
              self.formSending = false;
            }
          });
        }
      });
    },

    updateGroup() {
      this.$validator.validateAll('groupadd').then((result) => {
        if (result) {
          this.formSending = true;
          this.isPending = true;
          this.$store.commit('modal/setPageToRefresh', 'Groups');
          const self = this;
          const successMsg = this.$t('group_updated_success');
          this.$store.dispatch('modal/UPDATE_GROUP', self.$data).then(() => {
            if (!self.formError) {
              self.$snotify.success(successMsg);
            } else {
              self.isPending = false;
              self.formError = false;
              self.formSending = false;
            }
          });
        }
      });
    },

    inputUpdateUsers(newTags) {
      this.users = newTags;
    },

    deleteUsers(deleteTag) {
      if (!this.editMode) {
        deleteTag.deleteTag(deleteTag.index);
      } else {
        /**
         * TODO: they'll be move to under modal.js store. check later.
         */
        const groupID = this.groupId;
        const userID = deleteTag.tag.id;

        const request = axios.create({
          headers: {
            Authorization: `Bearer ${this.$store.getters['auth/token']}`,
          },
        });

        request.delete(API_USER_GROUP_USER,
          {
            data: {
              group_id: groupID,
              user_id: userID,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              deleteTag.deleteTag(deleteTag.index);
              this.$snotify.success(response.data.message);
            }
          })
          .catch((error) => {
            this.$helpers.displayError(error);
          });
      }
    },

    getUsers() {
      this.isLoadingUsers = true;
      this.groupUsers = [];
      const formData = { params: {} };
      formData.params.per_page = 10000;
      formData.params.customer_id = this.$store.state.auth.customerId;
      formData.params.search = this.user;

      if (this.user.length < 2) {
        return;
      }
      const searchInput = document.querySelector('.ti-new-tag-input');
      searchInput.classList.add('loading-icon');
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        API.get(API_USER_DATATABLES, formData)
          .then((response) => {
            this.groupUsers = response.data.data.map(a => (
              {
                text: `${a.first_name} ${a.last_name} [ ${a.email} ]`,
                id: `${a.id}`,
              }
            ));
            this.isLoadingUsers = false;
            searchInput.classList.remove('loading-icon');
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            searchInput.classList.remove('loading-icon');
          });
      }, 300);
    },

    putInEditMode() {
      this.$store.dispatch('modal/GET_GROUP');
    },
  },

};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 10px;
  padding-right: 10px;
}
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}

.user__tag {
  padding: 8px 12px;
  background: rgba(20,43,88,0.10);
  border-radius: 4px;
  color: #142B58;
  font-size: 12px;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  &:last-child {
    margin-right: 0;
  }
  .remove {
    margin-left: 5px;
    font-size: 14px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
