var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "child-header-navigation": _vm.childHeaderNavigation,
            "is-child-header-left-actions": "",
            "total-users": null,
            "journey-edit-icon": null,
          },
        },
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
          _c(
            "dropdown",
            {
              attrs: {
                "class-name": "filter",
                "filter-on": _vm.the_filter.only_active_customer_users,
              },
            },
            [
              _c("template", { slot: "icon" }, [
                _c("i", {
                  staticClass:
                    "fa fa-filter button button--shadow button--type-icon size-sm d-flex align-items-center",
                  staticStyle: {
                    cursor: "pointer",
                    color: "rgb(114, 125, 146)",
                  },
                }),
              ]),
              _c("template", { slot: "body" }, [
                _c("div", { staticClass: "d-block p-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column justify-content-center align-items-start",
                    },
                    [
                      _vm.isVibonsAdmin
                        ? _c(
                            "label",
                            {
                              staticClass: "checkbox",
                              attrs: {
                                for: "filter-only_active_customer_users",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.the_filter.only_active_customer_users,
                                    expression:
                                      "the_filter.only_active_customer_users",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "filter-only_active_customer_users",
                                  "true-value": true,
                                  "false-value": false,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.the_filter.only_active_customer_users
                                  )
                                    ? _vm._i(
                                        _vm.the_filter
                                          .only_active_customer_users,
                                        null
                                      ) > -1
                                    : _vm.the_filter.only_active_customer_users,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.the_filter
                                          .only_active_customer_users,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.the_filter,
                                            "only_active_customer_users",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.the_filter,
                                            "only_active_customer_users",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.the_filter,
                                        "only_active_customer_users",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("only_active_customer_users")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "label",
                        {
                          staticClass: "checkbox",
                          attrs: { for: "filter-completed" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.the_filter.status,
                                expression: "the_filter.status",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "filter-completed",
                              "true-value": "completed",
                              "false-value": "",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.the_filter.status)
                                ? _vm._i(_vm.the_filter.status, null) > -1
                                : _vm._q(_vm.the_filter.status, "completed"),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.the_filter.status,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "completed" : ""
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.the_filter,
                                        "status",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.the_filter,
                                        "status",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.the_filter, "status", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("completed")) + " "),
                        ]
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "checkbox",
                          attrs: { for: "filter-active" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.the_filter.status,
                                expression: "the_filter.status",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "filter-active",
                              "true-value": "active",
                              "false-value": "",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.the_filter.status)
                                ? _vm._i(_vm.the_filter.status, null) > -1
                                : _vm._q(_vm.the_filter.status, "active"),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.the_filter.status,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "active" : ""
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.the_filter,
                                        "status",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.the_filter,
                                        "status",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.the_filter, "status", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("active")) + " "),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("main", { staticClass: "page__body" }, [
        _vm.tableIsLoading
          ? _c("div", { staticClass: "lds-dual-ring" })
          : _vm._e(),
        _c("div", { staticClass: "container-fluid container-cards" }, [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "table-responsive" },
              [
                _c("vue-table", {
                  ref: "myvuetable",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "api-url": _vm.apiUrl,
                    "http-fetch": _vm.myFetch,
                    "api-mode": true,
                    "pagination-path": "meta",
                    "http-method": "get",
                    "append-params": _vm.moreParams,
                    "http-options": {
                      headers: {
                        Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                      },
                    },
                    fields: _vm.fields,
                    "no-data-template": _vm.$t("no_data_info"),
                    css: _vm.css.table,
                    "per-page": _vm.perPage,
                    "query-params": _vm.makeQueryParams,
                  },
                  on: {
                    "vuetable:loading": function ($event) {
                      _vm.tableIsLoading = true
                    },
                    "vuetable:loaded": function ($event) {
                      _vm.tableIsLoading = false
                    },
                    "vuetable:load-error": _vm.vuetableLoadError,
                    "vuetable:pagination-data": _vm.onPaginationData,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "name-slot",
                      fn: function (props) {
                        return [
                          _c("div", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.nameGenerated(props))),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "status-slot",
                      fn: function (props) {
                        return [
                          props.rowData.is_active
                            ? _c("div", [
                                _c("span", { staticClass: "status-active" }, [
                                  _vm._v(_vm._s(_vm.$t("active"))),
                                ]),
                              ])
                            : _c("div", [
                                _c("span", { staticClass: "status-pasive" }, [
                                  _vm._v(_vm._s(_vm.$t("completed_"))),
                                ]),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "customer-slot",
                      fn: function (props) {
                        return [
                          _c("span", [_vm._v(_vm._s(props.rowData.customer))]),
                        ]
                      },
                    },
                    {
                      key: "activation_date-slot",
                      fn: function (props) {
                        return [
                          props.rowData.activation_date
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDate(
                                        props.rowData.activation_date
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "user_count",
                      fn: function (props) {
                        return [
                          _c(
                            "span",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "JourneyUsers",
                                      params: {
                                        journeyId: props.rowData.id,
                                        title: props.rowData.name,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(props.rowData.user_count) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "action-slot",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "button-group center action-button",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onAction(
                                        "edit-content",
                                        props.rowData
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { cursor: "pointer" } },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: "20",
                                            height: "20",
                                            "view-box": "0 0 24 24",
                                            name: "edit",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-edit")],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown ml-2 mr-2",
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.dropdownToggle(
                                        `plus${props.rowIndex}`
                                      )
                                    },
                                    mouseleave: _vm.hideDropdown,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "card--actions dropdown__toggle",
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: "18",
                                            height: "18",
                                            "view-box": "0 0 24 24",
                                            name: "cplus",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-circleplus")],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.dropdownIndex === "plus" + props.rowIndex
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "on-clickaway",
                                              rawName: "v-on-clickaway",
                                              value: _vm.hideDropdown,
                                              expression: "hideDropdown",
                                            },
                                          ],
                                          ref: `plus${props.rowIndex}`,
                                          staticClass: "dropdown__menu",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dropdown__button button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onAction(
                                                    "add-user",
                                                    props.rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  attrs: {
                                                    width: 18,
                                                    height: 18,
                                                    name: "user",
                                                    color: "#727d92",
                                                  },
                                                },
                                                [_c("icon-user")],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("add_user")) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dropdown__button button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onAction(
                                                    "add-group",
                                                    props.rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  attrs: {
                                                    width: 18,
                                                    height: 18,
                                                    "view-box": "0 0 24 24",
                                                    name: "group",
                                                    color: "#727d92",
                                                  },
                                                },
                                                [_c("icon-group")],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("add_group")) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown",
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.dropdownToggle(
                                        `three${props.rowIndex}`
                                      )
                                    },
                                    mouseleave: _vm.hideDropdown,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "card--actions dropdown__toggle",
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: 20,
                                            height: 20,
                                            "view-box": "0 0 24 24",
                                            name: "threedots",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-threedots")],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.dropdownIndex === "three" + props.rowIndex
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "on-clickaway",
                                              rawName: "v-on-clickaway",
                                              value: _vm.hideDropdown,
                                              expression: "hideDropdown",
                                            },
                                          ],
                                          ref: `three${props.rowIndex}`,
                                          staticClass: "dropdown__menu",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dropdown__button button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onAction(
                                                    "notification",
                                                    props.rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  attrs: {
                                                    width: 18,
                                                    height: 18,
                                                    "view-box": "0 0 24 24",
                                                    name: "edit",
                                                    color: "#727d92",
                                                  },
                                                },
                                                [_c("icon-bell")],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "notification_button"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dropdown__button button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onAction(
                                                    "download-activity",
                                                    props.rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  attrs: {
                                                    width: "13",
                                                    height: "14",
                                                    name: "Download Activity",
                                                    color: "#727d92",
                                                  },
                                                },
                                                [_c("icon-download")],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("download_activity")
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dropdown__button button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.exportContentListReport(
                                                    props.rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  attrs: {
                                                    name: "content",
                                                    "view-box": "0 0 13 14",
                                                    color: "#727d92",
                                                    "is-icon-class": false,
                                                  },
                                                },
                                                [_c("icon-download")],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "download_journey_content_report"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dropdown__button button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onAction(
                                                    "delete-item",
                                                    props.rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  attrs: {
                                                    width: 18,
                                                    height: 18,
                                                    "view-box": "0 0 24 24",
                                                    name: "trash",
                                                    color: "#727d92",
                                                  },
                                                },
                                                [_c("icon-trash")],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("delete")) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c(
                  "div",
                  { staticClass: "pagination__info" },
                  [
                    _c("vuetable-pagination-info", {
                      ref: "paginationInfo",
                      class: { "table-fade": _vm.tableIsLoading },
                      attrs: {
                        "info-template": _vm.$t("pagination_info"),
                        "no-data-template": _vm.$t("no_data_info"),
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "pagination__items" },
                      [
                        _c("vuetable-pagination", {
                          ref: "pagination",
                          attrs: { css: _vm.css.pagination },
                          on: {
                            "vuetable-pagination:change-page": _vm.onChangePage,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }