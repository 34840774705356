var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-center justify-center",
      staticStyle: { width: "100%" },
    },
    [
      _c(
        "div",
        {
          style: `height: 18px;border-radius:10px; border:1px solid #D5D5D5;color: rgba(0, 0, 0, 0.87);background: transparent;overflow: hidden;position:relative;transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);width: 100%;`,
          attrs: {
            role: "progressbar",
            "aria-valuemin": "0",
            "aria-valuemax": "100",
            "aria-valuenow": _vm.progress,
          },
        },
        [
          _c("div", {
            staticStyle: {
              opacity: "1",
              left: "0",
              width: "0%",
              bottom: "0",
              position: "absolute",
              top: "0",
              "-webkit-transition": "inherit",
              transition: "inherit",
            },
          }),
          _c("div", {
            staticStyle: {
              height: "inherit",
              left: "0",
              position: "absolute",
              top: "0",
              "-webkit-transition": "inherit",
              transition: "inherit",
              width: "100%",
            },
          }),
          _c("div", {
            style: `width: ${_vm.progress}%; background-color: rgb(196, 196, 196); border-color: rgb(196, 196, 196);height: inherit;left: 0;position: absolute;-webkit-transition: inherit;transition: inherit;`,
          }),
          _c(
            "div",
            {
              staticStyle: {
                "-webkit-box-align": "center",
                "-ms-flex-align": "center",
                "align-items": "center",
                display: "flex",
                height: "100%",
                left: "0",
                "-webkit-box-pack": "center",
                "-ms-flex-pack": "center",
                "justify-content": "center",
                position: "absolute",
                top: "0",
                width: "100%",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { color: "#333", "font-size": "11px" } },
                [_vm._v(" " + _vm._s(_vm.timeFrame) + " ")]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }