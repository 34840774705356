var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { class: ["modal-dialog modal-dialog-scrollable"] }, [
          !_vm.isFirstSessionDone
            ? _c("div", { staticClass: "modal-content" }, [
                _c("header", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.journeyHaveUsers
                            ? _vm.$t("assign_journey")
                            : _vm.$t("start_journey")
                        ) +
                        " "
                    ),
                  ]),
                  _c("button", {
                    staticClass: "close",
                    attrs: { type: "button" },
                    domProps: { innerHTML: _vm._s("&times;") },
                    on: { click: _vm.closeModal },
                  }),
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "content-pane" }, [
                    _c(
                      "div",
                      { staticClass: "col-4 form-group px-4 pb-4" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "journeyLanguage" },
                          },
                          [_vm._v(_vm._s(_vm.$t("first_work_day")))]
                        ),
                        _c("date-picker", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          class: {
                            "is-danger": _vm.errors.has("assign.beginDate"),
                          },
                          attrs: {
                            "disabled-date": _vm.notBeforeToday,
                            "value-type": "date",
                            "data-vv-scope": "assign",
                            "data-vv-name": "beginDate",
                            "data-vv-as": _vm.$t("begin_date"),
                            "first-day-of-week": _vm.userLang === "en" ? 7 : 1,
                            lang: _vm.userLang,
                            format:
                              _vm.userLang === "en"
                                ? "MM.DD.YYYY"
                                : "DD.MM.YYYY",
                          },
                          on: {
                            "update:disabledDate": function ($event) {
                              _vm.notBeforeToday = $event
                            },
                            "update:disabled-date": function ($event) {
                              _vm.notBeforeToday = $event
                            },
                          },
                          model: {
                            value: _vm.beginActivationDateTime,
                            callback: function ($$v) {
                              _vm.beginActivationDateTime = $$v
                            },
                            expression: "beginActivationDateTime",
                          },
                        }),
                        _vm.errors.has("assign.beginDate")
                          ? _c("span", { staticClass: "help is-danger" }, [
                              _vm._v(
                                _vm._s(_vm.errors.first("assign.beginDate"))
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-group px-4 pb-4" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label form-label--small",
                            attrs: { for: "contentTitle" },
                          },
                          [_vm._v(_vm._s(_vm.$t("start_onboarding_for")))]
                        ),
                        _c("vue-tags-input", {
                          class: {
                            "is-danger": _vm.errors.has("assign.assignTo"),
                          },
                          attrs: {
                            placeholder: _vm.$t("add_name_placeholder"),
                            tags: _vm.tags,
                            "add-only-from-autocomplete": true,
                            "autocomplete-items": _vm.autocompleteItems,
                          },
                          on: { "tags-changed": _vm.update },
                          model: {
                            value: _vm.tag,
                            callback: function ($$v) {
                              _vm.tag = $$v
                            },
                            expression: "tag",
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            type: "hidden",
                            "data-vv-scope": "assign",
                            "data-vv-name": "assignTo",
                            "data-vv-as": _vm.$t("start_onboarding_for"),
                          },
                          domProps: { value: _vm.assignTo },
                        }),
                        _vm.errors.has("assign.assignTo")
                          ? _c("span", { staticClass: "help is-danger" }, [
                              _vm._v(
                                _vm._s(_vm.errors.first("assign.assignTo"))
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form form--horizontal" },
                      [
                        _c("div", { staticClass: "panel-title" }, [
                          _c(
                            "div",
                            {
                              class: [_vm.showAdvanceSettings ? "active" : ""],
                            },
                            [
                              _c(
                                "label",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.showAdvanceSettings =
                                        !_vm.showAdvanceSettings
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("advanced_tab")))]
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "collapse-transition",
                          { attrs: { duration: 500, dimension: "height" } },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showAdvanceSettings,
                                    expression: "showAdvanceSettings",
                                  },
                                ],
                                staticClass: "px-4 bglight",
                              },
                              [
                                _c("div", { staticClass: "row mx-4 bglight" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "div",
                                      { staticClass: "swicth-checkbox" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.sendOnSaturday,
                                              expression: "sendOnSaturday",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: "sendOnSaturday",
                                            checked: "",
                                            "true-value": 1,
                                            "false-value": 0,
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.sendOnSaturday
                                            )
                                              ? _vm._i(
                                                  _vm.sendOnSaturday,
                                                  null
                                                ) > -1
                                              : _vm._q(_vm.sendOnSaturday, 1),
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.sendOnSaturday,
                                                $$el = $event.target,
                                                $$c = $$el.checked ? 1 : 0
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.sendOnSaturday =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.sendOnSaturday = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.sendOnSaturday = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "swicth-label",
                                            attrs: { for: "sendOnSaturday" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("include_saturdays")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "div",
                                      { staticClass: "swicth-checkbox" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.sendOnSunday,
                                              expression: "sendOnSunday",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: "sendOnSunday",
                                            checked: "",
                                            "true-value": 1,
                                            "false-value": 0,
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.sendOnSunday
                                            )
                                              ? _vm._i(_vm.sendOnSunday, null) >
                                                -1
                                              : _vm._q(_vm.sendOnSunday, 1),
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.sendOnSunday,
                                                $$el = $event.target,
                                                $$c = $$el.checked ? 1 : 0
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.sendOnSunday =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.sendOnSunday = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.sendOnSunday = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "swicth-label",
                                            attrs: { for: "sendOnSunday" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("include_sundays")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "row mx-4" }, [
                                  _c("div", { staticClass: "col-12 mt-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-label",
                                            attrs: { for: "excludedDays" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("excluded_days"))
                                            ),
                                          ]
                                        ),
                                        _c("flat-pickr", {
                                          staticClass: "form-control",
                                          attrs: {
                                            config: _vm.excludedDaysConfig,
                                            placeholder: _vm.$t("select_dates"),
                                            name: "excludedDays",
                                          },
                                          model: {
                                            value: _vm.excludedDaysAsString,
                                            callback: function ($$v) {
                                              _vm.excludedDaysAsString = $$v
                                            },
                                            expression: "excludedDaysAsString",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.items.length > 0,
                            expression: "errors.items.length > 0",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "alert alert--card alert--error" },
                          [
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("error_msg_title"))),
                            ]),
                            _c(
                              "ul",
                              _vm._l(_vm.errors, function (error, index) {
                                return _c("li", { key: index }, [
                                  _vm._v(" " + _vm._s(error.msg) + " "),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button button--primary",
                          attrs: { type: "button", disabled: _vm.isPending },
                          on: { click: _vm.assignJourney },
                        },
                        [
                          !_vm.isPending
                            ? _c("span", [
                                _vm._v(" " + _vm._s(_vm.$t("assign")) + " "),
                              ])
                            : _vm._e(),
                          _vm.isPending
                            ? _c("div", { staticClass: "lds-ellipsis" }, [
                                _c("div"),
                                _c("div"),
                                _c("div"),
                                _c("div"),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.isFirstSessionDone
            ? _c("div", { staticClass: "modal-content" }, [
                _c("header", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.journeyHaveUsers
                            ? _vm.$t("assign_journey")
                            : _vm.$t("start_journey")
                        ) +
                        " "
                    ),
                  ]),
                  _c("button", {
                    staticClass: "close",
                    attrs: { type: "button" },
                    domProps: { innerHTML: _vm._s("&times;") },
                    on: { click: _vm.closeModal },
                  }),
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "content-pane" }, [
                    _c(
                      "div",
                      { staticClass: "assignment-final-msg-box" },
                      [
                        _c(
                          "icon-container",
                          {
                            attrs: {
                              name: "greencheck",
                              "view-box": "0 0 60 60",
                              width: 60,
                              height: 60,
                            },
                          },
                          [_c("icon-greencheck")],
                          1
                        ),
                        _c("div", { staticClass: "assignment-final-title" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("onboarding_assignment_final_msg")
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm.postAssignments.length
                      ? _c(
                          "div",
                          { staticClass: "more-assignments" },
                          [
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "modal-title m-4 text-center subtitle_borderbottom",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("assign_manager_title")) +
                                    " "
                                ),
                              ]
                            ),
                            _vm._l(
                              _vm.postAssignments,
                              function (assignment, indx) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: assignment.id,
                                      staticClass: "form-group px-4 pb-4",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "form-label form-label--small",
                                          attrs: { for: "contentTitle" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                `${assignment.name} (${assignment.email})`
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("vue-tags-input", {
                                        class: {
                                          "is-danger":
                                            _vm.errors.has("assign.assignTo"),
                                          checkdone: assignment.isDone,
                                        },
                                        attrs: {
                                          placeholder: _vm.$t("assign_manager"),
                                          tags: _vm.assignmentTags[indx],
                                          "add-only-from-autocomplete": true,
                                          "max-tags": 1,
                                          "autocomplete-items":
                                            _vm.autocompleteItems,
                                        },
                                        on: {
                                          "before-adding-tag": (event) =>
                                            _vm.addTag(
                                              event,
                                              assignment.id,
                                              indx
                                            ),
                                          "before-deleting-tag": (event) =>
                                            _vm.deleteTag(event, assignment.id),
                                          "tags-changed": (event) =>
                                            _vm.update(event, indx),
                                          input: _vm.onInput,
                                        },
                                        model: {
                                          value: _vm.assignmentTag[indx],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.assignmentTag,
                                              indx,
                                              $$v
                                            )
                                          },
                                          expression: "assignmentTag[indx]",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }