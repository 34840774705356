<script>
export default {
  props: {
    rowData: {
      type: Object,
      default: null,
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    rowField: {
      type: Object,
      default: null,
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    vuetable: {
      type: Object,
      default: null,
    },
  },
};
</script>
