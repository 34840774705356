var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalGroupAddJourney"
            ? _c("modal-groupaddjourney")
            : _vm._e(),
          _vm.modal.modalVisible &&
          _vm.modal.modalType === "modalJourneyRemoveGroup"
            ? _c("modal-journey-remove")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalAddGroup"
            ? _c("modal-group-add")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalGroupAddUser"
            ? _c("modal-groupadduser")
            : _vm._e(),
        ],
        1
      ),
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "child-header-navigation": _vm.userSubNavigation,
            "is-child-header-left-actions": "",
            "add-group": "",
          },
        },
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.tableIsLoading
          ? _c("div", { staticClass: "lds-dual-ring" })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c("vue-table", {
              ref: "myvuetable",
              class: { "table-fade": _vm.tableIsLoading },
              attrs: {
                "api-url": _vm.apiUrl,
                "http-fetch": _vm.myFetch,
                "api-mode": true,
                "pagination-path": "meta",
                "http-method": "get",
                "append-params": _vm.moreParams,
                "http-options": {
                  headers: {
                    Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                  },
                },
                fields: _vm.fields,
                "no-data-template": _vm.$t("no_data_info"),
                css: _vm.css.table,
                "per-page": _vm.perPage,
                "query-params": _vm.makeQueryParams,
              },
              on: {
                "vuetable:loading": function ($event) {
                  _vm.tableIsLoading = true
                },
                "vuetable:loaded": _vm.tableLoaded,
                "vuetable:load-error": _vm.vuetableLoadError,
                "vuetable:pagination-data": _vm.onPaginationData,
              },
              scopedSlots: _vm._u([
                {
                  key: "name",
                  fn: function (props) {
                    return [
                      _c("span", [
                        _vm._v(" " + _vm._s(props.rowData.name) + " "),
                      ]),
                    ]
                  },
                },
                {
                  key: "user_count",
                  fn: function (props) {
                    return [
                      _c(
                        "span",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "GroupUsers",
                                  params: {
                                    groupId: props.rowData.id,
                                    title: props.rowData.name,
                                  },
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(props.rowData.user_count) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "assigned_journeys",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.assignedjourneys[props.rowData.id]) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "actions",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "ui button",
                          on: {
                            click: function ($event) {
                              return _vm.onAction("edit-item", props.rowData)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 20,
                                height: 20,
                                "view-box": "0 0 24 24",
                                name: "edit",
                                color: "#727d92",
                              },
                            },
                            [_c("icon-edit")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown mr-2",
                          on: {
                            mouseover: function ($event) {
                              return _vm.dropdownAction(`plus${props.rowIndex}`)
                            },
                            mouseleave: _vm.hideDropdownAction,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "card--actions dropdown__toggle" },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    width: "18",
                                    height: "18",
                                    "view-box": "0 0 24 24",
                                    name: "cplus",
                                    color: "#727d92",
                                  },
                                },
                                [_c("icon-circleplus")],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.dropdownIndex === "plus" + props.rowIndex
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "on-clickaway",
                                      rawName: "v-on-clickaway",
                                      value: _vm.hideDropdownAction,
                                      expression: "hideDropdownAction",
                                    },
                                  ],
                                  ref: `plus${props.rowIndex}`,
                                  staticClass: "dropdown__menu",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "add-user",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: 18,
                                            height: 18,
                                            name: "user",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-user")],
                                        1
                                      ),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("add_user")) + " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "add-journey",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: 18,
                                            height: 18,
                                            name: "journey",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-journey")],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("add_to_existent_journey")
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown",
                          on: {
                            mouseover: function ($event) {
                              return _vm.dropdownAction(
                                `three${props.rowIndex}`
                              )
                            },
                            mouseleave: _vm.hideDropdownAction,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "card--actions dropdown__toggle" },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    width: 20,
                                    height: 20,
                                    "view-box": "0 0 24 24",
                                    name: "threedots",
                                    color: "#727d92",
                                  },
                                },
                                [_c("icon-threedots")],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.dropdownIndex === "three" + props.rowIndex
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "on-clickaway",
                                      rawName: "v-on-clickaway",
                                      value: _vm.hideDropdownAction,
                                      expression: "hideDropdownAction",
                                    },
                                  ],
                                  ref: `three${props.rowIndex}`,
                                  staticClass: "dropdown__menu",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "remove-journey",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "ml-n1",
                                        attrs: {
                                          type: "mdi",
                                          width: "21",
                                          color: "#727d92",
                                          height: "23",
                                          path: _vm.getImgUrl("mdiYoutubeTv"),
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("remove_from_journey")
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "notification",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: 18,
                                            height: 18,
                                            "view-box": "0 0 24 24",
                                            name: "edit",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-bell")],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("notification_button")
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "download-journey-report",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: "13",
                                            height: "14",
                                            name: "Download Journey Report",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-download")],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("download_journey_report")
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "delete-item",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: 18,
                                            height: 18,
                                            "view-box": "0 0 24 24",
                                            name: "trash",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-trash")],
                                        1
                                      ),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("delete")) + " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              { staticClass: "pagination__info" },
              [
                _c("vuetable-pagination-info", {
                  ref: "paginationInfo",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "info-template": _vm.$t("pagination_info"),
                    "no-data-template": _vm.$t("no_data_info"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "pagination__items" },
                  [
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }