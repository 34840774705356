var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        d: "M2.46586 2.91402L16.5346 16.9828",
        stroke: "#727D92",
        "stroke-width": "3",
        "stroke-linecap": "round",
      },
    }),
    _c("path", {
      attrs: {
        d: "M16.5341 2.91402L2.46539 16.9828",
        stroke: "#727D92",
        "stroke-width": "3",
        "stroke-linecap": "round",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }