var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "currentColor" } }, [
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M6.04 10.92l.1.08.04.03c.03 0 .05.02.07.03l.06.02.06.02a.64.64 0 00.25 0l.06-.02.06-.02c.03 0 .05-.02.07-.03l.04-.03a.63.63 0 00.1-.08l3.7-3.7a.64.64 0 10-.9-.9l-2.61 2.6V.64a.64.64 0 10-1.29 0v8.28l-2.6-2.6a.64.64 0 10-.91.9l3.7 3.7z",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M12.32 8.4a.64.64 0 00-.64.63v2.84c0 .42-.34.76-.73.76h-8.9a.78.78 0 01-.77-.76V9.03a.64.64 0 10-1.28 0v2.84c0 1.11.94 2.05 2.05 2.05h8.9c1.1 0 2.01-.92 2.01-2.05V9.03a.64.64 0 00-.64-.64z",
        fill: "currentColor",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }