var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.$t("edit_communication")
                        : _vm.$t("add_new_communication")
                    ) +
                    " "
                ),
              ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "tab-content" }, [
                _vm.tabPane === "mainTab"
                  ? _c("div", { staticClass: "tab-pane" }, [
                      _c(
                        "div",
                        { staticClass: "form-group scrollBodyContent" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label required",
                                    attrs: { for: "name" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("title_")))]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.title,
                                      expression: "title",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|min:10|max:500",
                                      expression: "'required|min:10|max:500'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-danger": _vm.errors.has(
                                      "communication.title"
                                    ),
                                  },
                                  attrs: {
                                    type: "text",
                                    id: "title",
                                    "data-vv-scope": "communication",
                                    "data-vv-name": "title",
                                    "data-vv-as": _vm.$t("title_"),
                                    placeholder: _vm.$t("title_placeholder_"),
                                  },
                                  domProps: { value: _vm.title },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.title = $event.target.value
                                    },
                                  },
                                }),
                                _vm.errors.has("communication.title")
                                  ? _c(
                                      "span",
                                      { staticClass: "help is-danger" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first(
                                              "communication.title"
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-label",
                                      attrs: { for: "cbody" },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("body")) + " ")]
                                  ),
                                  _c("vue-editor", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "max:1000",
                                        expression: "'max:1000'",
                                      },
                                    ],
                                    attrs: {
                                      id: "cbody",
                                      "editor-toolbar": _vm.customToolbar,
                                      "data-vv-name": "body",
                                      "data-vv-scope": "communication",
                                      "data-vv-as": _vm.$t("body"),
                                    },
                                    model: {
                                      value: _vm.body,
                                      callback: function ($$v) {
                                        _vm.body = $$v
                                      },
                                      expression: "body",
                                    },
                                  }),
                                  _vm.errors.has("communication.body")
                                    ? _c(
                                        "span",
                                        { staticClass: "help is-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                "communication.body"
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-12" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "mail_body" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("mail_body")) + " "
                                    ),
                                  ]
                                ),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.mail_body,
                                      expression: "mail_body",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "max:500",
                                      expression: "'max:500'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-danger": _vm.errors.has(
                                      "communication.mail_body"
                                    ),
                                  },
                                  attrs: {
                                    id: "mail_body",
                                    "data-vv-name": "mail_body",
                                    "data-vv-scope": "communication",
                                    "data-vv-as": _vm.$t("mail_body"),
                                  },
                                  domProps: { value: _vm.mail_body },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.mail_body = $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm.errors.has("communication.mail_body")
                                  ? _c(
                                      "span",
                                      { staticClass: "help is-danger" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first(
                                              "communication.mail_body"
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-12" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "push_body" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("push_body")) + " "
                                    ),
                                  ]
                                ),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.push_body,
                                      expression: "push_body",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "max:500",
                                      expression: "'max:500'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-danger": _vm.errors.has(
                                      "communication.push_body"
                                    ),
                                  },
                                  attrs: {
                                    id: "push_body",
                                    "data-vv-name": "push_body",
                                    "data-vv-scope": "communication",
                                    "data-vv-as": _vm.$t("push_body"),
                                  },
                                  domProps: { value: _vm.push_body },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.push_body = $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm.errors.has("communication.push_body")
                                  ? _c(
                                      "span",
                                      { staticClass: "help is-danger" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first(
                                              "communication.push_body"
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-label",
                                      attrs: { for: "files" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("upload_images")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("vue-upload-multiple-image", {
                                    ref: "dragdropbox",
                                    attrs: {
                                      id: "imageuploading",
                                      "drop-text": _vm.$t("drag_drop"),
                                      "drag-text": _vm.$t("drag_drop"),
                                      "browse-text": _vm.$t("or_select"),
                                      "primary-text": "",
                                      "popup-text": "",
                                      "mark-is-primary-text": "",
                                      "show-primary": false,
                                      "show-edit": false,
                                      "max-image": 10,
                                      "data-images": _vm.images,
                                    },
                                    on: {
                                      "upload-success": _vm.uploadImageSuccess,
                                      "before-remove": _vm.beforeRemove,
                                      "limit-exceeded": _vm.limitExceededImage,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-label",
                                          attrs: { for: "expire_date" },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("expire_date")))]
                                      ),
                                      _c("date-picker", {
                                        class: {
                                          "is-danger": _vm.errors.has(
                                            "communication.expire_date"
                                          ),
                                        },
                                        attrs: {
                                          type: "date",
                                          id: "expire_date",
                                          "value-type": "YYYY-MM-DD 00:00:00",
                                          "first-day-of-week":
                                            _vm.userLang === "en" ? 7 : 1,
                                          lang: _vm.userLang,
                                          format:
                                            _vm.userLang === "en"
                                              ? "MM.DD.YYYY"
                                              : "DD.MM.YYYY",
                                        },
                                        model: {
                                          value: _vm.expire_date,
                                          callback: function ($$v) {
                                            _vm.expire_date = $$v
                                          },
                                          expression: "expire_date",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "div",
                                      { staticClass: "swicth-checkbox" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.is_active,
                                              expression: "is_active",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: "is_active",
                                            "true-value": 1,
                                            "false-value": 0,
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.is_active
                                            )
                                              ? _vm._i(_vm.is_active, null) > -1
                                              : _vm._q(_vm.is_active, 1),
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.is_active,
                                                $$el = $event.target,
                                                $$c = $$el.checked ? 1 : 0
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.is_active = $$a.concat(
                                                      [$$v]
                                                    ))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.is_active = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.is_active = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "swicth-label",
                                            attrs: { for: "is_active" },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("is_active")))]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.items.length > 0,
                              expression: "errors.items.length > 0",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "alert alert--card alert--error" },
                            [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("error_msg_title"))),
                              ]),
                              _c(
                                "ul",
                                _vm._l(_vm.errors, function (error, index) {
                                  return _c("li", { key: index }, [
                                    _vm._v(" " + _vm._s(error.msg) + " "),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: { type: "button", disabled: _vm.isPending },
                            on: {
                              click: function ($event) {
                                _vm.editMode
                                  ? _vm.processCommunication(
                                      "UPDATE_COMMUNICATION"
                                    )
                                  : _vm.processCommunication(
                                      "CREATE_COMMUNICATION"
                                    )
                              },
                            },
                          },
                          [
                            !_vm.isPending
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.editMode
                                        ? _vm.$t("update_button")
                                        : _vm.$t("add")
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm.isPending
                              ? _c("div", { staticClass: "lds-ellipsis" }, [
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }