<template>
  <div class="button-group">
    <div class="button-group__item dropdown">
      <label
        class="button button--shadow button--type-icon size-sm d-flex align-items-center"
        @click="dropdownShow('showFilters')"
      >
        <icon-container
          name="search"
          :width="14"
          :height="14"
          color="#727d92"
          view-box="0 0 14 14"
          :is-icon-class="false"
        >
          <icon-search />
        </icon-container>
      </label>
      <div
        class="dropdown__menu dropdown__menu--filter"
        v-if="isDropdown === 'showFilters'"
        v-on-clickaway="hideDropdown"
      >
        <div class="form-group form-group--filter">
          <input
            type="text"
            v-model="filterText"
            v-sanitize
            class="form-control"
            @input="debounceInput"
            :placeholder="$t('filter_by_name')"
          >
          <button
            v-if="filterText"
            class="icon button-reset"
            @click="resetFilter"
          >
            <icon-container
              name="reset"
              view-box="0 0 20 20"
            >
              <icon-reset />
            </icon-container>
          </button>
        </div>
      </div>
    </div>
    <div class="button-group__item dropdown">
      <button
        class="button button--shadow button__dropdown size-sm"
        :class="{'active': isDropdown === 'perPageDropdown' }"
        @click="dropdownShow('perPageDropdown')"
      >
        <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
        {{ $t('view') }} {{ perPage }}
        <icon-container
          name="down"
          view-box="0 0 11 8"
          :is-icon-class="false"
        >
          <icon-down />
        </icon-container>
      </button>
      <div
        class="dropdown__menu dropdown__menu--per-page"
        v-if="isDropdown === 'perPageDropdown'"
        v-on-clickaway="hideDropdown"
      >
        <div
          class="dropdown__menu-item"
          v-for="option in pageOptions"
          :key="option.value"
          @click="pageOptionsSelected(option.value)"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway';
import IconContainer from '@/components/elements/Icon.vue';
import IconReset from '@/components/icons/Reset.vue';
import IconSearch from '@/components/icons/Search.vue';
import IconDown from '@/components/icons/Down.vue';

export default {
  data() {
    return {
      filterText: '',
      perPage: 10,
      isDropdown: null,
    };
  },
  components: {
    'icon-container': IconContainer,
    'icon-reset': IconReset,
    'icon-search': IconSearch,
    'icon-down': IconDown,
  },
  directives: {
    'on-clickaway': onClickaway,
  },
  computed: {
    pageOptions() {
      return [
        { text: `${this.$t('view')} 10`, value: 10 },
        { text: `${this.$t('view')} 20`, value: 20 },
        { text: `${this.$t('view')} 50`, value: 50 },
        { text: `${this.$t('view')} 100`, value: 100 },
      ];
    },
  },
  methods: {
    hideDropdown() {
      this.isDropdown = null;
    },
    dropdownShow(value) {
      this.isDropdown = value;
    },
    pageOptionsSelected(value) {
      this.$emit('perPage', value);
      this.perPage = value;
      this.hideDropdown();
    },
    resetFilter() {
      this.filterText = '';
      this.debounceInput();
    },
    debounceInput() {
      setTimeout(() => {
        this.$emit('searchFor', this.filterText);
      }, 750);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-group.form-group--filter {
  border-bottom: none !important;
}
</style>
