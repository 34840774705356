<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{'is-loading': formSending }"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ editMode ? $t('edit_pin') : $t('add_pin') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="tab-content">
              <div class="tab-pane">
                <div class="form-group">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label
                          for="customer"
                          class="form-label"
                        >{{ $t('customer').toUpperCase() }}</label>
                        <multi-select
                          :class="{ 'is-danger': errors.has('pin.customer') }"
                          track-by="id"
                          label="name"
                          clear-on-select
                          deselect-label
                          select-label
                          v-model="selectCustomer"
                          :options="customerOptions"
                          class="selectbox selectbox--secondary"
                          data-vv-scope="pin"
                          data-vv-name="customer"
                        />
                        <span
                          v-if="errors.has('pin.customer')"
                          class="help is-danger"
                        >{{ errors.first('pin.customer') }}</span>
                      </div>
                      <div class="form-group">
                        <label
                          for="name"
                          class="form-label"
                        >{{ $t('pin_code').toUpperCase() }}</label>
                        <input
                          :class="{ 'is-danger': errors.has('pin.pin_code') }"
                          type="text"
                          class="form-control"
                          id="pin_code"
                          v-model="pin_code"
                          data-vv-scope="pin"
                          data-vv-name="pin_code"
                          :data-vv-as="$t('pin_code')"
                          :placeholder="$t('pincode_placeholder')"
                          v-validate="'required|numeric'"
                        >
                        <span
                          v-if="errors.has('pin.pin_code')"
                          class="help is-danger"
                        >{{ errors.first('pin.pin_code') }}</span>
                      </div>

                      <div
                        class="form-group"
                        v-if="!editMode"
                      >
                        <label
                          for="group"
                          class="form-label"
                        >{{ $t('groups').toUpperCase() }}</label>
                        <vue-tags-input
                          :class="{ 'is-danger': errors.has('pin.group') }"
                          v-model="group"
                          :tags="groups"
                          @tags-changed="inputUpdateGroups"
                          :autocomplete-items="groupsToAdd"
                          :add-only-from-autocomplete="true"
                          :delete-on-backspace="false"
                          :placeholder="$t('add_new_group_placeholder')"
                        />
                        <input
                          type="hidden"
                          data-vv-scope="pin"
                          data-vv-name="group"
                          :data-vv-as="$t('group_name')"
                          v-model="groupsSelected"
                        >
                        <span
                          v-if="errors.has('pin.group')"
                          class="help is-danger"
                        >{{ errors.first('pin.group') }}</span>
                      </div>
                      <div
                        class="form-group"
                        v-if="editMode"
                      >
                        <label
                          for="group"
                          class="form-label"
                        >{{ $t('groups').toUpperCase() }}</label>
                        <input
                          :class="{ 'is-danger': errors.has('pin.group') }"
                          type="text"
                          class="form-control"
                          id="group"
                          v-model="groupsSelected"
                          data-vv-name="group"
                          :data-vv-as="$t('group_name')"
                          data-vv-scope="pin"
                          :placeholder="$t('add_new_group_placeholder')"
                        >
                        <span
                          v-if="errors.has('pin.group')"
                          class="help is-danger"
                        >{{ errors.first('pin.group') }}</span>
                      </div>
                      <div
                        class="form-group"
                      >
                        <label
                          for="journey"
                          class="form-label"
                        >{{ $t('journeys').toUpperCase() }}</label>
                        <vue-tags-input
                          :class="{ 'is-danger': errors.has('pin.journey') }"
                          v-model="journey"
                          :tags="journeys"
                          @tags-changed="inputUpdateJourneys"
                          :autocomplete-items="journeysToAdd"
                          :add-only-from-autocomplete="true"
                          :delete-on-backspace="false"
                          :placeholder="$t('addjourney_placeholder')"
                        />
                        <input
                          class="form-control"
                          :class="{ 'is-danger': errors.has('pin.sso_uri') }"
                          type="hidden"
                          id="journey"
                          v-model="journeysSelected"
                          data-vv-name="journey"
                          :data-vv-as="$t('journeys')"
                          data-vv-scope="pin"
                          :placeholder="$t('addjourney_placeholder')"
                        >
                        <span
                          v-if="errors.has('pin.journey')"
                          class="help is-danger"
                        >{{ errors.first('pin.journey') }}</span>
                      </div>
                      <!-- <div
                        class="form-group"
                        v-if="editMode && journeysSelected"
                      >
                        <label
                          for="journey"
                          class="form-label"
                        >{{ $t('journeys').toUpperCase() }}</label>
                        <input
                          :class="{ 'is-danger': errors.has('pin.sso_uri') }"
                          type="text"
                          class="form-control"
                          id="journey"
                          v-model="journeysSelected"
                          data-vv-name="journey"
                          :data-vv-as="$t('journeys')"
                          data-vv-scope="pin"
                          :placeholder="$t('addjourney_placeholder')"
                        >
                        <span
                          v-if="errors.has('pin.journey')"
                          class="help is-danger"
                        >{{ errors.first('pin.journey') }}</span>
                      </div> -->
                      <div class="form-group">
                        <label
                          for="contactName"
                          class="form-label"
                        >{{ $t('sso_uri').toUpperCase() }}</label>
                        <input
                          :class="{ 'is-danger': errors.has('pin.sso_uri') }"
                          type="text"
                          class="form-control"
                          id="sso_uri"
                          v-model="sso_uri"
                          data-vv-name="sso_uri"
                          v-validate="''"
                          :data-vv-as="$t('sso_uri').toUpperCase()"
                          data-vv-scope="pin"
                          :placeholder="$t('sso_uri_placeholder')"
                        >
                        <span
                          v-if="errors.has('pin.sso_uri')"
                          class="help is-danger"
                        >{{ errors.first('pin.sso_uri') }}</span>
                      </div>

                      <div class="form-group">
                        <div class="swicth-checkbox">
                          <input
                            type="checkbox"
                            v-model="get_privacy_approval"
                            id="get_privacy_approval"
                            :true-value="1"
                            :false-value="0"
                          >
                          <label
                            class="swicth-label"
                            for="get_privacy_approval"
                          >{{ $t('is_privacy_policy_needed') }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t('error_msg_title') }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary no-wrap"
                    @click="addPin"
                    :disabled="isPending"
                  >
                    <span
                      v-if="!isPending"
                    >
                      {{ editMode ? $t('edit') : $t('add') }}
                    </span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import vueTagsInput from '@johmun/vue-tags-input';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import API from '@/services/';
import {
  API_USER_GROUP_DATATABLES,
  API_PINS,
  API_JOURNEY_FILTERED,
  API_GET_CUSTOMER_LIST_LIGHT,
  API_SERVER_TIME_FORMAT,
} from '@/helpers/config';

export default {
  name: 'ModalAddPin',
  inject: ['$validator'],

  data() {
    return {
      isPending: false,
      formSending: false,
      selectCustomer: null,
      pin_code: null,
      sso_uri: '',
      customerOptions: [],
      groupsToAdd: [],
      group: '',
      groups: [],
      journeysToAdd: [],
      journey: '',
      journeys: [],
      get_privacy_approval: 0,
      journeysSelected: null,
      debounce: null,
      groupsSelected: null,
    };
  },

  components: {
    'multi-select': Multiselect,
    vueTagsInput,
  },
  watch: {
    group: 'getGroups',
    groups() {
      if (this.groups && this.groups.length > 0) {
        this.groupsSelected = this.groups[0].text;
      } else {
        this.groupsSelected = null;
      }
      this.$nextTick(() => {
        this.$validator.validate('pin.group', this.groupsSelected);
      });
    },
    journey: 'getJourneys',
    journeys() {
      if (this.journeys && this.journeys.length > 0) {
        this.journeysSelected = this.journeys[0].id;
      } else {
        this.journeysSelected = null;
      }
      // this.$nextTick(() => {
      //   this.$validator.validate('pin.journey', this.journeysSelected);
      // });
    },
    customerOptions(newValue) {
      if (!this.editMode && newValue) {
        this.selectCustomer = newValue.find(x => x.id === this.currentCustomerId);
      }
    },
  },
  computed: {
    pinModalData: {
      get() {
        return this.$store.state.modal.pinModalData;
      },
      set(val) {
        this.$store.state.modal.pinModalData = val;
      },
    },
    currentCustomerId() {
      return this.$store.state.auth.customerId;
    },
    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
    editMode() {
      return this.$store.state.modal.editMode;
    },
    dateFormat() {
      if (this.userLang === 'en') {
        return 'MM.DD.YYYY';
      }
      if (this.userLang === 'tr') {
        return 'DD.MM.YYYY';
      }
      return 'MM.DD.YYYY';
    },
    customerListFromCache() {
      return this.$store.state.auth.customerList;
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },
  async created() {
    if (this.isVibonsAdmin) {
      if (this.customerListFromCache && this.customerListFromCache.length === 0) {
        const formData = { params: {} };
        formData.params.per_page = 1000;
        await API.get(API_GET_CUSTOMER_LIST_LIGHT, formData).then((response) => {
          this.customerOptions = response.data;
          this.$store.commit('auth/set_customer_list', response.data);
        });
      } else {
        this.customerOptions = [...this.customerListFromCache];
      }
      if (this.currentCustomerId) {
        this.selectCustomer = this.customerOptions.find(x => x.id === this.currentCustomerId);
      }
      // this.getGroups();
      // this.getJourneys();
      if (this.pinModalData && this.editMode) {
        const $newValue = this.pinModalData;
        if ($newValue) {
          this.get_privacy_approval = $newValue.get_privacy_approval;
          this.sso_uri = $newValue.sso_uri;
          this.pin_code = $newValue.pin_code;
          if (this.isVibonsAdmin) {
            if (this.customerOptions) {
              this.selectCustomer = this.customerOptions.find(x => x.id === $newValue.as_customer_id);
            }
          }
          this.groupsSelected = $newValue.groups.join(',');
          this.journeysSelected = $newValue.journeys.join(',');
          this.journeys = $newValue.journeys.map(j => ({ id: j, text: j }));
        }
      }
    }
  },
  methods: {

    closeModal() {
      this.$store.commit('modal/toggleModal');
      this.pinModalData = null;
    },

    inputUpdateGroups(newTags) {
      this.groups = newTags;
    },

    getGroups() {
      this.groupsToAdd = [];

      const formData = { params: {} };
      formData.params.per_page = 10000;
      if (this.group) {
        formData.params.search = this.group;
        if (this.group.length < 2) {
          return;
        }
      }
      formData.params.customer_id = this.currentCustomerId;
      const searchInput = document.querySelectorAll('.ti-new-tag-input')[0];
      if (searchInput) {
        searchInput.classList.add('loading-icon');
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        API.get(API_USER_GROUP_DATATABLES, formData)
          .then((response) => {
            this.groupsToAdd = response.data.data.map(a => (
              {
                text: `${a.name}`,
                id: `${a.id}`,
              }
            ));
            if (searchInput) {
              searchInput.classList.remove('loading-icon');
            }
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            if (searchInput) {
              searchInput.classList.remove('loading-icon');
            }
          });
      }, 300);
    },

    inputUpdateJourneys(newTags) {
      this.journeys = newTags;
    },

    getJourneys() {
      this.journeysToAdd = [];
      const formData = { params: {} };
      formData.params.per_page = 10000;
      if (this.journey) {
        formData.params.search = this.journey;
        if (this.journey.length < 2) {
          return;
        }
      }
      formData.params.customer_id = this.selectCustomer.id;

      const searchInput = document.querySelectorAll('.ti-new-tag-input')[1];
      if (searchInput) {
        searchInput.classList.add('loading-icon');
      }
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        API.get(API_JOURNEY_FILTERED, formData)
          .then((response) => {
            if (searchInput) {
              searchInput.classList.remove('loading-icon');
            }
            this.journeysToAdd = response.data.data.map(a => (
              {
                text: `${a.id}: ${a.name} - [${moment(a.activation_date, API_SERVER_TIME_FORMAT).format(this.dateFormat)}]`,
                id: `${a.id}`,
                user_count: a.user_count,
              }
            )).filter(item => item.user_count > 0);
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            if (searchInput) {
              searchInput.classList.remove('loading-icon');
            }
          });
      }, 300);
    },

    addPin() {
      this.$validator.validateAll('pin').then(async (result) => {
        if (result) {
          this.formSending = true;
          this.isPending = true;
          const self = this;
          const successMsg = self.$t('added_success');

          const buildFormData = () => {
            const formData = new FormData();
            formData.append('customer_id', this.selectCustomer.id);
            if (this.sso_uri) {
              formData.append('sso_uri', this.sso_uri);
            }
            formData.append('get_privacy_approval', this.get_privacy_approval);
            formData.append('pin_code', this.pin_code);

            if (this.groups.length > 0) {
              formData.append('group_ids', this.groups.map(gs => gs.id).join(','));
            }
            if (this.journeys.length > 0) {
              formData.append('journey_ids', this.journeys.map(js => js.id).join(','));
            }

            return formData;
          };

          if (this.editMode) {
            const formData = buildFormData();
            formData.append('pin_id', this.pinModalData.id);

            await API.put(API_PINS, formData)
              .then((response) => {
                if (response.status === 200) {
                  self.$snotify.success(successMsg);
                  self.$store.commit('modal/REFRESH_PAGE', true);
                }
              })
              .catch((error) => {
                self.formSending = false;
                self.isPending = false;
                self.$helpers.displayError(error);
              });
          } else {
            const formData = buildFormData();

            await API.post(API_PINS, formData)
              .then((response) => {
                if (response.status === 200) {
                  self.$snotify.success(successMsg);
                  self.$store.commit('modal/REFRESH_PAGE', true);
                }
              })
              .catch((error) => {
                self.formSending = false;
                self.isPending = false;
                self.$helpers.displayError(error);
              });
          }

          if (self.isPending) {
            self.closeModal();
            self.isPending = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check {
  .form-label {
    font-size: 0.9rem;
    margin-left: 1rem;
    margin-bottom: 0;
    color: #142b58;
  }
}
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}
.modal-body {
  h4 {
    color: #727d92;
  }
}
.blur {
  filter: blur(3px);
}
.no-wrap {
  white-space: nowrap;
}
</style>
