var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "none" } }, [
    _c("path", {
      attrs: {
        fill: "#394C71",
        d: "M49.38 23.71c.47-.5.66-1.2.61-2.3-.06-1.5-1.77-2.79-3.12-2.79h-3.38c-.1 0-.37 0-.5-.03v-.04c0-.15.27-.52.5-.82.17-.23.36-.48.54-.77a6.7 6.7 0 001.23-3.93c0-1.7-1-4.03-2.9-4.03-1.53 0-1.94 1.6-2.24 2.77-.1.4-.2.78-.32 1.08-.4.97-1.31 1.77-2.11 2.47-.58.5-1.12.98-1.46 1.5l-.56.92c-.45.77-2.03 3.26-2.49 3.72h-1.66V33.3l1.07.03c.28.26.92.67 1.88.67h11.4c1 0 1.84-.43 2.34-1.21.46-.71.58-1.64.32-2.29.3-.25.55-.64.7-1.12a2.7 2.7 0 00-.13-2.03c.87-.83 1.17-2.53.28-3.64zm-1.04.85c.46.57.21 1.49-.18 1.83-.73.65-.33 1.42-.2 1.68.1.15.11.52 0 .91-.1.3-.23.46-.3.5-.4.2-.77.63-.37 1.53.06.13.08.6-.2 1.05-.26.4-.67.6-1.23.6H34.47c-.56 0-.81-.25-.97-.42l-.04-.04a.94.94 0 00-.7-.25v-9.41h.99c.6 0 1.08-.75 3.07-4.12l.53-.87c.23-.36.71-.78 1.21-1.22.92-.8 1.95-1.7 2.47-2.96.16-.39.27-.83.38-1.26.28-1.07.5-1.77.96-1.77 1 0 1.55 1.78 1.55 2.69 0 1.48-.44 2.3-1.02 3.21-.15.24-.32.46-.48.68-.4.53-.77 1.02-.77 1.63 0 1.41 1.34 1.41 1.84 1.41h3.38c.68 0 1.76.8 1.79 1.5.04 1.04-.19 1.27-.26 1.34-.5.5-.53 1.18-.06 1.76z",
      },
    }),
    _c("mask", { attrs: { id: "a", fill: "#fff" } }, [
      _c("rect", {
        attrs: { width: "5.98", height: "11.7", x: "25", y: "21.77", rx: "2" },
      }),
    ]),
    _c("rect", {
      attrs: {
        width: "5.98",
        height: "11.7",
        x: "25",
        y: "21.77",
        stroke: "#394C71",
        "stroke-width": "4.8",
        mask: "url(#a)",
        rx: "2",
      },
    }),
    _c("rect", {
      attrs: {
        width: "74",
        height: "43",
        x: ".5",
        y: ".5",
        stroke: "#727D92",
        "stroke-opacity": ".5",
        rx: "5.5",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }