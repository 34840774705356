<template>
  <div
    class="tab-pane show active"
  >
    <div class="form-group form-group--cards">
      <div class="row">
        <div class="col-12">
          <label
            for="uploadPodcast"
            class="form-label"
          >{{ $t('upload_podcast') }}</label>
          <div
            class="card card--upload"
            :class="{ 'is-danger': errors.has('podcast.podcast') }"
          >
            <div
              v-show="loading"
              class="vloader"
            >
              <svg
                version="1.1"
                id="loader-1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="60px"
                height="60px"
                viewBox="0 0 60 60"
                enable-background="new 0 0 60 60"
                xml:space="preserve"
              >
                <path
                  opacity="0.2"
                  fill="#000"
                  d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                />
                <path
                  fill="#000"
                  d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                    C22.32,8.481,24.301,9.057,26.013,10.047z"
                >
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 20 20"
                    to="360 20 20"
                    dur="0.5s"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </div>
            <div
              class="podcast-container"
              v-if="podcastPreview"
            >
              <av-waveform
                class="podcast-visual"
                :audio-src="podcastPreview"
                :cors-anonym="true"
                :canv-top="true"
                audio-class="podcast-audio"
              />
              <button
                class="js-media-delete"
                :disabled="disabled"
                @click="podcastRemoved"
              >
                <icon-container
                  :width="20"
                  :height="20"
                  name="trash"
                >
                  <icon-trash />
                </icon-container>
              </button>
            </div>
            <div
              class="podcast-upload-file"
              v-if="!podcastPreview"
            >
              <input
                type="file"
                @change="podcastUploaded"
                data-vv-scope="podcast"
                :disabled="disabled"
                name="podcast"
                data-vv-name="podcast"
                :data-vv-as="$t('podcast')"
                v-validate="'required|size:200000|mimes:audio/*,audio/x-mpeg-3,.mp3'"
                accept="audio/mpeg3,audio/x-mpeg-3,.mp3"
                @input="$emit('input', $event.target.value)"
              >
              <span
                v-if="!loading"
                class="media-object media-object--row"
              >
                <span class="media-object__media">
                  <icon-container
                    name="circleplusfull"
                    view-box="0 0 512 512"
                    :width="45"
                    :height="45"
                    :is-icon-class="false"
                  >
                    <icon-circleplusfull />
                  </icon-container>
                </span>
              </span>
            </div>
          </div>
          <span
            v-if="errors.has('podcast.podcast')"
            class="help is-danger"
          >{{ errors.first('podcast.podcast') }}</span>
        </div>
      </div>
    </div>
    <div
      v-show="podcastPreview"
      class="form-group"
      :class="{ 'is-danger': errors.has('podcast.title') }"
    >
      <label
        for="contentTitle"
        class="form-label"
      >{{ $t('title').toUpperCase() }}</label>
      <input
        type="text"
        class="form-control"
        id="contentTitle"
        v-model="title"
        :disabled="disabled && !canEditOnlyItem"
        data-vv-scope="podcast"
        data-vv-name="title"
        :data-vv-as="$t('title')"
        v-validate="'required|max:600'"
        @input="handleTitle"
      >
      <span
        v-show="errors.has('podcast.title')"
        class="help is-danger"
      >{{ errors.first('podcast.title') }}</span>
    </div>
    <div
      v-show="podcastPreview"
      class="quiz-assubcomponent"
    >
      <div class="title">
        <h5 class="modal-title">
          {{ $t('add_question') }}
        </h5>
        <div
          class="swicth-checkbox"
        >
          <input
            type="checkbox"
            id="isAddingQuestion"
            v-model="isAddingQuestion"
            :true-value="1"
            :false-value="0"
          >
          <label
            class="swicth-label"
            for="isAddingQuestion"
          />
        </div>
      </div>
      <div class="warning-box">
        <div class="desc-text">
          <icon-container
            width="27"
            height="30"
            view-box="0 0 27 30"
            name="lamp"
            color="#727d92"
          >
            <icon-lamp />
          </icon-container>
          <div class="description">
            {{ $t('quiz_remark') }}
          </div>
        </div>
      </div>
      <collapse-transition
        :duration="500"
        dimension="height"
        v-show="isAddingQuestion"
      >
        <quiz-component
          :disabled="disabledTemp"
          :can-edit-only-item="canEditOnlyItemTemp"
          :quiz-type="'content'"
          as-scope="podcastquiz"
          :repetitive_max_question_per_day="repetitiveMaxQuestionPerDayTemp"
          :repetitive_correct_answer_count="repetitiveCorrectAnswerCountTemp"
        />
      </collapse-transition>
    </div>
    <div
      v-show="errors.items.length > 0 && podcastPreview"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="button button--primary"
        @click="editMode ? updateContent() : createContent()"
        :disabled="isPending || (disabled && !canEditOnlyItem)"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import IconContainer from '@/components/elements/Icon.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconCirclePlusFull from '@/components/icons/CirclePlusFull.vue';
import { CORS_PROXY_URL } from '@/helpers/config';
import subQuizMixin from '../../../helpers/subQuizMixin.vue';

export default {
  name: 'PodcastContent',
  inject: ['$validator'],
  mixins: [subQuizMixin],
  components: {
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-circleplusfull': IconCirclePlusFull,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    podcastUrl: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPending: false,
      podcastPreview: '',
      podcastErrorField: false,
      podcastDuration: null,
      title: '',
      file: null,
      loading: false,
    };
  },

  watch: {
    modalError: {
      handler(newVal) {
        if (newVal) {
          this.isPending = false;
        }
      },
      immediate: true,
    },

    podcastUrl: {
      handler(newVal) {
        if (this.editMode && this.$helpers.isValidUrl(newVal)) {
          this.podcastPreview = CORS_PROXY_URL + newVal;
        } else {
          this.podcastPreview = newVal;
        }
      },
      immediate: true,
    },

    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },

  },

  computed: {
    modalData() {
      return this.$store.state.modal.modalData;
    },
    modalError() {
      return this.$store.state.modal.formError;
    },
  },

  methods: {
    async podcastUploaded(e) {
      this.$parent.multiProcessProgressBar = true;
      const files = e.target.files || e.dataTransfer.files;
      const file = files[0];
      let preview;
      this.loading = true;
      if (file) {
        preview = await this.readFile(file);
        this.$validator.validate('podcast.podcast').then((res) => {
          if (res) {
            this.file = file;
            this.title = this.$helpers.getFileNameOnly(file);
            this.handleTitle();
            this.podcastPreview = preview;
            if (this.$refs.podcastPlayer) {
              this.$refs.podcastPlayer.src = preview;
            }
            this.$emit('file', file);
            this.$emit('podcast', preview);
            this.$parent.multiProcessProgressBar = false;
          } else {
            this.$parent.multiProcessProgressBar = false;
          }
        });
      }
    },

    async readFile(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const preview = event.target.result;
          this.loading = false;
          resolve(preview);
        };
      });
    },

    podcastRemoved() {
      this.file = null;
      this.podcastPreview = '';
      this.podcastDuration = null;
      this.$emit('podcast', '');
    },

    podcastLoaded() {
      if (this.$refs.podcastPlayer && this.$refs.podcastPlayer.duration) {
        this.podcastDuration = this.$refs.podcastPlayer.duration;
      }
    },

    handleTitle() {
      this.$emit('setTitle', this.title);
    },

    async checkValidation() {
      let allIsValid = false;
      let podcastIsValid = false;
      let quizIsValid = false;

      if (this.isAddingQuestion) {
        quizIsValid = await this.checkQuizValidation('podcast');
      } else {
        quizIsValid = true;
      }

      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });

      await this.$validator.validateAll('podcast').then((res) => {
        podcastIsValid = res;
      });

      if (allIsValid && podcastIsValid && quizIsValid) {
        return true;
      }
      return false;
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    async updateContent() {
      if (this.isAddingQuestion === 0 && this.modalData?.quiz && this.modalData?.quiz?.questions) {
        // eslint-disable-next-line array-callback-return
        await Promise.all(this.modalData?.quiz?.questions.map((question) => {
          this.$store.dispatch('modal/DELETE_QUIZ_QUESTION', question.id);
        }));
        await this.$store.dispatch('modal/DELETE_CONTENT_SUB_QUIZ', this.modalData.quiz.id);
      }
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.podcast-container {
  width: 100%;
  // padding-top: 56.25%;
  height: 175px;
  position: relative;
}

.podcast {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
}

.podcast-upload-file {
  width: 100%;
  input {
    cursor: pointer;
    z-index: 20;
  }
}
.vloader {
  display: flex;
  left: calc(50% - 30px);
  position: absolute;
  svg path,
  svg rect{
  fill: #f27299;
  }
}
.card.card--upload.is-danger {
  border-color: #f27299;
}

</style>
