var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
      _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("header", { staticClass: "modal-header" }, [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(
                " " + _vm._s(_vm.journeyName + "-" + _vm.$t("user_list")) + " "
              ),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: { type: "button" },
              domProps: { innerHTML: _vm._s("&times;") },
              on: { click: _vm.closeModal },
            }),
          ]),
          _c("nav", { staticClass: "navigation navigation--secondary" }, [
            _c("div", { staticClass: "container" }, [
              _c(
                "ul",
                {
                  staticClass: "navigation__list nav",
                  attrs: { id: "contentCreateTab", role: "tablist" },
                },
                [
                  _c("li", { staticClass: "navigation__item" }, [
                    _c(
                      "button",
                      {
                        staticClass: "navigation__link",
                        class: { active: _vm.tabPane === "assigned_user" },
                        on: {
                          click: function ($event) {
                            return _vm.tabNavigation("assigned_user")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("assigned_user")) + " ")]
                    ),
                  ]),
                  _c("li", { staticClass: "navigation__item" }, [
                    _c(
                      "button",
                      {
                        staticClass: "navigation__link",
                        class: { active: _vm.tabPane === "plan_user_assign" },
                        on: {
                          click: function ($event) {
                            return _vm.tabNavigation("plan_user_assign")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("plan_user_assign")) + " ")]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _vm.journeyV2Id
              ? _c("div", { staticClass: "tab-content" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tabPane === "assigned_user",
                          expression: "tabPane === 'assigned_user'",
                        },
                      ],
                      staticClass: "tab-pane",
                    },
                    [
                      _vm.tabPane === "assigned_user"
                        ? _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("journey-users-v2", {
                                attrs: {
                                  "journey-id": _vm.journeyV2Id,
                                  "tab-pane": _vm.tabPane,
                                },
                                on: { closeModal: _vm.closeModal },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tabPane === "plan_user_assign",
                          expression: "tabPane === 'plan_user_assign'",
                        },
                      ],
                      staticClass: "tab-pane",
                    },
                    [
                      _vm.tabPane === "plan_user_assign"
                        ? _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("journey-users-v2", {
                                attrs: {
                                  "journey-id": _vm.journeyV2Id,
                                  "tab-pane": _vm.tabPane,
                                },
                                on: { closeModal: _vm.closeModal },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "button button--primary",
                  attrs: { type: "button" },
                  on: { click: _vm.closeModal },
                },
                [_c("span", [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")])]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }