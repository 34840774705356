var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _vm.modal.modalVisible &&
      _vm.modal.modalType === "modalAddMoreToJourneyGroup"
        ? _c("modal-add-more-to-journey-group")
        : _vm._e(),
      _c(
        "app-header",
        {
          attrs: {
            "child-header-navigation": _vm.childHeaderNavigation,
            "is-child-header-left-actions": "",
          },
          scopedSlots: _vm._u([
            {
              key: "right",
              fn: function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass:
                        "button button--secondary text-size-xs size-sm button--icon",
                      on: {
                        click: function ($event) {
                          return _vm.addMoreToJourneygroup()
                        },
                      },
                    },
                    [
                      _c(
                        "icon-container",
                        {
                          attrs: {
                            name: "plusfull",
                            "view-box": "0 0 448 512",
                            width: 15,
                            height: 16,
                            color: "#FFF",
                            "is-icon-class": true,
                          },
                        },
                        [_c("icon-plusfull")],
                        1
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.$t("add_more_to_journey_group")) + " "
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "button-search ml-4" }, [
            _c("div", { staticClass: "search-box" }, [
              _vm.filterText
                ? _c(
                    "span",
                    {
                      staticClass: "icon icon-reset",
                      on: { click: _vm.resetFilter },
                    },
                    [
                      _c(
                        "icon-container",
                        { attrs: { name: "reset", "view-box": "0 0 20 20" } },
                        [_c("icon-reset")],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "icon-search",
                  on: {
                    click: function ($event) {
                      return _vm.doFilter()
                    },
                  },
                },
                [
                  _c(
                    "icon-container",
                    {
                      attrs: {
                        name: "search",
                        width: 14,
                        height: 14,
                        color: "#727d92",
                        "view-box": "0 0 14 14",
                      },
                    },
                    [_c("icon-search")],
                    1
                  ),
                ],
                1
              ),
              _c("input", {
                directives: [
                  { name: "sanitize", rawName: "v-sanitize" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filterText,
                    expression: "filterText",
                  },
                ],
                ref: "searchBox",
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("search_placeholder"),
                },
                domProps: { value: _vm.filterText },
                on: {
                  keydown: function ($event) {
                    return _vm.doFilter()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.filterText = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]
      ),
      _c("main", { staticClass: "page__body" }, [
        _c("div", { staticClass: "container-fluid container-cards mb-4" }, [
          _c("div", { staticClass: "d-block mb-1 ml-4" }, [
            _c(
              "label",
              { staticClass: "h3 journey-title d-flex align-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "mr-4",
                    attrs: {
                      to: {
                        name: "journeyGroup",
                      },
                    },
                  },
                  [
                    _c(
                      "icon-container",
                      {
                        attrs: {
                          width: 40,
                          height: 18,
                          name: "Go Back",
                          color: "#727d92",
                        },
                      },
                      [_c("icon-goback")],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" " + _vm._s(_vm.$t("journey_group")) + " "),
                _vm._v(
                  " " +
                    _vm._s(" : ") +
                    " " +
                    _vm._s(_vm.$route.params.title) +
                    " "
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "row mx-4" },
            [
              _c(
                "transition-group",
                {
                  staticClass: "row margin-left-15",
                  style: {
                    "--total": _vm.journeyList ? _vm.journeyList.length : 0,
                  },
                  attrs: { name: "slide-in", tag: "div" },
                },
                _vm._l(_vm.journeyList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: `${item.id}-${index}`,
                      staticClass: "card card--catalog",
                      class: { is_public: item.is_public },
                      style: { "--i": index },
                    },
                    [
                      _vm.isItemNew(item.activation_date)
                        ? _c("div", { staticClass: "ribbon" }, [
                            _c("span", [_vm._v(_vm._s(_vm.$t("new")))]),
                          ])
                        : _vm._e(),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "JourneyItems",
                              params: {
                                journeyId: item.id || item.options,
                                title: item.name,
                              },
                            },
                          },
                        },
                        [
                          _c("figure", {
                            staticClass: "card__media_home",
                            style: {
                              backgroundImage: `url('${item.thumbnail_image}')`,
                            },
                          }),
                          _c("div", { staticClass: "card__body" }, [
                            _c(
                              "h3",
                              {
                                staticClass: "card__title",
                                attrs: { title: item.name },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("truncate")(item.name)) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "card__footer" }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "button button--border button--border-light text-size-xs size-sm button--icon",
                            attrs: { role: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.modalAssign(item)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "icon" },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      name: "assign",
                                      "view-box": "0 0 14 14",
                                      "is-icon-class": false,
                                    },
                                  },
                                  [_c("icon-assign")],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" " + _vm._s(_vm.$t("assign")) + " "),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown",
                            on: {
                              mouseover: function ($event) {
                                return _vm.dropdownToggle(index, "Private")
                              },
                              mouseleave: _vm.hideDropdown,
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "card--actions dropdown__toggle" },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      name: "threedotsvertical",
                                      width: 5,
                                      height: 22,
                                      "view-box": "0 0 5 22",
                                    },
                                  },
                                  [_c("icon-threedotsvertical")],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.dropdownIndex === index + "Private"
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "on-clickaway",
                                        rawName: "v-on-clickaway",
                                        value: _vm.hideDropdown,
                                        expression: "hideDropdown",
                                      },
                                    ],
                                    ref: `dropdownBox${index}Private`,
                                    refInFor: true,
                                    staticClass: "dropdown__menu",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "dropdown__button button",
                                        on: {
                                          click: function ($event) {
                                            return _vm.createPacketCode(item.id)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "icon-container",
                                          {
                                            attrs: {
                                              name: "password",
                                              width: 22,
                                              height: 22,
                                              "view-box": "0 0 32 32",
                                              color: "#727d92",
                                            },
                                          },
                                          [_c("icon-password")],
                                          1
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("create_packet_code")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "dropdown__button button",
                                        on: {
                                          click: function ($event) {
                                            return _vm.requestAssignedUserReport(
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "icon-container",
                                          {
                                            attrs: {
                                              name: "content",
                                              "view-box": "0 0 18 17",
                                              "is-icon-class": false,
                                            },
                                          },
                                          [_c("icon-dashboard")],
                                          1
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "request_assigned_user_report"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "dropdown__button button",
                                        on: {
                                          click: _vm.exportContentListReport,
                                        },
                                      },
                                      [
                                        _c(
                                          "icon-container",
                                          {
                                            attrs: {
                                              name: "content",
                                              "view-box": "0 0 15 14",
                                              "is-icon-class": false,
                                            },
                                          },
                                          [_c("icon-download")],
                                          1
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "download_journey_content_report"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "dropdown__button button",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editJourney(
                                              item.id,
                                              "mainTab"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "icon-container",
                                          {
                                            attrs: {
                                              name: "setting",
                                              "view-box": "0 0 15 15",
                                            },
                                          },
                                          [_c("icon-setting")],
                                          1
                                        ),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("settings")) + " "
                                        ),
                                      ],
                                      1
                                    ),
                                    !_vm.canNotEdit(item.is_public)
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "dropdown__button button",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeFromJourneyGroup(
                                                  index,
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                attrs: {
                                                  name: "remove",
                                                  width: 16,
                                                  height: 16,
                                                  color: "#727d92",
                                                  "view-box": "0 0 300 300",
                                                },
                                              },
                                              [_c("icon-remove")],
                                              1
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "remove_from_journey_group"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.canNotEdit(item.is_public)
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "dropdown__button button",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteJourney(
                                                  index,
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                attrs: {
                                                  name: "trash",
                                                  "view-box": "0 0 24 24",
                                                },
                                              },
                                              [_c("icon-trash")],
                                              1
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("delete")) +
                                                " "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
              !_vm.isLoading
                ? _c("Observer", { on: { intersect: _vm.intersected } })
                : _vm._e(),
              _vm.isLoading
                ? _c("div", { staticClass: "lds-dual-ring" })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }