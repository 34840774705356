var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("app-header", {
        attrs: {
          childTitle: _vm.journeyDetail ? _vm.journeyDetail.name : null,
          totalUsers: _vm.journeyTotalUser,
          totalJourney: _vm.totalJourney,
          justifyBetweenClass: "",
        },
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "dropdown",
                    on: {
                      mouseover: function ($event) {
                        return _vm.dropdownToggle(`three11`)
                      },
                      mouseleave: _vm.hideDropdown,
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "card--actions dropdown__toggle" },
                      [
                        _c(
                          "icon-container",
                          {
                            attrs: {
                              width: 20,
                              height: 20,
                              "view-box": "0 0 24 24",
                              name: "threedots",
                              color: "#727d92",
                            },
                          },
                          [_c("icon-threedots")],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.dropdownIndex === "three11"
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "on-clickaway",
                                rawName: "v-on-clickaway",
                                value: _vm.hideDropdown,
                                expression: "hideDropdown",
                              },
                            ],
                            ref: `three11`,
                            staticClass: "dropdown__menu right",
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "dropdown__button button" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column justify-content-center align-items-start",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "checkbox",
                                        attrs: { for: "filter-journey_type" },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.showRoleType,
                                              expression: "showRoleType",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: "filter-journey_type",
                                            "true-value": true,
                                            "false-value": false,
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.showRoleType
                                            )
                                              ? _vm._i(_vm.showRoleType, null) >
                                                -1
                                              : _vm.showRoleType,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.showRoleType,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.showRoleType =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.showRoleType = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.showRoleType = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("showRoleType")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm.showRoleType
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column justify-content-center align-items-start",
                                          },
                                          _vm._l(
                                            _vm.roleTypes,
                                            function (role) {
                                              return _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "swicth-checkbox",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: role.display,
                                                        expression:
                                                          "role.display",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      disabled:
                                                        role.value === 3,
                                                      id: role.name,
                                                      "true-value": true,
                                                      "false-value": false,
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        role.display
                                                      )
                                                        ? _vm._i(
                                                            role.display,
                                                            null
                                                          ) > -1
                                                        : role.display,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a = role.display,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                role,
                                                                "display",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                role,
                                                                "display",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            role,
                                                            "display",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "swicth-label",
                                                      attrs: { for: role.name },
                                                    },
                                                    [_vm._v(_vm._s(role.name))]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "dropdown__button button",
                                on: {
                                  click: function ($event) {
                                    return _vm.onAction("delete-item")
                                  },
                                },
                              },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      width: 18,
                                      height: 18,
                                      "view-box": "0 0 24 24",
                                      name: "trash",
                                      color: "#727d92",
                                    },
                                  },
                                  [_c("icon-trash")],
                                  1
                                ),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("delete_journey")) + " "
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "main",
        {
          staticClass: "page__body u-with-sidebar",
          class: { "sidebar-hidden": _vm.sidebarStatus },
        },
        [
          _c("content-library", {
            ref: "contentLibrary",
            attrs: {
              currentJourneyItems: _vm.currentJourneyItems,
              disabled: _vm.canNotEdit,
              isJourneyTypeV2: true,
            },
          }),
          _c(
            "div",
            {
              staticClass: "container-fluid ilist-content",
              attrs: { id: "mainlist" },
            },
            [
              !_vm.parentMounted || _vm.refreshPage
                ? _c("div", {
                    staticClass: "lds-dual-ring",
                    class: { "with-sidebar": !_vm.sidebarStatus },
                  })
                : _vm._e(),
              _vm.parentMounted
                ? [
                    !_vm.showRoleType
                      ? _vm._l(_vm.dailyList, function (adaylist, key, ii) {
                          return _c(
                            "section",
                            {
                              key: `${key}-${ii}`,
                              class:
                                adaylist.length === 0
                                  ? "day-ilist is-ghost"
                                  : "day-ilist",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-between align-items-center pa-2",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "title no-pointer" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("day_number", {
                                              number: key,
                                            })
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  !_vm.canNotEdit
                                    ? _c(
                                        "div",
                                        {
                                          key: "created",
                                          staticClass: "list--create-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.createNewItem(key)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button button--icon",
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  staticClass: "no-pointer",
                                                  attrs: {
                                                    name: "plusfull",
                                                    "view-box": "0 0 448 512",
                                                    width: 17,
                                                    height: 18,
                                                    color: "#979797",
                                                    "is-icon-class": false,
                                                  },
                                                },
                                                [_c("icon-plusfull")],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              adaylist
                                ? _c(
                                    "div",
                                    { staticClass: "ilistings" },
                                    [
                                      _c(
                                        "transition-group",
                                        {
                                          class: _vm.isDropFromLibrary
                                            ? "no-anim"
                                            : "",
                                          attrs: {
                                            appear: "",
                                            type: "transition",
                                            name: "slide-in",
                                            tag: "ul",
                                            id: "itemslist",
                                          },
                                        },
                                        _vm._l(
                                          adaylist,
                                          function (item, index) {
                                            return _c(
                                              "li",
                                              {
                                                key:
                                                  item?.content?.id +
                                                  "-" +
                                                  index,
                                                staticClass:
                                                  "list-group-item ilist list--catalog list--catalog-item button-handle",
                                                style: { "--i": index },
                                                attrs: {
                                                  id:
                                                    _vm.updatedContentId ===
                                                    item?.content?.id
                                                      ? "updatedContent"
                                                      : item?.content?.id,
                                                  "is-disabled": _vm.canNotEdit,
                                                },
                                              },
                                              [
                                                [
                                                  item?.content?.sub_type ===
                                                  "CHAPTER"
                                                    ? [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "js-media-delete",
                                                            attrs: {
                                                              disabled:
                                                                _vm.canNotEdit,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteJourneyItem(
                                                                  item,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "icon-container",
                                                              {
                                                                attrs: {
                                                                  width: 16,
                                                                  height: 16,
                                                                  "view-box":
                                                                    "0 0 20 20",
                                                                  name: "trash",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "icon-trash"
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "card-button",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editContentItem(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "card__body",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "card__content",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "chapter-content",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "chapter-content__title",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  item
                                                                                    ?.content
                                                                                    ?.name
                                                                                ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  item
                                                                                    ?.content
                                                                                    ?.id ===
                                                                                    1
                                                                                    ? "🏁"
                                                                                    : "🚀"
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "chapter-content__description",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  item
                                                                                    ?.content
                                                                                    ?.desc
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "js-media-delete",
                                                            attrs: {
                                                              disabled:
                                                                _vm.canNotEdit,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteJourneyItem(
                                                                  item,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "icon-container",
                                                              {
                                                                attrs: {
                                                                  width: 16,
                                                                  height: 16,
                                                                  "view-box":
                                                                    "0 0 20 20",
                                                                  name: "trash",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "icon-trash"
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "card-button",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editContentItem(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("figure", {
                                                              staticClass:
                                                                "card__media no-pointer",
                                                              class: {
                                                                "card-grey":
                                                                  _vm.journeyHaveUsers &&
                                                                  _vm.itemTimeAlreadyPassed(
                                                                    item
                                                                      ?.content
                                                                      ?.activation_date
                                                                  ),
                                                              },
                                                              style:
                                                                "background-image: url(" +
                                                                item?.content
                                                                  ?.thumbnail_image +
                                                                ");",
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "card__body no-pointer",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "card__content no-pointer",
                                                                  },
                                                                  [
                                                                    !_vm.isHidden(
                                                                      item
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "card__info",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$helpers.getSubTypeName(
                                                                                    item
                                                                                      ?.content
                                                                                      ?.sub_type
                                                                                  )
                                                                                ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  item?.content &&
                                                                                    item
                                                                                      ?.content
                                                                                      ?.quiz &&
                                                                                    item
                                                                                      ?.content
                                                                                      ?.sub_type !=
                                                                                      "QUIZ"
                                                                                    ? _vm.$t(
                                                                                        "has_questions"
                                                                                      )
                                                                                    : ""
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item?.content &&
                                                                    item
                                                                      ?.content
                                                                      ?.name &&
                                                                    !_vm.isHidden(
                                                                      item
                                                                    )
                                                                      ? _c(
                                                                          "h3",
                                                                          {
                                                                            staticClass:
                                                                              "card__title no-pointer",
                                                                          },
                                                                          [
                                                                            _vm.subTypeControl(
                                                                              item?.content
                                                                            )
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          item
                                                                                            ?.content
                                                                                            ?.name
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "card__bottom no-pointer",
                                                                  },
                                                                  [
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "card__bottom__left",
                                                                    }),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "card__bottom__right",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "card__time",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item?.activation_time
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                ],
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        })
                      : _vm._e(),
                    _vm.showRoleType
                      ? [
                          _c(
                            "section",
                            { staticClass: "roleday-ilist rowlabel" },
                            [
                              _c("div", { staticClass: "colheader" }, [
                                _c("div", { staticClass: "title no-pointer" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("roleType")) + " "
                                  ),
                                ]),
                              ]),
                              _vm._l(_vm.roleTypes, function (role) {
                                return [
                                  role.display
                                    ? _c("div", { staticClass: "rolerow" }, [
                                        _c(
                                          "div",
                                          {
                                            key: `${role.value}-${role.name}`,
                                            staticClass: "roleheading",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "rolename" },
                                              [_vm._v(_vm._s(role.name))]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                          _vm._l(_vm.dailyList, function (rolelist, key, ii) {
                            return _c(
                              "section",
                              {
                                key: `${key}-${ii}`,
                                class:
                                  rolelist.length === 0
                                    ? "roleday-ilist is-ghost"
                                    : "roleday-ilist",
                              },
                              [
                                _c("div", { staticClass: "colheader" }, [
                                  _c(
                                    "div",
                                    { staticClass: "title no-pointer" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("day_number", {
                                              number: key,
                                            })
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  !_vm.canNotEdit
                                    ? _c(
                                        "div",
                                        {
                                          key: "created",
                                          staticClass: "list--create-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.createNewItem(key)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button button--icon",
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  staticClass: "no-pointer",
                                                  attrs: {
                                                    name: "plusfull",
                                                    "view-box": "0 0 448 512",
                                                    width: 17,
                                                    height: 18,
                                                    color: "#979797",
                                                    "is-icon-class": false,
                                                  },
                                                },
                                                [_c("icon-plusfull")],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _vm._l(_vm.roleTypes, function (role) {
                                  return [
                                    role.display
                                      ? _c(
                                          "div",
                                          {
                                            key: `${role.name}-${role.value}`,
                                            staticClass: "rolerow",
                                          },
                                          [
                                            role.display &&
                                            rolelist &&
                                            rolelist[role.value] &&
                                            rolelist[role.value].length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "ilistings" },
                                                  [
                                                    _c(
                                                      "transition-group",
                                                      {
                                                        class:
                                                          _vm.isDropFromLibrary
                                                            ? "no-anim rolelisting"
                                                            : "rolelisting",
                                                        attrs: {
                                                          appear: "",
                                                          type: "transition",
                                                          name: "slide-in",
                                                          tag: "ul",
                                                          id: "itemslist",
                                                        },
                                                      },
                                                      _vm._l(
                                                        rolelist[role.value],
                                                        function (item, index) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key:
                                                                item?.content
                                                                  ?.id +
                                                                "-" +
                                                                index,
                                                              staticClass:
                                                                "list-group-item ilist list--catalog list--catalog-item button-handle",
                                                              style: {
                                                                "--i": index,
                                                              },
                                                              attrs: {
                                                                id:
                                                                  _vm.updatedContentId ===
                                                                  item?.content
                                                                    ?.id
                                                                    ? "updatedContent"
                                                                    : item
                                                                        ?.content
                                                                        ?.id,
                                                                "is-disabled":
                                                                  _vm.canNotEdit,
                                                              },
                                                            },
                                                            [
                                                              [
                                                                item?.content
                                                                  ?.sub_type ===
                                                                "CHAPTER"
                                                                  ? [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "js-media-delete",
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                _vm.canNotEdit,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.deleteJourneyItem(
                                                                                  item,
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "icon-container",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  width: 16,
                                                                                  height: 16,
                                                                                  "view-box":
                                                                                    "0 0 20 20",
                                                                                  name: "trash",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "icon-trash"
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "card-button",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.editContentItem(
                                                                                  item
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "card__body",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "card__content",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "chapter-content",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "chapter-content__title",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                item
                                                                                                  ?.content
                                                                                                  ?.name
                                                                                              ) +
                                                                                              " " +
                                                                                              _vm._s(
                                                                                                item
                                                                                                  ?.content
                                                                                                  ?.id ===
                                                                                                  1
                                                                                                  ? "🏁"
                                                                                                  : "🚀"
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "chapter-content__description",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                item
                                                                                                  ?.content
                                                                                                  ?.desc
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  : [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "js-media-delete",
                                                                          attrs:
                                                                            {
                                                                              disabled:
                                                                                _vm.canNotEdit,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.deleteJourneyItem(
                                                                                  item,
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "icon-container",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  width: 16,
                                                                                  height: 16,
                                                                                  "view-box":
                                                                                    "0 0 20 20",
                                                                                  name: "trash",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "icon-trash"
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "card-button",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.editContentItem(
                                                                                  item
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "figure",
                                                                            {
                                                                              staticClass:
                                                                                "card__media no-pointer",
                                                                              class:
                                                                                {
                                                                                  "card-grey":
                                                                                    _vm.journeyHaveUsers &&
                                                                                    _vm.itemTimeAlreadyPassed(
                                                                                      item
                                                                                        ?.content
                                                                                        ?.activation_date
                                                                                    ),
                                                                                },
                                                                              style:
                                                                                "background-image: url(" +
                                                                                item
                                                                                  ?.content
                                                                                  ?.thumbnail_image +
                                                                                ");",
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "card__body no-pointer",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "card__content no-pointer",
                                                                                },
                                                                                [
                                                                                  !_vm.isHidden(
                                                                                    item
                                                                                  )
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "card__info",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.$helpers.getSubTypeName(
                                                                                                  item
                                                                                                    ?.content
                                                                                                    ?.sub_type
                                                                                                )
                                                                                              ) +
                                                                                              " " +
                                                                                              _vm._s(
                                                                                                item?.content &&
                                                                                                  item
                                                                                                    ?.content
                                                                                                    ?.quiz &&
                                                                                                  item
                                                                                                    ?.content
                                                                                                    ?.sub_type !=
                                                                                                    "QUIZ"
                                                                                                  ? _vm.$t(
                                                                                                      "has_questions"
                                                                                                    )
                                                                                                  : ""
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  item?.content &&
                                                                                  item
                                                                                    ?.content
                                                                                    ?.name &&
                                                                                  !_vm.isHidden(
                                                                                    item
                                                                                  )
                                                                                    ? _c(
                                                                                        "h3",
                                                                                        {
                                                                                          staticClass:
                                                                                            "card__title no-pointer",
                                                                                        },
                                                                                        [
                                                                                          _vm.subTypeControl(
                                                                                            item?.content
                                                                                          )
                                                                                            ? _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        item
                                                                                                          ?.content
                                                                                                          ?.name
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "card__bottom no-pointer",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "card__bottom__left",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "card__bottom__right",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "small",
                                                                                        {
                                                                                          staticClass:
                                                                                            "card__time",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item?.activation_time
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                              ],
                                                            ],
                                                            2
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            )
                          }),
                        ]
                      : _vm._e(),
                    _vm.journeyItems.length === 0
                      ? [
                          _c("section", { staticClass: "day-ilist" }, [
                            _c("div", { staticClass: "ilistings" }, [
                              _c("ul", { attrs: { id: "itemslist" } }, [
                                !_vm.canNotEdit
                                  ? _c(
                                      "li",
                                      {
                                        key: "created",
                                        staticClass: "list--create-item-empty",
                                        on: {
                                          click: function ($event) {
                                            return _vm.createNewItem(1)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "button button--icon",
                                          },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                staticClass: "no-pointer",
                                                attrs: {
                                                  name: "plusfull",
                                                  "view-box": "0 0 448 512",
                                                  width: 17,
                                                  height: 18,
                                                  color: "#979797",
                                                  "is-icon-class": false,
                                                },
                                              },
                                              [_c("icon-plusfull")],
                                              1
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("create_new_one")
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" }, on: { enter: _vm.enterAlert } },
        [
          _vm.addedContentStatus
            ? _c(
                "div",
                { staticClass: "alert alert--success" },
                [
                  _c(
                    "icon-container",
                    {
                      staticClass: "icon icon-check size-xl no-pointer",
                      attrs: {
                        name: "check",
                        "view-box": "0 0 512 512",
                        color: "#FFF",
                      },
                    },
                    [_c("icon-check")],
                    1
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("saved")) + " "),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }