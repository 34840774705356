var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "currentColor" } }, [
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M5.9558 1.40303C4.53033 1.40303 3.37062 2.60428 3.37062 4.08082C3.37062 5.55723 4.53033 6.75849 5.9558 6.75849C7.38127 6.75849 8.54098 5.55723 8.54098 4.08082C8.54098 2.60428 7.38127 1.40303 5.9558 1.40303M5.9558 8.03065C3.85327 8.03065 2.14258 6.25854 2.14258 4.08082C2.14258 1.90297 3.85327 0.130859 5.9558 0.130859C8.05833 0.130859 9.76902 1.90297 9.76902 4.08082C9.76902 6.25854 8.05833 8.03065 5.9558 8.03065",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M0.613702 13.9184C0.532986 13.9184 0.450376 13.9021 0.371176 13.8665C0.059681 13.7278 -0.0839401 13.3537 0.0503336 13.0307C1.06591 10.5868 3.38381 9.00732 5.95572 9.00732C8.52701 9.00732 10.845 10.5868 11.8611 13.0307C11.9954 13.3532 11.8518 13.7278 11.5403 13.8665C11.2274 14.0086 10.8671 13.8563 10.7334 13.5342C9.91118 11.5573 8.03602 10.2795 5.95572 10.2795C3.87543 10.2795 1.99977 11.5573 1.17808 13.5342C1.07817 13.7748 0.851807 13.9184 0.613702 13.9184",
        fill: "currentColor",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }