var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
      _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("header", { staticClass: "modal-header" }, [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(" " + _vm._s(_vm.$t("nps_score")) + " "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: { type: "button" },
              domProps: { innerHTML: _vm._s("&times;") },
              on: {
                click: function ($event) {
                  return _vm.closeModal()
                },
              },
            }),
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "form form--horizontal form--strong" }, [
              _c("div", { staticClass: "form form--horizontal" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row justify-content-between align-items-center w-100",
                  },
                  [
                    _c(
                      "figure",
                      { staticClass: "highcharts-figure w-auto" },
                      [
                        _c("highcharts", {
                          ref: "completionGauge",
                          staticClass: "chart-container",
                          attrs: { options: _vm.npsChartOptions },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "d-flex flex-column w-100" }, [
                      _c("div", { staticClass: "d-flex flex-row w-100 mb-1" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#2F373E",
                              "background-color": "#FFACC1",
                              "border-radius": "10px",
                              padding: "2px 8px",
                              "margin-right": "10px",
                              "font-size": "14px",
                              "font-weight": "bold",
                              width: "55%",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("detractors")) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-center",
                            staticStyle: {
                              color: "#fff",
                              "background-color": "#E63D68",
                              "border-radius": "50%",
                              "min-width": "26px",
                              "text-align": "center",
                              "margin-right": "10px",
                              "font-size": "14px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.detractors_total) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#2F373E",
                              "background-color": "#FFACC1",
                              "border-radius": "10px",
                              padding: "2px 8px",
                              "font-weight": "bold",
                              "font-size": "14px",
                              width: "58px",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (
                                    (_vm.detractors_total / _vm.total || 0) *
                                    100
                                  ).toFixed(1) + "%"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "d-flex flex-row w-100 mb-1" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#2F373E",
                              "background-color": "#FFE1A0",
                              "border-radius": "10px",
                              padding: "2px 8px",
                              "margin-right": "10px",
                              "font-size": "14px",
                              "font-weight": "bold",
                              width: "55%",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("passives")) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-center",
                            staticStyle: {
                              color: "#fff",
                              "background-color": "#F8BA04",
                              "border-radius": "50%",
                              "min-width": "26px",
                              "text-align": "center",
                              "font-size": "14px",
                              "margin-right": "10px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.passives_total) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#2F373E",
                              "background-color": "#FFE1A0",
                              "border-radius": "10px",
                              padding: "2px 8px",
                              "font-weight": "bold",
                              "font-size": "14px",
                              width: "58px",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (
                                    (_vm.passives_total / _vm.total || 0) * 100
                                  ).toFixed(1) + "%"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "d-flex flex-row w-100 mb-1" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#2F373E",
                              "background-color": "#87DBC1",
                              "border-radius": "10px",
                              padding: "2px 8px",
                              "margin-right": "10px",
                              "font-size": "14px",
                              "font-weight": "bold",
                              width: "55%",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("promoters")) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-center",
                            staticStyle: {
                              color: "#fff",
                              "background-color": "#3DB785",
                              "border-radius": "50%",
                              "min-width": "26px",
                              "text-align": "center",
                              "font-size": "14px",
                              "margin-right": "10px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.promoters_total) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#2F373E",
                              "background-color": "#87DBC1",
                              "border-radius": "10px",
                              padding: "2px 8px",
                              "font-weight": "bold",
                              "font-size": "14px",
                              width: "58px",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (
                                    (_vm.promoters_total / _vm.total || 0) * 100
                                  ).toFixed(1) + "%"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column w-auto ml-2 justify-content-center align-items-center",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#fff",
                              "background-color": "#2F373E",
                              "border-radius": "10px",
                              padding: "2px 8px",
                              "margin-right": "10px",
                              "font-size": "14px",
                              "font-weight": "bold",
                              "margin-bottom": "2px",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("total")) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-center",
                            staticStyle: {
                              color: "#2F373E",
                              "background-color": "#9B9B9B",
                              "border-radius": "50%",
                              height: "50px",
                              width: "50px",
                              "text-align": "center",
                              "padding-top": "2px",
                              "margin-right": "10px",
                              "font-size": "24px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.total) + " ")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "button button--primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal()
                      },
                    },
                  },
                  [_c("span", [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")])]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }