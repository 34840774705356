var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal", class: { "is-loading": _vm.formSending } },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(" " + _vm._s(_vm.$t("add_user")) + " "),
              ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "tab-content" }, [
                _c("div", { staticClass: "tab-pane" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "user" },
                            },
                            [_vm._v(_vm._s(_vm.$t("users")))]
                          ),
                          _c("vue-tags-input", {
                            class: { "is-danger": _vm.errors.has("add.user") },
                            attrs: {
                              tags: _vm.users,
                              "autocomplete-items": _vm.autocompleteItems,
                              "add-only-from-autocomplete": true,
                              "delete-on-backspace": false,
                              placeholder: _vm.$t("adduser_placeholder"),
                            },
                            on: { "tags-changed": _vm.inputUpdateUsers },
                            model: {
                              value: _vm.user,
                              callback: function ($$v) {
                                _vm.user = $$v
                              },
                              expression: "user",
                            },
                          }),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.usersSelected,
                                expression: "usersSelected",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              type: "hidden",
                              "data-vv-scope": "add",
                              "data-vv-name": "user",
                              "data-vv-as": _vm.$t("users"),
                            },
                            domProps: { value: _vm.usersSelected },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.usersSelected = $event.target.value
                              },
                            },
                          }),
                          _vm.errors.has("add.user")
                            ? _c("span", { staticClass: "help is-danger" }, [
                                _vm._v(_vm._s(_vm.errors.first("add.user"))),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "journeyLanguage" },
                            },
                            [_vm._v(_vm._s(_vm.$t("activation_date")))]
                          ),
                          _c("date-picker", {
                            class: {
                              "is-danger": _vm.errors.has("add.beginDate"),
                            },
                            attrs: {
                              type: "date",
                              "value-type": "date",
                              "first-day-of-week":
                                _vm.userLang === "en" ? 7 : 1,
                              lang: _vm.userLang,
                              format:
                                _vm.userLang === "en"
                                  ? "MM.DD.YYYY"
                                  : "DD.MM.YYYY",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.adjustTime("date")
                              },
                            },
                            model: {
                              value: _vm.eventItemDateTime,
                              callback: function ($$v) {
                                _vm.eventItemDateTime = $$v
                              },
                              expression: "eventItemDateTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.items.length > 0,
                          expression: "errors.items.length > 0",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "alert alert--card alert--error" },
                        [
                          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function (error, index) {
                              return _c("li", { key: index }, [
                                _vm._v(" " + _vm._s(error.msg) + " "),
                              ])
                            }),
                            0
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button button--primary no-wrap",
                        attrs: { type: "button", disabled: _vm.isPending },
                        on: {
                          click: function ($event) {
                            return _vm.addUser()
                          },
                        },
                      },
                      [
                        !_vm.isPending
                          ? _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("add")) + " "),
                            ])
                          : _vm._e(),
                        _vm.isPending
                          ? _c("div", { staticClass: "lds-ellipsis" }, [
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }