var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "child-header-navigation": _vm.userSubNavigation,
            "is-child-header-left-actions": "",
            "add-group": "",
          },
        },
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "group-users--title" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Groups",
                  },
                },
              },
              [
                _c(
                  "icon-container",
                  {
                    attrs: {
                      width: 40,
                      height: 18,
                      name: "Go Back",
                      color: "#727d92",
                    },
                  },
                  [_c("icon-goback")],
                  1
                ),
              ],
              1
            ),
            _vm._v(" " + _vm._s(_vm.groupName)),
            _c("small", [_vm._v(" " + _vm._s(_vm.$t("user_list")))]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _vm.tableIsLoading
              ? _c("div", { staticClass: "lds-dual-ring" })
              : _vm._e(),
            _c("vue-table", {
              ref: "myvuetable",
              class: { "table-fade": _vm.tableIsLoading },
              attrs: {
                "api-url": _vm.apiUrl,
                "http-fetch": _vm.myFetch,
                "api-mode": true,
                "pagination-path": "meta",
                "http-method": "get",
                "append-params": _vm.moreParams,
                "http-options": {
                  headers: {
                    Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                  },
                },
                fields: _vm.fields,
                "no-data-template": _vm.$t("no_data_info"),
                css: _vm.css.table,
                "per-page": _vm.perPage,
                "query-params": _vm.makeQueryParams,
              },
              on: {
                "vuetable:loading": function ($event) {
                  _vm.tableIsLoading = true
                },
                "vuetable:loaded": function ($event) {
                  _vm.tableIsLoading = false
                },
                "vuetable:load-error": _vm.vuetableLoadError,
                "vuetable:pagination-data": _vm.onPaginationData,
              },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "ui button",
                          on: {
                            click: function ($event) {
                              return _vm.onAction("edit-item", props.rowData)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 20,
                                height: 20,
                                "view-box": "0 0 24 24",
                                name: "edit",
                                color: "#727d92",
                              },
                            },
                            [_c("icon-edit")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "ui button",
                          on: {
                            click: function ($event) {
                              return _vm.onAction("user-detail", props.rowData)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                name: "search",
                                width: 20,
                                height: 20,
                                color: "#727d92",
                                "view-box": "0 0 15 15",
                                "is-icon-class": false,
                              },
                            },
                            [_c("icon-search")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "ui button delete",
                          on: {
                            click: function ($event) {
                              return _vm.onAction(
                                "delete-item",
                                props.rowData,
                                props.rowIndex
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 20,
                                height: 20,
                                "view-box": "0 0 24 24",
                                name: "trash",
                                color: "#727d92",
                              },
                            },
                            [_c("icon-trash")],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              { staticClass: "pagination__info" },
              [
                _c("vuetable-pagination-info", {
                  ref: "paginationInfo",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "info-template": _vm.$t("pagination_info"),
                    "no-data-template": _vm.$t("no_data_info"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "pagination__items" },
                  [
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }