var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "button-group" }, [
    _c("div", { staticClass: "button-group__item dropdown" }, [
      _c(
        "label",
        {
          staticClass:
            "button button--shadow button--type-icon size-sm d-flex align-items-center",
          on: {
            click: function ($event) {
              return _vm.dropdownShow("showFilters")
            },
          },
        },
        [
          _c(
            "icon-container",
            {
              attrs: {
                name: "search",
                width: 14,
                height: 14,
                color: "#727d92",
                "view-box": "0 0 14 14",
                "is-icon-class": false,
              },
            },
            [_c("icon-search")],
            1
          ),
        ],
        1
      ),
      _vm.isDropdown === "showFilters"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.hideDropdown,
                  expression: "hideDropdown",
                },
              ],
              staticClass: "dropdown__menu dropdown__menu--filter",
            },
            [
              _c("div", { staticClass: "form-group form-group--filter" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filterText,
                      expression: "filterText",
                    },
                    { name: "sanitize", rawName: "v-sanitize" },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("filter_by_name"),
                  },
                  domProps: { value: _vm.filterText },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.filterText = $event.target.value
                      },
                      _vm.debounceInput,
                    ],
                  },
                }),
                _vm.filterText
                  ? _c(
                      "button",
                      {
                        staticClass: "icon button-reset",
                        on: { click: _vm.resetFilter },
                      },
                      [
                        _c(
                          "icon-container",
                          { attrs: { name: "reset", "view-box": "0 0 20 20" } },
                          [_c("icon-reset")],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "button-group__item dropdown" }, [
      _c(
        "button",
        {
          staticClass: "button button--shadow button__dropdown size-sm",
          class: { active: _vm.isDropdown === "perPageDropdown" },
          on: {
            click: function ($event) {
              return _vm.dropdownShow("perPageDropdown")
            },
          },
        },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("view")) + " " + _vm._s(_vm.perPage) + " "
          ),
          _c(
            "icon-container",
            {
              attrs: {
                name: "down",
                "view-box": "0 0 11 8",
                "is-icon-class": false,
              },
            },
            [_c("icon-down")],
            1
          ),
        ],
        1
      ),
      _vm.isDropdown === "perPageDropdown"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.hideDropdown,
                  expression: "hideDropdown",
                },
              ],
              staticClass: "dropdown__menu dropdown__menu--per-page",
            },
            _vm._l(_vm.pageOptions, function (option) {
              return _c(
                "div",
                {
                  key: option.value,
                  staticClass: "dropdown__menu-item",
                  on: {
                    click: function ($event) {
                      return _vm.pageOptionsSelected(option.value)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(option.text) + " ")]
              )
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }