var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(" " + _vm._s(_vm.$t("send_notification")) + " "),
              ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "form form--horizontal form--strong" }, [
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-12 col-md-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "notificationHeading" },
                      },
                      [_vm._v(_vm._s(_vm.$t("notification_heading")))]
                    ),
                  ]),
                  _c("div", { staticClass: "col-12 col-md-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.notificationHeading,
                          expression: "notificationHeading",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:40",
                          expression: "'required|max:40'",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-danger": _vm.errors.has("notification.heading"),
                      },
                      attrs: {
                        type: "text",
                        id: "notificationHeading",
                        "data-vv-scope": "notification",
                        "data-vv-name": "heading",
                        "data-vv-as": _vm.$t("notification_heading"),
                      },
                      domProps: { value: _vm.notificationHeading },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.notificationHeading = $event.target.value
                        },
                      },
                    }),
                    _c("button", {
                      staticClass: "emoji-button",
                      domProps: { innerHTML: _vm._s("☺") },
                      on: {
                        click: function ($event) {
                          return _vm.dropdownToggle("head")
                        },
                      },
                    }),
                    _vm.dropdownIndex === "head"
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "on-clickaway",
                                rawName: "v-on-clickaway",
                                value: _vm.hideDropdown,
                                expression: "hideDropdown",
                              },
                            ],
                            staticClass: "dropdown",
                            staticStyle: { "z-index": "1000001" },
                          },
                          [
                            _c(
                              "div",
                              {
                                ref: "dropdownBoxhead",
                                staticClass:
                                  "dropdown__menu emoji-dropdown-title",
                              },
                              [
                                _c("VEmojiPicker", {
                                  attrs: {
                                    pack: _vm.emojiPack,
                                    "label-search":
                                      _vm.$t("search_placeholder"),
                                  },
                                  on: { select: _vm.SelectEmojiForHeading },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.errors.has("notification.heading")
                      ? _c("span", { staticClass: "help is-danger" }, [
                          _vm._v(
                            _vm._s(_vm.errors.first("notification.heading"))
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-12 col-md-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-label",
                        attrs: { for: "contentTitle" },
                      },
                      [_vm._v(_vm._s(_vm.$t("notification_body")))]
                    ),
                  ]),
                  _c("div", { staticClass: "col-12 col-md-8" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.notificationBody,
                          expression: "notificationBody",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:110",
                          expression: "'required|max:110'",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-danger": _vm.errors.has("notification.body"),
                      },
                      attrs: {
                        id: "notificationBody",
                        "data-vv-scope": "notification",
                        "data-vv-name": "body",
                        "data-vv-as": _vm.$t("journey_name"),
                        rows: "20",
                      },
                      domProps: { value: _vm.notificationBody },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.notificationBody = $event.target.value
                        },
                      },
                    }),
                    _c("button", {
                      staticClass: "emoji-button",
                      domProps: { innerHTML: _vm._s("☺") },
                      on: {
                        click: function ($event) {
                          return _vm.dropdownToggle("body")
                        },
                      },
                    }),
                    _vm.dropdownIndex === "body"
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "on-clickaway",
                                rawName: "v-on-clickaway",
                                value: _vm.hideDropdown,
                                expression: "hideDropdown",
                              },
                            ],
                            staticClass: "dropdown",
                            staticStyle: { "z-index": "1000001" },
                          },
                          [
                            _c(
                              "div",
                              {
                                ref: "dropdownBoxbody",
                                staticClass:
                                  "dropdown__menu emoji-dropdown-body",
                              },
                              [
                                _c("VEmojiPicker", {
                                  attrs: {
                                    pack: _vm.emojiPack,
                                    "label-search":
                                      _vm.$t("search_placeholder"),
                                  },
                                  on: { select: _vm.SelectEmojiForBody },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.errors.has("notification.body")
                      ? _c("span", { staticClass: "help is-danger" }, [
                          _vm._v(_vm._s(_vm.errors.first("notification.body"))),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.items.length > 0,
                        expression: "errors.items.length > 0",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "alert alert--card alert--error" },
                      [
                        _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
                        _c(
                          "ul",
                          _vm._l(_vm.errors, function (error, index) {
                            return _c("li", { key: index }, [
                              _vm._v(" " + _vm._s(error.msg) + " "),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button button--primary",
                      attrs: { type: "button", disabled: _vm.isPending },
                      on: {
                        click: function ($event) {
                          return _vm.sendNotification()
                        },
                      },
                    },
                    [
                      !_vm.isPending
                        ? _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("send")) + " "),
                          ])
                        : _vm._e(),
                      _vm.isPending
                        ? _c("div", { staticClass: "lds-ellipsis" }, [
                            _c("div"),
                            _c("div"),
                            _c("div"),
                            _c("div"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }