var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "none" } }, [
    _c("rect", { attrs: { width: "75", height: "44", fill: "#fff", rx: "6" } }),
    _c("rect", {
      attrs: {
        width: "74",
        height: "43",
        x: ".5",
        y: ".5",
        stroke: "#727D92",
        "stroke-opacity": ".5",
        rx: "5.5",
      },
    }),
    _c("path", {
      attrs: {
        fill: "#394C71",
        d: "M36.11 24.12h4.38V25h-5.32v-1.12c0-.88.33-1.54 1-1.95.22-.14.46-.26.72-.36l1.5-.6c.76-.3 1.14-.8 1.14-1.5 0-.39-.13-.7-.4-.94a1.57 1.57 0 00-1.1-.37c-.48 0-.87.1-1.17.3-.3.2-.47.5-.52.9H35.3c.04-.66.3-1.16.79-1.51a3.12 3.12 0 011.87-.53c.77 0 1.4.2 1.87.62.48.42.72.94.72 1.55 0 .61-.16 1.1-.48 1.45-.3.36-.74.65-1.3.87l-1.52.61c-.38.16-.67.34-.86.55-.18.2-.28.47-.28.8v.35zM11.37 18.04c.54-.07 1-.28 1.4-.61h.78V25h-.94v-6.44c-.4.25-.81.4-1.24.42v-.94zM64.97 19.38c0 .8-.44 1.36-1.32 1.67.48.14.86.36 1.16.67.3.32.44.72.44 1.2 0 .67-.25 1.2-.75 1.61-.5.4-1.17.6-2 .6a3.5 3.5 0 01-2.1-.63 2.14 2.14 0 01-.87-1.82h.98c.02.52.2.9.57 1.18.37.27.83.4 1.39.4.56 0 1-.12 1.3-.39.3-.27.46-.6.46-1s-.15-.72-.44-.97c-.3-.25-.78-.38-1.44-.38h-.87v-.76h.6c.57 0 1.02-.1 1.36-.33.34-.23.5-.54.5-.93 0-.38-.13-.7-.4-.94a1.54 1.54 0 00-1.08-.38c-.45 0-.83.08-1.13.24-.3.16-.5.44-.6.87h-.98c.08-.67.36-1.16.82-1.48.47-.33 1.06-.49 1.8-.49.73 0 1.34.2 1.84.59.5.38.76.87.76 1.47z",
      },
    }),
    _c("path", {
      attrs: {
        stroke: "#727D92",
        "stroke-opacity": ".5",
        d: "M25.5 1v42M50.5 1v42",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }