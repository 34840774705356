var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(" " + _vm._s(_vm.$t("user_journey_contents")) + " "),
              ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _vm.modalData
                  ? _c("ReportJourneyUserJourneyInside", {
                      attrs: {
                        "prop-user-id": _vm.modalData.userId,
                        "prop-journey-id": _vm.modalData.journeyId,
                        "prop-journey-name": _vm.modalData.journeyName,
                        "prop-user-surname": _vm.modalData.userSurname,
                        "prop-user-name": _vm.modalData.userName,
                        "prop-user-email": _vm.modalData.userEmail,
                        "prop-user-employee-no": _vm.modalData.userEmployeeNo,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }