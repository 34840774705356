var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "currentColor" } }, [
    _c("path", {
      attrs: {
        d: "M167.5 44.1c-3.2.9-48.6 45.5-51.1 50.1-4.1 7.7.6 17.9 9.1 19.8 6.7 1.5 10.1-.4 21.8-11.8L158 91.6v66.8c0 73.9-.2 71.7 6.4 75.7 4 2.5 11.2 2.5 15.2 0 6.6-4 6.4-1.8 6.4-75.7V91.6l10.8 10.6c11.6 11.4 15 13.3 21.7 11.8 8.5-1.9 13.2-12.1 9.1-19.8-.8-1.5-12-13.2-24.8-26-25.8-25.7-27.1-26.6-35.3-24.1z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M77 123.1c-16 3.1-29.8 17.6-32.9 34.3-1.4 7.8-1.5 100.2-.1 107.7 3.4 17.8 17.1 31.5 34.9 34.9 7.6 1.4 178.8 1.4 186.6-.1 17.3-3.2 31.1-17.2 34.5-34.8 1.4-7.4 1.3-99.9-.1-107.5-3.2-17.3-17-31.4-33.8-34.6-13.7-2.6-23.7-.5-27.3 5.8-3.6 6.1-1.3 16.2 4.4 19.4 1.3.7 6.2 1.5 11 1.8 9.4.6 12.1 1.9 16.2 7.4 2.1 2.7 2.1 3.8 2.1 54.1 0 50.3 0 51.4-2.1 54.1-1.1 1.5-3.3 3.7-4.8 4.8-2.7 2.1-3.4 2.1-93.6 2.1-90.2 0-90.9 0-93.6-2.1-1.5-1.1-3.7-3.3-4.8-4.8-2.1-2.7-2.1-3.8-2.1-54.1 0-50.3 0-51.4 2.1-54.1 4.1-5.5 6.8-6.8 16.2-7.4 4.8-.3 9.7-1.1 11-1.8 5.7-3.2 8-13.3 4.5-19.4-3.7-6.2-14.5-8.4-28.3-5.7z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }