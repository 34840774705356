var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column justify-content-center align-items-center py-4",
                },
                [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("add_content_journey")) + " "),
                  ]),
                  _c("div", { staticClass: "d-block my-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.contentDetail.name) +
                        " " +
                        _vm._s(_vm.arrowok) +
                        " " +
                        _vm._s(_vm.journeyDetail.name) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _vm.pageIsLoading
                  ? _c("div", { staticClass: "page-loader" }, [
                      _c("div", { staticClass: "page-is-loading" }),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-md-4" }, [
                    _c("div", { staticClass: "form-group u-zIndex-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "form-label",
                          attrs: { for: "beginDate" },
                        },
                        [_vm._v(_vm._s(_vm.$t("begin_day")))]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.day,
                            expression: "day",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "form-control",
                        class: { "is-danger": _vm.errors.has("assign.day") },
                        attrs: {
                          type: "number",
                          id: "dayinput",
                          min: "1",
                          "data-vv-scope": "assign",
                          "data-vv-name": "day",
                          "data-vv-as": _vm.$t("begin_day"),
                        },
                        domProps: { value: _vm.day },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.day = $event.target.value
                          },
                        },
                      }),
                      _c("span", { staticClass: "daytext" }, [
                        _vm._v(_vm._s(_vm.$t("day"))),
                      ]),
                      _vm.errors.has("assign.day")
                        ? _c("span", { staticClass: "help is-danger" }, [
                            _vm._v(_vm._s(_vm.errors.first("assign.day"))),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-12 col-md-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group u-zIndex-3" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "form-label",
                            attrs: { for: "beginTime" },
                          },
                          [_vm._v(_vm._s(_vm.$t("begin_time")))]
                        ),
                        _c(
                          "date-picker",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            class: {
                              "is-danger": _vm.errors.has("assign.beginTime"),
                            },
                            attrs: {
                              format:
                                _vm.userLang === "en" ? "hh:mm A" : "HH:mm",
                              type: "time",
                              "value-type": "date",
                              "data-vv-scope": "assign",
                              "data-vv-name": "beginTime",
                              "data-vv-as": _vm.$t("begin_time"),
                              lang: _vm.userLang,
                              "time-picker-options": {
                                start: "08:00",
                                step: "00:15",
                                end: "23:45",
                                format:
                                  _vm.userLang === "en" ? "hh:mm A" : "HH:mm",
                              },
                            },
                            on: {
                              change: function ($event) {
                                return _vm.adjustTime("time")
                              },
                            },
                            model: {
                              value: _vm.beginActivationDateTime,
                              callback: function ($$v) {
                                _vm.beginActivationDateTime = $$v
                              },
                              expression: "beginActivationDateTime",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "icon-calendar" },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      name: "time",
                                      "view-box": "0 0 512 512",
                                    },
                                  },
                                  [_c("icon-time")],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                        _vm.errors.has("assign.beginTime")
                          ? _c("span", { staticClass: "help is-danger" }, [
                              _vm._v(
                                _vm._s(_vm.errors.first("assign.beginTime"))
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-4" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "form-label",
                          attrs: { for: "roleType" },
                        },
                        [_vm._v(_vm._s(_vm.$t("roleType")))]
                      ),
                      _c("multi-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "",
                            expression: "''",
                          },
                        ],
                        staticClass: "selectbox selectbox--secondary",
                        class: { "is-danger": _vm.errors.has("all.roleType") },
                        attrs: {
                          "track-by": "value",
                          name: "roleType",
                          label: "label",
                          "clear-on-select": "",
                          "deselect-label": "",
                          "select-label": "",
                          "data-vv-scope": "all",
                          "data-vv-name": "roleType",
                          options: _vm.roleTypeOptions,
                          "data-vv-as": _vm.$t("roleType"),
                        },
                        model: {
                          value: _vm.roleType,
                          callback: function ($$v) {
                            _vm.roleType = $$v
                          },
                          expression: "roleType",
                        },
                      }),
                      _vm.errors.has("all.roleType")
                        ? _c("span", { staticClass: "help is-danger" }, [
                            _vm._v(_vm._s(_vm.errors.first("all.roleType"))),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { class: ["panel-title", _vm.showPanel ? "active" : ""] },
                  [
                    _c(
                      "label",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.setPanel()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("advanced_tab")))]
                    ),
                  ]
                ),
                _c(
                  "collapse-transition",
                  { attrs: { duration: 500, dimension: "height" } },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showPanel,
                            expression: "showPanel",
                          },
                        ],
                        staticClass:
                          "form-group bglight justify-content-center my-4 p-4 flex-column",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col ma-2" }, [
                            _c("div", { staticClass: "swicth-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.is_email_enabled,
                                    expression: "is_email_enabled",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "sendEmail",
                                  "true-value": 1,
                                  "false-value": 0,
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.is_email_enabled)
                                    ? _vm._i(_vm.is_email_enabled, null) > -1
                                    : _vm._q(_vm.is_email_enabled, 1),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.is_email_enabled,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? 1 : 0
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.is_email_enabled = $$a.concat([
                                            $$v,
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.is_email_enabled = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.is_email_enabled = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "swicth-label",
                                  attrs: { for: "sendEmail" },
                                },
                                [_vm._v(_vm._s(_vm.$t("send_email")))]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col" }, [
                            _c("div", { staticClass: "swicth-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.is_notifications_enabled,
                                    expression: "is_notifications_enabled",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "sendPushNotification",
                                  "true-value": 1,
                                  "false-value": 0,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.is_notifications_enabled
                                  )
                                    ? _vm._i(
                                        _vm.is_notifications_enabled,
                                        null
                                      ) > -1
                                    : _vm._q(_vm.is_notifications_enabled, 1),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.is_notifications_enabled,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? 1 : 0
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.is_notifications_enabled =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.is_notifications_enabled = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.is_notifications_enabled = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "swicth-label",
                                  attrs: { for: "sendPushNotification" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("send_push_notif")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col" }, [
                            _c("div", { staticClass: "swicth-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.is_info_labels_hidden,
                                    expression: "is_info_labels_hidden",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "hideLabel",
                                  "true-value": 1,
                                  "false-value": 0,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.is_info_labels_hidden
                                  )
                                    ? _vm._i(_vm.is_info_labels_hidden, null) >
                                      -1
                                    : _vm._q(_vm.is_info_labels_hidden, 1),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.is_info_labels_hidden,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? 1 : 0
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.is_info_labels_hidden =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.is_info_labels_hidden = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.is_info_labels_hidden = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "swicth-label ma-2",
                                  attrs: { for: "hideLabel" },
                                },
                                [_vm._v(_vm._s(_vm.$t("hide_title")))]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.$t("hide_title_tooltip_msg"),
                                      expression:
                                        "$t('hide_title_tooltip_msg')",
                                    },
                                  ],
                                  staticClass: "vtooltip",
                                },
                                [
                                  _c("em", {
                                    staticClass: "fa fa-question-circle",
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button button--primary",
                      attrs: { type: "button", disabled: _vm.isPending },
                      on: { click: _vm.createContent },
                    },
                    [
                      !_vm.isPending
                        ? _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("create_button")) + " "),
                          ])
                        : _vm._e(),
                      _vm.isPending
                        ? _c("div", { staticClass: "lds-ellipsis" }, [
                            _c("div"),
                            _c("div"),
                            _c("div"),
                            _c("div"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }