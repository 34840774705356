<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{'is-loading': formSending }"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ $t('add_user') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="tab-content">
              <div class="tab-pane">
                <div class="row ">
                  <div class="col-12">
                    <div class="form-group">
                      <label
                        for="user"
                        class="form-label"
                      >{{ $t('users') }}</label>
                      <vue-tags-input
                        :class="{ 'is-danger': errors.has('add.user') }"
                        v-model="user"
                        :tags="users"
                        @tags-changed="inputUpdateUsers"
                        :autocomplete-items="autocompleteItems"
                        :add-only-from-autocomplete="true"
                        :delete-on-backspace="false"
                        :placeholder="$t('adduser_placeholder')"
                      />
                      <input
                        type="hidden"
                        data-vv-scope="add"
                        data-vv-name="user"
                        :data-vv-as="$t('users')"
                        v-model="usersSelected"
                        v-validate="'required'"
                      >
                      <span
                        v-if="errors.has('add.user')"
                        class="help is-danger"
                      >{{ errors.first('add.user') }}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label
                        for="journeyLanguage"
                        class="form-label"
                      >{{ $t('activation_date') }}</label>
                      <date-picker
                        :class="{ 'is-danger': errors.has('add.beginDate') }"
                        v-model="eventItemDateTime"
                        type="date"
                        value-type="date"
                        :first-day-of-week="userLang === 'en' ? 7 : 1"
                        :lang="userLang"
                        :format="userLang === 'en' ? 'MM.DD.YYYY' : 'DD.MM.YYYY'"
                        @change="adjustTime('date')"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t('error_msg_title') }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary no-wrap"
                    @click="addUser()"
                    :disabled="isPending"
                  >
                    <span
                      v-if="!isPending"
                    >
                      {{ $t('add') }}
                    </span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import vueTagsInput from '@johmun/vue-tags-input';
import { mapState } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/tr';
import 'vue2-datepicker/index.css';
import API from '@/services/';
import {
  API_USER_DATATABLES,
  API_SERVER_TIME_FORMAT,
  API_USER_GROUP_DATATABLES,
  API_JOURNEY_V2_USER_ASSIGMENT_ADD,
} from '@/helpers/config';

export default {
  name: 'ModalJourneyV2AddUser',
  inject: ['$validator'],

  data() {
    return {
      isPending: false,
      formSending: false,
      usersToAdd: [],
      user: '',
      users: [],
      userGroups: [],
      debounce: null,
      usersSelected: null,
      eventItemDateTime: new Date(),
      eventItemDateTimeOldValue: null,
    };
  },

  components: {
    vueTagsInput,
    'date-picker': DatePicker,
  },

  watch: {
    user: 'getUsers',
    users() {
      if (this.users && this.users.length > 0) {
        this.usersSelected = this.users[0].text;
      } else {
        this.usersSelected = null;
      }
      this.$nextTick(() => {
        this.$validator.validate('add.user', this.usersSelected);
      });
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
    journeyId() {
      return this.$store.state.modal.journeyId;
    },
    journeyCustomerId: {
      get() {
        return this.$store.state.modal.journeyCustomerId;
      },
      set(val) {
        this.$store.state.modal.journeyCustomerId = val;
      },
    },
    currentCustomerId() {
      return this.$store.state.auth.customerId;
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
    autocompleteItems() { // eslint-disable-next-line
      this.usersToAdd = this.usersToAdd.concat(this.userGroups);
      return this.usersToAdd;
    },
  },
  beforeDestroy() {
    this.journeyCustomerId = null;
  },
  methods: {

    closeModal() {
      this.$store.commit('modal/toggleModal');
    },
    adjustTime(type) {
      if (this.eventItemDateTime !== null) {
        if (this.eventItemDateTimeOldValue && type === 'date') {
          const date = moment(this.eventItemDateTime, API_SERVER_TIME_FORMAT).format('YYYY-MM-DD');
          const time = moment(this.eventItemDateTimeOldValue, API_SERVER_TIME_FORMAT).format('HH:mm:ss');
          this.eventItemDateTime = moment(`${date} ${time}`, API_SERVER_TIME_FORMAT).toDate();
        } else {
          this.eventItemDateTime.setHours(8, 0, 0);
        }
      }
    },
    notBeforeToday(date) {
      const now = new Date();
      return date < new Date(now.getFullYear(), now.getMonth(), now.getDate());
    },
    inputUpdateUsers(newTags) {
      this.users = newTags;
    },

    getUsers() {
      this.usersToAdd = [];
      this.userGroups = [];
      const formData = { params: {} };
      formData.params.per_page = 10000;
      formData.params.customer_id = this.journeyCustomerId || this.currentCustomerId;
      formData.params.search = this.user;
      if (this.user.length < 2) {
        return;
      }
      const searchInput = document.querySelector('.ti-new-tag-input');
      searchInput.classList.add('loading-icon');
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        // this.getUserGroups();
        API.get(API_USER_DATATABLES, formData)
          .then((response) => {
            this.usersToAdd = response.data.data.map(a => (
              {
                text: `${a.first_name} ${a.last_name} [ ${a.email} ]`,
                id: `${a.id}`,
              }
            ));
            searchInput.classList.remove('loading-icon');
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            searchInput.classList.remove('loading-icon');
          });
      }, 300);
    },

    getUserGroups() {
      const userData = { params: {} };
      userData.params.per_page = 10000;
      userData.params.search = this.user;
      userData.params.customer_id = this.journeyCustomerId || this.currentCustomerId;

      API.get(API_USER_GROUP_DATATABLES, userData)
        .then((response) => { // eslint-disable-next-line
          this.userGroups = response.data.data.map((item) => {
            return {
              text: `[User Group] ${item.name}`,
              id: item.id,
              userGroup: true,
            };
          });
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    addUser() {
      this.$validator.validate('add.user').then(async (result) => {
        if (result && this.users) {
          this.formSending = true;
          this.isPending = true;
          const self = this;
          const successMsg = self.$t('added_success');
          const formData = new FormData();
          formData.append('as_journey2_id', self.journeyId);
          formData.append('activation_date', `${moment(this.eventItemDateTime, API_SERVER_TIME_FORMAT).format('YYYY-MM-DD')} 00:00:00`);
          Array.from(this.users).forEach((user, index) => {
            formData.append(`user_ids[${index}]`, user.id);
          });
          await API.post(API_JOURNEY_V2_USER_ASSIGMENT_ADD, formData)
            .then(() => {
              this.$snotify.success(successMsg);
              self.$store.commit('modal/REFRESH_PAGE', true);
              self.closeModal();
            })
            .catch((error) => {
              self.$helpers.displayError(error);
              self.formSending = false;
              self.isPending = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check {
  .form-label {
    font-size: 0.9rem;
    margin-left: 1rem;
    margin-bottom: 0;
    color: #142b58;
  }
}
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}
.modal-body {
  h4 {
    color: #727d92;
  }
}
.blur {
  filter: blur(3px);
}
.no-wrap {
  white-space: nowrap;
}
</style>
