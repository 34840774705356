var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showPreview ? _c("modal-contentpreview") : _vm._e(),
      _c("FullCalendar", {
        ref: "fullCalendar",
        attrs: {
          "default-view": "dayGridMonth",
          locale: _vm.userLang,
          "first-day": _vm.userLang === "en" ? 0 : 1,
          "time-zone": "local",
          "button-text": _vm.buttonTextLocalized,
          events: _vm.calenderEvents,
          "event-time-format": {
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
          },
          selectable: true,
          editable: true,
          views: _vm.customViews,
          "list-day-format": _vm.listDayFormat,
          "valid-range": _vm.validRange,
          header: {
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,listAllEvents",
          },
          plugins: _vm.calendarPlugins,
          "event-order": "start,-duration,allDay,order",
          "event-render": _vm.eventRender,
        },
        on: {
          eventDrop: _vm.handleEvents,
          eventClick: _vm.eventClick,
          eventDragStart: _vm.eventDragStart,
          eventDragStop: _vm.eventDragStop,
        },
        scopedSlots: _vm._u([
          {
            key: "eventContent",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "dropdown", attrs: { id: "itemModal" } }, [
        _c("div", { staticClass: "dropdown__menu" }, [
          _c("header", { staticClass: "modal-header" }, [
            _c(
              "h5",
              {
                staticClass: "modal-title preview",
                on: { click: _vm.previewContentItem },
              },
              [_vm._v(" " + _vm._s(_vm.eventItemTitle) + " ")]
            ),
            _c("button", {
              staticClass: "close",
              attrs: { type: "button" },
              domProps: { innerHTML: _vm._s("&times;") },
              on: { click: _vm.closeEventModal },
            }),
          ]),
          _c("div", { staticClass: "row mx-4" }, [
            _c("div", { staticClass: "col-6" }, [
              _c(
                "div",
                {
                  staticClass: "form-group form-group--white u-mB-0 u-zIndex-3",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      attrs: { for: "journeyLanguage" },
                    },
                    [_vm._v(_vm._s(_vm.$t("begin_date")))]
                  ),
                  _c("date-picker", {
                    class: { "is-danger": _vm.errors.has("assign.beginDate") },
                    attrs: {
                      "disabled-date": _vm.notBeforeToday,
                      type: "date",
                      "value-type": "date",
                      "first-day-of-week": _vm.userLang === "en" ? 7 : 1,
                      lang: _vm.userLang,
                      format:
                        _vm.userLang === "en" ? "MM.DD.YYYY" : "DD.MM.YYYY",
                    },
                    on: {
                      "update:disabledDate": function ($event) {
                        _vm.notBeforeToday = $event
                      },
                      "update:disabled-date": function ($event) {
                        _vm.notBeforeToday = $event
                      },
                      change: function ($event) {
                        return _vm.adjustTime("date")
                      },
                    },
                    model: {
                      value: _vm.eventItemDateTime,
                      callback: function ($$v) {
                        _vm.eventItemDateTime = $$v
                      },
                      expression: "eventItemDateTime",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-6" }, [
              _c(
                "div",
                {
                  staticClass: "form-group form-group--white u-mB-0 u-zIndex-3",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      attrs: { for: "journeyLanguage" },
                    },
                    [_vm._v(_vm._s(_vm.$t("begin_time")))]
                  ),
                  _c(
                    "date-picker",
                    {
                      class: {
                        "is-danger": _vm.errors.has("assign.beginTime"),
                      },
                      attrs: {
                        "disabled-time": _vm.notBeforeTime,
                        type: "time",
                        "value-type": "date",
                        lang: _vm.userLang,
                        format: _vm.userLang === "en" ? "hh:mm A" : "HH:mm",
                        "time-picker-options": {
                          start: "08:00",
                          step: "00:15",
                          end: "23:45",
                          format: _vm.userLang === "en" ? "hh:mm A" : "HH:mm",
                        },
                      },
                      on: {
                        "update:disabledTime": function ($event) {
                          _vm.notBeforeTime = $event
                        },
                        "update:disabled-time": function ($event) {
                          _vm.notBeforeTime = $event
                        },
                        change: function ($event) {
                          return _vm.adjustTime("time")
                        },
                      },
                      model: {
                        value: _vm.eventItemDateTime,
                        callback: function ($$v) {
                          _vm.eventItemDateTime = $$v
                        },
                        expression: "eventItemDateTime",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "icon-calendar" },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                name: "time",
                                "view-box": "0 0 512 512",
                              },
                            },
                            [_c("icon-time")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "modal-footer mb-4" }, [
            _c(
              "button",
              {
                staticClass: "button button--primary",
                attrs: {
                  type: "button",
                  disabled: _vm.isPending || !_vm.eventItemDateTime,
                },
                on: { click: _vm.setNewDate },
              },
              [
                !_vm.isPending
                  ? _c("span", [_vm._v(" " + _vm._s(_vm.$t("change")) + " ")])
                  : _vm._e(),
                _vm.isPending
                  ? _c("div", { staticClass: "lds-ellipsis" }, [
                      _c("div"),
                      _c("div"),
                      _c("div"),
                      _c("div"),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
      ]),
      _c("div", { attrs: { id: "borderActivated" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }