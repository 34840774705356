var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flip-wrapper" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "bookblock",
            rawName: "v-bookblock",
            value: _vm.options,
            expression: "options",
          },
        ],
        ref: "bookBlock",
        staticClass: "bb-bookblock",
        attrs: { id: "bb-bookblock" },
      },
      _vm._l(_vm.images, function (image, index) {
        return _c("div", { key: index, staticClass: "bb-item" }, [
          _c("img", {
            staticClass: "bb-custom-img",
            attrs: {
              src: typeof image === "object" ? image.image : image,
              alt: "Flip Page Image",
            },
          }),
        ])
      }),
      0
    ),
    _c("button", {
      directives: [
        {
          name: "touch",
          rawName: "v-touch:swipe.bottom",
          value: _vm.prev,
          expression: "prev",
          arg: "swipe",
          modifiers: { bottom: true },
        },
      ],
      attrs: { id: "bb-nav-prev" },
      on: { mousedown: _vm.prev },
    }),
    _c("button", {
      directives: [
        {
          name: "touch",
          rawName: "v-touch:swipe.top",
          value: _vm.next,
          expression: "next",
          arg: "swipe",
          modifiers: { top: true },
        },
      ],
      attrs: { id: "bb-nav-next" },
      on: { mousedown: _vm.next },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }