<template>
  <div class="page">
    <app-header
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
      add-group
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>

    <div class="container">
      <div class="group-users--title">
        <router-link
          :to="{
            name: 'Groups',
          }"
        >
          <icon-container
            :width="40"
            :height="18"
            name="Go Back"
            color="#727d92"
          >
            <icon-goback />
          </icon-container>
        </router-link> {{ groupName }}<small> {{ $t('user_list') }}</small>
      </div>
      <div class="table-responsive">
        <div
          v-if="tableIsLoading"
          class="lds-dual-ring"
        />
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="get"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="actions"
            slot-scope="props"
          >
            <button
              class="ui button"
              @click="onAction('edit-item', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                view-box="0 0 24 24"
                name="edit"
                color="#727d92"
              >
                <icon-edit />
              </icon-container>
            </button>
            <button
              class="ui button"
              @click="onAction('user-detail', props.rowData)"
            >
              <icon-container
                name="search"
                :width="20"
                :height="20"
                color="#727d92"
                view-box="0 0 15 15"
                :is-icon-class="false"
              >
                <icon-search />
              </icon-container>
            </button>
            <button
              class="ui button delete"
              @click="onAction('delete-item', props.rowData, props.rowIndex)"
            >
              <icon-container
                :width="20"
                :height="20"
                view-box="0 0 24 24"
                name="trash"
                color="#727d92"
              >
                <icon-trash />
              </icon-container>
            </button>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';

import { directive as onClickaway } from 'vue-clickaway';

import IconContainer from '@/components/elements/Icon.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconGoBack from '@/components/icons/GoBack.vue';
import IconEdit from '@/components/icons/Edit.vue';
import IconSearch from '@/components/icons/Search.vue';

import AppHeader from '@/components/AppHeader.vue';
import {
  API_GET_GROUP_USERS,
  API_USER_GROUP_USER,
} from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import VuetablePagination from '../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'GroupUsers',
  mixins: [myTableMixin],
  data() {
    return {
      parentGroupIDHolder: null,
      groupIdHolder: null,
      toastHolder: null,
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-edit': IconEdit,
    'icon-goback': IconGoBack,
    'icon-search': IconSearch,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'GroupUsers') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
          this.$store.state.modal.launchedFrom = null;
        }, 500);
      }
    },
    $route(from) {
      if (from.name === 'GroupUsers') {
        this.$refs.groupUsersTable.refresh();
        window.scrollTo(0, document.body.scrollHeight);
        this.groupName = from.params.title;
      }
    },
  },

  created() {
    this.groupName = this.$route.params.title;
    this.$store.state.modal.launchedFrom = null;
  },

  computed: {
    userSubNavigation() {
      return [
        {
          text: this.$t('users'),
          url: '/users',
        },
        {
          text: this.$t('groups'),
          url: '/groups',
        },
      ];
    },

    apiUrl() {
      if (API_GET_GROUP_USERS) {
        return `${API_GET_GROUP_USERS}/${this.$route.params.groupId}`;
      }
      return null;
    },

    fields() {
      return [
        {
          name: 'name',
          title: this.$t('name'),
          sortField: 'name',
          width: '25%',
        },
        {
          name: 'email',
          title: this.$t('email'),
          width: '65%',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '10%',
        },
      ];
    },
  },

  methods: {

    onAction(action, data) {
      const groupId = data.id;
      const parentGroupID = this.$route.params.groupId;
      this.groupIdHolder = data.id;
      this.parentGroupIDHolder = parentGroupID;
      if (!groupId) {
        return;
      }
      if (action === 'delete-item' && !this.snotifyQueueList.includes(groupId)) {
        this.snotifyQueueList.push(groupId);
        this.$snotify.confirm(this.$t('group_user_delete_msg_body', { name: data.name }), this.$t('group_user_delete_msg_title'), {
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          buttons: [
            {
              text: this.$t('yes'),
              action: (toast) => {
                this.deleteConfirm(toast, parentGroupID, groupId);
              },
              bold: false,
            },
            {
              text: this.$t('no'),
              action: (toast) => {
                this.deleteCancel(toast, groupId);
              },
            },
          ],
        }).on('shown', (toast) => {
          this.toastHolder = toast;
          document.addEventListener('keydown', this.eventHandler, false);
        }).on('hidden', (toast) => {
          this.toastHolder = toast;
          document.removeEventListener('keydown', this.eventHandler, false);
        });
      }
      if (action === 'edit-item') {
        if (data.id) {
          const obj = {
            modalType: 'modalAddUser',
            userId: data.id,
            tabPane: 'mainTab',
            launchedFrom: 'GroupUsers',
            editMode: true,
          };
          this.$helpers.toggleModal(obj);
        }
      }
      if (action === 'user-detail') {
        if (data.id) {
          this.$store.commit('modal/SET_MODAL_DATA', data);
          this.$router.push({ path: `/dashboard/users/detail/${data.id}` });
        }
      }
      if (action === 'notification') {
        const obj = {
          modalType: 'modalNotification',
          groupId,
          notificationType: 'group',
        };
        this.$helpers.toggleModal(obj);
      }
    },

    deleteConfirm(toast, parentGroupID, groupId) {
      this.$snotify.remove(toast.id);
      const snotifyIndex = this.snotifyQueueList.indexOf(groupId);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }

      const request = axios.create({
        headers: {
          Authorization: `Bearer ${this.$store.getters['auth/token']}`,
        },
      });

      request.delete(API_USER_GROUP_USER,
        {
          data: {
            group_id: parentGroupID,
            user_id: groupId,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.$snotify.success(response.data.message);
            this.$refs.myvuetable.refresh();
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    deleteCancel(toast, itemId) {
      const snotifyIndex = this.snotifyQueueList.indexOf(itemId);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.$snotify.remove(toast.id);
    },

    eventHandler(event) {
      event.preventDefault();
      const key = event.key || event.keyCode;
      if (key === 'Enter' || key === 13) {
        this.deleteConfirm(this.toastHolder, this.parentGroupIDHolder, this.groupIdHolder);
      } else if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.deleteCancel(this.toastHolder, this.idHolder);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .group-users--title {
    display: inline-flex;
    font-size: 26px;
    color: #727D92;
    align-items: center;
    font-weight: 500;
    small {
      font-size: 16px;
      margin-top: 5px;
      margin-left: 10px;
    }
    a {
      margin-right: 10px;
      display: inline-block;
      svg {
        transition: transform .2s ease-in;
      }
      &:hover {
        svg {
          transform: translateX(-5px);
        }
      }
    }
  }
</style>
