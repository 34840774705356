<template>
  <div class="page page--fullscreen p-login">
    <div class="c-login">
      <div class="c-login__title">
        <img
          src="@/assets/img/journey-logo-blue.svg"
          alt="Journey"
        >
        <h2>{{ $t('login_title') }}</h2>
      </div>
      <div
        class="card"
        :class="{'is-loading': isLoading}"
      >
        <form
          class="card__body"
          @submit.prevent="validateBeforeSubmit"
        >
          <div
            v-if="authStatus === 'error'"
            class="form-group"
          >
            <p class="error">
              {{ $t('login_error_msg') }}
            </p>
          </div>

          <div
            class="form-group input"
            :class="{ 'is-danger': errors.has('email') }"
          >
            <label
              for=""
              class="form-label"
            >{{ $t('email_address') }}</label>
            <input
              type="email"
              class="form-control"
              v-model="email"
              :placeholder="$t('email_placeholder')"
              autocomplete="email"
              v-validate="'required|email'"
              name="email"
            >
            <span
              v-if="errors.has('email')"
              class="help is-danger"
            >{{ errors.first('email') }}</span>
          </div>
          <div
            class="form-group input"
            :class="{ 'is-danger': errors.has('password'), 'is-closed': forgotPassword }"
          >
            <label
              for=""
              class="form-label"
            >{{ $t('password') }}</label>
            <input
              type="password"
              class="form-control"
              ref="passwordRef"
              v-model="password"
              :placeholder="$t('password_placehodler')"
              autocomplete="password"
              v-validate="'required'"
              name="password"
              @focus="showBadge"
            >
            <span
              v-if="errors.has('password')"
              class="help is-danger"
            >{{ errors.first('password') }}</span>
          </div>
          <div class="form-group d-flex justify-content-center">
            <button
              type="submit"
              class="button button--primary size-lg"
              :class="{'is-forgot-password': forgotPassword}"
            >
              <div class="text-container">
                <span>{{ $t('sign_in') }}</span>
                <span>{{ $t('reset_password') }}</span>
              </div>
            </button>
          </div>
          <div class="form-group text-center">
            <a
              href="#"
              @click="forgotPass"
              v-if="forgotPassword == null"
              class="forgot-password"
            >
              <span>{{ $t('forgot_password') }}</span>
            </a>
            <a
              href="#"
              v-if="forgotPassword"
              @click="forgotPassword = null"
            >
              <icon-container
                :width="40"
                :height="18"
                name="Go Back"
                color="#727d92"
              >
                <icon-goback />
              </icon-container>
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import IconGoBack from '@/components/icons/GoBack.vue';

export default {
  name: 'Login',
  inject: ['$validator'],

  data() {
    return {
      email: '',
      password: '',
      forgotPassword: null,
      isLoading: null,
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-goback': IconGoBack,
  },
  computed: {
    ...mapGetters({
      authStatus: 'auth/authStatus',
    }),
  },

  mounted() {
    const lastSuccessfullLoginEmailAddress = localStorage.getItem('lastSuccessfullLoginEmailAddress');
    if (lastSuccessfullLoginEmailAddress) {
      this.email = lastSuccessfullLoginEmailAddress;
    }
    if (this.$refs.passwordRef) {
      this.$refs.passwordRef.focus();
    }
    if (this.$recaptchaInstance) {
      setTimeout(() => this.$recaptchaInstance.showBadge(), 2000);
    }
  },
  methods: {
    showBadge() {
      if (this.$recaptchaInstance) {
        this.$recaptchaInstance.showBadge();
      }
    },
    validateBeforeSubmit() {
      this.showBadge();
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.forgotPassword) {
            this.forgotPasswordPost();
          } else {
            this.login();
          }
        }
        return true;
      });
    },

    login() {
      this.isLoading = true;
      const { email, password } = this;
      if (email && password) {
        this.$recaptchaLoaded().then(() => {
          console.log('recaptcha loaded');
          this.$recaptcha('login').then((token) => {
            this.$store.dispatch('auth/validateCaptcha', token).then((validationStatus) => {
              if (Number(validationStatus) > 0.49) {
                this.$store.dispatch('auth/login', {
                  email,
                  password,
                }).then(() => {
                  this.$store.commit('auth/set_status', 'success');
                  this.$snotify.success(this.$t('login_success'));
                  this.isLoading = false;
                  // NOTE: cache email for next entry
                  localStorage.removeItem('lastSuccessfullLoginEmailAddress');
                  localStorage.setItem('lastSuccessfullLoginEmailAddress', email);
                  const lastRoutePathName = localStorage.getItem('lastRoutePathName');
                  const hasAuthIssue = localStorage.getItem('hasAuthIssue');
                  if (hasAuthIssue && lastRoutePathName) {
                    this.$router.push(lastRoutePathName);
                  } else {
                    this.$router.push('/');
                  }
                }).catch((error) => {
                  this.$helpers.displayError(error);
                  this.$store.commit('auth/set_status', 'error');
                  this.isLoading = false;
                });
              } else {
                this.$helpers.displayError('Can`t decide whether you are a human or a bot. Please Try again later...');
              }
            }).catch((error) => {
              this.$helpers.displayError(error);
              this.isLoading = false;
            });
          });
        });
      }
    },

    forgotPass() {
      this.forgotPassword = true;
    },
    forgotPasswordPost() {
      const { email } = this;
      this.$recaptchaLoaded().then(() => {
        console.log('recaptcha loaded');
        this.$recaptcha('login').then((token) => {
          this.$store.dispatch('auth/validateCaptcha', token).then((validationStatus) => {
            if (Number(validationStatus) > 0.49) {
              this.$store.dispatch('auth/forgotPassword', {
                email,
              }).then(() => {
                this.$snotify.success(this.$t('forgot_password_success_msg'));
                this.forgotPassword = false;
              }).catch((error) => {
                this.$helpers.displayError(error);
              });
            } else {
              this.$helpers.displayError('Can`t decide whether you are a human or a bot. Please Try again later...');
            }
          });
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .p-login {
    background: url(~@/assets/img/bg-login.jpg) center center/cover no-repeat
  }
  .c-login {
    width: 45%;
    &__title {
      text-align: center;
      h2 {
        font-size: 20px;
        font-weight: 400;
        color: #727D92;
        margin: 0.5rem 0 1rem;
      }
    }
    .card {
      background: #FFFFFF;
      box-shadow: 0 2px 14px 0 #F2F7FF;
      border-radius: 8px;
      &.is-loading {
        filter: blur(2px);
      }
      &__body {
        max-width: 55%;
        padding: 10% 0;
        margin: 0 auto;
        .form-group {
          transition: 0.3s ease;
          overflow: hidden;
          &.input {
            max-height: 70px;
            &.is-danger {
              max-height: 95px;
              input {
                border-color: #ff0000;
              }
              input,
              .help {
                color: #FF0000;
              }
            }
            &.is-closed {
              max-height: 0;
            }
          }
          p {
            &.error {
              color: #ff0000;
            }
          }
        }
        .button {
          width: 100%;
          border-radius: 8px;
          overflow: hidden;
          position: relative;
          flex-direction: column;
          padding: 0;
          height: 48px;

          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            border-radius: 8px;
          }
          &:before {
            background-image: linear-gradient(90deg, #254FFB 0%, #F27299 100%);
            width: 100%;
            left: 0;
            z-index: 1;
          }
          &:after {
            background-image: linear-gradient(270deg, #254FFB 0%, #F27299 100%);
            display: block;
            opacity: 0;
            right: 0;
            transition: max-width 0.4s ease-in, opacity 0.2s ease-in;
            width: 100%;
            z-index: 2;
          }
          .text-container {
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            transition: transform 0.3s ease;
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 48px;
              flex: 0 0 100%;
            }
          }
          &:hover {
            &::after {
              opacity: 1;
            }
          }
          &.is-forgot-password {
            .text-container {
              transform: translateY(-48px);
            }
          }
        }
        a {
          &.forgot-password {
            color: #727D92;
            font-size: 14px;
          }
          .icon {
            display: inline-flex;
          }
        }
      }
    }
  }
</style>
