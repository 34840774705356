<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      :class="{'is-loading': formSending }"
      tabindex="-1"
    >
      <div :class="journeyIsNew ? showPackageCode ? 'nocalender modal-dialog modal-dialog-scrollable' : 'modal-dialog modal-dialog-scrollable' : 'nocalender modal-dialog modal-dialog-scrollable' ">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ showPackageCode ? $t('journey_package_code') : $t('create_packet_code') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>

          <div
            class="modal-body"
            id="newPlacerWrap"
          >
            <div class="tab-content">
              <div
                class="tab-pane"
                v-show="!showPackageCode"
              >
                <div class="row">
                  <div :class="journeyIsNew ? 'col-4 bglight': 'col-12 bglight'">
                    <div class="row mx-0">
                      <div class="col-12">
                        <div class="form-group">
                          <label
                            for="packagecode"
                            class="form-label"
                          >{{ $t('package_code') }}</label>
                          <input
                            :class="{ 'is-danger': errors.has('package.packagecode') }"
                            type="text"
                            class="form-control"
                            id="packagecode"
                            v-model="packageCode"
                            data-vv-scope="package"
                            data-vv-name="packagecode"
                            :data-vv-as="$t('package_code')"
                            v-validate="'required'"
                          >
                          <span
                            v-if="errors.has('package.packagecode')"
                            class="help is-danger"
                          >{{ errors.first('package.packagecode') }}</span>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label
                            for="groupname"
                            class="form-label"
                          >{{ $t('group_name') }}</label>
                          <multi-select
                            :class="{ 'is-danger': errors.has('package.groupname') }"
                            track-by="id"
                            label="text"
                            :allow-empty="false"
                            v-model="groupName"
                            :options="groupOptions"
                            :taggable="true"
                            :clear-on-select="true"
                            :select-label="$t('click_to_select')"
                            :deselect-label="$t('click_to_deselect')"
                            :tag-placeholder="$t('group_select_add_new')"
                            :placeholder="$t('group_select')"
                            @tag="addTag"
                            data-vv-scope="package"
                            data-vv-name="groupname"
                            :data-vv-as="$t('group_name')"
                            v-validate="'required'"
                          />
                          <span
                            v-if="errors.has('package.groupname')"
                            class="help is-danger"
                          >{{ errors.first('package.groupname') }}</span>
                        </div>
                      </div>
                      <div :class="journeyIsNew ? 'col-3 my-2': 'col-1 my-2'">
                        <div class="swicth-checkbox">
                          <input
                            type="checkbox"
                            id="journeyIsNew"
                            checked
                            v-model="journeyIsNew"
                            :true-value="1"
                            :false-value="0"
                          >
                          <label
                            class="swicth-label"
                            for="journeyIsNew"
                          />
                        </div>
                      </div>
                      <div class="col-9 my-2">
                        <label
                          class="seperate-label"
                          for="journeyIsNew"
                        >
                          {{ $t('journey_is_new') }}
                        </label>
                      </div>
                      <collapse-transition
                        :duration="500"
                        dimension="height"
                      >
                        <div v-show="journeyIsNew">
                          <div class="col-12">
                            <div class="form-group">
                              <label
                                for="journeyLanguage"
                                class="form-label"
                              >{{ $t('begin_date') }}</label>
                              <date-picker
                                :class="{ 'is-danger': errors.has('package.beginDate') }"
                                v-model="beginActivationDateTime"
                                :disabled-date.sync="notBeforeToday"
                                value-type="date"
                                data-vv-scope="package"
                                data-vv-name="beginDate"
                                :data-vv-as="$t('begin_date')"
                                v-validate="'required'"
                                :first-day-of-week="userLang === 'en' ? 7 : 1"
                                :lang="userLang"
                                :format="userLang === 'en' ? 'MM.DD.YYYY' : 'DD.MM.YYYY'"
                                @change="adjustTime('date')"
                              />
                              <span
                                v-if="errors.has('package.beginDate')"
                                class="help is-danger"
                              >{{ errors.first('package.beginDate') }}</span>
                            </div>
                          </div>

                          <div class="form form--horizontal">
                            <div
                              :class="['panel-title', showAdvanceSettings ? 'active': '']"
                            >
                              <label @click="showAdvanceSettings = !showAdvanceSettings">{{ $t('advanced_tab') }}</label>
                            </div>
                            <collapse-transition
                              :duration="500"
                              dimension="height"
                            >
                              <div
                                v-show="showAdvanceSettings"
                              >
                                <div class="col-12">
                                  <div class="swicth-checkbox">
                                    <input
                                      type="checkbox"
                                      id="sendOnSaturday"
                                      v-model="sendOnSaturday"
                                      :true-value="1"
                                      :false-value="0"
                                      @change="journeyNotificationSelect = 'default'"
                                    >
                                    <label
                                      class="swicth-label"
                                      for="sendOnSaturday"
                                    >{{ $t('send_onsaturday') }}</label>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="swicth-checkbox">
                                    <input
                                      type="checkbox"
                                      id="sendOnSunday"
                                      v-model="sendOnSunday"
                                      @change="journeyNotificationSelect = 'default'"
                                      :true-value="1"
                                      :false-value="0"
                                    >
                                    <label
                                      class="swicth-label"
                                      for="sendOnSunday"
                                    >{{ $t('send_onsunday') }}</label>
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div
                                    class="form-group"
                                    ref="takvim"
                                  >
                                    <label
                                      for="excludedDays"
                                      class="form-label"
                                    >{{ $t('excluded_days') }}</label>
                                    <flat-pickr
                                      v-model="excludedDaysAsString"
                                      :config="excludedDaysConfig"
                                      class="form-control"
                                      :placeholder="$t('select_dates')"
                                      name="excludedDays"
                                    />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="swicth-checkbox">
                                    <input
                                      type="checkbox"
                                      id="isNotify"
                                      v-model="isNotificationDisabled"
                                      :true-value="1"
                                      :false-value="0"
                                    >
                                    <label
                                      class="swicth-label"
                                      for="isNotify"
                                    >{{ $t('is_notification_enabled') }}</label>
                                  </div>
                                </div>
                              </div>
                            </collapse-transition>
                          </div>
                        </div>
                      </collapse-transition>
                    </div>
                    <div class="col-12 my-2">
                      <div class="form-group row">
                        <div class="col-auto">
                          <div class="swicth-checkbox">
                            <input
                              type="checkbox"
                              name="hasReminder"
                              id="hasReminder"
                              checked
                              v-model="hasReminder"
                              :true-value="true"
                              :false-value="false"
                            >
                            <label
                              class="swicth-label"
                              for="hasReminder"
                            >
                              {{ $t('hasReminder') }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <collapse-transition
                          :duration="500"
                          dimension="height"
                        >
                          <div
                            class="container mt-5"
                            v-show="hasReminder"
                          >
                            <div class="row">
                              <div class="form-group row">
                                <label
                                  for="employee_title"
                                  class="col-3 form-label"
                                >
                                  {{ $t('employee_title') }}
                                </label>
                                <div class="col-md-9">
                                  <input
                                    :class="{ 'is-danger': errors.has('journey.employee_title') }"
                                    type="text"
                                    class="form-control"
                                    id="employee_title"
                                    v-model="employee_title"
                                    data-vv-name="employee_title"
                                    data-vv-scope="journey"
                                    :data-vv-as="$t('employee_title')"
                                    v-validate="hasReminder ? 'required|max:40':''"
                                  ><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                                  <span
                                    v-if="errors.has('journey.employee_title')"
                                    class="help is-danger"
                                  >{{ errors.first('journey.employee_title') }}</span>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  for="employee_content"
                                  class="col-3 form-label"
                                >
                                  {{ $t('employee_content') }}
                                </label>
                                <div class="col-md-9">
                                  <textarea
                                    :class="{ 'is-danger': errors.has('journey.employee_content') }"
                                    id="employee_content"
                                    class="form-control"
                                    v-model="employee_content"
                                    data-vv-name="employee_content"
                                    data-vv-scope="journey"
                                    :data-vv-as="$t('employee_content')"
                                    v-validate="hasReminder ? 'required|max:160':''"
                                  /><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                                  <span
                                    v-if="errors.has('journey.employee_content')"
                                    class="help is-danger"
                                  >{{ errors.first('journey.employee_content') }}</span>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  for="weeks_after_last_content"
                                  class="col-3 form-label"
                                >
                                  {{ $t('weeks_after_last_content') }}
                                </label>
                                <div class="col-md-7">
                                  <select
                                    class="selectbox-o"
                                    id="weeks_after_last_content"
                                    v-model="weeks_after_last_content"
                                  >
                                    <option
                                      v-for="n in [1,2,3,4,5,6,7,8,9]"
                                      :key="n"
                                      :value="n"
                                    >
                                      {{ n }}
                                    </option>
                                  </select>
                                  <span class="form-label mx-2">{{ $t('week') }}</span>
                                  <svg-icon
                                    v-tooltip.top="$t('weeks_after_last_content_info')"
                                    type="mdi"
                                    class="form-label"
                                    :path="iconInformationOutline"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  for="week_span"
                                  class="col-3 form-label"
                                >
                                  {{ $t('week_span') }}
                                </label>
                                <div class="col-md-7">
                                  <select
                                    class="selectbox-o"
                                    id="week_span"
                                    v-model="week_span"
                                  >
                                    <option
                                      v-for="n in [1,2,3,4]"
                                      :key="n"
                                      :value="n"
                                    >
                                      {{ n }}
                                    </option>
                                  </select>
                                  <span class="form-label mx-2">{{ $t('week') }}</span>
                                  <svg-icon
                                    v-tooltip.top="$t('week_span_info')"
                                    type="mdi"
                                    class="form-label"
                                    :path="iconInformationOutline"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  for="day_of_week"
                                  class="col-3 form-label"
                                >
                                  {{ $t('day_of_week') }}
                                </label>
                                <div class="col-md-7">
                                  <select
                                    class="selectbox-o"
                                    id="day_of_week"
                                    v-model="day_of_week"
                                  >
                                    <option
                                      v-for="n in [1,2,3,4,5,6,7]"
                                      :key="n"
                                      :value="n"
                                    >
                                      {{ $helpers.getWeekDay(n) }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </collapse-transition>
                      </div>
                    </div>
                    <div class="schedule-next">
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="button button--primary"
                          :disabled="isPending"
                          @click="doAction"
                        >
                          <span
                            v-if="!isPending"
                          >
                            {{ $t('create_button') }}
                          </span>
                          <div
                            class="lds-ellipsis"
                            v-if="isPending"
                          >
                            <div />
                            <div />
                            <div />
                            <div />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>

                  <collapse-transition
                    :duration="300"
                    dimension="width"
                  >
                    <div
                      class="col-8"
                      v-show="journeyIsNew"
                    >
                      <full-calendar
                        v-show="journeyIsNew"
                        :user-lang="userLang"
                        :calender-events="calenderEvents"
                        :preview-first-date="previewFirstDate"
                        :total-event-days="totalEventDays"
                        @setDateFromDrop="setDateFromDrop"
                        @isDragging="setDragging"
                      />
                    </div>
                  </collapse-transition>
                </div>
              </div>
              <div
                class="tab-pane"
                v-show="showPackageCode"
              >
                <div class="row package-box">
                  <div class="col-9">
                    <div class="show-packagecode">
                      <span>{{ packageCode }}</span>
                      <button
                        type="button"
                        class="button button--primary packagebtn"
                        @click="shareClick($event)"
                      >
                        {{ $t('copy') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <collapse-transition
        :duration="500"
        dimension="height"
      >
        <div
          v-show="isDragging"
          class="hint"
        >
          <div class="img">
            <img
              src="@/assets/img/arrowkeys.png"
              alt="keyboard"
            >
          </div>
          <div class="title">
            {{ $t('keyboard_hint') }}
          </div>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
// eslint-disable-next-line import/extensions
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
// eslint-disable-next-line import/extensions
import { english } from 'flatpickr/dist/l10n/default.js';
import { mdiInformationOutline } from '@mdi/js';
import SvgIcon from '@jamescoyle/vue-icon';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/tr';
import 'vue2-datepicker/index.css';
import CollapseTransition from '@ivanv/vue-collapse-transition';
import Multiselect from 'vue-multiselect';
import FullCalendarComponent from '@/components/FullCalendar.vue';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import {
  API_JOURNEY_ITEMS_ALL,
  API_JOURNEY_DUPLICATE,
  API_JOURNEY_UPDATE,
  API_POST_JOURNEY,
  API_SERVER_TIME_FORMAT,
  API_JOURNEY_SCHEDULED_NOTIFICATION_UPDATE,
  API_JOURNEY_ITEMS_ALL_UPDATE,
  API_ADD_PACKAGE_CODE,
  API_ADD_JOURNEY_TO_PACKAGE,
  API_USER_GROUP_DATATABLES,
} from '@/helpers/config';
import API from '@/services/';

export default {
  name: 'PackageCode',
  inject: ['$validator'],

  directives: {
    'on-clickaway': onClickaway,
  },
  data() {
    return {
      isPending: false,
      isDragging: false,
      isHintAlreadyShown: false,
      formSending: false,
      journeyIsNew: 1,
      packageCode: '',
      groupName: null,
      groupOptions: [],
      excludedDaysAsString: null,
      statusNewJourney: false,
      sendOnSaturday: 0,
      sendOnSunday: 0,
      showPackageCode: false,
      statusUpdateJourneyItem: false,
      cache: {},
      record: {},
      previewContentList: [],
      beginActivationDateTime: new Date(),
      beginActivationDateTimeOldValue: null,
      journeyNotificationSelect: null,
      duplicateJourneyItem: [],
      duplicatejourneyItems: [],
      addDay: 0,
      calenderEvents: [],
      previewLastDate: null,
      finalEventDay: null,
      totalEventDays: 1,
      firstItemDate: null,
      runOnce: true,
      showAdvanceSettings: false,
      isNotificationDisabled: 0,
      dayPush: 0,
      hasReminder: false,
      hasReminderBefore: false,
      employee_title: this.$t('employee_title_text'),
      employee_content: this.$t('employee_content_text'),
      push_notification_title: this.$t('default_content_push_noti_head_msg'),
      push_notification_body: this.$t('default_content_push_noti_body_msg'),
      weeks_after_last_content: 1,
      week_span: 1,
      day_of_week: 1,
    };
  },

  computed: {
    multiProcessProgressBar: {
      get() {
        return this.$store.state.modal.multiProcessProgressBar;
      },
      set(newValue) {
        this.$store.state.modal.multiProcessProgressBar = newValue;
      },
    },
    iconInformationOutline() {
      return mdiInformationOutline;
    },
    excludedDays() {
      let arr = [];
      if (this.excludedDaysAsString) {
        arr = this.excludedDaysAsString.split(',').map(item => item.trim());
      }
      return arr;
    },
    previewFirstDate() {
      return moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT);
    },

    journeyId() {
      return this.$store.state.modal.journeyId;
    },

    modalData() {
      return this.$store.state.modal.modalData;
    },

    journeyDetail: {
      get() {
        return this.$store.state.modal.journeyDetail;
      },
      set(newValue) {
        this.$store.state.modal.journeyDetail = newValue;
      },
    },

    journeyLocale() {
      if (this.journeyDetail) {
        return this.journeyDetail.lang;
      }
      return null;
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },

    currentCustomerId() {
      return this.$store.state.auth.customerId;
    },

    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },

    excludedDaysConfig() {
      const config = {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: this.userLang === 'en' ? 'm.d.Y' : 'd.m.Y',
        altInput: true,
        appendTo: this.$refs.takvim,
        mode: 'multiple',
        dateFormat: 'Y-m-d',
        minDate: 'today',
        locale: this.userLang === 'en' ? english : Turkish, // locale for this instance only
      };
      return config;
    },
  },

  components: {
    'date-picker': DatePicker,
    'full-calendar': FullCalendarComponent,
    'multi-select': Multiselect,
    'collapse-transition': CollapseTransition,
    flatPickr,
    SvgIcon,
  },

  watch: {
    modalData(newValue) {
      const $newValue = newValue;
      if ($newValue) {
        console.log('modalData:', $newValue);
      }
    },
    previewContentList: {
      handler(newval) {
        if (newval && newval.length) {
          if (newval[0]) {
            const hours = moment(newval[0].content.activation_date, API_SERVER_TIME_FORMAT).format('HH');
            const minutes = moment(newval[0].content.activation_date, API_SERVER_TIME_FORMAT).format('mm');
            this.beginActivationDateTime.setHours(hours, minutes, 0);
          }
          // this.triggerPreviewDate();
          this.$nextTick(() => this.triggerPreviewDate());
        }
      },
      immediate: true,
      deep: true,
    },
    firstItemDate: {
      handler(val, old) {
        if (val !== old && this.runOnce) {
          const mydate = moment(val, API_SERVER_TIME_FORMAT);
          this.beginActivationDateTime = new Date(
            mydate.format('YYYY'),
            (mydate.format('M') - 1),
            mydate.format('DD'), mydate.format('HH'),
            mydate.format('mm'), mydate.format('ss'),
          );
          this.runOnce = false;
          setTimeout(() => {
            this.runOnce = true; // to prevent loop
          }, 50);
        }
      },
    },
    groupName(newval) {
      if (newval) {
        console.log('groupname:', newval);
      }
    },
    journeyDetail(newValue) {
      if (newValue) {
        console.log('journeyDetail:', newValue);
      }
    },
    beginActivationDateTime: 'triggerPreviewDate',
    sendOnSaturday: 'triggerPreviewDate',
    sendOnSunday: 'triggerPreviewDate',
    excludedDays: {
      handler() {
        this.triggerPreviewDate();
      },
      immediate: true,
      deep: true,
    },
    duplicateJourneyItem: 'updateJourney',
    statusUpdateJourneyItem: 'newJourneyItems',
    statusNewJourney: 'addJourneysToPackage',
    cache: 'getActivationDates',
    journeyNotificationSelect: 'triggerPreviewDate',
  },

  mounted() {
    this.multiProcessProgressBar = true;
    this.getJourney();
    this.getContentItems();
    this.beginActivationDateTime.setHours(8, 0, 0);
    this.packageCode = this.getRandomCode(6);
    const picker = document.getElementsByClassName('flatpickr-calendar')[0];
    const newplace = document.getElementById('newPlacerWrap');
    if (picker && newplace) {
      newplace.appendChild(picker);
    }
  },

  methods: {
    getDaysBetweenDates(startDate, endDate) {
      const current = startDate.clone();
      const first = startDate.clone();
      const dates = [];
      while (current.isBefore(endDate)) {
        dates.push(current.format('YYYY-MM-DD'));
        current.add(1, 'days');
      }
      dates.splice(dates.indexOf(first.format('YYYY-MM-DD')), 1);
      return dates;
    },
    getRandomCode(length) {
      const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i += 1) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
      }
      return result;
    },
    doAction() {
      if (this.journeyIsNew) {
        this.duplicateJourney();
      } else {
        this.formSending = true;
        this.isPending = true;
        this.addJourneysToPackage();
      }
    },
    setTotalEventDays() {
      const now = moment(); // we have to calculate it from now, even if the beginDate is diferent then now
      const finalday = moment(this.finalEventDay, API_SERVER_TIME_FORMAT);
      this.totalEventDays = finalday.diff(now, 'days') + 2; // we have to include the first and last day in calculation
    },

    notBeforeToday(date) {
      const now = new Date();
      now.setMonth(now.getMonth() - 3);
      return date < new Date(now.getFullYear(), now.getMonth(), now.getDate());
    },

    notBeforeTime(date) {
      const now = new Date();
      now.setMonth(now.getMonth() - 3);
      if (moment(new Date(), API_SERVER_TIME_FORMAT).isSame(moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT), 'day')) {
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
      }
      return false;
    },

    adjustTime(type) {
      if (this.beginActivationDateTime !== null) {
        if (this.beginActivationDateTimeOldValue && type === 'date') {
          this.beginActivationDateTime.setHours(this.beginActivationDateTimeOldValue.getHours(), this.beginActivationDateTimeOldValue.getMinutes());
        } else if (!this.beginActivationDateTimeOldValue) {
          this.beginActivationDateTime.setHours(8, 0, 0);
        }
        this.beginActivationDateTimeOldValue = this.beginActivationDateTime;
      }
    },

    setDragging(value) {
      if (!this.isHintAlreadyShown) {
        this.isDragging = value;
        setTimeout(() => {
          this.isHintAlreadyShown = true;
        }, 3000);
      }
      if (!value) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.triggerPreviewDate();
          }, 300);
        });
      }
    },

    getGroups(customerId) {
      const formData = { params: {} };
      formData.params.per_page = 10000;
      formData.params.customer_id = customerId;

      API.get(API_USER_GROUP_DATATABLES, formData)
        .then((response) => {
          this.groupOptions = response.data.data.map(a => (
            {
              text: `${a.name}`,
              id: `${a.id}`,
            }
          ));
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    addTag(newTag) {
      const tag = {
        text: newTag,
        id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
      };
      this.groupOptions.push(tag);
      this.groupName = tag;
    },
    getJourney() {
      if (this.journeyId) {
        API.get(`${API_POST_JOURNEY}/${this.journeyId}`)
          .then((response) => {
            this.journeyDetail = response.data;
            this.getGroups(response.data.customer_id);
            this.$store.commit('modal/setJourneyDetail', response.data);
          })
          .catch((error) => {
            this.$helpers.displayError(error);
          });
      }
    },

    getActivationDates() {
      const items = this.dash.cloneDeep(this.previewContentList);
      const tempList = this.dash.cloneDeep(this.previewContentList);
      items.sort((a, b) => {
        if (moment(a.activation_date).format(API_SERVER_TIME_FORMAT) > moment(b.activation_date).format(API_SERVER_TIME_FORMAT)) {
          return 1;
        }
        if (moment(a.activation_date).format(API_SERVER_TIME_FORMAT) < moment(b.activation_date).format(API_SERVER_TIME_FORMAT)) {
          return -1;
        }
        return 0;
      });
      if (items && items[0]) {
        this.firstItemDate = items[0].activation_date;
        const lastItem = [...items].pop();
        if (lastItem) {
          this.previewLastDate = this.userLang === 'en'
            ? moment(lastItem.activation_date, API_SERVER_TIME_FORMAT).locale('en').format('LL, hh:mm A')
            : moment(lastItem.activation_date, API_SERVER_TIME_FORMAT).locale('tr').format('LL, HH:mm');
          this.finalEventDay = moment(lastItem.activation_date, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT);
          this.setTotalEventDays();
        }
      }
      this.updateCalenderEvents(tempList);
    },

    addDays(originalDate, numDaysToAdd) {
      const Sunday = 0;
      const Saturday = 6;
      let daysRemaining = numDaysToAdd;
      console.log(`numDaysToAdd :${numDaysToAdd}`);
      const newDate = originalDate.clone();
      if (numDaysToAdd === 0 && this.excludedDays.includes(newDate.format('YYYY-MM-DD'))) {
        newDate.add(1, 'days');
      }
      if (this.sendOnSaturday === 0 && this.sendOnSunday === 0) {
        while (daysRemaining > 0) {
          newDate.add(1, 'days');
          console.log(`addDays 1.cond :${newDate} - ${(newDate.day() !== Sunday && newDate.day() !== Saturday) ? '' : 'X'}`);
          if (newDate.day() !== Sunday && newDate.day() !== Saturday && !this.excludedDays.includes(newDate.format('YYYY-MM-DD'))) {
            daysRemaining -= 1;
          }
        }
      } else if (this.sendOnSunday === 0 && this.sendOnSaturday === 1) {
        while (daysRemaining > 0) {
          newDate.add(1, 'days');
          console.log(`addDays 2.cond :${newDate} - ${(newDate.day() !== Sunday) ? '' : 'X'}`);
          if (newDate.day() !== Sunday && !this.excludedDays.includes(newDate.format('YYYY-MM-DD'))) {
            daysRemaining -= 1;
          }
        }
      } else if (this.sendOnSunday === 1 && this.sendOnSaturday === 0) {
        while (daysRemaining > 0) {
          newDate.add(1, 'days');
          console.log(`addDays 3.cond :${newDate} - ${(newDate.day() !== Saturday) ? '' : 'X'}`);
          if (newDate.day() !== Saturday && !this.excludedDays.includes(newDate.format('YYYY-MM-DD'))) {
            daysRemaining -= 1;
          }
        }
      } else {
        while (daysRemaining > 0) {
          newDate.add(1, 'days');
          console.log(`addDays 4.cond :${newDate} -`);
          if (!this.excludedDays.includes(newDate.format('YYYY-MM-DD'))) {
            daysRemaining -= 1;
          }
        }
      }
      return newDate;
    },

    checkConditions(filterDate) {
      if (filterDate) {
        const checkdate = filterDate.format('YYYY-MM-DD');
        if (this.excludedDays.includes(checkdate)) {
          console.log('Exc....');
          this.dayPush += 1;
          return true;
        }
        if ((filterDate.format('dddd') === 'Saturday' || filterDate.format('dddd') === 'Cumartesi')) {
          if (this.sendOnSaturday === 0) {
            console.log('SAT....');
            this.dayPush += 1;
            return true;
          }
        }
        if ((filterDate.format('dddd') === 'Sunday' || filterDate.format('dddd') === 'Pazar')) {
          if (this.sendOnSunday === 0) {
            console.log('PAZ....');
            this.dayPush += 1;
            return true;
          }
        }
      }
      return false;
    },

    triggerPreviewDate() {
      const frequencyMode = this.journeyNotificationSelect ? this.journeyNotificationSelect : 'default';
      if (this.previewContentList && this.previewContentList.length && this.runOnce) {
        console.log('triggerPreviewDate....');
        this.dayPush = 0;
        const firstOne = moment(this.previewContentList[0].content.activation_date, API_SERVER_TIME_FORMAT);
        const journeyDate = moment(this.journeyDetail.activation_date, API_SERVER_TIME_FORMAT);
        const setDate = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT);
        const weekofSetDate = setDate.startOf('day').week();
        const journeyDateTemp = journeyDate.clone();

        this.previewContentList.map((item) => {
          const $item = item;
          let filterDate = null;
          let filterDateTemp = null;
          if (frequencyMode === 'custom') {
            filterDate = moment($item.activation_date, API_SERVER_TIME_FORMAT);
            while (this.checkConditions(filterDate)) {
              filterDate = filterDate.add(this.dayPush, 'days');
              this.dayPush += 1;
            }
          } else if (frequencyMode === 'default') {
            const currentItem = moment($item.content.activation_date, API_SERVER_TIME_FORMAT);
            const currentItemTemp = currentItem.clone();
            const addTime = currentItemTemp.startOf('day').diff(journeyDateTemp.startOf('day'), 'days');
            console.log(`
            setDate: ${setDate}\n
            firstOne: ${firstOne}\n
            journeyDate: ${journeyDate}\n
            currentItem: ${currentItem}\n
            addTime: ${addTime}\n`);

            if (addTime < 0) {
              filterDateTemp = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).add(addTime, 'days').set({ hour: currentItem.format('HH'), minute: currentItem.format('mm'), second: currentItem.format('ss') });
              const weekOfCurrent = filterDateTemp.week();
              const weekDiff = weekOfCurrent - weekofSetDate;
              let shiftDay = 0;
              if (weekDiff < 0) {
                if (this.sendOnSaturday === 0) {
                  shiftDay += 1;
                }
                if (this.sendOnSunday === 0) {
                  shiftDay += 1;
                }
              }
              if (shiftDay) shiftDay *= weekDiff;
              filterDate = filterDateTemp.add(shiftDay, 'days').set({ hour: currentItem.format('HH'), minute: currentItem.format('mm'), second: currentItem.format('ss') });
            } else {
              setDate.set({ hour: currentItem.format('HH'), minute: currentItem.format('mm'), second: currentItem.format('ss') });
              filterDate = this.addDays(setDate, addTime);
            }
          }
          console.log(`Date given final: ${filterDate}\n`);

          if (filterDate) {
            if (typeof filterDate === 'object') {
              filterDate = filterDate.format(API_SERVER_TIME_FORMAT);
            }
            $item.activation_date = filterDate;
          }
          return $item;
        });
        this.multiProcessProgressBar = false;
        this.getActivationDates();
        this.runOnce = false;
        setTimeout(() => {
          this.runOnce = true; // to prevent loop
        }, 50);
      }
    },

    updateCalenderEvents(newVal) {
      this.calenderEvents = [];
      // eslint-disable-next-line no-unused-vars
      Object.entries(newVal).forEach(([k, v]) => {
        let theColor = '#1976d2';
        if (v.type !== 'journey_item') {
          theColor = '#2dcf85';
        } else if (v.content.sub_type === 'LIVE_EVENT' || v.content.sub_type === 'MEETING') {
          theColor = '#FF6347';
        }
        const ss = {
          id: v.content.id,
          content_id: (v.type === 'journey_item') ? v.content.as_content_id : v.content.id,
          notif_preview: (v.type !== 'journey_item'),
          isnotif: (v.type !== 'journey_item'),
          all_content: (v.type !== 'journey_item') ? v.content : '',
          color: theColor,
          subtype: this.titleCase(this.$helpers.getSubTypeName(v.content.sub_type, this.userLang)),
          title: (v.type === 'journey_item') ? v.content.name : v.content.title,
          allDay: false,
          start: v.activation_date,
          order: v.content.order,
        };
        this.calenderEvents.push(ss);
      });
    },

    titleCase(str) {
      if (str) {
        const mystr = str.toLowerCase().split(' ');
        for (let i = 0; i < mystr.length; i += 1) {
          mystr[i] = mystr[i].charAt(0).toUpperCase() + mystr[i].slice(1);
        }
        return mystr.join(' ');
      }
      return '';
    },

    getContentItems() {
      const that = this;
      if (this.$route.params.journeyId || this.journeyId) {
        const journeyId = this.journeyId || this.$route.params.journeyId;
        API.get(`${API_JOURNEY_ITEMS_ALL}/${journeyId}`)
          .then((response) => {
            that.previewContentList = Object.values(response.data.items);
          })
          .catch((error) => {
            this.$helpers.displayError(error);
          });
      }
    },

    doEdit(record) {
      const index = this.dash.indexOf(this.previewContentList, this.cache);
      this.previewContentList.splice(index, 1, record);
    },

    edit(record) {
      this.record = JSON.parse(JSON.stringify(record));
      this.cache = record;
    },

    setDateFromDrop(dropitem) {
      this.journeyNotificationSelect = 'custom';
      let found = false;
      if (dropitem.isnotif) {
        found = this.previewContentList.find((item) => {
          if (item.content.id) {
            return item.content.id === dropitem.id;
          }
          return false;
        });
      } else {
        found = this.previewContentList.find((item) => {
          console.log('HELLALALALALALA', item?.content?.item?.id, item?.content?.id);
          if (item.content && item.content.id) {
            return Number(item.content.id) === Number(dropitem.id);
          }
          return false;
        });
      }
      if (found) {
        found.activation_date = dropitem.newdatetime;
      }
      this.getActivationDates();
    },

    closeModal() {
      this.$validator.errors.clear();
      this.$store.commit('modal/toggleModal');
    },

    async checkValidation() {
      let allIsValid = false;
      let packagecodeIsValid = false;
      let beginDateIsValid = false;
      let groupnameIsValid = false;

      await this.$validator.validate('package.packagecode').then((res) => {
        if (res) {
          packagecodeIsValid = true;
        }
      });
      await this.$validator.validate('package.beginDate').then((res) => {
        if (res) {
          beginDateIsValid = true;
        }
      });
      await this.$validator.validate('package.groupname').then((res) => {
        if (res) {
          groupnameIsValid = true;
        }
      });
      await this.$validator.validateAll('package').then((res) => {
        allIsValid = res;
      });
      if (!this.journeyIsNew && packagecodeIsValid && groupnameIsValid) {
        return true;
      }
      if (allIsValid && packagecodeIsValid && beginDateIsValid && groupnameIsValid) {
        return true;
      }
      return false;
    },

    duplicateJourney() {
      this.checkValidation().then((res) => {
        if (res) {
          this.formSending = true;
          this.isPending = true;
          const formData = new FormData();
          const activationDate = moment(this.beginActivationDateTime).format(API_SERVER_TIME_FORMAT);
          const journeyId = this.journeyId || this.$route.params.journeyId;
          formData.append('activation_date', activationDate);
          formData.append('journey_id', journeyId);
          if (this.isVibonsAdmin) {
            formData.append('customer_id', this.currentCustomerId);
          }
          API.post(API_JOURNEY_DUPLICATE, formData)
            .then((response) => {
              this.duplicateJourneyItem = response.data;
            })
            .catch((error) => {
              this.formSending = false;
              this.isPending = false;
              this.$helpers.displayError(error);
            });
        }
      });
    },

    updateJourney() {
      if (this.duplicateJourneyItem) {
        this.formSending = true;
        this.isPending = true;
        const item = this.duplicateJourneyItem.journey;
        const activationDate = moment(this.beginActivationDateTime).format(API_SERVER_TIME_FORMAT);

        const formData = new FormData();
        formData.append('journey_id', item.id);
        formData.append('name', item.name);
        formData.append('activation_date', activationDate);
        formData.append('lang', item.lang);
        formData.append('is_list_on_the_catalog_enabled', 0);
        formData.append('push_notification_title', this.push_notification_title.replace('{JOURNEY_NAME}', item.name));
        formData.append('push_notification_body', this.push_notification_body.replace('{JOURNEY_NAME}', item.name));
        formData.append('is_welcome_notifications_enabled', item.is_welcome_notifications_enabled);
        formData.append('is_self_paced', item.is_self_paced);


        API.post(API_JOURNEY_UPDATE, formData)
          .then((response) => {
            if (response.status === 200) {
              this.statusUpdateJourneyItem = true;
            }
          })
          .catch((error) => {
            this.formSending = false;
            this.isPending = false;
            this.$helpers.displayError(error);
          });
      }
    },

    async newJourneyItems() {
      if (this.duplicateJourneyItem && this.statusUpdateJourneyItem) {
        const $items = this.duplicateJourneyItem.items;
        const arr = Object.values($items);
        const $journeyId = this.duplicateJourneyItem.journey.id;
        const responseChaine = [];
        const notifyStatus = this.isNotificationDisabled ? 0 : 1;
        const self = this;
        let notifIndex = 0;
        const notifFormData = new FormData();
        const formData = new FormData();
        formData.append('journey_id', $journeyId);
        formData.append('is_email_enabled', notifyStatus);
        formData.append('is_notifications_enabled', notifyStatus);
        // eslint-disable-next-line no-restricted-syntax
        for await (const [index, item] of arr.entries()) {
          if (Object.prototype.hasOwnProperty.call(item.content, 'notify_dt')) {
            const $notificationId = item.content.id;
            const $date = this.previewContentList[index].activation_date;
            notifFormData.append(`notifies[${notifIndex}]`, $notificationId);
            notifFormData.append(`notify_dt[${notifIndex}]`, $date);
            notifIndex += 1;
          } else {
            const $contentId = item.content.id;
            const $date = this.previewContentList[index].activation_date;
            formData.append(`activation_dt[${$contentId}]`, $date);
          }
        }
        if (formData && !formData.entries().next().done) {
          API.post(API_JOURNEY_ITEMS_ALL_UPDATE, formData)
            .then((response) => {
              if (response.status === 200) {
                responseChaine.push(1);
                self.statusNewJourney = true;
              } else {
                responseChaine.push(0);
              }
            })
            .catch((error) => {
              self.formSending = false;
              self.isPending = false;
              self.$helpers.displayError(error);
            }).then(() => {
              if (!responseChaine.includes(0)) {
                self.$snotify.success(this.$t('processed_success'));
              }
            });
        }

        if (notifFormData && !notifFormData.entries().next().done) {
          API.post(API_JOURNEY_SCHEDULED_NOTIFICATION_UPDATE, notifFormData)
            .then((response) => {
              if (response.status === 200) {
                responseChaine.push(1);
                self.statusNewJourney = true;
              } else {
                responseChaine.push(0);
              }
            })
            .catch((error) => {
              self.formSending = false;
              self.isPending = false;
              self.$helpers.displayError(error);
            }).then(() => {
              if (!responseChaine.includes(0)) {
                self.$snotify.success(this.$t('processed_success'));
              }
            });
        }
        if ((formData && formData.entries().next().done) && (notifFormData && notifFormData.entries().next().done)) {
          self.formSending = false;
          self.isPending = false;
        }
        if (this.hasReminder) {
          const payload = {
            journey_id: $journeyId,
            employee_title: this.employee_title,
            employee_content: this.employee_content,
            weeks_after_last_content: this.weeks_after_last_content,
            week_span: this.week_span,
            day_of_week: this.day_of_week,
          };
          await this.$store.dispatch('modal/POST_JOURNEY_REMINDER', payload);
        }
      }
    },
    async addJourneysToPackage() {
      const self = this;
      const packageCodeFormData = new FormData();
      packageCodeFormData.append('customer_id', this.currentCustomerId);
      packageCodeFormData.append('name', this.groupName.text);
      packageCodeFormData.append('code', this.packageCode);
      let packetId = null;
      await API.post(API_ADD_PACKAGE_CODE, packageCodeFormData)
        .then((response) => {
          if (response.data.data.packet_id) {
            packetId = response.data.data.packet_id;
          }
        })
        .catch((error) => {
          self.formSending = false;
          self.isPending = false;
          self.$helpers.displayError(error);
        });

      let journeyId = null;
      if (this.duplicateJourneyItem && this.duplicateJourneyItem.journey) {
        journeyId = this.duplicateJourneyItem.journey.id;
      } else {
        journeyId = this.journeyId || this.$route.params.journeyId;
      }

      const journeysFormData = new FormData();
      const newJourneyCode = this.journeyIsNew === 0 ? 1 : 0;
      journeysFormData.append('packet_id', packetId);
      journeysFormData.append('journey_ids[0]', journeyId);
      journeysFormData.append('journey_is_new[0]', newJourneyCode);

      await API.post(API_ADD_JOURNEY_TO_PACKAGE, journeysFormData)
        .then((response) => {
          if (response.status === 200 && response.data.data.packet_id) {
            self.$snotify.success(this.$t('processed_success'));
            this.showPackageCode = true;
            this.formSending = false;
          }
        })
        .catch((error) => {
          self.formSending = false;
          self.isPending = false;
          self.$helpers.displayError(error);
        });
    },
    async shareClick(event) {
      const self = this;
      const text = self.packageCode;
      self.copyText(text);
      const $event = event;
      if ($event && $event.target) {
        $event.target.innerHTML = self.$t('copied');
        $event.target.style.background = '#2EB08E';
        $event.target.style.color = '#fff';
        await setTimeout(() => {
          $event.target.innerHTML = self.$t('copy');
          $event.target.style.background = '#254ffb';
          $event.target.style.color = '#fff';
        }, 2000);
      }
    },

    fallbackCopyText(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log(`Fallback: Copying text command was ${msg}`);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
    },

    copyText(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyText(text);
        return;
      }
      navigator.clipboard.writeText(text).then(() => {
        console.log('Async: Copying to clipboard was successful!');
      }, (err) => {
        console.error('Async: Could not copy text: ', err);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal .modal-dialog {
    width: 80%;
    max-width: 1200px;
}
.modal .nocalender.modal-dialog {
    width: 50%;
    max-width: 1200px;
}

.modal .modal-body {
    max-width: 1190px;
}
.modal .tab-content {
    max-width: 1180px;
}
label.seperate-label {
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
}
.form-group label {
    text-transform: uppercase;
}
.is-danger {
  border-color: #f27299;
}
.swicth-checkbox {
    justify-content: flex-start;
    display: inline-flex;
    height: 50px;
    align-items: center;
}
.swicth-label {
    white-space: wrap;
}

.bglight {
  background: #f8f9ff;
  padding-top:30px;
  padding-bottom: 30px;
}
.bglight .form-group {
  border-bottom-width:0!important;
}
.calender-tab {
  width: 75vw;
  max-width: 1440px;
  min-width: 1010px!important;
  .modal-body {
    max-width: 1430px!important;
    min-width: 1000px!important;
    .tab-content {
      max-width: 1410px;
      .schedule-next {
        display: block;
        text-align: center;
        margin-top: 50px;
      }
    }
  }
}
.schedule-title {
  text-align: center;
  margin-bottom: 30px!important;
}
.panel-title {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #727d92;
  text-align: center;

}
.panel-title label {
  cursor: pointer;
}
.panel-title label:after {
  content: "\032C";
  left:20px;
  position: relative;
  top: -8px;
  font-size:30px;
  transition: all 1s;
}
 .panel-title.active label:after {
   content: "\032D";
}
.multiselect {
    max-width: max-content;
}
.hint {
  position: absolute;
  text-align: center;
  background-color: #142b58;
  opacity: 0.8;
  bottom: 0;
  z-index: 100;
  width: 460px;
  left: calc(50% - 230px);
  img {
    width: 180px;
    height: auto;
  }
  .title {
    color: #fff;
    font-size: 18px;
    text-align: center;
  }
}
.package-box {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    .show-packagecode {
        display: flex;
        border: 1px solid #254ffb;
        border-radius: 5px;
        height: auto;
        width: 100%;
        span {
            flex-grow:1;
            text-align: center;
            vertical-align: middle;
            line-height: 44px;
        }
        .packagebtn {
            width: 50px;
        }
    }
}
::v-deep .multiselect,
::v-deep .multiselect__tags,
::v-deep .multiselect__single,
::v-deep .multiselect__placeholder {
  min-width: 180px!important;
}
.selectbox-o {
  height: 36px;
  color: #666;
  border: 1px solid rgba(114, 125, 146, 0.25);
  border-radius: 4px;
  padding:2px;
}
</style>
