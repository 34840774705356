var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page dashboard mb-4 pb-4" },
    [
      _c("app-header", {
        attrs: {
          "child-header-class": "container-fluid",
          "child-header-navigation": _vm.dashboardSubNavigation,
          "is-child-header-left-actions": "",
        },
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "page-settings--menu" },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "button button--secondary text-size-xs size-sm button--icon",
                        attrs: {
                          type: "button",
                          "data-toggle": "dropdown",
                          "aria-haspopup": "true",
                          "aria-expanded": "false",
                        },
                        on: {
                          mouseover: function ($event) {
                            $event.preventDefault()
                            _vm.showReportDropDown = true
                          },
                          mouseleave: _vm.closeReportDropDown,
                        },
                      },
                      [
                        _c(
                          "icon-container",
                          {
                            staticClass: "icon-svg",
                            attrs: {
                              name: "downloadfull",
                              width: "13",
                              height: "14",
                              "is-icon-class": false,
                            },
                          },
                          [_c("icon-download")],
                          1
                        ),
                        _vm._v(" " + _vm._s(_vm.$t("reports")) + " "),
                        _c(
                          "icon-container",
                          {
                            staticClass: "icon-dropdown",
                            attrs: {
                              name: "down",
                              "view-box": "0 0 11 8",
                              color: "#fffff",
                              "is-icon-class": false,
                            },
                          },
                          [_c("icon-down")],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("transition", { attrs: { name: "dropdown" } }, [
                      _vm.showReportDropDown
                        ? _c(
                            "div",
                            {
                              staticClass: "page-settings__dropdown-menu",
                              class: { active: _vm.showReportDropDown },
                              on: {
                                mouseleave: function ($event) {
                                  _vm.showReportDropDown = false
                                },
                                mouseover: function ($event) {
                                  $event.preventDefault()
                                  return _vm.holdBulkMenu.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "page-settings__dropdown-menu-nav",
                                },
                                [
                                  _c(
                                    "li",
                                    {
                                      staticClass:
                                        "page-settings__dropdown-menu-item",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "page-settings__dropdown-menu-link",
                                          attrs: {
                                            href: "#",
                                            title: _vm.$t("activity_report"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.reportDownload(
                                                "activity"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "icon-container",
                                            {
                                              attrs: {
                                                name: "flying",
                                                width: "19",
                                                height: "12",
                                              },
                                            },
                                            [_c("icon-flying")],
                                            1
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("activity_report")
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass:
                                        "page-settings__dropdown-menu-item",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "page-settings__dropdown-menu-link",
                                          attrs: {
                                            href: "#",
                                            title: _vm.$t("journey_report"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.reportDownload(
                                                "journey"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "icon-container",
                                            {
                                              attrs: {
                                                name: "flying",
                                                width: "19",
                                                height: "12",
                                              },
                                            },
                                            [_c("icon-flying")],
                                            1
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("journey_report")) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm.showLxpRecommendation
                                    ? _c(
                                        "li",
                                        {
                                          staticClass:
                                            "page-settings__dropdown-menu-item",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "page-settings__dropdown-menu-link",
                                              attrs: {
                                                href: "#",
                                                title: _vm.$t("lxp_report"),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.reportDownload(
                                                    "lxp"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  attrs: {
                                                    name: "flying",
                                                    width: "19",
                                                    height: "12",
                                                  },
                                                },
                                                [_c("icon-flying")],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("lxp_report")) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          { staticClass: "row dboard-top-row dboard-top-main-row-three" },
          [
            _c(
              "div",
              {
                staticClass: "col dboard-top-box",
                attrs: { id: "dboard-top-box-left" },
              },
              [
                _c("div", { staticClass: "col dboard-bg-white" }, [
                  _c("div", { staticStyle: { width: "20px" } }, [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.right",
                            value: _vm.$t("info_impression_counts"),
                            expression: "$t('info_impression_counts')",
                            modifiers: { right: true },
                          },
                        ],
                        staticClass: "vtooltip info-icon-left",
                      },
                      [
                        _c(
                          "icon-container",
                          {
                            attrs: {
                              name: "infocircle",
                              "view-box": "0 0 512 512",
                              "is-icon-class": false,
                            },
                          },
                          [_c("icon-infocircle")],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("impression_counts").toUpperCase())),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dboard-top-box-inside dboard-top-box-inside-inner",
                    },
                    [
                      _c("div", { staticClass: "dboard-box-vertical" }, [
                        _c("div", { staticClass: "row dboard-top-row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-7 d-flex justify-content-end align-items-center",
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "max-width": "200px" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-label text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("select_dates")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("date-picker", {
                                    attrs: {
                                      type:
                                        _vm.analyzeType.name === "daily"
                                          ? "date"
                                          : "month",
                                      "value-type": "YYYY-MM-DD",
                                      format:
                                        _vm.analyzeType.name === "daily"
                                          ? _vm.userLang === "en"
                                            ? "MM-DD-YYYY"
                                            : "DD-MM-YYYY"
                                          : "MMMM YYYY",
                                      lang: _vm.userLang,
                                    },
                                    on: {
                                      change: _vm.getCustomerImpressionCount,
                                    },
                                    model: {
                                      value: _vm.impressionDate,
                                      callback: function ($$v) {
                                        _vm.impressionDate = $$v
                                      },
                                      expression: "impressionDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-4 d-flex justify-content-start align-items-center",
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "max-width": "200px" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "form-label text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("analyze_type")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("multi-select", {
                                    staticClass:
                                      "selectbox selectbox--secondary",
                                    attrs: {
                                      "track-by": "value",
                                      name: "analyzeType",
                                      label: "value",
                                      "select-label": "",
                                      "allow-empty": false,
                                      multiple: false,
                                      searchable: false,
                                      "internal-search": false,
                                      "show-labels": false,
                                      taggable: false,
                                      "clear-on-select": false,
                                      options: _vm.analyzeTypeOptions,
                                    },
                                    on: {
                                      input: _vm.getCustomerImpressionCount,
                                    },
                                    model: {
                                      value: _vm.analyzeType,
                                      callback: function ($$v) {
                                        _vm.analyzeType = $$v
                                      },
                                      expression: "analyzeType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-1 d-flex flex-column justify-content-start align-items-center",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "button button--grey",
                                  attrs: { type: "button" },
                                  on: { click: _vm.copyImpressionStats },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      type: "mdi",
                                      path: _vm.iconCopy,
                                      size: 24,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { attrs: { id: "kopybtn" } }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-center",
                            },
                            [
                              _c("highcharts", {
                                staticClass: "chart",
                                attrs: {
                                  width: "auto",
                                  options: _vm.impressionCountCharts,
                                  "update-args": _vm.updateArgs,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "col dboard-top-box",
                attrs: { id: "dboard-top-box-center" },
              },
              [
                _c("div", { staticClass: "col dboard-bg-white" }, [
                  _c("div", { staticStyle: { width: "20px" } }, [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.right",
                            value: _vm.$t("info_general_completion"),
                            expression: "$t('info_general_completion')",
                            modifiers: { right: true },
                          },
                        ],
                        staticClass: "vtooltip info-icon-left",
                      },
                      [
                        _c(
                          "icon-container",
                          {
                            attrs: {
                              name: "infocircle",
                              "view-box": "0 0 512 512",
                              "is-icon-class": false,
                            },
                          },
                          [_c("icon-infocircle")],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("completion_rate").toUpperCase())),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dboard-top-box-inside dboard-top-box-inside-inner",
                    },
                    [
                      _c("div", { staticClass: "dboard-box-vertical" }, [
                        _c("div", { staticClass: "row dboard-top-row" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col d-flex justify-content-center",
                            },
                            [
                              _c("span", { staticClass: "percent" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("penetration_rate", {
                                      rate: this.completionRate,
                                    })
                                  )
                                ),
                              ]),
                              _vm.completionRateArrow === "arrow-up"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "percent d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { attrs: { id: "arrowup" } },
                                        [
                                          _c(
                                            "icon-container",
                                            {
                                              attrs: {
                                                name: "arrowup",
                                                width: 25,
                                                height: 25,
                                                color: "#2dcf85",
                                                "view-box": "0 0 448 512",
                                                "is-icon-class": false,
                                              },
                                            },
                                            [_c("icon-arrowup")],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm.completionRateArrow === "arrow-down"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "percent d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { attrs: { id: "arrowdown" } },
                                        [
                                          _c(
                                            "icon-container",
                                            {
                                              attrs: {
                                                name: "arrowdown",
                                                width: 25,
                                                height: 25,
                                                color: "#ea0505",
                                                "view-box": "0 0 448 512",
                                                "is-icon-class": false,
                                              },
                                            },
                                            [_c("icon-arrowdown")],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "percent d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { attrs: { id: "dash" } },
                                        [
                                          _c(
                                            "icon-container",
                                            {
                                              attrs: {
                                                name: "arrowhorizontal",
                                                width: 25,
                                                height: 25,
                                                color: "#727D92",
                                                "view-box": "0 0 512 512",
                                                "is-icon-class": false,
                                              },
                                            },
                                            [_c("icon-arrowhorizontal")],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _vm.completionRateArrow === "arrow-up"
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("completion_rate_up_sub_msg", {
                                          rate: this
                                            .completionRateCompareToPrevPercent,
                                        })
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm.completionRateArrow === "arrow-down"
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("completion_rate_down_sub_msg", {
                                          rate: this
                                            .completionRateCompareToPrevPercent,
                                        })
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("completion_rate_none_sub_msg", {
                                          rate: this
                                            .completionRateCompareToPrevPercent,
                                        })
                                      ) +
                                      " "
                                  ),
                                ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "col dboard-top-box",
                attrs: { id: "dboard-top-box-right" },
              },
              [
                _c("div", { staticClass: "col dboard-bg-white" }, [
                  _c("h5", [
                    _vm._v(
                      _vm._s(_vm.$t("actionable_analytics").toUpperCase())
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dboard-top-box-inside dboard-top-box-inside-inner",
                    },
                    [
                      _c(
                        "div",
                        { attrs: { id: "dboard-top-box-right-left" } },
                        [
                          _c("div", { staticClass: "dboard-top-row" }, [
                            _c("div", { staticClass: "col-6 text-left" }, [
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.right",
                                      value: _vm.$t(
                                        "info_notification_enabled"
                                      ),
                                      expression:
                                        "$t('info_notification_enabled')",
                                      modifiers: { right: true },
                                    },
                                  ],
                                  staticClass: "info-icon-inside d-inline-flex",
                                  attrs: { href: "javascript:void(0)" },
                                },
                                [
                                  _c("h6", [
                                    _vm._v(
                                      _vm._s(_vm.$t("notification_enabled"))
                                    ),
                                  ]),
                                  _c(
                                    "icon-container",
                                    {
                                      attrs: {
                                        name: "infocircle",
                                        "view-box": "0 0 512 512",
                                        "is-icon-class": false,
                                      },
                                    },
                                    [_c("icon-infocircle")],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c("div", { staticClass: "progress" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "progress-bar",
                                    style:
                                      _vm.notificationEnabledPercent === 0
                                        ? "width:" +
                                          _vm.notificationEnabledPercent +
                                          "%;background-color:transparent;color:#000"
                                        : "width:" +
                                          _vm.notificationEnabledPercent +
                                          "%;color:#000",
                                    attrs: {
                                      role: "progressbar",
                                      "aria-valuenow":
                                        _vm.notificationEnabledPercent,
                                      "aria-valuemin": "0",
                                      "aria-valuemax": "100",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎" +
                                            _vm.notificationEnabledPercent +
                                            "%"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "dboard-top-row" }, [
                            _c("div", { staticClass: "col-6 text-left" }, [
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.right",
                                      value: _vm.$t(
                                        "info_mobile_download_penetration"
                                      ),
                                      expression:
                                        "$t('info_mobile_download_penetration')",
                                      modifiers: { right: true },
                                    },
                                  ],
                                  staticClass: "info-icon-inside d-inline-flex",
                                  attrs: { href: "javascript:void(0)" },
                                },
                                [
                                  _c("h6", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("mobile_download_penetration")
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "icon-container",
                                    {
                                      attrs: {
                                        name: "infocircle",
                                        "view-box": "0 0 512 512",
                                        "is-icon-class": false,
                                      },
                                    },
                                    [_c("icon-infocircle")],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c("div", { staticClass: "progress" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "progress-bar",
                                    style:
                                      _vm.mobileDownloadPenetrationPercent === 0
                                        ? "width:" +
                                          _vm.mobileDownloadPenetrationPercent +
                                          "%;background-color:transparent;color:#000"
                                        : "width:" +
                                          _vm.mobileDownloadPenetrationPercent +
                                          "%;color:#000",
                                    attrs: {
                                      role: "progressbar",
                                      "aria-valuenow":
                                        _vm.mobileDownloadPenetrationPercent,
                                      "aria-valuemin": "0",
                                      "aria-valuemax": "100",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎" +
                                            _vm.mobileDownloadPenetrationPercent +
                                            "%"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "dboard-top-row" }, [
                            _c("div", { staticClass: "col-6 text-left" }, [
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.right",
                                      value: _vm.$t("info_active_users"),
                                      expression: "$t('info_active_users')",
                                      modifiers: { right: true },
                                    },
                                  ],
                                  staticClass: "info-icon-inside d-inline-flex",
                                  attrs: { href: "javascript:void(0)" },
                                },
                                [
                                  _c("h6", [
                                    _vm._v(_vm._s(_vm.$t("active_users"))),
                                  ]),
                                  _c(
                                    "icon-container",
                                    {
                                      attrs: {
                                        name: "infocircle",
                                        "view-box": "0 0 512 512",
                                        "is-icon-class": false,
                                      },
                                    },
                                    [_c("icon-infocircle")],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c("div", { staticClass: "progress" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "progress-bar",
                                    style:
                                      _vm.activeUsersPercent === 0
                                        ? "width:" +
                                          _vm.activeUsersPercent +
                                          "%;background-color:transparent;color:#000"
                                        : "width:" +
                                          _vm.activeUsersPercent +
                                          "%;color:#000",
                                    attrs: {
                                      role: "progressbar",
                                      "aria-valuenow": _vm.activeUsersPercent,
                                      "aria-valuemin": "0",
                                      "aria-valuemax": "100",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎" +
                                            _vm.activeUsersPercent +
                                            "%"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { attrs: { id: "dboard-top-box-right-right" } },
                        [
                          _c("div", { staticClass: "dboard-top-row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col",
                                attrs: { id: "download-excel-button" },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button button--grey",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.reportDownload("disengaged")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        staticClass: "mx-2",
                                        attrs: {
                                          name: "flying",
                                          width: "19",
                                          height: "12",
                                          "is-icon-class": true,
                                        },
                                      },
                                      [_c("icon-flying")],
                                      1
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("export_to_excel")) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("download_excel_sub_msg"))
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "row dboard-top-row dboard-top-main-row" }, [
          _c("div", { staticClass: "col-12 col-md-6 dboard-top-box mb-4" }, [
            _c(
              "div",
              { staticClass: "chart_holder" },
              [
                _c("div", { staticStyle: { width: "20px" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.right",
                          value: _vm.$t("info_total_item_assigned"),
                          expression: "$t('info_total_item_assigned')",
                          modifiers: { right: true },
                        },
                      ],
                      staticClass: "vtooltip info-icon-left",
                    },
                    [
                      _c(
                        "icon-container",
                        {
                          attrs: {
                            name: "infocircle",
                            "view-box": "0 0 512 512",
                            "is-icon-class": false,
                          },
                        },
                        [_c("icon-infocircle")],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("h5", [
                  _vm._v(_vm._s(_vm.$t("total_item_assigned").toUpperCase())),
                ]),
                _c("highcharts", {
                  staticClass: "chart",
                  attrs: {
                    options: _vm.totalItemAssignedCharts,
                    "update-args": _vm.updateArgs,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-12 col-md-6 dboard-top-box mb-4" }, [
            _c(
              "div",
              { staticClass: "chart_holder" },
              [
                _c("div", { staticStyle: { width: "20px" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.right",
                          value: _vm.$t("info_completed_item_count"),
                          expression: "$t('info_completed_item_count')",
                          modifiers: { right: true },
                        },
                      ],
                      staticClass: "vtooltip info-icon-left",
                    },
                    [
                      _c(
                        "icon-container",
                        {
                          attrs: {
                            name: "infocircle",
                            "view-box": "0 0 512 512",
                            "is-icon-class": false,
                          },
                        },
                        [_c("icon-infocircle")],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("h5", [
                  _vm._v(_vm._s(this.$t("completed_item_count").toUpperCase())),
                ]),
                _c("highcharts", {
                  staticClass: "chart",
                  attrs: {
                    options: _vm.completedItemCountCharts,
                    "update-args": _vm.updateArgs,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "row dboard-top-row dboard-top-main-row" }, [
          _c("div", { staticClass: "col-12 dboard-top-box" }, [
            _c(
              "div",
              { staticClass: "chart_holder" },
              [
                _c("div", { staticStyle: { width: "20px" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.right",
                          value: { content: _vm.$t("info_user_count_graphic") },
                          expression:
                            "{ content: $t('info_user_count_graphic') }",
                          modifiers: { right: true },
                        },
                      ],
                      staticClass: "vtooltip info-icon-left",
                    },
                    [
                      _c(
                        "icon-container",
                        {
                          attrs: {
                            name: "infocircle",
                            "view-box": "0 0 512 512",
                            "is-icon-class": false,
                          },
                        },
                        [_c("icon-infocircle")],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("h5", [
                  _vm._v(_vm._s(_vm.$t("user_count_graphic").toUpperCase())),
                ]),
                _c("highcharts", {
                  staticClass: "chart",
                  attrs: {
                    options: _vm.userCountChartOption,
                    "update-args": _vm.updateArgs,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "row dboard-top-row dboard-top-main-row" }, [
          _c("div", { staticClass: "col-12 col-md-6 dboard-top-box mb-4" }, [
            _c(
              "div",
              { staticClass: "chart_holder" },
              [
                _c("div", { staticStyle: { width: "20px" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.right",
                          value: _vm.$t("info_average_time_spent_weekly"),
                          expression: "$t('info_average_time_spent_weekly')",
                          modifiers: { right: true },
                        },
                      ],
                      staticClass: "vtooltip info-icon-left",
                    },
                    [
                      _c(
                        "icon-container",
                        {
                          attrs: {
                            name: "infocircle",
                            "view-box": "0 0 512 512",
                            "is-icon-class": false,
                          },
                        },
                        [_c("icon-infocircle")],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("h5", [
                  _vm._v(
                    _vm._s(this.$t("average_time_spent_weekly").toUpperCase())
                  ),
                ]),
                _c("highcharts", {
                  staticClass: "chart",
                  attrs: {
                    options: _vm.averageTimeSpentRatioCharts,
                    "update-args": _vm.updateArgs,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-12 col-md-6 dboard-top-box mb-4" }, [
            _c(
              "div",
              { staticClass: "chart_holder" },
              [
                _c("div", { staticStyle: { width: "20px" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.right",
                          value: _vm.$t("info_response_rate"),
                          expression: "$t('info_response_rate')",
                          modifiers: { right: true },
                        },
                      ],
                      staticClass: "vtooltip info-icon-left",
                    },
                    [
                      _c(
                        "icon-container",
                        {
                          attrs: {
                            name: "infocircle",
                            "view-box": "0 0 512 512",
                            "is-icon-class": false,
                          },
                        },
                        [_c("icon-infocircle")],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("h5", [
                  _vm._v(_vm._s(_vm.$t("response_rate").toUpperCase())),
                ]),
                _c("highcharts", {
                  staticClass: "chart",
                  attrs: {
                    options: _vm.responseRatioCharts,
                    "update-args": _vm.updateArgs,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "row dboard-top-row dboard-top-main-row" }, [
          _c("div", { staticClass: "col-12 dboard-top-box mb-4" }, [
            _c("div", { staticClass: "chart_holder" }, [
              _c("div", { staticStyle: { width: "20px" } }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.right",
                        value: _vm.$t("info_content_average_rating"),
                        expression: "$t('info_content_average_rating')",
                        modifiers: { right: true },
                      },
                    ],
                    staticClass: "vtooltip info-icon-left",
                  },
                  [
                    _c(
                      "icon-container",
                      {
                        attrs: {
                          name: "infocircle",
                          "view-box": "0 0 512 512",
                          "is-icon-class": false,
                        },
                      },
                      [_c("icon-infocircle")],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("h5", [
                _vm._v(_vm._s(this.$t("content_average_rating").toUpperCase())),
              ]),
              _c("table", { staticClass: "table" }, [
                _c("thead", { staticClass: "thead-light" }, [
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticStyle: { width: "40%" },
                        attrs: { scope: "col" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("content_name")) + " ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        staticStyle: { width: "20%" },
                        attrs: { scope: "col" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("avg_rating")) + " ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        staticStyle: { width: "20%" },
                        attrs: { scope: "col" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("vote_count")) + " ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        staticStyle: { width: "20%" },
                        attrs: { scope: "col" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("content_type")) + " ")]
                    ),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.averageRatingsTable, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c(
                        "td",
                        {
                          staticClass: "preview-item",
                          on: {
                            click: function ($event) {
                              return _vm.previewContentItem(item.id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(index + 1 + ". " + item.name) + " "
                          ),
                        ]
                      ),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("rate_info", {
                                rate: Math.ceil((item.avg_rating * 100) / 3),
                              })
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(" " + _vm._s(item.vote_count) + " "),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$helpers.getSubTypeName(item.content_type)
                            ) +
                            " "
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
                _c("tfoot", [
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "text-center", attrs: { colspan: "4" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "AverageRatings",
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("all_list")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row dboard-top-row dboard-top-main-row" }, [
          _c("div", { staticClass: "col-12 dboard-top-box mb-4" }, [
            _c("div", { staticClass: "chart_holder" }, [
              _c("div", { staticStyle: { width: "20px" } }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.right",
                        value: _vm.$t("info_group_analytics"),
                        expression: "$t('info_group_analytics')",
                        modifiers: { right: true },
                      },
                    ],
                    staticClass: "vtooltip info-icon-left",
                  },
                  [
                    _c(
                      "icon-container",
                      {
                        attrs: {
                          name: "infocircle",
                          "view-box": "0 0 512 512",
                          "is-icon-class": false,
                        },
                      },
                      [_c("icon-infocircle")],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("h5", [
                _vm._v(_vm._s(this.$t("group_analytics").toUpperCase())),
              ]),
              _c("table", { staticClass: "table" }, [
                _c("thead", { staticClass: "thead-light" }, [
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticStyle: { width: "40%" },
                        attrs: { scope: "col" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("group_name")) + " ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        staticStyle: { width: "20%" },
                        attrs: { scope: "col" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("completion_rate")) + " ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        staticStyle: { width: "20%" },
                        attrs: { scope: "col" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("response_rate")) + " ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-center",
                        staticStyle: { width: "20%" },
                        attrs: { scope: "col" },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("user_count")) + " ")]
                    ),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.usergroupAnalyticsTable, function (item, index) {
                    return _c("tr", { key: index }, [
                      _c(
                        "th",
                        {
                          staticClass: "gradient-back",
                          attrs: { scope: "row" },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "UserGroupOverview",
                                  params: {
                                    userGroupId: item.user_group_id,
                                    userGroupName: item.name,
                                  },
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("rate_info", {
                                rate: item.completion_rate,
                              })
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("rate_info", { rate: item.response_rate })
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", { staticClass: "text-center" }, [
                        _vm._v(" " + _vm._s(item.user_count) + " "),
                      ]),
                    ])
                  }),
                  0
                ),
                _c("tfoot", [
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "text-center", attrs: { colspan: "4" } },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "UserGroupAnalytics",
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("all_list")) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }