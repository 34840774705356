var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
      _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("header", { staticClass: "modal-header" }, [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(" " + _vm._s(_vm.$t("upload_image_content")) + " "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: { type: "button" },
              domProps: { innerHTML: _vm._s("&times;") },
              on: { click: _vm.closeModal },
            }),
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "form form--horizontal form--strong" }, [
              _vm.ItemContent.thumbnail_image
                ? _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c(
                          "vue-dropzone",
                          {
                            ref: "thumbnailDropzone",
                            attrs: {
                              id: "thumbnailDropzone",
                              options: _vm.dropOptions("thumbnails"),
                              "use-custom-slot": true,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "dropzone-custom-content" },
                              [
                                _c(
                                  "h4",
                                  { staticClass: "dropzone-custom-title" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("upload_thumbnail")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.ItemContent.content_url
                ? _c("div", { staticClass: "form-group row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c(
                          "vue-dropzone",
                          {
                            ref: "contentDropzone",
                            attrs: {
                              id: "contentDropzone",
                              options: _vm.dropOptions("contents"),
                              "use-custom-slot": true,
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "dropzone-custom-content" },
                              [
                                _c(
                                  "h4",
                                  { staticClass: "dropzone-custom-title" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("upload_content")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.ItemContent &&
              _vm.ItemContent.flipboard_items &&
              _vm.ItemContent.flipboard_items.length
                ? _c("div", [
                    _c("label", [_vm._v(_vm._s(_vm.$t("FLIPBOOKS")))]),
                  ])
                : _vm._e(),
              _vm.ItemContent &&
              _vm.ItemContent.flipboard_items &&
              _vm.ItemContent.flipboard_items.length
                ? _c(
                    "div",
                    { staticClass: "form-group row" },
                    [
                      _vm._l(
                        _vm.ItemContent.flipboard_items,
                        function (item, index) {
                          return [
                            _c(
                              "div",
                              { key: index, staticClass: "col-4 py-2" },
                              [
                                _c(
                                  "vue-dropzone",
                                  {
                                    ref: `flipbookDropzone-${index}`,
                                    refInFor: true,
                                    attrs: {
                                      id: `flipbookDropzone-${index}`,
                                      options: _vm.dropOptionsForFlip(
                                        "thumbnails",
                                        index
                                      ),
                                      "use-custom-slot": true,
                                    },
                                    on: {
                                      "vdropzone-sending": (
                                        file,
                                        xhr,
                                        formData
                                      ) => {
                                        _vm.uploadFileSend(
                                          file,
                                          xhr,
                                          formData,
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropzone-custom-content",
                                      },
                                      [
                                        _c(
                                          "h4",
                                          {
                                            staticClass:
                                              "dropzone-custom-title",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "upload_flipbook_page",
                                                    { page: index + 1 }
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.ItemContent &&
              _vm.ItemContent.flipbook_editable_items &&
              _vm.ItemContent.flipbook_editable_items.length
                ? _c("div", [
                    _c("label", [_vm._v(_vm._s(_vm.$t("image_library_")))]),
                  ])
                : _vm._e(),
              _vm.ItemContent &&
              _vm.ItemContent.flipbook_editable_items &&
              _vm.ItemContent.flipbook_editable_items.length
                ? _c(
                    "div",
                    { staticClass: "form-group row" },
                    [
                      _vm._l(
                        _vm.ItemContent.flipbook_editable_items,
                        function (item, index) {
                          return [
                            _vm._l(item.images, function (image, subindex) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: `${index}${subindex}`,
                                    staticClass: "col-4 py-2",
                                  },
                                  [
                                    _c(
                                      "vue-dropzone",
                                      {
                                        ref: `imageLibraryDropzone${index}${subindex}`,
                                        refInFor: true,
                                        attrs: {
                                          id: `imageLibraryDropzone${index}${subindex}`,
                                          options: _vm.dropOptionsForFlip(
                                            "image_library",
                                            index,
                                            subindex
                                          ),
                                          "use-custom-slot": true,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "dropzone-custom-content",
                                          },
                                          [
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "dropzone-custom-title",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "upload_image_library",
                                                        {
                                                          name:
                                                            item.type +
                                                            "-" +
                                                            image.name,
                                                          item: index + 1,
                                                        }
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            }),
                          ]
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "button button--primary",
                    attrs: { type: "button", disabled: _vm.isPending },
                    on: { click: _vm.closeModal },
                  },
                  [
                    !_vm.isPending
                      ? _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("close")) + " "),
                        ])
                      : _vm._e(),
                    _vm.isPending
                      ? _c("div", { staticClass: "lds-ellipsis" }, [
                          _c("div"),
                          _c("div"),
                          _c("div"),
                          _c("div"),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }