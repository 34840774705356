var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        d: "M3.603 13.691V6.583h7.108v7.108L7.157 12.36l-3.554 1.33Z",
        fill: "currentColor",
        stroke: "currentColor",
        "stroke-width": "1.5",
        "stroke-linejoin": "round",
      },
    }),
    _c("circle", {
      attrs: {
        cx: "6.997",
        cy: "6.423",
        r: "5.31",
        stroke: "currentColor",
        fill: "#F5F6F8",
        "stroke-width": "1.5",
        "stroke-linejoin": "round",
      },
    }),
    _c("path", {
      attrs: {
        d: "M6.575 4.208a.5.5 0 0 1 .951 0l.21.647a.5.5 0 0 0 .476.345h.68a.5.5 0 0 1 .294.905l-.55.4a.5.5 0 0 0-.182.558l.21.647a.5.5 0 0 1-.77.56l-.55-.4a.5.5 0 0 0-.587 0l-.55.4a.5.5 0 0 1-.77-.56l.21-.647a.5.5 0 0 0-.181-.559l-.55-.4a.5.5 0 0 1 .293-.904h.68a.5.5 0 0 0 .476-.345l.21-.647Z",
        fill: "currentColor",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }