var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M11.7928 11.3729L9.06902 9.8457V1.62668L11.7928 3.15282V11.3729ZM7.86183 9.84681L5.13804 11.3729V3.15393L7.86183 1.62668V9.84681ZM3.93098 11.3729L1.20707 9.84681V1.62668L3.93098 3.15282V11.3729ZM12.6933 2.28026L8.76235 0.0764808C8.75776 0.0738901 8.75279 0.0727798 8.7482 0.0703125C8.72051 0.0557556 8.69195 0.0435425 8.6629 0.03355C8.6552 0.0309593 8.64775 0.028122 8.64006 0.0259014C8.6079 0.0161556 8.57512 0.00900049 8.54185 0.00492947C8.53104 0.00357246 8.52049 0.0034491 8.50957 0.00258555C8.48734 0.00110518 8.46512 0.000611719 8.44289 0.00159863C8.42837 0.00209209 8.41421 0.00246218 8.39993 0.00406592C8.37138 0.00715003 8.34307 0.0119612 8.31513 0.0191164C8.30073 0.0226939 8.28707 0.0278752 8.27292 0.0324397C8.2538 0.0388546 8.23505 0.0460098 8.21643 0.0541518C8.20513 0.0592098 8.19371 0.064021 8.18266 0.0699424C8.17794 0.0722864 8.1731 0.0740135 8.1685 0.0764808L4.53451 2.11372L0.900521 0.0764808C0.712793 -0.0262817 0.48459 -0.0262817 0.298849 0.081292C0.114102 0.188989 0 0.385878 0 0.599299V10.1967C0 10.4128 0.116957 10.6128 0.306547 10.7194L4.2374 12.9231C4.259 12.935 4.2811 12.9451 4.30358 12.9542C4.3042 12.9545 4.30482 12.9548 4.30544 12.9551C4.32816 12.9642 4.35113 12.9719 4.3746 12.9782C4.37646 12.9787 4.37807 12.9794 4.38006 12.9799C4.43059 12.9932 4.48236 13 4.53451 13H4.53501H4.5355C4.56071 13 4.58629 12.9984 4.61186 12.9951C4.62353 12.9936 4.63496 12.9906 4.6465 12.9885C4.66016 12.9859 4.67382 12.9841 4.68735 12.9805C4.70126 12.9769 4.71467 12.9717 4.72832 12.9672C4.73975 12.9635 4.75129 12.9602 4.76259 12.9556C4.77203 12.9518 4.78109 12.9468 4.7904 12.9425C4.80418 12.9362 4.81796 12.9305 4.83137 12.9231L8.46536 10.8859L12.0995 12.9231C12.1922 12.9741 12.2938 13 12.3963 13C12.5018 13 12.6073 12.9732 12.701 12.9183C12.8858 12.8106 13 12.6137 13 12.4003V2.80308C13 2.58682 12.8828 2.38685 12.6933 2.28026V2.28026Z",
      fill: "currentColor",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }