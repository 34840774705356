var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("app-header", {
        attrs: {
          childTitle:
            _vm.modal && _vm.modal.journeyDetail
              ? _vm.modal.journeyDetail.name
              : null,
          startTheJourney: _vm.startTheJourney,
          assignTheJourney: _vm.assignTheJourney,
          totalUsers:
            _vm.journeyDetail &&
            _vm.journeyDetail.duplicated_journey_id === null
              ? undefined
              : _vm.journeyTotalUser,
          totalJourney: _vm.totalJourney,
          justifyBetweenClass: "",
        },
        scopedSlots: _vm._u([
          {
            key: "center",
            fn: function () {
              return [
                _c("div", { staticClass: "d-flex flex-row-reverse" }, [
                  _c(
                    "div",
                    { staticClass: "view-mode-type d-flex align-items-center" },
                    [
                      _c(
                        "label",
                        { staticClass: "m-0" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.currentViewMode,
                                expression: "currentViewMode",
                              },
                            ],
                            attrs: { type: "radio", value: "card" },
                            domProps: {
                              checked: _vm._q(_vm.currentViewMode, "card"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.currentViewMode = "card"
                              },
                            },
                          }),
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 40,
                                height: 40,
                                "view-box": "0 0 512 512",
                                name: "card",
                                color: "#727D92",
                              },
                            },
                            [_c("icon-card")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "label",
                        { staticClass: "m-0" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.currentViewMode,
                                expression: "currentViewMode",
                              },
                            ],
                            attrs: { type: "radio", value: "weekly" },
                            domProps: {
                              checked: _vm._q(_vm.currentViewMode, "weekly"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.currentViewMode = "weekly"
                              },
                            },
                          }),
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 40,
                                height: 40,
                                rotate: 90,
                                "view-box": "0 0 512 512",
                                name: "weekly",
                                color: "#727D92",
                              },
                            },
                            [_c("icon-list")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "label",
                        { staticClass: "m-0" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.currentViewMode,
                                expression: "currentViewMode",
                              },
                            ],
                            attrs: { type: "radio", value: "daily" },
                            domProps: {
                              checked: _vm._q(_vm.currentViewMode, "daily"),
                            },
                            on: {
                              change: function ($event) {
                                _vm.currentViewMode = "daily"
                              },
                            },
                          }),
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 37,
                                height: 37,
                                "view-box": "0 0 36 36",
                                name: "daily",
                                color: "#727D92",
                              },
                            },
                            [_c("icon-daily")],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "dropdown",
                    on: {
                      mouseover: function ($event) {
                        return _vm.dropdownToggle(`three11`)
                      },
                      mouseleave: _vm.hideDropdown,
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "card--actions dropdown__toggle" },
                      [
                        _c(
                          "icon-container",
                          {
                            attrs: {
                              width: 20,
                              height: 20,
                              "view-box": "0 0 24 24",
                              name: "threedots",
                              color: "#727d92",
                            },
                          },
                          [_c("icon-threedots")],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.dropdownIndex === "three11"
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "on-clickaway",
                                rawName: "v-on-clickaway",
                                value: _vm.hideDropdown,
                                expression: "hideDropdown",
                              },
                            ],
                            ref: `three11`,
                            staticClass: "dropdown__menu right",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "dropdown__button button",
                                on: {
                                  click: function ($event) {
                                    return _vm.onAction(
                                      "notification",
                                      _vm.modal.journeyDetail
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      width: 18,
                                      height: 18,
                                      "view-box": "0 0 24 24",
                                      name: "edit",
                                      color: "#727d92",
                                    },
                                  },
                                  [_c("icon-bell")],
                                  1
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("notification_button")) +
                                    " "
                                ),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "dropdown__button button",
                                on: {
                                  click: function ($event) {
                                    return _vm.onAction(
                                      "download-activity",
                                      _vm.modal.journeyDetail
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      width: "13",
                                      height: "14",
                                      name: "Download Activity",
                                      color: "#727d92",
                                    },
                                  },
                                  [_c("icon-download")],
                                  1
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("download_activity")) +
                                    " "
                                ),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "dropdown__button button",
                                on: {
                                  click: function ($event) {
                                    return _vm.onAction("export-content-list")
                                  },
                                },
                              },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      name: "content",
                                      "view-box": "0 0 13 14",
                                      color: "#727d92",
                                      "is-icon-class": false,
                                    },
                                  },
                                  [_c("icon-download")],
                                  1
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("download_journey_content_report")
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "dropdown__button button",
                                on: {
                                  click: function ($event) {
                                    return _vm.onAction("delete-item")
                                  },
                                },
                              },
                              [
                                _c(
                                  "icon-container",
                                  {
                                    attrs: {
                                      width: 18,
                                      height: 18,
                                      "view-box": "0 0 24 24",
                                      name: "trash",
                                      color: "#727d92",
                                    },
                                  },
                                  [_c("icon-trash")],
                                  1
                                ),
                                _vm._v(" " + _vm._s(_vm.$t("delete")) + " "),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "main",
        {
          staticClass: "page__body u-with-sidebar",
          class: { "sidebar-hidden": _vm.sidebarStatus },
        },
        [
          _c("content-library", {
            ref: "contentLibrary",
            attrs: {
              currentJourneyItems: _vm.currentJourneyItems,
              disabled: _vm.canNotEdit,
            },
            on: {
              updateJourneyItemsAfterDrop: _vm.updateJourneyItemsAfterDrop,
            },
          }),
          _c(
            "div",
            {
              staticClass: "container-fluid",
              class: {
                "ilist-content":
                  _vm.currentViewMode === "weekly" ||
                  _vm.currentViewMode === "daily",
              },
              attrs: { id: "mainlist" },
            },
            [
              !_vm.parentMounted || _vm.refreshPage
                ? _c("div", {
                    staticClass: "lds-dual-ring",
                    class: { "with-sidebar": !_vm.sidebarStatus },
                  })
                : _vm._e(),
              _vm.parentMounted
                ? [
                    _vm.currentViewMode === "card"
                      ? [
                          _vm.isDraggingFromLibrary && _vm.journeyTotalUser > 0
                            ? _c("div", {
                                staticClass: "weeklylist-drop-zone",
                                class: {
                                  "zone-is-active":
                                    _vm.isDraggingFromLibrary &&
                                    _vm.journeyTotalUser,
                                },
                                on: {
                                  dragover: function ($event) {
                                    $event.preventDefault()
                                  },
                                  drop: _vm.cardDropped,
                                },
                              })
                            : _vm._e(),
                          _c(
                            "draggable-comp",
                            _vm._b(
                              {
                                attrs: { handle: ".button-handle", delay: "0" },
                                on: {
                                  start: function ($event) {
                                    _vm.isDragging = true
                                  },
                                  end: function ($event) {
                                    _vm.isDragging = false
                                  },
                                  change: _vm.cardItemMoved,
                                },
                                model: {
                                  value: _vm.cardList,
                                  callback: function ($$v) {
                                    _vm.cardList = $$v
                                  },
                                  expression: "cardList",
                                },
                              },
                              "draggable-comp",
                              _vm.dragOptions,
                              false
                            ),
                            [
                              _c(
                                "transition-group",
                                {
                                  class: _vm.isDropFromLibrary
                                    ? "row no-anim"
                                    : "row",
                                  attrs: {
                                    appear: "",
                                    type: "transition",
                                    name: "slide-in",
                                    tag: "ul",
                                    id: "itemslist",
                                  },
                                },
                                [
                                  _vm._l(_vm.cardList, function (list, index) {
                                    return _c(
                                      "li",
                                      {
                                        key: `${index}-${list.item.content.id}`,
                                        staticClass:
                                          "list-group-item card card--catalog card--catalog-item button-handle",
                                        style: { "--i": index },
                                        attrs: {
                                          id:
                                            _vm.updatedContentId ===
                                            list.item.content.content_id
                                              ? "updatedContent"
                                              : list.item.content.content_id,
                                          "is-disabled": _vm.canNotEdit,
                                          dropzone: "",
                                        },
                                        on: { drag: _vm.autoScroll },
                                      },
                                      [
                                        list.item.type === "journey_item"
                                          ? [
                                              list.item.content.sub_type ===
                                              "CHAPTER"
                                                ? [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "card-button",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editContentItem(
                                                              list.item.content
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "card__body",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "card__content",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "chapter-content",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "chapter-content__title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              list
                                                                                .item
                                                                                .content
                                                                                .name
                                                                            ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              list
                                                                                .item
                                                                                .content
                                                                                .id ===
                                                                                1
                                                                                ? "🏁"
                                                                                : "🚀"
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "chapter-content__description",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              list
                                                                                .item
                                                                                .content
                                                                                .desc
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card__footer",
                                                      },
                                                      [
                                                        !_vm.canNotEdit
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "button-handle",
                                                              },
                                                              [
                                                                _c(
                                                                  "icon-container",
                                                                  {
                                                                    attrs: {
                                                                      name: "handle",
                                                                      "view-box":
                                                                        "0 0 25 18",
                                                                      "is-icon-class": false,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "icon-handle"
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        !_vm.canNotEdit
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dropdown",
                                                                on: {
                                                                  mouseover:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.dropdownToggle(
                                                                        index
                                                                      )
                                                                    },
                                                                  mouseleave:
                                                                    _vm.hideDropdown,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "card--actions dropdown__toggle",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "icon-container",
                                                                      {
                                                                        staticClass:
                                                                          "threedots",
                                                                        attrs: {
                                                                          name: "threedotsvertical",
                                                                          width: 5,
                                                                          height: 22,
                                                                          "view-box":
                                                                            "0 0 5 22",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "icon-threedotsvertical"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.dropdownIndex ===
                                                                index
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "on-clickaway",
                                                                              rawName:
                                                                                "v-on-clickaway",
                                                                              value:
                                                                                _vm.hideDropdown,
                                                                              expression:
                                                                                "hideDropdown",
                                                                            },
                                                                          ],
                                                                        ref: `dropdownBox${index}`,
                                                                        refInFor: true,
                                                                        staticClass:
                                                                          "dropdown__menu",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "dropdown__button button",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.editContentItem(
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "icon-container",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: "setting",
                                                                                    "view-box":
                                                                                      "0 0 15 15",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "icon-setting"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "edit"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "dropdown__button button",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteJourneyItem(
                                                                                    list
                                                                                      .item
                                                                                      .content,
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "icon-container",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    width: 24,
                                                                                    height: 24,
                                                                                    name: "trash",
                                                                                    color:
                                                                                      "#727d92",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "icon-trash"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "remove"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                : [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "card-button",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editContentItem(
                                                              list.item.content
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("figure", {
                                                          staticClass:
                                                            "card__media no-pointer",
                                                          class: {
                                                            "card-grey":
                                                              _vm.journeyHaveUsers &&
                                                              _vm.itemTimeAlreadyPassed(
                                                                list.item
                                                                  .content
                                                                  .activation_date
                                                              ),
                                                          },
                                                          style:
                                                            "background-image: url(" +
                                                            list.item.content
                                                              .thumbnail_image +
                                                            ");",
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "card__body",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "card__content",
                                                              },
                                                              [
                                                                !_vm.isHidden(
                                                                  list
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "card__info no-pointer",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$helpers.getSubTypeName(
                                                                                list
                                                                                  .item
                                                                                  .content
                                                                                  .sub_type
                                                                              )
                                                                            ) +
                                                                            " " +
                                                                            _vm._s(
                                                                              list
                                                                                .item
                                                                                .content
                                                                                .content &&
                                                                                list
                                                                                  .item
                                                                                  .content
                                                                                  .content
                                                                                  .quiz &&
                                                                                list
                                                                                  .item
                                                                                  .content
                                                                                  .sub_type !=
                                                                                  "QUIZ"
                                                                                ? _vm.$t(
                                                                                    "has_questions"
                                                                                  )
                                                                                : ""
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                list.item
                                                                  .content &&
                                                                list.item
                                                                  .content
                                                                  .name &&
                                                                !_vm.isHidden(
                                                                  list
                                                                )
                                                                  ? _c(
                                                                      "h3",
                                                                      {
                                                                        staticClass:
                                                                          "card__title no-pointer",
                                                                      },
                                                                      [
                                                                        _vm.subTypeControl(
                                                                          list
                                                                            .item
                                                                            .content
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      list
                                                                                        .item
                                                                                        .content
                                                                                        .name
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "card__bottom no-pointer",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "card__bottom__left",
                                                                  },
                                                                  [
                                                                    !_vm.isHidden(
                                                                      list
                                                                    )
                                                                      ? _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "card__info",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                list
                                                                                  .item
                                                                                  .content
                                                                                  .info
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _vm.journeyHaveUsers
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "card__bottom__right",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "card__time",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$helpers.getUserLangBasedDateTimeFormat(
                                                                                  list
                                                                                    .item
                                                                                    .content
                                                                                    .activation_date,
                                                                                  _vm.userLang,
                                                                                  "time"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "card__date",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$helpers.getUserLangBasedDateTimeFormat(
                                                                                  list
                                                                                    .item
                                                                                    .content
                                                                                    .activation_date,
                                                                                  _vm.userLang,
                                                                                  "date"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card__footer",
                                                      },
                                                      [
                                                        !_vm.canNotEdit
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "button-handle",
                                                              },
                                                              [
                                                                _c(
                                                                  "icon-container",
                                                                  {
                                                                    attrs: {
                                                                      name: "handle",
                                                                      "view-box":
                                                                        "0 0 25 18",
                                                                      "is-icon-class": false,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "icon-handle"
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.isVibonsAdmin
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "button-upload",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.uploadImageContent(
                                                                        list
                                                                          .item
                                                                          .content
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "icon-container",
                                                                  {
                                                                    attrs: {
                                                                      name: "upload",
                                                                      "view-box":
                                                                        "0 0 334 334",
                                                                      height:
                                                                        "24",
                                                                      width:
                                                                        "24",
                                                                      color:
                                                                        "#fa314a",
                                                                      "is-icon-class": true,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "icon-upload"
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "order",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                list.listordernumber
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        !_vm.canNotEdit
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "dropdown",
                                                                on: {
                                                                  mouseover:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.dropdownToggle(
                                                                        index
                                                                      )
                                                                    },
                                                                  mouseleave:
                                                                    _vm.hideDropdown,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "card--actions dropdown__toggle",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "icon-container",
                                                                      {
                                                                        staticClass:
                                                                          "threedots",
                                                                        attrs: {
                                                                          name: "threedotsvertical",
                                                                          width: 5,
                                                                          height: 22,
                                                                          "view-box":
                                                                            "0 0 5 22",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "icon-threedotsvertical"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.dropdownIndex ===
                                                                index
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "on-clickaway",
                                                                              rawName:
                                                                                "v-on-clickaway",
                                                                              value:
                                                                                _vm.hideDropdown,
                                                                              expression:
                                                                                "hideDropdown",
                                                                            },
                                                                          ],
                                                                        ref: `dropdownBox${index}`,
                                                                        refInFor: true,
                                                                        staticClass:
                                                                          "dropdown__menu",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "dropdown__button button",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.editContentItem(
                                                                                    list
                                                                                      .item
                                                                                      .content,
                                                                                    "advancedSettingsTab"
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "icon-container",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: "setting",
                                                                                    "view-box":
                                                                                      "0 0 15 15",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "icon-setting"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "settings"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    _vm.journeyHaveUsers,
                                                                                  expression:
                                                                                    "journeyHaveUsers",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "dropdown__button button",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.sendNotification(
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "icon-container",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    width: 18,
                                                                                    height: 18,
                                                                                    "view-box":
                                                                                      "0 0 24 24",
                                                                                    name: "edit",
                                                                                    color:
                                                                                      "#727d92",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "icon-bell"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "notification_touser_noncompleted"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                      .sub_type ===
                                                                                    "CHECKLIST",
                                                                                  expression:
                                                                                    "list.item.content.sub_type === 'CHECKLIST'",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "dropdown__button button",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.showChecklistDetailModal(
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "icon-container",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: "stats",
                                                                                    width:
                                                                                      "24",
                                                                                    height:
                                                                                      "24",
                                                                                    "view-box":
                                                                                      "0 0 460 460",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "icon-stats"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "show_stats_data"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                      .sub_type ===
                                                                                    "REQUEST",
                                                                                  expression:
                                                                                    "list.item.content.sub_type === 'REQUEST'",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "dropdown__button button",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.showRequestDetailModal(
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "icon-container",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: "stats",
                                                                                    width:
                                                                                      "24",
                                                                                    height:
                                                                                      "24",
                                                                                    "view-box":
                                                                                      "0 0 460 460",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "icon-stats"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "show_stats_data"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                      .sub_type ===
                                                                                    "QUIZ",
                                                                                  expression:
                                                                                    "list.item.content.sub_type === 'QUIZ'",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "dropdown__button button",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.showQuizDetailModal(
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "icon-container",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: "stats",
                                                                                    width:
                                                                                      "24",
                                                                                    height:
                                                                                      "24",
                                                                                    "view-box":
                                                                                      "0 0 460 460",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "icon-stats"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "show_stats_data"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                      .sub_type ===
                                                                                    "SURVEY",
                                                                                  expression:
                                                                                    "list.item.content.sub_type === 'SURVEY'",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "dropdown__button button",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.showSurveyDetailModal(
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "icon-container",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: "stats",
                                                                                    width:
                                                                                      "24",
                                                                                    height:
                                                                                      "24",
                                                                                    "view-box":
                                                                                      "0 0 460 460",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "icon-stats"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "show_stats_data"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "dropdown__button button",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteJourneyItem(
                                                                                    list
                                                                                      .item
                                                                                      .content,
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "icon-container",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    width: 24,
                                                                                    height: 24,
                                                                                    name: "trash",
                                                                                    color:
                                                                                      "#727d92",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "icon-trash"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "remove"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                            ]
                                          : _vm._e(),
                                        list.item.type ===
                                        "item_scheduled_notification"
                                          ? [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "card-button",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editScheduledNotificationItem(
                                                        list.item.content
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("figure", {
                                                    staticClass: "card__media",
                                                    class: {
                                                      "card-grey":
                                                        _vm.journeyHaveUsers &&
                                                        _vm.itemTimeAlreadyPassed(
                                                          list.item.content
                                                            .notify_dt
                                                        ),
                                                    },
                                                    style: {
                                                      "background-image":
                                                        "url(" +
                                                        require("@/assets/img/scheduled-notification-design.png") +
                                                        ")",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "card__body",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "card__content",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "notification-content__info",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "notification-content__info-content",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "notification-content__info-header",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: require("@/assets/img/journey-app-icon.png"),
                                                                              alt: "",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "snotification_preview"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "notification-content__info-title",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          list
                                                                            .item
                                                                            .content
                                                                            .title
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "notification-content__info-body",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          list
                                                                            .item
                                                                            .content
                                                                            .body
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "card__bottom",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "card__bottom__left",
                                                            },
                                                            [
                                                              !_vm.isHidden(
                                                                list
                                                              )
                                                                ? _c(
                                                                    "small",
                                                                    {
                                                                      staticClass:
                                                                        "card__info",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          list
                                                                            .item
                                                                            .info
                                                                        )
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _vm.journeyHaveUsers
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "card__bottom__right",
                                                                },
                                                                [
                                                                  _c(
                                                                    "small",
                                                                    {
                                                                      staticClass:
                                                                        "card__time",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$helpers.getUserLangBasedDateTimeFormat(
                                                                            list
                                                                              .item
                                                                              .content
                                                                              .notify_dt,
                                                                            _vm.userLang,
                                                                            "time"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "small",
                                                                    {
                                                                      staticClass:
                                                                        "card__date",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$helpers.getUserLangBasedDateTimeFormat(
                                                                            list
                                                                              .item
                                                                              .content
                                                                              .notify_dt,
                                                                            _vm.userLang,
                                                                            "date"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "card__footer" },
                                                [
                                                  !_vm.canNotEdit
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "button-handle",
                                                        },
                                                        [
                                                          _c(
                                                            "icon-container",
                                                            {
                                                              attrs: {
                                                                name: "handle",
                                                                "view-box":
                                                                  "0 0 25 18",
                                                                "is-icon-class": false,
                                                              },
                                                            },
                                                            [_c("icon-handle")],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.isVibonsAdmin
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "button-upload",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.uploadImageContent(
                                                                list.item
                                                                  .content
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "icon-container",
                                                            {
                                                              attrs: {
                                                                name: "upload",
                                                                "view-box":
                                                                  "0 0 334 334",
                                                                height: "24",
                                                                width: "24",
                                                                color:
                                                                  "#fa314a",
                                                                "is-icon-class": true,
                                                              },
                                                            },
                                                            [_c("icon-upload")],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "span",
                                                    { staticClass: "order" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          list.listordernumber
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  !_vm.canNotEdit
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dropdown",
                                                          on: {
                                                            mouseover:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.dropdownToggle(
                                                                  index
                                                                )
                                                              },
                                                            mouseleave:
                                                              _vm.hideDropdown,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "card--actions dropdown__toggle",
                                                            },
                                                            [
                                                              _c(
                                                                "icon-container",
                                                                {
                                                                  staticClass:
                                                                    "threedots",
                                                                  attrs: {
                                                                    name: "threedotsvertical",
                                                                    width: 5,
                                                                    height: 22,
                                                                    "view-box":
                                                                      "0 0 5 22",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "icon-threedotsvertical"
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm.dropdownIndex ===
                                                          index
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "on-clickaway",
                                                                      rawName:
                                                                        "v-on-clickaway",
                                                                      value:
                                                                        _vm.hideDropdown,
                                                                      expression:
                                                                        "hideDropdown",
                                                                    },
                                                                  ],
                                                                  ref: `dropdownBox${index}`,
                                                                  refInFor: true,
                                                                  staticClass:
                                                                    "dropdown__menu",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "dropdown__button button",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.deleteScheduledNotificationItem(
                                                                              list
                                                                                .item
                                                                                .content,
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "icon-container",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              width: 24,
                                                                              height: 24,
                                                                              name: "trash",
                                                                              color:
                                                                                "#727d92",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "icon-trash"
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "remove"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "card card--catalog-item drop-placeholder",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card__body" },
                                              [
                                                _c("h4", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("drag_drop"))
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    )
                                  }),
                                  !_vm.canNotEdit
                                    ? _c(
                                        "li",
                                        {
                                          key: "created",
                                          staticClass:
                                            "list-group-item card card--catalog card--create card--create-item",
                                          attrs: { id: "dropzone" },
                                          on: {
                                            dragover: function ($event) {
                                              $event.preventDefault()
                                            },
                                            drop: _vm.cardDropped,
                                            click: _vm.createNewItem,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "card__body" },
                                            [
                                              _c("h4", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("drag_drop"))
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "card__footer" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "button button--primary size-sm",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "create_new_content"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.currentViewMode === "weekly"
                      ? [
                          _c("div", {
                            staticClass: "weekly-drop-zone",
                            class: { "zone-is-active": _vm.isDragging },
                            attrs: { id: "dropzone" },
                            on: {
                              dragover: function ($event) {
                                $event.preventDefault()
                              },
                              drop: _vm.cardDropped,
                            },
                          }),
                          _vm._l(_vm.weeklyList, function (aweeklist, windex) {
                            return _c(
                              "section",
                              {
                                key: windex,
                                class:
                                  aweeklist.length === 0
                                    ? "week-ilist is-ghost"
                                    : "week-ilist",
                                attrs: { id: windex },
                              },
                              [
                                _c("div", { staticClass: "title no-pointer" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        windex < 1000
                                          ? _vm.$tc(
                                              "weeks_before",
                                              1000 - windex,
                                              { week: 1000 - windex }
                                            )
                                          : _vm.$t("week_number", {
                                              number: windex - 1000,
                                            })
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.sectionId === windex &&
                                          _vm.isDragging &&
                                          _vm.dayChosenText,
                                        expression:
                                          "sectionId === windex && isDragging && dayChosenText",
                                      },
                                    ],
                                    staticClass: "dayribbon no-pointer",
                                  },
                                  [_vm._v(_vm._s(_vm.dayChosenText))]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "ilistings" },
                                  [
                                    _c(
                                      "transition-group",
                                      {
                                        class: _vm.isDropFromLibrary
                                          ? "row no-anim flex-column"
                                          : "row flex-column",
                                        attrs: {
                                          appear: "",
                                          type: "transition",
                                          name: "slide-in",
                                          tag: "ul",
                                          "data-weekId": windex,
                                          "data-weekStart":
                                            aweeklist && aweeklist[0]
                                              ? aweeklist[0].weekStart
                                              : null,
                                          "data-weekEnd":
                                            aweeklist && aweeklist[0]
                                              ? aweeklist[0].weekEnd
                                              : null,
                                          "data-weekNum":
                                            aweeklist && aweeklist[0]
                                              ? aweeklist[0].weeknum
                                              : null,
                                          id: "itemslist",
                                        },
                                      },
                                      _vm._l(aweeklist, function (list, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: list.item.content.id,
                                            staticClass:
                                              "list-group-item ilist list--catalog list--catalog-item button-handle",
                                            style: { "--i": index },
                                            attrs: {
                                              id:
                                                _vm.updatedContentId ===
                                                list.item.content.content_id
                                                  ? "updatedContent"
                                                  : list.item.content
                                                      .content_id,
                                              "is-disabled": _vm.canNotEdit,
                                              "data-weekId": windex,
                                              "data-weekStart": list.weekStart,
                                              "data-weekEnd": list.weekEnd,
                                              "data-weekNum": list.weeknum,
                                              "data-item":
                                                list && list.item
                                                  ? JSON.stringify(list.item)
                                                  : "",
                                            },
                                          },
                                          [
                                            list.item.type === "journey_item"
                                              ? [
                                                  list.item.content.sub_type ===
                                                  "CHAPTER"
                                                    ? [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "js-media-delete",
                                                            attrs: {
                                                              disabled:
                                                                _vm.canNotEdit,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteJourneyItem(
                                                                  list.item
                                                                    .content,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "icon-container",
                                                              {
                                                                attrs: {
                                                                  width: 16,
                                                                  height: 16,
                                                                  "view-box":
                                                                    "0 0 20 20",
                                                                  name: "trash",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "icon-trash"
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "card-button",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editContentItem(
                                                                  list.item
                                                                    .content
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "card__body",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "card__content",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "chapter-content",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "chapter-content__title",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  list
                                                                                    .item
                                                                                    .content
                                                                                    .name
                                                                                ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  list
                                                                                    .item
                                                                                    .content
                                                                                    .id ===
                                                                                    1
                                                                                    ? "🏁"
                                                                                    : "🚀"
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "chapter-content__description",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  list
                                                                                    .item
                                                                                    .content
                                                                                    .desc
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "js-media-delete",
                                                            attrs: {
                                                              disabled:
                                                                _vm.canNotEdit,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteJourneyItem(
                                                                  list.item
                                                                    .content,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "icon-container",
                                                              {
                                                                attrs: {
                                                                  width: 16,
                                                                  height: 16,
                                                                  "view-box":
                                                                    "0 0 20 20",
                                                                  name: "trash",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "icon-trash"
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "card-button",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editContentItem(
                                                                  list.item
                                                                    .content
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("figure", {
                                                              staticClass:
                                                                "card__media no-pointer",
                                                              class: {
                                                                "card-grey":
                                                                  _vm.journeyHaveUsers &&
                                                                  _vm.itemTimeAlreadyPassed(
                                                                    list.item
                                                                      .content
                                                                      .activation_date
                                                                  ),
                                                              },
                                                              style:
                                                                "background-image: url(" +
                                                                list.item
                                                                  .content
                                                                  .thumbnail_image +
                                                                ");",
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "card__body no-pointer",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "card__content no-pointer",
                                                                  },
                                                                  [
                                                                    !_vm.isHidden(
                                                                      list
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "card__info",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$helpers.getSubTypeName(
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                      .sub_type
                                                                                  )
                                                                                ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  list
                                                                                    .item
                                                                                    .content
                                                                                    .content &&
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                      .content
                                                                                      .quiz &&
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                      .sub_type !=
                                                                                      "QUIZ"
                                                                                    ? _vm.$t(
                                                                                        "has_questions"
                                                                                      )
                                                                                    : ""
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    list.item
                                                                      .content &&
                                                                    list.item
                                                                      .content
                                                                      .name &&
                                                                    !_vm.isHidden(
                                                                      list
                                                                    )
                                                                      ? _c(
                                                                          "h3",
                                                                          {
                                                                            staticClass:
                                                                              "card__title no-pointer",
                                                                          },
                                                                          [
                                                                            _vm.subTypeControl(
                                                                              list
                                                                                .item
                                                                                .content
                                                                            )
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          list
                                                                                            .item
                                                                                            .content
                                                                                            .name
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "card__bottom no-pointer",
                                                                  },
                                                                  [
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "card__bottom__left",
                                                                    }),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "card__bottom__right",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "card__time",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$helpers.getUserLangBasedDateTimeFormat(
                                                                                  list
                                                                                    .item
                                                                                    .content
                                                                                    .activation_date,
                                                                                  _vm.userLang,
                                                                                  "time"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "card__date",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.getDateFromCalc(
                                                                                  list
                                                                                    .item
                                                                                    .content
                                                                                    .activation_date,
                                                                                  windex
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                ]
                                              : _vm._e(),
                                            list.item.type ===
                                            "item_scheduled_notification"
                                              ? [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "js-media-delete",
                                                      attrs: {
                                                        disabled:
                                                          _vm.canNotEdit,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteScheduledNotificationItem(
                                                            list.item.content,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "icon-container",
                                                        {
                                                          attrs: {
                                                            width: 16,
                                                            height: 16,
                                                            "view-box":
                                                              "0 0 20 20",
                                                            name: "trash",
                                                          },
                                                        },
                                                        [_c("icon-trash")],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "card-button",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editScheduledNotificationItem(
                                                            list.item.content
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("figure", {
                                                        staticClass:
                                                          "card__media no-pointer",
                                                        class: {
                                                          "card-grey":
                                                            _vm.journeyHaveUsers &&
                                                            _vm.itemTimeAlreadyPassed(
                                                              list.item.content
                                                                .notify_dt
                                                            ),
                                                        },
                                                        style: {
                                                          "background-image":
                                                            "url(" +
                                                            require("@/assets/img/scheduled-notification-design.png") +
                                                            ")",
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "card__body no-pointer",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "card__content",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "notification-content__info",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "notification-content__info-content",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "notification-content__info-header",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  src: require("@/assets/img/journey-app-icon.png"),
                                                                                  alt: "",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "snotification_preview"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "notification-content__info-title",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              list
                                                                                .item
                                                                                .content
                                                                                .title
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "notification-content__info-body",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              list
                                                                                .item
                                                                                .content
                                                                                .body
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "card__bottom no-pointer",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "card__bottom__left",
                                                                },
                                                                [
                                                                  !_vm.isHidden(
                                                                    list
                                                                  )
                                                                    ? _c(
                                                                        "small",
                                                                        {
                                                                          staticClass:
                                                                            "card__info",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              list.info
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _vm.journeyHaveUsers
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "card__bottom__right",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "small",
                                                                        {
                                                                          staticClass:
                                                                            "card__time",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$helpers.getUserLangBasedDateTimeFormat(
                                                                                list
                                                                                  .item
                                                                                  .content
                                                                                  .notify_dt,
                                                                                _vm.userLang,
                                                                                "time"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "small",
                                                                        {
                                                                          staticClass:
                                                                            "card__date",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$helpers.getUserLangBasedDateTimeFormat(
                                                                                list
                                                                                  .item
                                                                                  .content
                                                                                  .notify_dt,
                                                                                _vm.userLang,
                                                                                "date"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card list--catalog-item drop-placeholder no-pointer",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card__body no-pointer",
                                                  },
                                                  [
                                                    _c(
                                                      "h4",
                                                      {
                                                        staticClass:
                                                          "no-pointer",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("drag_drop")
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          2
                                        )
                                      }),
                                      0
                                    ),
                                  ],
                                  1
                                ),
                                !_vm.canNotEdit
                                  ? _c(
                                      "div",
                                      {
                                        key: "created",
                                        staticClass: "list--create-item",
                                        on: {
                                          dragover: function ($event) {
                                            $event.preventDefault()
                                          },
                                          drop: _vm.cardDropped,
                                          click: _vm.createNewItem,
                                        },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "button button--icon",
                                          },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                staticClass: "no-pointer",
                                                attrs: {
                                                  name: "plusfull",
                                                  "view-box": "0 0 448 512",
                                                  width: 17,
                                                  height: 18,
                                                  color: "#979797",
                                                  "is-icon-class": false,
                                                },
                                              },
                                              [_c("icon-plusfull")],
                                              1
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("drag_drop")) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                        ]
                      : _vm._e(),
                    _vm.currentViewMode === "daily"
                      ? [
                          _c("div", {
                            staticClass: "weekly-drop-zone",
                            class: { "zone-is-active": _vm.isDragging },
                            attrs: { id: "dropzone" },
                            on: {
                              dragover: function ($event) {
                                $event.preventDefault()
                              },
                              drop: _vm.cardDropped,
                            },
                          }),
                          _vm._l(
                            _vm.dailyList,
                            function (adaylist, daylabel, dindex) {
                              return _c(
                                "section",
                                {
                                  key: dindex,
                                  class:
                                    adaylist.length === 0
                                      ? "week-ilist is-ghost"
                                      : "week-ilist",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "title no-pointer" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getDateFromDayLabel(daylabel)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "ilistings" },
                                    [
                                      _c(
                                        "transition-group",
                                        {
                                          class: _vm.isDropFromLibrary
                                            ? "row no-anim flex-column"
                                            : "row flex-column",
                                          attrs: {
                                            appear: "",
                                            type: "transition",
                                            name: "slide-in",
                                            tag: "ul",
                                            id: "itemslist",
                                          },
                                        },
                                        _vm._l(
                                          adaylist,
                                          function (list, index) {
                                            return _c(
                                              "li",
                                              {
                                                key: list.item.content.id,
                                                staticClass:
                                                  "list-group-item ilist list--catalog list--catalog-item button-handle",
                                                style: { "--i": index },
                                                attrs: {
                                                  id:
                                                    _vm.updatedContentId ===
                                                    list.item.content.content_id
                                                      ? "updatedContent"
                                                      : list.item.content
                                                          .content_id,
                                                  "is-disabled": _vm.canNotEdit,
                                                },
                                              },
                                              [
                                                list.item.type ===
                                                "journey_item"
                                                  ? [
                                                      list.item.content
                                                        .sub_type === "CHAPTER"
                                                        ? [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "js-media-delete",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.canNotEdit,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteJourneyItem(
                                                                        list
                                                                          .item
                                                                          .content,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "icon-container",
                                                                  {
                                                                    attrs: {
                                                                      width: 16,
                                                                      height: 16,
                                                                      "view-box":
                                                                        "0 0 20 20",
                                                                      name: "trash",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "icon-trash"
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "card-button",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editContentItem(
                                                                        list
                                                                          .item
                                                                          .content
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "card__body",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "card__content",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "chapter-content",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "chapter-content__title",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      list
                                                                                        .item
                                                                                        .content
                                                                                        .name
                                                                                    ) +
                                                                                    " " +
                                                                                    _vm._s(
                                                                                      list
                                                                                        .item
                                                                                        .content
                                                                                        .id ===
                                                                                        1
                                                                                        ? "🏁"
                                                                                        : "🚀"
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "chapter-content__description",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      list
                                                                                        .item
                                                                                        .content
                                                                                        .desc
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "js-media-delete",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.canNotEdit,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteJourneyItem(
                                                                        list
                                                                          .item
                                                                          .content,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "icon-container",
                                                                  {
                                                                    attrs: {
                                                                      width: 16,
                                                                      height: 16,
                                                                      "view-box":
                                                                        "0 0 20 20",
                                                                      name: "trash",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "icon-trash"
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "card-button",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editContentItem(
                                                                        list
                                                                          .item
                                                                          .content
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("figure", {
                                                                  staticClass:
                                                                    "card__media no-pointer",
                                                                  class: {
                                                                    "card-grey":
                                                                      _vm.journeyHaveUsers &&
                                                                      _vm.itemTimeAlreadyPassed(
                                                                        list
                                                                          .item
                                                                          .content
                                                                          .activation_date
                                                                      ),
                                                                  },
                                                                  style:
                                                                    "background-image: url(" +
                                                                    list.item
                                                                      .content
                                                                      .thumbnail_image +
                                                                    ");",
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "card__body no-pointer",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "card__content no-pointer",
                                                                      },
                                                                      [
                                                                        !_vm.isHidden(
                                                                          list
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "card__info",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$helpers.getSubTypeName(
                                                                                        list
                                                                                          .item
                                                                                          .content
                                                                                          .sub_type
                                                                                      )
                                                                                    ) +
                                                                                    " " +
                                                                                    _vm._s(
                                                                                      list
                                                                                        .item
                                                                                        .content
                                                                                        .content &&
                                                                                        list
                                                                                          .item
                                                                                          .content
                                                                                          .content
                                                                                          .quiz &&
                                                                                        list
                                                                                          .item
                                                                                          .content
                                                                                          .sub_type !=
                                                                                          "QUIZ"
                                                                                        ? _vm.$t(
                                                                                            "has_questions"
                                                                                          )
                                                                                        : ""
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        list
                                                                          .item
                                                                          .content &&
                                                                        list
                                                                          .item
                                                                          .content
                                                                          .name &&
                                                                        !_vm.isHidden(
                                                                          list
                                                                        )
                                                                          ? _c(
                                                                              "h3",
                                                                              {
                                                                                staticClass:
                                                                                  "card__title no-pointer",
                                                                              },
                                                                              [
                                                                                _vm.subTypeControl(
                                                                                  list
                                                                                    .item
                                                                                    .content
                                                                                )
                                                                                  ? _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              list
                                                                                                .item
                                                                                                .content
                                                                                                .name
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "card__bottom no-pointer",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "card__bottom__left",
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "card__bottom__right",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "small",
                                                                              {
                                                                                staticClass:
                                                                                  "card__time",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$helpers.getUserLangBasedDateTimeFormat(
                                                                                      list
                                                                                        .item
                                                                                        .content
                                                                                        .activation_date,
                                                                                      _vm.userLang,
                                                                                      "time"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                    ]
                                                  : _vm._e(),
                                                list.item.type ===
                                                "item_scheduled_notification"
                                                  ? [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "js-media-delete",
                                                          attrs: {
                                                            disabled:
                                                              _vm.canNotEdit,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteScheduledNotificationItem(
                                                                list.item
                                                                  .content,
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "icon-container",
                                                            {
                                                              attrs: {
                                                                width: 16,
                                                                height: 16,
                                                                "view-box":
                                                                  "0 0 20 20",
                                                                name: "trash",
                                                              },
                                                            },
                                                            [_c("icon-trash")],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "card-button",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editScheduledNotificationItem(
                                                                list.item
                                                                  .content
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("figure", {
                                                            staticClass:
                                                              "card__media no-pointer",
                                                            class: {
                                                              "card-grey":
                                                                _vm.journeyHaveUsers &&
                                                                _vm.itemTimeAlreadyPassed(
                                                                  list.item
                                                                    .content
                                                                    .notify_dt
                                                                ),
                                                            },
                                                            style: {
                                                              "background-image":
                                                                "url(" +
                                                                require("@/assets/img/scheduled-notification-design.png") +
                                                                ")",
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "card__body no-pointer",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "card__content",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "notification-content__info",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "notification-content__info-content",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "notification-content__info-header",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "img",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      src: require("@/assets/img/journey-app-icon.png"),
                                                                                      alt: "",
                                                                                    },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "snotification_preview"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "notification-content__info-title",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  list
                                                                                    .item
                                                                                    .content
                                                                                    .title
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "notification-content__info-body",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  list
                                                                                    .item
                                                                                    .content
                                                                                    .body
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "card__bottom no-pointer",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "card__bottom__left",
                                                                    },
                                                                    [
                                                                      !_vm.isHidden(
                                                                        list
                                                                      )
                                                                        ? _c(
                                                                            "small",
                                                                            {
                                                                              staticClass:
                                                                                "card__info",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  list.info
                                                                                )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                  _vm.journeyHaveUsers
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "card__bottom__right",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "small",
                                                                            {
                                                                              staticClass:
                                                                                "card__time",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$helpers.getUserLangBasedDateTimeFormat(
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                      .notify_dt,
                                                                                    _vm.userLang,
                                                                                    "time"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "small",
                                                                            {
                                                                              staticClass:
                                                                                "card__date",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$helpers.getUserLangBasedDateTimeFormat(
                                                                                    list
                                                                                      .item
                                                                                      .content
                                                                                      .notify_dt,
                                                                                    _vm.userLang,
                                                                                    "date"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card list--catalog-item drop-placeholder no-pointer",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card__body no-pointer",
                                                      },
                                                      [
                                                        _c(
                                                          "h4",
                                                          {
                                                            staticClass:
                                                              "no-pointer",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "drag_drop"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ],
                                    1
                                  ),
                                  !_vm.canNotEdit
                                    ? _c(
                                        "div",
                                        {
                                          key: "created",
                                          staticClass: "list--create-item",
                                          on: {
                                            dragover: function ($event) {
                                              $event.preventDefault()
                                            },
                                            drop: _vm.cardDropped,
                                            click: _vm.createNewItem,
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button button--icon",
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  staticClass: "no-pointer",
                                                  attrs: {
                                                    name: "plusfull",
                                                    "view-box": "0 0 448 512",
                                                    width: 17,
                                                    height: 18,
                                                    color: "#979797",
                                                    "is-icon-class": false,
                                                  },
                                                },
                                                [_c("icon-plusfull")],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("drag_drop")) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                        ]
                      : _vm._e(),
                    (_vm.currentViewMode === "weekly" ||
                      _vm.currentViewMode === "daily") &&
                    _vm.journeyItems.length === 0
                      ? [
                          _c("div", {
                            staticClass: "weekly-drop-zone",
                            class: { "zone-is-active": _vm.isDragging },
                            attrs: { id: "dropzone" },
                            on: {
                              dragover: function ($event) {
                                $event.preventDefault()
                              },
                              drop: _vm.cardDropped,
                            },
                          }),
                          _c("section", { staticClass: "week-ilist" }, [
                            _c("div", { staticClass: "ilistings" }, [
                              _c(
                                "ul",
                                {
                                  staticClass: "row flex-column",
                                  attrs: { id: "itemslist" },
                                },
                                [
                                  !_vm.canNotEdit
                                    ? _c(
                                        "li",
                                        {
                                          key: "created",
                                          staticClass:
                                            "list--create-item mx-auto m-3",
                                          on: { click: _vm.createNewItem },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button button--icon",
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  staticClass: "no-pointer",
                                                  attrs: {
                                                    name: "plusfull",
                                                    "view-box": "0 0 448 512",
                                                    width: 17,
                                                    height: 18,
                                                    color: "#979797",
                                                    "is-icon-class": false,
                                                  },
                                                },
                                                [_c("icon-plusfull")],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("drag_drop")) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" }, on: { enter: _vm.enterAlert } },
        [
          _vm.addedContentStatus
            ? _c(
                "div",
                { staticClass: "alert alert--success" },
                [
                  _c(
                    "icon-container",
                    {
                      staticClass: "icon icon-check size-xl no-pointer",
                      attrs: {
                        name: "check",
                        "view-box": "0 0 512 512",
                        color: "#FFF",
                      },
                    },
                    [_c("icon-check")],
                    1
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("saved")) + " "),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }