<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{'is-loading': formSending }"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ $t('setup_ed_demo_title') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="tab-content">
              <div
                class="tab-pane"
                v-if="!codePresent"
              >
                <div class="action-header">
                  <div class="form-group">
                    <label
                      for="demoType"
                      class="form-label"
                    >{{ $t('demo_type') }}</label>

                    <div class="d-flex align-items-center">
                      <label
                        class="check-button"
                        :class="{'active' : demoType === 'development'}"
                      >
                        <input
                          type="radio"
                          v-model="demoType"
                          value="development"
                        >
                        {{ $t('development') }}
                      </label>
                      <label
                        class="check-button"
                        :class="{'active' : demoType === 'onboarding'}"
                      >
                        <input
                          type="radio"
                          v-model="demoType"
                          value="onboarding"
                        >
                        {{ $t('onboarding') }}
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <label
                      for="demoLanguage"
                      class="form-label"
                    >{{ $t('demo_language') }}</label>
                    <div class="d-flex align-items-center">
                      <label
                        class="check-button"
                        :class="{'active' : demoLanguage === 'en'}"
                      >
                        <input
                          type="radio"
                          v-model="demoLanguage"
                          value="en"
                        >
                        {{ $t('lang_en') }}
                      </label>
                      <label
                        class="check-button"
                        :class="{'active' : demoLanguage === 'tr'}"
                      >
                        <input
                          type="radio"
                          v-model="demoLanguage"
                          value="tr"
                        >
                        {{ $t('lang_tr') }}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    for="managerName"
                    class="form-label"
                  >{{ $t('manager_name_') }}</label>
                  <input
                    :class="{ 'is-danger': errors.has('setup.managername') }"
                    type="text"
                    class="form-control"
                    id="managerName"
                    v-model="managerName"
                    data-vv-scope="setup"
                    data-vv-name="managername"
                    :data-vv-as="$t('manager_name')"
                    v-validate="'required|min:2|max:300'"
                  >
                  <span
                    v-show="errors.has('setup.managername')"
                    class="help is-danger"
                  >{{ errors.first('setup.managername') }}</span>
                </div>
                <div class="form-group">
                  <label
                    for="employeeName"
                    class="form-label"
                  >{{ $t('employee_name_') }}</label>
                  <input
                    :class="{ 'is-danger': errors.has('setup.employeename') }"
                    type="text"
                    class="form-control"
                    id="employeeName"
                    v-model="employeeName"
                    data-vv-scope="setup"
                    data-vv-name="employeename"
                    :data-vv-as="$t('employee_name')"
                    v-validate="'required|min:2|max:300'"
                  >
                  <span
                    v-show="errors.has('setup.employeename')"
                    class="help is-danger"
                  >{{ errors.first('setup.employeename') }}</span>
                </div>
                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t('error_msg_title') }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary no-wrap"
                    @click="setupEdDemo()"
                    :disabled="isPending"
                  >
                    <span
                      v-if="!isPending"
                    >
                      {{ $t('create') }}
                    </span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>
              <div
                class="tab-pane"
                v-if="codePresent"
              >
                <div class="container">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group codex">
                        <label
                          for="managerLoginCode"
                          class="form-label"
                        >{{ $t('manager_login_code') }}
                        </label>
                        <div class="pin-numbers">
                          <span class="pinbox">{{ managerLoginCode[0] }}</span>
                          <span class="pinbox">{{ managerLoginCode[1] }}</span>
                          <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                          <span class="dash">{{ "-" }}</span>
                          <span class="pinbox">{{ managerLoginCode[2] }}</span>
                          <span class="pinbox">{{ managerLoginCode[3] }}</span>
                          <span
                            class="pinbox"
                            v-if="managerLoginCode[4]"
                          >{{ managerLoginCode[4] }}</span>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="button button--primary no-wrap"
                          @click="openWebApp()"
                        >
                          <span>
                            {{ $t('open_web_app') }}
                          </span>
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group codex">
                        <label
                          for="employeeLoginCode"
                          class="form-label"
                        >{{ $t('employee_login_code') }}
                        </label>
                        <div class="pin-numbers">
                          <span class="pinbox">{{ employeeLoginCode[0] }}</span>
                          <span class="pinbox">{{ employeeLoginCode[1] }}</span>
                          <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                          <span class="dash">{{ "-" }}</span>
                          <span class="pinbox">{{ employeeLoginCode[2] }}</span>
                          <span class="pinbox">{{ employeeLoginCode[3] }}</span>
                          <span
                            class="pinbox"
                            v-if="employeeLoginCode[4]"
                          >{{ employeeLoginCode[4] }}</span>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="button button--primary no-wrap"
                          @click="openReporting()"
                        >
                          <span>
                            {{ $t('open_reporting') }}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/services/';
import {
  API_ED_DEMO_SETUP_DEV,
  API_ED_DEMO_SETUP_ONBOARD,
} from '@/helpers/config';

export default {
  name: 'ModalSetupEdDemo',
  inject: ['$validator'],

  data() {
    return {
      isPending: false,
      formSending: false,
      managerName: null,
      demoType: 'development',
      demoLanguage: 'en',
      employeeName: 'Jane Schmidt',
      codePresent: false,
      managerLoginCode: '    ',
      employeeLoginCode: '    ',
      gotoUrl: null,
      reportingUrl: 'https://www.figma.com/proto/vF8Z3lrdHIhVfrRS8lgDGr/Reporting---Journey-Admin?page-id=0%3A1&node-id=16%3A2&scaling=scale-down-width',
    };
  },

  watch: {
    demoLanguage: {
      handler(val) {
        if (val === 'en') {
          this.employeeName = 'Jane Schmidt';
        } else {
          this.employeeName = 'Hande Günay';
        }
      },
      immediate: true,
    },
  },

  computed: {
    customerId() {
      return this.$store.state.auth.customerId;
    },
    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },
  },

  methods: {

    closeModal() {
      this.$store.commit('modal/toggleModal');
    },

    openWebApp() {
      if (this.gotoUrl) {
        console.log('goto url:', this.gotoUrl);
        const windowReference = window.open();
        windowReference.location = this.gotoUrl;
      }
    },

    openReporting() {
      const windowReference = window.open();
      windowReference.location = this.reportingUrl;
    },

    setupEdDemo() {
      if (this.isVibonsAdmin) {
        this.$validator.validateAll('setup').then((result) => {
          if (result) {
            this.formSending = true;
            this.isPending = true;
            const self = this;
            const formData = {};
            formData.manager_name = this.managerName;
            formData.employee_name = this.employeeName;
            formData.customer_id = this.customerId;
            formData.lang = this.demoLanguage;

            let callAPiUrl = '';
            if (this.demoType === 'development') {
              callAPiUrl = API_ED_DEMO_SETUP_DEV;
            } else {
              callAPiUrl = API_ED_DEMO_SETUP_ONBOARD;
            }

            API.post(callAPiUrl, formData)
              .then((response) => {
                if (response.status === 200) {
                  console.log('response:', response);
                  self.formSending = false;
                  self.isPending = false;
                  self.codePresent = true;
                  this.employeeLoginCode = response.data.EmployeePin;
                  this.managerLoginCode = response.data.ManagerPin;
                  this.gotoUrl = response.data.Url;
                }
              })
              .catch((error) => {
                self.formSending = false;
                self.isPending = false;
                self.$helpers.displayError(error);
              });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check {
  .form-label {
    font-size: 0.9rem;
    margin-left: 1rem;
    margin-bottom: 0;
    color: #142b58;
  }
}
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}
.modal-body {
  h4 {
    color: #727d92;
  }
}
.blur {
  filter: blur(3px);
}
.no-wrap {
  white-space: nowrap;
}
.form-label {
  text-transform: uppercase;
}
.dash {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  color: #000000;
  opacity: 0.3;
}
.pin-numbers {
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  color: #000000;
  margin: 35px auto;
  align-items: center;
  .pinbox {
    background: #ffffff;
    border: 0.7px solid #d3d4d5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    margin: 5px;
    padding: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    color: #000000;
  }
  }
  .codex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .action-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    [type="radio"] {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .check-button {
      height:50px;
      padding:10px;
      border: 1px solid #254ffb;
      border-radius:5px;
      display:flex;
      justify-content: center;
      align-items: center;
      font-size: 12px !important;
      line-height: 16px !important;
      font-weight: 500;
      color: #727d92;
      min-width: 100px;
      &.active {
        background-color: #f27299;
        color:#ffffff;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
