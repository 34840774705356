import * as DOMPurify from 'dompurify';

const getWindow = () => (typeof window === 'undefined' ? null : window);
const isFile = obj => (obj).size !== undefined;

const readAsText = svg => new Promise((resolve) => {
  if (!isFile(svg)) {
    resolve(svg.toString('utf-8'));
  } else {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsText(svg);
  }
});

const sanitizeSVG = async (svg, window = getWindow()) => {
  if (!window) throw new Error('DOM window required');
  // if (isFile(svg) && svg.type !== 'image/svg+xml') return svg;
  const svgText = await readAsText(svg);
  if (!svgText) throw new Error('Image corrupt');
  // console.log('svgText', svgText);
  if (svgText.includes('<svg')) {
    const clean = DOMPurify.sanitize(svgText, { USE_PROFILES: { svg: true, svgFilters: true } });
    const blob = new Blob([clean], { type: 'image/svg+xml' });
    const file = new File([blob], svg.name);
    // console.log('purified svg: ', clean);
    return file;
  }
  return svg;
};

export default sanitizeSVG;
