<template>
  <div class="page">
    <transition
      name="fade"
      mode="out-in"
    >
      <modal-addjourney
        v-if="modal.modalVisible && modal.modalType === 'modalAddJourney'"
      />
      <modal-logincode
        v-if="modal.modalVisible && modal.modalType === 'modalLoginCode'"
      />
      <modal-addgroup
        v-if="modal.modalVisible && modal.modalType === 'modalAddGroup'"
      />
      <modal-setpassword
        v-if="modal.modalVisible && modal.modalType === 'modalSetPassword'"
      />
    </transition>
    <app-header
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
      add-user
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
      <dropdown
        class-name="filter"
        v-if="isVibonsAdmin"
        :filter-on="the_filter.only_active_customer_users"
      >
        <template slot="icon">
          <i
            class="fa fa-filter button button--shadow button--type-icon size-sm d-flex align-items-center"
            style="cursor: pointer;color: rgb(114, 125, 146);"
          />
        </template>
        <template slot="body">
          <div class="d-block p-2">
            <div
              class="d-block"
            >
              <label
                for="filter-only_active_customer_users"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  id="filter-only_active_customer_users"
                  :true-value="true"
                  :false-value="false"
                  v-model="the_filter.only_active_customer_users"
                >
                {{ $t('only_active_customer_users') }}
              </label>
            </div>
          </div>
        </template>
      </dropdown>
    </app-header>

    <div class="container">
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="get"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="icon"
            slot-scope="props"
          >
            <button
              class="ui button"
              @click="getMobilStatus(props.rowData.id, `ref${props.rowData.id}`)"
              @blur="closeTooltip(`ref${props.rowData.id}`)"
            >
              <icon-container
                :width="13"
                :height="20"
                name="cellphone"
                view-box="0 0 320 512"
                :class="{'disabled': !props.rowData.has_mobile_device_registered}"
                :color="props.rowData.is_notifications_enabled ? 'rgb(8, 206, 25)':'#727d92'"
              >
                <icon-cellphone />
              </icon-container>
            </button>
            <div
              :ref="`ref${props.rowData.id}`"
              class="content-tooltip"
            >
              <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
              <pre>{{ tooltipText ? tooltipText : '⏳' }}</pre>
            </div>
          </template>
          <template
            slot="first_name"
            slot-scope="props"
          >
            <span>
              {{ props.rowData.first_name }}
            </span>
          </template>
          <template
            slot="is_active"
            slot-scope="props"
          >
            <span :class="props.rowData.is_active ?'is-active': 'is-passive'">
              {{ props.rowData.is_active ? $t('active') : $t('passive') }}
            </span>
          </template>
          <template
            slot="created_at"
            slot-scope="props"
          >
            <div>
              <span>{{ formatDate(props.rowData.created_at) }}</span>
            </div>
          </template>
          <template
            slot="customer_name"
            slot-scope="props"
          >
            <span v-if="isVibonsAdmin">
              {{ props.rowData.customer_name }}
            </span>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <button
              class="ui button"
              @click="onAction('edit-item', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                view-box="0 0 24 24"
                name="edit"
                color="#727d92"
              >
                <icon-edit />
              </icon-container>
            </button>
            <div
              class="dropdown mr-2"
              @mouseover="dropdownAction(`plus${props.rowIndex}`)"
              @mouseleave="hideDropdownAction"
            >
              <span class="card--actions dropdown__toggle">
                <icon-container
                  width="18"
                  height="18"
                  view-box="0 0 24 24"
                  name="cplus"
                  color="#727d92"
                >
                  <icon-circleplus />
                </icon-container></span>

              <div
                class="dropdown__menu"
                :ref="`plus${props.rowIndex}`"
                v-if="dropdownIndex === 'plus' + props.rowIndex"
                v-on-clickaway="hideDropdownAction"
              >
                <!-- <span
                  class="dropdown__button button"
                  @click="onAction('start-journey', props.rowData)"
                >
                  <icon-container
                    :width="14"
                    :height="15"
                    view-box="0 0 448 512"
                    name="play"
                    color="#727d92"
                  >
                    <icon-play />
                  </icon-container>
                  {{ $t('start_journey') }}
                </span> -->
                <span
                  class="dropdown__button button"
                  @click="onAction('add-journey', props.rowData)"
                >
                  <icon-container
                    :width="18"
                    :height="18"
                    name="journey"
                    color="#727d92"
                  >
                    <icon-journey />
                  </icon-container>
                  {{ $t('add_to_existent_journey') }}
                </span>

                <span
                  class="dropdown__button button"
                  @click="onAction('add-group', props.rowData)"
                >
                  <icon-container
                    :width="18"
                    :height="18"
                    name="user"
                    color="#727d92"
                  >
                    <icon-user />
                  </icon-container>
                  {{ $t('add_to_group') }}
                </span>
              </div>
            </div>
            <div
              class="dropdown"
              @mouseover="dropdownAction(`three${props.rowIndex}`)"
              @mouseleave="hideDropdownAction"
            >
              <span class="card--actions dropdown__toggle">
                <icon-container
                  :width="20"
                  :height="20"
                  view-box="0 0 24 24"
                  name="threedots"
                  color="#727d92"
                >
                  <icon-threedots />
                </icon-container>
              </span>

              <div
                class="dropdown__menu"
                :ref="`three${props.rowIndex}`"
                v-if="dropdownIndex === 'three' + props.rowIndex"
                v-on-clickaway="hideDropdownAction"
              >
                <span
                  class="dropdown__button button"
                  @click="onAction('notification', props.rowData)"
                >
                  <icon-container
                    :width="18"
                    :height="18"
                    view-box="0 0 24 24"
                    name="edit"
                    color="#727d92"
                  >
                    <icon-bell />
                  </icon-container>
                  {{ $t('notification_button') }}
                </span>
                <span
                  v-if="canSeeSetPassword"
                  class="dropdown__button button"
                  @click="onAction('set-new-password', props.rowData)"
                >
                  <icon-container
                    :width="17"
                    :height="17"
                    view-box="0 0 512 512"
                    name="email"
                    color="#727d92"
                  >
                    <icon-email />
                  </icon-container>
                  {{ $t('set_new_password') }}
                </span>
                <span
                  v-if="isVibonsAdmin"
                  class="dropdown__button button"
                  @click="onAction('login-code', props.rowData)"
                >
                  <icon-container
                    name="password"
                    :width="22"
                    :height="22"
                    view-box="0 0 32 32"
                    color="#727d92"
                  >
                    <icon-password />
                  </icon-container>
                  {{ $t('set_login_code') }}
                </span>
                <span
                  class="dropdown__button button"
                  @click="onAction('usage-detail', props.rowData)"
                >
                  <icon-container
                    name="infocircle"
                    view-box="0 0 512 512"
                    color="#727d92"
                    :is-icon-class="false"
                  >
                    <icon-infocircle />
                  </icon-container>
                  {{ $t('usage_detail') }}
                </span>
                <span
                  class="dropdown__button button"
                  @click="onAction('link-zoom-account', props.rowData)"
                >
                  <icon-container
                    :width="22"
                    :height="22"
                    view-box="0 0 50 50"
                    name="zoom"
                    color="#727d92"
                  >
                    <icon-zoom />
                  </icon-container>
                  {{ $t('link_zoom_account') }}
                </span>
                <span
                  class="dropdown__button button"
                  @click="onAction('delete-item', props.rowData)"
                >
                  <icon-container
                    :width="18"
                    :height="18"
                    view-box="0 0 24 24"
                    name="trash"
                    color="#727d92"
                  >
                    <icon-trash />
                  </icon-container>
                  {{ $t('delete') }}
                </span>
              </div>
            </div>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import { mapState, mapGetters } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import IconEdit from '@/components/icons/Edit.vue';
import IconBell from '@/components/icons/Bell.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconUser from '@/components/icons/User.vue';
import IconEmail from '@/components/icons/Email.vue';
import IconJourney from '@/components/icons/Journey.vue';
// import IconPlay from '@/components/icons/Play.vue';
import IconZoom from '@/components/icons/Zoom.vue';
import IconInfoCircle from '@/components/icons/InfoCircle.vue';
import IconThreedots from '@/components/icons/Threedots.vue';
import IconCirclePlus from '@/components/icons/CirclePlus.vue';
import IconCellPhone from '@/components/icons/CellPhone.vue';
import ModalAddJourney from '@/container/modal/ModalAddJourney.vue';
import ModalAddGroup from '@/container/modal/ModalAddGroup.vue';
import ModalSetPassword from '@/container/modal/user/SetNewPassword.vue';
import ModalLoginCode from '@/container/modal/ModalLoginCode.vue';
import IconPassword from '@/components/icons/Password.vue';
import Dropdown from '@/components/elements/Dropdown.vue';

import AppHeader from '@/components/AppHeader.vue';
import {
  API_USER_DATATABLES, API_USER, API_USER_ZOOM_LOGIN, API_SERVER_TIME_FORMAT,
} from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';

import VuetablePagination from '../components/CustomVuetable/VuetablePagination.vue';

const moment = require('moment');


export default {
  name: 'Users',
  mixins: [myTableMixin],
  data() {
    return {
      moreParams: {
      },
      the_filter: {
        only_active_customer_users: false,
      },
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-cellphone': IconCellPhone,
    'icon-edit': IconEdit,
    'icon-bell': IconBell,
    'icon-trash': IconTrash,
    'icon-user': IconUser,
    'icon-zoom': IconZoom,
    'icon-email': IconEmail,
    'icon-infocircle': IconInfoCircle,
    'icon-journey': IconJourney,
    // 'icon-play': IconPlay,
    'icon-threedots': IconThreedots,
    'icon-circleplus': IconCirclePlus,
    'modal-addjourney': ModalAddJourney,
    'modal-logincode': ModalLoginCode,
    'modal-addgroup': ModalAddGroup,
    'modal-setpassword': ModalSetPassword,
    'icon-password': IconPassword,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    Dropdown,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'Users') {
        setTimeout(() => {
          this.$refs.myvuetable.reload();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
    the_filter: {
      handler(filter) {
        if (filter.only_active_customer_users) {
          this.$set(this.moreParams, 'customer_id', this.$store.state.auth.customerId);
        } else {
          this.$delete(this.moreParams, 'customer_id');
        }
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),
    ...mapGetters({
      userLang: 'auth/userLang',
    }),
    apiUrl() {
      if (API_USER_DATATABLES) {
        return `${API_USER_DATATABLES}`;
      }
      return null;
    },
    canSeeSetPassword() {
      const forbiddenCustomerIds = [57, 390, 411]; // TEB: 57, TEB ONBOARDING: 390, TEB PENTEST: 411
      if (!this.isVibonsAdmin && this.$store.state.auth.customerId && forbiddenCustomerIds.includes(this.$store.state.auth.customerId)) {
        return false;
      }
      return true;
    },
    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },

    userSubNavigation() {
      return [
        {
          text: this.$t('users'),
          url: '/users',
        },
        {
          text: this.$t('groups'),
          url: '/groups',
        },
        {
          text: this.$t('managers'),
          url: '/managers',
        },
        {
          text: this.$t('buddies'),
          url: '/buddies',
        },
      ];
    },

    fields() {
      const isAdmin = this.isVibonsAdmin;
      const fields = [
        {
          name: 'icon',
          title: '',
          width: '5%',
        },
        {
          name: 'first_name',
          title: this.$t('first_name'),
          sortField: 'first_name',
          width: '10%',
        },
        {
          name: 'last_name',
          title: this.$t('last_name'),
          sortField: 'last_name',
          width: '10%',
        },
        {
          name: 'email',
          title: this.$t('email'),
          sortField: 'email',
          width: '30%',
        },
        {
          name: 'is_active',
          title: this.$t('status'),
          width: '10%',
        },
        {
          name: 'created_at',
          title: this.$t('created_at'),
          width: '15%',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '15%',
          titleClass: 'center',
          dataClass: 'center',
        },
      ];

      if (isAdmin) {
        fields.splice(6, 0, {
          name: 'customer_name',
          title: this.$t('customer_'),
          width: '10%',
          slot: 'customer_name',
        });
      }

      return fields;
    },
  },
  mounted() {
    // eslint-disable-next-line camelcase
    const { zoom_connect, for_user, name } = this.$route.query;
    // eslint-disable-next-line camelcase
    if (zoom_connect && for_user && name) {
      this.$snotify.success(this.$t('zoom_login_success', { name }));
      setTimeout(() => {
        this.$router.replace({ query: null });
      }, 1000);
    }
  },
  methods: {
    formatDate(value) {
      let fmt = null;
      let date = null;
      if (value) {
        if (this.userLang === 'en') {
          fmt = 'MM.DD.YYYY hh:mm A';
        }
        if (this.userLang === 'tr') {
          fmt = 'DD.MM.YYYY HH:mm';
        }
        date = moment(value, API_SERVER_TIME_FORMAT).locale(this.userLang).format(fmt);
      }
      return date;
    },
    onAction(action, data) {
      const userID = data.id;
      const userEmail = data.email;
      this.idHolder = userID;
      this.apiUrlHolder = API_USER;

      if (action === 'delete-item') {
        if (!this.snotifyQueueList.includes(userID)) {
          this.snotifyQueueList.push(userID);
          this.$snotify.confirm(this.$t('user_delete_msg_body', { id: userID }), this.$t('user_delete_msg_title'), {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            buttons: [
              {
                text: this.$t('yes'),
                action: (toast) => {
                  this.deleteConfirm(toast, this.idHolder, this.apiUrlHolder);
                },
                bold: false,
              },
              {
                text: this.$t('no'),
                action: (toast) => {
                  this.deleteCancel(toast, this.idHolder);
                },
              },
            ],
          }).on('shown', (toast) => {
            this.toastHolder = toast;
            document.addEventListener('keydown', this.eventHandler, false);
          }).on('hidden', (toast) => {
            this.toastHolder = toast;
            document.removeEventListener('keydown', this.eventHandler, false);
          });
        }
      } else if (action === 'edit-item') {
        if (data.id) {
          const obj = {
            modalType: 'modalAddUser',
            userId: data.id,
            tabPane: 'mainTab',
            editMode: true,
          };
          this.$helpers.toggleModal(obj);
        }
      } else if (action === 'notification') {
        const obj = {
          modalType: 'modalNotification',
          userId: userID,
          notificationType: 'user',
        };
        this.$helpers.toggleModal(obj);
      } else if (action === 'set-new-password') {
        if (data.email) {
          const obj = {
            modalType: 'modalSetPassword',
            userEmail: data.email,
          };
          this.$helpers.toggleModal(obj);
        }
      } else if (action === 'add-journey') {
        const obj = {
          modalType: 'modalAddJourney',
          pageToRefresh: 'Users',
          userId: userID,
          customerIdToEdit: data.customer_id,
        };
        this.$helpers.toggleModal(obj);
      } else if (action === 'login-code') {
        const obj = {
          modalType: 'modalLoginCode',
          pageToRefresh: 'Users',
          userEmail,
        };
        this.$helpers.toggleModal(obj);
      } else if (action === 'add-group') {
        const obj = {
          modalType: 'modalAddGroup',
          pageToRefresh: 'Users',
          userId: userID,
          customerIdToEdit: data.customer_id,
        };
        this.$helpers.toggleModal(obj);
      } else if (action === 'start-journey') {
        const obj = {
          modalType: 'modalSelectJourney',
          selectedToAssign: { // eslint-disable-next-line
            text: `${data.first_name} ${data.last_name} [${data.email}]`,
            id: userID,
          },
          customerIdToEdit: data.customer_id,
          launchedFrom: 'modalSelectJourney',
        };
        this.$helpers.toggleModal(obj);
      } else if (action === 'link-zoom-account') {
        const fileURL = `${API_USER_ZOOM_LOGIN}/${userID}?token=${this.$store.state.auth.token}`;
        window.open(fileURL);
      } else if (action === 'usage-detail') {
        if (userID) {
          this.$store.commit('modal/SET_MODAL_DATA', data);
          this.$router.push({ path: `/dashboard/users/detail/${userID}` });
        }
      }
    },
  },
};
</script>
<style lang="scss">
.tooltip-content {
  white-space: pre-line!important;
  height: 100%;
}
.content-tooltip {
    display: none;
    word-break: break-all;
    width: 350px;
    height: 150px;
    overflow-y: auto;
    z-index: 19;
    position: absolute;
    background: #000000d0;
    padding: 10px;
    color: #fff;
    border-radius: 4px;
}
span.is-active {
  color: green;
  font-weight: 600;
}
span.is-passive {
  color: red;
  font-weight: 600;
}
span.custom-dropdown__btn {
  border: none;
  padding: 0;
  cursor: pointer;
  }
</style>
