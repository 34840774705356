var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-pane show active" }, [
    _c(
      "div",
      {
        staticClass: "form-group",
        class: { "is-danger": _vm.errors.has("article.title") },
      },
      [
        _c(
          "label",
          { staticClass: "form-label", attrs: { for: "contentTitle" } },
          [_vm._v(_vm._s(_vm.$t("title").toUpperCase()))]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title",
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|max:100",
              expression: "'required|max:100'",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            id: "contentTitle",
            disabled: _vm.disabled && !_vm.canEditOnlyItem,
            "data-vv-scope": "article",
            "data-vv-name": "title",
            "data-vv-as": _vm.$t("title"),
          },
          domProps: { value: _vm.title },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.title = $event.target.value
              },
              _vm.handleTitle,
            ],
          },
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("article.title"),
                expression: "errors.has('article.title')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v(_vm._s(_vm.errors.first("article.title")))]
        ),
      ]
    ),
    _c("div", { staticClass: "form-group form-group--cards" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "uploadHtml" } },
            [_vm._v(_vm._s(_vm.$t("upload_html_file")))]
          ),
          _c(
            "div",
            {
              staticClass: "card card--upload",
              class: { "is-danger": _vm.errors.has("article.article") },
            },
            [
              _vm.articlePreview
                ? _c("div", { staticClass: "html-container" }, [
                    _c("div", {
                      staticClass: "article-preview",
                      attrs: { id: "article" },
                      domProps: { innerHTML: _vm._s(_vm.articlePreview) },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "js-media-delete",
                        on: { click: _vm.articleRemoved },
                      },
                      [
                        _c(
                          "icon-container",
                          { attrs: { width: 20, height: 20, name: "trash" } },
                          [_c("icon-trash")],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              !_vm.articlePreview
                ? _c("div", { staticClass: "video-upload-file" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|size:1000|mimes:text/html",
                          expression: "'required|size:1000|mimes:text/html'",
                        },
                      ],
                      attrs: {
                        type: "file",
                        disabled: _vm.disabled,
                        "data-vv-scope": "article",
                        name: "article",
                        "data-vv-name": "article",
                        "data-vv-as": _vm.$t("html_file"),
                        accept: "text/html,.html,.htm",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("input", $event.target.value)
                        },
                        change: _vm.articleUploaded,
                      },
                    }),
                    _c(
                      "span",
                      { staticClass: "media-object media-object--row" },
                      [
                        _c(
                          "span",
                          { staticClass: "media-object__media" },
                          [
                            _c(
                              "icon-container",
                              {
                                attrs: {
                                  name: "circleplusfull",
                                  "view-box": "0 0 512 512",
                                  width: 45,
                                  height: 45,
                                  "is-icon-class": false,
                                },
                              },
                              [_c("icon-circleplusfull")],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.errors.has("article.article")
            ? _c("span", { staticClass: "help is-danger" }, [
                _vm._v(_vm._s(_vm.errors.first("article.article"))),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.errors.items.length > 0,
            expression: "errors.items.length > 0",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("div", { staticClass: "alert alert--card alert--error" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
          _c(
            "ul",
            _vm._l(_vm.errors, function (error, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(error.msg) + " "),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "button button--primary",
          attrs: {
            type: "button",
            disabled: _vm.isPending || (_vm.disabled && !_vm.canEditOnlyItem),
          },
          on: {
            click: function ($event) {
              _vm.editMode ? _vm.updateContent() : _vm.createContent()
            },
          },
        },
        [
          !_vm.isPending
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.$t("update_button")
                        : _vm.$t("create_button")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.isPending
            ? _c("div", { staticClass: "lds-ellipsis" }, [
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }