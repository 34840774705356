var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    [
      _vm._l(_vm.vuetable.tableFields, function (field, fieldIndex) {
        return [
          field.visible
            ? [
                _vm.vuetable.isFieldComponent(field.name)
                  ? [
                      _c(field.name, {
                        key: fieldIndex,
                        tag: "component",
                        class: _vm.headerClass("vuetable-th-component", field),
                        style: { width: field.width },
                        attrs: {
                          "row-field": field,
                          "is-header": true,
                          title: _vm.renderTitle(field),
                          vuetable: _vm.vuetable,
                        },
                        on: {
                          "vuetable:header-event": _vm.vuetable.onHeaderEvent,
                          click: function ($event) {
                            return _vm.onColumnHeaderClicked(field, $event)
                          },
                        },
                      }),
                    ]
                  : _vm.vuetable.isFieldSlot(field.name)
                  ? [
                      _c("th", {
                        key: fieldIndex,
                        class: _vm.headerClass("vuetable-th-slot", field),
                        style: { width: field.width },
                        attrs: { scope: "col" },
                        domProps: { innerHTML: _vm._s(_vm.renderTitle(field)) },
                        on: {
                          click: function ($event) {
                            return _vm.onColumnHeaderClicked(field, $event)
                          },
                        },
                      }),
                    ]
                  : [
                      _c("th", {
                        key: fieldIndex,
                        class: _vm.headerClass("vuetable-th", field),
                        style: { width: field.width },
                        attrs: { id: "_" + field.name, scope: "col" },
                        domProps: { innerHTML: _vm._s(_vm.renderTitle(field)) },
                        on: {
                          click: function ($event) {
                            return _vm.onColumnHeaderClicked(field, $event)
                          },
                        },
                      }),
                    ],
              ]
            : _vm._e(),
        ]
      }),
      _vm.vuetable.scrollVisible ? _c("vuetable-col-gutter") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }