import { render, staticRenderFns } from "./ModalJourneyV2AddUser.vue?vue&type=template&id=b8dde478&scoped=true"
import script from "./ModalJourneyV2AddUser.vue?vue&type=script&lang=js"
export * from "./ModalJourneyV2AddUser.vue?vue&type=script&lang=js"
import style0 from "./ModalJourneyV2AddUser.vue?vue&type=style&index=0&id=b8dde478&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8dde478",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/journey/workspace/journeyAdmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b8dde478')) {
      api.createRecord('b8dde478', component.options)
    } else {
      api.reload('b8dde478', component.options)
    }
    module.hot.accept("./ModalJourneyV2AddUser.vue?vue&type=template&id=b8dde478&scoped=true", function () {
      api.rerender('b8dde478', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/container/modal/ModalJourneyV2AddUser.vue"
export default component.exports