var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        fill: "#currentColor",
        d: "M9.5 10.8a3.16 3.16 0 003.13-3.2c0-1.77-1.4-3.2-3.13-3.2a3.16 3.16 0 00-3.13 3.2c0 1.77 1.4 3.2 3.13 3.2zM11.9 12.66a2.24 2.24 0 00-1.7-.8H8.8a2.2 2.2 0 00-1.7.8 2.31 2.31 0 00-.52 1.83l.7 5c.07.55.34 1.06.75 1.42a2.2 2.2 0 002.94 0c.41-.36.67-.87.75-1.42l.7-5a2.35 2.35 0 00-.52-1.83z",
      },
    }),
    _c("path", {
      attrs: {
        fill: "#currentColor",
        d: "M13.19 12.44c-.2 0-.4-.06-.58-.18-.17-.11-.3-.28-.38-.47a1.09 1.09 0 01.22-1.16 4.28 4.28 0 001.23-3.02 4.36 4.36 0 00-1.23-3.03 1.08 1.08 0 010-1.5 1.03 1.03 0 011.48 0 6.41 6.41 0 011.83 4.53 6.53 6.53 0 01-1.83 4.52 1.04 1.04 0 01-.74.31zM5.81 12.44a1.02 1.02 0 01-.74-.32A6.41 6.41 0 013.24 7.6a6.53 6.53 0 011.83-4.52 1.04 1.04 0 01.74-.32 1.02 1.02 0 01.74.31 1.07 1.07 0 01.3.75 1.09 1.09 0 01-.3.76A4.27 4.27 0 005.32 7.6a4.36 4.36 0 001.23 3.03 1.08 1.08 0 01.22 1.16c-.08.19-.21.36-.38.47-.17.12-.37.18-.58.18v.01z",
      },
    }),
    _c("path", {
      attrs: {
        fill: "#currentColor",
        d: "M15.4 14.7a1.03 1.03 0 01-.88-.66 1.09 1.09 0 01.19-1.1A7.48 7.48 0 0016.9 7.6a7.62 7.62 0 00-2.2-5.33 1.08 1.08 0 01.01-1.51 1.03 1.03 0 011.48 0 9.62 9.62 0 012.74 6.8 9.8 9.8 0 01-2.74 6.78 1.04 1.04 0 01-.8.36zM3.6 14.7a1.02 1.02 0 01-.74-.3A9.62 9.62 0 01.12 7.6 9.8 9.8 0 012.86.83a1.06 1.06 0 01.77-.53 1.02 1.02 0 01.87.3 1.07 1.07 0 01.3.89 1.08 1.08 0 01-.5.79A7.48 7.48 0 002.1 7.6a7.62 7.62 0 002.2 5.33 1.08 1.08 0 01.22 1.17c-.08.2-.21.36-.39.48-.17.12-.37.18-.58.18l.05-.06z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }