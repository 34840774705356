<template>
  <select
    class="switch-language"
    name="language"
    @change="changeLanguage"
  >
    <option
      v-for="lang in supportedLanguages"
      :key="lang"
      :selected="isCurrentLanguage(lang)"
      :class="{ 'is-selected': isCurrentLanguage(lang) }"
      :value="lang"
    >
      {{ lang }}
    </option>
  </select>
</template>
<script>
import { Translation } from '@/helpers/translation';

export default {
  name: 'SwitchLanguage',

  computed: {
    supportedLanguages() {
      return Translation.supportedLanguages;
    },
    currentLanguage() {
      return Translation.currentLanguage;
    },
  },
  methods: {
    changeLanguage(e) {
      const lang = e.target.value;
      this.$store.state.auth.userInfo.lang = lang; // use this line if you want dynamic datepicker language, since it gets default value from userInfo
      return Translation.changeLanguage(lang);
    },
    isCurrentLanguage(lang) {
      return lang === this.currentLanguage;
    },
  },
};
</script>
<style  scoped>
.switch-language {
  margin-left: 5px;
}

</style>
