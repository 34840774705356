var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-container" },
    [
      _c(
        "div",
        {
          staticClass: "modal",
          class: { "is-loading": _vm.formSending },
          attrs: { tabindex: "-1" },
        },
        [
          _c(
            "div",
            {
              class: [
                "modal-dialog modal-dialog-scrollable",
                _vm.tabPane === "startTab" ? "calender-tab" : "",
              ],
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("header", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.journeyHaveUsers
                            ? _vm.$t("assign_journey")
                            : _vm.$t("start_journey")
                        ) +
                        " "
                    ),
                  ]),
                  _c("button", {
                    staticClass: "close",
                    attrs: { type: "button" },
                    domProps: { innerHTML: _vm._s("&times;") },
                    on: { click: _vm.closeModal },
                  }),
                ]),
                _c("nav", { staticClass: "navigation navigation--secondary" }, [
                  _c("div", { staticClass: "container" }, [
                    _c(
                      "ul",
                      {
                        staticClass: "navigation__list nav",
                        attrs: { id: "startJourneyTab", role: "tablist" },
                      },
                      [
                        _c("li", { staticClass: "navigation__item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "navigation__link",
                              class: { active: _vm.tabPane === "startTab" },
                              on: {
                                click: function ($event) {
                                  return _vm.tabNavigation("startTab")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("schedule")) + " ")]
                          ),
                        ]),
                        _c("li", { staticClass: "navigation__item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "navigation__link",
                              class: {
                                active: _vm.tabPane === "advancedSettingsTab",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.tabNavigation(
                                    "advancedSettingsTab"
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("assign_lowase")) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.tabPane === "startTab",
                            expression: "tabPane === 'startTab'",
                          },
                        ],
                        staticClass: "tab-pane",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-4 bglight" }, [
                            _c(
                              "h5",
                              { staticClass: "modal-title schedule-title" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("letsschedule")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "row mx-0" },
                              [
                                _c("div", { staticClass: "col-6" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group form-group--light u-mB-0 u-zIndex-3",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-label",
                                          attrs: { for: "journeyLanguage" },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("begin_date")))]
                                      ),
                                      _c("date-picker", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        class: {
                                          "is-danger":
                                            _vm.errors.has("assign.beginDate"),
                                        },
                                        attrs: {
                                          "disabled-date": _vm.notBeforeToday,
                                          "value-type": "date",
                                          "data-vv-scope": "assign",
                                          "data-vv-name": "beginDate",
                                          "data-vv-as": _vm.$t("begin_date"),
                                          "first-day-of-week":
                                            _vm.userLang === "en" ? 7 : 1,
                                          lang: _vm.userLang,
                                          format:
                                            _vm.userLang === "en"
                                              ? "MM.DD.YYYY"
                                              : "DD.MM.YYYY",
                                        },
                                        on: {
                                          "update:disabledDate": function (
                                            $event
                                          ) {
                                            _vm.notBeforeToday = $event
                                          },
                                          "update:disabled-date": function (
                                            $event
                                          ) {
                                            _vm.notBeforeToday = $event
                                          },
                                          change: function ($event) {
                                            return _vm.adjustTime(
                                              $event,
                                              "date"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.beginActivationDateTime,
                                          callback: function ($$v) {
                                            _vm.beginActivationDateTime = $$v
                                          },
                                          expression: "beginActivationDateTime",
                                        },
                                      }),
                                      _vm.errors.has("assign.beginDate")
                                        ? _c(
                                            "span",
                                            { staticClass: "help is-danger" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errors.first(
                                                    "assign.beginDate"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          !_vm.isOnboarding &&
                                          _vm.journeyNotificationSelect !==
                                            "scheduled",
                                        expression:
                                          "!isOnboarding && journeyNotificationSelect !== 'scheduled'",
                                      },
                                    ],
                                    staticClass: "col-6",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-group form-group--light u-mB-0 u-zIndex-3",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-label",
                                            attrs: { for: "journeyLanguage" },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("begin_time")))]
                                        ),
                                        _c(
                                          "date-picker",
                                          {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            class: {
                                              "is-danger":
                                                _vm.errors.has(
                                                  "assign.beginTime"
                                                ),
                                            },
                                            attrs: {
                                              "disabled-time":
                                                _vm.notBeforeTime,
                                              "data-vv-scope": "assign",
                                              "data-vv-name": "beginTime",
                                              "data-vv-as":
                                                _vm.$t("begin_time"),
                                              "value-type": "date",
                                              type: "time",
                                              lang: _vm.userLang,
                                              format:
                                                _vm.userLang === "en"
                                                  ? "hh:mm A"
                                                  : "HH:mm",
                                              "time-picker-options": {
                                                start: "08:00",
                                                step: "00:15",
                                                end: "23:45",
                                                format:
                                                  _vm.userLang === "en"
                                                    ? "hh:mm A"
                                                    : "HH:mm",
                                              },
                                            },
                                            on: {
                                              "update:disabledTime": function (
                                                $event
                                              ) {
                                                _vm.notBeforeTime = $event
                                              },
                                              "update:disabled-time": function (
                                                $event
                                              ) {
                                                _vm.notBeforeTime = $event
                                              },
                                              change: function ($event) {
                                                return _vm.adjustTime(
                                                  $event,
                                                  "time"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.beginActivationDateTime,
                                              callback: function ($$v) {
                                                _vm.beginActivationDateTime =
                                                  $$v
                                              },
                                              expression:
                                                "beginActivationDateTime",
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "icon-calendar" },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      name: "time",
                                                      "view-box": "0 0 512 512",
                                                    },
                                                  },
                                                  [_c("icon-time")],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                        _vm.errors.has("assign.beginTime")
                                          ? _c(
                                              "span",
                                              { staticClass: "help is-danger" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "assign.beginTime"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "swicth-checkbox" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.customizeDeliveryDate,
                                            expression: "customizeDeliveryDate",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "customizeDeliveryDate",
                                          checked: "",
                                          "true-value": 1,
                                          "false-value": 0,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.customizeDeliveryDate
                                          )
                                            ? _vm._i(
                                                _vm.customizeDeliveryDate,
                                                null
                                              ) > -1
                                            : _vm._q(
                                                _vm.customizeDeliveryDate,
                                                1
                                              ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.customizeDeliveryDate,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? 1 : 0
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.customizeDeliveryDate =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.customizeDeliveryDate =
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                              }
                                            } else {
                                              _vm.customizeDeliveryDate = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "swicth-label",
                                          attrs: {
                                            for: "customizeDeliveryDate",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "customize_delivery_date"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "collapse-transition",
                                  {
                                    attrs: {
                                      duration: 500,
                                      dimension: "height",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.customizeDeliveryDate,
                                            expression: "customizeDeliveryDate",
                                          },
                                        ],
                                        staticClass: "col-12",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-group form-group--light",
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-6 p-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "checkbox" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.journeyNotificationSelect,
                                                            expression:
                                                              "journeyNotificationSelect",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "radio",
                                                          name: "journeyNotificationSelect",
                                                          id: "scheduled",
                                                          value: "scheduled",
                                                        },
                                                        domProps: {
                                                          checked: _vm._q(
                                                            _vm.journeyNotificationSelect,
                                                            "scheduled"
                                                          ),
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              _vm.journeyNotificationSelect =
                                                                "scheduled"
                                                            },
                                                            function ($event) {
                                                              return _vm.changeNotificationChange(
                                                                "scheduled"
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "scheduled",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "scheduled"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6 p-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "checkbox" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.journeyNotificationSelect,
                                                            expression:
                                                              "journeyNotificationSelect",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "radio",
                                                          name: "journeyNotificationSelect",
                                                          id: "twiceAWeek",
                                                          value: "twiceAWeek",
                                                        },
                                                        domProps: {
                                                          checked: _vm._q(
                                                            _vm.journeyNotificationSelect,
                                                            "twiceAWeek"
                                                          ),
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              _vm.journeyNotificationSelect =
                                                                "twiceAWeek"
                                                            },
                                                            function ($event) {
                                                              return _vm.changeNotificationChange(
                                                                "twiceAWeek"
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "twiceAWeek",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "twice_aweek"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6 p-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "checkbox" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.journeyNotificationSelect,
                                                            expression:
                                                              "journeyNotificationSelect",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "radio",
                                                          name: "journeyNotificationSelect",
                                                          id: "thriceAWeek",
                                                          value: "thriceAWeek",
                                                        },
                                                        domProps: {
                                                          checked: _vm._q(
                                                            _vm.journeyNotificationSelect,
                                                            "thriceAWeek"
                                                          ),
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              _vm.journeyNotificationSelect =
                                                                "thriceAWeek"
                                                            },
                                                            function ($event) {
                                                              return _vm.changeNotificationChange(
                                                                "thriceAWeek"
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "thriceAWeek",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "thrice_aweek"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6 p-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "checkbox" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.journeyNotificationSelect,
                                                            expression:
                                                              "journeyNotificationSelect",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "radio",
                                                          name: "journeyNotificationSelect",
                                                          id: "spaced",
                                                          value: "spaced",
                                                        },
                                                        domProps: {
                                                          checked: _vm._q(
                                                            _vm.journeyNotificationSelect,
                                                            "spaced"
                                                          ),
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              _vm.journeyNotificationSelect =
                                                                "spaced"
                                                            },
                                                            function ($event) {
                                                              return _vm.changeNotificationChange(
                                                                "spaced"
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "spaced",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("spaced")
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.journeyNotificationSelect ===
                                                        "custom",
                                                      expression:
                                                        "journeyNotificationSelect === 'custom'",
                                                    },
                                                  ],
                                                  staticClass: "col-6 p-2",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "checkbox" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.journeyNotificationSelect,
                                                            expression:
                                                              "journeyNotificationSelect",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "radio",
                                                          name: "journeyNotificationSelect",
                                                          id: "custom",
                                                          value: "custom",
                                                        },
                                                        domProps: {
                                                          checked: _vm._q(
                                                            _vm.journeyNotificationSelect,
                                                            "custom"
                                                          ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            _vm.journeyNotificationSelect =
                                                              "custom"
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "custom",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("custom")
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.journeyNotificationSelect ===
                                                      "scheduled",
                                                    expression:
                                                      "journeyNotificationSelect === 'scheduled'",
                                                  },
                                                ],
                                                staticClass:
                                                  "form form--horizontal mt-2",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "panel-title",
                                                      _vm.showAdvanceSettings2
                                                        ? "active"
                                                        : "",
                                                    ],
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.showAdvanceSettings2 =
                                                              !_vm.showAdvanceSettings2
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "advanced_tab"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "collapse-transition",
                                                  {
                                                    attrs: {
                                                      duration: 500,
                                                      dimension: "height",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.showAdvanceSettings2,
                                                            expression:
                                                              "showAdvanceSettings2",
                                                          },
                                                        ],
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "swicth-checkbox",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.sendOnSaturday,
                                                                      expression:
                                                                        "sendOnSaturday",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                    id: "sendOnSaturday",
                                                                    "true-value": 1,
                                                                    "false-value": 0,
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm.sendOnSaturday
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm.sendOnSaturday,
                                                                            null
                                                                          ) > -1
                                                                        : _vm._q(
                                                                            _vm.sendOnSaturday,
                                                                            1
                                                                          ),
                                                                  },
                                                                  on: {
                                                                    change: [
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm.sendOnSaturday,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? 1
                                                                              : 0
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              (_vm.sendOnSaturday =
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                ))
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              (_vm.sendOnSaturday =
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  ))
                                                                          }
                                                                        } else {
                                                                          _vm.sendOnSaturday =
                                                                            $$c
                                                                        }
                                                                      },
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.changeNotificationChange(
                                                                          "scheduled"
                                                                        )
                                                                      },
                                                                    ],
                                                                  },
                                                                }),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "swicth-label",
                                                                    attrs: {
                                                                      for: "sendOnSaturday",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "send_onsaturday"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "swicth-checkbox",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.sendOnSunday,
                                                                      expression:
                                                                        "sendOnSunday",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                    id: "sendOnSunday",
                                                                    "true-value": 1,
                                                                    "false-value": 0,
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm.sendOnSunday
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm.sendOnSunday,
                                                                            null
                                                                          ) > -1
                                                                        : _vm._q(
                                                                            _vm.sendOnSunday,
                                                                            1
                                                                          ),
                                                                  },
                                                                  on: {
                                                                    change: [
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm.sendOnSunday,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? 1
                                                                              : 0
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              (_vm.sendOnSunday =
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                ))
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              (_vm.sendOnSunday =
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  ))
                                                                          }
                                                                        } else {
                                                                          _vm.sendOnSunday =
                                                                            $$c
                                                                        }
                                                                      },
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.changeNotificationChange(
                                                                          "scheduled"
                                                                        )
                                                                      },
                                                                    ],
                                                                  },
                                                                }),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "swicth-label",
                                                                    attrs: {
                                                                      for: "sendOnSunday",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "send_onsunday"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12 mt-4",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-group",
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "form-label",
                                                                    attrs: {
                                                                      for: "excludedDays",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "excluded_days"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "flat-pickr",
                                                                  {
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      config:
                                                                        _vm.excludedDaysConfig,
                                                                      placeholder:
                                                                        _vm.$t(
                                                                          "select_dates"
                                                                        ),
                                                                      name: "excludedDays",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.excludedDaysAsString,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.excludedDaysAsString =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "excludedDaysAsString",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.journeyNotificationSelect ===
                                            "spaced"
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-5" },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form-label",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "send_every"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-start mb-4",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.spacedValue,
                                                                  expression:
                                                                    "spacedValue",
                                                                },
                                                                {
                                                                  name: "validate",
                                                                  rawName:
                                                                    "v-validate.immediate",
                                                                  value: `required|numeric|decimal:0|min_value: 1|max_value: ${_vm.maxSpacedValue}`,
                                                                  expression:
                                                                    "`required|numeric|decimal:0|min_value: 1|max_value: ${maxSpacedValue}`",
                                                                  modifiers: {
                                                                    immediate: true,
                                                                  },
                                                                },
                                                              ],
                                                              staticClass:
                                                                "h-40",
                                                              class: {
                                                                "is-danger":
                                                                  _vm.errors.has(
                                                                    "assign.spacedValue"
                                                                  ),
                                                              },
                                                              attrs: {
                                                                type: "number",
                                                                id: "spacedValue",
                                                                name: "spacedValue",
                                                                min: "1",
                                                                max: _vm.maxSpacedValue,
                                                                "data-vv-scope":
                                                                  "assign",
                                                                "data-vv-name":
                                                                  "spacedValue",
                                                                "data-vv-as":
                                                                  _vm.$t(
                                                                    "spaced"
                                                                  ),
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.spacedValue,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.spacedValue =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                            _c("multi-select", {
                                                              staticClass:
                                                                "selectbox selectbox--secondary",
                                                              attrs: {
                                                                "track-by":
                                                                  "id",
                                                                label: "text",
                                                                "clear-on-select":
                                                                  "",
                                                                "allow-empty": false,
                                                                searchable: false,
                                                                "show-labels": false,
                                                                options:
                                                                  _vm.spaceOptions,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.selectSpace,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectSpace =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "selectSpace",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-7 d-flex align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "swicth-checkbox",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.is_weekend_included,
                                                                  expression:
                                                                    "is_weekend_included",
                                                                },
                                                              ],
                                                              attrs: {
                                                                type: "checkbox",
                                                                id: "includeWeekend",
                                                                "true-value": 1,
                                                                "false-value": 0,
                                                              },
                                                              domProps: {
                                                                checked:
                                                                  Array.isArray(
                                                                    _vm.is_weekend_included
                                                                  )
                                                                    ? _vm._i(
                                                                        _vm.is_weekend_included,
                                                                        null
                                                                      ) > -1
                                                                    : _vm._q(
                                                                        _vm.is_weekend_included,
                                                                        1
                                                                      ),
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        _vm.is_weekend_included,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c =
                                                                        $$el.checked
                                                                          ? 1
                                                                          : 0
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v =
                                                                          null,
                                                                        $$i =
                                                                          _vm._i(
                                                                            $$a,
                                                                            $$v
                                                                          )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          (_vm.is_weekend_included =
                                                                            $$a.concat(
                                                                              [
                                                                                $$v,
                                                                              ]
                                                                            ))
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          (_vm.is_weekend_included =
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              ))
                                                                      }
                                                                    } else {
                                                                      _vm.is_weekend_included =
                                                                        $$c
                                                                    }
                                                                  },
                                                              },
                                                            }),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "swicth-label right",
                                                                attrs: {
                                                                  for: "includeWeekend",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "include_weekend"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "row mx-0" }, [
                              _c("div", { staticClass: "col-auto" }, [
                                _c("div", { staticClass: "swicth-checkbox" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.hasReminder,
                                        expression: "hasReminder",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      name: "hasReminder",
                                      id: "hasReminder",
                                      checked: "",
                                      "true-value": true,
                                      "false-value": false,
                                    },
                                    domProps: {
                                      checked: Array.isArray(_vm.hasReminder)
                                        ? _vm._i(_vm.hasReminder, null) > -1
                                        : _vm.hasReminder,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.hasReminder,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.hasReminder = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.hasReminder = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.hasReminder = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "swicth-label",
                                      attrs: { for: "hasReminder" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("hasReminder")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                            _c(
                              "div",
                              [
                                _c(
                                  "collapse-transition",
                                  {
                                    attrs: {
                                      duration: 500,
                                      dimension: "height",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.hasReminder,
                                            expression: "hasReminder",
                                          },
                                        ],
                                        staticClass: "container mt-5",
                                      },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-group row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-4 form-label",
                                                  attrs: {
                                                    for: "employee_title",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("employee_title")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-8" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.employee_title,
                                                        expression:
                                                          "employee_title",
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: _vm.hasReminder
                                                          ? "required|max:40"
                                                          : "",
                                                        expression:
                                                          "hasReminder ? 'required|max:40':''",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    class: {
                                                      "is-danger":
                                                        _vm.errors.has(
                                                          "journey.employee_title"
                                                        ),
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      id: "employee_title",
                                                      "data-vv-name":
                                                        "employee_title",
                                                      "data-vv-scope":
                                                        "journey",
                                                      "data-vv-as":
                                                        _vm.$t(
                                                          "employee_title"
                                                        ),
                                                    },
                                                    domProps: {
                                                      value: _vm.employee_title,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.employee_title =
                                                          $event.target.value
                                                      },
                                                    },
                                                  }),
                                                  _vm.errors.has(
                                                    "journey.employee_title"
                                                  )
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "help is-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "journey.employee_title"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-group row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-4 form-label",
                                                  attrs: {
                                                    for: "employee_content",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "employee_content"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-8" },
                                                [
                                                  _c("textarea", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.employee_content,
                                                        expression:
                                                          "employee_content",
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: _vm.hasReminder
                                                          ? "required|max:160"
                                                          : "",
                                                        expression:
                                                          "hasReminder ? 'required|max:160':''",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    class: {
                                                      "is-danger":
                                                        _vm.errors.has(
                                                          "journey.employee_content"
                                                        ),
                                                    },
                                                    attrs: {
                                                      id: "employee_content",
                                                      "data-vv-name":
                                                        "employee_content",
                                                      "data-vv-scope":
                                                        "journey",
                                                      "data-vv-as":
                                                        _vm.$t(
                                                          "employee_content"
                                                        ),
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.employee_content,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.employee_content =
                                                          $event.target.value
                                                      },
                                                    },
                                                  }),
                                                  _vm.errors.has(
                                                    "journey.employee_content"
                                                  )
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "help is-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "journey.employee_content"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-group row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-4 form-label",
                                                  attrs: {
                                                    for: "weeks_after_last_content",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "weeks_after_last_content"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-8" },
                                                [
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.weeks_after_last_content,
                                                          expression:
                                                            "weeks_after_last_content",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "selectbox-o",
                                                      attrs: {
                                                        id: "weeks_after_last_content",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.weeks_after_last_content =
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                        },
                                                      },
                                                    },
                                                    _vm._l(
                                                      [
                                                        1, 2, 3, 4, 5, 6, 7, 8,
                                                        9,
                                                      ],
                                                      function (n) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: n,
                                                            domProps: {
                                                              value: n,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(n) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "form-label mx-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.$t("week"))
                                                      ),
                                                    ]
                                                  ),
                                                  _c("svg-icon", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName:
                                                          "v-tooltip.top",
                                                        value: _vm.$t(
                                                          "weeks_after_last_content_info"
                                                        ),
                                                        expression:
                                                          "$t('weeks_after_last_content_info')",
                                                        modifiers: {
                                                          top: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "form-label",
                                                    attrs: {
                                                      type: "mdi",
                                                      path: _vm.iconInformationOutline,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-group row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-4 form-label",
                                                  attrs: { for: "week_span" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("week_span")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-8" },
                                                [
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.week_span,
                                                          expression:
                                                            "week_span",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "selectbox-o",
                                                      attrs: {
                                                        id: "week_span",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.week_span = $event
                                                            .target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        },
                                                      },
                                                    },
                                                    _vm._l(
                                                      [1, 2, 3, 4],
                                                      function (n) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: n,
                                                            domProps: {
                                                              value: n,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(n) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "form-label mx-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.$t("week"))
                                                      ),
                                                    ]
                                                  ),
                                                  _c("svg-icon", {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName:
                                                          "v-tooltip.top",
                                                        value:
                                                          _vm.$t(
                                                            "week_span_info"
                                                          ),
                                                        expression:
                                                          "$t('week_span_info')",
                                                        modifiers: {
                                                          top: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "form-label",
                                                    attrs: {
                                                      type: "mdi",
                                                      path: _vm.iconInformationOutline,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-group row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-4 form-label",
                                                  attrs: { for: "day_of_week" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("day_of_week")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-8" },
                                                [
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.day_of_week,
                                                          expression:
                                                            "day_of_week",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "selectbox-o",
                                                      attrs: {
                                                        id: "day_of_week",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.day_of_week =
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                        },
                                                      },
                                                    },
                                                    _vm._l(
                                                      [1, 2, 3, 4, 5, 6, 7],
                                                      function (n) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: n,
                                                            domProps: {
                                                              value: n,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$helpers.getWeekDay(
                                                                    n
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "schedule-next" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col pre-wrap" }, [
                                  _vm.previewLastDate !== "Invalid date"
                                    ? _c("small", [
                                        _vm._v(
                                          _vm._s(_vm.$t("will_end") + "\n")
                                        ),
                                        _c("strong", [
                                          _vm._v(_vm._s(_vm.previewLastDate)),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "modal-footer" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button button--primary",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.tabNavigation(
                                            "advancedSettingsTab"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("next")) + " ")]
                                  ),
                                  _c(
                                    "transition",
                                    { attrs: { name: "waterfall" } },
                                    [
                                      _vm.previewContentCount === 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "alert--error assignment-final-msg-box border card__title help is-danger p-4 waterfall-enter-active",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "no_journey_items_to_be_assigned"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-8" },
                            [
                              _c("full-calendar", {
                                attrs: {
                                  "user-lang": _vm.userLang,
                                  "calender-events": _vm.calenderEvents,
                                  "preview-first-date": _vm.previewFirstDate,
                                  "total-event-days": _vm.totalEventDays,
                                },
                                on: {
                                  setDateFromDrop: _vm.setDateFromDrop,
                                  isDragging: _vm.setDragging,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm.tabPane === "advancedSettingsTab"
                      ? _c("div", { staticClass: "tab-pane" }, [
                          _c(
                            "h5",
                            { staticClass: "modal-title schedule-title" },
                            [_vm._v(" " + _vm._s(_vm.$t("towhomassign")) + " ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group px-4 pb-4" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label form-label--small",
                                  attrs: { for: "contentTitle" },
                                },
                                [_vm._v(_vm._s(_vm.$t("to")))]
                              ),
                              _c("vue-tags-input", {
                                class: {
                                  "is-danger":
                                    _vm.errors.has("assign.assignTo"),
                                },
                                attrs: {
                                  placeholder: _vm.$t("add_name_placeholder"),
                                  tags: _vm.tags,
                                  "add-only-from-autocomplete": true,
                                  "autocomplete-items": _vm.autocompleteItems,
                                },
                                on: { "tags-changed": _vm.update },
                                model: {
                                  value: _vm.tag,
                                  callback: function ($$v) {
                                    _vm.tag = $$v
                                  },
                                  expression: "tag",
                                },
                              }),
                              _c("input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  type: "hidden",
                                  "data-vv-scope": "assign",
                                  "data-vv-name": "assignTo",
                                  "data-vv-as": _vm.$t("to"),
                                },
                                domProps: { value: _vm.assignTo },
                              }),
                              _vm.errors.has("assign.assignTo")
                                ? _c(
                                    "span",
                                    { staticClass: "help is-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("assign.assignTo")
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form form--horizontal" },
                            [
                              _c(
                                "div",
                                {
                                  class: [
                                    "panel-title",
                                    _vm.showAdvanceSettings ? "active" : "",
                                  ],
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.showAdvanceSettings =
                                            !_vm.showAdvanceSettings
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("advanced_tab")))]
                                  ),
                                ]
                              ),
                              _c(
                                "collapse-transition",
                                {
                                  attrs: { duration: 500, dimension: "height" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.showAdvanceSettings,
                                          expression: "showAdvanceSettings",
                                        },
                                      ],
                                      staticClass: "px-4 bglight",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "row mx-4 bglight" },
                                        [
                                          _c("div", { staticClass: "col" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "swicth-checkbox",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.is_welcome_notifications_enabled,
                                                      expression:
                                                        "is_welcome_notifications_enabled",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    id: "openedDays",
                                                    checked: "",
                                                    "true-value": 1,
                                                    "false-value": 0,
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.is_welcome_notifications_enabled
                                                    )
                                                      ? _vm._i(
                                                          _vm.is_welcome_notifications_enabled,
                                                          null
                                                        ) > -1
                                                      : _vm._q(
                                                          _vm.is_welcome_notifications_enabled,
                                                          1
                                                        ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.is_welcome_notifications_enabled,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? 1
                                                          : 0
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.is_welcome_notifications_enabled =
                                                              $$a.concat([$$v]))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.is_welcome_notifications_enabled =
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                ))
                                                        }
                                                      } else {
                                                        _vm.is_welcome_notifications_enabled =
                                                          $$c
                                                      }
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "swicth-label",
                                                    attrs: {
                                                      for: "openedDays",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "send_welcome_email"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "collapse-transition",
                                        {
                                          attrs: {
                                            duration: 500,
                                            dimension: "height",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.is_welcome_notifications_enabled,
                                                  expression:
                                                    "is_welcome_notifications_enabled",
                                                },
                                              ],
                                              staticClass: "row mx-4",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "form-group row",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "col-md-5 form-label",
                                                      attrs: {
                                                        for: "pushNotificationHeading",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "push_noti_head"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-7" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.push_notification_title,
                                                            expression:
                                                              "push_notification_title",
                                                          },
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value: "max:40",
                                                            expression:
                                                              "'max:40'",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        class: {
                                                          "is-danger":
                                                            _vm.errors.has(
                                                              "assign.push_notification_title"
                                                            ),
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          id: "pushNotificationHeading",
                                                          "data-vv-name":
                                                            "push_notification_title",
                                                          "data-vv-scope":
                                                            "assign",
                                                          "data-vv-as":
                                                            _vm.$t(
                                                              "push_noti_head"
                                                            ),
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.push_notification_title,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.push_notification_title =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                      _c("button", {
                                                        staticClass:
                                                          "emoji-button",
                                                        domProps: {
                                                          innerHTML:
                                                            _vm._s("☺"),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.dropdownToggle(
                                                              "title"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm.dropdownIndex ===
                                                      "title"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "on-clickaway",
                                                                  rawName:
                                                                    "v-on-clickaway",
                                                                  value:
                                                                    _vm.hideDropdown,
                                                                  expression:
                                                                    "hideDropdown",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "dropdown",
                                                              staticStyle: {
                                                                "z-index":
                                                                  "1000001",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  ref: "dropdownBoxtitle",
                                                                  staticClass:
                                                                    "dropdown__menu emoji-dropdown-title",
                                                                },
                                                                [
                                                                  _c(
                                                                    "VEmojiPicker",
                                                                    {
                                                                      staticClass:
                                                                        "emoji",
                                                                      attrs: {
                                                                        pack: _vm.emojiPack,
                                                                        "label-search":
                                                                          _vm.$t(
                                                                            "search_placeholder"
                                                                          ),
                                                                      },
                                                                      on: {
                                                                        select:
                                                                          _vm.SelectEmojiForTitle,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.errors.has(
                                                        "assign.push_notification_title"
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "help is-danger",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.errors.first(
                                                                    "assign.push_notification_title"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "form-group row",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "col-md-5 form-label",
                                                      attrs: {
                                                        for: "pushNotificationBody",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "push_noti_body"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-md-7" },
                                                    [
                                                      _c("textarea", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.push_notification_body,
                                                            expression:
                                                              "push_notification_body",
                                                          },
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value: "max:110",
                                                            expression:
                                                              "'max:110'",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        class: {
                                                          "is-danger":
                                                            _vm.errors.has(
                                                              "assign.push_notification_body"
                                                            ),
                                                        },
                                                        attrs: {
                                                          id: "pushNotificationBody",
                                                          "data-vv-name":
                                                            "push_notification_body",
                                                          "data-vv-scope":
                                                            "assign",
                                                          "data-vv-as":
                                                            _vm.$t(
                                                              "push_noti_body"
                                                            ),
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.push_notification_body,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.push_notification_body =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                      _c("button", {
                                                        staticClass:
                                                          "emoji-button",
                                                        domProps: {
                                                          innerHTML:
                                                            _vm._s("☺"),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.dropdownToggle(
                                                              "body"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm.dropdownIndex ===
                                                      "body"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "on-clickaway",
                                                                  rawName:
                                                                    "v-on-clickaway",
                                                                  value:
                                                                    _vm.hideDropdown,
                                                                  expression:
                                                                    "hideDropdown",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "dropdown",
                                                              staticStyle: {
                                                                "z-index":
                                                                  "1000001",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  ref: "dropdownBoxbody",
                                                                  staticClass:
                                                                    "dropdown__menu emoji-dropdown-body",
                                                                },
                                                                [
                                                                  _c(
                                                                    "VEmojiPicker",
                                                                    {
                                                                      staticClass:
                                                                        "emoji",
                                                                      attrs: {
                                                                        pack: _vm.emojiPack,
                                                                        "label-search":
                                                                          _vm.$t(
                                                                            "search_placeholder"
                                                                          ),
                                                                      },
                                                                      on: {
                                                                        select:
                                                                          _vm.SelectEmojiForBody,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.errors.has(
                                                        "assign.push_notification_body"
                                                      )
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "help is-danger",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.errors.first(
                                                                    "assign.push_notification_body"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("div", { staticClass: "row mx-4" }, [
                                        _c("div", { staticClass: "col" }, [
                                          _c(
                                            "div",
                                            { staticClass: "swicth-checkbox" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.cancel_emails,
                                                    expression: "cancel_emails",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id: "sendEmail",
                                                  "true-value": 1,
                                                  "false-value": 0,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.cancel_emails
                                                  )
                                                    ? _vm._i(
                                                        _vm.cancel_emails,
                                                        null
                                                      ) > -1
                                                    : _vm._q(
                                                        _vm.cancel_emails,
                                                        1
                                                      ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a = _vm.cancel_emails,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked ? 1 : 0
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.cancel_emails =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.cancel_emails =
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                      }
                                                    } else {
                                                      _vm.cancel_emails = $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "swicth-label",
                                                  attrs: { for: "sendEmail" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("dont_send_email")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "row mx-4" }, [
                                        _c("div", { staticClass: "col" }, [
                                          _c(
                                            "div",
                                            { staticClass: "swicth-checkbox" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.disableNotification,
                                                    expression:
                                                      "disableNotification",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id: "disableNotify",
                                                  "true-value": 1,
                                                  "false-value": 0,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.disableNotification
                                                  )
                                                    ? _vm._i(
                                                        _vm.disableNotification,
                                                        null
                                                      ) > -1
                                                    : _vm._q(
                                                        _vm.disableNotification,
                                                        1
                                                      ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.disableNotification,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked ? 1 : 0
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.disableNotification =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.disableNotification =
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                      }
                                                    } else {
                                                      _vm.disableNotification =
                                                        $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "swicth-label",
                                                  attrs: {
                                                    for: "disableNotify",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "mobil_notify_disabled"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "row mx-4" }, [
                                        _c("div", { staticClass: "col" }, [
                                          _c(
                                            "div",
                                            { staticClass: "swicth-checkbox" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.is_self_paced,
                                                    expression: "is_self_paced",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id: "selfPaced",
                                                  "true-value": 1,
                                                  "false-value": 0,
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.is_self_paced
                                                  )
                                                    ? _vm._i(
                                                        _vm.is_self_paced,
                                                        null
                                                      ) > -1
                                                    : _vm._q(
                                                        _vm.is_self_paced,
                                                        1
                                                      ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a = _vm.is_self_paced,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked ? 1 : 0
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.is_self_paced =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.is_self_paced =
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                      }
                                                    } else {
                                                      _vm.is_self_paced = $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "swicth-label",
                                                  attrs: { for: "selfPaced" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("self_paced"))
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.items.length > 0,
                                  expression: "errors.items.length > 0",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "alert alert--card alert--error",
                                },
                                [
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.$t("error_msg_title"))),
                                  ]),
                                  _c(
                                    "ul",
                                    _vm._l(_vm.errors, function (error, index) {
                                      return _c("li", { key: index }, [
                                        _vm._v(" " + _vm._s(error.msg) + " "),
                                      ])
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button button--primary",
                                class: {
                                  "button--disabled":
                                    _vm.previewContentCount === 0,
                                },
                                attrs: {
                                  type: "button",
                                  disabled:
                                    _vm.isPending ||
                                    _vm.previewContentCount === 0,
                                },
                                on: { click: _vm.duplicateJourney },
                              },
                              [
                                !_vm.isPending
                                  ? _c("span", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("assign")) + " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.isPending
                                  ? _c("div", { staticClass: "lds-ellipsis" }, [
                                      _c("div"),
                                      _c("div"),
                                      _c("div"),
                                      _c("div"),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "collapse-transition",
        { attrs: { duration: 500, dimension: "height" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isDragging,
                  expression: "isDragging",
                },
              ],
              staticClass: "hint",
            },
            [
              _c("div", { staticClass: "img" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/arrowkeys.png"),
                    alt: "keyboard",
                  },
                }),
              ]),
              _c("div", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.$t("keyboard_hint")) + " "),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }