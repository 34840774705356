<template>
  <div class="d-flex align-center justify-center">
    <icon-container
      v-for="n in count"
      :key="n"
      name="sprint"
      view-box="0 0 14 15"
      :width="16"
      :height="18"
      :color="n <= rate ? colorHex: defaultHex"
    >
      <sprint-icon />
    </icon-container>
  </div>
</template>

<script>
import IconContainer from '@/components/elements/Icon.vue';
import SprintIcon from '@/components/icons/Sprint.vue';

export default {
  name: 'SprintBadge',
  props: {
    rate: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 4,
    },
  },
  components: {
    'icon-container': IconContainer,
    'sprint-icon': SprintIcon,
  },
  data() {
    return {
      colorHex: '#FDAB3D',
      defaultHex: '#C4C4C4',
    };
  },
  created() {
  },
  methods: {

  },
};
</script>
