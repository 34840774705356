<!-- eslint-disable vue-i18n/no-raw-text -->
<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div class="modal-container">
      <div
        class="modal"
        tabindex="-1"
      >
        <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
        <div
          class="previewclosebutton"
        >
          <div
            class="d-block btn mx-2"
            v-if="launchedFrom === 'Contents' && !isDisabled"
            @click.prevent="editContentItem()"
          >
            <icon-container

              name="edit"
              color="#fff"
              width="18"
              height="18"
              view-box="0 0 24 24"
              :is-icon-class="false"
            >
              <icon-edit />
            </icon-container>
          </div>
          <div class="d-block mx-2">
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </div>
        </div>

        <div
          class="modal__mobile-preview from-content-page"
          @click.stop
          :class="{ 'rotatepreview' : showVideo }"
        >
          <div class="flag">
            <icon-container
              name="flag"
              :width="224"
              :height="21"
              view-box="0 0 224 21"
              :is-icon-class="false"
            >
              <icon-flag />
            </icon-container>
          </div>
          <div
            class="mobile-content"
            :class="{ 'bluered-bg': (contentType === 'PODCAST' || contentType === 'KEY_CONTACT_MANAGER'
              || contentType === 'KNOWLEDGE_REQUIREMENT_MANAGER' || contentType === 'FILE_DOWNLOAD'|| contentType === 'CHECKLIST'
              || contentType === 'BUDDY_MANAGER'), 'bgBlack': (contentType === 'INFOGRAPHIC' || contentType === 'FLIPBOOK') }"
            :style="injectedArticleHtml ? 'background:#ffffff;': ''"
          >
            <div
              v-show="showVideo && showControl"
              class="videopausecontrol"
            >
              <button
                @click="exitVideo"
                @mouseenter="showControl = true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="fill:#fff;fill-opacity:0.5;"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M0 0h24v24H0z"
                    fill="none"
                  />
                  <path d="M12 10.6l5-4.9 1.4 1.4-4.9 5 5 5-1.4 1.4-4.9-4.9-4.9 5-1.4-1.4 5-4.9-4.9-4.9L7.1 5.6z" />
                </svg>
              </button>
            </div>
            <flip-book
              :images="flipImages"
              :key="flipImages.length"
              v-if="contentType === 'FLIPBOOK' && flipImages.length > 0"
            />
            <div
              v-if="content.content_url && contentType === 'INFOGRAPHIC'"
              class="infograph-content"
            >
              <img
                :src="content.content_url"
                alt="Infographic"
              >
            </div>
            <div
              v-if="showVideo && contentType === 'VIDEO'"
              class="videocontent"
            >
              <video
                id="videoElement"
                @mouseover="showControl = true"
                @mouseleave="showControl = false"
                @canplay="updatePaused"
                @playing="updatePaused"
                @pause="updatePaused"
                @ended="exitVideo"
                controls
                playsinline
                webkit-playsinline
                autoplay
                volume="0.5"
              >
                <source
                  :src="content.content_url"
                  type="video/mp4"
                >{{ $t('not_support_html5') }}
              </video>
            </div>
            <div
              v-if="podcastPreview && contentType === 'PODCAST'"
              class="podcastcontent"
            >
              <div class="contentBox">
                <div class="podcast-icon">
                  <img
                    src="@/assets/img/podcast-mic.png"
                    alt="podcast"
                  >
                </div>
                <div class="podcast-title">
                  {{ content.name }}
                </div>
                <div
                  class="podcast-desc"
                  v-if="content.desc"
                >
                  {{ content.desc }}
                </div>
              </div>
              <div class="controlBox">
                <div
                  class="currentProgressBar"
                  ref="progressBar"
                  @mousedown="podcastSeek"
                >
                  <div
                    class="currentProgress"
                    :style="{ width: currentPodcastProgressBar + '%' }"
                  />
                </div>
                <div class="currentTimeContainer">
                  <span class="currentTime">{{ currentPodcastTime | timeFormat }}</span>
                  <span class="totalTime"> {{ podcastDuration | timeFormat }}</span>
                </div>
                <div class="controlButtonsContainer">
                  <img
                    @click="backwardPodcast()"
                    src="@/assets/img/podcast-backward.png"
                    alt="Backward"
                  >
                  <transition
                    name="slide-fade"
                    mode="out-in"
                  >
                    <img
                      key="play"
                      v-if="!podcastIsPlaying"
                      @click="togglePodcast()"
                      src="@/assets/img/podcast-play.png"
                      alt="Play"
                    >
                    <img
                      key="pause"
                      v-if="podcastIsPlaying"
                      @click="togglePodcast()"
                      src="@/assets/img/podcast-pause.png"
                      alt="Pause"
                    >
                  </transition>
                  <img
                    @click="forwardPodcast()"
                    src="@/assets/img/podcast-forward.png"
                    alt="Forward"
                  >
                </div>
              </div>
            </div>
            <div
              v-if="contentType === 'QUIZ' && content && content.quiz && content.quiz.questions.length > 0"
              class="mobile-content__quiz"
            >
              <div class="quiz-preview--container">
                <div
                  class="quiz-preview--question"
                  v-for="(questionPreview, index) in content.quiz.questions"
                  :key="index"
                >
                  <div class="quiz-preview--progress">
                    <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                    <span>{{ (content && content.quiz && content.quiz.questions) ? parseInt(((100 / content.quiz.questions.length) * (index + 1) ), 10) + '% ' + $t('completed_') : '' }}</span>
                    <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                    <div class="quiz-progress-bar">
                      <span :style="{ width: (100 / content.quiz.questions.length) * (index + 1) + '%' }" />
                    </div>
                  </div>
                  <h5>{{ index + 1 + '.  ' + questionPreview.question }}</h5>
                  <ul v-if="questionPreview.type ==='standard' && !questionPreview.choices[0].image">
                    <li
                      v-for="(choice, cindex) in questionPreview.choices"
                      :key="cindex"
                    >
                      <span
                        v-if="choice.choice_text"
                        class="d-flex p-2"
                        :class="{ 'is-correct' : choice.is_correct == 1 }"
                      >
                        <span class="optiontick">
                          {{ $helpers.choiceLetter(cindex) }}
                        </span>
                        {{ choice.choice_text }}
                      </span>
                    </li>
                  </ul>
                  <ul
                    v-if="questionPreview.type ==='standard' && questionPreview.choices[0].image"
                    class="row"
                  >
                    <li
                      v-for="(choice, qcindex) in questionPreview.choices"
                      :key="qcindex"
                      class="col-6 px-1"
                    >
                      <picture
                        class="d-flex flex-column"
                        :class="{ 'is-correct' : choice.is_correct == 1 }"
                      >
                        <img
                          :src="choice.image"
                          alt="Choice Image"
                        >
                        <div
                          v-if="choice.choice_text"
                          class="d-flex p-2"
                          style="mix-blend-mode: difference; font-size: 11px"
                        >
                          {{ choice.choice_text }}
                        </div>
                      </picture>
                    </li>
                  </ul>
                  <ul
                    v-if="questionPreview.type ==='pairs'"
                    id="pair-choices"
                    class="row"
                  >
                    <li
                      v-for="(choice, qpindex) in questionPreview.choices"
                      :key="qpindex"
                    >
                      <span v-if="choice.choice_text">
                        {{ choice.choice_text }}
                      </span>
                    </li>
                  </ul>
                  <div class="quiz-preview--button">
                    <button
                      type="button"
                      class="button button--secondary size-sm"
                      @click="previewQuestionNext(index)"
                    >
                      {{ $t('next') }}
                    </button>
                  </div>
                </div>
                <div class="quiz-preview--question">
                  <div class="quiz-finalpage">
                    <div class="d-flex final-score flex-column justify-content-center">
                      <span class="score-point">{{ $t('score_point') }}</span>
                      <div class="your-score">
                        {{ $t('your_score') }}
                      </div>
                    </div>
                    <div class="completed-text text-center mt-4">
                      {{ $t('quiz_completed') }}
                    </div>
                    <div class="d-flex justify-content-center text-center mt-4">
                      {{ $t('thanks_feedback') }}
                    </div>
                  </div>
                  <div class="quiz-preview--button">
                    <button
                      type="button"
                      class="button button--secondary size-sm"
                      @click="previewQuestionNext(-1)"
                    >
                      {{ $t('close') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="contentType === 'KEY_CONTACT_MANAGER'"
              class="mobile-content__onboarding"
            >
              <div class="onboarding-preview--container">
                <div class="d-flex">
                  {{
                    $t("keycontacts_report", {
                      name: (content && content.target_user && content.target_user.first_name) ? content.target_user.first_name:'',
                    })
                  }}
                </div>
                <div class="px-0 subfont">
                  {{
                    $t("keycontacts_report_desc", {
                      name: (content && content.target_user && content.target_user.first_name) ? content.target_user.first_name:'',
                    })
                  }}
                </div>
                <div
                  class="contacts_block mx-0 p-0"
                  v-for="personitem in content.manager_contacts"
                  :key="personitem.id"
                >
                  <div class="d-flex">
                    <div class="checkbox-item ml-2 mt-2">
                      <input
                        type="checkbox"
                        v-model="personitem.is_checked"
                        :disabled="true"
                        :true-value="1"
                        :false-value="0"
                      >
                    </div>
                    <div class="info-block">
                      <div>
                        {{ personitem.key_contact_title }}
                      </div>
                      <div>
                        {{ personitem.key_contact_info }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="contentType === 'KNOWLEDGE_REQUIREMENT_MANAGER'"
              class="mobile-content__knowledge"
            >
              <div class="knowledge-preview--container">
                <div class="d-block">
                  <div class="px-0 pagefont">
                    {{ $t("knowledge_newhire_questions_for_manager", {name: (content && content.target_user && content.target_user.first_name) ? content.target_user.first_name:''}) }}
                  </div>
                  <div class="px-0 subfont">
                    {{
                      $t("knowledge_newhire_questions_desc_for_manager", {name: (content && content.target_user && content.target_user.first_name) ? content.target_user.first_name:''})
                    }}
                  </div>
                  <div
                    class="contacts_block clickable"
                    v-for="(requirement, ix) in content.knowledge_requirements"
                    :key="requirement.id"
                    @click="previewKnowledgeReqNext(ix)"
                  >
                    <div
                      class="checkbox-item"
                    >
                      <input
                        type="checkbox"
                        v-model="requirement.is_checked"
                        :disabled="true"
                        :true-value="1"
                        :false-value="0"
                      >
                    </div>
                    <div class="info-block">
                      <div>
                        {{ requirement.knowledge_title }}
                      </div>
                    </div>
                    <div class="arrow-block">
                      <icon-container
                        name="rarrow"
                        height="20"
                        width="20"
                        view-box="0 0 24 24"
                      >
                        <icon-rarrow />
                      </icon-container>
                    </div>
                  </div>
                </div>
              </div>
              <template v-for="(requirementdetail, ind) in content.knowledge_requirements">
                <div
                  class="knowledge-preview--container"
                  :key="'kr' + requirementdetail.id"
                  v-if="previewKnowledgeStep === ind"
                >
                  <div
                    class="goal-block p-2"
                  >
                    <div class="blue-box">
                      {{
                        $t("goal", {
                          index: ind + 1,
                        })
                      }}
                    </div>
                    <div>
                      {{ requirementdetail.knowledge_title }}
                    </div>
                  </div>
                  <div class="my-2 pagefont">
                    {{
                      $t("knowledge_newhire_goal_msg")
                    }}
                  </div>
                  <section
                    v-if="requirementdetail.support_elements && requirementdetail.support_elements.contacts && requirementdetail.support_elements.contacts.length"
                  >
                    <div class="d-flex mt-4">
                      <div
                        class="d-flex pagefont no-wspace"
                      >
                        {{ "A - " }}
                      </div>
                      <div class="m-0 pl-2 pagefont">
                        {{
                          $t("knowledge_newhire_sectiona_title")
                        }}
                      </div>
                    </div>
                    <div
                      class="contact_section p-2 "
                    >
                      <div>
                        {{
                          requirementdetail.support_elements.contacts[0].key_contact_title
                        }}
                      </div>
                      <div>
                        {{
                          requirementdetail.support_elements.contacts[0].key_contact_email
                        }}
                      </div>
                      <div>
                        {{ requirementdetail.support_elements.contacts[0].key_contact_info }}
                      </div>
                    </div>
                  </section>
                  <section
                    v-if="requirementdetail.support_elements && requirementdetail.support_elements.documents && requirementdetail.support_elements.documents.length"
                  >
                    <div class="d-flex mt-4">
                      <div
                        class="d-flex pagefont no-wspace"
                      >
                        {{ requirementdetail.support_elements.contacts.length ? "B - " : "A - " }}
                      </div>
                      <div class="m-0 pl-2 pagefont">
                        {{
                          $t("knowledge_newhire_sectionb_title")
                        }}
                      </div>
                    </div>
                    <div
                      class="contact_section p-2"
                      v-for="(document, dindex) of requirementdetail.support_elements.documents"
                      :key="`documents${document.id}${dindex}`"
                    >
                      <div
                        class="clickable"
                        v-if="$helpers.isValidUrl(document.document_url)"
                      >
                        <a
                          :href="document.document_url"
                          target="_blank"
                        >{{ document.document_url }}</a>
                      </div>
                      <div
                        v-else
                      >
                        {{
                          document.document_url
                        }}
                      </div>
                    </div>
                  </section>
                  <section
                    v-if="requirementdetail.support_elements && requirementdetail.support_elements.assignments && requirementdetail.support_elements.assignments.length"
                  >
                    <div class="d-flex mt-4">
                      <div
                        class="d-flex pagefont no-wspace"
                      >
                        {{ (requirementdetail.support_elements.contacts.length && requirementdetail.support_elements.documents.length) ? "C - " :
                          (requirementdetail.support_elements.contacts.length === 0 &&
                            requirementdetail.support_elements.documents.length === 0) ? "A - ": "B - " }}
                      </div>
                      <div class="m-0 pl-2 pagefont">
                        {{
                          $t("knowledge_newhire_sectionc_title")
                        }}
                      </div>
                    </div>
                    <div
                      class="contact_section p-2"
                      v-for="(assignment, aindex) of requirementdetail.support_elements.assignments"
                      :key="`assignments${assignment.id}${aindex}`"
                    >
                      <div>
                        {{
                          assignment.requirement_title
                        }}
                      </div>
                    </div>
                  </section>
                  <div
                    class="d-flex justify-space-between align-content-center ml-10 btnspacer"
                  >
                    <button
                      type="button"
                      class="button button--secondary size-sm"
                      @click="previewKnowledgeReqNext(-1)"
                    >
                      {{ $t("continue_") }}
                    </button>
                  </div>
                </div>
              </template>
            </div>
            <div
              v-if="contentType === 'BUDDY_MANAGER'"
              class="mobile-content__onboarding"
            >
              <div class="onboarding-preview--container">
                <div class="d-flex">
                  {{ $t('buddy_newhire_entry_msg_for_manager',
                        {
                          name: (content && content.target_user && content.target_user.first_name) ? content.target_user.first_name:'',
                          buddy: (content && content.manager_contacts && content.manager_contacts.length) ? content.manager_contacts[0].key_contact_title.split(' ')[0]:'',
                        }) }}
                </div>
                <div class="contacts_block mx-0 p-0">
                  <div class="ma-2 px-2">
                    {{ content.manager_contacts[0].key_contact_title }}
                  </div>
                  <div class="ma-2 px-2">
                    {{ content.manager_contacts[0].key_contact_info }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="contentType === 'FILE_DOWNLOAD'"
              class="mobile-content__filedownload"
            >
              <div class="title">
                {{ content.desc }}
              </div>
              <ul class="mt-4">
                <template v-for="(filedownloaditem, index) in content.file_download_content.files">
                  <li

                    v-if="filedownloaditem.title"
                    :key="index"
                    class="filedownload-item"
                    @click="openFileDownloadItem(filedownloaditem)"
                  >
                    <div class="filedownload-item-content">
                      <div
                        class="filedownload-item--title"
                        v-if="filedownloaditem.title"
                      >
                        {{ filedownloaditem.title }}
                      </div>
                      <div
                        class="filedownload-item--desc"
                        v-if="filedownloaditem.desc"
                      >
                        {{ filedownloaditem.desc }}
                      </div>
                    </div>
                    <div class="filedownload-item--icon">
                      <icon-container
                        name="downloadfull"
                        class="icon-svg"
                        width="13"
                        height="14"
                        :is-icon-class="false"
                      >
                        <icon-download />
                      </icon-container>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
            <div
              v-if="contentType === 'CHECKLIST' && content.checklist.items.length > 0"
              class="mobile-content__checklist"
            >
              <div class="title">
                {{ content.name }}
              </div>
              <ul>
                <li
                  v-for="(checklistItem, index) in content.checklist.items"
                  :key="index"
                >
                  <span v-if="checklistItem.item_text">{{ checklistItem.item_text }}</span>
                </li>
              </ul>
            </div>
            <div
              v-if="contentType === 'SURVEY' && content.survey.questions.length > 0"
              class="mobile-content__survey"
            >
              <div class="survey-preview--container">
                <div
                  class="survey-preview--question"
                  v-for="(surveyQuestion, index) in content.survey.questions"
                  :key="index"
                >
                  <div class="question-content">
                    <h5>{{ index + 1 + '.  ' + surveyQuestion.question }}</h5>
                    <p class="subfont">
                      {{ surveyQuestion.sub_text }}
                    </p>
                    <ul
                      v-if="surveyQuestion.type === 'multiple-choice' || surveyQuestion.type === 'single-choice'"
                      :class="{'haveImages': $helpers.haveSurveyQuestionImages(surveyQuestion.choices)}"
                    >
                      <li
                        v-for="(choice, ssindex) in surveyQuestion.choices"
                        :key="ssindex"
                        class="survey"
                      >
                        <picture
                          class="d-flex flex-column"
                          v-if="choice.imagePreview || choice.image"
                        >
                          <span class="d-flex flex-row">
                            <span
                              class="optiontick"
                            >
                              {{ $helpers.choiceLetter(ssindex) }}
                            </span>
                            <img
                              :src="choice.imagePreview || choice.image"
                              alt="Choice Image"
                            >
                          </span>
                        </picture>
                        <span
                          v-else
                          class="d-flex p-2"
                        >
                          <span class="optiontick">
                            {{ $helpers.choiceLetter(ssindex) }}
                          </span>
                          {{ choice.choice_text }}
                        </span>
                      </li>
                      <li
                        v-if="surveyQuestion && surveyQuestion.options && surveyQuestion.options.has_other_option"
                        class="survey"
                        :key="`other${surveyQuestion.id}`"
                      >
                        <span class="d-flex p-2">
                          <span class="optiontick">{{ $helpers.choiceLetter(surveyQuestion.choices.length) }}
                          </span>
                          {{ $t('other') }}
                        </span>
                      </li>
                    </ul>
                    <div
                      v-if="surveyQuestion.type === 'text'"
                      class="survey-textarea"
                    >
                      <textarea
                        name=""
                        id=""
                        :placeholder="$t('type_here')"
                      />
                    </div>
                    <div v-if="surveyQuestion.type === 'rating'">
                      <template v-if="surveyQuestion.options.shape === 'star'">
                        <span
                          class="rating-box"
                        >
                          <template v-for="sindex in Number(surveyQuestion.options.step)">
                            <img

                              :key="`star${index}${sindex}`"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA5CAYAAABj2ui7AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM3SURBVHgB3VqBdeIwDBX3bgA2uIzABucNSidoNigbwE1Ab4LABu0EoRPQDdIN6AaqUuQXxYTEEMsJ/Pf8BLZjWfa3ZSsBGACIOIVI+AXDYAH3Cpq9OaUDRMIQM/hAaUpGziAChjDQOPJ+QLNmsEIO9wYyKsM61HfT2BQ1LL+c/2qIZiBvKgmlT0r/OdvAvYAMfGFaZmItRnMX6iBj9myU4f8H/h/FXaiCjEjcGaPfG85TPdXEWoNzlq8ib8fyAW4dpc/j2ZqLvGlMd6EGQc8TQ5oMD40YFDUs3yaTyZdT9ubUuT2IWUobymY37S6cdZacqaPqLrQpatfWjuj5eabOlqUBBWgb+JfltqXOjuXtuQtBv6Slzm26C3He3HvUzbXcxW9WkJJYQljY2dh61C3dhaGUUV/WoIHSSEoFhkfn7oiVuwiJcnnMJ46ihEQZSkg4a0PpH/RAy+4JDbr7oDy0P/PvD0qPZ3WTspUYiQxHvPjxuEllor8vvg+mWO2CRYARDg6mdiEomV7yvD0oF2J0RhORpr48iwnY95oArMINg1O2iZJB+jMGyjqMupySVyiIRlmHknoDzBSRlF1rUpb1rYNT0kPxQntEh2TMuQ6kEAjU1hOOwU01UDaFnsDjO0SLDY7hoCFG20BPYHUTKWAMwIbAboA27YD9gR4IdaM3LN8hHGzE7RF6IJSBTyxfIRx2LIcPZYgNIYFAwCqUcRh0k8ELQhNXtJ333bhCUNTS02v9sQvIPGfbtqkW2u+EcPTGo648chVdRg7uLoR7KDzq5Y5xdn0tOp4N4i6uAh6vUCWyljoG60cuw/m1A3vL8xuuE/+yjS0vVrh8KYzI8fT12UKUN97KxSDG/65GdC5x8l1KLlrakAf2Us6c8mHcBVYbQN6QX7iU7GjLHZClU27LOtsKBqziIiuRt8Q6JRO4AHgmXCny/UKCISBmyTCNctG5FVwJ5M8tBQMSjO0uULiHayjp2b5t8+cijTHdBVY72wF7ULJDR8mKjWjf6tJ3F1ino+rawPq6/BlI0IYzqgaUgachej13IRZ8HpKSHnrLdVn73k1LUYYxt+tT/StV/TiCrwPxglfd33vRYRyPRxhZAAAAAElFTkSuQmCC"
                              alt="star"
                            >
                          </template>

                        </span>
                      </template>
                      <template v-if="surveyQuestion.options.shape === 'thumb' || surveyQuestion.options.shape === 'thumbsup'">
                        <span
                          class="rating-box"
                        >
                          <template v-for="tindex in Number(surveyQuestion.options.step)">
                            <img
                              :key="`thumb${index}${tindex}`"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALtSURBVHgB1VrbddswDIV6+h+PwA3qDaJu4E5gd4K6E0SdoO4EdiaIO4G1gesJ7A2cDVBCAiMI1suJHuQ9hw5JUQouCQIgJICJgYhzWw6Y42rL1hYDIYEEZuE1zkGREStBODIBSWYGvoNXw2Ev+l9EfwK+wwq5FgIvRP9MqNsZfIdSq5m6thPX5l2e9wkmAAseczONouhVDUlF3V8iFgtR/1tx/Z+oG+iAqYg8ifoeesDoRKxaEQnDzZ1Vq0vFMLlnXsE3WBJLsYmvdU6vzqJ5AUWCsG4YK32JAV9A6qRIJA1jpaM8gi+4hwSP34qxK/ABVpDfisSqZbxcDT+8uloJ2tjzlvEUmpy9Wg28Dc9bvbNSqS34ACVU0mF8LFUKfbBUVojFvXquiMfwDkTQM1h4w81v1nO3hiD2nhco4q+0ZTh5eorP0pqo4ONQG7yzntPGxvtxxiEMApZNZ2340ULGHXebisYT9An1TxIYCDxhiSITQx/AskqN4siwrI4HeA94VlxZqtkxMBJQ5MM+i84Y8mOlTsGQldgrC1E3678GsyTVcGeVXGa8DeywaVPVXN/ByJArEmFuwrbM7hluT2TGliXXf9oZ39Cd3P5jy4YqI68EsAo7zUip48isVg03LeSmEquQwEQQcueyu1rLTTM5zhMiDldqd0o+VOSdfEDKf2mSV1Olg/rAd1FfBEuEjcuFm19CXhGC83mXYImwX3NETp2sFt/ojdXC/HWdhAl1RWT2MQuLQiUis/WZegVJhI/PzrcZ+gnZarmN/kA/QRJRceHJdQZltbCcbsosFvWHuCI/RP3tIBcikZOov+XMQiRS+aKUiGRmDBs+l8AioaDD+QcYH4+iXsiDxbn3wK7fqEJ97jS25XskzjhS5oRlqfwqImIh6AhrWp5zseUrba4aK5dCEVYPgTmUPx6gjZ64RpbEZjLU+VjzEEoab5yFwCKvG0PHF/o949nKsoK+gPlZfo3lRMBQIJU6YE2S5D/iNRDHqdhCtAAAAABJRU5ErkJggg=="
                              alt="thumb"
                            >
                          </template>

                        </span>
                      </template>
                      <template v-if="surveyQuestion.options.shape === 'number'">
                        <div class="d-block">
                          <span class="rating-number-box">
                            <span
                              v-for="(notusedindex,nindex) in Number(surveyQuestion.options.step) + 1"
                              :key="`mum${nindex}`"
                              class="number-cell"
                            >
                              {{ nindex }}
                            </span>
                          </span>
                          <div class="rating-number-info">
                            <span>{{ surveyQuestion.options.min_value }}</span>
                            <span>{{ surveyQuestion.options.max_value }}</span>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div class="survey-preview--button">
                      <button
                        type="button"
                        class="button button--secondary size-sm"
                        @click="previewQuestionNext(index)"
                      >
                        {{ $t('next') }}
                      </button>
                    </div>
                  </div>
                  <div class="survey-preview--progress">
                    <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                    <span>{{ (content && content.survey && content.survey.questions) ? parseInt(((100 / content.survey.questions.length) * (index + 1) ), 10) + '% ' + $t('completed_') : '' }}</span>
                    <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                    <div class="survey-progress-bar">
                      <span :style="{ width: (content && content.survey && content.survey.questions) ? parseInt(((100 / content.survey.questions.length) * (index + 1) ), 10) + '%':'100%' }" />
                    </div>
                  </div>
                </div>
                <div class="survey-preview--question">
                  <div class="survey-finalpage">
                    <div
                      class="d-flex justify-content-center text-center mt-4"
                    >
                      {{ $t('thanks_feedback') }}
                    </div>
                    <div class="survey-preview--button">
                      <button
                        type="button"
                        class="button button--secondary size-sm"
                        @click="previewQuestionNext(-1)"
                      >
                        {{ $t('close') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="contentType === 'REQUEST' && content.request.questions.length > 0"
              class="mobile-content__request"
            >
              <div class="request-preview--container">
                <div
                  class="request-preview--question"
                  v-for="(requestQuestion, index) in content.request.questions"
                  :key="index"
                >
                  <div class="question-content">
                    <h5>{{ `${(index+1)}. `+ requestQuestion.question }}</h5>
                    <p class="subfont ml-3">
                      {{ requestQuestion.sub_text }}
                    </p>
                    <div
                      v-if="requestQuestion.type === 'get_info'"
                      class="request-info-input ml-2"
                    >
                      <span>{{ convertFormat(requestQuestion.options.format) }}</span>
                    </div>
                    <div
                      v-if="requestQuestion.type === 'image' || requestQuestion.type === 'file'"
                      class="imagefile-box"
                    >
                      <span
                        v-html="'+'"
                      />
                    </div>
                    <div class="request-preview--button">
                      <button
                        type="button"
                        class="button button--secondary size-sm"
                        @click="previewQuestionNext(index)"
                      >
                        {{ $t('next') }}
                      </button>
                    </div>
                  </div>
                  <div class="request-preview--progress">
                    <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                    <span>{{ (content.request.questions) ? parseInt(((100 / content.request.questions.length) * (index + 1) ), 10) + '% ' + $t('completed_') : '' }}</span>
                    <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                    <div class="request-progress-bar">
                      <span :style="{ width: (content.request.questions) ? parseInt(((100 / content.request.questions.length) * (index + 1) ), 10) + '%':'100%' }" />
                    </div>
                  </div>
                </div>
                <div class="request-preview--question">
                  <div class="request-finalpage">
                    <div
                      class="d-flex justify-content-center text-center mt-4"
                    >
                      {{ $t('thanks_feedback_info') }}
                    </div>
                    <div class="request-preview--button">
                      <button
                        type="button"
                        class="button button--secondary size-sm"
                        @click="previewQuestionNext(-1)"
                      >
                        {{ $t('close') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mobile-context"
              :style="showVideo ? 'z-index: -2': ''"
              v-if="contentType !== 'INFOGRAPHIC' && contentType !== 'CHECKLIST' && contentType !== 'PODCAST' && contentType !== 'FILE_DOWNLOAD' && contentType !== 'FLIPBOOK' && contentType !== 'KNOWLEDGE_REQUIREMENT_MANAGER' && contentType !== 'BUDDY_MANAGER' && contentType !== 'KEY_CONTACT_MANAGER' && !showVideo"
            >
              <div
                class="mobile-content__media"
                :style="{'background-image': 'url(' + content.thumbnail_image + ')'}"
              >
                <div
                  v-if="contentType === 'VIDEO' && content.content_url"
                  class="videocontrols"
                >
                  <button @click="play">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      style="fill:#fff;fill-opacity:0.5"
                    ><path d="M20.2 11.9L6.9 5.3C6.5 5.1 6 5.1 5.6 5.3 5.2 5.5 5 6 5 6.4L5 19.6C5 20 5.2 20.5 5.6 20.7 5.8 20.8 6.1 20.9 6.3 20.9 6.5 20.9 6.7 20.8 6.9 20.7L20.2 14.1C20.6 13.9 20.9 13.5 20.9 13 20.9 12.5 20.6 12.1 20.2 11.9Z" /></svg>
                  </button>
                </div>
                <small
                  v-if="contentType !== 'INFOGRAPHIC' && contentType !== 'FLIPBOOK'"
                  class="mobile-content__info"
                >
                  {{ content.info }}
                </small>
              </div>
              <small
                class="mobile-content__type"
              >
                {{ $helpers.getSubTypeName(content.sub_type) }}
              </small>
              <h2
                class="mobile-content__title"
                v-show="!showVideo"
                v-if="contentType !== 'INFOGRAPHIC' && contentType !== 'FLIPBOOK' && contentType !== 'BOOK_SUGGESTION' && contentType !== 'QUOTES'"
              >
                {{ content.name }}
              </h2>
            </div>
            <div
              v-show="!showVideo"
              class="mobile-content__main"
              :style="showVideo ? 'z-index: -2': ''"
              v-if="contentType !== 'INFOGRAPHIC' && contentType !== 'CHECKLIST' && contentType !== 'FILE_DOWNLOAD' && contentType !== 'FLIPBOOK' && contentType !== 'PODCAST' && contentType !== 'KNOWLEDGE_REQUIREMENT_MANAGER' && contentType !== 'BUDDY_MANAGER' && contentType !== 'KEY_CONTACT_MANAGER'"
            >
              <p>{{ content.desc }}</p>
            </div>
            <div
              class="mobile-content__main"
              v-if="contentType === 'ARTICLE'"
            >
              <iframe
                v-if="injectedArticleHtml"
                title="Article"
                id="articleIframe"
                width="270"
                :srcdoc="injectedArticleHtml"
                v-resize
              />
            </div>
            <div
              class="notification-content"
              v-if="contentType === 'notification'"
            >
              <img
                src="@/assets/img/notif_background.png"
                alt=""
              >
              <div class="notification-content__time">
                {{ notification_preview_time }}
              </div>
              <div class="notification-content__date">
                {{ notification_preview_date }}
              </div>
              <div class="notification-content__info">
                <div class="notification-content__info-content">
                  <div class="notification-content__info-header">
                    <img
                      src="@/assets/img/journey-app-icon.png"
                      alt=""
                    >{{ $t('snotification_preview') }}
                  </div>
                  <div class="notification-content__info-title">
                    {{ notification_title }}
                  </div>
                  <div class="notification-content__info-body">
                    {{ notification_body }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="add-action-button"
          v-if="launchedFrom === 'ContentLibrary' && !isDisabled"
        >
          <button
            type="button"
            class="button button--alternative button--icon u-full"
            @click.prevent="addContentItem()"
          >
            <icon-container
              name="circleplus"
              color="#fff"
              width="18"
              height="18"
              view-box="0 0 24 24"
              :is-icon-class="false"
            >
              <icon-circleplus />
            </icon-container>
            {{ $t('add_to_journey') }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';
import iFrameResize from 'iframe-resizer/js/iframeResizer';
import moment from 'moment';
import FlipBlock from '@/components/FlipBlock.vue';
import API from '@/services/';
import {
  API_GET_CONTENT_INFO, CORS_PROXY_URL, API_SERVER_TIME_FORMAT,
} from '@/helpers/config';

import IconRarrow from '@/components/icons/Rarrow.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconFlag from '@/components/icons/MobilePreviewFlag.vue';
import IconCirclePlus from '@/components/icons/CirclePlus.vue';
import IconDownload from '@/components/icons/Download.vue';
import IconEdit from '@/components/icons/Edit.vue';

Vue.directive('resize', {
  inserted(el) {
    iFrameResize({
      checkOrigin: false,
      log: false,
    }, `#${el.id}`);
  },
});
/* eslint-disable no-param-reassign */
export default {
  name: 'ModalPreviewContent',

  data() {
    return {
      content: {},
      contentType: null,
      flipImages: [],
      showVideo: false,
      showControl: false,
      videoElement: null,
      podcastPreview: null,
      podcastElement: null,
      podcastDuration: null,
      currentPodcastProgressBar: 0,
      currentPodcastTime: 0,
      podcastIsPlaying: false,
      checkingCurrentPositionInTrack: '',
      paused: null,
      articleHtml: null,
      injectedArticleHtml: '',
      showExternalLink: false,
      beginActivationDateTime: null,
      notification_title: '',
      notification_body: '',
      previewKnowledgeStep: 0,
    };
  },

  components: {
    'flip-book': FlipBlock,
    'icon-container': IconContainer,
    'icon-flag': IconFlag,
    'icon-rarrow': IconRarrow,
    'icon-download': IconDownload,
    'icon-circleplus': IconCirclePlus,
    'icon-edit': IconEdit,
  },

  watch: {
    content(newContent) {
      if (newContent) {
        if (newContent.sub_type === 'INTERNAL_VIDEO') {
          this.contentType = 'VIDEO';
        } else if (newContent.sub_type === 'INTERNAL_ARTICLE') {
          this.contentType = 'ARTICLE';
        } else if (newContent.sub_type === 'EXTERNAL_VIDEO' || newContent.sub_type === 'EXTERNAL_ARTICLE' || newContent.sub_type === 'LEARNING_PATH') {
          this.contentType = 'EXTERNAL_URL';
        } else if (newContent.sub_type === 'BOOK_SUGGESTION' || newContent.sub_type === 'QUOTES') {
          this.contentType = 'BOOK_SUGGESTION';
        } else {
          this.contentType = newContent.sub_type;
        }
        if (newContent.flipboard_items && newContent.flipboard_items.length > 0) {
          newContent.flipboard_items.forEach((images) => {
            if (images && images.content_url) {
              this.flipImages.push(images.content_url);
            }
          });
        }

        if (newContent.content_url && newContent.content_url.lastIndexOf('.html') > -1) {
          this.getHtmlContent(newContent.content_url);
        }
        if (newContent.content_url && (newContent.content_url.lastIndexOf('.mp3') > -1 || newContent.content_url.lastIndexOf('.wav') > -1)) {
          this.podcastPreview = newContent.content_url;
        }
        if (this.contentType === 'QUIZ' && newContent && newContent.quiz && newContent.quiz.questions.length > 0) {
          newContent.quiz.questions.map((question) => {
            if (question.type === 'pairs') {
              Object.assign(question.choices, this.$helpers.sortQuizPairChoices(question.choices));
            }
            return question;
          });
        }
        if (newContent.title) {
          this.beginActivationDateTime = moment(newContent.notify_dt, API_SERVER_TIME_FORMAT).toDate();
          this.notification_title = newContent.title;
          this.notification_body = newContent.body;
          this.contentType = 'notification';
        }
      }
    },
    currentPodcastTime() {
      this.currentPodcastTime = Math.round(this.currentPodcastTime);
    },
    podcastPreview: 'podcastInit',
    articleHtml() {
      this.injectedArticleHtml = this.iframeInjector(this.articleHtml);
    },
  },
  filters: {
    timeFormat(s) {
      // eslint-disable-next-line no-return-assign
      return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s;
    },
  },
  computed: {
    playing() {
      return !this.paused;
    },

    content_itemid() {
      return this.$store.state.modal.contentId;
    },

    journeyTotalUser() {
      return this.$store.state.modal.journeyDetail.mobile_device_penetration.number_of_total_users;
    },
    isJourneyTypeV2() {
      return this.$store.state.modal.isJourneyTypeV2;
    },
    launchedFrom() {
      return this.$store.state.modal.launchedFrom;
    },
    currentViewMode() {
      return this.$store.state.auth.currentViewMode;
    },
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },

    isDisabled() {
      return this.$store.state.modal.isDisabled;
    },

    notification_preview_time() {
      return this.$helpers.getUserLangBasedDateTimeFormat(this.beginActivationDateTime, this.userLang, 'time');
    },

    notification_preview_date() {
      return this.$helpers.getUserLangBasedDateTimeFormat(this.beginActivationDateTime, this.userLang, 'snotif');
    },
  },

  created() {
    if (this.launchedFrom === 'calendar' && this.$store.state.modal.previewContent) {
      this.content = this.$store.state.modal.previewContent;
    } else {
      this.getContent();
    }
  },
  deactivated() {
    this.podcastDestroy();
  },
  beforeDestroy() {
    this.podcastDestroy();
  },
  methods: {
    podcastInit() {
      this.podcastElement = new Audio(this.podcastPreview);
      this.podcastElement.loop = false;
      const self = this;
      // eslint-disable-next-line func-names
      this.podcastElement.addEventListener('loadedmetadata', function () {
        self.podcastDuration = Math.round(this.duration);
      });
      this.podcastElement.addEventListener('ended', this.handlePodcastEnd);
    },
    podcastDestroy() {
      if (this.podcastElement) {
        this.podcastElement.pause();
        this.podcastElement.removeEventListener('ended', this.handlePodcastEnd);
        this.podcastElement.removeEventListener('loadedmetadata', this.handlePodcastEnd);
        clearTimeout(this.checkingCurrentPositionInTrack);
      }
    },
    togglePodcast() {
      if (this.podcastElement) {
        if (this.podcastIsPlaying) {
          this.podcastElement.pause();
          clearTimeout(this.checkingCurrentPositionInTrack);
          this.podcastIsPlaying = false;
        } else {
          this.podcastElement.play();
          this.getCurrentTimeEverySecond(true);
          this.podcastIsPlaying = true;
        }
      }
    },
    handlePodcastEnd() {
      this.podcastIsPlaying = false;
      this.checkingCurrentPositionInTrack = '';
    },
    /* eslint-disable-next-line no-unused-vars */
    getCurrentTimeEverySecond(startStop = false) {
      const self = this;
      this.checkingCurrentPositionInTrack = setTimeout(
        () => {
          self.currentPodcastTime = self.podcastElement.currentTime;
          self.currentPodcastProgressBar = (self.podcastElement.currentTime / self.podcastDuration) * 100;
          if (self.currentPodcastProgressBar > 100) {
            self.currentPodcastProgressBar = 100;
          }
          self.getCurrentTimeEverySecond(true);
        },
        1000,
      );
    },
    backwardPodcast() {
      const backTime = this.currentPodcastTime - 15;
      if (backTime > 0) {
        const perc = Math.round((backTime / this.podcastDuration) * 100);
        this.podcastSetVal(backTime, perc);
      } else {
        this.podcastSetVal(backTime, 0);
      }
    },

    forwardPodcast() {
      const forwardTime = this.currentPodcastTime + 15;
      if (forwardTime <= this.podcastDuration) {
        const perc = Math.round((forwardTime / this.podcastDuration) * 100);
        this.podcastSetVal(forwardTime, perc);
      }
    },

    podcastSeek(e) {
      if (this.podcastElement && this.$refs.progressBar) {
        const bounds = this.$refs.progressBar.getBoundingClientRect();
        const max = bounds.width;
        const pos = e.pageX - bounds.left;
        const posr = Math.round((pos / max) * 100);
        const seektime = (this.podcastDuration * posr) / 100;
        this.podcastSetVal(seektime, posr);
      }
    },
    podcastSetVal(time, percent) {
      this.podcastElement.currentTime = time;
      this.currentPodcastTime = time;
      this.currentPodcastProgressBar = percent;
    },
    closeModal() {
      if (this.launchedFrom === 'calendar') {
        this.$store.state.modal.showPreview = false;
      } else {
        this.$store.commit('modal/togglePreviewModal');
      }
    },

    addContentItem() {
      if (this.isJourneyTypeV2) {
        this.$store.commit('modal/togglePreviewModal');
        this.$store.commit('modal/setPageToRefresh', 'journeyitemsv2');
        this.$helpers.toggleModal('modalDateTimeV2');
        return;
      }
      const self = this;
      this.content.options = this.content_itemid;
      this.content.canIRefresh = true;
      this.content.is_email_enabled = 1;
      this.content.is_notifications_enabled = 1;
      this.content.is_info_labels_hidden = 0;
      this.$store.commit('modal/setPageToRefresh', 'journeyitems');
      const successMsg = this.$t('content_add_success');

      if (this.journeyTotalUser > 0 || this.currentViewMode === 'weekly' || this.currentViewMode === 'daily') {
        this.closeModal();
        setTimeout(() => this.$helpers.toggleModal('modalDateTime'), 1000); // wait for the preiview modal closing..
      } else {
        this.$store.dispatch('modal/ADD_CONTENT_ITEM_TO_JOURNEY', this.content).then((response) => {
          if (!self.formError) {
            self.$root.$emit('newItemDropFromLibrary', response);
            self.$snotify.success(successMsg);
            this.closeModal();
          } else {
            self.formError = false;
          }
        });
      }
    },
    editContentItem() {
      this.$root.$emit('editContentItem', this.content_itemid);
    },
    convertFormat(data) {
      if (!data) return '';
      return data.replaceAll('_', '-').replaceAll('#', '_');
    },
    async getHtmlContent(contenturl) {
      if (contenturl) {
        const timestamp = new Date().getTime();
        const theURL = `${CORS_PROXY_URL}${contenturl}?${timestamp}`;
        const html = await fetch(theURL).then(r => r.text()).catch(() => null);
        if (html) {
          this.articleHtml = html;
        }
      }
    },

    getContent() {
      API.get(`${API_GET_CONTENT_INFO}/${this.content_itemid}`)
        .then((response) => {
          if (response.status === 200) {
            this.content = response.data;
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    updatePaused(event) {
      this.videoElement = event.target;
      this.paused = event.target.paused;
    },

    play() {
      this.showVideo = true;
      const el = this.$el.getElementsByClassName('modal')[0];
      el.scrollIntoView();
      let videoElement = null;
      setTimeout(() => {
        videoElement = document.getElementById('videoElement');
        if (videoElement) {
          videoElement.autopictureinpicture = true;
        }
      }, 300);


      setTimeout(() => {
        if (el.scrollWidth > el.offsetWidth) {
          el.setAttribute('style', 'overflow:scroll;');
          el.scrollLeft = el.scrollWidth - el.offsetWidth;
          el.scrollTop = 0;
          if (videoElement) {
            videoElement.autopictureinpicture = false;
          }
        }
      }, 1200);
    },

    exitVideo() {
      this.showVideo = false;
      const el = this.$el.getElementsByClassName('modal')[0];
      el.setAttribute('style', 'overflow:hidden;');
    },
    openFileDownloadItem(item) {
      if (item && item.url) {
        const win = window.open(item.url, '_blank');
        win.focus();
      }
    },
    openExternalLink() {
      const url = this.$helpers.addhttp(this.content.external_url);
      const win = window.open(url, '_blank');
      win.focus();
    },

    iframeInjector(htmlString) {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(htmlString, 'text/html');
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.1.1/iframeResizer.contentWindow.min.js';
      const f = document.createElement('style');
      f.type = 'text/css';
      f.innerHTML = '* {'
                        + 'font-size: 0.9rem!important;line-height: 145%!important;'
                      + '}';
      htmlDoc.head.append(s);
      htmlDoc.body.append(s);
      htmlDoc.body.append(f);
      return htmlDoc.body.innerHTML;
    },

    previewQuestionNext(index) {
      const previewContainer = document.querySelector('.quiz-preview--container') || document.querySelector('.survey-preview--container') || document.querySelector('.request-preview--container');
      const previewQuestion = document.querySelector('.quiz-preview--question') || document.querySelector('.survey-preview--question') || document.querySelector('.request-preview--question');
      const previewQuestionsLength = document.querySelectorAll('.quiz-preview--question').length || document.querySelectorAll('.survey-preview--question').length || document.querySelectorAll('.request-preview--question').length;
      const previewQuestionWidth = previewQuestion.offsetWidth;
      const $index = index + 1;
      if (previewQuestionsLength === $index) {
        previewContainer.style.transform = 'translateX(0)';
      } else {
        previewContainer.style.transform = `translateX(-${$index * previewQuestionWidth}px)`;
      }
      this.$helpers.randomizeList();
    },

    previewKnowledgeReqNext(index) {
      this.previewKnowledgeStep = index;
      const knowledgePreviewContainer = document.querySelector('.mobile-content__knowledge');
      const knowledgePreviewQuestionWidth = document.querySelector('.knowledge-preview--container').offsetWidth;
      const $index = index + 1;
      if ($index === 0) {
        knowledgePreviewContainer.style.transform = 'translateX(0)';
      } else {
        knowledgePreviewContainer.style.transform = `translateX(-${knowledgePreviewQuestionWidth + 20}px)`;
      }
      knowledgePreviewContainer.scrollIntoView();
    },
  },
};
</script>
<style lang="scss" scoped>
video {
  position: fixed;
  transform: rotate(90deg);
  margin: 122px 0px 0px -159px;
  width: 536px;
  height: 252px;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 8%;
 }
// .videocontent {
//   // position: relative;
//   // width: 536px;
//   // height: 252px;
//   // top:0;
//   // left:0;
//   // z-index: -1;
// }
.videocontrols {
  position: absolute;
  top: calc(50% - 1em);
  left: calc(50% - 1em);
  background-color: rgba(0,0,0,.45);
  border-radius: 50%;
  font-size: 3em;
  height: 2em!important;
  width: 2em!important;
  line-height: 2em!important;
  text-align: center;
  z-index: 10;
}

.videocontrols button svg {
  position: absolute;
  width: 2em;
  height: 2em;
  top: calc(50% - 1em);
  left: calc(50% - 0.9em);
}
.videopausecontrol {
  position: absolute;
  bottom: 20px;
  right: 10px;
  background-color: rgba(0,0,0,.45);
  border-radius: 50%;
  font-size: 1em;
  height: 1.7em!important;
  width: 1.7em!important;
  line-height: 1em!important;
  text-align: center;
  z-index: 1000!important;
}
.videopausecontrol button svg {
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: calc(50% - 0.75em);
  left: calc(50% - 0.75em);
  z-index: 1000!important;
}

.mobile-content__main {
  position: absolute!important;
  padding-right: 25px;
  p {
    font-family: Helvetica, Arial, sans-serif !important;
    font-size: 0.9rem!important;
  }
}
.mobile-content__title {
  font-family: Helvetica, Arial, sans-serif !important;
}
.modal .mobile-content {
  overflow-y: auto!important;
  font-family: Helvetica, Arial, sans-serif !important;
  z-index: 0;
}

.modal .mobile-content::-webkit-scrollbar {
    width: 0px;
}

.modal .mobile-content::-webkit-scrollbar-track {
    display: none;
}

.flag {
  position: sticky!important;
  margin-top: -25px!important;
  top: -25px!important;
}

.mobile-content__info {
  position: absolute;
  bottom: 10px;
  left: 15px;
  color: #fff;
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: 400;
}

.infograph-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}

#articleIframe {
  position: absolute;
  top:-8px;
  margin-left: -25px;
  min-height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
  min-width: 100%;
  border: none;
}
.mobile-content__externallink {
  position: absolute;
  bottom: 10px;
  right: 20px;
  border-radius: 25px;
  font-size: 0.8em;
  line-height: initial;
  background-color: #fff;
  color: #256dfb;
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: 600;
  padding: 5px 12px;
}
.survey picture img {
  width: 50px;
  height: 50px;
}
.rotatepreview {
  transform: rotate(-90deg);
  margin-left: 150px!important;
}
.modal__mobile-preview {
  transition: 1s;
  margin: 40px auto 10px!important;
  display: flex;
}

.mobile-content__type {
  font-family: Helvetica, Arial, sans-serif !important;
}
.close {
  color: white;
}

.quiz-preview--question::-webkit-scrollbar,
.mobile-content__checklist::-webkit-scrollbar {
  width: 0px;
}
.quiz-preview--question::-webkit-scrollbar-track,
.mobile-content__checklist::-webkit-scrollbar-track {
  display: none;
}
.survey-preview--question::-webkit-scrollbar,
.mobile-content__checklist::-webkit-scrollbar {
  width: 0px;
}
.survey-preview--question::-webkit-scrollbar-track,
.mobile-content__checklist::-webkit-scrollbar-track {
  display: none;
}
.modal {
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.add-action-button {
  justify-content: center;
  display: flex;
  width: 100%;
  // position: absolute;
  // bottom: -30px;
  button {
    width: 250px;
    margin-left: 20px;
  }

}
.previewclosebutton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 60px;
    position: absolute;
    top: 10px;
    right: 30%;
    .btn {
      cursor: pointer;
    }
}

.notification-content {
  width: 257px;
  height: 538px;
  margin: -20px;
  z-index: 1;
  display: inline-flex;

  &__time {
    z-index:2;
    width: 100%;
    position: absolute;
    top: 75px;
    display: inline-flex;
    justify-content: center;
    font-size: 2.5rem;
    color: #fff;
  }

  &__date {
    z-index:2;
    width: 100%;
    position: absolute;
    top: 130px;
    display: inline-flex;
    justify-content: center;
    font-size: 1.2rem;
    color: #fff;
  }
  &__info {
    z-index:2;
    position: absolute;
    top: 200px;
    display: inline-flex;
    justify-content: flex-start;
    background-color: rgba(#494949, 0.5);
    width: 96%;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    color: #fff;

    &-content {
      display: block;
      position: relative;
    }
    &-header {
      display: block;
      font-size: 0.8rem;
      font-weight: 500;
      margin-bottom: 5px;
      color:#ffffffdd;

      img {
        margin-right: 8px;
        width: 25px;
        border-radius: 5px;
      }
    }
    &-title {
      font-size: 0.9rem;
      font-weight: 700;
      display: block;
    }
    &-body {
      font-size: 0.9rem;
      display: block;
    }
  }
}
::v-deep ul.haveImages {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  li {
    margin: 0 10px 10px 0;
    width: 45%;
    picture span {
    display: flex !important;
    padding: 5px!important;
    flex-direction: column!important;
    img {
      margin-top: 5px;
      width: 100%;
      object-fit: contain;
    }
    }
  }
}
</style>
