var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flipbox draggable", on: { drag: _vm.autoScroll } },
    [
      _c(
        "div",
        { staticClass: "listing form-label button-handle" },
        [
          _c(
            "icon-container",
            {
              attrs: {
                width: "18",
                height: "10",
                "view-box": "0 0 18 12",
                name: "threelines",
                color: "#727d92",
                "is-icon-class": false,
              },
            },
            [_c("icon-threelines")],
            1
          ),
          _vm._v(
            " " + _vm._s(_vm.findex + 1 + _vm.$t("of") + _vm.totalPages) + " "
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flips", attrs: { id: "flip" } },
        [
          _c(
            "v-stage",
            { ref: "stageRef", attrs: { config: _vm.config } },
            [
              _c(
                "v-layer",
                [
                  _c("v-rect", { attrs: { config: _vm.background } }),
                  _c(
                    "v-group",
                    { ref: "coverImageGroupRef" },
                    [
                      _c("v-rect", {
                        attrs: { config: _vm.imagePlaceholderRect },
                      }),
                      _c("v-image", {
                        attrs: { config: _vm.imagePlaceholder },
                      }),
                      _c("v-text", {
                        attrs: { config: _vm.imagePlaceholderText },
                      }),
                    ],
                    1
                  ),
                  _c("v-image", {
                    ref: "coverImageRef",
                    attrs: { config: _vm.coverImage },
                  }),
                  _c("v-rect", {
                    ref: "contentTextRectRef",
                    attrs: { config: _vm.contentTextRect },
                  }),
                  _c("v-text", {
                    ref: "contextTextRef",
                    attrs: { config: _vm.contentText },
                    on: { click: _vm.editText },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.bodyText,
                expression: "bodyText",
              },
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.textBlur ? _vm.hideMe : null,
                expression: "textBlur ? hideMe : null",
              },
            ],
            ref: "flipBodyTextRef",
            staticClass: "edittitle",
            attrs: { tabindex: "-1", maxlength: "350", rows: "15" },
            domProps: { value: _vm.bodyText },
            on: {
              blur: function ($event) {
                _vm.textBlur = true
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.bodyText = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("div", { ref: "counterRef", staticClass: "letter-counter" }, [
            _vm._v(" " + _vm._s(_vm.letterCount) + " "),
          ]),
          _c(
            "div",
            {
              staticClass: "drop display-inline align-center",
              on: {
                dragover: function ($event) {
                  $event.preventDefault()
                },
                drop: _vm.onDrop,
              },
            },
            [
              _c("input", {
                attrs: { tabindex: "-1", type: "file", name: "image" },
                on: { change: _vm.onUpload },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "vloader",
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    version: "1.1",
                    id: "loader-1",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink",
                    viewBox: "0 0 40 40",
                    "enable-background": "new 0 0 40 40",
                    "xml:space": "preserve",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      opacity: "0.2",
                      fill: "#000",
                      d: "M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946\n          s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634\n          c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z",
                    },
                  }),
                  _c(
                    "path",
                    {
                      attrs: {
                        fill: "#000",
                        d: "M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0\n          C22.32,8.481,24.301,9.057,26.013,10.047z",
                      },
                    },
                    [
                      _c("animateTransform", {
                        attrs: {
                          attributeType: "xml",
                          attributeName: "transform",
                          type: "rotate",
                          from: "0 20 20",
                          to: "360 20 20",
                          dur: "0.5s",
                          repeatCount: "indefinite",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "trashIcon", on: { click: _vm.deleteFlipPage } },
        [
          _c(
            "icon-container",
            {
              attrs: {
                width: 20,
                height: 20,
                "view-box": "0 0 24 24",
                name: "trash",
                color: "#727d92",
              },
            },
            [_c("icon-trash")],
            1
          ),
        ],
        1
      ),
      _vm.isFileUploading && !_vm.getUploadStatus(_vm.findex)
        ? _c(
            "div",
            { staticClass: "progressing" },
            [
              _vm.getUploadProgress(_vm.findex) < 101 &&
              !_vm.getUploadStatus(_vm.findex)
                ? _c("progress-bar", {
                    staticClass: "progressbarline",
                    attrs: {
                      "bar-color": "#4a4",
                      size: "small",
                      "font-size": 10,
                      val: _vm.getUploadProgress(_vm.findex),
                      "text-position": "middle",
                      "text-fg-color": "#4558FB",
                      "text-align": "center",
                      text: _vm.$t("rate_info", {
                        rate: _vm.getUploadProgress(_vm.findex),
                      }),
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          key: "drop-placeholder",
          staticClass: "card card--catalog-item drop-placeholder",
        },
        [
          _c("div", { staticClass: "card__body" }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t("drag_drop")))]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }