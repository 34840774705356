var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-pane show active" }, [
    _c(
      "div",
      {
        staticClass: "form-group",
        class: { "is-danger": _vm.errors.has("chapter.title") },
      },
      [
        _c("label", { staticClass: "form-label", attrs: { for: "title" } }, [
          _vm._v(_vm._s(_vm.$t("title").toUpperCase())),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title",
            },
            {
              name: "validate",
              rawName: "v-validate.persist",
              value: "required|max:200",
              expression: "'required|max:200'",
              modifiers: { persist: true },
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            disabled: _vm.disabled,
            "data-vv-scope": "chapter",
            "data-vv-name": "title",
            "data-vv-as": _vm.$t("title"),
          },
          domProps: { value: _vm.title },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.title = $event.target.value
              },
              _vm.handleTitle,
            ],
          },
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("chapter.title"),
                expression: "errors.has('chapter.title')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v(_vm._s(_vm.errors.first("chapter.title")))]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "form-group",
        class: { "is-danger": _vm.errors.has("chapter.description") },
      },
      [
        _c(
          "label",
          { staticClass: "form-label", attrs: { for: "description" } },
          [_vm._v(_vm._s(_vm.$t("description")))]
        ),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.description,
              expression: "description",
            },
          ],
          staticClass: "form-control",
          class: { "is-danger": _vm.errors.has("chapter.description") },
          attrs: {
            disabled: _vm.disabled,
            "data-vv-scope": "chapter",
            "data-vv-name": "description",
            "data-vv-as": _vm.$t("description"),
          },
          domProps: { value: _vm.description },
          on: {
            blur: _vm.handleDesc,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.description = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("chapter.description"),
                expression: "errors.has('chapter.description')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v(_vm._s(_vm.errors.first("chapter.description")))]
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.errors.items.length > 0 &&
              (_vm.errors.has("chapter.title") ||
                _vm.errors.has("chapter.description")),
            expression:
              "errors.items.length > 0 && (errors.has('chapter.title') || errors.has('chapter.description'))",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("div", { staticClass: "alert alert--card alert--error" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
          _c(
            "ul",
            _vm._l(_vm.errors, function (error, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(error.msg) + " "),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "button button--primary",
          attrs: { type: "button", disabled: _vm.isPending || _vm.disabled },
          on: {
            click: function ($event) {
              _vm.editMode ? _vm.updateContent() : _vm.createContent()
            },
          },
        },
        [
          !_vm.isPending
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.$t("update_button")
                        : _vm.$t("create_button")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.isPending
            ? _c("div", { staticClass: "lds-ellipsis" }, [
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }