var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isHeader
    ? _c("th", {
        staticClass: "vuetable-th-component-handle",
        attrs: { scope: "col" },
        domProps: { innerHTML: _vm._s(_vm.title) },
      })
    : _c("td", {
        staticClass: "vuetable-td-component-handle",
        domProps: {
          innerHTML: _vm._s(
            _vm.renderIconTag(["handle-icon", _vm.css.handleIcon])
          ),
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }