var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        fill: "none",
        stroke: "currentColor",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-miterlimit": "10",
        "stroke-width": "2",
        d: "M33.62 45H16.38C10.1 45 5 39.9 5 33.62V16.38C5 10.1 10.1 5 16.38 5h17.24C39.9 5 45 10.1 45 16.38v17.24C45 39.9 39.9 45 33.62 45z",
      },
    }),
    _c("path", {
      attrs: {
        fill: "none",
        stroke: "currentColor",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-miterlimit": "10",
        "stroke-width": "2",
        d: "M30 32H17a4 4 0 01-4-4V18h13a4 4 0 014 4v10zM36.84 31.27L30 27v-5l6.84-4.27a.76.76 0 011.16.64v12.26c0 .6-.66.96-1.16.64z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }