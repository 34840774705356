var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-group", attrs: { id: `question-${_vm.id}` } },
    [
      _vm._l(_vm.options, function (option) {
        return _c("BaseChoice", {
          key: `${_vm.id}-${option.id}-${option.is_selected}`,
          attrs: {
            option: option.choice_text,
            "is-selected": option.is_selected,
            "is-multiple": _vm.isMultiple,
            "is-readonly": _vm.isReadonly,
            id: option.id,
            "q-id": _vm.id,
          },
          on: { selected: _vm.selected },
        })
      }),
      _vm.hasOtherOption
        ? _c(
            "label",
            {
              staticClass: "single-choice",
              class: { selected: _vm.hasOtherOption && _vm.hasOtherAnswer },
              attrs: { for: `${_vm.id}_other`, disabled: _vm.isReadonly },
            },
            [
              _c("input", {
                attrs: {
                  type: _vm.isMultiple ? "checkbox" : "radio",
                  id: `${_vm.id}_other`,
                  disabled: _vm.isReadonly,
                  name: `radio-input-${_vm.qId}`,
                },
                domProps: {
                  value: _vm.hasOtherAnswer,
                  checked:
                    _vm.hasOtherOption && _vm.hasOtherAnswer ? true : false,
                },
              }),
              _vm._v(" " + _vm._s(_vm.hasOtherAnswer || _vm.$t("other")) + " "),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }