var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "currentColor", "fill-rule": "evenodd" } }, [
    _c("path", {
      attrs: {
        d: "M15.7015094 17.3190757c0 .5152831-.4864151 1.0024529-1.0022641 1.0024529H5.22698113c-.51584905 0-1.00245283-.4871698-1.00245283-1.0024529V5.43624555h11.4769811V17.3190757zM8.02132075 3.60190592h3.88358495V2.02322668H8.02132075v1.57867924zm10.98754715 0h-5.2696226V1.10586819c0-.50622642-.41-.91716981-.9171698-.91716981H7.10415094c-.50716981 0-.91735849.4109434-.91735849.9171698v2.49603774H.91735849C.41 3.60190592 0 4.01284932 0 4.51907574c0 .50641509.41.91716981.9173585.91716981H2.39V17.3190757c0 1.5375472 1.29943396 2.8369812 2.83698113 2.8369812h9.47226417c1.5373585 0 2.8367924-1.299434 2.8367924-2.8369812V5.43624555h1.4728302c.5071698 0 .9173585-.41075472.9173585-.91716981 0-.50622642-.4101887-.91716982-.9173585-.91716982z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M7.65832075 15.8896038c.50716982 0 .91716982-.4107547.91716982-.9171698V8.78564151c0-.50641509-.41-.91735849-.91716982-.91735849-.50716981 0-.91716981.4109434-.91716981.91735849V14.972434c0 .5064151.41.9171698.91716981.9171698M12.2664151 15.8896038c.5071698 0 .9171698-.4107547.9171698-.9171698V8.78564151c0-.50641509-.41-.91735849-.9171698-.91735849s-.9171698.4109434-.9171698.91735849V14.972434c0 .5064151.41.9171698.9171698.9171698",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }