<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-sequence"
    v-html="title"
    scope="col"
  />
  <td
    v-else
    class="vuetable-td-component-sequence"
    v-html="renderSequence()"
  />
</template>

<script>
import VuetableFieldMixin from './VuetableFieldMixin.vue';

export default {
  name: 'VuetableFieldSequence',

  mixins: [VuetableFieldMixin],

  methods: {
    renderSequence() {
      return this.vuetable.tablePagination
        ? this.vuetable.tablePagination.from + this.rowIndex
        : 1 + this.rowIndex;
    },
  },
};
</script>
