import { render, staticRenderFns } from "./JourneyItems.vue?vue&type=template&id=58f78ee7&scoped=true"
import script from "./JourneyItems.vue?vue&type=script&lang=js"
export * from "./JourneyItems.vue?vue&type=script&lang=js"
import style0 from "./JourneyItems.vue?vue&type=style&index=0&id=58f78ee7&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58f78ee7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/journey/workspace/journeyAdmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58f78ee7')) {
      api.createRecord('58f78ee7', component.options)
    } else {
      api.reload('58f78ee7', component.options)
    }
    module.hot.accept("./JourneyItems.vue?vue&type=template&id=58f78ee7&scoped=true", function () {
      api.rerender('58f78ee7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/container/JourneyItems.vue"
export default component.exports