var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "currentColor" } }, [
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        d: "M15.9556094 7.59758696l-3.5425346-3.58188597 1.7873646-1.79113278 3.5734035 3.55094874-1.8182335 1.82207001zM1.84133778 16.4403205l1.72543746 1.7142642H1.84133778v-1.7142642zm3.95841224 1.3356153l-3.57453981-3.5511386 8.88569049-8.90422836 3.542724 3.58188597-8.85387468 8.87348099zM19.7272934 5.11748396L14.8452769.26755113c-.358685-.35928943-.9398909-.35492405-1.2983865.00227759L.2700553 13.574546c-.00056814.0005694-.00075752.0011388-.00132566.0017082-.00056814.0003796-.00113628.0007592-.00170442.0013286-.01609726.0163227-.02746004.0351128-.04223164.0523845-.02461935.0288494-.05056436.0567499-.07120673.0882565-.0223468.0332148-.03844406.0687072-.05548822.1038201-.0092796.0187901-.02083176.0360618-.0287857.0552315-.0157185.0385291-.02556624.0785767-.03598212.1186243-.00492387.0182207-.0121203.0354924-.0157185.0539029-.00852209.0436538-.01098402.0878769-.0130672.1321001C.00378759 14.1970866 0 14.2120807 0 14.2274544v4.8497431C0 19.5866179.41171123 20 .9205742 20h4.88220589c.06003333 0 .12006666-.0060736.17934248-.0178411.0083327-.0017082.01609726-.0053144.02442996-.0072124.04999622-.0113879.09980305-.0250534.14790547-.0449823.00265131-.0011388.00473449-.002847.0073858-.0039858.05776078-.0244841.11287024-.0556111.16532838-.0924321.04450421-.0309372.087304-.0645316.12612681-.1034404L19.7303235 6.42538859C19.9043633 6.25153274 20 6.01580243 20 5.77039238c0-.24559986-.0986668-.48057097-.2727066-.65290842z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }