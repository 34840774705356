var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal-container" },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "in-out" } },
        [
          _vm.modalVisible
            ? _c("modal-survey-engagment-detail", {
                attrs: {
                  "result-id": _vm.resultId,
                  "is-completed": _vm.isCompleted,
                },
                on: { completed: _vm.completed },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "modal",
          class: { "is-loading": _vm.formSending },
          attrs: { tabindex: "-1" },
        },
        [
          _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("header", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title d-inline-block text-truncate",
                    staticStyle: { "max-width": "380px" },
                  },
                  [_vm._v(" " + _vm._s(_vm.journeyItemDetail.name) + " ")]
                ),
                _c("button", {
                  staticClass: "close",
                  attrs: { type: "button" },
                  domProps: { innerHTML: _vm._s("&times;") },
                  on: { click: _vm.closeModal },
                }),
              ]),
              _c("nav", { staticClass: "navigation navigation--secondary" }, [
                _c("div", { staticClass: "container" }, [
                  _c(
                    "ul",
                    {
                      staticClass: "navigation__list nav",
                      attrs: { id: "contentCreateTab", role: "tablist" },
                    },
                    [
                      _c("li", { staticClass: "navigation__item" }, [
                        _c(
                          "button",
                          {
                            staticClass: "navigation__link",
                            class: { active: _vm.tabPane === "statisticsTab" },
                            on: {
                              click: function ($event) {
                                return _vm.tabNavigation("statisticsTab")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("statistics")) + " ")]
                        ),
                      ]),
                      _c("li", { staticClass: "navigation__item" }, [
                        _c(
                          "button",
                          {
                            staticClass: "navigation__link",
                            class: {
                              active: _vm.tabPane === "usersCompletedTab",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.tabNavigation("usersCompletedTab")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("users_completed")) + " "
                            ),
                          ]
                        ),
                      ]),
                      _c("li", { staticClass: "navigation__item" }, [
                        _c(
                          "button",
                          {
                            staticClass: "navigation__link",
                            class: {
                              active: _vm.tabPane === "downloadReportTab",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.tabNavigation("downloadReportTab")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("download_report")) + " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "tab-content" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tabPane === "statisticsTab",
                          expression: "tabPane === 'statisticsTab'",
                        },
                      ],
                      staticClass: "tab-pane",
                    },
                    [
                      _c("div", { staticClass: "form form--horizontal" }, [
                        _c(
                          "div",
                          { staticClass: "form-group form-group--cards" },
                          [
                            _c(
                              "figure",
                              { staticClass: "highcharts-figure" },
                              [
                                _c("highcharts", {
                                  ref: "completionGauge",
                                  staticClass: "chart-container",
                                  attrs: {
                                    options: _vm.completionChartOptions,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: { type: "button" },
                            on: { click: _vm.closeModal },
                          },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("close")) + " "),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm.tabPane === "usersCompletedTab"
                    ? _c("div", { staticClass: "tab-pane" }, [
                        _c("div", { staticClass: "form form--horizontal" }, [
                          _c(
                            "div",
                            { staticClass: "form-group form-group--cards" },
                            [
                              _c("div", { staticClass: "top-group" }, [
                                _c(
                                  "div",
                                  [
                                    _c("filter-perpage", {
                                      on: {
                                        searchFor: _vm.setSearchFor,
                                        perPage: _vm.setPerPage,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "container" }, [
                                _c(
                                  "div",
                                  { staticClass: "table-responsive" },
                                  [
                                    _vm.tableIsLoading
                                      ? _c("div", {
                                          staticClass: "lds-dual-ring",
                                        })
                                      : _vm._e(),
                                    _c("vue-table", {
                                      ref: "myvuetable",
                                      class: {
                                        "table-fade": _vm.tableIsLoading,
                                      },
                                      attrs: {
                                        "api-url": _vm.apiUrl,
                                        "http-fetch": _vm.myFetch,
                                        "api-mode": true,
                                        "pagination-path": "meta",
                                        "http-method": "get",
                                        "append-params": _vm.moreParams,
                                        "http-options": {
                                          headers: {
                                            Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                                          },
                                        },
                                        fields: _vm.fields,
                                        "no-data-template":
                                          _vm.$t("no_data_info"),
                                        css: _vm.css.table,
                                        "per-page": _vm.perPage,
                                        "query-params": _vm.makeQueryParams,
                                      },
                                      on: {
                                        "vuetable:loading": function ($event) {
                                          _vm.tableIsLoading = true
                                        },
                                        "vuetable:loaded": function ($event) {
                                          _vm.tableIsLoading = false
                                        },
                                        "vuetable:load-error":
                                          _vm.vuetableLoadError,
                                        "vuetable:pagination-data":
                                          _vm.onPaginationData,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "completion_date",
                                            fn: function (props) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getCurrentLocaleDate(
                                                        props.rowData
                                                          .completion_date
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "actions",
                                            fn: function (props) {
                                              return [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "ui button cursor-pointer",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadPDF(
                                                          props.rowData
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        type: "mdi",
                                                        path: _vm.iconPdfOutline,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm.surveyCompletionStatusData &&
                                                _vm.surveyCompletionStatusData
                                                  .survey &&
                                                _vm.surveyCompletionStatusData
                                                  .survey.survey_type === 0
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "ui button delete",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.getUserSurveyResult(
                                                              props.rowData
                                                                .user_id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "icon-container",
                                                          {
                                                            attrs: {
                                                              name: "search",
                                                              width: 24,
                                                              height: 24,
                                                              color: "#727d92",
                                                              "view-box":
                                                                "0 0 19 19",
                                                              "is-icon-class": false,
                                                            },
                                                          },
                                                          [_c("icon-search")],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "ui button delete",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showSurveyModal(
                                                              props.rowData
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        props.rowData
                                                          .is_evaluated_by_hr_user
                                                          ? _c(
                                                              "icon-container",
                                                              {
                                                                attrs: {
                                                                  name: "greencheck",
                                                                  "view-box":
                                                                    "0 0 60 60",
                                                                  width: 18,
                                                                  height: 18,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "icon-greencheck"
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "icon-container",
                                                              {
                                                                attrs: {
                                                                  name: "search",
                                                                  width: 18,
                                                                  height: 18,
                                                                  color:
                                                                    "#727d92",
                                                                  "view-box":
                                                                    "0 0 16 24",
                                                                  "is-icon-class": false,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "icon-finger"
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        451809238
                                      ),
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "pagination__info" },
                                      [
                                        _c("vuetable-pagination-info", {
                                          ref: "paginationInfo",
                                          class: {
                                            "table-fade": _vm.tableIsLoading,
                                          },
                                          attrs: {
                                            "info-template":
                                              _vm.$t("pagination_info"),
                                            "no-data-template":
                                              _vm.$t("no_data_info"),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "pagination__items" },
                                          [
                                            _c("vuetable-pagination", {
                                              ref: "pagination",
                                              attrs: {
                                                css: _vm.css.pagination,
                                              },
                                              on: {
                                                "vuetable-pagination:change-page":
                                                  _vm.onChangePage,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button button--primary",
                              attrs: { type: "button" },
                              on: { click: _vm.closeModal },
                            },
                            [
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.$t("close")) + " "),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.tabPane === "downloadReportTab"
                    ? _c("div", { staticClass: "tab-pane" }, [
                        _c("div", { staticClass: "form form--horizontal" }, [
                          _c(
                            "div",
                            { staticClass: "form-group form-group--cards" },
                            [
                              _c(
                                "div",
                                { staticClass: "row form-label mt-4" },
                                [
                                  _c("div", { staticClass: "col-6 mb-4" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.currentUsers,
                                          expression: "currentUsers",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        value: "onlyjourneyitemusers",
                                        id: "onlyjourneyitemusers",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.currentUsers,
                                          "onlyjourneyitemusers"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.currentUsers =
                                            "onlyjourneyitemusers"
                                        },
                                      },
                                    }),
                                    _c("label", {
                                      staticClass: "ml-3",
                                      attrs: { for: "onlyjourneyitemusers" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t("only_item_users")
                                        ),
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "col-6 mb-4" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.currentUsers,
                                          expression: "currentUsers",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        value: "allfirmusers",
                                        id: "allfirmusers",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.currentUsers,
                                          "allfirmusers"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.currentUsers = "allfirmusers"
                                        },
                                      },
                                    }),
                                    _c("label", {
                                      staticClass: "ml-3",
                                      attrs: { for: "allfirmusers" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t("all_firm_users")
                                        ),
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "col-6 mb-4" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.isFirstResult,
                                          expression: "isFirstResult",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        id: "isFirstResult1",
                                      },
                                      domProps: {
                                        value: 1,
                                        checked: _vm._q(_vm.isFirstResult, 1),
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.isFirstResult = 1
                                        },
                                      },
                                    }),
                                    _c("label", {
                                      staticClass: "ml-3",
                                      attrs: { for: "isFirstResult1" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t("is_first_result_1")
                                        ),
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "col-6 mb-4" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.isFirstResult,
                                          expression: "isFirstResult",
                                        },
                                      ],
                                      attrs: {
                                        type: "radio",
                                        id: "isFirstResult0",
                                      },
                                      domProps: {
                                        value: 0,
                                        checked: _vm._q(_vm.isFirstResult, 0),
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.isFirstResult = 0
                                        },
                                      },
                                    }),
                                    _c("label", {
                                      staticClass: "ml-3",
                                      attrs: { for: "isFirstResult0" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t("is_first_result_0")
                                        ),
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button button--primary",
                              attrs: { type: "button" },
                              on: { click: _vm.downloadReport },
                            },
                            [
                              !_vm.isPending
                                ? _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("download")) + " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.isPending
                                ? _c("div", { staticClass: "lds-ellipsis" }, [
                                    _c("div"),
                                    _c("div"),
                                    _c("div"),
                                    _c("div"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
          _vm.surveyCompletionStatusData &&
          _vm.surveyCompletionStatusData.survey &&
          _vm.surveyCompletionStatusData.survey.survey_type === 0
            ? _c(
                "div",
                {
                  staticClass: "modal__mobile-preview",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flag" },
                    [
                      _c(
                        "icon-container",
                        {
                          attrs: {
                            name: "flag",
                            width: 224,
                            height: 21,
                            "view-box": "0 0 224 21",
                            "is-icon-class": false,
                          },
                        },
                        [_c("icon-flag")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mobile-content bluered-bg" }, [
                    _vm.isLoading
                      ? _c("div", {
                          staticClass: "lds-dual-ring-mobile_preview",
                        })
                      : _vm._e(),
                    _vm.questions && _vm.questions.length
                      ? _c("div", { staticClass: "mobile-content__survey" }, [
                          _c(
                            "div",
                            { staticClass: "survey-preview--container" },
                            [
                              _vm._l(
                                _vm.questions,
                                function (surveyQuestion, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "survey-preview--question",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "question-content" },
                                        [
                                          _c("h5", [
                                            _vm._v(
                                              _vm._s(
                                                index +
                                                  1 +
                                                  ". " +
                                                  surveyQuestion.question
                                              )
                                            ),
                                          ]),
                                          _c("p", { staticClass: "subfont" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  surveyQuestion.sub_text
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          surveyQuestion.type ===
                                            "multiple-choice" ||
                                          surveyQuestion.type ===
                                            "single-choice"
                                            ? _c(
                                                "ul",
                                                [
                                                  _vm._l(
                                                    surveyQuestion.choices,
                                                    function (choice, ssindex) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: ssindex,
                                                          staticClass: "survey",
                                                          class: {
                                                            "is-selected":
                                                              choice.is_selected,
                                                          },
                                                        },
                                                        [
                                                          choice.imagePreview ||
                                                          choice.image
                                                            ? _c(
                                                                "picture",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-column",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-row",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "optiontick",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$helpers.choiceLetter(
                                                                                  ssindex
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src:
                                                                                choice.imagePreview ||
                                                                                choice.image,
                                                                              alt: "Choice Image",
                                                                            },
                                                                        }
                                                                      ),
                                                                      choice.choice_text
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "d-flex p-2",
                                                                              staticStyle:
                                                                                {
                                                                                  "mix-blend-mode":
                                                                                    "difference",
                                                                                  "font-size":
                                                                                    "11px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    choice.choice_text
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "d-flex p-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "optiontick",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$helpers.choiceLetter(
                                                                              ssindex
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        choice.choice_text
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  surveyQuestion &&
                                                  surveyQuestion.options &&
                                                  surveyQuestion.options
                                                    .has_other_option
                                                    ? _c(
                                                        "li",
                                                        {
                                                          key: `other${surveyQuestion.id}`,
                                                          staticClass: "survey",
                                                          class: {
                                                            "is-selected":
                                                              surveyQuestion.answer,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-flex p-2",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "optiontick",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$helpers.choiceLetter(
                                                                        surveyQuestion
                                                                          .choices
                                                                          .length
                                                                      )
                                                                    ) + " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    surveyQuestion.answer
                                                                      ? surveyQuestion.answer
                                                                      : _vm.$t(
                                                                          "other"
                                                                        )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          surveyQuestion.type === "day_picker"
                                            ? _c(
                                                "ul",
                                                [
                                                  _vm._l(
                                                    surveyQuestion.choices,
                                                    function (choice, dpindex) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: `dp${dpindex}`,
                                                          staticClass: "survey",
                                                          class: {
                                                            "is-selected":
                                                              !isNaN(
                                                                surveyQuestion.answer
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          choice.choice_text
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "d-flex p-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "optiontick",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$helpers.choiceLetter(
                                                                              dpindex
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        choice.choice_text
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  surveyQuestion.answer &&
                                                  isNaN(surveyQuestion.answer)
                                                    ? _vm._l(
                                                        surveyQuestion.answer.split(
                                                          ","
                                                        ),
                                                        function (
                                                          dchoice,
                                                          dcindex
                                                        ) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key: `dpc${dcindex}`,
                                                              staticClass:
                                                                "is-selected",
                                                            },
                                                            [
                                                              dchoice
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex p-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "optiontick",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$helpers.choiceLetter(
                                                                                  dcindex +
                                                                                    surveyQuestion
                                                                                      .choices
                                                                                      .length
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            dchoice
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          surveyQuestion.type === "text"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "survey-textarea",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        surveyQuestion.answer
                                                          ? surveyQuestion.answer
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          surveyQuestion.type === "rating" &&
                                          surveyQuestion.options
                                            ? _c(
                                                "div",
                                                [
                                                  surveyQuestion.options
                                                    .shape === "star"
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "rating-box",
                                                          },
                                                          [
                                                            _vm._l(
                                                              Number(
                                                                surveyQuestion
                                                                  .options.step
                                                              ),
                                                              function (
                                                                sindex
                                                              ) {
                                                                return [
                                                                  _c("img", {
                                                                    key: `star${index}${sindex}`,
                                                                    attrs: {
                                                                      src:
                                                                        surveyQuestion.value >=
                                                                        sindex
                                                                          ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAAxCAYAAACRQFplAAAAAXNSR0IArs4c6QAAAutJREFUaEPdmuFxEzEQRncbAFIBUEGgAuiAUAGmAkIFhAogFUA6SCoAKkhSAaGChAo+5vNIHtm5k7SS9uLhZvIrtqTnfdr1SlZxfgAci8gXEblT1QPn6TbDq/dEAC5F5EWY562qnnvPyfFdwQA8E5HfCciZqq7+B7CoYWRZTEfviKUaRrhFdHQDm9Awgi2ioyfYroaL6ugJxuz3ZiZRvFTVK88k4gIG4ImI3GYWfqqqjKjb4wXGlP4ts+obVX3uRuVVxwDkNIw8rjoOj1iFhhHMVUcPsJKGEcxVRw+wGg3ddRwKZtDQXcfRYLUauus4GsyioauOCuDHwHryumGsGxHh38jnmmAYOeIejLUuIwT7LiLv9mBBvUv4KyJHqvqTA633GABu+q8i8rh39Ad6/68AdRfn3ySP0D9x8x8+0OJap/2oqgzK1nMvKwLgiz60zrLg+/6EKE22P5PpHgCzG6O3r2qeicixqm7UK0YsviB8iyDcqwWjUJqKCYJATHjZp1igkwPP0lje/78O6lXVvCJYyJo88GT0nnqvfmZ8c4tTBRbg2O4zsSxZ87Zqk+VDrQZL9t4KAD/BR5aJGl57rzZZxjCDhejx6Nqz5k3WJnewZN/xpHf0c6GqR72DNkUsgHkW8oNcjaqB7gHjLQqV9Hje19Sq3MRNYACY/j00jGvt1rEVzFPDCNelYyuYp4YRrEtHM9gCGg7RsQVs7npodBLpuv1sAZu6pcxB8csrO3TWpk9G+ubbTxNY5pZybr1bfVNDn9d8+2kFq9WQX15XUz99CH0e+6m5S8H0Q2rW0QpWo2FV32To85p0rAar1PCzqp7U7qOQYRm93AFSk44WsJyGzX1TUDPX5zXpaAGb0/Ai7KfZg5WaCAJg1mT0pg6QzDpWgWU07O6bUujM2aZZx1qw3euhdW3y+kkDAO7TtOaZbz9rwdLroVMROentl0p6hppHNeMBkukyvgiW3FLO1qbSIlv/v1PzTCdV/wCqeT3OqYqM+wAAAABJRU5ErkJggg=="
                                                                          : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAAxCAYAAACRQFplAAAAAXNSR0IArs4c6QAABCNJREFUaEPdmuFRFEEQhbsjUCJQIlAj0AzECIQIlAiECIQIlAiUCMQIlAiECIQI2vquuqnhbnZ3ZndmfzhV/Djubmfe9JvX/XpOpfMws48i8llE7lR1r/N0D4/X3hOZ2S8ReenzvFPV773n5PldgZnZcxH5kwC5UNXD/wFY0PCniLxek469IxY0PBKRExF5JiKr0LEbsC0aIhoA+yAiq9CxJ7Cg4aWqHpgZAkIEV1HHnsBQv7cicqSqXxEMM7txOr5S1d89RaQLMDN7KiJ/feF7qnrnwM6cjueqSkS7jV7AkPQvIrKhYaw+oeONqu53Q9Urj5nZDg0TcKvQsXnEhmiYAFuFjj2AZWm4Nh17ABuk4Zp0bApsioZr0rE1sKDhtapGRb8jfmaGUn4TkW7q2BpY0PBYVRGJwWFm5LYnItIlWauZ/WiYT974s/ZVFVkfA0Y18p6o+V/DZcg1wKzlE0VklIbJOQs6Np5eNlUNwGLnmIDdO6ZQXTAb52Y0Wgm4iPDc6dgcHAPjXkQOVPWKF5szZmYces4EnGdReKauRepcJMmm0EeJehMjC6iHgDyIh/snDv8L//KJqp4uXUDr73u9SR0aqpsVqh1VNLMoeVgTYSV6S6jZDJszi0jhHm49SllmZeXezOA+0YOagMJTrdJdyu2CJ36iFE7hAhqObfhgHvOHAYYmDONMVRGWVYdTj2ROxwuBANDGuI6NyQSdNDx5DmEneqsIi5l98l4Jc1879YoUdxKYqyYHlejRZYKaHNjJXZva1aH3nS1EKdJBteMuAubgOLAIC9UCA6BEr6mweB3JeWK+R7mpZqOKgSX5o1vOM7PR3NQVmEePg5zmvMnacGpRWxXQZBE99bzqiCWRiz7hraoCdNHwHLXTAJr70CXAmvYuSk1qKdAlwLhFIVLN/NRYd6sUUHxuFrCkP9iEhlvC1ISOc4E1pWECLNtBro0Wn58LLGhYdCXkuemqJOeZGVUNDuOh578KsISG96rKDg8OFwTKInwTpRCL3RjBoZGUcI/a47XgqiOWTDx6z5XxTbG2UZ+X3Kstum6aAyxuKQdpaGbYdS76iCjFK9UKloPoMYga0csWtAkdi6iei2YVsGQ3szSc8k2lPq+UFWP0rAUWt5Q7NPRFI9Xhmw5z5tTB4wy4FGSgsKepsLSgYy2wLA3n+KYpn7eUjsXAcjT0/xGl8E3sPGeraLjAED3kHfuDsJzz5aV0rAG2fVmOGCz2TU7NHZ/nlJ59GV8DLGhI3wMnHT29S1SvJPlO5C82iuhFb5PfhvB61m9DioBt/WYDiQ6bstg3pWAzvc2Yq/q3IaXA4noo1rHJTb2aOmbGOY2cx5zV102lwOJ6iEk43Bzypr2ObZp6+ggq8naVPZoElhhAGivZ3FQkgTM+tJXzqjpV/wAVfzrdWRlIUwAAAABJRU5ErkJggg==",
                                                                      alt: "star",
                                                                    },
                                                                  }),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                  surveyQuestion.options
                                                    .shape === "thumb" ||
                                                  surveyQuestion.options
                                                    .shape === "thumbsup"
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "rating-box",
                                                          },
                                                          [
                                                            _vm._l(
                                                              Number(
                                                                surveyQuestion
                                                                  .options.step
                                                              ),
                                                              function (
                                                                tindex
                                                              ) {
                                                                return [
                                                                  _c("img", {
                                                                    key: `thumb${index}${tindex}`,
                                                                    attrs: {
                                                                      src:
                                                                        surveyQuestion.value >
                                                                        tindex
                                                                          ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAA7CAYAAADLjIzcAAAAAXNSR0IArs4c6QAAAwFJREFUaEPtm/1xFDEMxfUqACqAdAAVkFQAVEBogI8KSCogVAB0kFRAUgGhAkgFwFCAmDezN7O3s3foedfaiy/+80ZeWz9Zsiz7YAs0d/9lZvdHhj4BcJo5JWQOthrL3X3LuB8BvM2a1y4CoO5HAC4zIKQDcPdDM/v6H+UuADzfZwAGIMU4KYP0Lenu9O8PAeumuMESAD6b2csAgBcAzgNyk0SWAPDDzB4FZn0K4CQgN0kkFYC7U3ECiLR3AM4iglNksgFQoTfBCbcVA9ydmR+tP5YBjjE5APAzCKtYLG0FuPsnMzsOzvQGQCROBD+3WSwFgKg8Z5vi/xyoKoBu2TPreyyY6g93CQC/hT7FotUAFPj8SomU7W81WE0A30TLc06p1q/mAu7OYMegp7ZU69cEEM32hoAeZPl+NRcIHnfHVkbaEbg/+OwxwN2jh50hhFcA2De1zQpAzPWHilL5aObHLfLazJgwRfuMgp0bQKn1p1idpTMmTgQit9kAdPs+q71LtaLD05wAlrB+Hzbd4onqErMAcHemukx8lm6XAI6UScwFoCTrU+apyEquMBlAwUlPUaZEVrpYgbuz/h6p0vDDa0XKHVSewKSEigC2XVMNLUD/et/9yIJFStFCXAZSQqUCuDCzZ+KEMsWvAPDmKdxUAFdm9jT89XxB+TDVGgBpB6B9WgOg5wFiENx1F6BRqwbB2wCg6jZ4GwBIZbXWYsB3MztUymotAWBFmcpLdYGWAEhLf5WitARACn4tAqBOe58JSjlAi5mgHAdaigE0KGuCe7sLSJWg1oLgFwDR1ydrh/QWXKBo+2tpBciBr78EWlgBew9A3vtbWgGTn9SoLsD7t+hDx4yS6OQH1SoA/p+H9/G8D2D5mXeC9zI0HYzx18xez/GgggD4fpd76DZFuNTOxl5vd48i+kAI5WFFKCx6HKsZ36b5TL4b3PTh7q0QYdBl+pcVJfcKN92LkPM5rL4WBCtaSvp0B2zV51opa0kDDYSrrYApk8rsewcgk/YujvUPxm5LS3hm7W4AAAAASUVORK5CYII="
                                                                          : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAA7CAYAAADLjIzcAAAAAXNSR0IArs4c6QAABElJREFUaEPtW41VFDEQnq8CtQKxArECpQKxArUCoQKhArECtQKhAqECoQKlAqWC8X33Mr653OY22c3m2NW8x+OUbDbzzU++mclBdjBU9ZeIPOx49QmA05ZbQsuX2btUVfkZAFR1T0TeiMj78PePAI5a7WvnADhQXojIt/DvAwCXLUBoDoCqmqBXAPj571DVk2AJFwAO/0UAGBcYH1busVQA6N8fRKTT11X1WkSeikgTN2iCcmTmn0XktYgcAziLtayq5yLyUkReAeDnSccuAPghIoz8zwBQ22vDxYFTAIwJk46mAIQjjwDcAejiAaKqtIp3KQupjUZrAEy4LwB49m8MVeXx93xxMUBVqXFqn7+TAc6xxCcAftbWeLxeMwtQ1U+B8W2c/44M8ez/KiK3ABgnJh9NAHDC34nIfkqzqkomSHLUeUJMgcakAASzp1D7DHwUrivyUzDHEDlvD8DvKQRu5gKRz9+KyGFK+ACAab/J8WdATGYBqvo9aP4maD6p0V1pnyBMAoCq8ohj0KPm6fNbzdmxv6banxIAY3tvAZD6JocjR5zzqA+s2nGhugU4c846ylTVkqNmKbAHcQoALNnJMmdn/r3WUlv71V0gMucsJueoL4HLZX6MKUykaGW5z3TiV9UCVNW0n+T68S5c8jNUwcwdSJw2MsucBasBEM79VTVHRLK0H85/K4rm7NfmMJ8gueLPg/CfgwooNQEo1n6JxKm5zuroFqwxFLlEFQBUlZog8SnSfg0AghVZFekSwEHJurUAMNbXtKZvggb3o+bpDkWuMBoAl+llsb4S7ZTMdcG0SAnszDAHZwmqb3DhtSJllOYmM72+hWv83dHvIkJFAFZtqsxB/7IWFqO3FS12QmIiF7BqU9FeDIDjQCxSOLBIQcEvQsna5vWmuZnAjprmCinJalPqBSsA+rowvp0VCpZcr7OsPUqSgQ87Ky5OpgYD0AfaQFkGPaaq5ADFJwBfthQArKlazgOGusA9swBSY+MB5UGwT5iuGND3zCBbHvHQ0LrCUlyAFmDV56w6hGG9FACs6dJbgI2NbPYAOAa4te9QnQfclxgwtp2+JAsoygEWEwPGltVnbwHU5JjK8lIAsIsXRUfgIqhwVI4rTtBmbQFBeBIgEqGiStDsg2AIfqxFUvjsPsRiiFCta7WzdYGxBGgJLmA1gOLI791gzhZgV2qK8v9qMSDc5hrdnR1SAojK8aMuVFlVeOv56YoNrL3FV1ytVc0uLasyN0M7tTlgOOE5ffSF6tK+AL/PQyHZD2Cp3Hdn4/2zXc0fzufv1T0hAFc5gvo54bznFXreJrErd0d9129y3kMASCN5qcnazF3PMdc+67q9Hc5jDwg3+Djn5dEc/xUZrtF5mZoWxv3WsrLRvcGUoKGOaIL4r8bwInTpYAOGVnReQ+trp0DpTqaaHwCz5a9b3RabzAKmAqr2uv8BqI3o3Nb7A3CCp1pcXm2JAAAAAElFTkSuQmCC",
                                                                      alt: "thumb",
                                                                    },
                                                                  }),
                                                                ]
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                  surveyQuestion.options
                                                    .shape === "number"
                                                    ? [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-block",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "rating-number-box",
                                                              },
                                                              _vm._l(
                                                                Number(
                                                                  surveyQuestion
                                                                    .options
                                                                    .step
                                                                ) + 1,
                                                                function (
                                                                  notusedindex,
                                                                  nindex
                                                                ) {
                                                                  return _c(
                                                                    "span",
                                                                    {
                                                                      key: `mum${nindex}`,
                                                                      staticClass:
                                                                        "number-cell",
                                                                      class: {
                                                                        "is-rating-selected":
                                                                          surveyQuestion.value ===
                                                                          nindex,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            nindex
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "rating-number-info",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      surveyQuestion
                                                                        .options
                                                                        .min_value
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      surveyQuestion
                                                                        .options
                                                                        .max_value
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "survey-preview--button",
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "button button--secondary size-sm",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.previewQuestionNext(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("next")) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "survey-preview--progress",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.questions
                                                  ? parseInt(
                                                      (100 /
                                                        _vm.questions.length) *
                                                        (index + 1),
                                                      10
                                                    ) +
                                                      "% " +
                                                      _vm.$t("completed_")
                                                  : ""
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "survey-progress-bar",
                                            },
                                            [
                                              _c("span", {
                                                style: {
                                                  width: _vm.questions
                                                    ? parseInt(
                                                        (100 /
                                                          _vm.questions
                                                            .length) *
                                                          (index + 1),
                                                        10
                                                      ) + "%"
                                                    : "100%",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _c(
                                "div",
                                { staticClass: "survey-preview--question" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "survey-finalpage" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-center text-center mt-4",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("thanks_feedback")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "survey-preview--button",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button button--secondary size-sm",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewQuestionNext(
                                                    -1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("close")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }