var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-pane show active" }, [
    _c(
      "div",
      {
        staticClass: "form-group",
        class: { "is-danger": _vm.errors.has("flipbook.flipbook") },
      },
      [
        _c("label", { staticClass: "form-label", attrs: { for: "desc" } }, [
          _vm._v(_vm._s(_vm.$t("upload_flippages"))),
        ]),
        _c(
          "draggable-comp",
          {
            staticClass: "previews",
            attrs: {
              "ghost-class": "ghost",
              disabled: _vm.disabled,
              list: _vm.thumbnails,
              "force-fallback": true,
              move: _vm.checkMove,
            },
            on: {
              start: function ($event) {
                _vm.dragging = true
              },
              end: function ($event) {
                return _vm.endDraggable()
              },
            },
          },
          [
            _c(
              "transition-group",
              {
                staticClass: "fliplist",
                attrs: { type: "transition", name: "slidepreivew", tag: "ul" },
              },
              [
                _vm._l(_vm.thumbnails, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: "flip" + index,
                      staticClass: "preview",
                      style: { "--i": index },
                    },
                    [
                      _c("span", { staticClass: "preview__count" }, [
                        _vm._v(_vm._s(index + 1)),
                      ]),
                      _c("img", { attrs: { src: item.image, alt: "" } }),
                      _c(
                        "button",
                        {
                          staticClass: "remove",
                          attrs: {
                            disabled: _vm.disabled || _vm.isFileUploading,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removePreviewItem(index)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 15,
                                height: 15,
                                "view-box": "0 0 22 22",
                                name: "trash",
                                "is-icon-class": false,
                              },
                            },
                            [_c("icon-trash")],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                _vm._l(_vm.uploadingFiles, function (item, index) {
                  return _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isFileUploading,
                          expression: "isFileUploading",
                        },
                      ],
                      key: "prevflip" + index,
                      staticClass: "preview preview-image",
                      style: {
                        "--i":
                          index +
                          (_vm.thumbnails.length
                            ? _vm.thumbnails.length - 1
                            : 0),
                      },
                    },
                    [
                      _c("span", { staticClass: "preview__count" }, [
                        _vm._v(
                          _vm._s(
                            index +
                              1 +
                              (_vm.thumbnails.length
                                ? _vm.thumbnails.length - 1
                                : 0)
                          )
                        ),
                      ]),
                      _c("img", {
                        style: {
                          opacity: _vm.getUploadProgress(item.id) / 100,
                        },
                        attrs: { src: item.preview, alt: "" },
                      }),
                      _vm.getUploadStatus(item.id)
                        ? _c(
                            "div",
                            {
                              staticClass: "preview-error",
                              attrs: { role: "alert" },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("upload_error")) + " ")]
                          )
                        : _vm._e(),
                      _vm.getUploadProgress(item.id) < 101 &&
                      !_vm.getUploadStatus(item.id)
                        ? _c("progress-bar", {
                            staticClass: "progressbarline",
                            attrs: {
                              "bar-color": "#4a4",
                              size: "small",
                              "font-size": 10,
                              val: _vm.getUploadProgress(item.id),
                              "text-position": "middle",
                              "text-fg-color": "#4558FB",
                              "text-align": "center",
                              text: _vm.$t("rate_info", {
                                rate: _vm.getUploadProgress(item.id),
                              }),
                            },
                          })
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "preview-remove",
                          attrs: { disable: _vm.isFileUploading },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.deleteTempPreviewItem(item.id)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 15,
                                height: 15,
                                "view-box": "0 0 22 22",
                                name: "trash",
                                "is-icon-class": false,
                              },
                            },
                            [_c("icon-trash")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _c(
                  "li",
                  { key: "create", staticClass: "flip_card card card--upload" },
                  [
                    _c("input", {
                      attrs: {
                        type: "file",
                        name: "photo",
                        id: "photo",
                        disabled: _vm.disabled || _vm.isFileUploading,
                        accept: ".png, .jpg, .jpeg",
                        multiple: "",
                      },
                      on: { change: _vm.readFile },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|min:2",
                          expression: "'required|min:2'",
                        },
                      ],
                      attrs: {
                        type: "hidden",
                        name: "photos",
                        multiple: "",
                        "data-vv-scope": "flipbook",
                        "data-vv-name": "flipbook",
                        "data-vv-as": _vm.$t("flip_page"),
                      },
                      domProps: { value: _vm.thumbnailUrls },
                    }),
                    _c("span", { staticClass: "flip" }, [
                      _c(
                        "span",
                        { staticClass: "media-object media-object--row" },
                        [
                          _c(
                            "span",
                            { staticClass: "media-object__media" },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "circleplusfull",
                                    "view-box": "0 0 512 512",
                                    width: 45,
                                    height: 45,
                                    "is-icon-class": false,
                                  },
                                },
                                [_c("icon-circleplusfull")],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("flipbook.flipbook"),
                expression: "errors.has('flipbook.flipbook')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v(_vm._s(_vm.errors.first("flipbook.flipbook")))]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.enoughFlipExist,
            expression: "enoughFlipExist",
          },
        ],
        staticClass: "form-group",
        class: { "is-danger": _vm.errors.has("flipbook.title") },
      },
      [
        _c(
          "label",
          { staticClass: "form-label", attrs: { for: "contentTitle" } },
          [_vm._v(_vm._s(_vm.$t("title").toUpperCase()))]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title",
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|max:100",
              expression: "'required|max:100'",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            id: "contentTitle",
            disabled: _vm.disabled && !_vm.canEditOnlyItem,
            "data-vv-scope": "flipbook",
            "data-vv-name": "title",
            "data-vv-as": _vm.$t("title"),
          },
          domProps: { value: _vm.title },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.title = $event.target.value
              },
              _vm.handleTitle,
            ],
          },
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("flipbook.title"),
                expression: "errors.has('flipbook.title')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v(_vm._s(_vm.errors.first("flipbook.title")))]
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.thumbnails.length > 1,
            expression: "thumbnails.length > 1",
          },
        ],
        staticClass: "quiz-assubcomponent",
      },
      [
        _c("div", { staticClass: "title" }, [
          _c("h5", { staticClass: "modal-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("add_question")) + " "),
          ]),
          _c("div", { staticClass: "swicth-checkbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isAddingQuestion,
                  expression: "isAddingQuestion",
                },
              ],
              attrs: {
                type: "checkbox",
                id: "isAddingQuestion",
                "true-value": 1,
                "false-value": 0,
              },
              domProps: {
                checked: Array.isArray(_vm.isAddingQuestion)
                  ? _vm._i(_vm.isAddingQuestion, null) > -1
                  : _vm._q(_vm.isAddingQuestion, 1),
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.isAddingQuestion,
                    $$el = $event.target,
                    $$c = $$el.checked ? 1 : 0
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isAddingQuestion = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isAddingQuestion = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isAddingQuestion = $$c
                  }
                },
              },
            }),
            _c("label", {
              staticClass: "swicth-label",
              attrs: { for: "isAddingQuestion" },
            }),
          ]),
        ]),
        _c("div", { staticClass: "warning-box" }, [
          _c(
            "div",
            { staticClass: "desc-text" },
            [
              _c(
                "icon-container",
                {
                  attrs: {
                    width: "27",
                    height: "30",
                    "view-box": "0 0 27 30",
                    name: "lamp",
                    color: "#727d92",
                  },
                },
                [_c("icon-lamp")],
                1
              ),
              _c("div", { staticClass: "description" }, [
                _vm._v(" " + _vm._s(_vm.$t("quiz_remark")) + " "),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "collapse-transition",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isAddingQuestion,
                expression: "isAddingQuestion",
              },
            ],
            attrs: { duration: 500, dimension: "height" },
          },
          [
            _c("quiz-component", {
              attrs: {
                disabled: _vm.disabledTemp,
                "can-edit-only-item": _vm.canEditOnlyItemTemp,
                "quiz-type": "content",
                "as-scope": "flipbookquiz",
                repetitive_max_question_per_day:
                  _vm.repetitiveMaxQuestionPerDayTemp,
                repetitive_correct_answer_count:
                  _vm.repetitiveCorrectAnswerCountTemp,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.errors.items.length > 0 && _vm.enoughFlipExist,
            expression: "errors.items.length > 0 && enoughFlipExist",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("div", { staticClass: "alert alert--card alert--error" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
          _c(
            "ul",
            _vm._l(_vm.errors, function (error, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(error.msg) + " "),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "button button--primary",
          attrs: {
            type: "button",
            disabled:
              _vm.isPending ||
              (_vm.disabled && !_vm.canEditOnlyItem) ||
              _vm.isFileUploading,
          },
          on: {
            click: function ($event) {
              _vm.editMode ? _vm.updateContent() : _vm.createContent()
            },
          },
        },
        [
          !_vm.isPending
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.$t("update_button")
                        : _vm.$t("create_button")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.isPending
            ? _c("div", { staticClass: "lds-ellipsis" }, [
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }