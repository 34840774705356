var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M29.336 53.84C16.139 53.84 5.4 43.105 5.4 29.908c0-13.127 10.738-23.807 23.935-23.807 13.196 0 23.932 10.68 23.932 23.807 0 13.197-10.736 23.932-23.932 23.932Zm0-53.14C13.159.7 0 13.804 0 29.908c0 16.174 13.16 29.334 29.336 29.334 16.173 0 29.333-13.16 29.333-29.334C58.669 13.803 45.509.7 29.336.7Z",
        fill: "#2DCF85",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M38.228 19.211a2.7 2.7 0 0 0-3.634 1.175L27.946 33.39l-4.17-5.176a2.702 2.702 0 0 0-4.207 3.389l6.782 8.419.02.022.054.06c.099.111.204.214.315.306l.037.032c.132.106.274.195.422.272.01.005.017.013.026.017l.03.013c.067.033.135.063.205.09.03.011.059.025.089.035.064.023.13.041.195.06l.107.03c.06.013.12.022.18.032.042.006.082.015.123.02.077.008.155.01.232.013.022 0 .044.004.067.004h.004l.02-.002c.128-.001.256-.012.382-.032.051-.007.102-.02.154-.031a2.61 2.61 0 0 0 .651-.228c.044-.022.088-.042.13-.067a2.672 2.672 0 0 0 .99-.986c.02-.036.044-.07.063-.105.003-.008.008-.014.012-.02l8.544-16.711a2.698 2.698 0 0 0-1.175-3.634Z",
        fill: "#2DCF85",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }