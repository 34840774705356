var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal", class: { "is-loading": _vm.formSending } },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode ? _vm.$t("edit_pin") : _vm.$t("add_pin")
                    ) +
                    " "
                ),
              ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "tab-content" }, [
                _c("div", { staticClass: "tab-pane" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "customer" },
                              },
                              [_vm._v(_vm._s(_vm.$t("customer").toUpperCase()))]
                            ),
                            _c("multi-select", {
                              staticClass: "selectbox selectbox--secondary",
                              class: {
                                "is-danger": _vm.errors.has("pin.customer"),
                              },
                              attrs: {
                                "track-by": "id",
                                label: "name",
                                "clear-on-select": "",
                                "deselect-label": "",
                                "select-label": "",
                                options: _vm.customerOptions,
                                "data-vv-scope": "pin",
                                "data-vv-name": "customer",
                              },
                              model: {
                                value: _vm.selectCustomer,
                                callback: function ($$v) {
                                  _vm.selectCustomer = $$v
                                },
                                expression: "selectCustomer",
                              },
                            }),
                            _vm.errors.has("pin.customer")
                              ? _c("span", { staticClass: "help is-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("pin.customer"))
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "name" },
                            },
                            [_vm._v(_vm._s(_vm.$t("pin_code").toUpperCase()))]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.pin_code,
                                expression: "pin_code",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|numeric",
                                expression: "'required|numeric'",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-danger": _vm.errors.has("pin.pin_code"),
                            },
                            attrs: {
                              type: "text",
                              id: "pin_code",
                              "data-vv-scope": "pin",
                              "data-vv-name": "pin_code",
                              "data-vv-as": _vm.$t("pin_code"),
                              placeholder: _vm.$t("pincode_placeholder"),
                            },
                            domProps: { value: _vm.pin_code },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.pin_code = $event.target.value
                              },
                            },
                          }),
                          _vm.errors.has("pin.pin_code")
                            ? _c("span", { staticClass: "help is-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first("pin.pin_code"))
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        !_vm.editMode
                          ? _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "group" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("groups").toUpperCase())
                                    ),
                                  ]
                                ),
                                _c("vue-tags-input", {
                                  class: {
                                    "is-danger": _vm.errors.has("pin.group"),
                                  },
                                  attrs: {
                                    tags: _vm.groups,
                                    "autocomplete-items": _vm.groupsToAdd,
                                    "add-only-from-autocomplete": true,
                                    "delete-on-backspace": false,
                                    placeholder: _vm.$t(
                                      "add_new_group_placeholder"
                                    ),
                                  },
                                  on: { "tags-changed": _vm.inputUpdateGroups },
                                  model: {
                                    value: _vm.group,
                                    callback: function ($$v) {
                                      _vm.group = $$v
                                    },
                                    expression: "group",
                                  },
                                }),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.groupsSelected,
                                      expression: "groupsSelected",
                                    },
                                  ],
                                  attrs: {
                                    type: "hidden",
                                    "data-vv-scope": "pin",
                                    "data-vv-name": "group",
                                    "data-vv-as": _vm.$t("group_name"),
                                  },
                                  domProps: { value: _vm.groupsSelected },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.groupsSelected = $event.target.value
                                    },
                                  },
                                }),
                                _vm.errors.has("pin.group")
                                  ? _c(
                                      "span",
                                      { staticClass: "help is-danger" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.errors.first("pin.group"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.editMode
                          ? _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "group" },
                                },
                                [_vm._v(_vm._s(_vm.$t("groups").toUpperCase()))]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.groupsSelected,
                                    expression: "groupsSelected",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-danger": _vm.errors.has("pin.group"),
                                },
                                attrs: {
                                  type: "text",
                                  id: "group",
                                  "data-vv-name": "group",
                                  "data-vv-as": _vm.$t("group_name"),
                                  "data-vv-scope": "pin",
                                  placeholder: _vm.$t(
                                    "add_new_group_placeholder"
                                  ),
                                },
                                domProps: { value: _vm.groupsSelected },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.groupsSelected = $event.target.value
                                  },
                                },
                              }),
                              _vm.errors.has("pin.group")
                                ? _c(
                                    "span",
                                    { staticClass: "help is-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("pin.group"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "journey" },
                              },
                              [_vm._v(_vm._s(_vm.$t("journeys").toUpperCase()))]
                            ),
                            _c("vue-tags-input", {
                              class: {
                                "is-danger": _vm.errors.has("pin.journey"),
                              },
                              attrs: {
                                tags: _vm.journeys,
                                "autocomplete-items": _vm.journeysToAdd,
                                "add-only-from-autocomplete": true,
                                "delete-on-backspace": false,
                                placeholder: _vm.$t("addjourney_placeholder"),
                              },
                              on: { "tags-changed": _vm.inputUpdateJourneys },
                              model: {
                                value: _vm.journey,
                                callback: function ($$v) {
                                  _vm.journey = $$v
                                },
                                expression: "journey",
                              },
                            }),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.journeysSelected,
                                  expression: "journeysSelected",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-danger": _vm.errors.has("pin.sso_uri"),
                              },
                              attrs: {
                                type: "hidden",
                                id: "journey",
                                "data-vv-name": "journey",
                                "data-vv-as": _vm.$t("journeys"),
                                "data-vv-scope": "pin",
                                placeholder: _vm.$t("addjourney_placeholder"),
                              },
                              domProps: { value: _vm.journeysSelected },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.journeysSelected = $event.target.value
                                },
                              },
                            }),
                            _vm.errors.has("pin.journey")
                              ? _c("span", { staticClass: "help is-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("pin.journey"))
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "form-label",
                              attrs: { for: "contactName" },
                            },
                            [_vm._v(_vm._s(_vm.$t("sso_uri").toUpperCase()))]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sso_uri,
                                expression: "sso_uri",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "",
                                expression: "''",
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-danger": _vm.errors.has("pin.sso_uri"),
                            },
                            attrs: {
                              type: "text",
                              id: "sso_uri",
                              "data-vv-name": "sso_uri",
                              "data-vv-as": _vm.$t("sso_uri").toUpperCase(),
                              "data-vv-scope": "pin",
                              placeholder: _vm.$t("sso_uri_placeholder"),
                            },
                            domProps: { value: _vm.sso_uri },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.sso_uri = $event.target.value
                              },
                            },
                          }),
                          _vm.errors.has("pin.sso_uri")
                            ? _c("span", { staticClass: "help is-danger" }, [
                                _vm._v(_vm._s(_vm.errors.first("pin.sso_uri"))),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "swicth-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.get_privacy_approval,
                                  expression: "get_privacy_approval",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "get_privacy_approval",
                                "true-value": 1,
                                "false-value": 0,
                              },
                              domProps: {
                                checked: Array.isArray(_vm.get_privacy_approval)
                                  ? _vm._i(_vm.get_privacy_approval, null) > -1
                                  : _vm._q(_vm.get_privacy_approval, 1),
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.get_privacy_approval,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? 1 : 0
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.get_privacy_approval = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.get_privacy_approval = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.get_privacy_approval = $$c
                                  }
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "swicth-label",
                                attrs: { for: "get_privacy_approval" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("is_privacy_policy_needed"))
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.items.length > 0,
                          expression: "errors.items.length > 0",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "alert alert--card alert--error" },
                        [
                          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function (error, index) {
                              return _c("li", { key: index }, [
                                _vm._v(" " + _vm._s(error.msg) + " "),
                              ])
                            }),
                            0
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button button--primary no-wrap",
                        attrs: { type: "button", disabled: _vm.isPending },
                        on: { click: _vm.addPin },
                      },
                      [
                        !_vm.isPending
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.editMode
                                      ? _vm.$t("edit")
                                      : _vm.$t("add")
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.isPending
                          ? _c("div", { staticClass: "lds-ellipsis" }, [
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }