import { i18n } from '@/helpers/i18n';

const PRESS_TIMEOUT = 0;
const dragStarted = new CustomEvent('long-press-drag-started');
const dragEnded = new CustomEvent('long-press-drag-ended');
let dropZoneElements = [];
let initiatedFromLibrary = null;
let closeTimer = null;
let placeholderPositionedAt = null;
let placeholderPositionIndex = null;
let globalEl = null;
let globalvNode = null;
let CURRENT_VIEW_MODE = null;

const getPosition = (e) => {
  const rect = e.target.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;
  return {
    x,
    y,
  };
};
const getIndex = (rootNode, element) => Array.prototype.slice.call(rootNode.childNodes).indexOf(element);
const removeListener = (element) => {
  ['drag', 'dragenter', 'dragover', 'dragleave', 'drop'].forEach(d => element.removeEventListener(d, {}));
};

const removePlaceholder = () => {
  // if (initiatedFromLibrary) {
  let cardid = 'newItem';
  if (CURRENT_VIEW_MODE === 'weekly' || CURRENT_VIEW_MODE === 'daily') {
    cardid = 'newListItem';
  }
  const removeOldOneFirst = document.getElementById(cardid);
  if (removeOldOneFirst) {
    removeListener(removeOldOneFirst);
    removeOldOneFirst.remove();
  }
  // }
};

const newItem = () => {
  const item = document.createElement('li');
  item.className = 'list-group-item card card--catalog card--catalog-item drop-from-library-placeholder';
  item.innerHTML = `<div class="card__body no-pointer"><h4 class="no-pointer">${i18n.t('drag_drop')}</h4></div>`;
  item.id = 'newItem';
  return item;
};

const newListItem = () => {
  const item = document.createElement('li');
  item.className = 'list-group-item card card--catalog list--catalog-item drop-from-library-placeholder no-pointer';
  item.innerHTML = `<div class="card__body no-pointer"><h4 class="no-pointer">${i18n.t('drag_drop')}</h4></div>`;
  item.id = 'newListItem';
  return item;
};

const drag = (e) => {
  e.preventDefault();
  return false;
};

const dragOver = (e) => {
  e.preventDefault();
  return false;
};

const dragLeave = (e) => {
  e.preventDefault();
  const parentNode = e.target.closest('li');
  if (parentNode && (parentNode.id === 'newItem' || parentNode.id === 'newListItem')) {
    closeTimer = setTimeout(() => removePlaceholder(), 100);
  }
  if (e.target && e.target.classList.contains('drop-active')) {
    e.target.classList.remove('drop-active');
  }
  return false;
};

const drop = (e) => {
  e.preventDefault();
  const rootNode = e.target.closest('ul');
  const parentNode = e.target.closest('li');
  let index = null;
  index = getIndex(rootNode, e.target);
  if (index < 0) {
    index = getIndex(rootNode, parentNode);
    index -= 1;
  }
  if (initiatedFromLibrary && !e.target.classList.contains('card--create-item') && !e.target.classList.contains('list--create-item')) {
    removePlaceholder();
    const rawData = e.dataTransfer.getData('rawData') ? JSON.parse(e.dataTransfer.getData('rawData')) : '';
    if (rawData) {
      const contentId = rawData.options;
      if (globalvNode.componentInstance) {
        globalvNode.componentInstance.$emit('long-press-drop', { detail: { index, contentId, rawData } });
      } else {
        globalEl.dispatchEvent(new CustomEvent('long-press-drop', { detail: { index, contentId, rawData } }));
      }
    }
    initiatedFromLibrary = false;
    placeholderPositionedAt = null;
    placeholderPositionIndex = null;
  }

  if (e.target.classList.contains('card--create-item' || e.target.classList.contains('list--create-item')) && e.target.classList.contains('drop-active')) {
    e.target.classList.remove('drop-active');
  }
};

const dragEnter = (e) => {
  e.stopPropagation();
  e.preventDefault();
  if (initiatedFromLibrary) {
    const parentNode = e.target.closest('li');
    const rootNode = e.target.closest('ul');
    if ((parentNode && parentNode.id && (parentNode.id === 'newItem' || parentNode.id === 'newListItem'))) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    e.dataTransfer.dropEffect = 'move';
    if (parentNode && parentNode.id === 'dropzone') {
      removePlaceholder();
      parentNode.classList.add('drop-active');
    }
    if (parentNode && parentNode.id !== 'dropzone') {
      clearTimeout(closeTimer);
      let newPlaceItem = newItem();
      if (CURRENT_VIEW_MODE === 'weekly' || CURRENT_VIEW_MODE === 'daily') {
        newPlaceItem = newListItem();
      }
      removePlaceholder();
      const position = getPosition(e);
      const currentIndex = getIndex(rootNode, parentNode);
      const rowSize = Math.floor(rootNode.offsetWidth / 240);
      const isTheLastCard = (currentIndex + 1) % rowSize === 0;
      const mousePosY = e.pageY - rootNode.getBoundingClientRect().top;
      const cardPos = (currentIndex / rowSize) * 346;
      const isTheCardAtPlace = (mousePosY < cardPos);
      if (placeholderPositionedAt && (placeholderPositionIndex === 0 || placeholderPositionIndex)) {
        if (isTheLastCard) {
          if (isTheCardAtPlace) {
            rootNode.insertBefore(newPlaceItem, parentNode);
            placeholderPositionedAt = 'left';
          } else {
            rootNode.insertBefore(newPlaceItem, parentNode.nextSibling);
            placeholderPositionedAt = 'right';
          }
        } else if (currentIndex === placeholderPositionIndex) {
          if (placeholderPositionedAt === 'left') {
            rootNode.insertBefore(newPlaceItem, parentNode.nextSibling);
            placeholderPositionedAt = 'right';
          } else {
            rootNode.insertBefore(newPlaceItem, parentNode);
            placeholderPositionedAt = 'left';
          }
        } else if (Math.abs(currentIndex - placeholderPositionIndex) === 1) {
          if (placeholderPositionedAt === 'left') {
            rootNode.insertBefore(newPlaceItem, parentNode);
            placeholderPositionedAt = 'left';
          } else {
            rootNode.insertBefore(newPlaceItem, parentNode.nextSibling);
            placeholderPositionedAt = 'right';
          }
        } else if (position.x < 120) {
          rootNode.insertBefore(newPlaceItem, parentNode);
          placeholderPositionedAt = 'left';
        } else {
          rootNode.insertBefore(newPlaceItem, parentNode.nextSibling);
          placeholderPositionedAt = 'right';
        }
      } else if (position.x < 120) {
        rootNode.insertBefore(newPlaceItem, parentNode);
        placeholderPositionedAt = 'left';
      } else {
        rootNode.insertBefore(newPlaceItem, parentNode.nextSibling);
        placeholderPositionedAt = 'right';
      }
      placeholderPositionIndex = currentIndex;

      if (newPlaceItem) {
        newPlaceItem.addEventListener('drag', drag, false);
        newPlaceItem.addEventListener('dragenter', dragEnter, false);
        newPlaceItem.addEventListener('dragover', dragOver, false);
        newPlaceItem.addEventListener('dragleave', dragLeave, false);
        newPlaceItem.addEventListener('drop', drop, false);
      }
    }
  }
  return false;
};

const addListener = (element) => {
  element.addEventListener('drag', drag, false);
  element.addEventListener('dragenter', dragEnter, false);
  element.addEventListener('dragover', dragOver, false);
  element.addEventListener('dragleave', dragLeave, false);
  element.addEventListener('drop', drop, false);
};

const addMouseListener = (element, start, cancel) => {
  ['mousedown', 'touchstart'].forEach(e => element.addEventListener(e, start, { passive: true }));
  ['mouseup', 'mouseout', 'touchend', 'touchcancel'].forEach(e => element.addEventListener(e, cancel));
};

const initZones = (zoneElements) => {
  zoneElements.forEach((zone) => {
    addListener(zone);
  });
};
export const longPressToDrag = {
  isFn: true,
  bind(el, bind, vNode) {
    if (bind.value && typeof bind.value === 'function') {
      const compName = vNode.context.name;
      let warn = '[v-longPressToDrag:] provided expression is a function, but it has to be like E.g { delay: 300, dragdrop: true, dropzone:"dropzone"}';
      if (compName) {
        warn += `Found in component '${compName}'`;
      }
      console.warn(warn);
    }
  },
  update(el, bind) {
    let currentViewMode = null;
    if (bind.value) {
      ({ currentViewMode } = bind.value);
      CURRENT_VIEW_MODE = currentViewMode;
    }
  },
  inserted(el, bind, vNode) {
    let pressTimer = null;
    let delay = null;
    let dragdrop = false;
    let dropzoneId = null;
    let currentViewMode = null;

    const itemslist = document.getElementById('itemslist');
    globalEl = el;
    globalvNode = vNode;

    if (bind.value) {
      ({
        delay, dragdrop, dropzoneId, currentViewMode,
      } = bind.value);
      CURRENT_VIEW_MODE = currentViewMode;

      if (dragdrop === true && dropzoneId === null) {
        const compName = vNode.context.name;
        let warn = `
          [v-longPressToDrag:] please provide dropzone ref name dropzone in object value expression
          if dragdrop enabled, or remove the latter. E.g { dragdrop: true, dropzone: dropzone }
        `;
        if (compName) {
          warn += `Found in component '${compName}'`;
        }
        console.warn(warn);
      }
    }

    const start = (e) => {
      if (dragdrop && e.dataTransfer) {
        e.dataTransfer.effectAllowed = 'move';
      }
      if (dragdrop && dropzoneId) {
        dropZoneElements = document.querySelectorAll(`[${dropzoneId}]`);
        initZones(dropZoneElements);
      }
      const isDisabled = el.getAttribute('is-disabled');
      if (isDisabled) {
        return false;
      }

      if (pressTimer === null) {
        pressTimer = setTimeout(() => {
          el.classList.add('moving');
          if (dragdrop) {
            initiatedFromLibrary = true;
            if (itemslist) {
              itemslist.classList.add('no-point-event');
            }
            el.setAttribute('draggable', true);
            el.classList.add('start-dragging');
          } else {
            initiatedFromLibrary = false;
          }
          const event = document.createEvent('HTMLEvents');
          event.initEvent('mousedown', false, true);
          el.dispatchEvent(event);
        }, delay || PRESS_TIMEOUT);
      }
      return true;
    };

    const cancel = () => {
      removePlaceholder();
      if (pressTimer !== null) {
        clearTimeout(pressTimer);
        pressTimer = null;
        if (dragdrop) {
          const ghost = el.querySelector('.content-ghost');
          ghost.style.display = 'none';
          const orginal = el.querySelector('.content-orginal');
          orginal.style.opacity = '1';
          el.classList.remove('start-dragging');
          el.removeAttribute('draggable');
          if (itemslist) {
            itemslist.classList.remove('no-point-event');
          }
          placeholderPositionedAt = null;
          placeholderPositionIndex = null;
        }
        // eslint-disable-next-line no-param-reassign
        el.classList.remove('moving');
      }
    };

    if (dragdrop) {
      const dragstart = (e) => {
        e.stopPropagation();
        if (e.dataTransfer && !document.getElementById('newGhost')) {
          e.dataTransfer.effectAllowed = 'move';
          const rawData = e.target.getAttribute('raw-data');
          if (rawData) {
            e.dataTransfer.setData('rawData', rawData);
          }
        }
        const ghost = el.querySelector('.content-ghost');
        if (ghost) {
          ghost.style.display = 'block';
          const position = getPosition(e);
          let diff = null;
          if (position.x >= 120) {
            diff = position.x - 120;
            ghost.style.left = `${diff}px`;
          } else {
            diff = 120 - position.x;
            ghost.style.marginLeft = `-${diff}px`;
          }
        }
        const orginal = el.querySelector('.content-orginal');
        if (orginal) {
          orginal.style.opacity = '0';
          orginal.style.display = 'none';
        }
        setTimeout(() => {
          if (ghost) {
            ghost.style.display = 'none';
          }
          if (orginal) {
            orginal.style.opacity = '1';
            orginal.style.display = 'block';
          }
          if (globalvNode.componentInstance) {
            globalvNode.componentInstance.$emit('long-press-drag-started', e, false);
          } else {
            globalEl.dispatchEvent(dragStarted);
          }
        }, 1);
      };

      el.addEventListener('dragstart', dragstart, false);

      const dragend = (e) => {
        setTimeout(() => {
          if (globalvNode.componentInstance) {
            globalvNode.componentInstance.$emit('long-press-drag-ended', e, false);
          } else {
            globalEl.dispatchEvent(dragEnded);
          }
        }, 1);
        return false;
      };
      el.addEventListener('dragend', dragend, false);
    }

    const newContentButtonDropZone = document.getElementById('dropzone');
    if (newContentButtonDropZone) {
      addListener(newContentButtonDropZone);
    }
    addMouseListener(el, start, cancel);
    return true;
  },

  unbind(el) {
    el.removeEventListener('dragstart', {});
    el.removeEventListener('dragend', {});
    if (dropZoneElements) {
      dropZoneElements.forEach((zone) => {
        removeListener(zone);
      });
    }
    let cardid = 'newItem';
    if (CURRENT_VIEW_MODE === 'weekly' || CURRENT_VIEW_MODE === 'daily') {
      cardid = 'newListItem';
    }
    const newItemListener = document.getElementById(cardid);

    if (newItemListener) {
      removeListener(newItemListener);
    }
    const newContentButtonDropZone = document.getElementById('dropzone');
    if (newContentButtonDropZone) {
      removeListener(newContentButtonDropZone);
    }
    ['mousedown', 'touchstart'].forEach(e => el.removeEventListener(e, {}));
    ['mouseup', 'mouseout', 'touchend', 'touchcancel'].forEach(e => el.removeEventListener(e, {}));
  },
};

export default longPressToDrag;
