<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      :class="{'is-loading': formSending }"
      tabindex="-1"
    >
      <div :class="['modal-dialog modal-dialog-scrollable']">
        <div
          class="modal-content"
          v-if="!isFirstSessionDone"
        >
          <header class="modal-header">
            <h5 class="modal-title">
              {{ journeyHaveUsers ? $t('assign_journey') : $t('start_journey') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="content-pane">
              <div class="col-4 form-group px-4 pb-4">
                <label
                  for="journeyLanguage"
                  class="form-label"
                >{{ $t('first_work_day') }}</label>
                <date-picker
                  :class="{ 'is-danger': errors.has('assign.beginDate') }"
                  v-model="beginActivationDateTime"
                  :disabled-date.sync="notBeforeToday"
                  value-type="date"
                  data-vv-scope="assign"
                  data-vv-name="beginDate"
                  :data-vv-as="$t('begin_date')"
                  v-validate="'required'"
                  :first-day-of-week="userLang === 'en' ? 7 : 1"
                  :lang="userLang"
                  :format="userLang === 'en' ? 'MM.DD.YYYY' : 'DD.MM.YYYY'"
                />
                <span
                  v-if="errors.has('assign.beginDate')"
                  class="help is-danger"
                >{{ errors.first('assign.beginDate') }}</span>
              </div>
              <div class="form-group px-4 pb-4">
                <label
                  for="contentTitle"
                  class="form-label form-label--small"
                >{{ $t('start_onboarding_for') }}</label>
                <vue-tags-input
                  :class="{ 'is-danger': errors.has('assign.assignTo') }"
                  :placeholder="$t('add_name_placeholder')"
                  v-model="tag"
                  :tags="tags"
                  :add-only-from-autocomplete="true"
                  :autocomplete-items="autocompleteItems"
                  @tags-changed="update"
                />
                <input
                  type="hidden"
                  data-vv-scope="assign"
                  data-vv-name="assignTo"
                  :data-vv-as="$t('start_onboarding_for')"
                  :value="assignTo"
                  v-validate="'required'"
                >
                <span
                  v-if="errors.has('assign.assignTo')"
                  class="help is-danger"
                >{{ errors.first('assign.assignTo') }}</span>
              </div>
              <div class="form form--horizontal">
                <div class="panel-title">
                  <div
                    :class="[showAdvanceSettings ? 'active': '']"
                  >
                    <label @click="showAdvanceSettings = !showAdvanceSettings">{{ $t('advanced_tab') }}</label>
                  </div>
                </div>

                <collapse-transition
                  :duration="500"
                  dimension="height"
                >
                  <div
                    class="px-4 bglight"
                    v-show="showAdvanceSettings"
                  >
                    <div class="row mx-4 bglight">
                      <div class="col-12">
                        <div class="swicth-checkbox">
                          <input
                            type="checkbox"
                            id="sendOnSaturday"
                            checked
                            v-model="sendOnSaturday"
                            :true-value="1"
                            :false-value="0"
                          >
                          <label
                            class="swicth-label"
                            for="sendOnSaturday"
                          >
                            {{ $t('include_saturdays') }}
                          </label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="swicth-checkbox">
                          <input
                            type="checkbox"
                            id="sendOnSunday"
                            checked
                            v-model="sendOnSunday"
                            :true-value="1"
                            :false-value="0"
                          >
                          <label
                            class="swicth-label"
                            for="sendOnSunday"
                          >
                            {{ $t('include_sundays') }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row mx-4">
                      <div class="col-12 mt-4">
                        <div
                          class="form-group"
                        >
                          <label
                            for="excludedDays"
                            class="form-label"
                          >{{ $t('excluded_days') }}</label>
                          <flat-pickr
                            v-model="excludedDaysAsString"
                            :config="excludedDaysConfig"
                            class="form-control"
                            :placeholder="$t('select_dates')"
                            name="excludedDays"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </collapse-transition>
              </div>

              <div
                v-show="errors.items.length > 0"
                class="form-group"
              >
                <div class="alert alert--card alert--error">
                  <p>{{ $t('error_msg_title') }}</p>
                  <ul>
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                    >
                      {{ error.msg }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="button button--primary"
                  :disabled="isPending"
                  @click="assignJourney"
                >
                  <span
                    v-if="!isPending"
                  >
                    {{ $t('assign') }}
                  </span>
                  <div
                    class="lds-ellipsis"
                    v-if="isPending"
                  >
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal-content"
          v-if="isFirstSessionDone"
        >
          <header class="modal-header">
            <h5 class="modal-title">
              {{ journeyHaveUsers ? $t('assign_journey') : $t('start_journey') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="content-pane">
              <div class="assignment-final-msg-box">
                <icon-container
                  name="greencheck"
                  view-box="0 0 60 60"
                  :width="60"
                  :height="60"
                >
                  <icon-greencheck />
                </icon-container>
                <div class="assignment-final-title">
                  {{ $t('onboarding_assignment_final_msg') }}
                </div>
              </div>
              <div
                class="more-assignments"
                v-if="postAssignments.length"
              >
                <h5 class="modal-title m-4 text-center subtitle_borderbottom">
                  {{ $t('assign_manager_title') }}
                </h5>
                <template v-for="(assignment, indx) in postAssignments">
                  <div
                    class="form-group px-4 pb-4"
                    :key="assignment.id"
                  >
                    <label
                      for="contentTitle"
                      class="form-label form-label--small"
                    >                  <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                      {{ `${assignment.name} (${assignment.email})` }}
                    </label>
                    <vue-tags-input
                      :class="{ 'is-danger': errors.has('assign.assignTo'), 'checkdone': assignment.isDone }"
                      :placeholder="$t('assign_manager')"
                      v-model="assignmentTag[indx]"
                      :tags="assignmentTags[indx]"
                      :add-only-from-autocomplete="true"
                      :max-tags="1"
                      :autocomplete-items="autocompleteItems"
                      @before-adding-tag="(event) => addTag(event, assignment.id, indx)"
                      @before-deleting-tag="(event) => deleteTag(event, assignment.id)"
                      @tags-changed="(event) => update(event, indx)"
                      @input="onInput"
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/tr';
import 'vue2-datepicker/index.css';
import vueTagsInput from '@johmun/vue-tags-input';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import CollapseTransition from '@ivanv/vue-collapse-transition';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
// eslint-disable-next-line import/extensions
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
// eslint-disable-next-line import/extensions
import { english } from 'flatpickr/dist/l10n/default.js';
import IconContainer from '@/components/elements/Icon.vue';
import IconGreenCheck from '@/components/icons/GreenCirleCheck.vue';
import {
  API_USER_LIST,
  API_SERVER_TIME_FORMAT,
  API_USER_GROUP_DATATABLES,
  // eslint-disable-next-line no-unused-vars
  API_START_ONBARDING_TEAM,
  API_USER_MANAGER_LINK,
  API_USER_MANAGER_UNLINK,
} from '@/helpers/config';
import API from '@/services/';

export default {
  name: 'JourneyOnBoardingAssign',
  inject: ['$validator'],

  directives: {
    'on-clickaway': onClickaway,
  },
  data() {
    return {
      isPending: false,
      formSending: false,
      tag: '',
      tags: [],
      userList: [],
      groupList: [],
      isFirstSessionDone: false,
      debounce: null,
      assignTo: null,
      showAdvanceSettings: false,
      beginActivationDateTime: new Date(),
      sendOnSaturday: 0,
      sendOnSunday: 0,
      excludedDaysAsString: null,
      postAssignments: [],
      assignmentTag: [],
      assignmentTags: [],
    };
  },

  computed: {
    autocompleteItems() {
      return this.userList.concat(this.groupList);
    },
    userId() {
      return this.$store.state.auth.userInfo.id;
    },
    excludedDays() {
      let arr = [];
      if (this.excludedDaysAsString) {
        arr = this.excludedDaysAsString.split(',').map(item => item.trim());
      }
      return arr;
    },

    excludedDaysConfig() {
      const config = {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: this.userLang === 'en' ? 'm.d.Y' : 'd.m.Y',
        altInput: true,
        mode: 'multiple',
        dateFormat: 'Y-m-d',
        minDate: 'today',
        locale: this.userLang === 'en' ? english : Turkish, // locale for this instance only
      };
      return config;
    },

    journeyId() {
      return this.$store.state.modal.journeyId;
    },

    modalData() {
      return this.$store.state.modal.modalData;
    },

    journeyDetail: {
      get() {
        return this.$store.state.modal.journeyDetail;
      },
      set(newValue) {
        this.$store.state.modal.journeyDetail = newValue;
      },
    },

    journeyHaveUsers() {
      if (this.$store.state.modal.journeyDetail) {
        const totalUsers = this.$store.state.modal.journeyDetail.mobile_device_penetration.number_of_total_users;
        if (totalUsers && totalUsers > 0) {
          return true;
        }
        return false;
      }
      return false;
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },

    currentCustomerId() {
      return this.$store.state.auth.customerId;
    },

    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
  },

  components: {
    'date-picker': DatePicker,
    'vue-tags-input': vueTagsInput,
    'collapse-transition': CollapseTransition,
    'icon-container': IconContainer,
    'icon-greencheck': IconGreenCheck,
    flatPickr,
  },

  watch: {
    tag(val) {
      return this.getUsers(val);
    },
    tags(val) {
      if (val && val.length > 0) {
        this.assignTo = val;
      } else {
        this.assignTo = null;
      }
      this.$nextTick(() => {
        this.$validator.validate('assign.assignTo', this.assignTo);
      });
    },
  },

  mounted() {
    this.beginActivationDateTime.setHours(8, 0, 0);
  },

  methods: {

    notBeforeToday(date) {
      const now = new Date();
      now.setMonth(now.getMonth() - 3);
      return date < new Date(now.getFullYear(), now.getMonth(), now.getDate());
    },

    closeModal() {
      this.$validator.errors.clear();
      this.$store.commit('modal/toggleModal');
    },

    update(newTags, indx = null) {
      if (indx != null) {
        this.assignmentTags[indx] = newTags;
      } else {
        this.tags = newTags;
      }
    },
    onInput(val) {
      this.getUsers(val);
    },
    addTag(obj, id = null, index = null) {
      let managerId = null;
      let employeeId = null;

      if (id != null) {
        employeeId = id;
        managerId = obj.tag.id;
      } else {
        employeeId = obj.tag.id;
        managerId = this.userId;
      }
      this.linkManager(employeeId, managerId).then(() => {
        obj.addTag();
        if (index != null) {
          this.postAssignments[index].isDone = true;
        }
      });
    },

    deleteTag(obj, id = null) {
      let employeeId = null;
      if (id != null) {
        employeeId = id;
      } else {
        employeeId = obj.tag.id;
      }
      this.unlinkManager(employeeId).then(() => obj.deleteTag());
    },

    linkManager(employeeId, managerId) {
      const that = this;
      return new Promise((resolve, reject) => {
        if (!employeeId || !managerId) {
          reject(new Error('No Employee Id or Manager Id provided'));
        } else {
          const payload = {};
          payload.employee_id = employeeId;
          payload.manager_id = managerId;
          API.post(`${API_USER_MANAGER_LINK}`, payload)
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
              }
            })
            .catch((error) => {
              that.$helpers.displayError(error);
              reject(error);
            });
        }
      });
    },

    unlinkManager(employeeId) {
      return new Promise((resolve, reject) => {
        if (!employeeId) {
          reject(new Error('No Employee Id provided'));
        } else {
          API.delete(`${API_USER_MANAGER_UNLINK}/${employeeId}`)
            .then((response) => {
              if (response.status === 200) {
                resolve(response);
              }
            })
            .catch((error) => {
              this.$helpers.displayError(error);
              reject(error);
            });
        }
      });
    },

    getUsers(tag) {
      this.userList = [];
      this.groupList = [];
      const userData = { params: {} };
      userData.params.per_page = 10000;
      userData.params.search = null;
      console.log(JSON.stringify(tag));
      userData.params.search = tag.toString();
      if (this.currentCustomerId) {
        userData.params.customer_id = this.currentCustomerId;
      }
      if (tag.length < 2) {
        return;
      }
      const searchInput = document.activeElement; // querySelector('.ti-new-tag-input');
      searchInput.classList.add('loading-icon');
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getUserGroups(tag);
        API.get(API_USER_LIST, userData)
          .then((response) => { // eslint-disable-next-line
            this.userList = response.data.data.map((item) => {
              return { // eslint-disable-next-line
                text: `${item.first_name} ${item.last_name} [${item.email}]`,
                type: 'user',
                id: item.id,
              };
            });
            searchInput.classList.remove('loading-icon');
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            searchInput.classList.remove('loading-icon');
          });
      }, 300);
    },

    getUserGroups(tag) {
      const userData = { params: {} };
      userData.params.per_page = 10000;
      userData.params.search = tag;
      if (this.currentCustomerId) {
        userData.params.customer_id = this.currentCustomerId;
      }
      API.get(API_USER_GROUP_DATATABLES, userData)
        .then((response) => { // eslint-disable-next-line
          this.groupList = response.data.data.map((item) => {
            return {
              text: `[User Group] ${item.name}`,
              id: item.id,
              type: 'group',
            };
          });
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },
    async checkValidation() {
      let assignToIsValid = false;
      let beginDateIsValid = false;

      await this.$validator.validate('assign.assignTo').then((res) => {
        if (res) {
          assignToIsValid = true;
        }
      });
      await this.$validator.validate('assign.beginDate').then((res) => {
        if (res) {
          beginDateIsValid = true;
        }
      });

      if (assignToIsValid && beginDateIsValid) {
        return true;
      }
      return false;
    },

    assignJourney() {
      this.checkValidation().then((res) => {
        if (res) {
          this.formSending = true;
          this.isPending = true;
          const formData = new FormData();
          const activationDate = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT);
          const journeyId = this.journeyId || this.$route.params.journeyId;
          const users = [...this.assignTo].map(({ id, type }) => ({ id, type }));
          formData.append('activation_date', activationDate);
          formData.append('journey_id', Number(journeyId));
          formData.append('include_saturday', Number(this.sendOnSaturday));
          formData.append('include_sunday', Number(this.sendOnSunday));
          formData.append('excluded_days', JSON.stringify(this.excludedDays));
          // eslint-disable-next-line no-param-reassign
          formData.append('users', JSON.stringify(users));
          if (this.isVibonsAdmin) {
            formData.append('customer_id', Number(this.currentCustomerId));
          }

          API.post(API_START_ONBARDING_TEAM, formData)
            .then((response) => {
              this.isFirstSessionDone = true;
              this.postAssignments = response.data.users_without_a_manager;
              this.formSending = false;
              this.isPending = false;
            })
            .catch((error) => {
              this.formSending = false;
              this.isPending = false;
              this.$helpers.displayError(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.is-danger {
  border-color: #f27299;
}

.bglight {
  background: #f8f9ff;
  padding-top:30px;
  padding-bottom: 30px;
}
.bglight .form-group {
  border-bottom-width:0!important;
}

.schedule-title {
  text-align: center;
  margin-bottom: 30px!important;
}
.panel-title {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #727d92;
  text-align: center;

}
.panel-title label {
  cursor: pointer;
}
.panel-title label:after {
  content: "\032C";
  left:20px;
  position: relative;
  top: -8px;
  font-size:30px;
  transition: all 1s;
}
 .panel-title.active label:after {
   content: "\032D";
}
.multiselect {
    max-width: max-content;
}
.hint {
  position: absolute;
  text-align: center;
  background-color: #142b58;
  opacity: 0.8;
  bottom: 0;
  z-index: 100;
  width: 460px;
  left: calc(50% - 230px);
  img {
    width: 180px;
    height: auto;
  }
  .title {
    color: #fff;
    font-size: 18px;
    text-align: center;
  }
}
.assignment-final-msg-box {
  display: flex;
  margin: 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    margin-bottom: 20px;
  }
  .assignment-final-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.269231px;
    color: #2DCF85;
  }
}
.more-assignments {
  padding-top: 10px;
  .subtitle_borderbottom {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(114, 125, 146, 0.25);
    margin: 0px -30px 20px;
  }
}
.checkdone {
  &:after {
    content: '';
    width: 30px!important;
    height: 30px!important;
    position: absolute;
    right: 10px!important;
    top: 0;
    bottom: 0;
    border: 0;
    margin: auto!important;
    background: transparent url('~@/assets/img/greencheck.png') no-repeat;
    background-size: contain;

  }
  .ti-input {
    border: 1px solid #2dcf85;
  }
}
</style>
