var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "colgroup",
    [
      _vm._l(_vm.vuetable.tableFields, function (field, fieldIndex) {
        return [
          field.visible
            ? [
                _c("col", {
                  key: fieldIndex,
                  class: _vm.columnClass(field),
                  style: { width: field.width },
                }),
              ]
            : _vm._e(),
        ]
      }),
      _vm.isHeader && _vm.vuetable.scrollVisible
        ? _c("col", {
            staticClass: "vuetable-col-gutter",
            style: { width: _vm.vuetable.scrollBarWidth },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }