<template>
  <nav
    class="navigation"
    :class="'navigation--' + type"
  >
    <slot />
  </nav>
</template>

<script>
console.log('AppNavigation');
export default {
  name: 'AppNavigation',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    type: String,
  },
};
</script>

<style lang="scss">
.navigation {
  &,
  &__list,
  &__item {
    height: 100%;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    @extend .d-none;
    @extend .d-md-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
  }

  &__item:not(:last-child) {
    padding-right: 30px;
  }

  &__link {
    opacity: 0.5;
    font-size: 12px;
    color: $white;
    text-transform: uppercase;
    height: 100%;
    @include primary-bold;
    @extend .d-flex;
    @extend .align-items-center;
  }

  &__item .navigation__link.router-link-exact-active,
  .navigation__link:hover {
    opacity: 1;
  }

  &.navigation--secondary {
    width: 100%;
    height: 48px;
    background-color: #fff;
    border-bottom: 1px solid $secondaryColorLight;

    .container {
      height: 100%;
    }

    .navigation__link {
      font-size: 14px;
      font-weight: 500;
      color: $secondaryColor;
      height: 100%;
      text-transform: none;
      position: relative;
    }

    .navigation__link.router-link-exact-active,
    .navigation__link:hover {
      color: $alternativeColor;

      &:after {
        width: 100%;
        height: 3px;
        background: $alternativeColor;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}
</style>
