<script>
import API from '@/services/';
import {
  API_USER_DEVICE_STATUS,
} from '@/helpers/config';

console.log('Tablemixing');

export default {
  data() {
    return {
      timer: null,
      tooltipText: '',
      snotifyQueueList: [],
      idHolder: null,
      toastHolder: null,
      apiUrlHolder: null,
      dropdownIndex: null,
      debounce: null,
      moreParams: {
        // customer_id: this.$store.state.auth.customerId,
      },
      filterText: '',
      searchFor: '',
      perPage: 10,
      runOnce: true,
      tableIsLoading: false,
      secondTableIsLoading: false,
      css: {
        table: {
          tableWrapper: '',
          tableHeaderClass: 'mb-0',
          tableBodyClass: 'mb-0',
          tableClass: '',
          loadingClass: 'blur',
          ascendingIcon: 'fa fa-angle-up',
          descendingIcon: 'fa fa-angle-down',
          ascendingClass: 'sorted-asc',
          descendingClass: 'sorted-desc',
          sortableIcon: '',
          detailRowClass: 'vuetable-detail-row',
          handleIcon: 'fa-bars text-secondary',
          renderIcon(classes, options) {
            return `<i class="${classes.join(' ')}" ${options}></span>`;
          },
        },
        pagination: {
          wrapperClass: 'page-items-list',
          activeClass: 'active',
          disabledClass: 'disabled',
          pageClass: 'page-item',
          linkClass: 'page-link',
          paginationClass: 'pagination',
          paginationInfoClass: 'float-left',
          dropdownClass: 'form-control',
          icons: {
            first: 'fa fa-angle-double-left',
            prev: 'fa fa-angle-left',
            next: 'fa fa-angle-right',
            last: 'fa fa-angle-double-right',
          },
        },
      },
    };
  },

  watch: {
    searchFor: {
      handler(newVal) {
        if (newVal && this.$refs.myvuetable) {
          clearTimeout(this.debounce);
          this.debounce = setTimeout(() => {
            this.moreParams.search = newVal;
            this.$refs.myvuetable.refresh();
          }, 500);
        } else if (this.$refs.myvuetable) {
          this.moreParams.search = '';
          this.$refs.myvuetable.refresh();
        }
      },
      immediate: false,
    },
    moreParams: {
      handler(newVal) {
        if (newVal && this.$refs.myvuetable) {
          clearTimeout(this.debounce);
          this.debounce = setTimeout(() => {
            this.$refs.myvuetable.refresh();
          }, 500);
        } else if (this.$refs.myvuetable) {
          this.$refs.myvuetable.refresh();
        }
      },
      immediate: false,
      deep: true,
    },

    perPage: {
      handler(newVal, oldVal) {
        console.log('perPage:', newVal, oldVal);
        if (newVal && newVal !== oldVal && this.$refs.myvuetable) {
          clearTimeout(this.debounce);
          this.debounce = setTimeout(() => {
            this.$refs.myvuetable.refresh();
          }, 500);
        }
      },
      immediate: false,
    },

    data() {
      // this.$refs.myvuetable.refresh();
    },
  },

  computed: {
    refreshPage: {
      get() {
        return this.$store.state.modal.refreshPage;
      },
      set(newValue) {
        this.$store.state.modal.refreshPage = newValue;
      },
    },

    pageToRefresh: {
      get() {
        return this.$store.state.modal.pageToRefresh;
      },

      set(newValue) {
        this.$store.state.modal.pageToRefresh = newValue;
      },
    },

    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },
  },
  deactivated() {
    this.runOnce = true;
  },
  methods: {
    nameGenerated(props) {
      let newname = '';
      let extended = '';
      if (props) {
        const { name } = props.rowData;
        if (props.rowData.packets) {
          props.rowData.packets.forEach((packet, index) => {
            extended += `[${packet}]`;
            if (props.rowData.packets[index + 1]) {
              extended += ', ';
            }
          });
        }
        if (extended) {
          newname = `${name} - ${extended}`;
        } else {
          newname = `${name}`;
        }
      }
      return newname;
    },
    vuetableLoadError(payload) {
      if (payload && payload.response && payload.response.status === 403) {
        this.$store.dispatch('auth/reset').then(() => {
          localStorage.removeItem(process.env.VUE_APP_STORE_PREFIX);
        });
      } else if (payload && payload.response && (payload.response.status === 400 || payload.response.status === 404)) {
        setTimeout(() => {
          if (this.runOnce) {
            this.$refs.myvuetable.reload();
            this.runOnce = false;
          }
        }, 3000);
      }
    },
    // eslint-disable-next-line consistent-return
    myFetch(apiUrl, httpOptions) {
      // console.log('APIURL:', apiUrl, 'options:', httpOptions);
      if (this.$refs.myvuetable.httpMethod === 'get') {
        return API.get(apiUrl, httpOptions);
      } if (this.$refs.myvuetable.httpMethod === 'post') {
        const { params } = httpOptions;
        // eslint-disable-next-line no-param-reassign
        delete httpOptions.params;
        return API.post(apiUrl, params, httpOptions);
      }
      return API.get(apiUrl, httpOptions);
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },

    onSecondPaginationData(paginationData) {
      this.$refs.secondPagination.setPaginationData(paginationData);
      this.$refs.secondPaginationInfo.setPaginationData(paginationData);
    },

    onChangePage(page) {
      this.$refs.myvuetable.changePage(page);
    },

    onSecondChangePage(page) {
      this.$refs.mysecondvuetable.changePage(page);
    },

    makeQueryParams(sortOrder, currentPage, perPage) {
      // console.log('JSON:', JSON.stringify(sortOrder, null, 2));
      if (sortOrder.length > 0) {
        let sortString = sortOrder[0].sortField;
        if (sortString.endsWith('-slot')) {
          sortString = sortString.replace('-slot', '');
        } else if (sortString === 'name') {
          sortString = 'first_name';
        }
        return {
          sort: sortString,
          order: sortOrder[0].direction,
          page: currentPage,
          per_page: perPage,
        };
      }
      return {
        page: currentPage ?? 1,
        per_page: this.perPage,
      };
    },

    hideDropdownAction() {
      this.timer = setTimeout(() => {
        this.dropdownIndex = null;
      }, 750);
    },

    dropdownAction(value) {
      clearTimeout(this.timer);
      this.dropdownIndex = value;
      setTimeout(() => {
        const elem = this.$refs[value];
        if (elem) {
          const isOut = this.$helpers.isOutOfScreen(elem);
          if (isOut.right) {
            elem.classList.add('right');
          } else if (isOut.left) {
            elem.classList.add('left');
          } else if (isOut.bottom) {
            elem.classList.add('bottom');
          } else if (isOut.top) {
            elem.classList.add('top');
          }
        }
      }, 1);
    },

    hideDropdown() {
      this.isDropdown = null;
      this.timer = setTimeout(() => {
        this.dropdownIndex = null;
      }, 750);
    },

    dropdownShow(value) {
      this.isDropdown = value;
    },

    dropdownToggle(value) {
      clearTimeout(this.timer);
      this.dropdownIndex = value;
      setTimeout(() => {
        const elem = this.$refs[value];
        if (elem) {
          const isOut = this.$helpers.isOutOfScreen(elem);
          if (isOut.right) {
            elem.classList.add('right');
          } else if (isOut.left) {
            elem.classList.add('left');
          } else if (isOut.bottom) {
            elem.classList.add('bottomjourneyactive');
          } else if (isOut.top) {
            elem.classList.add('top');
          }
        }
      }, 1);
    },

    deleteConfirm(toast, itemId, apiUrl) {
      this.$snotify.remove(toast.id);
      const snotifyIndex = this.snotifyQueueList.indexOf(itemId);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.tableIsLoading = true;
      API.delete(`${apiUrl}/${itemId}`)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.message) {
              this.$snotify.success(response.data.message);
            } else {
              this.$snotify.success(this.$t('processed_success'));
            }
            this.$refs.myvuetable.refresh();
            setTimeout(() => {
              this.tableIsLoading = false;
            }, 400);
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
          this.tableIsLoading = false;
        });
    },

    deleteCancel(toast, itemId) {
      const snotifyIndex = this.snotifyQueueList.indexOf(itemId);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.$snotify.remove(toast.id);
    },

    eventHandler(event) {
      event.preventDefault();
      const key = event.key || event.keyCode;
      if (key === 'Enter' || key === 13) {
        this.deleteConfirm(this.toastHolder, this.idHolder, this.apiUrlHolder);
      } else if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.deleteCancel(this.toastHolder, this.idHolder);
      }
    },

    async getMobilStatus(id, refim) {
      if (this.isVibonsAdmin) {
        this.$refs[refim].style.display = 'block';
        await API.get(`${API_USER_DEVICE_STATUS}/${id}`).then((response) => {
          this.tooltipText = `Updated at: ${response.data.data.updated_at}\nNotification Enabled: ${!!response.data.data.is_notifications_enabled}\n`;
          Object.entries(response.data.data.data).forEach(([key, value]) => {
            this.tooltipText += `${key}: ${value}\n`;
          });
        }).catch(() => {
          this.tooltipText = 'No data yet!';
        });
      }
    },

    closeTooltip(refim) {
      if (refim && this.$refs[refim]) {
        this.$refs[refim].style.display = 'none';
        this.tooltipText = null;
      }
    },

    setSearchFor(value) {
      this.searchFor = value;
    },

    setPerPage(value) {
      this.perPage = value;
    },
  },
};
</script>
