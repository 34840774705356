import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

import Login from '@/container/auth/Login.vue';
import Journey from '@/container/Journey.vue';
import JourneyV2 from '@/container/JourneyV2.vue';
import JourneyGroup from '@/container/JourneyGroup.vue';
import JourneyGroupjourneys from '@/container/JourneyGroupJourneys.vue';
import JourneyUsers from '@/container/JourneyUsers.vue';
import JourneyPlanned from '@/container/JourneyPlanned.vue';
import JourneyItems from '@/container/JourneyItems.vue';
import JourneyItemsV2 from '@/container/JourneyItemsV2.vue';
import Customers from '@/container/Customers.vue';
import Communication from '@/container/Communication.vue';
import Pins from '@/container/Pins.vue';
import Users from '@/container/Users.vue';
import DashboardUsers from '@/container/dashboard/Users.vue';
import ReportJourneys from '@/container/dashboard/ReportJourneys.vue';
import ReportJourneysV2 from '@/container/dashboard/ReportJourneysV2.vue';
import ReportJourneyUsers from '@/container/dashboard/ReportJourneyUsers.vue';
import ReportJourneyV2Users from '@/container/dashboard/ReportJourneyV2Users.vue';
import ReportJourneyContents from '@/container/dashboard/ReportJourneyContents.vue';
import ReportJourneyV2Contents from '@/container/dashboard/ReportJourneyV2Contents.vue';
import ReportJourneyUserJourneys from '@/container/dashboard/ReportJourneyUserJourneys.vue';
import ReportJourneyUserJourneyInside from '@/container/dashboard/ReportJourneyUserJourneyInside.vue';
import DashboardUserDetail from '@/container/dashboard/UserDetail.vue';
import DashboardOverview from '@/container/dashboard/Overview.vue';
import AverageRatings from '@/container/dashboard/AverageRatings.vue';
import UserGroupAnalytics from '@/container/dashboard/UserGroupAnalytics.vue';
import UserGroupOverview from '@/container/dashboard/UserGroupOverview.vue';
import DashboardCustomers from '@/container/dashboard/Customers.vue';
import DashboardCustomerDetail from '@/container/dashboard/CustomerDetail.vue';
import DashboardQuizSurvey from '@/container/dashboard/QuizSurvey.vue';
import DashboardReportDemands from '@/container/dashboard/ReportDemands.vue';
import Groups from '@/container/Groups.vue';
import Buddies from '@/container/Buddies.vue';
import GroupUsers from '@/container/GroupUsers.vue';
import Managers from '@/container/Managers.vue';
import ManagerUserDevReport from '@/container/ManagerUserDevRep.vue';
import Contents from '@/container/contents/Index.vue';

const Zoom = () => import('@/components/Zoom.vue');

Vue.use(Router);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/communication',
    name: 'Communication',
    component: Communication,
    meta: {
      requiresAuth: true,
      allowCommunication: true,
    },
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    meta: {
      requiresAuth: true,
      isVibonsAdmin: true,
    },
  },
  {
    path: '/pins',
    name: 'Pins',
    component: Pins,
    meta: {
      requiresAuth: true,
      isVibonsAdmin: true,
    },
  },
  {
    path: '/dashboard',
    redirect: '/dashboard/overview',
  },
  {
    path: '/dashboard/overview',
    name: 'DashboardOverview',
    component: DashboardOverview,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/journeys',
    name: 'ReportJourneys',
    component: ReportJourneys,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/journeys/users/:journeyId',
    name: 'ReportJourneyUsers',
    component: ReportJourneyUsers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/journeys/users/:journeyId/user/:userId',
    name: 'ReportJourneyUserJourneys',
    component: ReportJourneyUserJourneys,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/journeys/users/:journeyId/user/:userId/:insideJourneyId',
    name: 'ReportJourneyUserJourneyInside',
    component: ReportJourneyUserJourneyInside,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/journeys/contents/:journeyId',
    name: 'ReportJourneyContents',
    component: ReportJourneyContents,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/journeys_v2',
    name: 'ReportJourneysV2',
    component: ReportJourneysV2,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/journeys_v2/users/:journeyId',
    name: 'ReportJourneyV2Users',
    component: ReportJourneyV2Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/journeys_v2/contents/:journeyId',
    name: 'ReportJourneyV2Contents',
    component: ReportJourneyV2Contents,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/users',
    name: 'DashboardUsers',
    component: DashboardUsers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/users/detail/:userId',
    name: 'DashboardUserDetail',
    component: DashboardUserDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/customers',
    name: 'DashboardCustomers',
    component: DashboardCustomers,
    meta: {
      requiresAuth: true,
      isVibonsAdmin: true,
    },
  },
  {
    path: '/dashboard/quiz-survey',
    name: 'DashboardQuizSurvey',
    component: DashboardQuizSurvey,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/customers/detail/:customerId',
    name: 'DashboardCustomerDetail',
    component: DashboardCustomerDetail,
    meta: {
      requiresAuth: true,
      isVibonsAdmin: true,
    },
  },
  {
    path: '/dashboard/overview/average-ratings',
    name: 'AverageRatings',
    component: AverageRatings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/overview/usergroup-analytics',
    name: 'UserGroupAnalytics',
    component: UserGroupAnalytics,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/overview/usergroup-overview/:userGroupId',
    name: 'UserGroupOverview',
    component: UserGroupOverview,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/reportdemands',
    name: 'ReportDemands',
    component: DashboardReportDemands,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/',
    name: 'journey',
    component: Journey,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/journey_v2',
    name: 'journeyV2',
    component: JourneyV2,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/journey_v2/detail/:journeyId',
    name: 'journey_item_detail_v2',
    component: JourneyItemsV2,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/journeygroup',
    name: 'journeyGroup',
    component: JourneyGroup,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/journeygroup/:journeyGroupId',
    name: 'journeyGroupjourney',
    component: JourneyGroupjourneys,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/plannedjourney',
    name: 'journeyPlanned',
    component: JourneyPlanned,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/journey/detail/:journeyId',
    name: 'JourneyItems',
    component: JourneyItems,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/buddies',
    name: 'Buddies',
    component: Buddies,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/group/users/:groupId',
    name: 'GroupUsers',
    component: GroupUsers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/managers',
    name: 'Managers',
    component: Managers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/managers/devreport/:userId',
    name: 'ManagerUserDevReport',
    component: ManagerUserDevReport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/journey/users/:journeyId',
    name: 'JourneyUsers',
    component: JourneyUsers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/contents',
    name: 'Contents',
    component: Contents,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/zoom',
    props: true,
    name: 'Zoom',
    component: Zoom,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new Router({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (from) {
      localStorage.setItem('lastRoutePathName', to.fullPath);
    }
    if (store.getters['auth/token'] === null) {
      next({
        path: '/login',
      });
    } else if (to.meta.isVibonsAdmin && !store.state.auth.isVibonsAdmin) {
      next({
        path: '/',
      });
    } else if (to.meta.allowCommunication && !store.state.auth.userInfo.customer.services.includes('Communication')) {
      next({
        path: '/',
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
