<template>
  <div class="modal-container">
    <div
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body">
            <div class="notif-content">
              <icon-container
                class="icon"
                name="time"
                width="30"
                height="30"
                color="#2dcf85"
                view-box="0 0 512 512"
              >
                <icon-time />
              </icon-container>
              <h2>
                {{ importType === 'badi' ? $t('we_are_generating_badi'):$t('we_are_generating') }}
              </h2>
              <!-- eslint-disable-next-line -->
              <p v-html="$t('report_notification_desc')" />
              <!-- eslint-disable-next-line -->
              <p v-html="importType === 'badi' ? $t('report_notification_badi_desc_two'):$t('report_notification_desc_two')" />
              <div class="survey-preview--button">
                <button
                  type="button"
                  class="button button--primary"
                  @click="closeModal"
                >
                  <span>
                    {{ $t('close') }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTime from '@/components/icons/Time.vue';
import IconContainer from '@/components/elements/Icon.vue';

export default {
  name: 'ModalReportNotification',
  components: {
    'icon-container': IconContainer,
    'icon-time': IconTime,
  },
  data() {
    return {
    };
  },
  computed: {
    importType() {
      if (this.$store.state.modal && this.$store.state.modal.importType) {
        return this.$store.state.modal.importType;
      }
      return false;
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('modal/toggleModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  h6 {
    font-weight: bold;
    color: #727D92;
  }
  p {
    text-align: center;
    margin-top: 1rem;
    font-weight: 500;
    a {
      font-weight: bold;
    }
  }
}

.notif-content {
    width: 90%;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: 'center';
    text-align: center;
}
h2 {
    font-size: 1.5rem;
    font-weight: 700;
}
p {
    font-size: 1rem;
    font-weight: 500;
}
.icon {
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 10px;
}
</style>
