<template>
  <div class="page">
    <app-header
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
      :add-bulk-badi="true"
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>
    <div class="container">
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="post"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="buddy_name"
            slot-scope="props"
          >
            <span>{{ getBuddyName(props.rowData) }}</span>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import myTableMixin from '@/helpers/myTableMixin.vue';

import AppHeader from '@/components/AppHeader.vue';
import {
  API_GET_BUDDIES,
  API_SERVER_TIME_FORMAT,
} from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import VuetablePagination from '../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'Buddies',
  mixins: [myTableMixin],
  data() {
    return {
      moreParams: {
        customer_id: this.$store.state.auth.customerId,
      },
    };
  },

  components: {
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'Buddies') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 1000);
      }
    },
  },

  computed: {
    ...mapState(['modal']),
    apiUrl() {
      if (API_GET_BUDDIES) {
        return `${API_GET_BUDDIES}`;
      }
      return null;
    },

    fields() {
      return [
        {
          name: 'manager_name',
          title: this.$t('manager_name'),
          sortField: 'manager_name',
          width: '33%',
        },
        {
          name: 'employee_name',
          title: this.$t('newhire_name'),
          sortField: 'employee_name',
          titleClass: 'text-left',
          width: '33%',
        },
        {
          name: 'buddy_name',
          title: this.$t('buddy_name'),
          titleClass: 'text-left',
          sortField: 'buddy_name',
          width: '33%',
        }];
    },

    userSubNavigation() {
      return [
        {
          text: this.$t('users'),
          url: '/users',
        },
        {
          text: this.$t('groups'),
          url: '/groups',
        },
        {
          text: this.$t('managers'),
          url: '/managers',
        },
        {
          text: this.$t('buddies'),
          url: '/buddies',
        },
      ];
    },
  },

  methods: {
    getBuddyName(data) {
      // a- activation dt>now ve buddy_name = "" ise saat iconu çıkaralım.
      // b- activation dt <=now iken
      //      buddy_name = "" ise "-" yazdıralım.
      //      null değil ise buddy_name'i yazdıralım.
      if (data.buddy_name) {
        return data.buddy_name;
      }
      const now = new Date();
      const checkDate = moment(data.activation_dt, API_SERVER_TIME_FORMAT).isAfter(now);
      if (checkDate) {
        return '<i class="saat"><svg data-v-03af735e="" data-v-c4f7c18e="" xmlns="http://www.w3.org/2000/svg" version="1.2" role="presentation" preserveAspectRatio="xMidYMid meet" color="currentColor" width="15" height="15" viewBox="0 0 512 512" aria-labelledby="time" data-drop-effect="none" class="icon"><path data-v-c4f7c18e="" fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z" data-v-03af735e=""></path></svg></i>';
      }
      return '-';
    },
  },
};
</script>
