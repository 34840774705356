<template>
  <div
    class="tab-pane show active"
  >
    <div
      class="form-group"
      :class="{ 'is-danger': errors.has('flipbook.flipbook') }"
    >
      <label
        for="desc"
        class="form-label"
      >{{ $t('upload_flippages') }}</label>
      <draggable-comp
        class="previews"
        ghost-class="ghost"
        :disabled="disabled"
        :list="thumbnails"
        :force-fallback="true"
        :move="checkMove"
        @start="dragging = true"
        @end="endDraggable()"
      >
        <transition-group
          type="transition"
          name="slidepreivew"
          class="fliplist"
          tag="ul"
        >
          <template>
            <li
              class="preview"
              v-for="(item, index) in thumbnails"
              :key="'flip' + index"
              :style="{'--i': index}"
            >
              <span class="preview__count">{{ index + 1 }}</span>
              <img
                :src="item.image"
                alt=""
              >
              <button
                class="remove"
                :disabled="disabled || isFileUploading"
                @click.prevent="removePreviewItem(index)"
              >
                <icon-container
                  :width="15"
                  :height="15"
                  view-box="0 0 22 22"
                  name="trash"
                  :is-icon-class="false"
                >
                  <icon-trash />
                </icon-container>
              </button>
            </li>
          </template>
          <template>
            <li
              class="preview preview-image"
              v-for="(item, index) in uploadingFiles"
              v-show="isFileUploading"
              :key="'prevflip' + index"
              :style="{'--i': index + (thumbnails.length ? thumbnails.length - 1 : 0) }"
            >
              <span class="preview__count">{{ index + 1 + (thumbnails.length ? thumbnails.length - 1 : 0) }}</span>
              <img
                :style="{opacity: getUploadProgress(item.id)/100}"
                :src="item.preview"
                alt=""
              >
              <div
                v-if="getUploadStatus(item.id)"
                class="preview-error"
                role="alert"
              >
                {{ $t('upload_error') }}
              </div>
              <progress-bar
                class="progressbarline"
                bar-color="#4a4"
                v-if="getUploadProgress(item.id) < 101 && !getUploadStatus(item.id)"
                size="small"
                :font-size="10"
                :val="getUploadProgress(item.id)"
                text-position="middle"
                text-fg-color="#4558FB"
                text-align="center"
                :text="$t('rate_info', { rate: getUploadProgress(item.id) })"
              />
              <button
                class="preview-remove"
                :disable="isFileUploading"
                @click.prevent="deleteTempPreviewItem(item.id)"
              >
                <icon-container
                  :width="15"
                  :height="15"
                  view-box="0 0 22 22"
                  name="trash"
                  :is-icon-class="false"
                >
                  <icon-trash />
                </icon-container>
              </button>
            </li>
          </template>
          <li
            key="create"
            class="flip_card card card--upload"
          >
            <input
              type="file"
              name="photo"
              id="photo"
              :disabled="disabled || isFileUploading"
              accept=".png, .jpg, .jpeg"
              @change="readFile"
              multiple
            >
            <input
              type="hidden"
              name="photos"
              multiple
              :value="thumbnailUrls"
              data-vv-scope="flipbook"
              data-vv-name="flipbook"
              :data-vv-as="$t('flip_page')"
              v-validate="'required|min:2'"
            >
            <span class="flip">
              <span class="media-object media-object--row">
                <span class="media-object__media">
                  <icon-container
                    name="circleplusfull"
                    view-box="0 0 512 512"
                    :width="45"
                    :height="45"
                    :is-icon-class="false"
                  >
                    <icon-circleplusfull />
                  </icon-container>
                </span>
              </span>

            </span>
          </li>
        </transition-group>
      </draggable-comp>
      <span
        v-show="errors.has('flipbook.flipbook')"
        class="help is-danger"
      >{{ errors.first('flipbook.flipbook') }}</span>
    </div>
    <div
      v-show="enoughFlipExist"
      class="form-group"
      :class="{ 'is-danger': errors.has('flipbook.title') }"
    >
      <label
        for="contentTitle"
        class="form-label"
      >{{ $t('title').toUpperCase() }}</label>
      <input
        type="text"
        class="form-control"
        id="contentTitle"
        v-model="title"
        :disabled="disabled && !canEditOnlyItem"
        data-vv-scope="flipbook"
        data-vv-name="title"
        :data-vv-as="$t('title')"
        v-validate="'required|max:100'"
        @input="handleTitle"
      >
      <span
        v-show="errors.has('flipbook.title')"
        class="help is-danger"
      >{{ errors.first('flipbook.title') }}</span>
    </div>
    <div
      v-show="thumbnails.length > 1"
      class="quiz-assubcomponent"
    >
      <div class="title">
        <h5 class="modal-title">
          {{ $t('add_question') }}
        </h5>
        <div
          class="swicth-checkbox"
        >
          <input
            type="checkbox"
            id="isAddingQuestion"
            v-model="isAddingQuestion"
            :true-value="1"
            :false-value="0"
          >
          <label
            class="swicth-label"
            for="isAddingQuestion"
          />
        </div>
      </div>
      <div class="warning-box">
        <div class="desc-text">
          <icon-container
            width="27"
            height="30"
            view-box="0 0 27 30"
            name="lamp"
            color="#727d92"
          >
            <icon-lamp />
          </icon-container>
          <div class="description">
            {{ $t('quiz_remark') }}
          </div>
        </div>
      </div>
      <collapse-transition
        :duration="500"
        dimension="height"
        v-show="isAddingQuestion"
      >
        <quiz-component
          :disabled="disabledTemp"
          :can-edit-only-item="canEditOnlyItemTemp"
          :quiz-type="'content'"
          as-scope="flipbookquiz"
          :repetitive_max_question_per_day="repetitiveMaxQuestionPerDayTemp"
          :repetitive_correct_answer_count="repetitiveCorrectAnswerCountTemp"
        />
      </collapse-transition>
    </div>
    <div
      v-show="errors.items.length > 0 && enoughFlipExist"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="button button--primary"
        @click="editMode ? updateContent() : createContent()"
        :disabled="isPending || (disabled && !canEditOnlyItem) || isFileUploading"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Draggable from 'vuedraggable';
import ProgressBar from 'vue-simple-progress';
import IconTrash from '@/components/icons/Trash.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconCirclePlusFull from '@/components/icons/CirclePlusFull.vue';

import {
  API_CONTENT_INFOGRAPHICS,
} from '@/helpers/config';
import API from '@/services/';
import subQuizMixin from '../../../helpers/subQuizMixin.vue';

export default {
  name: 'FlipbookContent',
  inject: ['$validator'],
  mixins: [subQuizMixin],
  components: {
    'draggable-comp': Draggable,
    ProgressBar,
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-circleplusfull': IconCirclePlusFull,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    thumbnailUrls: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPending: false,
      thumbnails: [],
      dragging: false,
      title: '',
      uploadIndex: 0,
    };
  },

  computed: {
    modalData() {
      return this.$store.state.modal.modalData;
    },

    uploadingFiles() {
      return this.$store.state.modal.uploadFileStatus;
    },

    ...mapGetters({
      getUploadStatus: 'modal/getFileUploadStatus',
      isFileUploading: 'modal/isFileUploading',
      getUploadProgress: 'modal/getFileUploadProgress',
    }),

    journeyDetail: {
      get() {
        return this.$store.state.modal.journeyDetail;
      },
      set(newValue) {
        this.$store.state.modal.journeyDetail = newValue;
      },
    },

    enoughFlipExist() {
      if (this.thumbnails.length > 1) {
        if (this.editMode === false) {
          const title = this.title || this.$helpers.getFileNameOnly(this.thumbnails[0]);
          this.handleTitle(title);
        }
        return true;
      }
      return false;
    },
  },

  deactivated() {
    this.$store.commit('modal/resetUploadFileStatus');
    if (!this.editMode) {
      this.clearPageFromKeepAlive();
    }
  },

  watch: {
    thumbnailUrls: {
      handler(newVal) {
        if (newVal) {
          this.thumbnails = newVal;
          this.$validator.errors.clear();
          const error = {
            field: 'flipbook',
            msg: this.$t('flipbook_min_image_msg'),
            scope: 'flipbook',
          };
          if (newVal.length < 2 && newVal.length > 0) {
            this.$validator.errors.add(error);
          }
        }
      },
      immediate: true,
    },

    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },

    isFileUploading: {
      handler(val) {
        if (!val) {
          this.$store.commit('modal/resetUploadFileStatus');
        }
      },
      immediate: false,
    },
  },

  methods: {
    clearPageFromKeepAlive() {
      const myKey = this.$vnode.key;
      const keepAlive = this.$vnode.parent?.componentInstance;
      delete keepAlive.cache[myKey];
      keepAlive.keys = keepAlive.keys.filter(k => k !== myKey);
      this.$destroy();
    },

    async readFile(event) {
      const input = event.target;
      const { files } = input;
      const uploadedItems = [];
      await Promise.all(Array.from(files).map(async file => new Promise(async (resolve, reject) => {
        const preview = await this.readFileAsync(file);
        this.uploadIndex += 1;
        try {
          this.createFlipbookPreview({ index: this.uploadIndex, file, preview }).then((response) => {
            uploadedItems.push(response);
            resolve();
          });
        } catch (error) {
          console.log('reading image error:', error);
          reject();
        }
      })));
      const sorted = this.$helpers.sortByName(uploadedItems);
      const uniq = new Set(sorted.map(e => JSON.stringify(e)));
      const final = Array.from(uniq).map(e => JSON.parse(e));
      this.$emit('flipEvent', final);
      this.$emit('flipSort');
    },

    createFlipbookPreview(flip) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('customer_id', this.$store.state.auth.customerId);
        formData.append('name', 'Z-Flipbook');
        formData.append('desc', '');
        formData.append('info', 'Flipbook Page');
        formData.append('lang', this.$parent.contentLanguage.name);
        formData.append('subtype_id', 1);
        formData.append('is_email_enabled', '1');
        formData.append('is_notifications_enabled', '1');
        formData.append('push_notification_title', '1');
        formData.append('push_notification_body', '1');
        formData.append('is_reminder_enabled', '1');
        formData.append('tags', '');
        formData.append('thumbnail', flip.file);
        formData.append('file', flip.file);
        let $id;
        const self = this;
        try {
          API.post(API_CONTENT_INFOGRAPHICS, formData,
            {
              onUploadProgress: (progressEvent) => {
                self.$store.commit('modal/setUploadFileStatus', {
                  id: flip.index,
                  preview: flip.preview,
                  progress: parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10),
                  hasError: false,
                });
              },
            }).then((response) => {
            $id = response.data.id;
            self.$store.commit('modal/setUploadFileStatus', { id: flip.index, progress: 101, hasError: false });
            resolve({ id: $id, name: flip.file.name, image: flip.preview });
          }).catch((error) => {
            self.$helpers.displayError(error);
            self.$store.commit('modal/setUploadFileStatus', { id: flip.index, progress: 10, hasError: true });
            reject();
          });
        } catch (error) {
          self.$helpers.displayError(error);
          self.$store.commit('modal/setUploadFileStatus', { id: flip.index, progress: 10, hasError: true });
          reject();
        }
      });
    },

    deleteTempPreviewItem(itemId) {
      this.$store.dispatch('modal/DELETE_UPLOAD_FILE_PREVIEW_ITEM', itemId);
    },

    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    },

    endDraggable() {
      const that = this;
      that.dragging = false;
      this.$emit('flipEvent');
    },

    checkMove(e) {
      if (e.draggedContext.element) {
        return true;
      }
      return false;
    },

    handleTitle(value = '') {
      if (typeof value === 'object') {
        this.$emit('setTitle', this.title);
      } else {
        this.$emit('setTitle', value || this.title);
      }
    },

    removePreviewItem(index) { // eslint-disable-next-line
      this.$emit('removePreview', index);
    },

    async checkValidation() {
      let allIsValid = false;
      let flipbookIsValid = false;
      let quizIsValid = false;

      if (this.isAddingQuestion) {
        quizIsValid = await this.checkQuizValidation('flipbook');
      } else {
        quizIsValid = true;
      }

      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });
      await this.$validator.validateAll('flipbook').then((res) => {
        flipbookIsValid = res;
      });
      if (allIsValid && flipbookIsValid && quizIsValid) return true;
      return false;
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    async updateContent() {
      if (this.isAddingQuestion === 0 && this.modalData?.quiz && this.modalData?.quiz?.questions) {
        // eslint-disable-next-line array-callback-return
        await Promise.all(this.modalData?.quiz?.questions.map((question) => {
          this.$store.dispatch('modal/DELETE_QUIZ_QUESTION', question.id);
        }));
        await this.$store.dispatch('modal/DELETE_CONTENT_SUB_QUIZ', this.modalData.quiz.id);
      }
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5 !important;
  background: #fff !important;
  border: 1px dashed rgba(114, 125, 146, 1) !important;
  box-shadow: none !important;
  cursor: move;
}

.previews {
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 220px;

  .flip_card {
    width: 115px;
    height: 200px;
    display: inline-flex;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .preview {
    width: 115px;
    height: 200px;
    display: flex;
    margin-right: 20px;
    margin-top: 20px;
    position: relative;
    border-radius: 4px;
    animation-name: fadepreview;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: .2s;

    &__count {
      position: absolute;
      right: 12px;
      top: 8px;
      border-radius: 50%;
      background: #FFFFFF;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      @include primary-bold;
      color: #142B58;
    }

    img {
      width: 115px;
      height: 200px;
      border-radius: 4px;
      cursor: grab;
    }

    .remove {
      opacity: 0;
      width: 24px;
      height: 24px;
      background: rgba(255, 255, 255, 0.3);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: absolute;
      right: 12px;
      bottom: 8px;
      text-indent: -9999px;
      transition: opacity 0.3s;
    }

    &:hover .remove {
      opacity: 1;
    }
    .preview-remove {
      opacity: 0;
      width: 24px;
      height: 24px;
      background: rgba(197, 10, 10, 0.92);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: absolute;
      right: 12px;
      bottom: 8px;
      text-indent: -9999px;
      transition: opacity 0.3s;
    }
    &:hover .preview-remove {
      opacity: 1;
    }
  }
}
.card.card--upload.is-danger {
  border-color: #f27299;
}
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}
.progressbarline {
    align-self: center;
    display: block;
    position: absolute;
    z-index: 100;
    width: inherit;
    padding: 10px;
    text-align: center;
}
.fliplist {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
}

.preview-image{
  box-shadow: none;
  border: 2px dashed rgba(114, 125, 146, 0.2);
  animation-name: fadepreview;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: .4s;
}
@keyframes fadepreview {
  0% {
    opacity: 1
  }
  1% {
    opacity: 0
  }
  99% {
    opacity: 0
  }
  100% {
    opacity: 1;
  }
}

.vue-simple-progress-bar {
  height: 14px!important;
}
::v-deep div.vue-simple-progress{
  height: 14px!important;
}
::v-deep div.vue-simple-progress-text{
  padding: 0 !important;
}
.preview-error {
  z-index: 100;
  font-size: 14px;
  font-weight: bolder;
  color: #f02965;
  align-self: center;
  display: block;
  position: absolute;
  z-index: 100;
  width: inherit;
  padding: 10px;
  text-align: center;
}
.preview-error:before {
  content: "\26A0";
}
</style>
