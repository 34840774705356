var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("line", {
      attrs: {
        x1: "0.75",
        y1: "1.25",
        x2: "17.25",
        y2: "1.25",
        stroke: "currentColor",
        "stroke-width": "1.5",
        "stroke-linecap": "round",
      },
    }),
    _c("line", {
      attrs: {
        x1: "0.75",
        y1: "6.25",
        x2: "17.25",
        y2: "6.25",
        stroke: "currentColor",
        "stroke-width": "1.5",
        "stroke-linecap": "round",
      },
    }),
    _c("line", {
      attrs: {
        x1: "0.75",
        y1: "11.25",
        x2: "17.25",
        y2: "11.25",
        stroke: "currentColor",
        "stroke-width": "1.5",
        "stroke-linecap": "round",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }