<template>
  <div>
    <modal-contentpreview
      v-if="showPreview"
    />
    <FullCalendar
      ref="fullCalendar"
      default-view="dayGridMonth"
      :locale="userLang"
      :first-day="userLang === 'en' ? 0:1"
      :time-zone="'local'"
      :button-text="buttonTextLocalized"
      :events="calenderEvents"
      :event-time-format="{
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
      }"
      :selectable="true"
      :editable="true"
      :views="customViews"
      :list-day-format="listDayFormat"
      :valid-range="validRange"
      :header="{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listAllEvents',
      }"
      :plugins="calendarPlugins"
      event-order="start,-duration,allDay,order"
      @eventDrop="handleEvents"
      @eventClick="eventClick"
      @eventDragStart="eventDragStart"
      @eventDragStop="eventDragStop"
      :event-render="eventRender"
    >
      <template v-slot:eventContent="" />
    </FullCalendar>
    <div
      class="dropdown"
      id="itemModal"
    >
      <div
        class="dropdown__menu"
      >
        <header class="modal-header">
          <h5
            @click="previewContentItem"
            class="modal-title preview"
          >
            {{ eventItemTitle }}
          </h5>
          <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
          <button
            type="button"
            class="close"
            @click="closeEventModal"
            v-html="'&times;'"
          />
        </header>
        <div class="row mx-4">
          <div class="col-6">
            <div class="form-group form-group--white u-mB-0 u-zIndex-3">
              <label
                for="journeyLanguage"
                class="form-label"
              >{{ $t('begin_date') }}</label>
              <date-picker
                :class="{ 'is-danger': errors.has('assign.beginDate') }"
                v-model="eventItemDateTime"
                :disabled-date.sync="notBeforeToday"
                type="date"
                value-type="date"
                :first-day-of-week="userLang === 'en' ? 7 : 1"
                :lang="userLang"
                :format="userLang === 'en' ? 'MM.DD.YYYY' : 'DD.MM.YYYY'"
                @change="adjustTime('date')"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group form-group--white u-mB-0 u-zIndex-3">
              <label
                for="journeyLanguage"
                class="form-label"
              >{{ $t('begin_time') }}</label>
              <date-picker
                :class="{ 'is-danger': errors.has('assign.beginTime') }"
                v-model="eventItemDateTime"
                :disabled-time.sync="notBeforeTime"
                type="time"
                value-type="date"
                :lang="userLang"
                :format="userLang === 'en' ? 'hh:mm A' : 'HH:mm'"
                :time-picker-options="{ start: '08:00', step: '00:15', end: '23:45', format: userLang === 'en' ? 'hh:mm A' : 'HH:mm' }"
                @change="adjustTime('time')"
              >
                <template slot="icon-calendar">
                  <icon-container
                    name="time"
                    view-box="0 0 512 512"
                  >
                    <icon-time />
                  </icon-container>
                </template>
              </date-picker>
            </div>
          </div>
        </div>
        <div class="modal-footer mb-4">
          <button
            type="button"
            class="button button--primary"
            :disabled="isPending || !eventItemDateTime"
            @click="setNewDate"
          >
            <span
              v-if="!isPending"
            >
              {{ $t('change') }}
            </span>
            <div
              class="lds-ellipsis"
              v-if="isPending"
            >
              <div />
              <div />
              <div />
              <div />
            </div>
          </button>
        </div>
      </div>
    </div>
    <div id="borderActivated" />
  </div>
</template>

<script>
import moment from 'moment';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/tr';
import 'vue2-datepicker/index.css';
import ModalContentPreview from '@/container/modal/previewContent/Index.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconTime from '@/components/icons/Time.vue';
import {
  API_SERVER_TIME_FORMAT,
} from '@/helpers/config';

export default {
  name: 'FullCalendarIO',
  inject: ['$validator'],
  data() {
    return {
      calendarPlugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        listPlugin,
      ],
      listDayFormat: { // will produce something like "Tuesday, July 24, 2020"
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long',
        omitCommas: false,
      },
      openEventModal: false,
      eventItemTitle: '',
      eventItemId: null,
      eventItemDateTime: null,
      eventItemDateTimeOldValue: null,
      isPending: false,
      info: null,
      calendarPosition: null,
      debounce: null,
    };
  },

  props: {
    calenderEvents: {
      type: [Array, Object],
      default: () => [{ title: '', date: '', order: 0 }],
    },
    userLang: {
      type: String,
      default: 'en',
    },
    totalEventDays: {
      type: Number,
      default: 0,
    },
    previewFirstDate: {
      type: String,
      default: '',
    },
    limitDate: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    customViews() {
      return {
        listAllEvents: {
          type: 'list',
          duration: { days: this.totalEventDays },
          buttonText: this.userLang === 'tr' ? 'liste' : 'list',
          listDayFormat: date => moment(date.date).locale(this.userLang === 'en' ? 'en' : 'tr').format(this.userLang === 'en' ? 'dddd, MMMM DD, YYYY' : ' DD MMMM YYYY, dddd'),
        },
      };
    },

    showPreview: {
      get() {
        return this.$store.state.modal.showPreview;
      },
      set(val) {
        this.$store.state.modal.showPreview = val;
      },
    },

    buttonTextLocalized() {
      return {
        today: this.$t('fullcalendar_today'),
        month: this.$t('fullcalendar_month'),
        week: this.$t('fullcalendar_week'),
        day: this.$t('fullcalendar_day'),
        list: this.$t('fullcalendar_list'),
      };
    },
  },

  watch: {
    calenderEvents: {
      handler() {
        console.log('calendar updated');
        if (this.$refs.fullCalendar) {
          this.$refs.fullCalendar.getApi().render();
          setTimeout(() => {
            this.$refs.fullCalendar.getApi().render();
          }, 1000);
        }
      },
      immediate: true,
      deep: true,
    },

    previewFirstDate: {
      handler(val) {
        if (val) {
          this.gotoDate(val);
        }
      },
      immediate: true,
      deep: true,
    },

    totalEventDays: {
      handler(val) {
        if (val) {
          this.gotoDate(this.previewFirstDate);
        }
      },
      immediate: true,
    },
    eventItemDateTime: {
      handler(newValue) {
        this.eventItemDateTimeOldValue = newValue;
      },
      immediate: true,
      deep: true,
    },
  },

  components: {
    FullCalendar,
    'date-picker': DatePicker,
    'modal-contentpreview': ModalContentPreview,
    'icon-container': IconContainer,
    'icon-time': IconTime,
  },

  mounted() {
    this.closeEventModal();
    this.getPoisitions();
    window.addEventListener('resize', this.getPoisitions);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getPoisitions);
    document.removeEventListener('keydown', this.keyEventHandler, false);
    document.removeEventListener('mousemove', this.handleDragging, true);
  },

  methods: {
    gotoDate(myDate) {
      if (this.$refs.fullCalendar) {
        const date = moment(myDate, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT);
        this.$refs.fullCalendar.getApi().gotoDate(date);
        this.$refs.fullCalendar.getApi().render();
      }
    },
    handleEvents(info) {
      if (info) {
        let time = info.event.start;
        if (info.view.type === 'timeGridWeek') {
          if (moment(time).minute() > 30) {
            time = moment(time).minute(30).second(0);
          } else {
            time = moment(time).minute(0).second(0);
          }
        }
        this.$emit('setDateFromDrop', {
          id: info.event.id,
          isnotif: info.event.extendedProps.isnotif,
          newdatetime: moment(time, 'YYYY-MM-DDTHH:mm:ss.000Z').format(API_SERVER_TIME_FORMAT),
        });
      }
    },

    getPoisitions() {
      this.calendarPosition = this.$refs.fullCalendar.$el.getBoundingClientRect();
    },

    handleDragging(info) {
      if (info) {
        if (this.calendarPosition) {
          const waitTimeInMs = 1000;
          const activeArea = 10;
          const header = 88;
          const position = this.calendarPosition;
          const leftPlus = position.left + activeArea;
          const leftMinus = position.left - activeArea;
          const rightMinus = position.right - activeArea;
          const rightPlus = position.right + activeArea;
          const bottomPlus = position.bottom + activeArea;
          const bottomMinus = position.bottom - activeArea;
          const topPlus = position.top + header + activeArea;
          const topMinus = position.top + header - activeArea;
          if (info.clientX > leftMinus
              && info.clientX < leftPlus
              && info.clientY > topMinus
              && info.clientY < bottomPlus
          ) {
            if (this.debounce === null) {
              this.highlightArea(true, 'left');
              this.debounce = setTimeout(() => {
                if (this.$refs.fullCalendar) {
                  this.$refs.fullCalendar.getApi().prev();
                  this.debounce = null;
                }
              }, waitTimeInMs);
            }
          } else if (info.clientX > leftMinus
              && info.clientX < rightPlus
              && info.clientY > topMinus
              && info.clientY < topPlus
          ) {
            if (this.debounce === null) {
              this.highlightArea(true, 'top');
              this.debounce = setTimeout(() => {
                if (this.$refs.fullCalendar) {
                  this.$refs.fullCalendar.getApi().prev();
                  this.debounce = null;
                }
              }, waitTimeInMs);
            }
          } else if (info.clientX < rightPlus
              && info.clientX > rightMinus
              && info.clientY > topMinus
              && info.clientY < bottomPlus
          ) {
            if (this.debounce === null) {
              this.highlightArea(true, 'right');
              this.debounce = setTimeout(() => {
                if (this.$refs.fullCalendar) {
                  this.$refs.fullCalendar.getApi().next();
                  this.debounce = null;
                }
              }, waitTimeInMs);
            }
          } else if (info.clientX < rightPlus
              && info.clientX > leftMinus
              && info.clientY > bottomMinus
              && info.clientY < bottomPlus
          ) {
            if (this.debounce === null) {
              this.highlightArea(true, 'bottom');
              this.debounce = setTimeout(() => {
                if (this.$refs.fullCalendar) {
                  this.$refs.fullCalendar.getApi().next();
                  this.debounce = null;
                }
              }, waitTimeInMs);
            }
          } else {
            clearTimeout(this.debounce);
            this.debounce = null;
            this.highlightArea();
          }
        }
      }
    },

    highlightArea(enable = false, direction = null) {
      const elem = document.getElementById('borderActivated');
      if (elem) {
        if (!enable) {
          elem.setAttribute('style', 'display: none');
        } else if (direction === 'left') {
          elem.setAttribute('style', `
          z-index:100;
          display:flex;
          background:rgba(0, 230, 64, 0.2);
          border:none;
          position:absolute;
          top:88px;
          left:5px;
          height:${this.calendarPosition.bottom - this.calendarPosition.top - 88}px;
          width:20px`);
        } else if (direction === 'right') {
          elem.setAttribute('style', `
          z-index:100;
          display:flex;
          background:rgba(0, 230, 64, 0.2);
          border:none;
          position:absolute;
          top:88px;
          right:5px;
          height:${this.calendarPosition.bottom - this.calendarPosition.top - 88}px;
          width:20px`);
        } else if (direction === 'top') {
          elem.setAttribute('style', `
          z-index:100;
          display:flex;
          background:rgba(0, 230, 64, 0.2);
          border:none;
          position:absolute;
          top:78px;
          left:15px;
          width:${this.calendarPosition.right - this.calendarPosition.left}px;
          height:20px`);
        } else if (direction === 'bottom') {
          elem.setAttribute('style', `
          z-index:100;
          display:flex;
          background:rgba(0, 230, 64, 0.2);
          border:none;
          position:absolute;
          bottom:-10px;
          left:15px;
          width:${this.calendarPosition.right - this.calendarPosition.left}px;
          height:20px`);
        }
      }
    },

    validRange() {
      if (this.limitDate) {
        const now = new Date();
        now.setMonth(now.getMonth() - 3);
        return {
          start: now,
        };
      }
      return { start: '2018-01-01' };
    },

    notBeforeToday(date) {
      if (this.limitDate) {
        const now = new Date();
        now.setMonth(now.getMonth() - 3);
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate());
      }
      return false;
    },

    notBeforeTime(date) {
      const now = new Date();
      now.setMonth(now.getMonth() - 3);
      if (this.limitDate && moment(new Date(), API_SERVER_TIME_FORMAT).isSame(moment(this.eventItemDateTime, API_SERVER_TIME_FORMAT).format(API_SERVER_TIME_FORMAT), 'day')) {
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
      }
      return false;
    },

    eventClick(info) {
      if (info && info.event) {
        this.eventItemTitle = info.event.title;
        this.eventItemId = info.event.id;
        this.eventItemDateTime = info.event.start;
        this.eventItemDateTimeOldValue = this.eventItemDateTime;
      }
      this.info = info;
      const elem = document.getElementById('itemModal');
      if (elem && this.eventItemTitle) {
        elem.setAttribute('style', `left:${(info.jsEvent.x - 400)}px;position:fixed!important;z-index:20;top:${(info.jsEvent.y - 250)}px;`);
      }
    },

    eventDragStart(info) {
      document.addEventListener('keydown', this.keyEventHandler, false);
      document.addEventListener('mousemove', this.handleDragging, true);
      if (!info.isMirror) {
        this.$emit('isDragging', true);
        this.closeEventModal();
      }
    },

    eventDragStop() {
      document.removeEventListener('keydown', this.keyEventHandler, false);
      document.removeEventListener('mousemove', this.handleDragging, true);
      this.highlightArea();
      this.$emit('isDragging', false);
    },

    closeEventModal() {
      const elem = document.getElementById('itemModal');
      if (elem) {
        elem.setAttribute('style', 'display:none;');
      }
    },

    eventRender(info) {
      if (info.view.type === 'listAllEvents') {
        info.el.lastChild.insertAdjacentHTML('beforeend', `<span style="float:right">${info.event.extendedProps.subtype}</span>`);
      }
    },

    setNewDate() {
      this.isPending = true;
      setTimeout(() => {
        this.$emit('setDateFromDrop', {
          id: this.info.event.id,
          isnotif: this.info.event.extendedProps.isnotif,
          newdatetime: moment(this.eventItemDateTime).format(API_SERVER_TIME_FORMAT),
        });
        this.isPending = false;
        this.closeEventModal();
      }, 500);
    },

    previewContentItem() {
      let obj = {};
      if (this.info.event.extendedProps.notif_preview) {
        obj = {
          preview_content: this.info.event.extendedProps.all_content,
        };
      } else {
        obj = {
          id: this.info.event.extendedProps.content_id,
        };
      }
      this.showPreview = true;
      this.$store.commit('modal/toggleCalendarPreviewModal', obj);
    },

    keyEventHandler(event) {
      event.preventDefault();
      const key = event.key || event.keyCode;
      if (key === 'ArrowLeft' || key === 'Left' || key === 37) {
        this.$refs.fullCalendar.getApi().prev();
      } else if (key === 'ArrowRight' || key === 'Right' || key === 39) {
        this.$refs.fullCalendar.getApi().next();
      }
    },

    adjustTime(type) {
      if (this.eventItemDateTime !== null) {
        if (this.eventItemDateTimeOldValue && type === 'date') {
          const date = moment(this.eventItemDateTime, API_SERVER_TIME_FORMAT).format('YYYY-MM-DD');
          const time = moment(this.eventItemDateTimeOldValue, API_SERVER_TIME_FORMAT).format('HH:mm:ss');
          this.eventItemDateTime = moment(`${date} ${time}`, API_SERVER_TIME_FORMAT).toDate();
        } else if (this.eventItemDateTimeOldValue && type === 'time') {
          const date = moment(this.eventItemDateTimeOldValue, API_SERVER_TIME_FORMAT).format('YYYY-MM-DD');
          const time = moment(this.eventItemDateTime, API_SERVER_TIME_FORMAT).format('HH:mm:ss');
          this.eventItemDateTime = moment(`${date} ${time}`, API_SERVER_TIME_FORMAT).toDate();
        } else {
          this.eventItemDateTime.setHours(8, 0, 0);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../../node_modules/@fullcalendar/core/main.css";
@import "./../../node_modules/@fullcalendar/daygrid/main.css";
@import "./../../node_modules/@fullcalendar/list/main.css";
@import "./../../node_modules/@fullcalendar/timegrid/main.css";

::v-deep .fc-toolbar h2 {
    font-size: 1.50em;
    margin: 0;
}

#itemModal {
  min-width: 400px!important;
  .modal-title {
    white-space: pre-line;
    line-height: 25px !important;
    margin-right: 20px;
    max-width: 400px;
  }
}
.bgwhite:before {
    content: "";
    position: absolute;
    left: -30px;
    top: 0;
    bottom: 0;
    right: -30px;
    z-index: -1;
  background: #FFF;
}
.modal-title.preview:hover {
  cursor: pointer;
}
::v-deep tr.fc-list-item:hover {
  cursor: pointer;
}
::v-deep .fc-header-toolbar .fc-left {
  display: flex;
}
</style>
