<template>
  <div
    class="tab-pane show active"
  >
    <div
      class="form-group"
      :class="{ 'is-danger': errors.has('chapter.title') }"
    >
      <label
        for="title"
        class="form-label"
      >{{ $t('title').toUpperCase() }}</label>
      <input
        type="text"
        class="form-control"
        v-model="title"
        :disabled="disabled"
        data-vv-scope="chapter"
        data-vv-name="title"
        :data-vv-as="$t('title')"
        v-validate.persist="'required|max:200'"
        @input="handleTitle"
      >
      <span
        v-show="errors.has('chapter.title')"
        class="help is-danger"
      >{{ errors.first('chapter.title') }}</span>
    </div>
    <div
      class="form-group"
      :class="{ 'is-danger': errors.has('chapter.description') }"
    >
      <label
        for="description"
        class="form-label"
      >{{ $t('description') }}</label>
      <textarea
        :class="{ 'is-danger': errors.has('chapter.description') }"
        class="form-control"
        v-model="description"
        :disabled="disabled"
        data-vv-scope="chapter"
        data-vv-name="description"
        :data-vv-as="$t('description')"
        @blur="handleDesc"
      />
      <span
        v-show="errors.has('chapter.description')"
        class="help is-danger"
      >{{ errors.first('chapter.description') }}</span>
    </div>
    <div
      v-show="errors.items.length > 0 && (errors.has('chapter.title') || errors.has('chapter.description'))"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="button button--primary"
        @click="editMode ? updateContent() : createContent()"
        :disabled="isPending || disabled"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';

export default {
  name: 'ChapterContent',

  inject: ['$validator'],
  components: {
  },
  directives: {
    'on-clickaway': onClickaway,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      title: '',
      description: '',
      isPending: false,
    };
  },

  computed: {
    modalData() {
      return this.$store.state.modal.modalData;
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },

  watch: {

    formError(newValue) {
      if (newValue) {
        this.isPending = false;
      }
    },

    desc(newValue) {
      this.description = newValue;
    },

    name(newValue) {
      this.title = newValue;
    },
  },

  mounted() {
    this.clearErrorMsg();
    if (this.editMode) {
      this.title = this.name;
      this.description = this.desc;
    }
  },


  methods: {

    handleTitle() {
      this.$emit('setTitle', this.title);
    },

    handleDesc() {
      this.$emit('setDesc', this.description);
    },

    clearErrorMsg() {
      this.$validator.errors.clear();
    },

    async checkValidation() {
      let chapterIsValid = false;
      this.clearErrorMsg();
      await this.$validator.validateAll('chapter').then((res) => {
        chapterIsValid = res;
      });
      if (chapterIsValid) {
        return true;
      }
      return false;
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    updateContent() {
      this.checkValidation().then((res) => {
        if (res) {
          const payload = {
            id: this.$parent.modalData.id,
            dataObject: {
              name: this.title,
              desc: this.desc,
              description: this.desc,
            },
          };
          this.$root.$emit('refreshJourneyitemsPage', payload);
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.is-danger {
  border-color: #f27299;
}

.form-group {
  position: relative;
}
</style>
