var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
      _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("header", { staticClass: "modal-header" }, [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(
                " " + _vm._s(_vm.journeyName + "-" + _vm.$t("user_list")) + " "
              ),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: { type: "button" },
              domProps: { innerHTML: _vm._s("&times;") },
              on: { click: _vm.closeModal },
            }),
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("journey-users", {
                  attrs: {
                    "is-sub-component-mode": "",
                    "journey-customer-id": _vm.journeyCustomerId,
                  },
                  on: { closeModal: _vm.closeModal },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "button button--primary",
                  attrs: { type: "button" },
                  on: { click: _vm.closeModal },
                },
                [_c("span", [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")])]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }