var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      fill: "currentColor",
      d: "M52.671 29.835a3.714 3.714 0 0 0-.32-.8 3.179 3.179 0 0 0-4.38-1.18l-4.17 2.4a14.785 14.785 0 0 1-7.4 2c-.378 0-.756-.03-1.13-.09a4.545 4.545 0 0 1-.36-.4l-.32-.38h4.41c2.664 0 4.328-2.884 2.996-5.19a3.458 3.458 0 0 0-2.996-1.73h-10.79a13.337 13.337 0 0 0-7.35-2.13h-3.89a3.28 3.28 0 0 0-3.1-2.27h-3.63a3.28 3.28 0 0 0-3.27 3.25v15.13a3.28 3.28 0 0 0 3.27 3.27h3.58a3.28 3.28 0 0 0 3.15-2.27h3c2.235.133 4.457.427 6.65.88a18.717 18.717 0 0 1 4.23 1.85 13.272 13.272 0 0 0 13.21 0l11.38-6.57a3.125 3.125 0 0 0 1.48-1.94c.635-2.381-1.546-4.555-3.924-3.914-.09.024-.179.052-.266.084h-.06Zm-7.85 2.11 4.15-2.36a1.18 1.18 0 0 1 1.62.45c.152.252.206.551.15.84l-2.77 1.59a14.785 14.785 0 0 1-7.4 2 7.56 7.56 0 0 1-2.17-.33 16.913 16.913 0 0 0 6.42-2.19Zm-31 7.73h-3.58a1.271 1.271 0 0 1-1.27-1.23v-15.13c0-.702.569-1.27 1.27-1.27h3.58c.701 0 1.27.568 1.27 1.27v15.13a1.27 1.27 0 0 1-1.27 1.27v-.04Zm41.15-6.52c-.08.303-.278.562-.55.72l-11.45 6.57a11.27 11.27 0 0 1-11.21 0 20.298 20.298 0 0 0-4.7-2 46.434 46.434 0 0 0-7.12-1h-2.85v-13.13h3.77a11.204 11.204 0 0 1 6.47 2c.17.11.368.169.57.17h11.12a1.46 1.46 0 1 1 0 2.92h-10.95a1 1 0 1 0 0 2h4l1.35 1.65a9.313 9.313 0 0 0 7.23 3.43 16.842 16.842 0 0 0 8.32-2.29l4.17-2.41a1.21 1.21 0 0 1 1.83 1.37Z",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }