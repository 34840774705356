<template>
  <div
    class="d-flex align-center justify-center"
    style="width: 100%"
  >
    <div
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      :aria-valuenow="progress"
      :style="`height: 18px;border-radius:10px; border:1px solid #D5D5D5;color: rgba(0, 0, 0, 0.87);background: transparent;overflow: hidden;position:relative;transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);width: 100%;`"
    >
      <div
        style="opacity: 1; left: 100%; width: 0%;    bottom: 0;left: 0;position: absolute;top: 0;-webkit-transition: inherit;transition: inherit;"
      />
      <div
        style="height: inherit;left: 0;position: absolute;top: 0; -webkit-transition: inherit;transition: inherit;width: 100%;"
      />
      <div
        :style="`width: ${progress}%; background-color: rgb(196, 196, 196); border-color: rgb(196, 196, 196);height: inherit;left: 0;position: absolute;-webkit-transition: inherit;transition: inherit;`"
      />
      <div style="-webkit-box-align: center;-ms-flex-align: center;align-items: center;display: -webkit-box;display: -ms-flexbox;display: flex;height: 100%;left: 0;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;position: absolute;top: 0;width: 100%;">
        <div style="color: #333; font-size:11px;">
          {{ timeFrame }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'TimelineBadge',
  props: {
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },

    isValid() {
      if (this.startDate && this.endDate && moment(this.startDate, 'YYYY-MM-DD HH:mm:ss').isValid() && moment(this.endDate, 'YYYY-MM-DD HH:mm:ss').isValid()) {
        return true;
      }
      return false;
    },

    timeFrame() {
      let response = '-';
      if (this.isValid) {
        response = `${this.listDayFormat(this.startDate)} - ${this.listDayFormat(this.endDate)}`;
      }
      return response;
    },

    progress() {
      if (this.isValid) {
        const now = moment();
        const start = moment(this.startDate, 'YYYY-MM-DD HH:mm:ss');
        const end = moment(this.endDate, 'YYYY-MM-DD HH:mm:ss');
        const frame = end.diff(start, 'days');
        const tillnow = now.diff(start, 'days');
        return Math.ceil((100 * tillnow) / frame);
      }
      return 0;
    },
  },

  methods: {
    listDayFormat(date) {
      if (date) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').locale(this.userLang === 'en' ? 'en' : 'tr').format(this.userLang === 'en' ? 'MMM. DD' : 'DD MMM.');
      }
      return '-';
    },
  },
};
</script>
<style lang='scss' scoped>
.v-progress-linear--rounded {
    border-radius: 10px;
    border: 1px solid #D5D5D5;
}
</style>
