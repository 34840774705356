var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page mb-4 pb-4" },
    [
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "child-header-navigation": _vm.userSubNavigation,
            "is-child-header-left-actions": "",
          },
        },
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
          _c(
            "dropdown",
            {
              attrs: {
                "class-name": "filter",
                "filter-on":
                  _vm.the_filter.journey_type ||
                  _vm.the_filter.only_active_users,
              },
            },
            [
              _c("template", { slot: "icon" }, [
                _c("i", {
                  staticClass:
                    "fa fa-filter button button--shadow button--type-icon size-sm d-flex align-items-center",
                  staticStyle: {
                    cursor: "pointer",
                    color: "rgb(114, 125, 146)",
                  },
                }),
              ]),
              _c("template", { slot: "body" }, [
                _c("div", { staticClass: "d-block p-2" }, [
                  _c("div", { staticClass: "d-block" }, [
                    _c(
                      "label",
                      {
                        staticClass: "checkbox",
                        attrs: { for: "filter-only_active_users" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.the_filter.only_active_users,
                              expression: "the_filter.only_active_users",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "filter-only_active_users",
                            "true-value": true,
                            "false-value": false,
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.the_filter.only_active_users
                            )
                              ? _vm._i(_vm.the_filter.only_active_users, null) >
                                -1
                              : _vm.the_filter.only_active_users,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.the_filter.only_active_users,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.the_filter,
                                      "only_active_users",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.the_filter,
                                      "only_active_users",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.the_filter,
                                  "only_active_users",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("only_active_users")) + " "),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "d-block p-2" }, [
                  _c("div", { staticClass: "d-block" }, [
                    _c(
                      "label",
                      {
                        staticClass: "checkbox",
                        attrs: { for: "filter-journey_type" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.the_filter.journey_type,
                              expression: "the_filter.journey_type",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "filter-journey_type",
                            "true-value": true,
                            "false-value": false,
                          },
                          domProps: {
                            checked: Array.isArray(_vm.the_filter.journey_type)
                              ? _vm._i(_vm.the_filter.journey_type, null) > -1
                              : _vm.the_filter.journey_type,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.the_filter.journey_type,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.the_filter,
                                      "journey_type",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.the_filter,
                                      "journey_type",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.the_filter, "journey_type", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("journey_type")) + " "),
                      ]
                    ),
                  ]),
                  _vm.the_filter.journey_type
                    ? _c("div", { staticClass: "d-block" }, [
                        _c("div", { staticClass: "swicth-checkbox" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.journey_type,
                                expression: "journey_type",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "get_journey_type",
                              "true-value": "ON_BOARDING",
                              "false-value": "STANDARD",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.journey_type)
                                ? _vm._i(_vm.journey_type, null) > -1
                                : _vm._q(_vm.journey_type, "ON_BOARDING"),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.journey_type,
                                  $$el = $event.target,
                                  $$c = $$el.checked
                                    ? "ON_BOARDING"
                                    : "STANDARD"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.journey_type = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.journey_type = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.journey_type = $$c
                                }
                              },
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "swicth-label",
                              attrs: { for: "get_journey_type" },
                            },
                            [_vm._v(_vm._s(_vm.journey_type))]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.tableIsLoading
          ? _c("div", { staticClass: "lds-dual-ring" })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c("vue-table", {
              ref: "myvuetable",
              class: { "table-fade": _vm.tableIsLoading },
              attrs: {
                "api-url": _vm.apiUrl,
                "http-fetch": _vm.myFetch,
                "api-mode": true,
                "pagination-path": "meta",
                "http-method": "post",
                "append-params": _vm.moreParams,
                "http-options": {
                  headers: {
                    Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                  },
                },
                fields: _vm.fields,
                "no-data-template": _vm.$t("no_data_info"),
                css: _vm.css.table,
                "per-page": _vm.perPage,
                "query-params": _vm.makeQueryParams,
              },
              on: {
                "vuetable:loading": function ($event) {
                  _vm.tableIsLoading = true
                },
                "vuetable:loaded": function ($event) {
                  _vm.tableIsLoading = false
                },
                "vuetable:load-error": _vm.vuetableLoadError,
                "vuetable:pagination-data": _vm.onPaginationData,
              },
              scopedSlots: _vm._u([
                {
                  key: "name",
                  fn: function (props) {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              path: `/dashboard/journeys/users/${props.rowData.id}`,
                              query: { journey: props.rowData.name },
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(props.rowData.name) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "completion_rate",
                  fn: function (props) {
                    return [
                      _c("div", { staticClass: "progress" }, [
                        _c(
                          "div",
                          {
                            staticClass: "progress-bar",
                            style:
                              _vm.calcRate(props.rowData) < 15
                                ? "width:" +
                                  _vm.calcRate(props.rowData) +
                                  "%;color:#000"
                                : "width:" +
                                  _vm.calcRate(props.rowData) +
                                  "%;color:#fff",
                            attrs: {
                              role: "progressbar",
                              "aria-valuenow": _vm.calcRate(props.rowData),
                              "aria-valuemin": "0",
                              "aria-valuemax": "100",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  "‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎" +
                                    _vm.calcRate(props.rowData) +
                                    "%"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "actions",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "dropdown",
                          on: {
                            mouseover: function ($event) {
                              return _vm.dropdownToggle(
                                `three${props.rowIndex}`
                              )
                            },
                            mouseleave: _vm.hideDropdown,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "card--actions dropdown__toggle" },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    width: 20,
                                    height: 20,
                                    "view-box": "0 0 24 24",
                                    name: "threedots",
                                    color: "#727d92",
                                  },
                                },
                                [_c("icon-threedots")],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.dropdownIndex === "three" + props.rowIndex
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "on-clickaway",
                                      rawName: "v-on-clickaway",
                                      value: _vm.hideDropdown,
                                      expression: "hideDropdown",
                                    },
                                  ],
                                  ref: `three${props.rowIndex}`,
                                  staticClass: "dropdown__menu",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "progress-report",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            name: "content",
                                            "view-box": "0 0 13 14",
                                            color: "#727d92",
                                            "is-icon-class": false,
                                          },
                                        },
                                        [_c("icon-download")],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "download_journey_progress_report"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              { staticClass: "pagination__info" },
              [
                _c("vuetable-pagination-info", {
                  ref: "paginationInfo",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "info-template": _vm.$t("pagination_info"),
                    "no-data-template": _vm.$t("no_data_info"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "pagination__items" },
                  [
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }