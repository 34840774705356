<script>
import CssSemanticUI from './VuetableCssSemanticUI';

export default {
  props: {
    css: {
      type: Object,
      default() {
        return {};
      },
    },
    infoTemplate: {
      type: String,
      default() {
        return 'Displaying {from} to {to} of {total} items';
      },
    },
    noDataTemplate: {
      type: String,
      default() {
        return 'No relevant data';
      },
    },
  },
  data() {
    return {
      tablePagination: null,
      $_css: {},
    };
  },
  computed: {
    paginationInfo() {
      if (this.tablePagination === null || this.tablePagination.total === 0) {
        return this.noDataTemplate;
      }

      return this.infoTemplate
        .replace('{from}', this.tablePagination.from || 0)
        .replace('{to}', this.tablePagination.to || 0)
        .replace('{total}', this.tablePagination.total || 0);
    },
  },
  created() {
    this.mergeCss();
  },
  methods: {
    mergeCss() {
      this.$_css = { ...CssSemanticUI.paginationInfo, ...this.css };
    },
    setPaginationData(tablePagination) {
      this.tablePagination = tablePagination;
    },
    resetData() {
      this.tablePagination = null;
    },
  },
};
</script>
