<template>
  <div class="page mb-4 pb-4">
    <app-header
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>

    <div class="container">
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="get"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="date-slot"
            slot-scope="props"
          >
            {{ getCurrentLocaleDate(props.rowData.created_at) }}
          </template>
          <template
            slot="name-slot"
            slot-scope="props"
          >
            {{ (props.rowData.friendly_name && props.rowData.friendly_name !=='' && props.rowData.friendly_name != null) ? props.rowData.friendly_name : getTypeName(props.rowData.type) }}
          </template>
          <template
            slot="type-slot"
            slot-scope="props"
          >
            <span :title="getTypeName(props.rowData.type)">{{ props.rowData.type }}</span>
          </template>
          <template
            slot="status-slot"
            slot-scope="props"
          >
            <div>
              <span
                v-if="props && props.rowData && props.rowData.is_ready"
                class="status-generated"
              >{{ $t('generated') }}</span>
              <span
                v-else
                class="status-generating"
              >{{ $t('generating') }}</span>
            </div>
          </template>

          <template
            slot="action-slot"
            slot-scope="props"
          >
            <button
              class="ui button"
              :disabled="!props.rowData.is_ready"
              @click="onAction('download', props.rowData)"
            >
              <icon-container
                width="13"
                height="14"
                name="download"
                color="#727d92"
              >
                <icon-download />
              </icon-container>
            </button>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import { mapState } from 'vuex';
import { saveAs } from 'file-saver';
import IconContainer from '@/components/elements/Icon.vue';
import IconDownload from '@/components/icons/Download.vue';
import AppHeader from '@/components/AppHeader.vue';
import { API_REPORTS_DEMAND, API_PREFIX, API_REPORTS_DOWNLOAD } from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import API from '@/services/';
import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'DashboardReportDemands',
  mixins: [myTableMixin],
  data() {
    return {
      moreParams: {
        sort: 'created_at',
        order: 'desc',
        customer_id: this.$store.state.auth.customerId,
      },
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-download': IconDownload,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'DashboardReportDemands') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
    customerId: {
      handler() {
        if (this.$refs && this.$refs.myvuetable) {
          console.log('customer id refreshed');
          this.$refs.myvuetable.refresh();
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),

    apiUrl() {
      if (API_REPORTS_DEMAND) {
        return `${API_REPORTS_DEMAND}`;
      }
      return null;
    },

    customerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },

    userSubNavigation() {
      if (this.isVibonsAdmin) {
        return [
          {
            text: this.$t('overview'),
            url: '/dashboard/overview',
          },
          {
            text: this.$t('journeys'),
            url: '/dashboard/journeys',
          },
          {
            text: this.$t('journeys_v2'),
            url: '/dashboard/journeys_v2',
          },
          {
            text: this.$t('users'),
            url: '/dashboard/users',
          },
          {
            text: this.$t('quiz_survey'),
            url: '/dashboard/quiz-survey',
          },
          {
            text: this.$t('customers_sub'),
            url: '/dashboard/customers',
          },
          {
            text: this.$t('report_demands'),
            url: '/dashboard/reportdemands',
          },
        ];
      }
      return [
        {
          text: this.$t('overview'),
          url: '/dashboard/overview',
        },
        {
          text: this.$t('journeys'),
          url: '/dashboard/journeys',
        },
        {
          text: this.$t('users'),
          url: '/dashboard/users',
        },
        {
          text: this.$t('quiz_survey'),
          url: '/dashboard/quiz-survey',
        },
        {
          text: this.$t('report_demands'),
          url: '/dashboard/reportdemands',
        },
      ];
    },
    fields() {
      return [
        {
          name: 'date-slot',
          title: this.$t('request_date'),
          width: '20%',
        },
        {
          name: 'name-slot',
          title: this.$t('report_name'),
          width: '35%',
        }, {
          name: 'type-slot',
          title: this.$t('type'),
          width: '15%',
        },
        {
          name: 'status-slot',
          title: this.$t('status'),
          width: '15%',
        },
        {
          name: 'action-slot',
          title: this.$t('actions'),
          width: '15%',
          titleClass: 'center',
          dataClass: 'center',
        }];
    },
  },
  mounted() {
    if (this.$route && this.$route.query) {
      const reportId = this.$route.query.report_id;
      if (reportId) {
        this.multiProcessProgressBar = true;
        setTimeout(() => {
          this.multiProcessProgressBar = false;
          this.downloadExcel(reportId);
        }, 3000);
      }
    }
  },
  activated() {
    this.$refs.myvuetable.refresh();
  },
  methods: {
    onAction(action, data) {
      const { // eslint-disable-next-line camelcase
        path, is_encrypted, id, friendly_name, type,
      } = data;
      if (action === 'download') {
        // eslint-disable-next-line camelcase
        if (is_encrypted) {
          this.downloadExcel(id, friendly_name, type);
        } else if (path) {
          window.open(API_PREFIX + path);
        }
      }
    },

    downloadExcel(id, friendlyName = '', type = null) {
      API.get(`${API_REPORTS_DOWNLOAD}/${id}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          this.$store.commit('modal/setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
        },
      }).then((response) => {
        const excelFile = response.data;
        const date = new Date().toISOString().split('T')[0];
        let filename = `Report_${date}`;
        if (friendlyName) {
          filename = `${friendlyName}_${date}`;
        }
        if (type === 4) {
          saveAs(excelFile, `${filename}.csv`);
        } else {
          saveAs(excelFile, `${filename}.xlsx`);
        }
      })
        .catch((error) => {
          this.$helpers.displayError(error);
          this.$store.commit('modal/setProgressBarValue', -1);
        })
        .finally(() => this.$store.commit('modal/setProgressBarValue', -1));
    },

    getCurrentLocaleDate(dateVal) {
      if (this.$store.state.auth.userInfo && this.$store.state.auth.userInfo.lang) {
        return this.$helpers.getUserLangBasedDateTimeFormat(dateVal, this.$store.state.auth.userInfo.lang, 'datetime');
      }
      return dateVal;
    },

    getTypeName(type) {
      let name = '';
      switch (type) {
        case 0:
          name = this.$t('customer_user_journeys');
          break;
        case 1:
          name = this.$t('customer_survey_result');
          break;
        case 2:
          name = this.$t('customer_quiz_result');
          break;
        case 3:
          name = this.$t('customer_disengaged_result');
          break;
        case 4:
          name = this.$t('customer_user_activities');
          break;
        case 5:
          name = this.$t('group_user_activities');
          break;
        case 6:
          name = this.$t('group_survey_results');
          break;
        case 7:
          name = this.$t('group_quiz_results');
          break;
        case 8:
          name = this.$t('customer_quiz_results_detailed');
          break;
        case 9:
          name = this.$t('journey_user_activities');
          break;
        case 10:
          name = this.$t('weekly_customer_activity');
          break;
        case 11:
          name = this.$t('customer_checklist_result_excel');
          break;
        case 12:
          name = this.$t('group_checklist_result_excel');
          break;
        case 13:
          name = this.$t('user_list');
          break;
        case 14:
          name = this.$t('download_journey_progress_report');
          break;
        case 15:
          name = this.$t('communication_report');
          break;
        default:
          name = 'Error: Type name not defined!';
          break;
      }
      return name;
    },
  },
};
</script>
<style lang="scss" scoped>
.status-generated {
  color: rgb(8, 206, 25);
  font-weight: 600;
}
.status-generating {
  color:#f27299;
  font-weight: 600;
}
</style>
