<template>
  <div
    v-show="tablePagination && lastPage > firstPage"
    :class="$_css.wrapperClass"
  >
    <a
      @click="loadPage(firstPage)"
      :class="['btn-nav', $_css.linkClass, isOnFirstPage ? $_css.disabledClass : '']"
    >
      <icon-container
        v-if="$_css.icons.first != ''"
        :class="[$_css.icons.first]"
        name="angeldoubleleft"
        view-box="0 0 448 512"
        :is-icon-class="false"
      >
        <icon-angeldoubleleft />
      </icon-container>
      <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
      <span v-else>&laquo;</span>
    </a>
    <a
      @click="loadPage('prev')"
      :class="['btn-nav', $_css.linkClass, isOnFirstPage ? $_css.disabledClass : '']"
    >
      <icon-container
        v-if="$_css.icons.next != ''"
        :class="[$_css.icons.prev]"
        name="angelleft"
        view-box="0 0 256 512"
        :is-icon-class="false"
      >
        <icon-angelleft />
      </icon-container>
      <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
      <span v-else>&nbsp;&lsaquo;</span>
    </a>
    <template v-if="notEnoughPages">
      <template v-for="(n, i) in totalPage">
        <a
          @click="loadPage(i+firstPage)"
          :key="i"
          :class="[$_css.pageClass, isCurrentPage(i+firstPage) ? $_css.activeClass : '']"
          v-html="n"
        />
      </template>
    </template>
    <template v-else>
      <template v-for="(n, i) in windowSize">
        <a
          @click="loadPage(windowStart+i+firstPage-1)"
          :key="i"
          :class="[$_css.pageClass, isCurrentPage(windowStart+i+firstPage-1) ? $_css.activeClass : '']"
          v-html="windowStart+n-1"
        />
      </template>
    </template>
    <a
      @click="loadPage('next')"
      :class="['btn-nav', $_css.linkClass, isOnLastPage ? $_css.disabledClass : '']"
    >
      <icon-container
        v-if="$_css.icons.next != ''"
        :class="[$_css.icons.next]"
        name="angelright"
        view-box="0 0 256 512"
        :is-icon-class="false"
      >
        <icon-angelright />
      </icon-container>
      <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
      <span v-else>&rsaquo;&nbsp;</span>
    </a>
    <a
      @click="loadPage(lastPage)"
      :class="['btn-nav', $_css.linkClass, isOnLastPage ? $_css.disabledClass : '']"
    >
      <icon-container
        v-if="$_css.icons.last != ''"
        :class="[$_css.icons.last]"
        name="angeldoubleright"
        view-box="0 0 448 512"
        :is-icon-class="false"
      >
        <icon-angeldoubleright />
      </icon-container>
      <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
      <span v-else>&raquo;</span>
    </a>
  </div>
</template>

<script>
import IconContainer from '@/components/elements/Icon.vue';
import AngelDoubleRight from '../icons/AngleDoubleRight.vue';
import AngelRight from '../icons/AngleRight.vue';
import AngelLeft from '../icons/AngleLeft.vue';
import AngelDoubleLeft from '../icons/AngleDoubleLeft.vue';
import PaginationMixin from './VuetablePaginationMixin.vue';

export default {
  mixins: [PaginationMixin],
  components: {
    'icon-container': IconContainer,
    'icon-angeldoubleleft': AngelDoubleLeft,
    'icon-angeldoubleright': AngelDoubleRight,
    'icon-angelleft': AngelLeft,
    'icon-angelright': AngelRight,
  },
};
</script>
<style>
.vuetable-pagination {
  background: #f9fafb !important;
}
</style>
