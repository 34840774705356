var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.$_css.tableWrapper },
    [
      _vm.currentViewMode === "card"
        ? [
            _c(
              "div",
              { staticClass: "container-fluid" },
              [
                _c(
                  "transition-group",
                  {
                    class: "row",
                    attrs: {
                      appear: "",
                      type: "transition",
                      name: "slide-in",
                      tag: "ul",
                      id: "itemslist",
                    },
                  },
                  [
                    _vm._l(_vm.tableData, function (item, itemIndex) {
                      return [
                        _vm._t("custom-block", null, {
                          rowData: item,
                          rowIndex: itemIndex,
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.currentViewMode === "list"
        ? [
            _vm.isFixedHeader
              ? _c("div", { staticClass: "vuetable-head-wrapper" }, [
                  _c(
                    "table",
                    {
                      class: [
                        "vuetable",
                        _vm.$_css.tableClass,
                        _vm.$_css.tableHeaderClass,
                      ],
                      attrs: { "aria-describedby": "table" },
                    },
                    [
                      _c("vuetable-col-group", {
                        attrs: { "is-header": true },
                      }),
                      _c(
                        "thead",
                        [
                          _vm._t(
                            "tableHeader",
                            function () {
                              return [
                                _vm._l(
                                  _vm.headerRows,
                                  function (header, headerIndex) {
                                    return [
                                      _c(header, {
                                        key: headerIndex,
                                        tag: "component",
                                        on: {
                                          "vuetable:header-event":
                                            _vm.onHeaderEvent,
                                        },
                                      }),
                                    ]
                                  }
                                ),
                              ]
                            },
                            { fields: _vm.tableFields }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "vuetable-body-wrapper",
                class: { "fixed-header": _vm.isFixedHeader },
                style: { height: _vm.tableHeight },
              },
              [
                _c(
                  "table",
                  {
                    class: [
                      "vuetable",
                      _vm.isFixedHeader ? "fixed-header" : "",
                      _vm.$_css.tableClass,
                      _vm.$_css.tableBodyClass,
                    ],
                    attrs: { "aria-describedby": "table" },
                  },
                  [
                    _c("vuetable-col-group"),
                    !_vm.isFixedHeader
                      ? _c(
                          "thead",
                          [
                            _vm._t(
                              "tableHeader",
                              function () {
                                return [
                                  _vm._l(
                                    _vm.headerRows,
                                    function (header, headerIndex) {
                                      return [
                                        _c(header, {
                                          key: headerIndex,
                                          tag: "component",
                                          on: {
                                            "vuetable:header-event":
                                              _vm.onHeaderEvent,
                                          },
                                        }),
                                      ]
                                    }
                                  ),
                                ]
                              },
                              { fields: _vm.tableFields }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "tfoot",
                      [
                        _vm._t("tableFooter", null, {
                          fields: _vm.tableFields,
                        }),
                      ],
                      2
                    ),
                    _c(
                      "tbody",
                      { staticClass: "vuetable-body" },
                      [
                        _vm._l(_vm.tableData, function (item, itemIndex) {
                          return [
                            _c(
                              "tr",
                              {
                                key: itemIndex,
                                class: _vm.onRowClass(item, itemIndex),
                                style: { "--i": itemIndex },
                                attrs: { "item-index": itemIndex },
                                on: {
                                  click: function ($event) {
                                    return _vm.onRowClicked(
                                      item,
                                      itemIndex,
                                      $event
                                    )
                                  },
                                  dblclick: function ($event) {
                                    return _vm.onRowDoubleClicked(
                                      item,
                                      itemIndex,
                                      $event
                                    )
                                  },
                                  mouseover: function ($event) {
                                    return _vm.onMouseOver(
                                      item,
                                      itemIndex,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _vm._l(
                                  _vm.tableFields,
                                  function (field, fieldIndex) {
                                    return [
                                      field.visible
                                        ? [
                                            _vm.isFieldComponent(field.name)
                                              ? [
                                                  _c(field.name, {
                                                    key: fieldIndex,
                                                    tag: "component",
                                                    class: _vm.bodyClass(
                                                      "vuetable-component",
                                                      field
                                                    ),
                                                    style: {
                                                      width: field.width,
                                                    },
                                                    attrs: {
                                                      "row-data": item,
                                                      "row-index": itemIndex,
                                                      "row-field": field,
                                                      vuetable: _vm.vuetable,
                                                    },
                                                    on: {
                                                      "vuetable:field-event":
                                                        _vm.onFieldEvent,
                                                    },
                                                  }),
                                                ]
                                              : _vm.isFieldSlot(field.name)
                                              ? [
                                                  _c(
                                                    "td",
                                                    {
                                                      key: fieldIndex,
                                                      class: _vm.bodyClass(
                                                        "vuetable-slot",
                                                        field
                                                      ),
                                                      style: {
                                                        width: field.width,
                                                      },
                                                    },
                                                    [
                                                      _vm._t(field.name, null, {
                                                        rowData: item,
                                                        rowIndex: itemIndex,
                                                        rowField: field,
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              : [
                                                  _c("td", {
                                                    key: fieldIndex,
                                                    class: _vm.bodyClass(
                                                      "vuetable-td-" +
                                                        field.name,
                                                      field
                                                    ),
                                                    style: {
                                                      width: field.width,
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.renderNormalField(
                                                          field,
                                                          item
                                                        )
                                                      ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onCellClicked(
                                                          item,
                                                          itemIndex,
                                                          field,
                                                          $event
                                                        )
                                                      },
                                                      dblclick: function (
                                                        $event
                                                      ) {
                                                        return _vm.onCellDoubleClicked(
                                                          item,
                                                          itemIndex,
                                                          field,
                                                          $event
                                                        )
                                                      },
                                                      contextmenu: function (
                                                        $event
                                                      ) {
                                                        return _vm.onCellRightClicked(
                                                          item,
                                                          itemIndex,
                                                          field,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                          ]
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm.useDetailRow
                              ? [
                                  _c(
                                    "transition",
                                    {
                                      key: itemIndex,
                                      attrs: { name: _vm.detailRowTransition },
                                    },
                                    [
                                      _vm.isVisibleDetailRow(item[_vm.trackBy])
                                        ? _c(
                                            "tr",
                                            {
                                              class: _vm.onDetailRowClass(
                                                item,
                                                itemIndex
                                              ),
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onDetailRowClick(
                                                    item,
                                                    itemIndex,
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "td",
                                                {
                                                  attrs: {
                                                    colspan:
                                                      _vm.countVisibleFields,
                                                  },
                                                },
                                                [
                                                  _c(_vm.detailRowComponent, {
                                                    tag: "component",
                                                    attrs: {
                                                      "row-data": item,
                                                      "row-index": itemIndex,
                                                      options:
                                                        _vm.detailRowOptions,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        }),
                        _vm.displayEmptyDataRow
                          ? [
                              _c("tr", [
                                _c("td", {
                                  staticClass: "vuetable-empty-result",
                                  attrs: { colspan: _vm.countVisibleFields },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.noDataTemplate),
                                  },
                                }),
                              ]),
                            ]
                          : _vm._e(),
                        _vm.lessThanMinRows
                          ? _vm._l(_vm.blankRows, function (i) {
                              return _c(
                                "tr",
                                { key: i, staticClass: "blank-row" },
                                [
                                  _vm._l(
                                    _vm.tableFields,
                                    function (field, fieldIndex) {
                                      return [
                                        field.visible
                                          ? _c("td", { key: fieldIndex }, [
                                              _vm._v("   "),
                                            ])
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }