var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-pane show active" }, [
    _c(
      "div",
      {
        staticClass: "form-group",
        class: { "is-danger": _vm.errors.has("snotification.ntitle") },
      },
      [
        _c("label", { staticClass: "form-label", attrs: { for: "ntitle" } }, [
          _vm._v(_vm._s(_vm.$t("snotification_title"))),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.ntitle,
              expression: "ntitle",
            },
            {
              name: "validate",
              rawName: "v-validate.persist",
              value: "required|max:40",
              expression: "'required|max:40'",
              modifiers: { persist: true },
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            disabled: _vm.disabled,
            "data-vv-scope": "snotification",
            "data-vv-name": "ntitle",
            "data-vv-as": _vm.$t("snotification_title"),
          },
          domProps: { value: _vm.ntitle },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.ntitle = $event.target.value
              },
              _vm.handleTitle,
            ],
          },
        }),
        _c("button", {
          staticClass: "emoji-button",
          domProps: { innerHTML: _vm._s("☺") },
          on: {
            click: function ($event) {
              return _vm.dropdownToggle("title")
            },
          },
        }),
        _vm.dropdownIndex === "title"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "on-clickaway",
                    rawName: "v-on-clickaway",
                    value: _vm.hideDropdown,
                    expression: "hideDropdown",
                  },
                ],
                staticClass: "dropdown",
                staticStyle: { "z-index": "1000001" },
              },
              [
                _c(
                  "div",
                  {
                    ref: "dropdownBoxtitle",
                    staticClass: "dropdown__menu emoji-dropdown-title",
                  },
                  [
                    _c("VEmojiPicker", {
                      staticClass: "emoji",
                      attrs: {
                        pack: _vm.emojiPack,
                        "label-search": _vm.$t("search_placeholder"),
                      },
                      on: { select: _vm.SelectEmojiForTitle },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("snotification.ntitle"),
                expression: "errors.has('snotification.ntitle')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v(_vm._s(_vm.errors.first("snotification.ntitle")))]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "form-group",
        class: { "is-danger": _vm.errors.has("snotification.nbody") },
      },
      [
        _c("label", { staticClass: "form-label", attrs: { for: "nbody" } }, [
          _vm._v(_vm._s(_vm.$t("snotification_body"))),
        ]),
        _c("textarea", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate.persist",
              value: "required|max:110",
              expression: "'required|max:110'",
              modifiers: { persist: true },
            },
            {
              name: "model",
              rawName: "v-model",
              value: _vm.nbody,
              expression: "nbody",
            },
          ],
          staticClass: "form-control",
          attrs: {
            id: "body",
            maxlength: "110",
            disabled: _vm.disabled,
            "data-vv-scope": "snotification",
            "data-vv-name": "nbody",
            "data-vv-as": _vm.$t("snotification_body"),
          },
          domProps: { value: _vm.nbody },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.nbody = $event.target.value
              },
              _vm.handleBody,
            ],
          },
        }),
        _c("button", {
          staticClass: "emoji-button",
          domProps: { innerHTML: _vm._s("☺") },
          on: {
            click: function ($event) {
              return _vm.dropdownToggle("body")
            },
          },
        }),
        _vm.dropdownIndex === "body"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "on-clickaway",
                    rawName: "v-on-clickaway",
                    value: _vm.hideDropdown,
                    expression: "hideDropdown",
                  },
                ],
                staticClass: "dropdown",
                staticStyle: { "z-index": "1000001" },
              },
              [
                _c(
                  "div",
                  {
                    ref: "dropdownBoxbody",
                    staticClass: "dropdown__menu emoji-dropdown-body",
                  },
                  [
                    _c("VEmojiPicker", {
                      staticClass: "emoji",
                      attrs: {
                        pack: _vm.emojiPack,
                        "label-search": _vm.$t("search_placeholder"),
                      },
                      on: { select: _vm.SelectEmojiForBody },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("snotification.nbody"),
                expression: "errors.has('snotification.nbody')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v(_vm._s(_vm.errors.first("snotification.nbody")))]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "form-group",
        class: { "is-danger": _vm.errors.has("snotification.journeyItem") },
      },
      [
        _c("label", { staticClass: "form-label", attrs: { for: "nbody" } }, [
          _vm._v(_vm._s(_vm.$t("journey_item"))),
        ]),
        _c("multi-select", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required",
              expression: "'required'",
            },
          ],
          staticClass: "selectbox selectbox--secondary",
          class: { "is-danger": _vm.errors.has("snotification.journeyItem") },
          attrs: {
            "track-by": "name",
            label: "name",
            searchable: false,
            "close-on-select": true,
            "show-labels": false,
            options: _vm.journeyItems,
            placeholder: _vm.$t("select_placeholder"),
            "data-vv-name": "journeyItem",
            "data-vv-scope": "snotification",
            "data-vv-as": _vm.$t("journey_item"),
          },
          model: {
            value: _vm.journeyItem,
            callback: function ($$v) {
              _vm.journeyItem = $$v
            },
            expression: "journeyItem",
          },
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("snotification.journeyItem"),
                expression: "errors.has('snotification.journeyItem')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v(_vm._s(_vm.errors.first("snotification.journeyItem")))]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.errors.items.length > 0 &&
              (_vm.errors.has("snotification.ntitle") ||
                _vm.errors.has("snotification.nbody") ||
                _vm.errors.has("snotification.journeyItem")),
            expression:
              "errors.items.length > 0 && (errors.has('snotification.ntitle') || errors.has('snotification.nbody') || errors.has('snotification.journeyItem'))",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("div", { staticClass: "alert alert--card alert--error" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
          _c(
            "ul",
            _vm._l(_vm.errors, function (error, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(error.msg) + " "),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "button button--primary",
          attrs: { type: "button", disabled: _vm.isPending || _vm.disabled },
          on: {
            click: function ($event) {
              _vm.editMode ? _vm.updateContent() : _vm.createContent()
            },
          },
        },
        [
          !_vm.isPending
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.$t("update_button")
                        : _vm.$t("create_button")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.isPending
            ? _c("div", { staticClass: "lds-ellipsis" }, [
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }