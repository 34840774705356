var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-pane show active" },
    [
      _vm._t("default"),
      _c(
        "div",
        {
          staticClass: "form-group",
          class: { "is-danger": _vm.errors.has("quiz.title") },
        },
        [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "contentTitle" } },
            [_vm._v(_vm._s(_vm.$t("title").toUpperCase()))]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.title,
                expression: "title",
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|max:100",
                expression: "'required|max:100'",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              id: "contentTitle",
              disabled: _vm.disabled && !_vm.canEditOnlyItem,
              "data-vv-scope": "quiz",
              "data-vv-name": "title",
              "data-vv-as": _vm.$t("title"),
            },
            domProps: { value: _vm.title },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.title = $event.target.value
                },
                _vm.handleTitle,
              ],
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("quiz.title"),
                  expression: "errors.has('quiz.title')",
                },
              ],
              staticClass: "help is-danger",
            },
            [_vm._v(_vm._s(_vm.errors.first("quiz.title")))]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.items.length > 0,
              expression: "errors.items.length > 0",
            },
          ],
          staticClass: "form-group",
        },
        [
          _c("div", { staticClass: "alert alert--card alert--error" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
            _c(
              "ul",
              _vm._l(_vm.errors, function (error, index) {
                return _c("li", { key: index }, [
                  _vm._v(" " + _vm._s(error.msg) + " "),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "button button--primary",
            attrs: {
              type: "submit",
              disabled: _vm.isPending || (_vm.disabled && !_vm.canEditOnlyItem),
            },
            on: {
              click: function ($event) {
                _vm.editMode ? _vm.updateContent() : _vm.createContent()
              },
            },
          },
          [
            !_vm.isPending
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.editMode
                          ? _vm.$t("update_button")
                          : _vm.$t("create_button")
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.isPending
              ? _c("div", { staticClass: "lds-ellipsis" }, [
                  _c("div"),
                  _c("div"),
                  _c("div"),
                  _c("div"),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }