<template>
  <div
    class="tab-pane show active"
  >
    <div class="form-group form-group--cards">
      <draggable-comp
        class="request-listings"
        v-model="questions"
        v-bind="dragOptions"
        handle=".button-handle"
        @start="dragStart"
        @end="dragEnd"
      >
        <transition-group name="ilist">
          <div
            class="request-text-item"
            v-for="(item, qindex) in questions"
            :key="`k${qindex}`"
          >
            <span
              class="button-handle"
            >
              <icon-container
                name="handle"
                width="25"
                view-box="0 0 25 18"
                :is-icon-class="false"
              >
                <icon-handle />
              </icon-container>
            </span>
            <div
              class="single-update-block"
              v-if="editMode"
            >
              <transition
                name="fade"
                mode="in-out"
              >
                <button
                  type="button"
                  v-if="isDirty(item)"
                  class="button button--alternative text-size-xs size-sm m-0 p-2 float-right"
                  @click="saveChanges()"
                  :disabled="isPending"
                >
                  {{ $t('save_changes') }}
                </button>
              </transition>
              <transition
                name="fade"
                mode="in-out"
              >
                <button
                  type="button"
                  v-if="isUpdated(item)"
                  class="button button--secondary shadow-none text-size-xs size-sm m-0 p-2 float-right"
                >
                  {{ $t('saved_changes') }}
                </button>
              </transition>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="form-group"
                >
                  <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                  <label
                    for=""
                    class="form-label"
                  >{{ $t('question') }} {{ qindex + 1 }}</label>
                  <textarea
                    :class="{ 'is-danger': errors.has(`request.question${qindex}`) }"
                    rows="1"
                    v-resizable
                    @input.once="triggerPreviewNextQuestion(qindex)"
                    :disabled="disabled"
                    class="form-control"
                    :placeholder="$t('question_placeholder')"
                    v-model="item.question"
                    data-vv-scope="request"
                    :key="qindex"
                    :name="'question' + qindex"
                    :data-vv-as="$t('question_') + (qindex + 1)"
                    v-validate.disable="{
                      required: true,
                      max: 1000,
                      min:5,
                    }"
                  />
                  <span
                    :key="'error'+qindex"
                    v-if="errors.has(`request.question${qindex}`)"
                    class="help is-danger"
                  >{{ errors.first(`request.question${qindex}`) }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="form-group"
                >
                  <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                  <label
                    for=""
                    class="form-label"
                  >{{ $t('description') }}</label>
                  <textarea
                    :class="{ 'is-danger': errors.has(`request.sub_text${qindex}`) }"
                    rows="1"
                    v-resizable
                    :disabled="disabled"
                    class="form-control"
                    :placeholder="$t('type_your_desciption')"
                    v-model="item.sub_text"
                    data-vv-scope="request"
                    :name="'sub_text' + qindex"
                    :data-vv-as="$t('description') + (qindex + 1)"
                    v-validate.disable="{
                      max: 400,
                    }"
                  />
                  <span
                    :key="'derror'+qindex"
                    v-if="errors.has(`request.sub_text${qindex}`)"
                    class="help is-danger"
                  >{{ errors.first(`request.sub_text${qindex}`) }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div
                  class="form-group"
                  v-if="item.type === 'get_info'"
                >
                  <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                  <label
                    for=""
                    class="form-label"
                  >{{ $t('format') }}</label>
                  <input
                    type="text"
                    :class="{ 'is-danger': errors.has(`request.format${qindex}`) }"
                    :disabled="disabled"
                    class="form-control"
                    :placeholder="$t('type_your_format')"
                    v-model="item.options.format"
                    data-vv-scope="request"
                    :name="'format' + qindex"
                    :data-vv-as="$t('format') + (qindex + 1)"
                  >
                  <span
                    :key="'derror'+qindex"
                    v-if="errors.has(`request.format${qindex}`)"
                    class="help is-danger"
                  >{{ errors.first(`request.format${qindex}`) }}</span>
                </div>
              </div>
              <div class="col-6 form-group">
                <label
                  for=""
                  class="form-label"
                >{{ $t('type') }} </label>
                <div class="d-flex rating-block">
                  <div class="view-mode-type d-flex align-items-center">
                    <label
                      class="m-0"
                      :class="{'selected':item.type === 'get_info' }"
                      @click="item.type = 'get_info'"
                    >
                      <icon-container
                        view-box="0 0 75 44"
                        :width="75"
                        :height="44"
                        name="card"
                        color="transparent"
                      >
                        <icon-requestinfo />
                      </icon-container>
                    </label>
                    <label
                      class="m-0 ml-2"
                      :class="{'selected':item.type === 'image'}"
                      @click="item.type ='image'"
                    >
                      <icon-container
                        view-box="0 0 75 44"
                        :width="75"
                        :height="44"
                        name="list"
                        color="transparent"
                      >
                        <icon-requestimage />
                      </icon-container>
                    </label>
                    <!-- // this option was removed but is being left open for existed ones on edit mode -->
                    <label
                      v-if="editMode && item.type === 'file'"
                      class="m-0 ml-2"
                      :class="{'selected':item.type === 'file' }"
                      @click="item.type = 'file'"
                    >
                      <icon-container
                        view-box="0 0 75 44"
                        :width="75"
                        :height="44"
                        name="list"
                        color="transparent"
                      >
                        <icon-requestfile />
                      </icon-container>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="button-remove"
              :disabled="disabled"
              @click="removeQuestionItem(qindex)"
              v-if="item"
            >
              <icon-container
                :width="15"
                :height="15"
                view-box="0 0 25 25"
                name="trash"
                color="#727d92"
              >
                <icon-trash />
              </icon-container>
            </button>
          </div>
        </transition-group>
      </draggable-comp>
    </div>
    <div class="modal-footer">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-12">
          <div
            class="d-flex justify-content-center align-items-center pointer-click"
            @click="addQuestion"
          >
            <span class="card--actions request-button-add">
              <icon-container
                name="cross"
                :width="20"
                :height="20"
                color="#fff"
              >
                <icon-cross />
              </icon-container>
            </span>
            <span class="request-btn-text">{{ $t('add_button') }}</span>
          </div>
        </div>
      </div>

      <span
        v-if="errors.has('request.question')"
        class="help is-danger"
      >{{ errors.first('request.question') }}</span>
    </div>
    <div
      class="form-group"
      :class="{ 'is-danger': errors.has('request.title') }"
    >
      <label
        for="contentTitle"
        class="form-label"
      >{{ $t('title').toUpperCase() }}</label>
      <input
        type="text"
        class="form-control"
        id="contentTitle"
        v-model="title"
        :disabled="disabled && !canEditOnlyItem"
        data-vv-scope="request"
        data-vv-name="title"
        :data-vv-as="$t('title')"
        v-validate="'required|max:100'"
        @input="handleTitle"
      >
      <span
        v-show="errors.has('request.title')"
        class="help is-danger"
      >{{ errors.first('request.title') }}</span>
    </div>
    <div
      v-show="errors.items.length > 0"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        class="button button--primary"
        @click="editMode ? isOrderChanged ? saveOrder() : updateContent() : createContent()"
        :disabled="isPending || (disabled && !canEditOnlyItem)"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? isOrderChanged ? $t('update_order_button') : $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import Draggable from 'vuedraggable';
import IconTrash from '@/components/icons/Trash.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconCross from '@/components/icons/Cross.vue';
import IconRequestInfo from '@/components/icons/RequestInfo.vue';
import IconRequestImage from '@/components/icons/RequestImage.vue';
import IconRequestFile from '@/components/icons/RequestFile.vue';
import IconHandle from '@/components/icons/Handle.vue';

export default {
  name: 'RequestContent',

  inject: ['$validator'],

  components: {
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-cross': IconCross,
    'icon-requestinfo': IconRequestInfo,
    'icon-requestimage': IconRequestImage,
    'icon-requestfile': IconRequestFile,
    'icon-handle': IconHandle,
    'draggable-comp': Draggable,
  },

  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      title: '',
      isPending: false,
      questions: [],
      idHolder: null,
      snotifyQueueList: [],
      queindex: null,
      questionsCloned: [],
      dirtyList: null,
      updatedList: [],
      initialOrder: [],
      lastestOrder: [],
      isOrderChanged: false,
    };
  },
  directives: {
    'on-clickaway': onClickaway,
    resizable: {
      inserted(el) {
        el.addEventListener('input', (e) => {
          e.target.style.height = 'auto';
          e.target.style.height = `${e.target.scrollHeight}px`;
        });
      },
    },
  },

  computed: {
    modalData() {
      return this.$store.state.modal.modalData;
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
    dragOptions() {
      return {
        animation: 150,
        disabled: false,
        ghostClass: 'newghost',
        sort: true,
        forceFallback: !!(/Edge/.test(navigator.userAgent)),
      };
    },
  },

  watch: {
    modalData: {
      handler(newVal) {
        const $newVal = newVal;
        if ($newVal) {
          if (this.editMode && $newVal.request) {
            this.questions = $newVal.request.questions;
            this.prepareDirty();
          }
        }
      },
      immediate: true,
    },

    formError: {
      handler(newVal) {
        if (newVal) {
          this.isPending = false;
        }
      },
      immediate: true,
    },

    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },
    // isOrderChanged: {
    //   handler(newVal) {
    //     console.log('isOrderChanged:', newVal);
    //   },
    //   immediate: true,
    // },
    // lastestOrder: {
    //   handler(newVal) {
    //     console.log('lastestOrder:', newVal);
    //     console.log('initialOrder:', this.initialOrder);
    //   },
    //   immediate: true,
    // },
    // dirtyList: {
    //   handler(newVal) {
    //     console.log('dirtyList:', newVal);
    //   },
    //   immediate: true,
    // },
    questions: {
      handler(newvalue) {
        this.$emit('setQuestions', newvalue);
        if (this.editMode) {
          this.prepareDirty();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  async created() {
    if (this.editMode) {
      const items = this.dash.cloneDeep(this.modalData.request.questions);
      this.initialOrder = [...items.map(i => i.id)];
      this.questionsCloned = items;
    } else {
      this.addQuestion();
    }
  },
  mounted() {
    if (this.editMode) {
      this.setHeights();
    }
  },
  methods: {
    stepUpdate() {
      this.$emit('setQuestions', this.questions);
    },

    setHeights() {
      this.$nextTick(() => {
        [...document.querySelectorAll('textarea')].forEach((textarea) => {
          // eslint-disable-next-line no-param-reassign
          textarea.style.height = `${textarea.scrollHeight}px`;
        });
      });
    },

    dragStart() {
      console.log('dragstarted');
    },

    async dragEnd() {
      this.setHeights();
      const copy = await this.dash.cloneDeep(this.questions);
      this.lastestOrder = [...copy.map(i => i.id)];
      this.isOrderChanged = !this.dash.isEqual(this.lastestOrder, this.initialOrder);
    },

    async saveOrder() {
      this.isPending = true;
      const payload = { ordered_question_ids: '', content_id: null };
      payload.content_id = this.modalData.id;
      payload.ordered_question_ids = JSON.stringify(this.lastestOrder);
      await this.$store.dispatch('modal/UPDATE_ORDER_REQUEST', payload).then(async () => {
        this.isPending = false;
        this.isOrderChanged = false;
        this.$snotify.success(this.$t('order_changed'));
      });
    },

    async saveChanges() {
      this.checkValidation().then(async (res) => {
        if (res) {
          this.isPending = true;
          const payload = { requestQuestions: [], content_id: null };
          payload.content_id = this.modalData.id;
          if (this.dirtyList) {
            this.dirtyList.forEach((element) => {
              payload.requestQuestions.push(element);
            });
          }
          await this.$store.dispatch('modal/UPDATE_REQUEST_QUESTION', payload).then(async () => {
            if (!this.$store.state.modal.formError) {
              this.questionsCloned = await this.dash.cloneDeep(this.questions);
              this.isPending = false;
              this.dirtyList = [];
              setTimeout(() => {
                this.updatedList = payload.requestQuestions;
              }, 1000);
              setTimeout(() => {
                this.updatedList = [];
              }, 3000);
            }
          });
        }
      });
    },

    isDirty(item) {
      if (this.dirtyList) {
        const found = this.dirtyList.find(element => element.id === item.id);
        if (found) {
          return true;
        }
      }
      return false;
    },

    isUpdated(item) {
      if (this.updatedList) {
        const found = this.updatedList.find(element => element.id === item.id);
        if (found) {
          return true;
        }
      }
      return false;
    },

    async prepareDirty() {
      const items = await this.dash.cloneDeep(this.questions);
      this.dirtyList = await this.dash.differenceWith(items, this.questionsCloned, this.dash.isEqual);
    },

    handleTitle() {
      this.$emit('setTitle', this.title);
    },

    triggerPreviewNextQuestion(qindex) {
      this.$emit('triggerPreviewNextQuestion', qindex - 1);
    },

    checkItemValidate(qindex) {
      if (this.errors.items.length > 0) {
        this.$validator.validate(`request.question${qindex}`);
      }
    },

    async checkValidation() {
      let allIsValid = false;
      let requestIsValid = false;
      let customCheck = true;

      this.clearErrorMsg();

      if (this.questions.length < 1) { // check at least one question exist
        this.$validator.errors.add({
          field: 'question',
          msg: this.$t('quiz_one_question'),
          scope: 'request',
        });
        customCheck = false;
      }

      await this.$validator.validateAll('all').then((res) => {
        allIsValid = res;
      });
      await this.$validator.validateAll('request').then((res) => {
        requestIsValid = res;
      });
      if (allIsValid && requestIsValid && customCheck) {
        return true;
      }
      return false;
    },

    clearErrorMsg() {
      this.$validator.errors.clear();
    },

    addQuestion() {
      this.questions.push({
        question: '',
        type: 'get_info',
        options: { format: '####_###_##', input_type: 'text' },
        sub_text: '',
      });
    },

    removeQuestionItem(qindex) {
      const removeQestionId = this.questions[qindex].id;
      this.queindex = qindex;
      if (removeQestionId) {
        if (!this.snotifyQueueList.includes(removeQestionId)) {
          this.idHolder = removeQestionId;
          this.snotifyQueueList.push(removeQestionId);
          this.$snotify.confirm(this.$t('question_delete_msg_body'), this.$t('question_delete_msg_title'), {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            buttons: [
              {
                text: this.$t('yes'),
                action: (toast) => {
                  this.deleteConfirm(toast, this.idHolder, qindex);
                },
                bold: false,
              },
              {
                text: this.$t('no'),
                action: (toast) => {
                  this.deleteCancel(toast, this.idHolder);
                },
              },
            ],
          }).on('shown', (toast) => {
            this.toastHolder = toast;
            document.addEventListener('keydown', this.eventHandler, false);
          }).on('hidden', (toast) => {
            this.toastHolder = toast;
            document.removeEventListener('keydown', this.eventHandler, false);
          });
        }
      } else {
        this.questions.splice(qindex, 1);
      }
    },

    deleteConfirm(toast, itemId, qindex) {
      this.$snotify.remove(toast.id);
      const snotifyIndex = this.snotifyQueueList.indexOf(itemId);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.$store.dispatch('modal/DELETE_REQUEST_QUESTION', itemId).then(() => {
        if (!this.formError) {
          this.questions.splice(qindex, 1);
        } else {
          this.formError = false;
        }
      });
    },

    deleteCancel(toast, itemId) {
      const snotifyIndex = this.snotifyQueueList.indexOf(itemId);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.$snotify.remove(toast.id);
    },

    eventHandler(event) {
      event.preventDefault();
      const key = event.key || event.keyCode;
      if (key === 'Enter' || key === 13) {
        this.deleteConfirm(this.toastHolder, this.idHolder, this.queindex);
      } else if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.deleteCancel(this.toastHolder, this.idHolder);
      }
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    updateContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$root.$emit('updateDirtyList', this.dirtyList);
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-footer .button {
    width: 100%;
}
.modal-footer .row {
    margin-bottom: 30px;
}
.request-text-item {
  width: 100%;
  padding: 25px 50px;
  border-radius: 14px;
  box-shadow: 0 1px 6px 0 rgba(114, 125, 146, 0.2);
  border: solid 2px rgba(114, 125, 146, 0.2);
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 30px;
  position: relative;

  .button-remove {
    opacity: 0;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .button-handle {
    position: absolute;
    top: 10px;
    left: 15px;
    opacity: 0;
    cursor: move;
  }

  &:hover {
    .button-remove {
      opacity: 1;
    }
    .button-handle {
      opacity: 1;
    }
  }

  ol {
    list-style: upper-alpha inside;
  }

  .form-label {
    @include primary-medium;
    font-size: 12px;
    color: #727d92;
  }

  .checkbox {
    @include primary-medium;
    padding: 5px;
    font-size: 12px;
    letter-spacing: 0.38px;
    color: #727d92;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    input {
      margin-right: 5px;
    }
  }
  &.sortable-chosen.newghost {
    background-color: #2e74f741;
  }
}
.is-danger {
  border-color: #f27299;
}
.request-button-add {
  background-color: #F27299;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50%;
  height:48px;
  width: 48px;
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 10px;
}
.request-btn-text {
  color: #F27299;
  font-weight: bolder;
  margin-left:20px;
}
.white-text {
  color: #fff!important;
}
::v-deep .rating-block {
  height: 44px;
  width: 100%;
  justify-content: flex-end;
  .view-mode-type {
    label svg g {
      path {
        fill: #142B58;
        stroke: #142B58;
      }
      rect {
        stroke: #142B58;
      }
    }
    label.selected svg g {
      path {
        fill: #F27299;
        stroke: #F27299;
      }
      rect {
        stroke: #F27299;
      }
    }
  }
}

::v-deep textarea.form-control {
  min-height: 44px;
  height: 100%;
  overflow: hidden;
  resize:vertical;
}
::v-deep .ilist-move{
  transition: .5s;
}
.pointer-click {
  cursor: pointer;
}
</style>
