var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-pane show active" }, [
    _c("div", { staticClass: "col-12" }, [
      !_vm.editMode
        ? _c("div", { staticClass: "d-flex my-2" }, [
            _c(
              "label",
              {
                staticClass: "form-label check-button mr-4",
                class: { active: _vm.surveyContentType === "survey" },
                on: {
                  click: function ($event) {
                    return _vm.switchSurveyType("survey")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("survey")) + " ")]
            ),
            _c(
              "label",
              {
                staticClass: "form-label check-button mr-4",
                class: { active: _vm.surveyContentType === "nps" },
                on: {
                  click: function ($event) {
                    return _vm.switchSurveyType("nps")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("nps")) + " ")]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "form-group form-group--cards" },
        [
          _c(
            "draggable-comp",
            _vm._b(
              {
                staticClass: "survey-listings",
                attrs: {
                  handle: ".button-handle",
                  disabled: _vm.surveyContentType === "nps",
                },
                on: { start: _vm.dragStart, end: _vm.dragEnd },
                model: {
                  value: _vm.questions,
                  callback: function ($$v) {
                    _vm.questions = $$v
                  },
                  expression: "questions",
                },
              },
              "draggable-comp",
              _vm.dragOptions,
              false
            ),
            [
              _c(
                "transition-group",
                { attrs: { name: "ilist" } },
                _vm._l(_vm.questions, function (item, qindex) {
                  return _c(
                    "div",
                    { key: `k${qindex}`, staticClass: "survey-text-item" },
                    [
                      _c(
                        "span",
                        {
                          class: `button-handle ${
                            _vm.surveyContentType === "nps" ? "disabled" : ""
                          }`,
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                name: "handle",
                                width: "25",
                                "view-box": "0 0 25 18",
                                "is-icon-class": false,
                              },
                            },
                            [_c("icon-handle")],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.editMode
                        ? _c(
                            "div",
                            { staticClass: "single-update-block" },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "in-out" } },
                                [
                                  _vm.isDirty(item)
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "button button--alternative text-size-xs size-sm m-0 p-2 float-right",
                                          attrs: {
                                            type: "button",
                                            disabled: _vm.isPending,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveChanges()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("save_changes")) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "in-out" } },
                                [
                                  _vm.isUpdated(item)
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "button button--secondary shadow-none text-size-xs size-sm m-0 p-2 float-right",
                                          attrs: { type: "button" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("saved_changes")) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { staticClass: "form-label", attrs: { for: "" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("question")) +
                                    " " +
                                    _vm._s(qindex + 1)
                                ),
                              ]
                            ),
                            _c("textarea", {
                              directives: [
                                { name: "resizable", rawName: "v-resizable" },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.question,
                                  expression: "item.question",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate.disable",
                                  value: {
                                    required: true,
                                    max: 1000,
                                    min: 5,
                                    minSurveyAnswer: item,
                                  },
                                  expression:
                                    "{\n                      required: true,\n                      max: 1000,\n                      min:5,\n                      minSurveyAnswer: item,\n                    }",
                                  modifiers: { disable: true },
                                },
                              ],
                              key: qindex,
                              staticClass: "form-control",
                              class: {
                                "is-danger": _vm.errors.has(
                                  `survey.question${qindex}`
                                ),
                              },
                              attrs: {
                                rows: "1",
                                disabled: _vm.disabled,
                                placeholder: _vm.$t("question_placeholder"),
                                "data-vv-scope": "survey",
                                name: "question" + qindex,
                                "data-vv-as":
                                  _vm.$t("question_") + (qindex + 1),
                              },
                              domProps: { value: item.question },
                              on: {
                                "~input": function ($event) {
                                  return _vm.triggerPreviewNextQuestion(qindex)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    item,
                                    "question",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has(`survey.question${qindex}`)
                              ? _c(
                                  "span",
                                  {
                                    key: "error" + qindex,
                                    staticClass: "help is-danger",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.errors.first(
                                          `survey.question${qindex}`
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { staticClass: "form-label", attrs: { for: "" } },
                              [_vm._v(_vm._s(_vm.$t("description")))]
                            ),
                            _c("textarea", {
                              directives: [
                                { name: "resizable", rawName: "v-resizable" },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.sub_text,
                                  expression: "item.sub_text",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate.disable",
                                  value: {
                                    max: 400,
                                  },
                                  expression:
                                    "{\n                      max: 400,\n                    }",
                                  modifiers: { disable: true },
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-danger": _vm.errors.has(
                                  `survey.sub_text${qindex}`
                                ),
                              },
                              attrs: {
                                rows: "1",
                                disabled: _vm.disabled,
                                placeholder: _vm.$t("type_your_desciption"),
                                "data-vv-scope": "survey",
                                name: "sub_text" + qindex,
                                "data-vv-as":
                                  _vm.$t("description") + (qindex + 1),
                              },
                              domProps: { value: item.sub_text },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    item,
                                    "sub_text",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has(`survey.sub_text${qindex}`)
                              ? _c(
                                  "span",
                                  {
                                    key: "derror" + qindex,
                                    staticClass: "help is-danger",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.errors.first(
                                          `survey.sub_text${qindex}`
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      item.type === "single-choice" ||
                      item.type === "multiple-choice"
                        ? _c("div", [
                            _c(
                              "ol",
                              { staticClass: "row", attrs: { type: "A" } },
                              [
                                _vm._l(item.choices, function (choice, aindex) {
                                  return _c(
                                    "li",
                                    {
                                      key: aindex,
                                      staticClass: "col-12 col-md-6 form-label",
                                    },
                                    [
                                      choice &&
                                      Object.prototype.hasOwnProperty.call(
                                        choice,
                                        "imagePreview"
                                      )
                                        ? _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb-2" },
                                                    [
                                                      _c(
                                                        "thumbnail-container",
                                                        {
                                                          staticClass:
                                                            "card card--upload",
                                                          class: {
                                                            "is-danger":
                                                              _vm.errors.has(
                                                                `imageanswer${qindex}${aindex}`
                                                              ),
                                                          },
                                                          attrs: {
                                                            "thumbnail-image":
                                                              _vm.questions[
                                                                qindex
                                                              ].choices[aindex]
                                                                .imagePreview,
                                                            "quiz-indexes": {
                                                              qindex,
                                                              aindex,
                                                            },
                                                            disabled:
                                                              _vm.disabled,
                                                            "is-multiple": true,
                                                            "vee-rule":
                                                              "required|size:5000|mimes:image/jpeg,image/png",
                                                            "vee-scoop-name":
                                                              "survey",
                                                            "vee-name": `imageanswer${qindex}${aindex}`,
                                                            "vee-as":
                                                              _vm.$t(
                                                                "quiz_type_image"
                                                              ),
                                                          },
                                                          on: {
                                                            update:
                                                              _vm.imageAdd,
                                                            updateMultiple:
                                                              _vm.imageAddMultiple,
                                                          },
                                                        }
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.errors.has(
                                                                  `imageanswer${qindex}${aindex}`
                                                                ),
                                                              expression:
                                                                "errors.has(`imageanswer${qindex}${aindex}`)",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "help is-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                `imageanswer${qindex}${aindex}`
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("div", [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            choice.choice_text,
                                                          expression:
                                                            "choice.choice_text",
                                                        },
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value:
                                                            "required|max:100",
                                                          expression:
                                                            "'required|max:100'",
                                                        },
                                                      ],
                                                      key: `${qindex}${aindex}`,
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "text",
                                                        disabled: _vm.disabled,
                                                        name: "answer",
                                                        placeholder: _vm.$t(
                                                          "answer_placeholder_text"
                                                        ),
                                                        "data-vv-scope":
                                                          "survey",
                                                        "data-vv-name": `answer${qindex}${aindex}`,
                                                        "data-vv-as":
                                                          _vm.$t("answer"),
                                                      },
                                                      domProps: {
                                                        value:
                                                          choice.choice_text,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            choice,
                                                            "choice_text",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm.errors.has(
                                                      `survey.answer${qindex}${aindex}`
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "help is-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors.first(
                                                                  `survey.answer${qindex}${aindex}`
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-between align-items-start mt-2",
                                                      },
                                                      [
                                                        item.choices.length > 1
                                                          ? _c(
                                                              "button",
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.disabled,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeAnswerItem(
                                                                        aindex,
                                                                        qindex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "icon-container",
                                                                  {
                                                                    attrs: {
                                                                      width: 15,
                                                                      height: 15,
                                                                      "view-box":
                                                                        "0 0 25 25",
                                                                      name: "trash",
                                                                      color:
                                                                        "#727d92",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "icon-trash"
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: choice.choice_text,
                                                    expression:
                                                      "choice.choice_text",
                                                  },
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required|max:100",
                                                    expression:
                                                      "'required|max:100'",
                                                  },
                                                ],
                                                key: `${qindex}${aindex}`,
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  disabled: _vm.disabled,
                                                  name: "answer",
                                                  placeholder:
                                                    _vm.$t(
                                                      "answer_placeholder"
                                                    ),
                                                  "data-vv-scope": "survey",
                                                  "data-vv-name": `answer${qindex}${aindex}`,
                                                  "data-vv-as":
                                                    _vm.$t("answer"),
                                                },
                                                domProps: {
                                                  value: choice.choice_text,
                                                },
                                                on: {
                                                  input: [
                                                    function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        choice,
                                                        "choice_text",
                                                        $event.target.value
                                                      )
                                                    },
                                                    function ($event) {
                                                      _vm.addAnswerItem(
                                                        qindex,
                                                        aindex
                                                      )
                                                      _vm.triggerPreviewNextQuestion(
                                                        qindex
                                                      )
                                                    },
                                                  ],
                                                },
                                              }),
                                              _vm.errors.has(
                                                `survey.answer${qindex}${aindex}`
                                              )
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "help is-danger",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            `survey.answer${qindex}${aindex}`
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-between align-items-start mt-2",
                                                },
                                                [
                                                  item.choices.length > 1
                                                    ? _c(
                                                        "button",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeAnswerItem(
                                                                aindex,
                                                                qindex
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "icon-container",
                                                            {
                                                              attrs: {
                                                                width: 15,
                                                                height: 15,
                                                                "view-box":
                                                                  "0 0 25 25",
                                                                name: "trash",
                                                                color:
                                                                  "#727d92",
                                                              },
                                                            },
                                                            [_c("icon-trash")],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                    ]
                                  )
                                }),
                                _vm.checkNeedForAddAnswer(item.choices)
                                  ? _c(
                                      "li",
                                      {
                                        staticClass:
                                          "col-12 col-md-6 form-label",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "button button--alternative button--icon text-size-xs form-group",
                                            attrs: {
                                              type: "button",
                                              disabled: _vm.disabled,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.addAnswerSmart(
                                                  item.choices,
                                                  qindex
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                staticClass: "icon-svg",
                                                attrs: {
                                                  name: "circleplusfull",
                                                  "view-box": "0 0 512 512",
                                                  color: "#fff",
                                                  "is-icon-class": false,
                                                },
                                              },
                                              [_c("icon-circleplusfull")],
                                              1
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("add_answer_button")
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                            item
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button-remove",
                                    attrs: { disabled: _vm.disabled },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeQuestionItem(qindex)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        attrs: {
                                          width: 15,
                                          height: 15,
                                          "view-box": "0 0 25 25",
                                          name: "trash",
                                          color: "#727d92",
                                        },
                                      },
                                      [_c("icon-trash")],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-between align-items-start",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p-0 form-group form-checkbox u-justify-start d-flex justify-content-start align-items-start",
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "mt-1",
                                      attrs: {
                                        type: "checkbox",
                                        disabled: _vm.disabled,
                                        name: `limitSingleChoice${qindex}`,
                                      },
                                      domProps: {
                                        checked:
                                          item.type === "single-choice"
                                            ? true
                                            : false,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.makeSingleChoice(
                                            $event,
                                            qindex
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-label",
                                        attrs: {
                                          for: `limitSingleChoice${qindex}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("limit_user_to_select")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p-0 form-group form-checkbox u-justify-start d-flex justify-content-start align-items-start",
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "mt-1",
                                      attrs: {
                                        type: "checkbox",
                                        disabled: _vm.disabled,
                                        name: `hasOtherChoice${qindex}`,
                                      },
                                      domProps: {
                                        checked:
                                          item &&
                                          item.options &&
                                          item.options.has_other_option
                                            ? true
                                            : false,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.makeOtherChoice(
                                            $event,
                                            qindex
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-label",
                                        attrs: {
                                          for: `hasOtherChoice${qindex}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("has_other_choice")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      item.type === "text"
                        ? _c("div", [
                            _c("div", { staticClass: "form-group" }, [
                              item && _vm.surveyContentType === "survey"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "button-remove",
                                      attrs: { disabled: _vm.disabled },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeQuestionItem(qindex)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: 15,
                                            height: 15,
                                            "view-box": "0 0 25 25",
                                            name: "trash",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-trash")],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                      item.type === "rating"
                        ? _c("div", [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-6 form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label d-block",
                                    class: {
                                      disabled: _vm.surveyContentType === "nps",
                                    },
                                    attrs: { for: "" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("max_value")))]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: item.options.step,
                                      expression: "item.options.step",
                                      modifiers: { number: true },
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate.disable",
                                      value: {
                                        required: true,
                                        max:
                                          item.options.shape === "number"
                                            ? 10
                                            : 5,
                                        min: 1,
                                      },
                                      expression:
                                        "{\n                      required: true,\n                      max: item.options.shape === 'number' ? 10:5,\n                      min:1,\n                    }",
                                      modifiers: { disable: true },
                                    },
                                  ],
                                  staticClass: "form-control number-box",
                                  class: {
                                    "is-danger": _vm.errors.has(
                                      `survey.step${qindex}`
                                    ),
                                  },
                                  attrs: {
                                    type: "number",
                                    disabled:
                                      _vm.disabled ||
                                      _vm.surveyContentType === "nps",
                                    min: "1",
                                    max:
                                      item.options.shape === "number" ? 10 : 5,
                                    "data-vv-scope": "survey",
                                    name: "step" + qindex,
                                    "data-vv-as": _vm.$t("max_value"),
                                  },
                                  domProps: { value: item.options.step },
                                  on: {
                                    change: _vm.stepUpdate,
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        item.options,
                                        "step",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _vm.errors.has(`survey.step${qindex}`)
                                  ? _c(
                                      "span",
                                      {
                                        key: "derror" + qindex,
                                        staticClass: "help is-danger",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first(
                                              `survey.step${qindex}`
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "col-6 form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-label",
                                    attrs: { for: "" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("rate_shape")))]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex rating-block" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "view-mode-type d-flex align-items-center",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "m-0",
                                            class: {
                                              selected:
                                                item.options.shape === "star",
                                              disabled:
                                                _vm.surveyContentType === "nps",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.surveyContentType ===
                                                "survey"
                                                  ? (item.options.step =
                                                      Number(5))
                                                  : null
                                              },
                                            },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.options.shape,
                                                  expression:
                                                    "item.options.shape",
                                                },
                                              ],
                                              attrs: {
                                                type: "radio",
                                                disabled:
                                                  _vm.surveyContentType ===
                                                  "nps",
                                                value: "star",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  item.options.shape,
                                                  "star"
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    item.options,
                                                    "shape",
                                                    "star"
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "icon-container",
                                              {
                                                attrs: {
                                                  "view-box": "0 0 75 44",
                                                  width: 75,
                                                  height: 44,
                                                  name: "card",
                                                  color: "none",
                                                },
                                              },
                                              [_c("icon-starchoice")],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "m-0",
                                            class: {
                                              selected:
                                                item.options.shape === "number",
                                              disabled:
                                                _vm.surveyContentType === "nps",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.surveyContentType ===
                                                "survey"
                                                  ? (item.options.step =
                                                      Number(10))
                                                  : null
                                              },
                                            },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.options.shape,
                                                  expression:
                                                    "item.options.shape",
                                                },
                                              ],
                                              attrs: {
                                                type: "radio",
                                                disabled:
                                                  _vm.surveyContentType ===
                                                  "nps",
                                                value: "number",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  item.options.shape,
                                                  "number"
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    item.options,
                                                    "shape",
                                                    "number"
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "icon-container",
                                              {
                                                attrs: {
                                                  "view-box": "0 0 75 44",
                                                  width: 75,
                                                  height: 44,
                                                  name: "list",
                                                  color: "none",
                                                },
                                              },
                                              [_c("icon-numberchoice")],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "m-0",
                                            class: {
                                              selected:
                                                item.options.shape ===
                                                  "thumb" ||
                                                item.options.shape ===
                                                  "thumbsup",
                                              disabled:
                                                _vm.surveyContentType === "nps",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.surveyContentType ===
                                                "survey"
                                                  ? (item.options.step =
                                                      Number(5))
                                                  : null
                                              },
                                            },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.options.shape,
                                                  expression:
                                                    "item.options.shape",
                                                },
                                              ],
                                              attrs: {
                                                type: "radio",
                                                disabled:
                                                  _vm.surveyContentType ===
                                                  "nps",
                                                value: "thumbsup",
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  item.options.shape,
                                                  "thumbsup"
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.$set(
                                                    item.options,
                                                    "shape",
                                                    "thumbsup"
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "icon-container",
                                              {
                                                attrs: {
                                                  "view-box": "0 0 75 44",
                                                  width: 75,
                                                  height: 44,
                                                  name: "list",
                                                  color: "none",
                                                },
                                              },
                                              [_c("icon-ratechoice")],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              item.options.shape === "number"
                                ? _c(
                                    "div",
                                    { staticClass: "col-6 form-group" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-label d-block",
                                          attrs: { for: "" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("min_value_text"))
                                          ),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.options.min_value,
                                            expression:
                                              "item.options.min_value",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate.disable",
                                            value: {
                                              max: 60,
                                            },
                                            expression:
                                              "{\n                      max: 60,\n                    }",
                                            modifiers: { disable: true },
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: {
                                          "is-danger": _vm.errors.has(
                                            `survey.minval${qindex}`
                                          ),
                                        },
                                        attrs: {
                                          type: "text",
                                          disabled: _vm.disabled,
                                          "data-vv-scope": "survey",
                                          name: "minval" + qindex,
                                          "data-vv-as":
                                            _vm.$t("min_value_text"),
                                        },
                                        domProps: {
                                          value: item.options.min_value,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              item.options,
                                              "min_value",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              item.options.shape === "number"
                                ? _c(
                                    "div",
                                    { staticClass: "col-6 form-group" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "form-label d-block",
                                          attrs: { for: "" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("max_value_text"))
                                          ),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.options.max_value,
                                            expression:
                                              "item.options.max_value",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate.disable",
                                            value: {
                                              max: 60,
                                            },
                                            expression:
                                              "{\n                      max: 60,\n                    }",
                                            modifiers: { disable: true },
                                          },
                                        ],
                                        staticClass: "form-control",
                                        class: {
                                          "is-danger": _vm.errors.has(
                                            `survey.step${qindex}`
                                          ),
                                        },
                                        attrs: {
                                          type: "text",
                                          disabled: _vm.disabled,
                                          "data-vv-scope": "survey",
                                          name: "maxval" + qindex,
                                          "data-vv-as":
                                            _vm.$t("max_value_text"),
                                        },
                                        domProps: {
                                          value: item.options.max_value,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              item.options,
                                              "max_value",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            item && _vm.surveyContentType === "survey"
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button-remove",
                                    attrs: { disabled: _vm.disabled },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeQuestionItem(qindex)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        attrs: {
                                          width: 15,
                                          height: 15,
                                          "view-box": "0 0 25 25",
                                          name: "trash",
                                          color: "#727d92",
                                        },
                                      },
                                      [_c("icon-trash")],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "div",
          {
            staticClass: "row d-flex justify-content-center align-items-center",
          },
          [
            _c("div", { staticClass: "col-12" }, [
              _vm.surveyContentType === "survey"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "dropdown d-flex justify-content-center align-items-center",
                      on: {
                        mouseover: function ($event) {
                          return _vm.dropdownToggle("Survey")
                        },
                        mouseleave: _vm.hideDropdown,
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "card--actions dropdown__toggle survey-button-add",
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                name: "cross",
                                width: 20,
                                height: 20,
                                color: "#fff",
                              },
                            },
                            [_c("icon-cross")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "survey-btn-text" }, [
                        _vm._v(_vm._s(_vm.$t("add_button"))),
                      ]),
                      _vm.dropdownIndex === "Survey"
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "on-clickaway",
                                  rawName: "v-on-clickaway",
                                  value: _vm.hideDropdown,
                                  expression: "hideDropdown",
                                },
                              ],
                              ref: "dropdownBoxSurvey",
                              staticClass: "dropdown__menu p-3",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "dropdown__button button button--alternative button--icon u-full text-size-xs size-sm mb-2 white-text dropoption",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addOpenEndedQuestion()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "icon-container",
                                    {
                                      attrs: {
                                        name: "comment",
                                        "view-box": "0 0 20 17",
                                      },
                                    },
                                    [_c("icon-comment")],
                                    1
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("select_comment_question")
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "dropdown__button button button--alternative button--icon u-full text-size-xs size-sm mb-2 white-text dropoption",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addMultiChoiceQuestion()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "icon-container",
                                    {
                                      attrs: {
                                        name: "choice",
                                        "view-box": "0 0 24 23",
                                      },
                                    },
                                    [_c("icon-choice")],
                                    1
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("select_choice_question")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "dropdown__button button button--alternative button--icon u-full text-size-xs size-sm mb-2 white-text dropoption",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addImageQuestion()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "icon-container",
                                    {
                                      attrs: {
                                        name: "images",
                                        "view-box": "0 0 28 28",
                                      },
                                    },
                                    [_c("icon-images")],
                                    1
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("select_image_question")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "dropdown__button button button--alternative button--icon u-full text-size-xs size-sm white-text dropoption",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addRatingQuestion()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "icon-container",
                                    {
                                      attrs: {
                                        name: "rate",
                                        "view-box": "0 0 20 20",
                                      },
                                    },
                                    [_c("icon-rate")],
                                    1
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("select_rate_question")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        ),
        _vm.errors.has("survey.question")
          ? _c("span", { staticClass: "help is-danger" }, [
              _vm._v(_vm._s(_vm.errors.first("survey.question"))),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass: "form-group",
          class: { "is-danger": _vm.errors.has("survey.title") },
        },
        [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "contentTitle" } },
            [_vm._v(_vm._s(_vm.$t("title").toUpperCase()))]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.title,
                expression: "title",
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|max:100",
                expression: "'required|max:100'",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              id: "contentTitle",
              disabled: _vm.disabled && !_vm.canEditOnlyItem,
              "data-vv-scope": "survey",
              "data-vv-name": "title",
              "data-vv-as": _vm.$t("title"),
            },
            domProps: { value: _vm.title },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.title = $event.target.value
                },
                _vm.handleTitle,
              ],
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("survey.title"),
                  expression: "errors.has('survey.title')",
                },
              ],
              staticClass: "help is-danger",
            },
            [_vm._v(_vm._s(_vm.errors.first("survey.title")))]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.items.length > 0,
              expression: "errors.items.length > 0",
            },
          ],
          staticClass: "form-group",
        },
        [
          _c("div", { staticClass: "alert alert--card alert--error" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
            _c(
              "ul",
              _vm._l(_vm.errors, function (error, index) {
                return _c("li", { key: index }, [
                  _vm._v(" " + _vm._s(error.msg) + " "),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "button button--primary",
            attrs: {
              type: "submit",
              disabled: _vm.isPending || (_vm.disabled && !_vm.canEditOnlyItem),
            },
            on: {
              click: function ($event) {
                _vm.editMode
                  ? _vm.isOrderChanged
                    ? _vm.saveOrder()
                    : _vm.updateContent()
                  : _vm.createContent()
              },
            },
          },
          [
            !_vm.isPending
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.editMode
                          ? _vm.isOrderChanged
                            ? _vm.$t("update_order_button")
                            : _vm.$t("update_button")
                          : _vm.$t("create_button")
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.isPending
              ? _c("div", { staticClass: "lds-ellipsis" }, [
                  _c("div"),
                  _c("div"),
                  _c("div"),
                  _c("div"),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }