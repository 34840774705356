var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "is-child-header-left-actions": "",
            "child-header-navigation": _vm.userSubNavigation,
          },
          scopedSlots: _vm._u([
            {
              key: "right",
              fn: function () {
                return [
                  _c(
                    "button",
                    {
                      staticClass:
                        "button button--secondary text-size-xs size-sm button--icon",
                      on: {
                        click: function ($event) {
                          return _vm.addCommunicationModal()
                        },
                      },
                    },
                    [
                      _c(
                        "icon-container",
                        {
                          attrs: {
                            name: "plusfull",
                            "view-box": "0 0 448 512",
                            width: 15,
                            height: 16,
                            color: "#FFF",
                            "is-icon-class": true,
                          },
                        },
                        [_c("icon-plusfull")],
                        1
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.$t("add_new_communication")) + " "
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
          _vm.isVibonsAdmin
            ? _c(
                "dropdown",
                {
                  attrs: {
                    "class-name": "filter",
                    "filter-on": _vm.the_filter.only_active_customer_users,
                  },
                },
                [
                  _c("template", { slot: "icon" }, [
                    _c("i", {
                      staticClass:
                        "fa fa-filter button button--shadow button--type-icon size-sm d-flex align-items-center",
                      staticStyle: {
                        cursor: "pointer",
                        color: "rgb(114, 125, 146)",
                      },
                    }),
                  ]),
                  _c("template", { slot: "body" }, [
                    _c("div", { staticClass: "d-block p-2" }, [
                      _c("div", { staticClass: "d-block" }, [
                        _c(
                          "label",
                          {
                            staticClass: "checkbox",
                            attrs: { for: "filter-only_active_customer_users" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.the_filter.only_active_customer_users,
                                  expression:
                                    "the_filter.only_active_customer_users",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "filter-only_active_customer_users",
                                "true-value": true,
                                "false-value": false,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.the_filter.only_active_customer_users
                                )
                                  ? _vm._i(
                                      _vm.the_filter.only_active_customer_users,
                                      null
                                    ) > -1
                                  : _vm.the_filter.only_active_customer_users,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.the_filter.only_active_customer_users,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.the_filter,
                                          "only_active_customer_users",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.the_filter,
                                          "only_active_customer_users",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.the_filter,
                                      "only_active_customer_users",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("only_active_customer_users")) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.tableIsLoading
          ? _c("div", { staticClass: "lds-dual-ring" })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c("vue-table", {
              ref: "myvuetable",
              class: { "table-fade": _vm.tableIsLoading },
              attrs: {
                "api-url": _vm.apiUrl,
                "http-fetch": _vm.myFetch,
                "api-mode": true,
                "pagination-path": "meta",
                "http-method": "post",
                "append-params": _vm.moreParams,
                "http-options": {
                  headers: {
                    Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                  },
                },
                fields: _vm.fields,
                "no-data-template": _vm.$t("no_data_info"),
                css: _vm.css.table,
                "per-page": _vm.perPage,
                "query-params": _vm.makeQueryParams,
              },
              on: {
                "vuetable:loading": function ($event) {
                  _vm.tableIsLoading = true
                },
                "vuetable:loaded": function ($event) {
                  _vm.tableIsLoading = false
                },
                "vuetable:load-error": _vm.vuetableLoadError,
                "vuetable:pagination-data": _vm.onPaginationData,
              },
              scopedSlots: _vm._u([
                {
                  key: "customer_name_slot",
                  fn: function (props) {
                    return [
                      _vm.isVibonsAdmin
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getCustomerName(
                                    props.rowData.as_customer_id
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "actions",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "ui button edit",
                          on: {
                            click: function ($event) {
                              return _vm.onAction("edit-item", props.rowData)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 20,
                                height: 20,
                                name: "edit",
                                color: "#727d92",
                              },
                            },
                            [_c("icon-edit")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "ui button delete",
                          on: {
                            click: function ($event) {
                              return _vm.onAction("delete-item", props.rowData)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 20,
                                height: 20,
                                name: "trash",
                                color: "#727d92",
                              },
                            },
                            [_c("icon-trash")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown",
                          on: {
                            mouseover: function ($event) {
                              return _vm.dropdownAction(
                                `three${props.rowIndex}`
                              )
                            },
                            mouseleave: _vm.hideDropdownAction,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "card--actions dropdown__toggle" },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    width: 20,
                                    height: 20,
                                    "view-box": "0 0 24 24",
                                    name: "threedots",
                                    color: "#727d92",
                                  },
                                },
                                [_c("icon-threedots")],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.dropdownIndex === "three" + props.rowIndex
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "on-clickaway",
                                      rawName: "v-on-clickaway",
                                      value: _vm.hideDropdownAction,
                                      expression: "hideDropdownAction",
                                    },
                                  ],
                                  ref: `three${props.rowIndex}`,
                                  staticClass: "dropdown__menu",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "show-users",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "ml-n1",
                                        attrs: {
                                          type: "mdi",
                                          width: "21",
                                          color: "#727d92",
                                          height: "23",
                                          path: _vm.getImgUrl("mdiYoutubeTv"),
                                        },
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("user_list")) + " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "download-report",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: "13",
                                            height: "14",
                                            name: "Download Communication Report",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-download")],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("download_report")) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              { staticClass: "pagination__info" },
              [
                _c("vuetable-pagination-info", {
                  ref: "paginationInfo",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "info-template": _vm.$t("pagination_info"),
                    "no-data-template": _vm.$t("no_data_info"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "pagination__items" },
                  [
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }