<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{'is-loading': formSending }"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ $t('login_code_for', {email: userEmail }) }}
            </h5>
            <!-- eslint-disable vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="tab-content">
              <div class="tab-pane">
                <div
                  id="messageInfo"
                  class="text-center"
                />
                <div
                  class="form-group d-flex justify-content-center cursor-pointer"
                  @click="shareClick"
                >
                  <div class="pin-numbers">
                    <span class="pinbox">{{ pinpassword ? pinpassword[0] : 0 }}</span>
                    <span class="pinbox">{{ pinpassword ? pinpassword[1] : 0 }}</span>
                    <span class="dash">{{ "-" }}</span>
                    <span class="pinbox">{{ pinpassword ? pinpassword[2] : 0 }}</span>
                    <span class="pinbox">{{ pinpassword ? pinpassword[3] : 0 }}</span>
                    <span class="pinbox">{{ pinpassword ? pinpassword[4] : 0 }}</span>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary no-wrap"
                    @click="gotoWebApp()"
                    :disabled="!pinpassword"
                  >
                    <span>
                      {{ $t('open_web_app') }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/services/';
import {
  API_USER_PINCODE,
} from '@/helpers/config';

export default {
  name: 'ModalLoginCode',
  inject: ['$validator'],

  data() {
    return {
      isPending: false,
      formSending: false,
      pinpassword: null,
      pathWebApp_Login: null,
    };
  },

  computed: {
    userEmail() {
      return this.$store.state.modal.userEmail;
    },

    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },

  created() {
    this.getPinCode();
  },

  methods: {

    closeModal() {
      this.$store.commit('modal/toggleModal');
    },
    gotoWebApp() {
      const windowReference = window.open();
      windowReference.location = this.pathWebApp_Login;
    },
    async shareClick() {
      const self = this;
      const text = self.pinpassword;
      self.copyText(text);
      const $elem = document.getElementById('messageInfo');
      if ($elem) {
        $elem.innerHTML = self.$t('copied');
        $elem.style.color = '#2EB08E';
        await setTimeout(() => {
          $elem.innerHTML = '';
          $elem.style.color = '#fff';
        }, 2000);
      }
    },

    fallbackCopyText(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log(`Fallback: Copying text command was ${msg}`);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
    },

    copyText(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyText(text);
        return;
      }
      navigator.clipboard.writeText(text).then(() => {
        console.log('Async: Copying to clipboard was successful!');
      }, (err) => {
        console.error('Async: Could not copy text: ', err);
      });
    },

    async getPinCode() {
      const formData = new FormData();
      formData.append('email', this.userEmail);
      await API.post(API_USER_PINCODE, formData)
        .then((response) => {
          this.pinpassword = response.data.Pin;
          this.token = response.data.Token;
          this.pathWebApp_Login = response.data.WebApp_Login;
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

  },
};
</script>

<style lang="scss" scoped>
.form-check {
  .form-label {
    font-size: 0.9rem;
    margin-left: 1rem;
    margin-bottom: 0;
    color: #142b58;
  }
}
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}
.modal-body {
  h4 {
    color: #727d92;
  }
}
.blur {
  filter: blur(3px);
}
.no-wrap {
  white-space: nowrap;
}
  .pin-numbers {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    color: #000000;
    margin: 35px auto;
    align-items: center;
    .pinbox {
      background: #ffffff;
      border: 0.7px solid #d3d4d5;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      margin: 5px;
      padding: 5px;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 35px;
      color: #000000;
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>
