var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      fill: "currentColor",
      d: "M0 113h113V0H0zm30-83h53v53H30zm0 0M149 0v113h363V0zm333 83H179V30h303zm0 0M0 260h113V147H0zm30-83h53v53H30zm0 0M149 260h363V147H149zm30-83h303v53H179zm0 0M0 407h113V294H0zm30-83h53v53H30zm0 0M149 407h363V294H149zm30-83h303v53H179zm0 0",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }