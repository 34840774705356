import axios from 'axios';
import Highcharts from 'highcharts';
import noData from 'highcharts/modules/no-data-to-display';
import { i18n } from '@/helpers/i18n';
import enLang from '@/lang/en.json';
import trLang from '@/lang/tr.json';

const borderRadius = require('highcharts-border-radius');

borderRadius(Highcharts);
noData(Highcharts);

export const DEFAULT_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGES = ['en', 'tr'];

const Translation = {
  get defaultLanguage() {
    return DEFAULT_LANGUAGE;
  },
  get supportedLanguages() {
    return SUPPORTED_LANGUAGES;
  },
  get currentLanguage() {
    return i18n.locale;
  },
  set currentLanguage(lang) {
    i18n.locale = lang;
  },

  setI18nLanguageInServices(lang) {
    Translation.currentLanguage = lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);
    return lang;
  },

  async changeLanguage(lang) {
    if (!Translation.isLangSupported(lang)) {
      return Promise.reject(new Error('Language not supported'));
    }
    if (i18n.locale === lang) {
      return Promise.resolve(lang);
    }// has been loaded prior
    const msgs = await Translation.loadLanguageFile(lang);
    i18n.setLocaleMessage(lang, msgs.default || msgs);
    return Translation.setI18nLanguageInServices(lang);
  },

  loadLanguageFile(lang) {
    this.setHighchartLang(lang);
    if (lang === 'en') {
      return enLang;
    }
    return trLang;
  },

  isLangSupported(lang) {
    return Translation.supportedLanguages.includes(lang);
  },

  setHighchartLang(lang) {
    let seperater = '.';
    const nodata = i18n.t('no_data', lang);
    if (lang === 'en') {
      seperater = ' ';
    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: seperater,
        noData: nodata,
      },
    });
  },

};

export { Translation };
