var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-pane show active" }, [
    _c(
      "div",
      { staticClass: "form-group form-group--cards" },
      _vm._l(_vm.filedownloadItems, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            ref: "formItem",
            refInFor: true,
            staticClass: "check-text-item filedownload-box",
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      attrs: { for: "title" + index },
                    },
                    [_vm._v(_vm._s(_vm.$t("title").toUpperCase()))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.title,
                            expression: "item.title",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate.disable",
                            value: "required|max:300|min:3",
                            expression: "'required|max:300|min:3'",
                            modifiers: { disable: true },
                          },
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-danger": _vm.errors.has(
                            `filedownload.title${index}`
                          ),
                        },
                        attrs: {
                          type: "text",
                          disabled: _vm.disabled,
                          name: "title" + index,
                          placeholder: _vm.$t("type_your_title"),
                          "data-vv-scope": "filedownload",
                          "data-vv-name": `title${index}`,
                          "data-vv-as": _vm.$t("title"),
                        },
                        domProps: { value: item.title },
                        on: {
                          keypress: function ($event) {
                            return _vm.addFiledownloadItem(index + 1)
                          },
                          paste: function ($event) {
                            return _vm.addFiledownloadItem(index + 1)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(item, "title", $event.target.value)
                          },
                        },
                      }),
                    ]
                  ),
                  _vm.errors.has(`filedownload.title${index}`)
                    ? _c("span", { staticClass: "help is-danger" }, [
                        _vm._v(
                          _vm._s(_vm.errors.first(`filedownload.title${index}`))
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-label",
                      attrs: { for: "description" + index },
                    },
                    [_vm._v(_vm._s(_vm.$t("description")))]
                  ),
                  _c("textarea", {
                    directives: [
                      { name: "resizable", rawName: "v-resizable" },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.desc,
                        expression: "item.desc",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate.disable",
                        value: {
                          max: 400,
                        },
                        expression:
                          "{\n                max: 400,\n              }",
                        modifiers: { disable: true },
                      },
                    ],
                    staticClass: "form-control",
                    class: {
                      "is-danger": _vm.errors.has(`filedownload.desc${index}`),
                    },
                    attrs: {
                      rows: "1",
                      disabled: _vm.disabled,
                      placeholder: _vm.$t("type_your_desciption"),
                      "data-vv-scope": "filedownload",
                      "data-vv-name": `desc${index}`,
                      "data-vv-as": _vm.$t("description"),
                    },
                    domProps: { value: item.desc },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(item, "desc", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors.has(`filedownload.desc${index}`)
                    ? _c("span", { staticClass: "help is-danger" }, [
                        _vm._v(
                          _vm._s(_vm.errors.first(`filedownload.desc${index}`))
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("fileupload-container", {
                      staticClass: "card cardfile--upload",
                      class: {
                        "is-danger": _vm.errors.has(
                          `filedownload.file${index}`
                        ),
                      },
                      attrs: {
                        "file-url": item.url,
                        disabled: _vm.disabled,
                        "file-index": index,
                        "is-multiple": false,
                        "vee-rule": "required|size:60000",
                        "vee-scoop-name": "filedownload",
                        "vee-name": `file${index}`,
                        "vee-as": _vm.$t("file"),
                      },
                      on: { update: _vm.fileAdd },
                    }),
                    _vm.errors.has(`filedownload.file${index}`)
                      ? _c("span", { staticClass: "help is-danger" }, [
                          _vm._v(
                            _vm._s(
                              _vm.errors.first(`filedownload.file${index}`)
                            )
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-end align-items-center",
                  },
                  [
                    _vm.filedownloadItems.length > 1
                      ? _c(
                          "button",
                          {
                            attrs: { disabled: _vm.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.removeFiledownloadItem(index)
                              },
                            },
                          },
                          [
                            _c(
                              "icon-container",
                              {
                                attrs: {
                                  name: "trash",
                                  color: "#727d92",
                                  width: 18,
                                  height: 18,
                                  "view-box": "0 0 25 25",
                                },
                              },
                              [_c("icon-trash")],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "form-group",
        class: { "is-danger": _vm.errors.has("filedownload.title") },
      },
      [
        _c(
          "label",
          { staticClass: "form-label", attrs: { for: "contentTitle" } },
          [_vm._v(_vm._s(_vm.$t("title").toUpperCase()))]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title",
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|max:100",
              expression: "'required|max:100'",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            id: "contentTitle",
            disabled: _vm.disabled && !_vm.canEditOnlyItem,
            "data-vv-scope": "filedownload",
            "data-vv-name": "title",
            "data-vv-as": _vm.$t("title"),
          },
          domProps: { value: _vm.title },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.title = $event.target.value
              },
              _vm.handleTitle,
            ],
          },
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("filedownload.title"),
                expression: "errors.has('filedownload.title')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v(_vm._s(_vm.errors.first("filedownload.title")))]
        ),
      ]
    ),
    _c("div", { staticClass: "modal-footer" }, [
      _vm.filedownloadItems.length < 1
        ? _c(
            "button",
            {
              staticClass: "button button--alternative button--icon u-full",
              attrs: { disabled: _vm.disabled, type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.addFiledownloadItem()
                },
              },
            },
            [
              _c(
                "icon-container",
                {
                  staticClass: "icon-svg",
                  attrs: {
                    name: "circleplusfull",
                    "view-box": "0 0 512 512",
                    color: "#fff",
                    "is-icon-class": false,
                  },
                },
                [_c("icon-circleplusfull")],
                1
              ),
              _vm._v(" " + _vm._s(_vm.$t("add_item")) + " "),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.errors.items.length > 0,
            expression: "errors.items.length > 0",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("div", { staticClass: "alert alert--card alert--error" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
          _c(
            "ul",
            _vm._l(_vm.errors, function (error, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(error.msg) + " "),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "button button--primary",
          attrs: {
            type: "button",
            disabled: _vm.isPending || (_vm.disabled && !_vm.canEditOnlyItem),
          },
          on: {
            click: function ($event) {
              _vm.editMode ? _vm.updateContent() : _vm.createContent()
            },
          },
        },
        [
          !_vm.isPending
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.$t("update_button")
                        : _vm.$t("create_button")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.isPending
            ? _c("div", { staticClass: "lds-ellipsis" }, [
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }