var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "child-header-navigation": _vm.userSubNavigation,
            "is-child-header-left-actions": "",
          },
        },
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "manager-user--title" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Managers",
                  },
                },
              },
              [
                _c(
                  "icon-container",
                  {
                    attrs: {
                      width: 40,
                      height: 18,
                      name: "Go Back",
                      color: "#727d92",
                    },
                  },
                  [_c("icon-goback")],
                  1
                ),
              ],
              1
            ),
            _vm._v(" " + _vm._s(_vm.managerName)),
            _c("small", [_vm._v(" " + _vm._s(_vm.$t("employee_development")))]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _vm.tableIsLoading
              ? _c("div", { staticClass: "lds-dual-ring" })
              : _vm._e(),
            _vm.userId
              ? _c(
                  "vue-table",
                  {
                    ref: "myvuetable",
                    class: { "table-fade": _vm.tableIsLoading },
                    attrs: {
                      "api-url": _vm.apiUrl,
                      "http-fetch": _vm.myFetch,
                      "api-mode": true,
                      "pagination-path": "meta",
                      "track-by": "1",
                      "show-pagination": false,
                      "http-method": "post",
                      "append-params": _vm.moreParams,
                      "http-options": {
                        headers: {
                          Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                        },
                      },
                      fields: _vm.fields,
                      "no-data-template": _vm.$t("no_data_info"),
                      css: _vm.css.table,
                      "per-page": _vm.perPage,
                      "query-params": _vm.makeQueryParams,
                    },
                    on: {
                      "vuetable:loading": function ($event) {
                        _vm.tableIsLoading = true
                      },
                      "vuetable:loaded": function ($event) {
                        _vm.tableIsLoading = false
                      },
                      "vuetable:load-error": _vm.vuetableLoadError,
                      "vuetable:pagination-data": _vm.onPaginationData,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "slot_name",
                          fn: function (props) {
                            return [
                              _c("span", { staticClass: "slot_name" }, [
                                _vm._v(" " + _vm._s(props.rowData.name) + " "),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "slot_selected_skill",
                          fn: function (props) {
                            return [
                              _c("span", { staticClass: "slot_skill" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("truncate")(
                                        props.rowData.selected_skill
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "slot_identify_skill",
                          fn: function (props) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "slot_td",
                                  class: _vm.getBoxColor(props.rowData.step_1),
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getBoxText(props.rowData.step_1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "slot_dev_plan",
                          fn: function (props) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "slot_td",
                                  class: _vm.getBoxColor(props.rowData.step_2),
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getBoxText(props.rowData.step_2)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "slot_checkin",
                          fn: function (props) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "slot_td",
                                  class: _vm.getCheckinColor(props.rowData),
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getCheckinText(props.rowData)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "slot_evaluation",
                          fn: function (props) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "slot_td",
                                  class: _vm.getBoxColor(props.rowData.step_4),
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getBoxText(props.rowData.step_4)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "slot_timeline",
                          fn: function (props) {
                            return [
                              _c(
                                "span",
                                { staticClass: "slot_td" },
                                [
                                  _c("timeline-badge", {
                                    attrs: {
                                      "end-date": props.rowData.end_dt,
                                      "start-date":
                                        props.rowData.sprint_start_dt,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "slot_sprints",
                          fn: function (props) {
                            return [
                              _c(
                                "span",
                                { staticClass: "slot_td" },
                                [
                                  _c("sprint-badge", {
                                    attrs: { rate: props.rowData.sprint_count },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3816870366
                    ),
                  },
                  [
                    _c(
                      "template",
                      { slot: "tableHeader" },
                      [
                        [
                          _c("tr", [
                            _c("th", { staticStyle: { width: "15%" } }, [
                              _vm._v(
                                " " + _vm._s(this.$t("employee_name")) + " "
                              ),
                            ]),
                            _c(
                              "th",
                              { staticStyle: { width: "15%" } },
                              [
                                _vm._v(
                                  " " + _vm._s(this.$t("active_goal")) + " "
                                ),
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.top",
                                      value: _vm.$t("info_active_goal"),
                                      expression: "$t('info_active_goal')",
                                      modifiers: { top: true },
                                    },
                                  ],
                                  attrs: {
                                    type: "mdi",
                                    path: _vm.iconInformationOutline,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "th",
                              { staticStyle: { width: "10%" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s("1. " + this.$t("critical_skills")) +
                                    " "
                                ),
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.top",
                                      value: _vm.$t("info_critical_skills"),
                                      expression: "$t('info_critical_skills')",
                                      modifiers: { top: true },
                                    },
                                  ],
                                  attrs: {
                                    type: "mdi",
                                    path: _vm.iconInformationOutline,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "th",
                              { staticStyle: { width: "10%" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s("2. " + this.$t("dev_plan")) +
                                    " "
                                ),
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.top",
                                      value: _vm.$t("info_development_plan"),
                                      expression: "$t('info_development_plan')",
                                      modifiers: { top: true },
                                    },
                                  ],
                                  attrs: {
                                    type: "mdi",
                                    path: _vm.iconInformationOutline,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "th",
                              { staticStyle: { width: "10%" } },
                              [
                                _vm._v(
                                  " " + _vm._s("3. " + this.$t("checkin")) + " "
                                ),
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.top",
                                      value: _vm.$t("info_check_ins"),
                                      expression: "$t('info_check_ins')",
                                      modifiers: { top: true },
                                    },
                                  ],
                                  attrs: {
                                    type: "mdi",
                                    path: _vm.iconInformationOutline,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "th",
                              { staticStyle: { width: "10%" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s("4. " + this.$t("evaluation")) +
                                    " "
                                ),
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.top",
                                      value: _vm.$t("info_evaluation"),
                                      expression: "$t('info_evaluation')",
                                      modifiers: { top: true },
                                    },
                                  ],
                                  attrs: {
                                    type: "mdi",
                                    path: _vm.iconInformationOutline,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "th",
                              { staticStyle: { width: "15%" } },
                              [
                                _vm._v(" " + _vm._s(this.$t("timeline")) + " "),
                                _c("svg-icon", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.top",
                                      value: _vm.$t("info_timeline"),
                                      expression: "$t('info_timeline')",
                                      modifiers: { top: true },
                                    },
                                  ],
                                  attrs: {
                                    type: "mdi",
                                    path: _vm.iconInformationOutline,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("th", { staticStyle: { width: "15%" } }, [
                              _vm._v(" " + _vm._s(this.$t("sprints")) + " "),
                            ]),
                          ]),
                        ],
                      ],
                      2
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "pagination__info" },
              [
                _c("vuetable-pagination-info", {
                  ref: "paginationInfo",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "info-template": _vm.$t("pagination_info"),
                    "no-data-template": _vm.$t("no_data_info"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "pagination__items" },
                  [
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }