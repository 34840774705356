var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M6 0v2h8.5V0H16v2h1c1.6569 0 3 1.34315 3 3v12c0 1.6569-1.3431 3-3 3H3c-1.65685 0-3-1.3431-3-3V5c0-1.65685 1.34315-3 3-3h1.5V0H6zm8.5 3.5V5H16V3.5h1c.8284 0 1.5.67157 1.5 1.5v1.5h-17V5c0-.82843.67157-1.5 1.5-1.5h1.5V5H6V3.5h8.5zM1.5 8v9c0 .8284.67157 1.5 1.5 1.5h14c.8284 0 1.5-.6716 1.5-1.5V8h-17z",
      "clip-rule": "evenodd",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }