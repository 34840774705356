var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("rect", {
      attrs: {
        x: ".5",
        y: ".5",
        width: "74",
        height: "43",
        rx: "5.5",
        stroke: "currentColor",
        "stroke-opacity": ".5",
      },
    }),
    _c("rect", {
      attrs: {
        x: "23.5",
        y: "8.5",
        width: "29.013",
        height: "27.643",
        rx: "3.5",
        stroke: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        d: "M33.002 21.591 28 27.956h20.009L41.339 20l-5.002 5.57-3.335-3.979Z",
        stroke: "currentColor",
      },
    }),
    _c("rect", {
      attrs: {
        x: "33.5",
        y: "16.5",
        width: "4",
        height: "3.772",
        rx: "1.886",
        stroke: "currentColor",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }