var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c(
          "div",
          {
            class: _vm.journeyIsNew
              ? _vm.showPackageCode
                ? "nocalender modal-dialog modal-dialog-scrollable"
                : "modal-dialog modal-dialog-scrollable"
              : "nocalender modal-dialog modal-dialog-scrollable",
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("header", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.showPackageCode
                          ? _vm.$t("journey_package_code")
                          : _vm.$t("create_packet_code")
                      ) +
                      " "
                  ),
                ]),
                _c("button", {
                  staticClass: "close",
                  attrs: { type: "button" },
                  domProps: { innerHTML: _vm._s("&times;") },
                  on: { click: _vm.closeModal },
                }),
              ]),
              _c(
                "div",
                { staticClass: "modal-body", attrs: { id: "newPlacerWrap" } },
                [
                  _c("div", { staticClass: "tab-content" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.showPackageCode,
                            expression: "!showPackageCode",
                          },
                        ],
                        staticClass: "tab-pane",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c(
                              "div",
                              {
                                class: _vm.journeyIsNew
                                  ? "col-4 bglight"
                                  : "col-12 bglight",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row mx-0" },
                                  [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-label",
                                            attrs: { for: "packagecode" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("package_code"))
                                            ),
                                          ]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.packageCode,
                                              expression: "packageCode",
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: {
                                            "is-danger": _vm.errors.has(
                                              "package.packagecode"
                                            ),
                                          },
                                          attrs: {
                                            type: "text",
                                            id: "packagecode",
                                            "data-vv-scope": "package",
                                            "data-vv-name": "packagecode",
                                            "data-vv-as":
                                              _vm.$t("package_code"),
                                          },
                                          domProps: { value: _vm.packageCode },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.packageCode =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        _vm.errors.has("package.packagecode")
                                          ? _c(
                                              "span",
                                              { staticClass: "help is-danger" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "package.packagecode"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-label",
                                              attrs: { for: "groupname" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("group_name"))
                                              ),
                                            ]
                                          ),
                                          _c("multi-select", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            class: {
                                              "is-danger":
                                                _vm.errors.has(
                                                  "package.groupname"
                                                ),
                                            },
                                            attrs: {
                                              "track-by": "id",
                                              label: "text",
                                              "allow-empty": false,
                                              options: _vm.groupOptions,
                                              taggable: true,
                                              "clear-on-select": true,
                                              "select-label":
                                                _vm.$t("click_to_select"),
                                              "deselect-label":
                                                _vm.$t("click_to_deselect"),
                                              "tag-placeholder": _vm.$t(
                                                "group_select_add_new"
                                              ),
                                              placeholder:
                                                _vm.$t("group_select"),
                                              "data-vv-scope": "package",
                                              "data-vv-name": "groupname",
                                              "data-vv-as":
                                                _vm.$t("group_name"),
                                            },
                                            on: { tag: _vm.addTag },
                                            model: {
                                              value: _vm.groupName,
                                              callback: function ($$v) {
                                                _vm.groupName = $$v
                                              },
                                              expression: "groupName",
                                            },
                                          }),
                                          _vm.errors.has("package.groupname")
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "help is-danger",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "package.groupname"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        class: _vm.journeyIsNew
                                          ? "col-3 my-2"
                                          : "col-1 my-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "swicth-checkbox" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.journeyIsNew,
                                                  expression: "journeyIsNew",
                                                },
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                id: "journeyIsNew",
                                                checked: "",
                                                "true-value": 1,
                                                "false-value": 0,
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.journeyIsNew
                                                )
                                                  ? _vm._i(
                                                      _vm.journeyIsNew,
                                                      null
                                                    ) > -1
                                                  : _vm._q(_vm.journeyIsNew, 1),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.journeyIsNew,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked ? 1 : 0
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.journeyIsNew =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.journeyIsNew = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.journeyIsNew = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _c("label", {
                                              staticClass: "swicth-label",
                                              attrs: { for: "journeyIsNew" },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-9 my-2" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "seperate-label",
                                          attrs: { for: "journeyIsNew" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("journey_is_new")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "collapse-transition",
                                      {
                                        attrs: {
                                          duration: 500,
                                          dimension: "height",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.journeyIsNew,
                                                expression: "journeyIsNew",
                                              },
                                            ],
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-12" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "form-label",
                                                        attrs: {
                                                          for: "journeyLanguage",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("begin_date")
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("date-picker", {
                                                      directives: [
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value: "required",
                                                          expression:
                                                            "'required'",
                                                        },
                                                      ],
                                                      class: {
                                                        "is-danger":
                                                          _vm.errors.has(
                                                            "package.beginDate"
                                                          ),
                                                      },
                                                      attrs: {
                                                        "disabled-date":
                                                          _vm.notBeforeToday,
                                                        "value-type": "date",
                                                        "data-vv-scope":
                                                          "package",
                                                        "data-vv-name":
                                                          "beginDate",
                                                        "data-vv-as":
                                                          _vm.$t("begin_date"),
                                                        "first-day-of-week":
                                                          _vm.userLang === "en"
                                                            ? 7
                                                            : 1,
                                                        lang: _vm.userLang,
                                                        format:
                                                          _vm.userLang === "en"
                                                            ? "MM.DD.YYYY"
                                                            : "DD.MM.YYYY",
                                                      },
                                                      on: {
                                                        "update:disabledDate":
                                                          function ($event) {
                                                            _vm.notBeforeToday =
                                                              $event
                                                          },
                                                        "update:disabled-date":
                                                          function ($event) {
                                                            _vm.notBeforeToday =
                                                              $event
                                                          },
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.adjustTime(
                                                            "date"
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.beginActivationDateTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.beginActivationDateTime =
                                                            $$v
                                                        },
                                                        expression:
                                                          "beginActivationDateTime",
                                                      },
                                                    }),
                                                    _vm.errors.has(
                                                      "package.beginDate"
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "help is-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors.first(
                                                                  "package.beginDate"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form form--horizontal",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "panel-title",
                                                      _vm.showAdvanceSettings
                                                        ? "active"
                                                        : "",
                                                    ],
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.showAdvanceSettings =
                                                              !_vm.showAdvanceSettings
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "advanced_tab"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "collapse-transition",
                                                  {
                                                    attrs: {
                                                      duration: 500,
                                                      dimension: "height",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.showAdvanceSettings,
                                                            expression:
                                                              "showAdvanceSettings",
                                                          },
                                                        ],
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "swicth-checkbox",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.sendOnSaturday,
                                                                      expression:
                                                                        "sendOnSaturday",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                    id: "sendOnSaturday",
                                                                    "true-value": 1,
                                                                    "false-value": 0,
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm.sendOnSaturday
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm.sendOnSaturday,
                                                                            null
                                                                          ) > -1
                                                                        : _vm._q(
                                                                            _vm.sendOnSaturday,
                                                                            1
                                                                          ),
                                                                  },
                                                                  on: {
                                                                    change: [
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm.sendOnSaturday,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? 1
                                                                              : 0
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              (_vm.sendOnSaturday =
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                ))
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              (_vm.sendOnSaturday =
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  ))
                                                                          }
                                                                        } else {
                                                                          _vm.sendOnSaturday =
                                                                            $$c
                                                                        }
                                                                      },
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.journeyNotificationSelect =
                                                                          "default"
                                                                      },
                                                                    ],
                                                                  },
                                                                }),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "swicth-label",
                                                                    attrs: {
                                                                      for: "sendOnSaturday",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "send_onsaturday"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "swicth-checkbox",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.sendOnSunday,
                                                                      expression:
                                                                        "sendOnSunday",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                    id: "sendOnSunday",
                                                                    "true-value": 1,
                                                                    "false-value": 0,
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm.sendOnSunday
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm.sendOnSunday,
                                                                            null
                                                                          ) > -1
                                                                        : _vm._q(
                                                                            _vm.sendOnSunday,
                                                                            1
                                                                          ),
                                                                  },
                                                                  on: {
                                                                    change: [
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm.sendOnSunday,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? 1
                                                                              : 0
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              (_vm.sendOnSunday =
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                ))
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              (_vm.sendOnSunday =
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  ))
                                                                          }
                                                                        } else {
                                                                          _vm.sendOnSunday =
                                                                            $$c
                                                                        }
                                                                      },
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.journeyNotificationSelect =
                                                                          "default"
                                                                      },
                                                                    ],
                                                                  },
                                                                }),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "swicth-label",
                                                                    attrs: {
                                                                      for: "sendOnSunday",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "send_onsunday"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                ref: "takvim",
                                                                staticClass:
                                                                  "form-group",
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "form-label",
                                                                    attrs: {
                                                                      for: "excludedDays",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "excluded_days"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "flat-pickr",
                                                                  {
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      config:
                                                                        _vm.excludedDaysConfig,
                                                                      placeholder:
                                                                        _vm.$t(
                                                                          "select_dates"
                                                                        ),
                                                                      name: "excludedDays",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.excludedDaysAsString,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.excludedDaysAsString =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "excludedDaysAsString",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "swicth-checkbox",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.isNotificationDisabled,
                                                                      expression:
                                                                        "isNotificationDisabled",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                    id: "isNotify",
                                                                    "true-value": 1,
                                                                    "false-value": 0,
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm.isNotificationDisabled
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm.isNotificationDisabled,
                                                                            null
                                                                          ) > -1
                                                                        : _vm._q(
                                                                            _vm.isNotificationDisabled,
                                                                            1
                                                                          ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm.isNotificationDisabled,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? 1
                                                                              : 0
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              (_vm.isNotificationDisabled =
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                ))
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              (_vm.isNotificationDisabled =
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  ))
                                                                          }
                                                                        } else {
                                                                          _vm.isNotificationDisabled =
                                                                            $$c
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "swicth-label",
                                                                    attrs: {
                                                                      for: "isNotify",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "is_notification_enabled"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "col-12 my-2" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c("div", { staticClass: "col-auto" }, [
                                      _c(
                                        "div",
                                        { staticClass: "swicth-checkbox" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.hasReminder,
                                                expression: "hasReminder",
                                              },
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              name: "hasReminder",
                                              id: "hasReminder",
                                              checked: "",
                                              "true-value": true,
                                              "false-value": false,
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.hasReminder
                                              )
                                                ? _vm._i(
                                                    _vm.hasReminder,
                                                    null
                                                  ) > -1
                                                : _vm.hasReminder,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a = _vm.hasReminder,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.hasReminder =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.hasReminder = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.hasReminder = $$c
                                                }
                                              },
                                            },
                                          }),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "swicth-label",
                                              attrs: { for: "hasReminder" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("hasReminder")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "collapse-transition",
                                        {
                                          attrs: {
                                            duration: 500,
                                            dimension: "height",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.hasReminder,
                                                  expression: "hasReminder",
                                                },
                                              ],
                                              staticClass: "container mt-5",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group row",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-3 form-label",
                                                          attrs: {
                                                            for: "employee_title",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "employee_title"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-9",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.employee_title,
                                                                expression:
                                                                  "employee_title",
                                                              },
                                                              {
                                                                name: "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  _vm.hasReminder
                                                                    ? "required|max:40"
                                                                    : "",
                                                                expression:
                                                                  "hasReminder ? 'required|max:40':''",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            class: {
                                                              "is-danger":
                                                                _vm.errors.has(
                                                                  "journey.employee_title"
                                                                ),
                                                            },
                                                            attrs: {
                                                              type: "text",
                                                              id: "employee_title",
                                                              "data-vv-name":
                                                                "employee_title",
                                                              "data-vv-scope":
                                                                "journey",
                                                              "data-vv-as":
                                                                _vm.$t(
                                                                  "employee_title"
                                                                ),
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.employee_title,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.employee_title =
                                                                  $event.target.value
                                                              },
                                                            },
                                                          }),
                                                          _vm.errors.has(
                                                            "journey.employee_title"
                                                          )
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "help is-danger",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "journey.employee_title"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group row",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-3 form-label",
                                                          attrs: {
                                                            for: "employee_content",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "employee_content"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-9",
                                                        },
                                                        [
                                                          _c("textarea", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.employee_content,
                                                                expression:
                                                                  "employee_content",
                                                              },
                                                              {
                                                                name: "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  _vm.hasReminder
                                                                    ? "required|max:160"
                                                                    : "",
                                                                expression:
                                                                  "hasReminder ? 'required|max:160':''",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            class: {
                                                              "is-danger":
                                                                _vm.errors.has(
                                                                  "journey.employee_content"
                                                                ),
                                                            },
                                                            attrs: {
                                                              id: "employee_content",
                                                              "data-vv-name":
                                                                "employee_content",
                                                              "data-vv-scope":
                                                                "journey",
                                                              "data-vv-as":
                                                                _vm.$t(
                                                                  "employee_content"
                                                                ),
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.employee_content,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.employee_content =
                                                                  $event.target.value
                                                              },
                                                            },
                                                          }),
                                                          _vm.errors.has(
                                                            "journey.employee_content"
                                                          )
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "help is-danger",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "journey.employee_content"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group row",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-3 form-label",
                                                          attrs: {
                                                            for: "weeks_after_last_content",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "weeks_after_last_content"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-7",
                                                        },
                                                        [
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.weeks_after_last_content,
                                                                  expression:
                                                                    "weeks_after_last_content",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "selectbox-o",
                                                              attrs: {
                                                                id: "weeks_after_last_content",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.weeks_after_last_content =
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              [
                                                                1, 2, 3, 4, 5,
                                                                6, 7, 8, 9,
                                                              ],
                                                              function (n) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: n,
                                                                    domProps: {
                                                                      value: n,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          n
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "form-label mx-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("week")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("svg-icon", {
                                                            directives: [
                                                              {
                                                                name: "tooltip",
                                                                rawName:
                                                                  "v-tooltip.top",
                                                                value: _vm.$t(
                                                                  "weeks_after_last_content_info"
                                                                ),
                                                                expression:
                                                                  "$t('weeks_after_last_content_info')",
                                                                modifiers: {
                                                                  top: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-label",
                                                            attrs: {
                                                              type: "mdi",
                                                              path: _vm.iconInformationOutline,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group row",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-3 form-label",
                                                          attrs: {
                                                            for: "week_span",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "week_span"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-7",
                                                        },
                                                        [
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.week_span,
                                                                  expression:
                                                                    "week_span",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "selectbox-o",
                                                              attrs: {
                                                                id: "week_span",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.week_span =
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              [1, 2, 3, 4],
                                                              function (n) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: n,
                                                                    domProps: {
                                                                      value: n,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          n
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "form-label mx-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("week")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("svg-icon", {
                                                            directives: [
                                                              {
                                                                name: "tooltip",
                                                                rawName:
                                                                  "v-tooltip.top",
                                                                value:
                                                                  _vm.$t(
                                                                    "week_span_info"
                                                                  ),
                                                                expression:
                                                                  "$t('week_span_info')",
                                                                modifiers: {
                                                                  top: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-label",
                                                            attrs: {
                                                              type: "mdi",
                                                              path: _vm.iconInformationOutline,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group row",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "col-3 form-label",
                                                          attrs: {
                                                            for: "day_of_week",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "day_of_week"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-md-7",
                                                        },
                                                        [
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.day_of_week,
                                                                  expression:
                                                                    "day_of_week",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "selectbox-o",
                                                              attrs: {
                                                                id: "day_of_week",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.day_of_week =
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              [
                                                                1, 2, 3, 4, 5,
                                                                6, 7,
                                                              ],
                                                              function (n) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: n,
                                                                    domProps: {
                                                                      value: n,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$helpers.getWeekDay(
                                                                            n
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "schedule-next" }, [
                                  _c("div", { staticClass: "modal-footer" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "button button--primary",
                                        attrs: {
                                          type: "button",
                                          disabled: _vm.isPending,
                                        },
                                        on: { click: _vm.doAction },
                                      },
                                      [
                                        !_vm.isPending
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("create_button")
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.isPending
                                          ? _c(
                                              "div",
                                              { staticClass: "lds-ellipsis" },
                                              [
                                                _c("div"),
                                                _c("div"),
                                                _c("div"),
                                                _c("div"),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "collapse-transition",
                              { attrs: { duration: 300, dimension: "width" } },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.journeyIsNew,
                                        expression: "journeyIsNew",
                                      },
                                    ],
                                    staticClass: "col-8",
                                  },
                                  [
                                    _c("full-calendar", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.journeyIsNew,
                                          expression: "journeyIsNew",
                                        },
                                      ],
                                      attrs: {
                                        "user-lang": _vm.userLang,
                                        "calender-events": _vm.calenderEvents,
                                        "preview-first-date":
                                          _vm.previewFirstDate,
                                        "total-event-days": _vm.totalEventDays,
                                      },
                                      on: {
                                        setDateFromDrop: _vm.setDateFromDrop,
                                        isDragging: _vm.setDragging,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showPackageCode,
                            expression: "showPackageCode",
                          },
                        ],
                        staticClass: "tab-pane",
                      },
                      [
                        _c("div", { staticClass: "row package-box" }, [
                          _c("div", { staticClass: "col-9" }, [
                            _c("div", { staticClass: "show-packagecode" }, [
                              _c("span", [_vm._v(_vm._s(_vm.packageCode))]),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "button button--primary packagebtn",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.shareClick($event)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("copy")) + " ")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _c(
          "collapse-transition",
          { attrs: { duration: 500, dimension: "height" } },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isDragging,
                    expression: "isDragging",
                  },
                ],
                staticClass: "hint",
              },
              [
                _c("div", { staticClass: "img" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/arrowkeys.png"),
                      alt: "keyboard",
                    },
                  }),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("keyboard_hint")) + " "),
                ]),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }