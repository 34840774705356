var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      fill: "currentColor",
      d: "M.667 33.168V2.5a2.5 2.5 0 1 1 5 0v30.668a2.5 2.5 0 1 1-5 0zm7.334 0V2.5a2.5 2.5 0 1 1 5 0v30.668a2.5 2.5 0 1 1-5 0zm7.334 0V2.5a2.5 2.5 0 1 1 5 0v30.668a2.5 2.5 0 1 1-5 0zm7.332 0V2.5a2.5 2.5 0 1 1 5 0v30.668a2.5 2.5 0 1 1-5 0zm7.334 0V2.5a2.5 2.5 0 1 1 5 0v30.668a2.5 2.5 0 1 1-5 0z",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }