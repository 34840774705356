var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: _vm.isIconClass ? "icon" : "",
      style: _vm.rotate
        ? `transform: rotate(${_vm.rotate}deg);margin: 4px 0 0 -10px;`
        : "",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        version: "1.2",
        role: "presentation",
        preserveAspectRatio: "xMidYMid meet",
        color: _vm.color,
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.myviewBox,
        "aria-labelledby": _vm.name,
        "data-drop-effect": "none",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }