import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from './modules/auth';
import modal from './modules/modal';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    modal,
  },
  plugins: [
    createPersistedState({
      key: process.env.VUE_APP_STORE_PREFIX,
      paths: ['auth'],
    }),
  ],
});
