<template>
  <div class="page">
    <app-header
      v-if="!isSubComponentMode"
      child-header-class="container"
      :child-header-navigation="childHeaderNavigation"
      is-child-header-left-actions
      add-journey-user
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>
    <template
      v-else
    >
      <div class="top-group">
        <div>
          <filter-perpage
            @searchFor="setSearchFor"
            @perPage="setPerPage"
          />
        </div>
        <div>
          <button
            class="button button--secondary text-size-xs size-sm button--icon"
            v-if="isSubComponentMode"
            @click="addJourneyUserModal()"
          >
            <icon-container
              name="plusfull"
              view-box="0 0 448 512"
              :width="15"
              :height="16"
              color="#FFF"
              class="mr-2"
              :is-icon-class="false"
            >
              <icon-plusfull />
            </icon-container>
            {{ $t('add_new_user') }}
          </button>
        </div>
      </div>
    </template>

    <div class="container">
      <div
        v-if="!isSubComponentMode"
        class="journey-users--title"
      >
        <button
          @click="goBack()"
        >
          <icon-container
            :width="40"
            :height="18"
            name="Go Back"
            color="#727d92"
          >
            <icon-goback />
          </icon-container>
        </button>{{ journeyName }}<small>{{ $t('user_list') }}</small>
      </div>
      <div class="table-responsive">
        <div
          v-if="tableIsLoading"
          class="lds-dual-ring"
        />
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="get"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="icon"
            slot-scope="props"
          >
            <button
              class="ui button"
              @click="getMobilStatus(props.rowData.id, `ref${props.rowData.id}`)"
              @blur="closeTooltip(`ref${props.rowData.id}`)"
            >
              <icon-container
                :width="13"
                :height="20"
                name="cellphone"
                view-box="0 0 320 512"
                :class="{'disabled': !props.rowData.has_mobile_device_regitered}"
                :color="props.rowData.has_mobile_device_regitered ? 'rgb(8, 206, 25)':'#727d92'"
              >
                <icon-cellphone />
              </icon-container>
            </button>
            <div
              :ref="`ref${props.rowData.id}`"
              class="content-tooltip"
            >
              <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
              <pre>{{ tooltipText ? tooltipText : '⏳' }}</pre>
            </div>
          </template>
          <template
            slot="email"
            slot-scope="props"
          >
            <div
              class="d-inline-block text-truncate"
              style="max-width: 180px;"
              :title="props.rowData.email"
            >
              {{ props.rowData.email }}
            </div>
          </template>
          <template
            slot="user_completion_rate"
            slot-scope="props"
          >
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :style="props.rowData.user_completion_rate === 0 ? 'width:' + props.rowData.user_completion_rate + '%;background-color:transparent;color:#000' : 'width:' + props.rowData.user_completion_rate + '%;color:#fff'"
                :aria-valuenow="props.rowData.user_completion_rate"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ '‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎' + parseFloat(props.rowData.user_completion_rate).toFixed(0) + '%' }}
              </div>
            </div>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <button
              class="ui button"
              @click="onAction('edit-item', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                view-box="0 0 24 24"
                name="edit"
                color="#727d92"
              >
                <icon-edit />
              </icon-container>
            </button>
            <button
              class="ui button"
              @click="onAction('user-detail', props.rowData)"
            >
              <icon-container
                name="search"
                :width="20"
                :height="20"
                color="#727d92"
                view-box="0 0 15 15"
                :is-icon-class="false"
              >
                <icon-search />
              </icon-container>
            </button>
            <button
              class="ui button delete"
              @click="onAction('delete-item', props.rowData, props.rowIndex)"
            >
              <icon-container
                :width="20"
                :height="20"
                view-box="0 0 24 24"
                name="trash"
                color="#727d92"
              >
                <icon-trash />
              </icon-container>
            </button>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import IconContainer from '@/components/elements/Icon.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconGoBack from '@/components/icons/GoBack.vue';
import IconCellPhone from '@/components/icons/CellPhone.vue';
import IconPlusFull from '@/components/icons/PlusFull.vue';
import IconSearch from '@/components/icons/Search.vue';
import IconEdit from '@/components/icons/Edit.vue';
import AppHeader from '@/components/AppHeader.vue';
import {
  API_JOURNEY_USER,
  API_JOURNEY_USER_DATATABLES,
} from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import API from '@/services/';
import VuetablePagination from '../components/CustomVuetable/VuetablePagination.vue';

console.log('JourneyUsers');

export default {
  name: 'JourneyUsers',
  mixins: [myTableMixin],
  props: {
    isSubComponentMode: {
      type: Boolean,
      default: false,
    },
    journeyCustomerId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {

    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-goback': IconGoBack,
    'icon-plusfull': IconPlusFull,
    'icon-cellphone': IconCellPhone,
    'icon-edit': IconEdit,
    'icon-search': IconSearch,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  computed: {

    apiUrl() {
      if (API_JOURNEY_USER_DATATABLES && this.$route && this.$route.params && this.$route.params.journeyId) {
        return `${API_JOURNEY_USER_DATATABLES}/${this.$route.params.journeyId}`;
      }
      return `${API_JOURNEY_USER_DATATABLES}`;
    },

    refreshPage: {
      get() {
        return this.$store.state.modal.refreshPage;
      },
      set(newValue) {
        this.$store.state.modal.refreshPage = newValue;
      },
    },

    pageToRefresh: {
      get() {
        return this.$store.state.modal.pageToRefresh;
      },

      set(newValue) {
        this.$store.state.modal.pageToRefresh = newValue;
      },
    },

    childHeaderNavigation() {
      return [
        {
          text: this.$t('catalog'),
          url: '/',
        },
      ];
    },

    fields() {
      return [
        {
          name: 'icon',
          title: '',
          width: '35px',
        },
        {
          name: 'name',
          title: this.$t('name'),
          width: '25%',
        },
        {
          name: 'email',
          title: this.$t('email'),
          width: '35%',
        },
        {
          name: 'user_completion_rate',
          title: this.$t('completion_rate'),
          sortField: 'completion_rate',
          width: '20%',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '15%',
        },
      ];
    },

    journeyName() {
      if (this.$route.params && this.$route.params.title) {
        return this.$route.params.title;
      }
      return '';
    },
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'JourneyUsers') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
          this.$store.state.modal.launchedFrom = null;
        }, 500);
      }
    },
  },
  mounted() {
    if (this.journeyCustomerId) {
      this.moreParams.customer_id = this.journeyCustomerId;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    addJourneyUserModal() {
      const { journeyId } = this.$route.params;
      const { journeyCustomerId } = this;
      if (this.isSubComponentMode) {
        this.$store.dispatch('modal/SAVE_PREVIOUS_STATE');
      }
      const obj = {
        modalType: 'modalJourneyAddUserOverlay',
        journeyId,
        journeyCustomerId,
        overlayModal: true,
        pageToRefresh: 'JourneyUsers',
      };
      this.$helpers.toggleModal(obj);
    },

    async onAction(action, data) {
      const userId = data.id;
      this.idHolder = userId;
      this.apiUrlHolder = API_JOURNEY_USER;

      if (action === 'delete-item') {
        if (!this.snotifyQueueList.includes(userId)) {
          this.snotifyQueueList.push(userId);
          this.$snotify.confirm(this.$t('journey_user_delete_msg_body', { name: data.name }), this.$t('journey_user_delete_msg_title'), {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            buttons: [
              {
                text: this.$t('yes'),
                action: (toast) => {
                  this.deleteConfirm(toast, this.idHolder, this.apiUrlHolder);
                },
                bold: false,
              },
              {
                text: this.$t('no'),
                action: (toast) => {
                  this.deleteCancel(toast, this.idHolder);
                },
              },
            ],
          }).on('shown', (toast) => {
            this.toastHolder = toast;
            document.addEventListener('keydown', this.eventHandler, false);
          }).on('hidden', (toast) => {
            this.toastHolder = toast;
            document.removeEventListener('keydown', this.eventHandler, false);
          });
        }
      }
      if (action === 'edit-item') {
        if (data.id) {
          if (this.isSubComponentMode) {
            this.$store.dispatch('modal/SAVE_PREVIOUS_STATE');
          }
          const obj = {
            modalType: 'modalAddUser',
            userId: data.id,
            tabPane: 'mainTab',
            launchedFrom: 'JourneyUsers',
            editMode: true,
          };
          this.$helpers.toggleModal(obj);
        }
      }
      if (action === 'user-detail') {
        if (userId) {
          if (this.isSubComponentMode) {
            this.$emit('closeModal');
          }
          this.$store.commit('modal/SET_MODAL_DATA', data);
          this.$router.push({ path: `/dashboard/users/detail/${userId}` });
        }
      }
    },
    deleteConfirm(toast, itemId, apiUrl) {
      this.$snotify.remove(toast.id);
      const snotifyIndex = this.snotifyQueueList.indexOf(itemId);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      const { journeyId } = this.$route.params;
      this.tableIsLoading = true;
      API.delete(`${apiUrl}`, {
        data: {
          journey_id: journeyId,
          user_id: itemId,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.$snotify.success(response.data.message);
            this.$refs.myvuetable.refresh();
            setTimeout(() => {
              this.tableIsLoading = false;
            }, 400);
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .journey-users--title {
    display: inline-flex;
    font-size: 26px;
    color: #727D92;
    align-items: center;
    font-weight: 500;
    small {
      font-size: 16px;
      margin-top: 5px;
      margin-left: 10px;
    }
    a {
      margin-right: 10px;
      display: inline-block;
      svg {
        transition: transform .2s ease-in;
      }
      &:hover {
        svg {
          transform: translateX(-5px);
        }
      }
    }
  }

  .disabled {
    opacity:0.5;
  }
  .top-group {
    display: flex;
    justify-content: space-between;
  }

</style>
