var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page mb-4 pb-4" },
    [
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "child-header-navigation": _vm.userSubNavigation,
            "is-child-header-left-actions": "",
          },
        },
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.tableIsLoading
          ? _c("div", { staticClass: "lds-dual-ring" })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c("vue-table", {
              ref: "myvuetable",
              class: { "table-fade": _vm.tableIsLoading },
              attrs: {
                "api-url": _vm.apiUrl,
                "http-fetch": _vm.myFetch,
                "api-mode": true,
                "pagination-path": "meta",
                "http-method": "get",
                "append-params": _vm.moreParams,
                "http-options": {
                  headers: {
                    Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                  },
                },
                fields: _vm.fields,
                "no-data-template": _vm.$t("no_data_info"),
                css: _vm.css.table,
                "per-page": _vm.perPage,
                "query-params": _vm.makeQueryParams,
              },
              on: {
                "vuetable:loading": function ($event) {
                  _vm.tableIsLoading = true
                },
                "vuetable:loaded": function ($event) {
                  _vm.tableIsLoading = false
                },
                "vuetable:load-error": _vm.vuetableLoadError,
                "vuetable:pagination-data": _vm.onPaginationData,
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "ui button",
                          on: {
                            click: function ($event) {
                              return _vm.getMobilStatus(
                                props.rowData.id,
                                `ref${props.rowData.id}`
                              )
                            },
                            blur: function ($event) {
                              return _vm.closeTooltip(`ref${props.rowData.id}`)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              class: {
                                disabled:
                                  !props.rowData.has_mobile_device_registered,
                              },
                              attrs: {
                                width: 13,
                                height: 20,
                                name: "cellphone",
                                "view-box": "0 0 320 512",
                                color: props.rowData
                                  .has_mobile_device_registered
                                  ? "rgb(8, 206, 25)"
                                  : "#727d92",
                              },
                            },
                            [_c("icon-cellphone")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          ref: `ref${props.rowData.id}`,
                          staticClass: "content-tooltip",
                        },
                        [
                          _c("pre", [
                            _vm._v(
                              _vm._s(_vm.tooltipText ? _vm.tooltipText : "⏳")
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "completion_rate",
                  fn: function (props) {
                    return [
                      _c("div", { staticClass: "progress" }, [
                        _c(
                          "div",
                          {
                            staticClass: "progress-bar",
                            style:
                              props.rowData.completion_rate_live === 0
                                ? "width:" +
                                  props.rowData.completion_rate_live +
                                  "%;background-color:transparent;color:#000"
                                : "width:" +
                                  props.rowData.completion_rate_live +
                                  "%;color:#fff",
                            attrs: {
                              role: "progressbar",
                              "aria-valuenow":
                                props.rowData.completion_rate_live,
                              "aria-valuemin": "0",
                              "aria-valuemax": "100",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  "‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎" +
                                    props.rowData.completion_rate_live +
                                    "%"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "actions",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "ui button",
                          on: {
                            click: function ($event) {
                              return _vm.onAction("user-detail", props.rowData)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                name: "search",
                                width: 14,
                                height: 14,
                                color: "#727d92",
                                "view-box": "0 0 14 14",
                                "is-icon-class": false,
                              },
                            },
                            [_c("icon-search")],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              { staticClass: "pagination__info" },
              [
                _c("vuetable-pagination-info", {
                  ref: "paginationInfo",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "info-template": _vm.$t("pagination_info"),
                    "no-data-template": _vm.$t("no_data_info"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "pagination__items" },
                  [
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }