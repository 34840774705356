var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class":
              _vm.currentViewMode === "card" ? "container-fluid" : "container",
            "is-child-header-left-actions": "",
            "add-new-content-status": "",
            "justify-between-class": "",
          },
          scopedSlots: _vm._u([
            {
              key: "right",
              fn: function () {
                return [
                  _c("div", { staticClass: "d-flex flex-row-reverse" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "table-mode-type d-flex align-items-center",
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "m-0" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentViewMode,
                                  expression: "currentViewMode",
                                },
                              ],
                              attrs: { type: "radio", value: "card" },
                              domProps: {
                                checked: _vm._q(_vm.currentViewMode, "card"),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.currentViewMode = "card"
                                },
                              },
                            }),
                            _c(
                              "icon-container",
                              {
                                attrs: {
                                  width: 40,
                                  height: 40,
                                  "view-box": "0 0 512 512",
                                  name: "card",
                                  color: "#727D92",
                                },
                              },
                              [_c("icon-card")],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "label",
                          { staticClass: "m-0" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.currentViewMode,
                                  expression: "currentViewMode",
                                },
                              ],
                              attrs: { type: "radio", value: "list" },
                              domProps: {
                                checked: _vm._q(_vm.currentViewMode, "list"),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.currentViewMode = "list"
                                },
                              },
                            }),
                            _c(
                              "icon-container",
                              {
                                attrs: {
                                  width: 40,
                                  height: 40,
                                  "view-box": "0 0 512 512",
                                  name: "list",
                                  color: "#727D92",
                                },
                              },
                              [_c("icon-list")],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("div", { staticClass: "button-group" }, [
                _c("div", { staticClass: "button-group__item dropdown" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "button button--shadow button--type-icon size-sm d-flex align-items-center",
                      class: { active: _vm.isDropdown === "perPageDropdown" },
                      on: {
                        click: function ($event) {
                          return _vm.dropdownAction("perPageDropdown")
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "25",
                            height: "18",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("g", [
                            _c("path", {
                              attrs: {
                                stroke: "#ffffff",
                                fill: "#ffffff",
                                id: "svg_8",
                                d: "m20.86045,13.91667c0,0.28222 -0.37434,0.51104 -0.83604,0.51104l-15.04882,0c-0.4617,0 -0.83604,-0.22882 -0.83604,-0.51104l0,0c0,-0.28223 0.37434,-0.51105 0.83604,-0.51105l15.04882,0c0.4617,0 0.83604,0.22882 0.83604,0.51105l0,0z",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                stroke: "#ffffff",
                                fill: "#ffffff",
                                id: "svg_10",
                                d: "m20.86045,4.58334c0,0.28223 -0.37434,0.51105 -0.83604,0.51105l-15.04882,0c-0.4617,0 -0.83604,-0.22882 -0.83604,-0.51105l0,0c0,-0.28223 0.37434,-0.51105 0.83604,-0.51105l15.04882,0c0.4617,0 0.83604,0.22882 0.83604,0.51105l0,0z",
                              },
                            }),
                            _c("path", {
                              attrs: {
                                stroke: "#ffffff",
                                fill: "#ffffff",
                                id: "svg_11",
                                d: "m20.86045,9.41667c0,0.28223 -0.37434,0.51105 -0.83604,0.51105l-15.04882,0c-0.4617,0 -0.83604,-0.22882 -0.83604,-0.51105l0,0c0,-0.28223 0.37434,-0.51104 0.83604,-0.51104l15.04882,0c0.4617,0 0.83604,0.22881 0.83604,0.51104l0,0z",
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm.isDropdown === "perPageDropdown"
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "on-clickaway",
                              rawName: "v-on-clickaway",
                              value: _vm.hideDropdown,
                              expression: "hideDropdown",
                            },
                          ],
                          staticClass:
                            "dropdown__menu left dropdown__menu--per-page",
                        },
                        _vm._l(_vm.pageOptions, function (option) {
                          return _c(
                            "div",
                            {
                              key: option.value,
                              class: [
                                _vm.perPage === option.value
                                  ? "dropdown__menu-item active"
                                  : "dropdown__menu-item",
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.pageOptionsSelected(option.value)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(option.text) + " ")]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "button-group__item" }, [
                  _c("div", { staticClass: "search-box" }, [
                    _vm.filterText
                      ? _c(
                          "span",
                          {
                            staticClass: "icon icon-reset",
                            on: { click: _vm.resetFilter },
                          },
                          [
                            _c(
                              "icon-container",
                              {
                                attrs: {
                                  name: "reset",
                                  "view-box": "0 0 20 20",
                                },
                              },
                              [_c("icon-reset")],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        staticClass: "icon-search",
                        on: {
                          click: function ($event) {
                            return _vm.doFilter()
                          },
                        },
                      },
                      [
                        _c(
                          "icon-container",
                          {
                            attrs: {
                              name: "search",
                              width: 14,
                              height: 14,
                              color: "#727d92",
                              "view-box": "0 0 14 14",
                            },
                          },
                          [_c("icon-search")],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("input", {
                      directives: [
                        { name: "sanitize", rawName: "v-sanitize" },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterText,
                          expression: "filterText",
                        },
                      ],
                      ref: "searchBox",
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("search_placeholder"),
                      },
                      domProps: { value: _vm.filterText },
                      on: {
                        keydown: function ($event) {
                          return _vm.doFilter()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.filterText = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "button-group__item dropdown" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "button button--shadow button--type-icon size-sm d-flex align-items-center",
                      class: { "type-active": _vm.subType },
                      on: {
                        click: function ($event) {
                          return _vm.dropdownAction("typeDropdown")
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "16",
                            viewBox: "0 0 20 16",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d: "M9.60074 3.22443C9.11201 3.22443 8.71692 2.82843 8.71692 2.34061V1.0657C8.71692 0.577703 9.11201 0.181885 9.60074 0.181885C10.0895 0.181885 10.4846 0.577703 10.4846 1.0657V2.34061C10.4846 2.82843 10.0895 3.22443 9.60074 3.22443ZM9.60074 15.7233C9.11201 15.7233 8.71692 15.3273 8.71692 14.8393V6.3697C8.71692 5.8817 9.11201 5.48588 9.60074 5.48588C10.0895 5.48588 10.4846 5.8817 10.4846 6.3697V14.8393C10.4846 15.3273 10.0895 15.7233 9.60074 15.7233Z",
                              fill: "white",
                            },
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d: "M2.89957 10.4193C2.41084 10.4193 2.01575 10.0235 2.01575 9.53552V1.0657C2.01575 0.577885 2.41084 0.181885 2.89957 0.181885C3.38829 0.181885 3.78338 0.577885 3.78338 1.0657V9.53552C3.78338 10.0235 3.38829 10.4193 2.89957 10.4193ZM2.89957 15.7233C2.41084 15.7233 2.01575 15.3273 2.01575 14.8395V13.5646C2.01575 13.0766 2.41084 12.6808 2.89957 12.6808C3.38829 12.6808 3.78338 13.0766 3.78338 13.5646V14.8395C3.78338 15.3273 3.38829 15.7233 2.89957 15.7233Z",
                              fill: "white",
                            },
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d: "M16.3022 9.35061C15.8134 9.35061 15.4183 8.95479 15.4183 8.46679V1.0657C15.4183 0.577885 15.8134 0.181885 16.3022 0.181885C16.7909 0.181885 17.186 0.577885 17.186 1.0657V8.46679C17.186 8.95479 16.7909 9.35061 16.3022 9.35061ZM16.3022 15.7233C15.8134 15.7233 15.4183 15.3273 15.4183 14.8393V12.537C15.4183 12.049 15.8134 11.6532 16.3022 11.6532C16.7909 11.6532 17.186 12.049 17.186 12.537V14.8393C17.186 15.3273 16.7909 15.7233 16.3022 15.7233Z",
                              fill: "white",
                            },
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d: "M2.89958 10.4194C2.27485 10.4194 1.76776 10.9265 1.76776 11.5505C1.76776 12.1736 2.27485 12.6809 2.89958 12.6809C3.52285 12.6809 4.02994 12.1736 4.02994 11.5505C4.02994 10.9265 3.52285 10.4194 2.89958 10.4194ZM2.89958 14.4485C1.3003 14.4485 0.00012207 13.1482 0.00012207 11.5505C0.00012207 9.95197 1.3003 8.65161 2.89958 8.65161C4.49721 8.65161 5.79758 9.95197 5.79758 11.5505C5.79758 13.1482 4.49721 14.4485 2.89958 14.4485Z",
                              fill: "white",
                            },
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d: "M16.3021 9.35058C15.6788 9.35058 15.1717 9.85767 15.1717 10.4809C15.1717 11.1382 15.669 11.6531 16.3021 11.6531C16.9366 11.6531 17.4339 11.1382 17.4339 10.4809C17.4339 9.85767 16.9268 9.35058 16.3021 9.35058ZM16.3021 13.4208C14.7042 13.4208 13.4041 12.102 13.4041 10.4809C13.4041 8.88313 14.7042 7.58276 16.3021 7.58276C17.9011 7.58276 19.2015 8.88313 19.2015 10.4809C19.2015 12.102 17.9011 13.4208 16.3021 13.4208Z",
                              fill: "white",
                            },
                          }),
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d: "M9.60076 3.22436C8.97749 3.22436 8.4704 3.73145 8.4704 4.35472C8.4704 4.97854 8.97749 5.48582 9.60076 5.48582C10.224 5.48582 10.7311 4.97854 10.7311 4.35472C10.7311 3.73145 10.224 3.22436 9.60076 3.22436ZM9.60076 7.25345C8.00312 7.25345 6.70276 5.95309 6.70276 4.35472C6.70276 2.75691 8.00312 1.45654 9.60076 1.45654C11.1986 1.45654 12.4988 2.75691 12.4988 4.35472C12.4988 5.95309 11.1986 7.25345 9.60076 7.25345Z",
                              fill: "white",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm.isDropdown === "typeDropdown"
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "on-clickaway",
                              rawName: "v-on-clickaway",
                              value: _vm.hideDropdown,
                              expression: "hideDropdown",
                            },
                          ],
                          staticClass:
                            "dropdown__menu right dropdown__menu--sub-type",
                        },
                        _vm._l(_vm.typeOptions, function (option) {
                          return _c(
                            "div",
                            {
                              key: option.value,
                              class: [
                                _vm.subTypeText === option.text
                                  ? "dropdown__menu-item active"
                                  : "dropdown__menu-item",
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.typeOptionsSelected(option.value)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(option.text) + " ")]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm.isVibonsAdmin
                ? _c(
                    "dropdown",
                    {
                      attrs: {
                        "class-name": "filter",
                        "filter-on": _vm.the_filter.only_active_customer_users,
                      },
                    },
                    [
                      _c("template", { slot: "icon" }, [
                        _c("i", {
                          staticClass:
                            "fa fa-filter button button--type-icon size-sm d-flex align-items-center",
                          staticStyle: {
                            cursor: "pointer",
                            color: "rgb(114, 125, 146)",
                          },
                        }),
                      ]),
                      _c("template", { slot: "body" }, [
                        _c("div", { staticClass: "d-block p-2" }, [
                          _c("div", { staticClass: "d-block" }, [
                            _c(
                              "label",
                              {
                                staticClass: "checkbox",
                                attrs: {
                                  for: "filter-only_active_customer_users",
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.the_filter
                                          .only_active_customer_users,
                                      expression:
                                        "the_filter.only_active_customer_users",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "filter-only_active_customer_users",
                                    "true-value": true,
                                    "false-value": false,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.the_filter.only_active_customer_users
                                    )
                                      ? _vm._i(
                                          _vm.the_filter
                                            .only_active_customer_users,
                                          null
                                        ) > -1
                                      : _vm.the_filter
                                          .only_active_customer_users,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.the_filter
                                            .only_active_customer_users,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.the_filter,
                                              "only_active_customer_users",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.the_filter,
                                              "only_active_customer_users",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.the_filter,
                                          "only_active_customer_users",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("only_active_customer_users")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          ref: "containerBox",
          class:
            _vm.currentViewMode === "card" ? "container-fluid" : "container",
          attrs: { id: "containerBox" },
        },
        [
          _c(
            "div",
            { staticClass: "vuetable-wrapper" },
            [
              _vm.tableIsLoading
                ? _c("div", { staticClass: "lds-dual-ring" })
                : _vm._e(),
              _c("vue-table", {
                ref: "myvuetable",
                class: { "table-fade": _vm.tableIsLoading },
                attrs: {
                  "api-url": _vm.apiUrl,
                  "http-fetch": _vm.myFetch,
                  "api-mode": true,
                  "pagination-path": "meta",
                  "http-method": "get",
                  "append-params": _vm.moreParams,
                  "http-options": {
                    headers: {
                      Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                    },
                  },
                  fields: _vm.fields,
                  "view-mode": _vm.currentViewMode,
                  "wrapper-class": "vuetable-wrapper",
                  "no-data-template": _vm.$t("no_data_info"),
                  css: _vm.css.table,
                  "per-page": _vm.perPage,
                  "query-params": _vm.makeQueryParams,
                  "reactive-api-url": false,
                },
                on: {
                  "vuetable:loading": function ($event) {
                    _vm.tableIsLoading = true
                  },
                  "vuetable:loaded": function ($event) {
                    _vm.tableIsLoading = false
                  },
                  "vuetable:load-error": _vm.vuetableLoadError,
                  "vuetable:pagination-data": _vm.onPaginationData,
                  viewModeStatus: _vm.setViewMode,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "custom-block",
                      fn: function (props) {
                        return _vm.currentViewMode === "card"
                          ? [
                              _c(
                                "li",
                                {
                                  key: props.rowIndex,
                                  staticClass:
                                    "list-group-item card card--catalog card--catalog-item",
                                  style: { "--i": props.rowIndex },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.plusActionToggle(
                                        props.rowIndex
                                      )
                                    },
                                    mouseleave: _vm.hidePlusAction,
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "card-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.previewContentItem(
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.isItemNew(props.rowData.created_at)
                                        ? _c("div", { staticClass: "ribbon" }, [
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.$t("new"))),
                                            ]),
                                          ])
                                        : _vm._e(),
                                      _c("figure", {
                                        staticClass: "card__media",
                                        style:
                                          "background-image: url(" +
                                          props.rowData.thumb +
                                          ");",
                                      }),
                                      _c("div", { staticClass: "card__body" }, [
                                        _c(
                                          "div",
                                          { staticClass: "card__content" },
                                          [
                                            _vm.subtypeCondition(props.rowData)
                                              ? _c(
                                                  "span",
                                                  { staticClass: "card__info" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$helpers.getSubTypeName(
                                                          props.rowData.sub_type
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "h3",
                                              { staticClass: "card__title" },
                                              [
                                                _vm.titleCondition(
                                                  props.rowData
                                                )
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.rowData.name
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "card__bottom" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card__bottom__left",
                                              },
                                              [
                                                _c(
                                                  "small",
                                                  { staticClass: "card__info" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(props.rowData.info)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("div", { staticClass: "card__footer" }, [
                                    _vm.isVibonsAdmin
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "button-upload",
                                            on: {
                                              click: function ($event) {
                                                return _vm.uploadImageContent(
                                                  props.rowData
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                attrs: {
                                                  name: "upload",
                                                  "view-box": "0 0 334 334",
                                                  height: "24",
                                                  width: "24",
                                                  color: "#fa314a",
                                                  "is-icon-class": true,
                                                },
                                              },
                                              [_c("icon-upload")],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown",
                                        on: {
                                          mouseover: function ($event) {
                                            return _vm.dropdownToggle(
                                              props.rowIndex
                                            )
                                          },
                                          mouseleave: _vm.hideToggleDropdown,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "card--actions dropdown__toggle",
                                          },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                staticClass: "threedots",
                                                attrs: {
                                                  name: "threedotsvertical",
                                                  width: 5,
                                                  height: 22,
                                                  "view-box": "0 0 5 22",
                                                },
                                              },
                                              [_c("icon-threedotsvertical")],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.dropdownIndex ===
                                                  props.rowIndex,
                                                expression:
                                                  "dropdownIndex === props.rowIndex",
                                              },
                                              {
                                                name: "on-clickaway",
                                                rawName: "v-on-clickaway",
                                                value: _vm.hideToggleDropdown,
                                                expression:
                                                  "hideToggleDropdown",
                                              },
                                            ],
                                            ref: `dropdownBox${props.rowIndex}`,
                                            staticClass: "dropdown__menu",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "dropdown__button button",
                                                attrs: {
                                                  disabled:
                                                    !_vm.canEdit &&
                                                    !!props.rowData.is_public,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onAction(
                                                      "edit-item",
                                                      props.rowData
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      width: 18,
                                                      height: 18,
                                                      name: "edit",
                                                      "view-box": "0 0 24 24",
                                                      color: "#727d92",
                                                      "is-icon-class": false,
                                                    },
                                                  },
                                                  [_c("icon-edit")],
                                                  1
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("edit")) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "dropdown__button button",
                                                attrs: {
                                                  disabled:
                                                    !_vm.canEdit &&
                                                    !!props.rowData.is_public,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onAction(
                                                      "delete-item",
                                                      props.rowData
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "icon-container",
                                                  {
                                                    attrs: {
                                                      width: 18,
                                                      height: 18,
                                                      name: "trash",
                                                      "view-box": "0 0 24 24",
                                                      color: "#727d92",
                                                      "is-icon-class": false,
                                                    },
                                                  },
                                                  [_c("icon-trash")],
                                                  1
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("delete")) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          : undefined
                      },
                    },
                    {
                      key: "icontype-slot",
                      fn: function (props) {
                        return [
                          _c("span", {
                            staticClass: "icon__item",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$helpers.iconType(props.rowData.sub_type)
                              ),
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "name-slot",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "content-item",
                              on: {
                                click: function ($event) {
                                  return _vm.previewContentItem(props.rowData)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(props.rowData.name) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "subtype-slot",
                      fn: function (props) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$helpers.getSubTypeName(
                                  props.rowData.sub_type
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "action-slot",
                      fn: function (props) {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "ui button edit",
                              attrs: {
                                disabled:
                                  !_vm.canEdit && !!props.rowData.is_public,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onAction(
                                    "edit-item",
                                    props.rowData
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    width: 20,
                                    height: 20,
                                    name: "edit",
                                    color: "#727d92",
                                  },
                                },
                                [_c("icon-edit")],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "ui button delete",
                              attrs: {
                                disabled:
                                  !_vm.canEdit && !!props.rowData.is_public,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onAction(
                                    "delete-item",
                                    props.rowData
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    width: 20,
                                    height: 20,
                                    name: "trash",
                                    color: "#727d92",
                                  },
                                },
                                [_c("icon-trash")],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c(
                "div",
                { staticClass: "pagination__info" },
                [
                  _c("vuetable-pagination-info", {
                    ref: "paginationInfo",
                    class: {
                      "table-fade": _vm.tableIsLoading,
                      center: _vm.currentViewMode === "card",
                      left: _vm.currentViewMode !== "card",
                    },
                    attrs: {
                      "info-template": _vm.$t("pagination_info"),
                      "no-data-template": _vm.$t("no_data_info"),
                      css: _vm.css.paginationInfo,
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "pagination__items" },
                    [
                      _c("vuetable-pagination", {
                        ref: "pagination",
                        attrs: { css: _vm.css.pagination },
                        on: {
                          "vuetable-pagination:change-page": _vm.onChangePage,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }