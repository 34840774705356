var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "custom-dropdown",
      class: { "custom-dropdown--sub": _vm.role },
    },
    [
      _c(
        "span",
        {
          class: {
            [`custom-dropdown__${_vm.role ? "sub" : "btn"}`]: true,
            [`custom-dropdown__${_vm.role ? "sub" : "btn"}--active`]:
              !_vm.isHidden,
            [`${_vm.className}-bp__btn`]: _vm.className,
            [`${_vm.className}-bp__btn--active`]: !_vm.isHidden,
            "filter-on": _vm.filterOn,
          },
          on: {
            click: _vm._onToggle,
            mouseenter: _vm._onBtnEnter,
            mouseleave: _vm._onBtnLeave,
          },
        },
        [
          _vm._t("btn"),
          _vm.isIcon
            ? _vm._t("icon", function () {
                return [
                  _vm.isLoading
                    ? _c(
                        "svg",
                        {
                          staticClass:
                            "custom-dropdown__icon custom-dropdown__icon--spin",
                          attrs: { viewBox: "0 0 512 512" },
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "currentColor",
                              d: "M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z",
                            },
                          }),
                        ]
                      )
                    : _c(
                        "svg",
                        {
                          staticClass: "custom-dropdown__icon",
                          class: {
                            [`custom-dropdown__icon--${_vm.align}`]: _vm.align,
                          },
                          attrs: { viewBox: "0 0 256 512" },
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "currentColor",
                              d: "M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z",
                            },
                          }),
                        ]
                      ),
                ]
              })
            : _vm._e(),
        ],
        2
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.isHidden
          ? _c(
              "div",
              {
                staticClass: "custom-dropdown__body",
                class: { [`${_vm.className}-bp__body`]: _vm.className },
                style: {
                  minWidth: `${_vm.width}px`,
                  top: `${_vm.top}px`,
                  left: `${_vm.left}px`,
                },
                attrs: { id: _vm.id },
                on: {
                  click: _vm._onBodyClick,
                  mouseenter: _vm._onBodyEnter,
                  mouseleave: _vm._onBodyLeave,
                },
              },
              [_vm._t("body")],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }