var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("rect", {
      attrs: {
        x: ".5",
        y: ".5",
        width: "74",
        height: "43",
        rx: "5.5",
        stroke: "currentColor",
        "stroke-opacity": ".5",
      },
    }),
    _c("path", {
      attrs: {
        stroke: "currentColor",
        "stroke-linecap": "round",
        d: "M14.5 26.5h6M22.5 26.5h6M31.5 20.5h3M37.5 26.5h6M45.5 26.5h6M53.5 26.5h6",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }