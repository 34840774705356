<template>
  <div class="page">
    <app-header
      child-header-class="container"
      :child-header-navigation="childHeaderNavigation"
      is-child-header-left-actions
      add-journeygroup
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>
    <modal-create-journey-group
      v-if="modal.modalVisible && modal.modalType === 'modalCreateJourneyGroup'"
    />
    <modal-journey-group-add-journeys
      v-if="modal.modalVisible && modal.modalType === 'modalJourneyGroupAddJourneys'"
    />
    <main class="page__body">
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="container-fluid container-cards">
        <div class="container">
          <div class="table-responsive">
            <vue-table
              :class="{ 'table-fade': tableIsLoading }"
              ref="myvuetable"
              :api-url="apiUrl"
              :http-fetch="myFetch"
              :api-mode="true"
              @vuetable:loading="tableIsLoading = true"
              @vuetable:loaded="tableIsLoading = false"
              @vuetable:load-error="vuetableLoadError"
              pagination-path="meta"
              http-method="get"
              :append-params="moreParams"
              :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
              :fields="fields"
              :no-data-template="$t('no_data_info')"
              :css="css.table"
              :per-page="perPage"
              :query-params="makeQueryParams"
              @vuetable:pagination-data="onPaginationData"
            >
              <template
                slot="name-slot"
                slot-scope="props"
              >
                <div>
                  <span>{{ nameGenerated(props) }}</span>
                </div>
              </template>
              <template
                slot="journey_count"
                slot-scope="props"
              >
                <span>
                  <router-link
                    :to="{
                      name: 'journeyGroupjourney',
                      params: {
                        journeyGroupId: props.rowData.id,
                        title: props.rowData.name,
                      },
                    }"
                  >
                    {{ props.rowData.journey_count }}
                  </router-link>
                </span>
              </template>
              <template
                slot="action-slot"
                slot-scope="props"
              >
                <div class="button-group center action-button">
                  <div
                    class="dropdown"
                    @click="onAction('open-journey-group', props.rowData)"
                  >
                    <span style="cursor: pointer">
                      <icon-container
                        :width="16"
                        :height="16"
                        color="#727d92"
                        view-box="0 0 14 14"
                      >
                        <icon-search />
                      </icon-container>
                    </span>
                  </div>

                  <div
                    class="dropdown ml-2 mr-2"
                    @click="onAction('add-journey', props.rowData)"
                  >
                    <span style="cursor: pointer">
                      <icon-container
                        width="18"
                        height="18"
                        view-box="0 0 24 24"
                        name="cplus"
                        color="#727d92"
                      >
                        <icon-circleplus />
                      </icon-container>
                    </span>
                  </div>
                  <div
                    class="dropdown"
                    @mouseover="dropdownToggle(`three${props.rowIndex}`)"
                    @mouseleave="hideDropdown"
                  >
                    <span class="card--actions dropdown__toggle">
                      <icon-container
                        :width="20"
                        :height="20"
                        view-box="0 0 24 24"
                        name="threedots"
                        color="#727d92"
                      >
                        <icon-threedots />
                      </icon-container>
                    </span>

                    <div
                      class="dropdown__menu"
                      :ref="`three${props.rowIndex}`"
                      v-if="dropdownIndex === 'three' + props.rowIndex"
                      v-on-clickaway="hideDropdown"
                    >
                      <span
                        class="dropdown__button button"
                        @click="onAction('edit-journey-group', props.rowData)"
                      >
                        <icon-container
                          :width="16"
                          :height="16"
                          view-box="0 0 20 20"
                          name="edit"
                          color="#727d92"
                        >
                          <icon-edit />
                        </icon-container>
                        {{ $t("edit") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="requestAssignedUserReport(props.rowData)"
                      >
                        <icon-container
                          name="content"
                          view-box="0 0 18 17"
                          :is-icon-class="false"
                        >
                          <icon-dashboard />
                        </icon-container>
                        {{ $t("request_assigned_user_report") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="exportContentListReport(props.rowData)"
                      >
                        <icon-container
                          name="content"
                          view-box="0 0 15 14"
                          :is-icon-class="false"
                        >
                          <icon-download />
                        </icon-container>
                        {{ $t("download_journey_content_report") }}
                      </span>
                      <span
                        class="dropdown__button button"
                        @click="onAction('delete-item', props.rowData)"
                      >
                        <icon-container
                          :width="18"
                          :height="18"
                          view-box="0 0 24 24"
                          name="trash"
                          color="#727d92"
                        >
                          <icon-trash />
                        </icon-container>
                        {{ $t('delete') }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </vue-table>
            <div class="pagination__info">
              <vuetable-pagination-info
                :class="{ 'table-fade': tableIsLoading }"
                ref="paginationInfo"
                :info-template="$t('pagination_info')"
                :no-data-template="$t('no_data_info')"
              />
              <div class="pagination__items">
                <vuetable-pagination
                  ref="pagination"
                  :css="css.pagination"
                  @vuetable-pagination:change-page="onChangePage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable import/no-unresolved */
import { mapState, mapGetters } from 'vuex';
import { directive as onClickaway } from 'vue-clickaway';
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconThreedots from '@/components/icons/Threedots.vue';
import IconCirclePlus from '@/components/icons/CirclePlus.vue';
import IconSearch from '@/components/icons/Search.vue';
import IconDownload from '@/components/icons/Download.vue';
import IconDashboard from '@/components/icons/Dashboard.vue';
import IconEdit from '@/components/icons/Edit.vue';

import ModalCreateJourneyGroup from '@/container/modal/ModalCreateJourneyGroup.vue';
import ModalJourneyGroupAddJourneys from '@/container/modal/ModalJourneyGroupAddJourneys.vue';
import {
  API_JOURNEY_GROUP_DATATABLE,
  API_JOURNEY_GROUP,
  API_JOURNEY_REPORT,
  API_USER_GROUP_JOURNEY_REPORT,
  API_SERVER_TIME_FORMAT,
} from '@/helpers/config';
import AppHeader from '@/components/AppHeader.vue';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import VuetablePagination from '../components/CustomVuetable/VuetablePagination.vue';

const moment = require('moment');

export default {
  name: 'JourneyGroup',
  mixins: [myTableMixin],
  components: {
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'icon-container': IconContainer,
    'icon-search': IconSearch,
    'icon-download': IconDownload,
    'icon-trash': IconTrash,
    'modal-create-journey-group': ModalCreateJourneyGroup,
    'modal-journey-group-add-journeys': ModalJourneyGroupAddJourneys,
    'icon-dashboard': IconDashboard,
    'icon-edit': IconEdit,
    'icon-threedots': IconThreedots,
    'icon-circleplus': IconCirclePlus,
    'filter-perpage': FilterPerpage,
  },

  data() {
    return {
      moreParams: {
        customer_id: this.$store.state.auth.customerId,
      },
    };
  },
  beforeDestroy() {
    console.log('Journeyactive before destroyed');
    this.$refs.myvuetable.resetData();
  },
  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'JourneyGroup') {
        this.$refs.myvuetable.refresh();
        this.refreshPage = false;
        this.pageToRefresh = null;
      }
    },
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  computed: {
    ...mapState([
      'modal',
    ]),

    ...mapGetters({
      userLang: 'auth/userLang',
    }),

    apiUrl() {
      if (API_JOURNEY_GROUP_DATATABLE) {
        return `${API_JOURNEY_GROUP_DATATABLE}`;
      }
      return null;
    },

    fields() {
      return [
        {
          name: 'name-slot',
          title: this.$t('journey_group_name'),
          width: '60%',
          sortField: 'name',
        },
        {
          name: 'journey_count',
          title: this.$t('journey_count'),
          width: '20%',
          titleClass: 'center',
          dataClass: 'center',
        },
        {
          name: 'action-slot',
          title: this.$t('actions'),
          width: '20%',
          titleClass: 'center',
          dataClass: 'center',
        },
      ];
    },

    currentCustomerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      return this.$store.state.auth.isVibonsAdmin;
    },

    childHeaderNavigation() {
      const pathsTemp = [
        {
          text: this.$t('catalog'),
          url: '/',
        },
        {
          text: this.$t('journey_group'),
          url: '/journeygroup',
        },
        {
          text: this.$t('planned_journey'),
          url: '/plannedjourney',
        },
      ];
      // if (this.isVibonsAdmin) {
      //  pathsTemp.push(
      //    {
      //      text: this.$t('planned_journey'),
      //      url: '/plannedjourney',
      //    },
      //  );
      // }
      return pathsTemp;
    },
  },

  activated() {
    this.$refs.myvuetable.refresh();
  },

  methods: {
    requestAssignedUserReport(data) {
      const itemId = data.id;
      const obj = {
        modalType: 'modalReportDownload',
        reportDownloadUrl: `${API_USER_GROUP_JOURNEY_REPORT}`,
        dateOnly: false,
        reportDownloadPayload: {
          customer_id: this.currentCustomerId,
          journey_group_id: itemId,
        },
      };
      this.$helpers.toggleModal(obj);
    },
    exportContentListReport(data) {
      const itemId = data.id;
      const obj = {
        modalType: 'modalReportDownload',
        reportDownloadUrl: `${API_JOURNEY_REPORT}/${itemId}`,
        dateOnly: false,
        reportDownloadRequest: 'get',
        reportDownloadPayload: {},
      };
      this.$helpers.toggleModal(obj);
    },
    onAction(action, data) {
      const itemId = data.id;
      this.idHolder = itemId;
      this.apiUrlHolder = API_JOURNEY_GROUP;

      if (!itemId) {
        return;
      }

      if (action === 'delete-item' && !this.snotifyQueueList.includes(itemId)) {
        this.snotifyQueueList.push(itemId);
        this.$snotify.confirm(this.$t('journey_delete_msg_body', { name: data.name }), this.$t('journey_delete_msg_title'), {
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          buttons: [
            {
              text: this.$t('yes'),
              action: (toast) => {
                this.deleteConfirm(toast, this.idHolder, this.apiUrlHolder);
              },
              bold: false,
            },
            {
              text: this.$t('no'),
              action: (toast) => {
                this.deleteCancel(toast, this.idHolder);
              },
            },
          ],
        }).on('shown', (toast) => {
          this.toastHolder = toast;
          document.addEventListener('keydown', this.eventHandler, false);
        }).on('hidden', (toast) => {
          this.toastHolder = toast;
          document.removeEventListener('keydown', this.eventHandler, false);
        });
      }

      if (action === 'add-journey') {
        const obj = {
          modalType: 'modalJourneyGroupAddJourneys',
          pageToRefresh: 'JourneyGroup',
          journeyGroupId: itemId,
          customerIdToEdit: this.currentCustomerId,
        };
        this.$helpers.toggleModal(obj);
      }
      if (action === 'edit-journey-group') {
        const obj = {
          modalType: 'modalCreateJourneyGroup',
          editMode: true,
          journeyGroupId: itemId,
          pageToRefresh: 'JourneyGroup',
        };
        this.$helpers.toggleModal(obj);
      }
      if (action === 'open-journey-group') {
        this.$router.push({
          name: 'journeyGroupjourney',
          params: {
            journeyGroupId: itemId,
            title: data.name,
          },
        });
      }
    },

    displayReportNotification() {
      const obj = {
        modalType: 'modalReportNotification',
      };
      this.$helpers.toggleModal(obj);
    },

    formatDate(value) {
      let fmt = null;
      let date = null;
      if (value) {
        if (this.userLang === 'en') {
          fmt = 'MM.DD.YYYY';
        }
        if (this.userLang === 'tr') {
          fmt = 'DD.MM.YYYY';
        }
        date = moment(value, API_SERVER_TIME_FORMAT).format(fmt);
      }
      return date;
    },
  },
};
</script>

<style lang="scss" scoped>
.status-active {
  color: rgb(8, 206, 25);
  font-weight: 600;
}

.select-perpage {
  vertical-align: middle;
  display: inline-block;
}
button.ui.button {
  padding: 8px 3px 8px 10px;
  margin-top: 1px;
  margin-bottom: 1px;
  }

::v-deep div.action-button {
  margin: 10px!important;
}
</style>
