var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-pane show active" }, [
    _c(
      "div",
      { staticClass: "form-group form-group--cards" },
      [
        _c(
          "draggable-comp",
          _vm._b(
            {
              staticClass: "request-listings",
              attrs: { handle: ".button-handle" },
              on: { start: _vm.dragStart, end: _vm.dragEnd },
              model: {
                value: _vm.questions,
                callback: function ($$v) {
                  _vm.questions = $$v
                },
                expression: "questions",
              },
            },
            "draggable-comp",
            _vm.dragOptions,
            false
          ),
          [
            _c(
              "transition-group",
              { attrs: { name: "ilist" } },
              _vm._l(_vm.questions, function (item, qindex) {
                return _c(
                  "div",
                  { key: `k${qindex}`, staticClass: "request-text-item" },
                  [
                    _c(
                      "span",
                      { staticClass: "button-handle" },
                      [
                        _c(
                          "icon-container",
                          {
                            attrs: {
                              name: "handle",
                              width: "25",
                              "view-box": "0 0 25 18",
                              "is-icon-class": false,
                            },
                          },
                          [_c("icon-handle")],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.editMode
                      ? _c(
                          "div",
                          { staticClass: "single-update-block" },
                          [
                            _c(
                              "transition",
                              { attrs: { name: "fade", mode: "in-out" } },
                              [
                                _vm.isDirty(item)
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "button button--alternative text-size-xs size-sm m-0 p-2 float-right",
                                        attrs: {
                                          type: "button",
                                          disabled: _vm.isPending,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveChanges()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("save_changes")) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "transition",
                              { attrs: { name: "fade", mode: "in-out" } },
                              [
                                _vm.isUpdated(item)
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "button button--secondary shadow-none text-size-xs size-sm m-0 p-2 float-right",
                                        attrs: { type: "button" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("saved_changes")) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "form-label", attrs: { for: "" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("question")) +
                                  " " +
                                  _vm._s(qindex + 1)
                              ),
                            ]
                          ),
                          _c("textarea", {
                            directives: [
                              { name: "resizable", rawName: "v-resizable" },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.question,
                                expression: "item.question",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate.disable",
                                value: {
                                  required: true,
                                  max: 1000,
                                  min: 5,
                                },
                                expression:
                                  "{\n                    required: true,\n                    max: 1000,\n                    min:5,\n                  }",
                                modifiers: { disable: true },
                              },
                            ],
                            key: qindex,
                            staticClass: "form-control",
                            class: {
                              "is-danger": _vm.errors.has(
                                `request.question${qindex}`
                              ),
                            },
                            attrs: {
                              rows: "1",
                              disabled: _vm.disabled,
                              placeholder: _vm.$t("question_placeholder"),
                              "data-vv-scope": "request",
                              name: "question" + qindex,
                              "data-vv-as": _vm.$t("question_") + (qindex + 1),
                            },
                            domProps: { value: item.question },
                            on: {
                              "~input": function ($event) {
                                return _vm.triggerPreviewNextQuestion(qindex)
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(item, "question", $event.target.value)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.has(`request.question${qindex}`)
                            ? _c(
                                "span",
                                {
                                  key: "error" + qindex,
                                  staticClass: "help is-danger",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        `request.question${qindex}`
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "form-label", attrs: { for: "" } },
                            [_vm._v(_vm._s(_vm.$t("description")))]
                          ),
                          _c("textarea", {
                            directives: [
                              { name: "resizable", rawName: "v-resizable" },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.sub_text,
                                expression: "item.sub_text",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate.disable",
                                value: {
                                  max: 400,
                                },
                                expression:
                                  "{\n                    max: 400,\n                  }",
                                modifiers: { disable: true },
                              },
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-danger": _vm.errors.has(
                                `request.sub_text${qindex}`
                              ),
                            },
                            attrs: {
                              rows: "1",
                              disabled: _vm.disabled,
                              placeholder: _vm.$t("type_your_desciption"),
                              "data-vv-scope": "request",
                              name: "sub_text" + qindex,
                              "data-vv-as":
                                _vm.$t("description") + (qindex + 1),
                            },
                            domProps: { value: item.sub_text },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(item, "sub_text", $event.target.value)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.has(`request.sub_text${qindex}`)
                            ? _c(
                                "span",
                                {
                                  key: "derror" + qindex,
                                  staticClass: "help is-danger",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        `request.sub_text${qindex}`
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-6" }, [
                        item.type === "get_info"
                          ? _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "" },
                                },
                                [_vm._v(_vm._s(_vm.$t("format")))]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.options.format,
                                    expression: "item.options.format",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-danger": _vm.errors.has(
                                    `request.format${qindex}`
                                  ),
                                },
                                attrs: {
                                  type: "text",
                                  disabled: _vm.disabled,
                                  placeholder: _vm.$t("type_your_format"),
                                  "data-vv-scope": "request",
                                  name: "format" + qindex,
                                  "data-vv-as": _vm.$t("format") + (qindex + 1),
                                },
                                domProps: { value: item.options.format },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      item.options,
                                      "format",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm.errors.has(`request.format${qindex}`)
                                ? _c(
                                    "span",
                                    {
                                      key: "derror" + qindex,
                                      staticClass: "help is-danger",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            `request.format${qindex}`
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "col-6 form-group" }, [
                        _c(
                          "label",
                          { staticClass: "form-label", attrs: { for: "" } },
                          [_vm._v(_vm._s(_vm.$t("type")) + " ")]
                        ),
                        _c("div", { staticClass: "d-flex rating-block" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "view-mode-type d-flex align-items-center",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "m-0",
                                  class: { selected: item.type === "get_info" },
                                  on: {
                                    click: function ($event) {
                                      item.type = "get_info"
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "icon-container",
                                    {
                                      attrs: {
                                        "view-box": "0 0 75 44",
                                        width: 75,
                                        height: 44,
                                        name: "card",
                                        color: "transparent",
                                      },
                                    },
                                    [_c("icon-requestinfo")],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "m-0 ml-2",
                                  class: { selected: item.type === "image" },
                                  on: {
                                    click: function ($event) {
                                      item.type = "image"
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "icon-container",
                                    {
                                      attrs: {
                                        "view-box": "0 0 75 44",
                                        width: 75,
                                        height: 44,
                                        name: "list",
                                        color: "transparent",
                                      },
                                    },
                                    [_c("icon-requestimage")],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.editMode && item.type === "file"
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "m-0 ml-2",
                                      class: { selected: item.type === "file" },
                                      on: {
                                        click: function ($event) {
                                          item.type = "file"
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            "view-box": "0 0 75 44",
                                            width: 75,
                                            height: 44,
                                            name: "list",
                                            color: "transparent",
                                          },
                                        },
                                        [_c("icon-requestfile")],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    item
                      ? _c(
                          "button",
                          {
                            staticClass: "button-remove",
                            attrs: { disabled: _vm.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.removeQuestionItem(qindex)
                              },
                            },
                          },
                          [
                            _c(
                              "icon-container",
                              {
                                attrs: {
                                  width: 15,
                                  height: 15,
                                  "view-box": "0 0 25 25",
                                  name: "trash",
                                  color: "#727d92",
                                },
                              },
                              [_c("icon-trash")],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "div",
        { staticClass: "row d-flex justify-content-center align-items-center" },
        [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-center align-items-center pointer-click",
                on: { click: _vm.addQuestion },
              },
              [
                _c(
                  "span",
                  { staticClass: "card--actions request-button-add" },
                  [
                    _c(
                      "icon-container",
                      {
                        attrs: {
                          name: "cross",
                          width: 20,
                          height: 20,
                          color: "#fff",
                        },
                      },
                      [_c("icon-cross")],
                      1
                    ),
                  ],
                  1
                ),
                _c("span", { staticClass: "request-btn-text" }, [
                  _vm._v(_vm._s(_vm.$t("add_button"))),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _vm.errors.has("request.question")
        ? _c("span", { staticClass: "help is-danger" }, [
            _vm._v(_vm._s(_vm.errors.first("request.question"))),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      {
        staticClass: "form-group",
        class: { "is-danger": _vm.errors.has("request.title") },
      },
      [
        _c(
          "label",
          { staticClass: "form-label", attrs: { for: "contentTitle" } },
          [_vm._v(_vm._s(_vm.$t("title").toUpperCase()))]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title",
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|max:100",
              expression: "'required|max:100'",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            id: "contentTitle",
            disabled: _vm.disabled && !_vm.canEditOnlyItem,
            "data-vv-scope": "request",
            "data-vv-name": "title",
            "data-vv-as": _vm.$t("title"),
          },
          domProps: { value: _vm.title },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.title = $event.target.value
              },
              _vm.handleTitle,
            ],
          },
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("request.title"),
                expression: "errors.has('request.title')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v(_vm._s(_vm.errors.first("request.title")))]
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.errors.items.length > 0,
            expression: "errors.items.length > 0",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("div", { staticClass: "alert alert--card alert--error" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
          _c(
            "ul",
            _vm._l(_vm.errors, function (error, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(error.msg) + " "),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "button button--primary",
          attrs: {
            type: "submit",
            disabled: _vm.isPending || (_vm.disabled && !_vm.canEditOnlyItem),
          },
          on: {
            click: function ($event) {
              _vm.editMode
                ? _vm.isOrderChanged
                  ? _vm.saveOrder()
                  : _vm.updateContent()
                : _vm.createContent()
            },
          },
        },
        [
          !_vm.isPending
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.isOrderChanged
                          ? _vm.$t("update_order_button")
                          : _vm.$t("update_button")
                        : _vm.$t("create_button")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.isPending
            ? _c("div", { staticClass: "lds-ellipsis" }, [
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }