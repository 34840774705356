var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-pane show active" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "form-group form-group--cards" },
          [
            _c(
              "label",
              { staticClass: "form-label", attrs: { for: "package_zip" } },
              [_vm._v(_vm._s(_vm.$t("package_zip").toUpperCase() + "*"))]
            ),
            _c("fileupload-container", {
              staticClass: "card cardfile--upload",
              class: { "is-danger": _vm.errors.has(`scorm.package_zip`) },
              attrs: {
                "file-url": _vm.url,
                disabled: _vm.disabled,
                "file-index": 0,
                id: "package_zip",
                "is-multiple": false,
                "vee-rule": "required|size:1800000",
                "vee-scoop-name": "scorm",
                "vee-name": `package_zip`,
                "vee-as": _vm.$t("package_zip"),
              },
              on: { update: _vm.fileAdd },
            }),
            _vm.errors.has(`scorm.package_zip`)
              ? _c("span", { staticClass: "help is-danger" }, [
                  _vm._v(_vm._s(_vm.errors.first(`scorm.package_zip`))),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "indexFilePath" } },
            [_vm._v(_vm._s(_vm.$t("indexFilePath").toUpperCase() + "*"))]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.iFilePath,
                expression: "iFilePath",
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{required: true}",
              },
            ],
            ref: "indexFilePath",
            staticClass: "form-control",
            class: { "is-danger": _vm.errors.has("scorm.indexFilePath") },
            attrs: {
              type: "text",
              disabled: _vm.disabled,
              id: "indexFilePath",
              "data-vv-scope": "scorm",
              "data-vv-name": "indexFilePath",
              "data-vv-as": _vm.$t("indexFilePath"),
            },
            domProps: { value: _vm.iFilePath },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.iFilePath = $event.target.value
                },
                function ($event) {
                  return _vm.$emit("setIndexFilePath", _vm.iFilePath)
                },
              ],
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("scorm.indexFilePath"),
                  expression: "errors.has('scorm.indexFilePath')",
                },
              ],
              staticClass: "help is-danger",
            },
            [_vm._v(_vm._s(_vm.errors.first("scorm.indexFilePath")))]
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "form-group",
        class: { "is-danger": _vm.errors.has("scorm.title") },
      },
      [
        _c(
          "label",
          { staticClass: "form-label", attrs: { for: "contentTitle" } },
          [_vm._v(_vm._s(_vm.$t("title").toUpperCase()))]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title",
            },
            {
              name: "validate",
              rawName: "v-validate",
              value: "required|max:100",
              expression: "'required|max:100'",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            id: "contentTitle",
            disabled: _vm.disabled && !_vm.canEditOnlyItem,
            "data-vv-scope": "scorm",
            "data-vv-name": "title",
            "data-vv-as": _vm.$t("title"),
          },
          domProps: { value: _vm.title },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.title = $event.target.value
              },
              function ($event) {
                return _vm.$emit("setTitle", _vm.title)
              },
            ],
          },
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("scorm.title"),
                expression: "errors.has('scorm.title')",
              },
            ],
            staticClass: "help is-danger",
          },
          [_vm._v(_vm._s(_vm.errors.first("scorm.title")))]
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.errors.items.length > 0,
            expression: "errors.items.length > 0",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("div", { staticClass: "alert alert--card alert--error" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
          _c(
            "ul",
            _vm._l(_vm.errors, function (error, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(error.msg) + " "),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "button button--primary",
          attrs: {
            type: "button",
            disabled: _vm.isPending || (_vm.disabled && !_vm.canEditOnlyItem),
          },
          on: {
            click: function ($event) {
              _vm.editMode
                ? _vm.handleContent("updateContent")
                : _vm.handleContent("createContent")
            },
          },
        },
        [
          !_vm.isPending
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.$t("update_button")
                        : _vm.$t("create_button")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.isPending
            ? _c("div", { staticClass: "lds-ellipsis" }, [
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }