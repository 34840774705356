import Vue from 'vue';
import Snotify from 'vue-snotify';
import VueSanitize from 'vue-sanitize';
import VeeValidate from 'vee-validate';
import en from 'vee-validate/dist/locale/en';
import tr from 'vee-validate/dist/locale/tr';
import Tooltip from 'vue-directive-tooltip';
import HighchartsVue from 'highcharts-vue';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import VueKonva from 'vue-konva';
import VueLodash from 'vue-lodash';
import map from 'lodash/map';
import has from 'lodash/has';
import every from 'lodash/every';
import last from 'lodash/last';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import lowerCase from 'lodash/lowerCase';
import isEqual from 'lodash/isEqual';
import indexOf from 'lodash/indexOf';
import difference from 'lodash/difference';
import differenceWith from 'lodash/differenceWith';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import eq from 'lodash/eq';
import keys from 'lodash/keys';
import union from 'lodash/union';
import transform from 'lodash/transform';
import filter from 'lodash/filter';
import mapKeys from 'lodash/mapKeys';
import merge from 'lodash/merge';
import { chain } from 'lodash';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import VueLazyload from 'vue-lazyload';

import AudioVisual from 'vue-audio-visual';
import { i18n } from '@/helpers/i18n';
import helpers from '@/helpers/helpers';
import route from '@/router/';
import API from '@/services/';
import store from '@/store/';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { GOOGLE_RECAPTCHA_SITE_KEY } from '@/helpers/config';
import * as DOMPurify from 'dompurify';
import App from './App.vue';
import 'font-awesome/scss/font-awesome.scss';

Vue.use(VueLazyload);
Vue.use(VueReCaptcha, {
  siteKey: GOOGLE_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    useRecaptchaNet: false,
    autoHideBadge: true,
  },
});
Vue.use(AudioVisual);
Vue.use(VueKonva);


const lodashOptions = {
  name: 'dash',
  lodash: {
    map, has, every, mapKeys, chain, groupBy, sortBy, last, size, isEmpty, isObject, indexOf, lowerCase, isEqual, difference, differenceWith, keys, eq, filter, merge, union, cloneDeep, debounce, transform,
  },
};
Vue.use(VueLodash, lodashOptions);

Vue.prototype.$store = store;
Vue.prototype.$API = API;

const defaultSanitizeOptions = {
  allowedTags: [],
  allowedAttributes: {},
};
Vue.use(VueSanitize, defaultSanitizeOptions);

Vue.use(Tooltip, {
  delay: 300,
  placement: 'right',
  class: 'tooltip-black',
  triggers: ['hover'],
  offset: 5,
});

Vue.directive('sanitize', {
  update(el) {
    const sourceValue = el.value;
    const newValue = DOMPurify.sanitize(sourceValue);
    if (sourceValue !== newValue) {
      // eslint-disable-next-line no-param-reassign
      el.value = newValue;
      el.dispatchEvent(new Event('input', { bubbles: true }));
    }
  },
});
const plugin = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};

Vue.use(plugin);

const VeeValidateConfig = {
  events: 'input',
  fieldsBagName: 'formFields',
  inject: false,
  dictionary: {
    en: {
      messages: {
        ...en.messages,
        oneAnswer: 'One answer should be marked as correct!',
        minAnswer: 'Minimum two answers is required!',
        minSurveyAnswer: 'Minimum two answers is required!',
      },
    },
    tr: {
      messages: {
        ...tr.messages,
        oneAnswer: 'Bir cevap doğru olarak işaretlenmelidir!',
        minAnswer: 'En az iki cevap gerekli!',
        minSurveyAnswer: 'En az iki cevap gerekli!',
      },
    },
  },
};

Vue.use(VeeValidate, VeeValidateConfig);

const SnotifyOptions = {
  global: {
    showProgressBar: false,
    newOnTop: true,
    oneAtTime: false,
    preventDuplicates: true,
  },
};

Vue.use(Snotify, SnotifyOptions);
Vue.config.productionTip = true;

Vue.use(HighchartsVue);
Vue.config.devtools = true;
Vue.config.performance = true;
/* eslint-disable no-new */
const bus = new Vue({
  store,
  router: route,
  i18n,
  render: h => h(App),
}).$mount('#app');
Vue.prototype.$bus = bus;
