<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      :class="{'is-loading': formSending }"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ $t('user_journey_contents') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <ReportJourneyUserJourneyInside
              v-if="modalData"
              :prop-user-id="modalData.userId"
              :prop-journey-id="modalData.journeyId"
              :prop-journey-name="modalData.journeyName"
              :prop-user-surname="modalData.userSurname"
              :prop-user-name="modalData.userName"
              :prop-user-email="modalData.userEmail"
              :prop-user-employee-no="modalData.userEmployeeNo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportJourneyUserJourneyInside from '../dashboard/ReportJourneyUserJourneyInside.vue';

export default {
  name: 'ModalJourneyUserJourneyInside', // bunu degısık veriyouz kı hangi sayfada oldugunmu zbellı olsu vue debugger lar b
  inject: ['$validator'],
  components: {
    ReportJourneyUserJourneyInside,
  },
  data() {
    return {
      isPending: false,
      formSending: false,

    };
  },

  watch: {
    errors: {
      handler(val) {
        const that = val;
        if (val.items === 0) {
          that.isValidator = false;
          that.formIsCompleted = false;
        } else {
          that.isValidator = true;
          that.formIsCompleted = true;
        }
      },
      deep: true,
    },
  },
  // /dashboard/journeys/users/:journeyId/user/:userId/:insideJourneyId' alında 2 dene gereklı bunlar ısın comsteics query: {journey:$route.query.journey, name: userName, surname:userSurname, employee_no: userEmployeeNo, email: userEmail}}"

  // ${userId}/${props.rowData.id}`, query: {journey:$route.query.journey, name: userName, surname:userSurname, employee_no: userEmployeeNo, email: userEmail}}"

  computed: {

    modalData() {
      return this.$store.state.modal.modalData;
    },

    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/toggleModal');
    },

  },
};
</script>
<style scoped>
  .modal-dialog {
    min-width: 670px;
    width: 900px;
    max-width: 90vw !important;
  }
  .modal-body {
    min-width: 670px;
    width: 900px;
    max-width: 90vw !important;
  }
</style>
