var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.tablePagination && _vm.lastPage > _vm.firstPage,
          expression: "tablePagination && lastPage > firstPage",
        },
      ],
      class: _vm.$_css.wrapperClass,
    },
    [
      _c(
        "a",
        {
          class: [
            "btn-nav",
            _vm.$_css.linkClass,
            _vm.isOnFirstPage ? _vm.$_css.disabledClass : "",
          ],
          on: {
            click: function ($event) {
              return _vm.loadPage(_vm.firstPage)
            },
          },
        },
        [
          _vm.$_css.icons.first != ""
            ? _c(
                "icon-container",
                {
                  class: [_vm.$_css.icons.first],
                  attrs: {
                    name: "angeldoubleleft",
                    "view-box": "0 0 448 512",
                    "is-icon-class": false,
                  },
                },
                [_c("icon-angeldoubleleft")],
                1
              )
            : _c("span", [_vm._v("«")]),
        ],
        1
      ),
      _c(
        "a",
        {
          class: [
            "btn-nav",
            _vm.$_css.linkClass,
            _vm.isOnFirstPage ? _vm.$_css.disabledClass : "",
          ],
          on: {
            click: function ($event) {
              return _vm.loadPage("prev")
            },
          },
        },
        [
          _vm.$_css.icons.next != ""
            ? _c(
                "icon-container",
                {
                  class: [_vm.$_css.icons.prev],
                  attrs: {
                    name: "angelleft",
                    "view-box": "0 0 256 512",
                    "is-icon-class": false,
                  },
                },
                [_c("icon-angelleft")],
                1
              )
            : _c("span", [_vm._v(" ‹")]),
        ],
        1
      ),
      _vm.notEnoughPages
        ? [
            _vm._l(_vm.totalPage, function (n, i) {
              return [
                _c("a", {
                  key: i,
                  class: [
                    _vm.$_css.pageClass,
                    _vm.isCurrentPage(i + _vm.firstPage)
                      ? _vm.$_css.activeClass
                      : "",
                  ],
                  domProps: { innerHTML: _vm._s(n) },
                  on: {
                    click: function ($event) {
                      return _vm.loadPage(i + _vm.firstPage)
                    },
                  },
                }),
              ]
            }),
          ]
        : [
            _vm._l(_vm.windowSize, function (n, i) {
              return [
                _c("a", {
                  key: i,
                  class: [
                    _vm.$_css.pageClass,
                    _vm.isCurrentPage(_vm.windowStart + i + _vm.firstPage - 1)
                      ? _vm.$_css.activeClass
                      : "",
                  ],
                  domProps: { innerHTML: _vm._s(_vm.windowStart + n - 1) },
                  on: {
                    click: function ($event) {
                      return _vm.loadPage(
                        _vm.windowStart + i + _vm.firstPage - 1
                      )
                    },
                  },
                }),
              ]
            }),
          ],
      _c(
        "a",
        {
          class: [
            "btn-nav",
            _vm.$_css.linkClass,
            _vm.isOnLastPage ? _vm.$_css.disabledClass : "",
          ],
          on: {
            click: function ($event) {
              return _vm.loadPage("next")
            },
          },
        },
        [
          _vm.$_css.icons.next != ""
            ? _c(
                "icon-container",
                {
                  class: [_vm.$_css.icons.next],
                  attrs: {
                    name: "angelright",
                    "view-box": "0 0 256 512",
                    "is-icon-class": false,
                  },
                },
                [_c("icon-angelright")],
                1
              )
            : _c("span", [_vm._v("› ")]),
        ],
        1
      ),
      _c(
        "a",
        {
          class: [
            "btn-nav",
            _vm.$_css.linkClass,
            _vm.isOnLastPage ? _vm.$_css.disabledClass : "",
          ],
          on: {
            click: function ($event) {
              return _vm.loadPage(_vm.lastPage)
            },
          },
        },
        [
          _vm.$_css.icons.last != ""
            ? _c(
                "icon-container",
                {
                  class: [_vm.$_css.icons.last],
                  attrs: {
                    name: "angeldoubleright",
                    "view-box": "0 0 448 512",
                    "is-icon-class": false,
                  },
                },
                [_c("icon-angeldoubleright")],
                1
              )
            : _c("span", [_vm._v("»")]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }