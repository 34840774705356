var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
      _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("header", { staticClass: "modal-header" }, [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.communicationTitle + "-" + _vm.$t("user_list")) +
                  " "
              ),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: { type: "button" },
              domProps: { innerHTML: _vm._s("&times;") },
              on: { click: _vm.closeModal },
            }),
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "page" }, [
                _c("div", { staticClass: "top-group" }, [
                  _c(
                    "div",
                    [
                      _c("filter-perpage", {
                        on: {
                          searchFor: _vm.setSearchFor,
                          perPage: _vm.setPerPage,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass:
                          "button button--secondary text-size-xs size-sm button--icon",
                        on: {
                          click: function ($event) {
                            return _vm.addCommunicationUserModal()
                          },
                        },
                      },
                      [
                        _c(
                          "icon-container",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              name: "plusfull",
                              "view-box": "0 0 448 512",
                              width: 15,
                              height: 16,
                              color: "#FFF",
                              "is-icon-class": false,
                            },
                          },
                          [_c("icon-plusfull")],
                          1
                        ),
                        _vm._v(" " + _vm._s(_vm.$t("add_new_user")) + " "),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "container" }, [
                  _c(
                    "div",
                    { staticClass: "table-responsive" },
                    [
                      _vm.tableIsLoading
                        ? _c("div", { staticClass: "lds-dual-ring" })
                        : _vm._e(),
                      _c("vue-table", {
                        ref: "myvuetable",
                        class: { "table-fade": _vm.tableIsLoading },
                        attrs: {
                          "api-url": _vm.apiUrl,
                          "http-fetch": _vm.myFetch,
                          "api-mode": true,
                          "pagination-path": "meta",
                          "http-method": "post",
                          "append-params": _vm.moreParams,
                          "http-options": {
                            headers: {
                              Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                            },
                          },
                          fields: _vm.fields,
                          "no-data-template": _vm.$t("no_data_info"),
                          css: _vm.css.table,
                          "per-page": _vm.perPage,
                          "query-params": _vm.makeQueryParams,
                        },
                        on: {
                          "vuetable:loading": function ($event) {
                            _vm.tableIsLoading = true
                          },
                          "vuetable:loaded": function ($event) {
                            _vm.tableIsLoading = false
                          },
                          "vuetable:load-error": _vm.vuetableLoadError,
                          "vuetable:pagination-data": _vm.onPaginationData,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "opened_at",
                            fn: function (props) {
                              return [
                                _c("div", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDate(props.rowData.opened_at)
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "email",
                            fn: function (props) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-inline-block text-truncate",
                                    staticStyle: { "max-width": "180px" },
                                    attrs: { title: props.rowData.email },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(props.rowData.email) + " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "actions",
                            fn: function (props) {
                              return [
                                _c(
                                  "button",
                                  {
                                    staticClass: "ui button delete",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onAction(
                                          "delete-item",
                                          props.rowData,
                                          props.rowIndex
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        attrs: {
                                          width: 20,
                                          height: 20,
                                          "view-box": "0 0 24 24",
                                          name: "trash",
                                          color: "#727d92",
                                        },
                                      },
                                      [_c("icon-trash")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c(
                        "div",
                        { staticClass: "pagination__info" },
                        [
                          _c("vuetable-pagination-info", {
                            ref: "paginationInfo",
                            class: { "table-fade": _vm.tableIsLoading },
                            attrs: {
                              "info-template": _vm.$t("pagination_info"),
                              "no-data-template": _vm.$t("no_data_info"),
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "pagination__items" },
                            [
                              _c("vuetable-pagination", {
                                ref: "pagination",
                                attrs: { css: _vm.css.pagination },
                                on: {
                                  "vuetable-pagination:change-page":
                                    _vm.onChangePage,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "button button--primary",
                  attrs: { type: "button" },
                  on: { click: _vm.closeModal },
                },
                [_c("span", [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")])]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }