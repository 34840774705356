<template>
  <div
    class="input-group"
    :id="`question-${id}`"
  >
    <BaseChoice
      v-for="option in options"
      :option="option.choice_text"
      :is-selected="option.is_selected"
      :is-multiple="isMultiple"
      :is-readonly="isReadonly"
      :id="option.id"
      :q-id="id"
      :key="`${id}-${option.id}-${option.is_selected}`"
      @selected="selected"
    />
    <label
      :for="`${id}_other`"
      v-if="hasOtherOption"
      class="single-choice"
      :disabled="isReadonly"
      :class="{'selected': hasOtherOption && hasOtherAnswer }"
    >
      <input
        :type="isMultiple ? 'checkbox': 'radio'"
        :value="hasOtherAnswer"
        :id="`${id}_other`"
        :disabled="isReadonly"
        :checked="hasOtherOption && hasOtherAnswer ? true:false"
        :name="`radio-input-${qId}`"
      >
      {{ hasOtherAnswer || $t('other') }}
    </label>
  </div>
</template>

<script>
import BaseChoice from './BaseChoice.vue';

export default {
  name: 'BaseRadionButtonGroup',
  components: {
    BaseChoice,
  },
  props: {
    options: {
      required: true,
      type: Array,
    },
    id: {
      required: true,
      type: Number,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    hasOtherOption: {
      type: [Boolean, Number],
      default: false,
    },
    hasOtherAnswer: {
      type: String,
      default: '',
    },
  },
  methods: {
    selected(id) {
      this.options.map((option) => {
        const $option = option;
        if ($option.id === Number(id)) {
          $option.is_selected = !$option.is_selected;
        } else if (!this.isMultiple) {
          $option.is_selected = false;
        }
        return $option;
      });
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.input-group {
  width: 100%;
  margin: 15px auto;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

label {
  cursor: pointer;
}

input[type="radio"]{
    visibility:hidden;
}
</style>
