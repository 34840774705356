var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "child-header-navigation": _vm.childHeaderNavigation,
            "is-child-header-left-actions": "",
            "add-journeygroup": "",
          },
        },
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
        ],
        1
      ),
      _vm.modal.modalVisible &&
      _vm.modal.modalType === "modalCreateJourneyGroup"
        ? _c("modal-create-journey-group")
        : _vm._e(),
      _vm.modal.modalVisible &&
      _vm.modal.modalType === "modalJourneyGroupAddJourneys"
        ? _c("modal-journey-group-add-journeys")
        : _vm._e(),
      _c("main", { staticClass: "page__body" }, [
        _vm.tableIsLoading
          ? _c("div", { staticClass: "lds-dual-ring" })
          : _vm._e(),
        _c("div", { staticClass: "container-fluid container-cards" }, [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "table-responsive" },
              [
                _c("vue-table", {
                  ref: "myvuetable",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "api-url": _vm.apiUrl,
                    "http-fetch": _vm.myFetch,
                    "api-mode": true,
                    "pagination-path": "meta",
                    "http-method": "get",
                    "append-params": _vm.moreParams,
                    "http-options": {
                      headers: {
                        Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                      },
                    },
                    fields: _vm.fields,
                    "no-data-template": _vm.$t("no_data_info"),
                    css: _vm.css.table,
                    "per-page": _vm.perPage,
                    "query-params": _vm.makeQueryParams,
                  },
                  on: {
                    "vuetable:loading": function ($event) {
                      _vm.tableIsLoading = true
                    },
                    "vuetable:loaded": function ($event) {
                      _vm.tableIsLoading = false
                    },
                    "vuetable:load-error": _vm.vuetableLoadError,
                    "vuetable:pagination-data": _vm.onPaginationData,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "name-slot",
                      fn: function (props) {
                        return [
                          _c("div", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.nameGenerated(props))),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "journey_count",
                      fn: function (props) {
                        return [
                          _c(
                            "span",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "journeyGroupjourney",
                                      params: {
                                        journeyGroupId: props.rowData.id,
                                        title: props.rowData.name,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(props.rowData.journey_count) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "action-slot",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "button-group center action-button",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onAction(
                                        "open-journey-group",
                                        props.rowData
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { cursor: "pointer" } },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: 16,
                                            height: 16,
                                            color: "#727d92",
                                            "view-box": "0 0 14 14",
                                          },
                                        },
                                        [_c("icon-search")],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown ml-2 mr-2",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onAction(
                                        "add-journey",
                                        props.rowData
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { cursor: "pointer" } },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: "18",
                                            height: "18",
                                            "view-box": "0 0 24 24",
                                            name: "cplus",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-circleplus")],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown",
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.dropdownToggle(
                                        `three${props.rowIndex}`
                                      )
                                    },
                                    mouseleave: _vm.hideDropdown,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "card--actions dropdown__toggle",
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: 20,
                                            height: 20,
                                            "view-box": "0 0 24 24",
                                            name: "threedots",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-threedots")],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.dropdownIndex === "three" + props.rowIndex
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "on-clickaway",
                                              rawName: "v-on-clickaway",
                                              value: _vm.hideDropdown,
                                              expression: "hideDropdown",
                                            },
                                          ],
                                          ref: `three${props.rowIndex}`,
                                          staticClass: "dropdown__menu",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dropdown__button button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onAction(
                                                    "edit-journey-group",
                                                    props.rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  attrs: {
                                                    width: 16,
                                                    height: 16,
                                                    "view-box": "0 0 20 20",
                                                    name: "edit",
                                                    color: "#727d92",
                                                  },
                                                },
                                                [_c("icon-edit")],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("edit")) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dropdown__button button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.requestAssignedUserReport(
                                                    props.rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  attrs: {
                                                    name: "content",
                                                    "view-box": "0 0 18 17",
                                                    "is-icon-class": false,
                                                  },
                                                },
                                                [_c("icon-dashboard")],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "request_assigned_user_report"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dropdown__button button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.exportContentListReport(
                                                    props.rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  attrs: {
                                                    name: "content",
                                                    "view-box": "0 0 15 14",
                                                    "is-icon-class": false,
                                                  },
                                                },
                                                [_c("icon-download")],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "download_journey_content_report"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dropdown__button button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onAction(
                                                    "delete-item",
                                                    props.rowData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "icon-container",
                                                {
                                                  attrs: {
                                                    width: 18,
                                                    height: 18,
                                                    "view-box": "0 0 24 24",
                                                    name: "trash",
                                                    color: "#727d92",
                                                  },
                                                },
                                                [_c("icon-trash")],
                                                1
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("delete")) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c(
                  "div",
                  { staticClass: "pagination__info" },
                  [
                    _c("vuetable-pagination-info", {
                      ref: "paginationInfo",
                      class: { "table-fade": _vm.tableIsLoading },
                      attrs: {
                        "info-template": _vm.$t("pagination_info"),
                        "no-data-template": _vm.$t("no_data_info"),
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "pagination__items" },
                      [
                        _c("vuetable-pagination", {
                          ref: "pagination",
                          attrs: { css: _vm.css.pagination },
                          on: {
                            "vuetable-pagination:change-page": _vm.onChangePage,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }