var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d: "M4 11v2h12l-5.5 5.5l1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5L16 11H4z",
      fill: "currentColor",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }