var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "g",
    {
      attrs: {
        fill: "none",
        "fill-rule": "evenodd",
        stroke: "currentColor",
        transform: "translate(1 1)",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "14", cy: "14", r: "2", "stroke-width": "2" },
      }),
      _c("rect", {
        attrs: {
          width: "24",
          height: "18",
          x: "8",
          y: "19",
          "stroke-width": "2",
          rx: "3",
        },
      }),
      _c("path", {
        attrs: {
          "stroke-linejoin": "round",
          "stroke-width": "2",
          d: "M32 26l5-5a1 1 0 012 1v14a1 1 0 01-2 0l-5-5h0v-5z",
        },
      }),
      _c("path", { attrs: { "stroke-width": "3", d: "M14 19v-3" } }),
      _c("path", {
        attrs: {
          "stroke-width": "2",
          d: "M8 13c0-3 2-5 5-5M0 13C1 4 5 0 13 0M4 13c1-6 4-9 9-9",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }