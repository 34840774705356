<template>
  <div class="page">
    <app-header
      child-header-class="container"
      is-child-header-left-actions
      :child-header-navigation="userSubNavigation"
      add-customer
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>

    <div class="container">
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="get"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="actions"
            slot-scope="props"
          >
            <button
              class="ui button edit"
              @click="onAction('edit-item', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                name="edit"
                color="#727d92"
              >
                <icon-edit />
              </icon-container>
            </button>
            <!-- <button
              class="ui button delete"
              @click="onAction('delete-item', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                name="trash"
                color="#727d92"
              >
                <icon-trash />
              </icon-container>
            </button> -->
          </template>
          <template
            slot="is_active"
            slot-scope="props"
          >
            <span :class="props.rowData.status ?'is-active': 'is-passive'">
              {{ props.rowData.status ? $t('active') : $t('passive') }}
            </span>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { mapState } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import IconEdit from '@/components/icons/Edit.vue';
// import IconTrash from '@/components/icons/Trash.vue';
import AppHeader from '@/components/AppHeader.vue';
import { API_GET_CUSTOMER_DATATABLES, API_CUSTOMER } from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import VuetablePagination from '../components/CustomVuetable/VuetablePagination.vue';

console.log('Customers');

export default {
  name: 'Customers',
  mixins: [myTableMixin],
  data() {
    return {

    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-edit': IconEdit,
    // 'icon-trash': IconTrash,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'Customers') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),

    apiUrl() {
      if (API_GET_CUSTOMER_DATATABLES) {
        return `${API_GET_CUSTOMER_DATATABLES}`;
      }
      return null;
    },

    refreshPage: {
      get() {
        return this.$store.state.modal.refreshPage;
      },
      set(newValue) {
        this.$store.state.modal.refreshPage = newValue;
      },
    },

    pageToRefresh: {
      get() {
        return this.$store.state.modal.pageToRefresh;
      },

      set(newValue) {
        this.$store.state.modal.pageToRefresh = newValue;
      },
    },
    userSubNavigation() {
      return [
        {
          text: this.$t('customers'),
          url: '/customers',
        },
        {
          text: this.$t('pins'),
          url: '/pins',
        },
      ];
    },
    fields() {
      return [{
        name: 'id',
        title: this.$t('id'),
        width: '5%',
      }, {
        name: 'name',
        title: this.$t('name'),
        sortField: 'name',
        width: '25%',
      },
      {
        name: 'contact_name',
        title: this.$t('contact_name'),
        sortField: 'contact_name',
        width: '25%',
      },
      {
        name: 'email',
        title: this.$t('email'),
        sortField: 'email',
        width: '30%',
      },
      {
        name: 'is_active',
        title: this.$t('status'),
        width: '10%',
      },
      {
        name: 'actions',
        title: this.$t('actions'),
        width: '15%',
        titleClass: 'center',
        dataClass: 'center',
      }];
    },
  },

  methods: {
    onAction(action, data) {
      const customerID = data.id;
      this.idHolder = customerID;
      this.apiUrlHolder = API_CUSTOMER;
      if (!customerID) {
        return;
      }
      if (action === 'delete-item' && !this.snotifyQueueList.includes(customerID)) {
        this.snotifyQueueList.push(customerID);
        this.$snotify.confirm(this.$t('customer_delete_msg_body', { name: data.name }), this.$t('customer_delete_msg_title'), {
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          buttons: [
            {
              text: this.$t('yes'),
              action: (toast) => {
                this.deleteConfirm(toast, this.idHolder, this.apiUrlHolder);
              },
              bold: false,
            },
            {
              text: this.$t('no'),
              action: (toast) => {
                this.deleteCancel(toast, this.idHolder);
              },
            },
          ],
        }).on('shown', (toast) => {
          this.toastHolder = toast;
          document.addEventListener('keydown', this.eventHandler, false);
        }).on('hidden', (toast) => {
          this.toastHolder = toast;
          document.removeEventListener('keydown', this.eventHandler, false);
        });
      } else if (action === 'edit-item') {
        const obj = {
          modalType: 'modalAddCustomer',
          customerIdToEdit: customerID,
          tabPane: 'mainTab',
          editMode: true,
        };
        this.$helpers.toggleModal(obj);
      }
    },
  },
};
</script>
