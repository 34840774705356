var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "thumbnail-wrapper", class: { disabled: _vm.isDisable } },
    [
      _vm.fileUrl
        ? _c(
            "div",
            { staticClass: "thumbnail-container" },
            [
              _vm.$helpers.isValidUrl(_vm.fileUrl)
                ? [
                    _c(
                      "div",
                      { staticClass: "file-container ellipsize-left" },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.fileUrl,
                              alt: "file",
                              target: "_blank",
                              download: _vm.fileUrl.substring(
                                _vm.fileUrl.lastIndexOf("/") + 1
                              ),
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.fileUrl.substring(
                                  _vm.fileUrl.lastIndexOf("/") + 1
                                )
                              ) + " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "file-container ellipsize-left" },
                      [_vm._v(" " + _vm._s(_vm.fileUrl) + " ")]
                    ),
                  ],
            ],
            2
          )
        : _vm._e(),
      _vm.fileUrl
        ? _c(
            "button",
            {
              staticClass: "js-media-delete",
              attrs: { disabled: _vm.isDisable },
              on: { click: _vm.previewRemoved },
            },
            [
              _c(
                "icon-container",
                {
                  attrs: {
                    width: 18,
                    height: 18,
                    "view-box": "0 0 25 25",
                    color: "#727d92",
                    name: "trash",
                  },
                },
                [_c("icon-trash")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.fileUrl
        ? _c("div", { staticClass: "file-upload-file" }, [
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.veeRules,
                  expression: "veeRules",
                },
              ],
              class: { "is-danger": _vm.errors.has(`${_vm.veeField}`) },
              attrs: {
                type: "file",
                "data-vv-scope": _vm.veeScoopName,
                "data-vv-name": _vm.veeNameIs,
                "data-vv-as": _vm.veeAs ? _vm.veeAs : _vm.defaultAs,
                name: _vm.veeName ? _vm.veeName : _vm.defaultName,
                multiple: _vm.isMultiple,
                accept: _vm.isPdf
                  ? ".pdf"
                  : ".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.zip,image/*",
                disabled: _vm.isDisable,
              },
              domProps: { value: _vm.fileUrl },
              on: { change: _vm.fileUploaded },
            }),
            _c("span", { staticClass: "media-object media-object--row" }, [
              _c(
                "span",
                { staticClass: "media-object__media" },
                [
                  _c(
                    "icon-container",
                    {
                      attrs: {
                        name: "circleplusfull",
                        "view-box": "0 0 512 512",
                        width: 25,
                        height: 25,
                        "is-icon-class": false,
                      },
                    },
                    [_c("icon-circleplusfull")],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("span", { staticClass: "upload-file" }, [
              _vm._v(
                _vm._s(
                  _vm.isPdf ? _vm.$t("pdf_file_upload") : _vm.$t("file_upload")
                )
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }