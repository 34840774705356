import moment from 'moment';

export default class MyTimer {
  constructor() {
    this.start = moment();
    this.lapses = [];
    this.lapTimes = [];
    this.total = null;
    this.end = null;
    this.totalms = null;
  }

  static msToTime(duration) {
    let seconds = parseInt((duration / 1000) % 60, 10);
    let minutes = parseInt((duration / (1000 * 60)) % 60, 10);
    let hours = parseInt((duration / (1000 * 60 * 60)) % 24, 10);
    hours = (hours < 10) ? `0${hours}` : hours;
    minutes = (minutes < 10) ? `0${minutes}` : minutes;
    seconds = (seconds < 10) ? `0${seconds}` : seconds;
    return `${hours}:${minutes}:${seconds}`;
  }

  lapse() {
    const lap = moment();
    const time = this.diff(lap);
    if (time) {
      this.lapses.push(time);
      this.lapTimes.push(lap);
    } else {
      const xlap = moment().add(1, 'seconds');
      const xtime = this.diff(xlap);
      this.lapses.push(xtime);
      this.lapTimes.push(xlap);
    }
  }

  diff(lap) {
    let duration = null;
    if (this.lapTimes && this.lapTimes.length > 0) {
      duration = lap.diff(this.lapTimes[this.lapTimes.length - 1]);
    } else {
      duration = lap.diff(this.start);
    }
    if (duration) {
      const time = MyTimer.msToTime(duration);
      return time;
    }
    return false;
  }

  ended() {
    this.lapse();
    this.end = moment();
    const duration = this.end.diff(this.start);
    this.totalms = duration;
    this.total = MyTimer.msToTime(duration);
  }

  getTotalTime() {
    if (this.total) return this.total;
    return 'Make sure you ended the time with MyTimer.end()';
  }

  getTotalMsTime() {
    if (this.totalms) return this.totalms;
    return 'Make sure you ended the time with MyTimer.end()';
  }

  getLapsTime() {
    if (this.lapses) return this.lapses;
    return 'Make sure you lap the time with MyTimer.lapse()';
  }
}
