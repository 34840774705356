var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "currentColor" } }, [
    _c("path", {
      attrs: {
        fill: "currentColor",
        d: "M408 184H272c-4 0-8-4-8-8V40a40 40 0 00-80 0v136c0 4-4 8-8 8H40a40 40 0 000 80h136c4 0 8 4 8 8v136a40 40 0 0080 0V272c0-4 4-8 8-8h136a40 40 0 000-80zm0 0",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }