var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      { staticClass: "modal", class: { "is-loading": _vm.formSending } },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(" " + _vm._s(_vm.$t("add_more_to_journey_group")) + " "),
              ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "tab-content" }, [
                _c("div", { staticClass: "tab-pane" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 my-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "journey" },
                                },
                                [_vm._v(_vm._s(_vm.$t("journeys")))]
                              ),
                              _c("vue-tags-input", {
                                class: {
                                  "is-danger": _vm.errors.has("add.journey"),
                                },
                                attrs: {
                                  tags: _vm.journeys,
                                  "autocomplete-items": _vm.journeysToAdd,
                                  "add-only-from-autocomplete": true,
                                  "delete-on-backspace": false,
                                  placeholder: _vm.$t("addjourney_placeholder"),
                                },
                                on: {
                                  "tags-changed": _vm.inputUpdateJourneys,
                                  "before-adding-tag": _vm.journeyTagAdded,
                                  "before-deleting-tag": _vm.journeyTagDeleted,
                                },
                                model: {
                                  value: _vm.journey,
                                  callback: function ($$v) {
                                    _vm.journey = $$v
                                  },
                                  expression: "journey",
                                },
                              }),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.journeysSelected,
                                    expression: "journeysSelected",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "",
                                    expression: "''",
                                  },
                                ],
                                attrs: {
                                  type: "hidden",
                                  "data-vv-scope": "add",
                                  "data-vv-name": "journey",
                                  "data-vv-as": _vm.$t("journeys"),
                                },
                                domProps: { value: _vm.journeysSelected },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.journeysSelected = $event.target.value
                                  },
                                },
                              }),
                              _vm.errors.has("add.journey")
                                ? _c(
                                    "span",
                                    { staticClass: "help is-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("add.journey"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.items.length > 0,
                          expression: "errors.items.length > 0",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "alert alert--card alert--error" },
                        [
                          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function (error, index) {
                              return _c("li", { key: index }, [
                                _vm._v(" " + _vm._s(error.msg) + " "),
                              ])
                            }),
                            0
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button button--primary no-wrap",
                        attrs: { type: "button", disabled: _vm.isPending },
                        on: { click: _vm.closeModal },
                      },
                      [
                        !_vm.isPending
                          ? _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("close")) + " "),
                            ])
                          : _vm._e(),
                        _vm.isPending
                          ? _c("div", { staticClass: "lds-ellipsis" }, [
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }