<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      :class="{'is-loading': formSending }"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ $t('send_notification') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="form form--horizontal form--strong">
              <div class="form-group row">
                <div class="col-12 col-md-4">
                  <label
                    for="notificationHeading"
                    class="form-label"
                  >{{ $t('notification_heading') }}</label>
                </div>
                <div class="col-12 col-md-8">
                  <input
                    :class="{ 'is-danger': errors.has('notification.heading') }"
                    type="text"
                    class="form-control"
                    id="notificationHeading"
                    v-model="notificationHeading"
                    data-vv-scope="notification"
                    data-vv-name="heading"
                    :data-vv-as="$t('notification_heading')"
                    v-validate="'required|max:40'"
                  ><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                  <button
                    class="emoji-button"
                    @click="dropdownToggle('head')"
                    v-html="'☺'"
                  />
                  <div
                    class="dropdown"
                    style="z-index: 1000001"
                    v-if="dropdownIndex === 'head'"
                    v-on-clickaway="hideDropdown"
                  >
                    <div
                      class="dropdown__menu emoji-dropdown-title"
                      ref="dropdownBoxhead"
                    >
                      <VEmojiPicker
                        :pack="emojiPack"
                        :label-search="$t('search_placeholder')"
                        @select="SelectEmojiForHeading"
                      />
                    </div>
                  </div>
                  <span
                    v-if="errors.has('notification.heading')"
                    class="help is-danger"
                  >{{ errors.first('notification.heading') }}</span>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-12 col-md-4">
                  <label
                    for="contentTitle"
                    class="form-label"
                  >{{ $t('notification_body') }}</label>
                </div>
                <div class="col-12 col-md-8">
                  <textarea
                    :class="{ 'is-danger': errors.has('notification.body') }"
                    class="form-control"
                    id="notificationBody"
                    v-model="notificationBody"
                    data-vv-scope="notification"
                    data-vv-name="body"
                    :data-vv-as="$t('journey_name')"
                    v-validate="'required|max:110'"
                    rows="20"
                  /><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                  <button
                    class="emoji-button"
                    @click="dropdownToggle('body')"
                    v-html="'☺'"
                  />
                  <div
                    class="dropdown"
                    style="z-index: 1000001"
                    v-if="dropdownIndex === 'body'"
                    v-on-clickaway="hideDropdown"
                  >
                    <div
                      class="dropdown__menu emoji-dropdown-body"
                      ref="dropdownBoxbody"
                    >
                      <VEmojiPicker
                        :pack="emojiPack"
                        :label-search="$t('search_placeholder')"
                        @select="SelectEmojiForBody"
                      />
                    </div>
                  </div>
                  <span
                    v-if="errors.has('notification.body')"
                    class="help is-danger"
                  >{{ errors.first('notification.body') }}</span>
                </div>
              </div>
              <div
                class="form-group"
                v-show="errors.items.length > 0"
              >
                <div class="alert alert--card alert--error">
                  <p>{{ $t('error_msg_title') }}</p>
                  <ul>
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                    >
                      {{ error.msg }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="button button--primary"
                  :disabled="isPending"
                  @click="sendNotification()"
                >
                  <span
                    v-if="!isPending"
                  >
                    {{ $t('send') }}
                  </span>
                  <div
                    class="lds-ellipsis"
                    v-if="isPending"
                  >
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VEmojiPicker from 'v-emoji-picker';
import packData from 'v-emoji-picker/data/emojis';
import { directive as onClickaway } from 'vue-clickaway';

export default {
  name: 'ModalNotification',
  inject: ['$validator'],
  components: {
    VEmojiPicker,
  },
  directives: {
    'on-clickaway': onClickaway,
  },
  data() {
    return {
      isPending: false,
      formSending: false,
      isValidator: true,
      notificationHeading: '',
      notificationBody: '',
      emojiPack: packData,
      dropdownIndex: null,
    };
  },

  watch: {
    errors: {
      handler(val) {
        const that = val;
        if (val.items === 0) {
          that.isValidator = false;
          that.formIsCompleted = false;
        } else {
          that.isValidator = true;
          that.formIsCompleted = true;
        }
      },
      deep: true,
    },
  },

  computed: {
    journeyId() {
      return this.$store.state.modal.journeyId;
    },

    userId() {
      return this.$store.state.modal.userId;
    },

    groupId() {
      return this.$store.state.modal.groupId;
    },
    journeyItemId() {
      return this.$store.state.modal.journeyItemId;
    },
    modalData() {
      return this.$store.state.modal.modalData;
    },

    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/toggleModal');
    },
    hideDropdown() {
      this.dropdownIndex = null;
    },
    SelectEmojiForHeading(dataEmoji) {
      this.notificationHeading += dataEmoji.emoji;
    },
    SelectEmojiForBody(dataEmoji) {
      this.notificationBody += dataEmoji.emoji;
    },

    dropdownToggle(index) {
      clearTimeout(this.timer);
      this.dropdownIndex = index;

      setTimeout(() => {
        if (this.dropdownIndex && this.$refs[`dropdownBox${index}`]) {
          const elem = this.$refs[`dropdownBox${index}`];
          if (elem) {
            const isOut = this.$helpers.isOutOfScreen(elem);
            if (isOut.right) {
              elem.classList.add('right');
            } else if (isOut.left) {
              elem.classList.add('left');
            } else if (isOut.bottom) {
              elem.classList.add('bottom');
            } else if (isOut.top) {
              elem.classList.add('top');
            }
          }
        }
      }, 50);
    },

    async checkValidation() {
      let allIsValid = false;
      await this.$validator.validateAll('notification').then((res) => {
        allIsValid = res;
      });
      if (allIsValid) {
        return true;
      }
      return false;
    },

    sendNotification() {
      this.checkValidation().then((res) => {
        if (res) {
          const self = this;
          self.formSending = true;
          self.isPending = true;
          const successMsg = this.$t('sent_success');
          self.$store.dispatch('modal/SEND_NOTIFICATION', self.$data).then(() => {
            if (!self.formError) {
              self.$snotify.success(successMsg);
            } else {
              self.formError = false;
              self.formSending = false;
              self.isPending = false;
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.emoji-button {
  position: absolute;
  display: inline-flex;
  font-size: 1.2em;
  color: #727d92;
}
::v-deep #Emojis div.container-emoji div.grid-emojis span.emoji {
  display: inline-table!important;
}
.emoji-dropdown-title {
  transform: scale(0.8);
  top: -60px;
}
.emoji-dropdown-body {
  transform: scale(0.8);
  top: -100px;
}
</style>
