<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{'is-loading': formSending }"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ $t('add_more_to_journey_group') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="tab-content">
              <div class="tab-pane">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12 my-4">
                        <div class="form-group">
                          <label
                            for="journey"
                            class="form-label"
                          >{{ $t('journeys') }}</label>
                          <vue-tags-input
                            :class="{ 'is-danger': errors.has('add.journey') }"
                            v-model="journey"
                            :tags="journeys"
                            @tags-changed="inputUpdateJourneys"
                            :autocomplete-items="journeysToAdd"
                            :add-only-from-autocomplete="true"
                            @before-adding-tag="journeyTagAdded"
                            @before-deleting-tag="journeyTagDeleted"
                            :delete-on-backspace="false"
                            :placeholder="$t('addjourney_placeholder')"
                          />
                          <input
                            type="hidden"
                            data-vv-scope="add"
                            data-vv-name="journey"
                            :data-vv-as="$t('journeys')"
                            v-model="journeysSelected"
                            v-validate="''"
                          >
                          <span
                            v-if="errors.has('add.journey')"
                            class="help is-danger"
                          >{{ errors.first('add.journey') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t('error_msg_title') }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary no-wrap"
                    @click="closeModal"
                    :disabled="isPending"
                  >
                    <span
                      v-if="!isPending"
                    >
                      {{ $t('close') }}
                    </span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import vueTagsInput from '@johmun/vue-tags-input';
import moment from 'moment';
import API from '@/services/';

import {
  API_JOURNEY_CATALOG,
  API_JOURNEY_GROUP_JOURNEYS,
  API_JOURNEY_GROUP,
  API_SERVER_TIME_FORMAT,
} from '@/helpers/config';

export default {
  name: 'ModalAddMoreToJourneyGroup',
  inject: ['$validator'],

  data() {
    return {
      isPending: false,
      formSending: false,
      journeygroupName: '',
      snotifyQueueList: [],
      journeysToAdd: [],
      idHolder: null,
      journey: '',
      journeys: [],
      debounce: null,
      toastHolder: null,
      eventHolder: null,
      journeysSelected: null,
    };
  },

  components: {
    vueTagsInput,
  },

  watch: {
    journey: 'getJourneys',
    journeys() {
      if (this.journeys && this.journeys.length > 0) {
        this.journeysSelected = this.journeys[0].text;
      } else {
        this.journeysSelected = null;
      }
      this.$nextTick(() => {
        this.$validator.validate('add.journey', this.journeysSelected);
      });
    },
  },

  computed: {
    journeyGroupId() {
      return this.$store.state.modal.journeyGroupId;
    },
    customerId() {
      return this.$store.state.auth.customerId;
    },
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
    dateFormat() {
      if (this.userLang === 'en') {
        return 'MM.DD.YYYY';
      }
      if (this.userLang === 'tr') {
        return 'DD.MM.YYYY';
      }
      return 'MM.DD.YYYY';
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },

  created() {
    API.get(`${API_JOURNEY_GROUP}/${this.journeyGroupId}`).then((response) => {
      this.journeygroupName = response.data.name;
    })
      .catch((error) => {
        this.$helpers.displayError(error);
      });
    API.get(`${API_JOURNEY_GROUP_JOURNEYS}/${this.journeyGroupId}?page=1&per_page=1000`).then((response) => {
      this.journeys = response.data.data.map(item => ({ text: item.name, id: item.id, preDefined: true }));
    })
      .catch((error) => {
        this.$helpers.displayError(error);
      });
  },

  methods: {

    closeModal() {
      this.$store.commit('modal/toggleModal');
      this.$store.commit('modal/REFRESH_PAGE', true);
    },

    journeyTagAdded(event) {
      if (event.tag.id) {
        event.addTag();
        const formData = new FormData();
        formData.append('journey_id', event.tag.id);
        formData.append('group_id', this.journeyGroupId);
        API.post(API_JOURNEY_GROUP_JOURNEYS, formData)
          .then((response) => {
            if (response.status === 201) {
              console.log('Successfully added');
            }
          })
          .catch((error) => {
            this.$helpers.displayError(error);
          });
      }
    },

    journeyTagDeleted(event) {
      if (event.tag.id) {
        const { id, text } = event.tag;
        this.eventHolder = event;
        this.idHolder = id;
        if (!this.snotifyQueueList.includes(id)) {
          this.snotifyQueueList.push(id);
          this.$snotify
            .confirm(
              this.$t('journey_group_remove_msg_body', { name: text }),
              this.$t('journey_delete_msg_title'),
              {
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                buttons: [
                  {
                    text: this.$t('yes'),
                    action: (toast) => {
                      this.deleteConfirm(toast, id, event);
                    },
                    bold: false,
                  },
                  {
                    text: this.$t('no'),
                    action: (toast) => {
                      this.deleteCancel(toast, id);
                    },
                  },
                ],
              },
            )
            .on('shown', (toast) => {
              this.toastHolder = toast;
              document.addEventListener('keydown', this.eventHandler, false);
            })
            .on('hidden', (toast) => {
              this.toastHolder = toast;
              document.removeEventListener('keydown', this.eventHandler, false);
            });
        }
      }
    },

    deleteConfirm(toast, id, event) {
      this.$snotify.remove(toast.id);
      const snotifyIndex = this.snotifyQueueList.indexOf(id);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      event.deleteTag();
      this.deletePreassignedJourney(id);
    },

    deleteCancel(toast, id) {
      const snotifyIndex = this.snotifyQueueList.indexOf(id);
      if (snotifyIndex > -1) {
        this.snotifyQueueList.splice(snotifyIndex, 1);
      }
      this.$snotify.remove(toast.id);
    },

    eventHandler(event) {
      event.preventDefault();
      const key = event.key || event.keyCode;
      if (key === 'Enter' || key === 13 || key === 'Y' || key === 'E' || key === 'e' || key === 'y') {
        this.deleteConfirm(this.toastHolder, this.idHolder, this.eventHolder);
      } else if (key === 'Escape' || key === 'Esc' || key === 27 || key === 'N' || key === 'H' || key === 'n' || key === 'h') {
        this.deleteCancel(this.toastHolder, this.idHolder);
      }
    },

    deletePreassignedJourney(journeyId) {
      API.delete(`${API_JOURNEY_GROUP_JOURNEYS}`, {
        data: {
          journey_id: journeyId,
          group_id: this.journeyGroupId,
        },
      })
        .then((response) => {
          if (response.status === 201) {
            console.log('Successfully deleted');
          }
        })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },

    inputUpdateJourneys(newTags) {
      this.journeys = newTags;
    },

    getJourneys() {
      this.journeysToAdd = [];
      const formData = { params: {} };
      formData.params.per_page = 10000;
      formData.params.search = this.journey;
      formData.params.customer_id = this.customerId;

      if (this.journey.length < 2) {
        return;
      }
      const searchInput = document.querySelector('.ti-new-tag-input');
      searchInput.classList.add('loading-icon');
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        API.get(API_JOURNEY_CATALOG, formData)
          .then((response) => {
            this.journeysToAdd = response.data.data.map(a => (
              {
                text: `${a.id}: ${a.name} - [${moment(a.activation_date, API_SERVER_TIME_FORMAT).format(this.dateFormat)}]`,
                id: `${a.id}`,
              }
            ));
            searchInput.classList.remove('loading-icon');
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            searchInput.classList.remove('loading-icon');
          });
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check {
  .form-label {
    font-size: 0.9rem;
    margin-left: 1rem;
    margin-bottom: 0;
    color: #142b58;
  }
}
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}
.modal-body {
  h4 {
    color: #727d92;
  }
}
.blur {
  filter: blur(3px);
}
.no-wrap {
  white-space: nowrap;
}
.thumbnail-wrapper {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  align-content: center;
}
::v-deep .ti-new-tag-input-wrapper {
  font-size: 1em!important;
}
</style>
