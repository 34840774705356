var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      !_vm.isSubComponentMode
        ? _c(
            "app-header",
            {
              attrs: {
                "child-header-class": "container",
                "child-header-navigation": _vm.childHeaderNavigation,
                "is-child-header-left-actions": "",
                "add-journey-user": "",
              },
            },
            [
              _c("filter-perpage", {
                on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
              }),
            ],
            1
          )
        : [
            _c("div", { staticClass: "top-group" }, [
              _c(
                "div",
                [
                  _c("filter-perpage", {
                    on: {
                      searchFor: _vm.setSearchFor,
                      perPage: _vm.setPerPage,
                    },
                  }),
                ],
                1
              ),
              _c("div", [
                _vm.isSubComponentMode
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "button button--secondary text-size-xs size-sm button--icon",
                        on: {
                          click: function ($event) {
                            return _vm.addJourneyUserModal()
                          },
                        },
                      },
                      [
                        _c(
                          "icon-container",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              name: "plusfull",
                              "view-box": "0 0 448 512",
                              width: 15,
                              height: 16,
                              color: "#FFF",
                              "is-icon-class": false,
                            },
                          },
                          [_c("icon-plusfull")],
                          1
                        ),
                        _vm._v(" " + _vm._s(_vm.$t("add_new_user")) + " "),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ],
      _c("div", { staticClass: "container" }, [
        !_vm.isSubComponentMode
          ? _c("div", { staticClass: "journey-users--title" }, [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goBack()
                    },
                  },
                },
                [
                  _c(
                    "icon-container",
                    {
                      attrs: {
                        width: 40,
                        height: 18,
                        name: "Go Back",
                        color: "#727d92",
                      },
                    },
                    [_c("icon-goback")],
                    1
                  ),
                ],
                1
              ),
              _vm._v(_vm._s(_vm.journeyName)),
              _c("small", [_vm._v(_vm._s(_vm.$t("user_list")))]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _vm.tableIsLoading
              ? _c("div", { staticClass: "lds-dual-ring" })
              : _vm._e(),
            _c("vue-table", {
              ref: "myvuetable",
              class: { "table-fade": _vm.tableIsLoading },
              attrs: {
                "api-url": _vm.apiUrl,
                "http-fetch": _vm.myFetch,
                "api-mode": true,
                "pagination-path": "meta",
                "http-method": "get",
                "append-params": _vm.moreParams,
                "http-options": {
                  headers: {
                    Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                  },
                },
                fields: _vm.fields,
                "no-data-template": _vm.$t("no_data_info"),
                css: _vm.css.table,
                "per-page": _vm.perPage,
                "query-params": _vm.makeQueryParams,
              },
              on: {
                "vuetable:loading": function ($event) {
                  _vm.tableIsLoading = true
                },
                "vuetable:loaded": function ($event) {
                  _vm.tableIsLoading = false
                },
                "vuetable:load-error": _vm.vuetableLoadError,
                "vuetable:pagination-data": _vm.onPaginationData,
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "ui button",
                          on: {
                            click: function ($event) {
                              return _vm.getMobilStatus(
                                props.rowData.id,
                                `ref${props.rowData.id}`
                              )
                            },
                            blur: function ($event) {
                              return _vm.closeTooltip(`ref${props.rowData.id}`)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              class: {
                                disabled:
                                  !props.rowData.has_mobile_device_regitered,
                              },
                              attrs: {
                                width: 13,
                                height: 20,
                                name: "cellphone",
                                "view-box": "0 0 320 512",
                                color: props.rowData.has_mobile_device_regitered
                                  ? "rgb(8, 206, 25)"
                                  : "#727d92",
                              },
                            },
                            [_c("icon-cellphone")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          ref: `ref${props.rowData.id}`,
                          staticClass: "content-tooltip",
                        },
                        [
                          _c("pre", [
                            _vm._v(
                              _vm._s(_vm.tooltipText ? _vm.tooltipText : "⏳")
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "email",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "d-inline-block text-truncate",
                          staticStyle: { "max-width": "180px" },
                          attrs: { title: props.rowData.email },
                        },
                        [_vm._v(" " + _vm._s(props.rowData.email) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "user_completion_rate",
                  fn: function (props) {
                    return [
                      _c("div", { staticClass: "progress" }, [
                        _c(
                          "div",
                          {
                            staticClass: "progress-bar",
                            style:
                              props.rowData.user_completion_rate === 0
                                ? "width:" +
                                  props.rowData.user_completion_rate +
                                  "%;background-color:transparent;color:#000"
                                : "width:" +
                                  props.rowData.user_completion_rate +
                                  "%;color:#fff",
                            attrs: {
                              role: "progressbar",
                              "aria-valuenow":
                                props.rowData.user_completion_rate,
                              "aria-valuemin": "0",
                              "aria-valuemax": "100",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  "‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎" +
                                    parseFloat(
                                      props.rowData.user_completion_rate
                                    ).toFixed(0) +
                                    "%"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "actions",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "ui button",
                          on: {
                            click: function ($event) {
                              return _vm.onAction("edit-item", props.rowData)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 20,
                                height: 20,
                                "view-box": "0 0 24 24",
                                name: "edit",
                                color: "#727d92",
                              },
                            },
                            [_c("icon-edit")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "ui button",
                          on: {
                            click: function ($event) {
                              return _vm.onAction("user-detail", props.rowData)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                name: "search",
                                width: 20,
                                height: 20,
                                color: "#727d92",
                                "view-box": "0 0 15 15",
                                "is-icon-class": false,
                              },
                            },
                            [_c("icon-search")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "ui button delete",
                          on: {
                            click: function ($event) {
                              return _vm.onAction(
                                "delete-item",
                                props.rowData,
                                props.rowIndex
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 20,
                                height: 20,
                                "view-box": "0 0 24 24",
                                name: "trash",
                                color: "#727d92",
                              },
                            },
                            [_c("icon-trash")],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              { staticClass: "pagination__info" },
              [
                _c("vuetable-pagination-info", {
                  ref: "paginationInfo",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "info-template": _vm.$t("pagination_info"),
                    "no-data-template": _vm.$t("no_data_info"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "pagination__items" },
                  [
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }