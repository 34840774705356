<template>
  <div
    class="tab-pane show active"
  >
    <div
      class="form-group"
      :class="{ 'is-danger': errors.has('snotification.ntitle') }"
    >
      <label
        for="ntitle"
        class="form-label"
      >{{ $t('snotification_title') }}</label>
      <input
        type="text"
        class="form-control"
        v-model="ntitle"
        :disabled="disabled"
        data-vv-scope="snotification"
        data-vv-name="ntitle"
        :data-vv-as="$t('snotification_title')"
        v-validate.persist="'required|max:40'"
        @input="handleTitle"
      ><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
      <button
        class="emoji-button"
        @click="dropdownToggle('title')"
        v-html="'☺'"
      />
      <div
        class="dropdown"
        style="z-index: 1000001"
        v-if="dropdownIndex === 'title'"
        v-on-clickaway="hideDropdown"
      >
        <div
          class="dropdown__menu emoji-dropdown-title"
          ref="dropdownBoxtitle"
        >
          <VEmojiPicker
            class="emoji"
            :pack="emojiPack"
            :label-search="$t('search_placeholder')"
            @select="SelectEmojiForTitle"
          />
        </div>
      </div>
      <span
        v-show="errors.has('snotification.ntitle')"
        class="help is-danger"
      >{{ errors.first('snotification.ntitle') }}</span>
    </div>
    <div
      class="form-group"
      :class="{ 'is-danger': errors.has('snotification.nbody') }"
    >
      <label
        for="nbody"
        class="form-label"
      >{{ $t('snotification_body') }}</label>
      <textarea
        id="body"
        class="form-control"
        maxlength="110"
        :disabled="disabled"
        data-vv-scope="snotification"
        data-vv-name="nbody"
        :data-vv-as="$t('snotification_body')"
        v-validate.persist="'required|max:110'"
        v-model="nbody"
        @input="handleBody"
      /><!-- eslint-disable-next-line vue-i18n/no-raw-text -->
      <button
        class="emoji-button"
        @click="dropdownToggle('body')"
        v-html="'☺'"
      />
      <div
        class="dropdown"
        style="z-index: 1000001"
        v-if="dropdownIndex === 'body'"
        v-on-clickaway="hideDropdown"
      >
        <div
          class="dropdown__menu emoji-dropdown-body"
          ref="dropdownBoxbody"
        >
          <VEmojiPicker
            class="emoji"
            :pack="emojiPack"
            :label-search="$t('search_placeholder')"
            @select="SelectEmojiForBody"
          />
        </div>
      </div>
      <span
        v-show="errors.has('snotification.nbody')"
        class="help is-danger"
      >{{ errors.first('snotification.nbody') }}</span>
    </div>
    <div
      class="form-group"
      :class="{ 'is-danger': errors.has('snotification.journeyItem') }"
    >
      <label
        for="nbody"
        class="form-label"
      >{{ $t('journey_item') }}</label>
      <multi-select
        :class="{ 'is-danger': errors.has('snotification.journeyItem') }"
        track-by="name"
        label="name"
        :searchable="false"
        :close-on-select="true"
        :show-labels="false"
        v-model="journeyItem"
        :options="journeyItems"
        :placeholder="$t('select_placeholder')"
        class="selectbox selectbox--secondary"
        v-validate="'required'"
        data-vv-name="journeyItem"
        data-vv-scope="snotification"
        :data-vv-as="$t('journey_item')"
      />
      <span
        v-show="errors.has('snotification.journeyItem')"
        class="help is-danger"
      >{{ errors.first('snotification.journeyItem') }}</span>
    </div>
    <div
      v-show="errors.items.length > 0 && (errors.has('snotification.ntitle') || errors.has('snotification.nbody') || errors.has('snotification.journeyItem'))"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="button button--primary"
        @click="editMode ? updateContent() : createContent()"
        :disabled="isPending || disabled"
      >
        <span
          v-if="!isPending"
        >
          {{ editMode ? $t('update_button') : $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import VEmojiPicker from 'v-emoji-picker';
import packData from 'v-emoji-picker/data/emojis';
import { directive as onClickaway } from 'vue-clickaway';
import Multiselect from 'vue-multiselect';
import API from '@/services/';
import {
  API_JOURNEY_ITEM,
} from '@/helpers/config';

export default {
  name: 'NotificationContent',

  inject: ['$validator'],
  components: {
    VEmojiPicker,
    'multi-select': Multiselect,
  },
  directives: {
    'on-clickaway': onClickaway,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    nBody: {
      type: String,
      default: '',
    },
    nTitle: {
      type: String,
      default: '',
    },
    nJourneyId: {
      type: String,
      default: '',
    },
    nJourneyItemId: {
      type: [String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ntitle: '',
      nbody: '',
      journeyItem: null,
      journeyItems: [],
      listOfItems: null,
      isPending: false,
      emojiPack: packData,
      dropdownIndex: null,
    };
  },

  computed: {
    modalData() {
      return this.$store.state.modal.modalData;
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
    journeyId() {
      return this.$store.state.modal.journeyId;
    },
  },

  watch: {

    formError(newValue) {
      if (newValue) {
        this.isPending = false;
      }
    },

    nBody(newValue) {
      this.nbody = newValue;
    },

    nTitle(newValue) {
      this.ntitle = newValue;
    },

    journeyItem(newValue) {
      if (newValue && newValue.value) {
        this.$emit('setNJourneyItemId', newValue.value);
      }
    },

    listOfItems(newValue) {
      if (newValue.data) {
        this.journeyItems = [];
        console.log('newvalue', newValue.data);
        newValue.data.forEach((journey) => {
          this.journeyItems.push({ value: journey.id, name: journey.item.name });
        });
        if (this.editMode) {
          this.journeyItem = this.journeyItems.filter(item => item.value === this.nJourneyItemId);
        }
      }
    },
  },

  mounted() {
    this.getJourneyItems();
    this.clearErrorMsg();
  },

  activated() {
    this.getJourneyItems();
  },

  methods: {
    hideDropdown() {
      this.dropdownIndex = null;
    },
    SelectEmojiForTitle(dataEmoji) {
      this.ntitle += dataEmoji.emoji;
      this.handleTitle();
    },
    SelectEmojiForBody(dataEmoji) {
      this.nbody += dataEmoji.emoji;
      this.handleBody();
    },

    dropdownToggle(index) {
      clearTimeout(this.timer);
      this.dropdownIndex = index;
      setTimeout(() => {
        if (this.dropdownIndex && this.$refs[`dropdownBox${index}`]) {
          const elem = this.$refs[`dropdownBox${index}`];

          if (elem) {
            const isOut = this.$helpers.isOutOfScreen(elem);
            if (isOut.right) {
              elem.classList.add('right');
            } else if (isOut.left) {
              elem.classList.add('left');
            } else if (isOut.bottom) {
              elem.classList.add('bottomjourney');
            } else if (isOut.top) {
              elem.classList.add('top');
            }
          }
        }
      }, 50);
    },

    getJourneyItems() {
      if (this.journeyId) {
        API.get(`${API_JOURNEY_ITEM}/${this.journeyId}?per_page=1000`)
          .then((response) => {
            this.listOfItems = response.data;
          })
          .catch((error) => {
            this.$helpers.displayError(error);
          });
      }
    },

    handleTitle() {
      this.$emit('setNTitle', this.ntitle);
    },

    handleBody() {
      this.$emit('setNBody', this.nbody);
    },

    clearErrorMsg() {
      this.$validator.errors.clear();
    },

    async checkValidation() {
      let snotificationIsValid = false;
      this.clearErrorMsg();
      await this.$validator.validateAll('snotification').then((res) => {
        snotificationIsValid = res;
      });
      if (snotificationIsValid) {
        return true;
      }
      return false;
    },

    createContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('createContent');
          this.isPending = true;
        }
      });
    },

    updateContent() {
      this.checkValidation().then((res) => {
        if (res) {
          this.$emit('updateContent');
          this.isPending = true;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.is-danger {
  border-color: #f27299;
}
select {
  padding: 5px 35px 5px 5px;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.65573 6.82705L9.82101 2.48355C10.1532 2.1368 10.1636 1.56327 9.8445 1.20258C9.52527 0.841708 8.99728 0.830307 8.66523 1.17706L5.07792 4.91537L1.49062 1.17706C1.15906 0.829583 0.631237 0.839537 0.311177 1.1995C-0.00871563 1.55947 0.000448227 2.13299 0.331838 2.48047C0.332837 2.48156 0.333671 2.48265 0.33467 2.48355L4.49995 6.82705C4.66356 6.99627 4.88332 7.08621 5.10908 7.0759C5.31318 7.06848 5.50778 6.97998 5.65573 6.82705Z' fill='%23727D92'/%3E%3C/svg%3E") 96% / 2% no-repeat #fff;
}
.form-group {
  position: relative;
}

::v-deep #Emojis div.container-emoji div.grid-emojis span.emoji {
  display: inline-table!important;
}
.emoji-button {
  position: absolute;
  display: inline-flex;
  right: 0;
  padding-top: 2px;
  font-size: 1.2em;
  color: #727d92;
}
.emoji-dropdown-title {
  transform: scale(0.8);
  top: -70px;
}
.emoji-dropdown-body {
  transform: scale(0.8);
  top: -110px;
}
</style>
