<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{'is-loading': formSending }"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ reportModalTitle ? reportModalTitle : $t('create_journey_report') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="tab-content">
              <div class="tab-pane">
                <div class="form-group">
                  <label
                    for="report_name"
                    class="form-label"
                  >{{ $t('report_name') }}</label>
                  <input
                    :class="{ 'is-danger': errors.has('report.report_name') }"
                    type="text"
                    class="form-control"
                    id="report_name"
                    v-model="reportName"
                    :placeholder="$t('add_name_placeholder')"
                    data-vv-scope="report"
                    data-vv-name="report_name"
                    :data-vv-as="$t('report_name')"
                    v-validate="'required|min:2|max:1000'"
                  >
                  <span
                    v-show="errors.has('report.report_name')"
                    class="help is-danger"
                  >{{ errors.first('report.report_name') }}</span>
                </div>
                <div
                  class="form mt-2"
                  :style="((reportDownloadRequest && reportDownloadRequest === 'get') || omitDate) ? 'display: none;': ''"
                >
                  <div
                    :class="['panel-title', showAdvanceSettings ? 'active': '']"
                  >
                    <label @click="showAdvanceSettings = !showAdvanceSettings">{{ $t('advanced_tab') }}</label>
                  </div>
                  <collapse-transition
                    :duration="500"
                    dimension="height"
                  >
                    <div
                      class="form-group--light"
                      v-show="showAdvanceSettings"
                    >
                      <div class="warning-box">
                        <div class="desc-text">
                          <icon-container
                            width="27"
                            height="30"
                            view-box="0 0 27 30"
                            name="lamp"
                            color="#727d92"
                          >
                            <icon-lamp />
                          </icon-container>
                          <div class="description">
                            {{ (reportDownloadName === $t('lxp_report')) ? $t('report_download_lxp_remark'):$t('report_download_remark') }}
                          </div>
                        </div>
                      </div>

                      <div class="form-group d-flex">
                        <label
                          class="form-label"
                        >{{ $t('report_begin_time') }}
                          <date-picker
                            :class="{ 'is-danger': errors.has('report.beginDate') }"
                            v-model="beginActivationDateTime"
                            type="date"
                            ref="beginDate"
                            value-type="YYYY-MM-DD 00:00:00"
                            data-vv-scope="report"
                            data-vv-name="beginDate"
                            :data-vv-as="$t('report_begin_time')"
                            v-validate="'required|max1year:endDate'"
                            :first-day-of-week="userLang === 'en' ? 7 : 1"
                            :lang="userLang"
                            :format="userLang === 'en' ? 'MM.DD.YYYY' : 'DD.MM.YYYY'"
                            @change="clearAll()"
                          />
                          <span
                            v-if="errors.has('report.beginDate')"
                            class="help is-danger"
                          >{{ errors.first('report.beginDate') }}</span>
                        </label>
                        <label
                          v-if="reportDownloadHasEndDate"
                          class="form-label pl-4"
                        >{{ $t('report_end_time') }}
                          <date-picker
                            :class="{ 'is-danger': errors.has('report.endDate') }"
                            v-model="endActivationDateTime"
                            type="date"
                            value-type="YYYY-MM-DD 00:00:00"
                            data-vv-scope="report"
                            ref="endDate"
                            data-vv-name="endDate"
                            :data-vv-as="$t('report_end_time')"
                            v-validate.lazy="'required|max1year:beginDate'"
                            :first-day-of-week="userLang === 'en' ? 7 : 1"
                            :lang="userLang"
                            :format="userLang === 'en' ? 'MM.DD.YYYY' : 'DD.MM.YYYY'"
                            @change="clearAll()"
                          />
                          <span
                            v-if="errors.has('report.endDate')"
                            class="help is-danger"
                          >{{ errors.first('report.endDate') }}</span>
                        </label>
                      </div>
                      <div
                        class="form-group"
                        v-if="reportDownloadHasUserStatus"
                      >
                        <label
                          for="demoType"
                          class="form-label"
                        >{{ $t('user_status') }}</label>

                        <div class="d-flex align-items-center">
                          <label
                            class="check-button mr-4"
                            :class="{'active' : userStatus === 'active'}"
                          >
                            <input
                              type="radio"
                              v-model="userStatus"
                              value="active"
                            >
                            {{ $t('active_users') }}
                          </label>
                          <label
                            class="check-button mr-4"
                            :class="{'active' : userStatus === 'inactive'}"
                          >
                            <input
                              type="radio"
                              v-model="userStatus"
                              value="inactive"
                            >
                            {{ $t('inactive_users') }}
                          </label>
                          <label
                            class="check-button"
                            :class="{'active' : userStatus === 'all'}"
                          >
                            <input
                              type="radio"
                              v-model="userStatus"
                              value="all"
                            >
                            {{ $t('all_users') }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </collapse-transition>
                </div>
                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t('error_msg_title') }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary no-wrap"
                    @click="downloadReport()"
                    :disabled="isPending"
                  >
                    <span
                      v-if="!isPending"
                    >
                      {{ $t('create') }}
                    </span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import API from '@/services/';
import IconLamp from '@/components/icons/Lamp.vue';
import CollapseTransition from '@ivanv/vue-collapse-transition';
import IconContainer from '@/components/elements/Icon.vue';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import { saveAs } from 'file-saver';
import { Validator } from 'vee-validate';
import { i18n } from '@/helpers/i18n';

export default {
  name: 'ModalReportDownload',
  inject: ['$validator'],

  data() {
    return {
      isPending: false,
      formSending: false,
      reportName: '',
      userStatus: 'active',
      showAdvanceSettings: false,
      beginActivationDateTime: moment().subtract(3, 'month').set('date', 1).set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      })
        .format('YYYY-MM-DD HH:mm:ss'),
      endActivationDateTime: moment().set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      })
        .format('YYYY-MM-DD HH:mm:ss'),
      begin_date: null,
    };
  },

  components: {
    'collapse-transition': CollapseTransition,
    'icon-container': IconContainer,
    'icon-lamp': IconLamp,
    'date-picker': DatePicker,
  },
  watch: {

  },
  computed: {
    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
    reportModalTitle() {
      return this.$store.state.modal.reportModalTitle || this.$store.state.modal.reportDownloadName;
    },
    reportDownloadUrl() {
      return this.$store.state.modal.reportDownloadUrl;
    },
    reportDownloadPayload() {
      return this.$store.state.modal.reportDownloadPayload;
    },
    reportDownloadName() {
      return this.$store.state.modal.reportDownloadName;
    },
    dateOnly() {
      return this.$store.state.modal.dateOnly;
    },
    omitDate() {
      return this.$store.state.modal.omitDate;
    },
    reportDownloadRequest() {
      return this.$store.state.modal.reportDownloadRequest;
    },
    reportDownloadHasUserStatus() {
      return this.$store.state.modal.reportDownloadHasUserStatus;
    },
    reportDownloadHasEndDate() {
      return this.$store.state.modal.reportDownloadHasEndDate;
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },
  created() {
    if (this.reportDownloadName) {
      this.reportName = this.reportDownloadName;
    }
    Validator.extend('max1year', {
      getMessage() {
        return i18n.t('max1year_awswer');
      },
      validate(value, { targetValue }) {
        if (value && targetValue && targetValue !== 'beginDate' && targetValue !== 'endDate') {
          const endDate = moment(value, 'YYYY-MM-DD HH:mm:ss');
          const beginDate = moment(targetValue, 'YYYY-MM-DD HH:mm:ss');
          if (endDate.isValid() && beginDate.isValid()) {
            const days = Math.abs(endDate.diff(targetValue, 'days'));
            const years = Math.abs(endDate.diff(targetValue, 'years'));
            if (days > 0 && years > 0) {
              return false;
            }
            return true;
          }
        }
        return true;
      },
      options: {
        hasTarget: true,
        isDate: true,
      },
      paramNames: ['targetValue'],
    });
  },

  beforeDestroy() {
    this.resetDownloadReportParams();
  },

  methods: {

    ...mapActions({
      resetDownloadReportParams: 'modal/RESET_DOWNLOAD_REPORT',
    }),
    clearAll() {
      setTimeout(() => {
        this.$validator.errors.clear();
        this.$validator.validateAll('report');
      }, 350);
    },
    closeModal() {
      this.$store.commit('modal/toggleModal');
    },

    async checkValidation() {
      let allIsValid = false;
      await this.$validator.validateAll('report').then((res) => {
        allIsValid = res;
      });
      return allIsValid;
    },

    downloadReport() {
      this.checkValidation().then((res) => {
        if (res) {
          if (this.reportDownloadPayload && this.reportDownloadUrl) {
            const payload = new FormData();
            let reportDate = this.beginActivationDateTime;
            if (this.dateOnly) {
              reportDate = this.beginActivationDateTime.substring(0, 10);
            }
            if (!this.omitDate) {
              if (this.reportDownloadName === this.$t('lxp_report')) {
                payload.append('report_start_date', reportDate);
              } else {
                payload.append('report_date', reportDate);
              }
            }
            if (this.reportDownloadHasEndDate) {
              payload.append('report_end_date', this.endActivationDateTime);
            }
            if (this.reportDownloadHasUserStatus) {
              payload.append('user_status', this.userStatus);
            }
            payload.append('friendly_name', this.reportName);
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(this.reportDownloadPayload)) {
              payload.append(key, value);
            }
            this.$store.commit('modal/setProgressBarValue', 0);
            if (this.reportDownloadRequest && this.reportDownloadRequest === 'get') {
              API.get(`${this.reportDownloadUrl}`, {
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                  this.$store.commit('modal/setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
                },
              })
                .then((response) => {
                  if (response.data && response.data.type === 'application/json') {
                    this.displayReportNotification();
                  } else {
                    const excelFile = response.data;
                    saveAs(excelFile, `${this.reportName}-${moment().format('YYYY-MM-DD')}.xlsx`);
                    this.$store.commit('modal/toggleModal');
                  }
                })
                .catch((error) => {
                  this.$helpers.displayError(error);
                })
                .finally(() => this.$store.commit('modal/setProgressBarValue', -1));
            } else {
              API.post(`${this.reportDownloadUrl}`, payload, {
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                  this.$store.commit('modal/setProgressBarValue', parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10));
                },
              })
                .then((response) => {
                  if (this.reportDownloadHasEndDate && !response.data.message && response.data.type !== 'application/json') {
                    const csvFile = response.data;
                    saveAs(csvFile, `${this.reportName}-${moment().format('YYYY-MM-DD')}.csv`);
                    this.$store.commit('modal/toggleModal');
                  } else if (response.data && response.data.type === 'application/json') {
                    this.displayReportNotification();
                  } else {
                    const excelFile = response.data;
                    saveAs(excelFile, `${this.reportName}-${moment().format('YYYY-MM-DD')}.xlsx`);
                    this.$store.commit('modal/toggleModal');
                  }
                })
                .catch((error) => {
                  this.$helpers.displayError(error);
                })
                .finally(() => this.$store.commit('modal/setProgressBarValue', -1));
            }
          } else {
            this.$helpers.displayError('Missing report url and payload params');
          }
        }
      });
    },

    displayReportNotification() {
      console.log('Report notifier');
      this.$store.commit('modal/toggleModal');
      const obj = {
        modalType: 'modalReportNotification',
      };
      this.$helpers.toggleModal(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check {
  .form-label {
    font-size: 0.9rem;
    margin-left: 1rem;
    margin-bottom: 0;
    color: #142b58;
  }
}
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}
.modal-body {
  h4 {
    color: #727d92;
  }
}
.blur {
  filter: blur(3px);
}
.no-wrap {
  white-space: nowrap;
}
  .warning-box {
    display: flex !important;
    min-height: 100px;
    justify-content: center;
    align-items: center;
    .desc-text {
      width: 100%;
      font-size: 13px;
      font-family: "Circular";
      margin: 10px;
      background: rgba(37, 79, 251, 0.18);
      border-radius: 8px;
      padding: 16px 10px;
      display: flex;
      align-items: center;
      .description {
        white-space: pre-line;
      }
      svg {
        margin: 10px 15px 10px 15px;
        .icon {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
.panel-title {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #727d92;
  text-align: center;
  label {
    cursor: pointer;
  }
  label:after {
    content: "\032C";
    left:20px;
    position: relative;
    top: -8px;
    font-size:30px;
    transition: all 1s;
  }
  &.active label:after {
   content: "\032D";
  }
}
</style>
