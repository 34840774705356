var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page mb-4 pb-4" },
    [
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "child-header-navigation": _vm.userSubNavigation,
            "is-child-header-left-actions": "",
          },
        },
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
          _c(
            "dropdown",
            {
              attrs: {
                "class-name": "filter",
                "filter-on": _vm.the_filter.only_active_users,
              },
            },
            [
              _c("template", { slot: "icon" }, [
                _c("i", {
                  staticClass:
                    "fa fa-filter button button--shadow button--type-icon size-sm d-flex align-items-center",
                  staticStyle: {
                    cursor: "pointer",
                    color: "rgb(114, 125, 146)",
                  },
                }),
              ]),
              _c("template", { slot: "body" }, [
                _c("div", { staticClass: "d-block p-2" }, [
                  _c("div", { staticClass: "d-block" }, [
                    _c(
                      "label",
                      {
                        staticClass: "checkbox",
                        attrs: { for: "filter-only_active_users" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.the_filter.only_active_users,
                              expression: "the_filter.only_active_users",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "filter-only_active_users",
                            "true-value": true,
                            "false-value": false,
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.the_filter.only_active_users
                            )
                              ? _vm._i(_vm.the_filter.only_active_users, null) >
                                -1
                              : _vm.the_filter.only_active_users,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.the_filter.only_active_users,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.the_filter,
                                      "only_active_users",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.the_filter,
                                      "only_active_users",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.the_filter,
                                  "only_active_users",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("only_active_users")) + " "),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("nav", { staticClass: "navigation navigation--ternary" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "ul",
            {
              staticClass: "navigation__list nav",
              attrs: { id: "myRoles", role: "tablist" },
            },
            [
              _vm._l(_vm.roleTypes, function (r) {
                return [
                  _c(
                    "li",
                    {
                      key: `${r.value}-${r.name}`,
                      staticClass: "navigation__item",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: { active: _vm.role === Number(r.value) },
                          on: {
                            click: function ($event) {
                              _vm.role = r.value
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(r.name) + " ")]
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            staticClass: "mb-4 navigation text-capitalize d-flex items-center",
          },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "ReportJourneysV2",
                  },
                },
              },
              [
                _c(
                  "icon-container",
                  {
                    attrs: {
                      width: 40,
                      height: 18,
                      name: "Go Back",
                      color: "#727d92",
                    },
                  },
                  [_c("icon-goback")],
                  1
                ),
              ],
              1
            ),
            _vm._v(" " + _vm._s(_vm.journeyName) + " "),
          ],
          1
        ),
        _vm.tableIsLoading
          ? _c("div", { staticClass: "lds-dual-ring" })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c("vue-table", {
              ref: "myvuetable",
              class: { "table-fade": _vm.tableIsLoading },
              attrs: {
                "api-url": _vm.apiUrl,
                "http-fetch": _vm.myFetch,
                "api-mode": true,
                "pagination-path": "meta",
                "http-method": "get",
                "append-params": _vm.moreParams,
                "http-options": {
                  headers: {
                    Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                  },
                },
                fields: _vm.fields,
                "no-data-template": _vm.$t("no_data_info"),
                css: _vm.css.table,
                "per-page": _vm.perPage,
                "query-params": _vm.makeQueryParams,
              },
              on: {
                "vuetable:loading": function ($event) {
                  _vm.tableIsLoading = true
                },
                "vuetable:loaded": function ($event) {
                  _vm.tableIsLoading = false
                },
                "vuetable:load-error": _vm.vuetableLoadError,
                "vuetable:pagination-data": _vm.onPaginationData,
              },
              scopedSlots: _vm._u([
                {
                  key: "first_name",
                  fn: function (props) {
                    return [
                      _vm._v(" " + _vm._s(props.rowData.first_name) + " "),
                    ]
                  },
                },
                {
                  key: "last_name",
                  fn: function (props) {
                    return [_vm._v(" " + _vm._s(props.rowData.last_name) + " ")]
                  },
                },
                {
                  key: "assigned_user-slot",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(props.rowData.assigned_user?.first_name) +
                          " " +
                          _vm._s(props.rowData.assigned_user?.last_name) +
                          " " +
                          _vm._s(_vm.tire) +
                          " " +
                          _vm._s(props.rowData.assigned_user?.employee_no) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "activation_date-slot",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatDate(props.rowData.activation_date, false)
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "first_viewed_at-slot",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatDate(props.rowData.first_viewed_at)
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "duration",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$helpers.convertToTimeString(
                              props.rowData.duration
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "last_viewed_at-slot",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDate(props.rowData.last_viewed_at)) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "completed_at-slot",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDate(props.rowData.completed_at)) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "actions",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "ui button",
                          on: {
                            click: function ($event) {
                              return _vm.onAction("user-detail", props.rowData)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 20,
                                height: 20,
                                name: "infocircle",
                                "view-box": "0 0 512 512",
                                color: "#727d92",
                                "is-icon-class": false,
                              },
                            },
                            [_c("icon-infocircle")],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              { staticClass: "pagination__info" },
              [
                _c("vuetable-pagination-info", {
                  ref: "paginationInfo",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "info-template": _vm.$t("pagination_info"),
                    "no-data-template": _vm.$t("no_data_info"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "pagination__items" },
                  [
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }