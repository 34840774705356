var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M5.32644 1.24111C4.10857 1.24111 3.11806 2.23174 3.11806 3.44949C3.11806 4.66723 4.10857 5.65838 5.32644 5.65838C6.52836 5.65838 7.50597 4.66723 7.50597 3.44949C7.50597 2.23174 6.52836 1.24111 5.32644 1.24111ZM5.32644 6.89962C3.42444 6.89962 1.87695 5.352 1.87695 3.44949C1.87695 1.54749 3.42444 0 5.32644 0C7.21261 0 8.74721 1.54749 8.74721 3.44949C8.74721 5.352 7.21261 6.89962 5.32644 6.89962Z",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M12.861 4.07009H10.4075C10.0649 4.07009 9.78687 3.79217 9.78687 3.44953C9.78687 3.10689 10.0649 2.82898 10.4075 2.82898H12.861C13.2037 2.82898 13.4816 3.10689 13.4816 3.44953C13.4816 3.79217 13.2037 4.07009 12.861 4.07009Z",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M10.0038 11.8936C9.76262 11.8936 9.53386 11.7528 9.43288 11.5177C8.72628 9.86971 7.1142 8.80452 5.32645 8.80452C3.52083 8.80452 1.89777 9.86971 1.19117 11.5177C1.05598 11.832 0.692409 11.9776 0.376068 11.8433C0.0613876 11.7081 -0.0847826 11.3437 0.0504088 11.0284C0.952962 8.92337 3.02411 7.56342 5.32645 7.56342C7.61143 7.56342 9.67109 8.92337 10.5736 11.0284C10.7088 11.3437 10.5627 11.7081 10.248 11.8433C10.1678 11.8777 10.0853 11.8936 10.0038 11.8936Z",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M11.6198 5.28296C11.2772 5.28296 10.9993 5.00492 10.9993 4.66241V2.23713C10.9993 1.89462 11.2772 1.61658 11.6198 1.61658C11.9625 1.61658 12.2404 1.89462 12.2404 2.23713V4.66241C12.2404 5.00492 11.9625 5.28296 11.6198 5.28296Z",
        fill: "currentColor",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }