<template>
  <div
    class="thumbnail-wrapper"
    :class="{'disabled': isDisable}"
  >
    <div
      class="thumbnail-container"
      v-if="fileUrl"
    >
      <template v-if="$helpers.isValidUrl(fileUrl)">
        <div class="file-container ellipsize-left">
          <a
            :href="fileUrl"
            alt="file"
            target="_blank"
            :download="fileUrl.substring(fileUrl.lastIndexOf('/')+1)"
          >{{ fileUrl.substring(fileUrl.lastIndexOf('/')+1) }} </a>
        </div>
      </template>
      <template v-else>
        <div class="file-container ellipsize-left">
          {{ fileUrl }}
        </div>
      </template>
    </div>
    <button
      v-if="fileUrl"
      class="js-media-delete"
      :disabled="isDisable"
      @click="previewRemoved"
    >
      <icon-container
        :width="18"
        :height="18"
        view-box="0 0 25 25"
        color="#727d92"
        name="trash"
      >
        <icon-trash />
      </icon-container>
    </button>
    <div
      class="file-upload-file"
      v-if="!fileUrl"
    >
      <input
        type="file"
        :class="{ 'is-danger': errors.has(`${veeField}`) }"
        @change="fileUploaded"
        :data-vv-scope="veeScoopName"
        :data-vv-name="veeNameIs"
        :data-vv-as="veeAs ? veeAs : defaultAs"
        :name="veeName ? veeName : defaultName"
        v-validate="veeRules"
        :value="fileUrl"
        :multiple="isMultiple"
        :accept="isPdf ? '.pdf':'.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.zip,image/*'"
        :disabled="isDisable"
      >
      <span class="media-object media-object--row">
        <span class="media-object__media">
          <icon-container
            name="circleplusfull"
            view-box="0 0 512 512"
            :width="25"
            :height="25"
            :is-icon-class="false"
          >
            <icon-circleplusfull />
          </icon-container>
        </span>
      </span>
      <span class="upload-file">{{ isPdf ? $t('pdf_file_upload'):$t('file_upload') }}</span>
    </div>
  </div>
</template>

<script>
import IconContainer from '@/components/elements/Icon.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconCirclePlusFull from '@/components/icons/CirclePlusFull.vue';

export default {
  name: 'FileUploadInput',
  components: {
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-circleplusfull': IconCirclePlusFull,
  },
  inject: ['$validator'],
  data() {
    return {
      defaultRule: 'required|size:60000',
      defaultName: 'file',
      defaultAs: this.$t('file_upload'),
      file: null,
    };
  },
  props: {
    errorField: {
      type: String,
      default: null,
    },
    fileUrl: {
      type: String,
      default: null,
    },
    veeScoopName: {
      type: String,
      default: null,
    },
    veeRule: {
      type: String,
      default: null,
    },
    veeName: {
      type: String,
      default: null,
    },
    veeAs: {
      type: String,
      default: null,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isPdf: {
      type: Boolean,
      default: false,
    },
    fileIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    veeField() {
      if (this.veeName) {
        return `${this.veeScoopName}.${this.veeName}`.toString();
      }
      return `${this.veeScoopName}.file`.toString();
    },
    veeRules() {
      if (this.veeRule) {
        return this.veeRule;
      }
      return this.defaultRule;
    },
    veeNameIs() {
      if (this.veeName) {
        return this.veeName;
      }
      return this.defaultName;
    },
    veeScoopIs() {
      if (this.veeScoopName) {
        return this.veeScoopName;
      }
      return 'all';
    },
  },

  methods: {
    previewRemoved() {
      const arr = [null, null, this.fileIndex];
      this.$emit('update', arr);
    },

    fileUploaded(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (files.length > 1 && this.isMultiple) {
        Array.from(files).forEach(async (file) => {
          if (file && file.size < 60000000) {
            const result = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (event) => {
                const arr = [];
                arr.push(event.target.result);
                arr.push(file);
                arr.push(this.fileIndex);
                resolve(arr);
              };
            });
            this.$emit('updateMultiple', result);
          }
        });
      } else {
        const file = files[0];
        if (file) {
          this.file = file;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            this.$validator.validate(this.veeField, this.file).then((res) => {
              if (res) {
                const arr = [];
                arr.push(event.target.result);
                arr.push(file);
                arr.push(this.fileIndex);
                this.$emit('update', arr);
              }
            });
          };
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.is-danger {
  border-color: #f27299;
}
.thumbnail-wrapper{
  width: 100%;
  height: 56px;
}
.thumbnail-container {
  position: relative;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-container {
  width: 100%;
}
.upload-file {
  white-space: nowrap;
}
.ellipsize-left {
    /* Standard CSS ellipsis */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400px;
    /* Beginning of string */
    direction: rtl;
    text-align: left;
}
</style>
