<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ communicationTitle + '-' + $t('user_list') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>

          <div class="modal-body">
            <div class="form-group">
              <div class="page">
                <div class="top-group">
                  <div>
                    <filter-perpage
                      @searchFor="setSearchFor"
                      @perPage="setPerPage"
                    />
                  </div>
                  <div>
                    <button
                      class="button button--secondary text-size-xs size-sm button--icon"
                      @click="addCommunicationUserModal()"
                    >
                      <icon-container
                        name="plusfull"
                        view-box="0 0 448 512"
                        :width="15"
                        :height="16"
                        color="#FFF"
                        class="mr-2"
                        :is-icon-class="false"
                      >
                        <icon-plusfull />
                      </icon-container>
                      {{ $t('add_new_user') }}
                    </button>
                  </div>
                </div>

                <div class="container">
                  <div class="table-responsive">
                    <div
                      v-if="tableIsLoading"
                      class="lds-dual-ring"
                    />
                    <vue-table
                      :class="{ 'table-fade': tableIsLoading }"
                      ref="myvuetable"
                      :api-url="apiUrl"
                      :http-fetch="myFetch"
                      :api-mode="true"
                      @vuetable:loading="tableIsLoading = true"
                      @vuetable:loaded="tableIsLoading = false"
                      @vuetable:load-error="vuetableLoadError"
                      pagination-path="meta"
                      http-method="post"
                      :append-params="moreParams"
                      :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
                      :fields="fields"
                      :no-data-template="$t('no_data_info')"
                      :css="css.table"
                      :per-page="perPage"
                      :query-params="makeQueryParams"
                      @vuetable:pagination-data="onPaginationData"
                    >
                      <template
                        slot="opened_at"
                        slot-scope="props"
                      >
                        <div>
                          <span>{{ formatDate(props.rowData.opened_at) }}</span>
                        </div>
                      </template>
                      <template
                        slot="email"
                        slot-scope="props"
                      >
                        <div
                          class="d-inline-block text-truncate"
                          style="max-width: 180px;"
                          :title="props.rowData.email"
                        >
                          {{ props.rowData.email }}
                        </div>
                      </template>
                      <template
                        slot="actions"
                        slot-scope="props"
                      >
                        <button
                          class="ui button delete"
                          @click="onAction('delete-item', props.rowData, props.rowIndex)"
                        >
                          <icon-container
                            :width="20"
                            :height="20"
                            view-box="0 0 24 24"
                            name="trash"
                            color="#727d92"
                          >
                            <icon-trash />
                          </icon-container>
                        </button>
                      </template>
                    </vue-table>
                    <div class="pagination__info">
                      <vuetable-pagination-info
                        :class="{ 'table-fade': tableIsLoading }"
                        ref="paginationInfo"
                        :info-template="$t('pagination_info')"
                        :no-data-template="$t('no_data_info')"
                      />
                      <div class="pagination__items">
                        <vuetable-pagination
                          ref="pagination"
                          :css="css.pagination"
                          @vuetable-pagination:change-page="onChangePage"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="button button--primary"
                @click="closeModal"
              >
                <span>
                  {{ $t('close') }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import IconContainer from '@/components/elements/Icon.vue';
import IconTrash from '@/components/icons/Trash.vue';
import IconPlusFull from '@/components/icons/PlusFull.vue';
import moment from 'moment';

import {
  API_SERVER_TIME_FORMAT,
  API_COMMUNICATION,
} from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import VuetablePagination from '@/components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'ModalCommunicationItemUsers',
  mixins: [myTableMixin],
  data() {
    return {
      moreParams: {
        id: this.$store.state.modal.communicationIdToEdit,
        customer_id: this.$store.state.modal.customerIdToEdit,
      },

    };
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'CommunicationItemUsers') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
          this.$store.state.modal.launchedFrom = null;
        }, 500);
      }
    },
  },

  components: {
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
    'icon-plusfull': IconPlusFull,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  computed: {
    ...mapState([
      'modal',
    ]),
    communicationIdToEdit() {
      return this.$store.state.modal.communicationIdToEdit;
    },
    customerIdToEdit() {
      return this.$store.state.modal.customerIdToEdit;
    },
    communicationTitle: {
      get() {
        return this.$store.state.modal.communicationTitle;
      },
      set(newValue) {
        this.$store.state.modal.communicationTitle = newValue;
      },
    },

    apiUrl() {
      return `${API_COMMUNICATION}/details`;
    },

    refreshPage: {
      get() {
        return this.$store.state.modal.refreshPage;
      },
      set(newValue) {
        this.$store.state.modal.refreshPage = newValue;
      },
    },

    pageToRefresh: {
      get() {
        return this.$store.state.modal.pageToRefresh;
      },

      set(newValue) {
        this.$store.state.modal.pageToRefresh = newValue;
      },
    },
    fields() {
      return [
        {
          name: 'fullname',
          title: this.$t('name'),
          width: '25%',
        },
        {
          name: 'email',
          title: this.$t('email'),
          width: '35%',
        },
        {
          name: 'opened_at',
          title: this.$t('opened_at'),
          sortField: 'recipients.opened_at',
          width: '25%',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '15%',
        },
      ];
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/toggleModal');
      this.$store.commit('modal/REFRESH_PAGE', true);
    },

    formatDate(value) {
      let date = '-';
      if (value && value !== '-') {
        date = moment(value, API_SERVER_TIME_FORMAT).format(this.userLang === 'en' ? 'MM.DD.YYYY HH:mm' : 'DD.MM.YYYY HH:mm');
      }
      return date;
    },
    addCommunicationUserModal() {
      this.$store.dispatch('modal/SAVE_PREVIOUS_STATE');
      const obj = {
        modalType: 'modalCommunicationAddUserOverlay',
        communicationIdToEdit: this.communicationIdToEdit,
        overlayModal: true,
        pageToRefresh: 'CommunicationItemUsers',
      };
      this.$helpers.toggleModal(obj);
    },
    onAction(action, data) {
      const userId = data.id;
      this.idHolder = userId;
      if (!userId) {
        return;
      }
      if (action === 'delete-item' && !this.snotifyQueueList.includes(userId)) {
        this.snotifyQueueList.push(userId);
        this.$snotify.confirm(this.$t('user_delete_msg_body', { name: data.fullname }), this.$t('user_delete_msg_title'), {
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          buttons: [
            {
              text: this.$t('yes'),
              action: (toast) => {
                this.$store.dispatch('modal/DELETE_COMMUNICATION_USER', { id: this.communicationIdToEdit, user_recipients: userId }).then(() => {
                  this.$refs.myvuetable.refresh();
                });
                this.$snotify.remove(toast.id);
              },
              bold: false,
            },
            {
              text: this.$t('no'),
              action: (toast) => {
                this.$snotify.remove(toast.id);
              },
            },
          ],
        }).on('shown', (toast) => {
          this.toastHolder = toast;
          document.addEventListener('keydown', this.eventHandler, false);
        }).on('hidden', (toast) => {
          this.toastHolder = toast;
          document.removeEventListener('keydown', this.eventHandler, false);
        });
      }
    },
  },
};
</script>
<style scoped>
.modal .modal-body {
    padding: 15px 0px 30px;
    margin: 0 20px;
    min-width: 750px;
}
  .disabled {
    opacity:0.5;
  }
  .top-group {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .vuetable tbody tr {
  height: 44px;
  }
</style>
