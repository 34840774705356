<template>
  <div class="page mb-4 pb-4">
    <app-header
      v-if="$route.params.journeyId"
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
    </app-header>

    <div class="container">
      <div class="d-flex mb-4 navigation text-capitalize d-flex items-center">
        <router-link
          v-if="$route.params.journeyId"
          :to="{ path: `/dashboard/journeys/users/${$route.params.journeyId}/user/${$route.params.userId}`, query: {journey: journeyName, name: userName, surname:userSurname, employee_no: userEmployeeNo, email: userEmail}}"
        >
          <icon-container
            :width="40"
            :height="18"
            name="Go Back"
            color="#727d92"
          >
            <icon-goback />
          </icon-container>
        </router-link> <div class="d-flex flex-column">
          <span>{{ userName }} {{ userSurname }}</span>
          <span>{{ userEmployeeNo }}</span>
          <span>{{ userEmail }}</span>
        </div>
      </div>
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div
        class="table-responsive"
        v-if="$route.params.insideJourneyId || propJourneyId"
      >
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="post"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="type"
            slot-scope="props"
          >
            <div class="d-flex justify-content-center">
              <span>
                {{ $helpers.getTypeNameFromId(props.rowData.type, userLang) }}
              </span>
            </div>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <button
              class="ui button"
              @click="onAction('user-menu', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                view-box="0 0 24 24"
                name="threedots"
                color="#727d92"
              >
                <icon-threedots />
              </icon-container>
            </button>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import { mapState, mapGetters } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import IconThreedots from '@/components/icons/Threedots.vue';
import IconGoBack from '@/components/icons/GoBack.vue';
import AppHeader from '@/components/AppHeader.vue';
import { API_REPORT_JOURNEY_USER_JOURNEY_CONTENTS } from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'ReportJourneyUserJourneyInside',
  mixins: [myTableMixin],
  props: {
    propUserId: {
      type: String,
      default: '',
      required: false,
    },
    propJourneyId: {
      type: Number || String,
      default: 0 || null,
      required: false,
    },
    propJourneyName: {
      type: String,
      default: '',
      required: false,
    },
    propUserName: {
      type: String,
      default: null,
      required: false,
    },
    propUserSurname: {
      type: String,
      default: null,
      required: false,
    },
    propUserEmployeeNo: {
      type: String,
      default: null,
      required: false,
    },
    propUserEmail: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      moreParams: {},
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-threedots': IconThreedots,
    'icon-goback': IconGoBack,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'ReportJourneys') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),
    ...mapGetters({
      userLang: 'auth/userLang',
    }),
    journeyName() {
      return this.$route.query.journey ? this.$route.query.journey : this.propJourneyName;
    },
    userName() { return this.$route.query.name ? this.$route.query.name : this.propUserName; },
    userSurname() { return this.$route.query.surname ? this.$route.query.surname : this.propUserSurname; },
    userEmployeeNo() { return this.$route.query.employee_no ? this.$route.query.employee_no : this.propUserEmployeeNo; },
    userEmail() { return this.$route.query.email ? this.$route.query.email : this.propUserEmail; },
    apiUrl() {
      if (API_REPORT_JOURNEY_USER_JOURNEY_CONTENTS) {
        return `${API_REPORT_JOURNEY_USER_JOURNEY_CONTENTS}`;
      }
      return null;
    },

    customerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },

    userSubNavigation() {
      return [
        {
          text: this.$t('users'),
          url: `/dashboard/journeys/users/${this.$route.params.journeyId}`,
          query: { journey: this.journeyName },
        },
        {
          text: this.$t('contents_subtitle'),
          url: `/dashboard/journeys/contents/${this.$route.params.journeyId}`,
          query: { journey: this.journeyName },
        },
      ];
    },

    fields() {
      return [
        {
          name: 'name',
          title: this.$t('journey_name'),
          sortField: 'contents.name',
          width: '35%',
        },
        {
          name: 'type',
          title: this.$t('type'),
          sortField: 'contents.as_content_sub_type_id',
          width: '10%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'order',
          title: this.$t('order'),
          sortField: 'journey_items.order',
          width: '5%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'activation_date',
          title: this.$t('activation_date'),
          sortField: 'journey_items.activation_dt',
          width: '15%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'first_opened_at',
          title: this.$t('first_viewed_at'),
          sortField: 'item_reports.user_opened_at',
          width: '15%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'duration_in_seconds',
          title: this.$t('duration_in_seconds'),
          sortField: 'item_reports.duration',
          width: '5%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'status',
          title: this.$t('status'),
          width: '10%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '5%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        }];
    },
  },
  created() {
    this.moreParams.user_id = this.$route.params.userId ? this.$route.params.userId : this.propUserId;
    this.moreParams.journey_id = this.$route.params.insideJourneyId ? this.$route.params.insideJourneyId : this.propJourneyId;
  },
  methods: {
    onAction(action, data) {
      const userId = data.id;
      if (action === 'user-menu') {
        if (userId) {
          console.log('User action to do'); // TODO: complete dropdown action menu
        }
      }
    },
  },
};
</script>
