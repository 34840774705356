<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ $t('upload_image_content') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="form form--horizontal form--strong">
              <div
                class="form-group row"
                v-if="ItemContent.thumbnail_image"
              >
                <div class="col-6">
                  <vue-dropzone
                    ref="thumbnailDropzone"
                    id="thumbnailDropzone"
                    :options="dropOptions('thumbnails')"
                    :use-custom-slot="true"
                  >
                    <div class="dropzone-custom-content">
                      <h4 class="dropzone-custom-title">
                        {{ $t('upload_thumbnail') }}
                      </h4>
                    </div>
                  </vue-dropzone>
                </div>
              </div>
              <div
                class="form-group row"
                v-if="ItemContent.content_url"
              >
                <div class="col-6">
                  <vue-dropzone
                    ref="contentDropzone"
                    id="contentDropzone"
                    :options="dropOptions('contents')"
                    :use-custom-slot="true"
                  >
                    <div class="dropzone-custom-content">
                      <h4 class="dropzone-custom-title">
                        {{ $t('upload_content') }}
                      </h4>
                    </div>
                  </vue-dropzone>
                </div>
              </div>
              <div v-if="ItemContent && ItemContent.flipboard_items && ItemContent.flipboard_items.length">
                <!-- eslint-disable-next-line -->
                <label>{{ $t('FLIPBOOKS') }}</label>
              </div>
              <div
                class="form-group row"
                v-if="ItemContent && ItemContent.flipboard_items && ItemContent.flipboard_items.length"
              >
                <template v-for="(item, index) in ItemContent.flipboard_items">
                  <div
                    class="col-4 py-2"
                    :key="index"
                  >
                    <vue-dropzone
                      :ref="`flipbookDropzone-${index}`"
                      :id="`flipbookDropzone-${index}`"
                      :options="dropOptionsForFlip('thumbnails', index)"
                      :use-custom-slot="true"
                      @vdropzone-sending="(file, xhr, formData) => { uploadFileSend(file, xhr, formData, index) }"
                    >
                      <div class="dropzone-custom-content">
                        <h4 class="dropzone-custom-title">
                          {{ $t('upload_flipbook_page', { page: index + 1}) }}
                        </h4>
                      </div>
                    </vue-dropzone>
                  </div>
                </template>
              </div>
              <div v-if="ItemContent && ItemContent.flipbook_editable_items && ItemContent.flipbook_editable_items.length">
                <label>{{ $t('image_library_') }}</label>
              </div>
              <div
                class="form-group row"
                v-if="ItemContent && ItemContent.flipbook_editable_items && ItemContent.flipbook_editable_items.length"
              >
                <template v-for="(item, index) in ItemContent.flipbook_editable_items">
                  <template v-for="(image, subindex ) in item.images">
                    <div
                      class="col-4 py-2"
                      :key="`${index}${subindex}`"
                    >
                      <vue-dropzone
                        :ref="`imageLibraryDropzone${index}${subindex}`"
                        :id="`imageLibraryDropzone${index}${subindex}`"
                        :options="dropOptionsForFlip('image_library', index, subindex)"
                        :use-custom-slot="true"
                      >
                        <div class="dropzone-custom-content">
                          <h4 class="dropzone-custom-title">
                            {{ $t('upload_image_library', { name: item.type + '-' + image.name, item: (index + 1)}) }}
                          </h4>
                        </div>
                      </vue-dropzone>
                    </div>
                  </template>
                </template>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="button button--primary"
                  :disabled="isPending"
                  @click="closeModal"
                >
                  <span
                    v-if="!isPending"
                  >
                    {{ $t('close') }}
                  </span>
                  <div
                    class="lds-ellipsis"
                    v-if="isPending"
                  >
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import axios from 'axios';

export default {
  name: 'ModalUploadImageContent',

  data() {
    return {
      isPending: false,
      formSending: false,
      ItemContent: {},
      token: 'c1NmZ0FkSFRrekRBSUM2TU1MbUlCV0UyUHFhdjh5cTdDUVRGT1FVWExXSzhWQkRzdDJidlNxSXFhSDhqajZ5c1VGMzBZd09VUHBsaWpVOGRr',
    };
  },

  watch: {
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  computed: {
    modalData() {
      return this.$store.state.modal.modalData;
    },
  },
  created() {
    if (this.$store.state.modal.launchedFrom === 'Contents') {
      this.$store.dispatch('modal/GET_CONTENT').then(() => {
        this.ItemContent = this.modalData;
      });
    } else {
      this.ItemContent = this.$store.state.modal.journeyItemDetail;
    }
    console.log('itemcontent:', this.ItemContent);
  },
  methods: {
    closeModal() {
      this.$store.commit('modal/toggleModal');
    },
    dropOptions(type) {
      let fileName = null;
      if (type && type === 'thumbnails' && this.ItemContent.thumbnail_image) {
        fileName = this.ItemContent.thumbnail_image;
      }
      if (type && type === 'contents' && this.ItemContent.content_url) {
        fileName = this.ItemContent.content_url;
      }
      let fName = '';
      if (fileName) {
        fName = fileName.substring(fileName.lastIndexOf('/') + 1);
      }
      const options = {
        acceptedFiles: 'image/*, .mp4, .MP4, .m4v, .M4V, .mov, .MOV, .html',
      };
      options.addRemoveLinks = false;
      options.url = `https://api.journeyapp.com/upload_missing_file/${type}/${fName}`;
      options.headers = {
        Authorization: `Bearer ${this.token}`,
      };
      options.params = {
        overwrite: 1,
      };
      options.timeout = 0;
      return options;
    },
    dropOptionsForFlip(type, index = null, subindex = null) {
      let fileName = null;

      if (type && type === 'thumbnails' && this.ItemContent.flipboard_items) {
        fileName = this.ItemContent.flipboard_items[index].thumbnail_image;
      }
      if (type && type === 'image_library' && this.ItemContent.flipbook_editable_items) {
        fileName = this.ItemContent.flipbook_editable_items[index].images[subindex].path;
      }
      let fName = '';
      if (fileName) {
        fName = fileName.substring(fileName.lastIndexOf('/') + 1);
      }
      console.log('s:', fName);
      const options = {
        acceptedFiles: 'image/*',
      };
      options.addRemoveLinks = false;
      options.url = `https://api.journeyapp.com/upload_missing_file/${type}/${fName}`;
      options.headers = {
        Authorization: `Bearer ${this.token}`,
      };
      options.params = {
        overwrite: 1,
      };
      options.timeout = 0;
      return options;
    },
    uploadFileSend(file, xhr, formData, index) {
      console.log('INDEX:', index);
      const fileName = this.ItemContent.flipboard_items[index].content_url;
      let fName = '';
      if (fileName) {
        fName = fileName.substring(fileName.lastIndexOf('/') + 1);
      }

      formData.append('file', file);
      axios.post(`https://api.journeyapp.com/upload_missing_file/contents/${fName}`,
        formData, { headers: { Authorization: `Bearer ${this.token}` } })
        .catch((error) => {
          this.$helpers.displayError(error);
        });
    },
  },
};
</script>
