var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        fill: "#fff",
        d: "M19.5 11.77c.38-.4.53-.97.5-1.85-.05-1.2-1.44-2.22-2.54-2.22h-2.74c-.08 0-.3 0-.41-.03v-.03c0-.12.22-.42.4-.65l.45-.62a5.3 5.3 0 00.99-3.15c0-1.35-.82-3.22-2.35-3.22-1.24 0-1.58 1.28-1.82 2.22-.09.32-.16.62-.26.86A5.95 5.95 0 0110 5.06c-.46.4-.9.78-1.18 1.2-.1.14-.25.4-.45.73-.37.62-1.65 2.6-2.02 2.98H5v9.47l.87.02c.22.22.75.54 1.52.54h9.25c.82 0 1.5-.34 1.9-.97.38-.57.48-1.31.27-1.83.25-.2.44-.5.57-.9a2.13 2.13 0 00-.1-1.62c.7-.66.94-2.02.22-2.9z",
      },
    }),
    _c("rect", {
      attrs: {
        width: "4.62",
        height: "9.43",
        y: "10.19",
        fill: "#fff",
        rx: "2",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }