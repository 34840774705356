var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "currentColor", "fill-rule": "evenodd" } }, [
    _c("path", {
      attrs: {
        d: "M28 8v16a2 2 0 0 1-2 2H5c0 1.1.9 2 2 2h21a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2zm-1 15V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h21a2 2 0 0 0 2-2zM4 7h21v16H4V7zm11.63 10.31-3-3.18-2.98 5.1-2.24-2.34-2.43 5.13h19.05l-4.3-11.25-4.1 6.54zM8 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }