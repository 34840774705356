import axios from 'axios';
import Vue from 'vue';
import { Translation } from '@/helpers/translation';

import {
  API_LOGIN,
  API_LOGOUT,
  API_RECAPTCHA,
  API_GET_USER_INFO,
  API_LOGIN_REFRESH,
  API_FORGOT_PASSWORD,
  API_GET_CUSTOMER_LIST_LIGHT,
} from '@/helpers/config';

export default {
  namespaced: true,

  state: {
    token: null,
    expires: null,
    timeToExpire: null,
    status: null,
    userInfo: null,
    customerId: null,
    customerName: null,
    isVibonsAdmin: false,
    sidebarStatus: false,
    currentViewMode: 'weekly',
    customerList: [],
  },

  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    isVibonsAdmin: state => state.isVibonsAdmin,
    userInfo: state => state.userInfo,
    custormerId: state => state.customerId,
    customerList: state => state.customerList,
    token: state => state.token,
    userLang: (state) => {
      const $state = state;
      if ($state.userInfo) {
        return $state.userInfo.lang;
      }
      return 'en'; // default fallback
    },
  },

  mutations: {
    auth_request(state) {
      const localState = state;
      localState.status = 'loading';
    },
    reset_auth(state) {
      let localState = state;
      const newState = {};

      Object.keys(localState).forEach((key) => {
        newState[key] = null; // or = initialState[key]
      });
      localState = newState;
    },
    set_status(state, payload) {
      const localState = state;
      localState.status = payload;
    },

    set_customer_list(state, payload) {
      const localState = state;
      localState.customerList = payload;
    },

    auth_success(state, payload) {
      const localState = state;
      const now = Math.floor(Date.now() / 1000);
      localState.status = 'success';
      localStorage.setItem('hasAuthIssue', false);
      localState.token = payload.token;
      localState.expires = payload.expires;
      localState.timeToExpire = now + parseInt(payload.expires, 10);
    },

    auth_error(state) {
      const localState = state;
      localStorage.setItem('hasAuthIssue', true);
      localState.status = 'error';
    },

    auth_info(state, payload) {
      const localState = state;
      localState.userInfo = payload;
      localState.customerId = payload.customer_id;
    },
    add_new_customer(state, payload) {
      const localState = state;
      const newlist = [...localState.customerList];
      if (newlist && newlist.length) {
        newlist.push(payload);
        const sorter1 = (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        newlist.sort(sorter1);
        localState.customerList = newlist;
      }
    },
    setSidebarStatus(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.sidebarStatus = $payload;
    },

    setCustomerId(state, payload) {
      const localState = state;
      localState.customerId = payload;
    },

    setCustomerName(state, payload) {
      const localState = state;
      localState.customerName = payload;
    },

    setCurrentViewMode(state, payload) {
      const $state = state;
      const $payload = payload;
      $state.currentViewMode = $payload;
    },

    setVibonsAdmin(state, payload) {
      const $state = state;
      const $payload = payload;
      if ($payload.role === 'vibons-admin') {
        $state.isVibonsAdmin = true;
      } else {
        $state.isVibonsAdmin = false;
      }
    },

    logout(state) {
      const localState = state;
      localState.status = '';
      localState.token = null;
      localState.userInfo = null;
      localState.customerId = null;
      localState.isVibonsAdmin = false;
      localState.expires = null;
      localState.customerName = null;
      localState.timeToExpire = null;
      delete axios.defaults.headers.common.Authorization;
    },
  },

  actions: {
    login({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        const formData = new FormData();
        formData.append('email', user.email);
        formData.append('password', user.password);

        Vue.prototype.$API.post(API_LOGIN, formData)
          .then((response) => {
            const { token, expires } = response.data;
            axios.defaults.headers.Authorization = `Bearer ${token}`;
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            const payload = { token, expires }; // same here user login details will be seen on vuex
            commit('auth_success', payload);
            dispatch('getUserInfo');
            resolve(response);
          })
          .catch((error) => {
            commit('auth_error');
            reject(error);
          });
      });
    },

    setLocale(context, payload) {
      return new Promise((resolve) => {
        Translation.changeLanguage(payload.lang);
        resolve(true);
      });
    },

    // eslint-disable-next-line camelcase
    validateCaptcha(context, g_recaptcha_token) {
      return new Promise((resolve, reject) => {
        const payload = { g_recaptcha_token };
        Vue.prototype.$API.post(API_RECAPTCHA, payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            Vue.prototype.$helpers.displayError(error);
            reject(error);
          });
      });
    },

    getUserInfo({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$API.post(API_GET_USER_INFO)
          .then((response) => {
            commit('auth_info', response.data);
            commit('setVibonsAdmin', response.data);
            dispatch('setLocale', response.data);
            dispatch('setCustomerName');
            resolve(response);
          })
          .catch((error) => {
            commit('auth_error');
            Vue.prototype.$helpers.displayError(error);
            reject(error);
          });
      });
    },

    setCustomerName(context) {
      return new Promise(async (resolve, reject) => {
        if (context.state.isVibonsAdmin) {
          const formData = { params: {} };
          formData.params.per_page = 1000;
          await Vue.prototype.$API.get(API_GET_CUSTOMER_LIST_LIGHT, formData).then((response) => {
            const listOfCustomers = Object.values(response.data);
            if (listOfCustomers) {
              const customerName = listOfCustomers.filter(item => item.id === Number(context.state.customerId));
              let name = null;
              if (customerName) {
                ([{ name }] = customerName);
                context.commit('setCustomerName', name);
                context.commit('set_customer_list', listOfCustomers);
              }
            }
            resolve();
          })
            .catch((error) => {
              Vue.prototype.$helpers.displayError(error);
              reject(error);
            });
        }
      });
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        Vue.prototype.$API.get(API_LOGOUT)
          .then((response) => {
            Vue.prototype.$snotify.success(response.data.message);
            commit('logout');
            localStorage.removeItem(process.env.VUE_APP_STORE_PREFIX);
            resolve();
          });
      });
    },

    reset({ commit }) {
      commit('reset_auth');
    },

    refreshToken({ commit }) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        Vue.prototype.$API.get(API_LOGIN_REFRESH)
          .then((response) => {
            if (response.status === 200) {
              // eslint-disable-next-line camelcase
              const { access_token, expires_in } = response.data;
              // eslint-disable-next-line camelcase
              axios.defaults.headers.Authorization = `Bearer ${access_token}`;
              // eslint-disable-next-line camelcase
              axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
              const payload = { token: access_token, expires: expires_in };
              commit('auth_success', payload);
              resolve(payload);
            } else if (response.status === 403) {
              reject(new Error('refresh error'));
            }
          })
          .catch((error) => {
            commit('auth_error');
            Vue.prototype.$helpers.displayError(error);
            reject(error);
          });
      });
    },

    forgotPassword(context, user) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('email', user.email);

        Vue.prototype.$API.post(API_FORGOT_PASSWORD, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
