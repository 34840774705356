var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("rect", {
      attrs: {
        x: ".5",
        y: ".5",
        width: "74",
        height: "43",
        rx: "5.5",
        stroke: "currentColor",
        "stroke-opacity": ".5",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M27.98 10.19c-.99 0-1.79.8-1.79 1.79v19.04c0 .99.8 1.79 1.79 1.79h5.35V34h-5.35A2.98 2.98 0 0 1 25 31.02V11.98A2.98 2.98 0 0 1 27.98 9h5.62c.74 0 1.46.28 2 .78L41.3 15c.61.56.96 1.36.96 2.19v.14h-1.19v-.14c0-.5-.2-.98-.58-1.32l-5.69-5.21c-.33-.3-.76-.47-1.2-.47h-5.62Z",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M33.93 13.76V9.6h1.19v4.16c0 .99.8 1.79 1.78 1.79h4.17v1.19H36.9a2.98 2.98 0 0 1-2.97-2.98ZM43.45 22.69v8.33h-1.19V22.7h1.2Z",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M42.44 22.27a.6.6 0 0 1 .84 0l3.57 3.57-.84.84-3.15-3.15-3.15 3.15-.85-.84 3.58-3.57Z",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M42.86 20.9a5.95 5.95 0 1 0 0 11.9 5.95 5.95 0 0 0 0-11.9Zm-7.15 5.96a7.14 7.14 0 1 1 14.29 0 7.14 7.14 0 0 1-14.29 0Z",
        fill: "currentColor",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }