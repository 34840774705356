var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c(
      "select",
      {
        staticClass: "switch-customer",
        attrs: { name: "customer" },
        on: { change: _vm.changeCustomer },
      },
      _vm._l(_vm.listOfCustomers, function (customer) {
        return _c(
          "option",
          {
            key: customer.id,
            domProps: {
              selected: _vm.isCurrentCustomer(customer.id),
              value: customer.id,
            },
          },
          [_vm._v(" " + _vm._s(customer.name) + " ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }