var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M5.65573 6.82705L9.82101 2.48355C10.1532 2.1368 10.1636 1.56327  9.8445 1.20258C9.52527 0.841708 8.99728 0.830307 8.66523 1.17706L5.07792 4.91537L1.49062 1.17706C1.15906 0.829583 0.631237 0.839537 0.311177 1.1995C-0.00871563 1.55947 0.000448227 2.13299 0.331838 2.48047C0.332837 2.48156 0.333671 2.48265 0.33467 2.48355L4.49995 6.82705C4.66356 6.99627 4.88332 7.08621 5.10908 7.0759C5.31318 7.06848 5.50778 6.97998 5.65573 6.82705Z",
      fill: "currentColor",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }