var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "currentColor" } }, [
    _c("path", {
      attrs: {
        d: "M3 23h18V6.709L15.29 1H3zM15 2h.2L20 6.8V7h-5zM4 2h10v6h6v14H4zm13.324 11.819l-.167-.004a12.208 12.208 0 0 0-2.469.338 24.235 24.235 0 0 1-2.344-2.728 11.894 11.894 0 0 0 .697-4.028 1.432 1.432 0 0 0-.928-1.237 1.37 1.37 0 0 0-.461-.08 1.524 1.524 0 0 0-1.014.398c-.836.75-.752 2.316.226 4.187.042.081.084.175.13.276a1.939 1.939 0 0 0 .323.561c-.334 1.046-.803 2.182-1.216 3.184l-.252.694a9.195 9.195 0 0 0-1.36.607c-1.673.959-2.683 1.849-2.17 3.08a1.147 1.147 0 0 0 .88.716 1.315 1.315 0 0 0 .23.02 2.343 2.343 0 0 0 1.623-.813 10.846 10.846 0 0 0 1.467-2.83c1.074-.37 2.484-.74 3.784-1.04a7.526 7.526 0 0 0 2.876 1.87 2.144 2.144 0 0 0 .426.045 1.347 1.347 0 0 0 1.088-.489 1.652 1.652 0 0 0 .136-1.703 1.707 1.707 0 0 0-1.505-1.024zM9.44 16.474c-.395.94-1.057 2.513-1.842 2.513a.655.655 0 0 1-.154-.018.624.624 0 0 1-.372-.58c.001-.285.195-1.033 1.972-1.815.096-.042.202-.094.314-.15l.13-.063zm1.84-9.28a.557.557 0 0 1 .367-.153.418.418 0 0 1 .144.024.627.627 0 0 1 .355.54 8.16 8.16 0 0 1-.47 2.71l-.01-.02c-.797-1.525-.794-2.736-.387-3.102zm-.424 8.02l1.17-2.792a69.42 69.42 0 0 0 1.709 1.937c-.947.21-2.358.678-2.88.854zm7.094.67a.552.552 0 0 1-.485.22 3.2 3.2 0 0 1-1.94-1.154l.178-.032a7.814 7.814 0 0 1 1.292-.168.894.894 0 0 1 .96.522.646.646 0 0 1-.005.613z",
      },
    }),
    _c("path", { attrs: { fill: "none", d: "M0 0h24v24H0z" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }