var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
      _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "div",
              { staticClass: "notif-content" },
              [
                _c(
                  "icon-container",
                  {
                    staticClass: "icon",
                    attrs: {
                      name: "time",
                      width: "30",
                      height: "30",
                      color: "#2dcf85",
                      "view-box": "0 0 512 512",
                    },
                  },
                  [_c("icon-time")],
                  1
                ),
                _c("h2", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.importType === "badi"
                          ? _vm.$t("we_are_generating_badi")
                          : _vm.$t("we_are_generating")
                      ) +
                      " "
                  ),
                ]),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("report_notification_desc")),
                  },
                }),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.importType === "badi"
                        ? _vm.$t("report_notification_badi_desc_two")
                        : _vm.$t("report_notification_desc_two")
                    ),
                  },
                }),
                _c("div", { staticClass: "survey-preview--button" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button button--primary",
                      attrs: { type: "button" },
                      on: { click: _vm.closeModal },
                    },
                    [_c("span", [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")])]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }