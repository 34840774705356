var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page dashboard" },
    [
      _c("app-header", {
        attrs: {
          "child-header-class": "container-fluid",
          "child-header-navigation": _vm.dashboardSubNavigation,
          "is-child-header-left-actions": "",
          "child-title": _vm.childTitle,
        },
      }),
      _c("main", { staticClass: "page__body mb-4 pb-4" }, [
        _c(
          "div",
          { staticClass: "vuetable-wrapper" },
          [
            _c("h5", { staticClass: "sub-title" }, [
              _vm._v(" " + _vm._s(_vm.$t("user_journeys")) + " "),
            ]),
            _c("ReportJourneyUserJourneys", {
              attrs: {
                "prop-user-id": _vm.userId,
                "prop-user-email": _vm.$route.query.email,
                "prop-user-name": _vm.$route.query.first_name,
                "prop-user-surname": _vm.$route.query.last_name,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vuetable-wrapper" },
          [
            _c("div", { staticClass: "button-group" }, [
              _c("div", { staticClass: "button-group__item dropdown" }, [
                _c(
                  "label",
                  {
                    staticClass:
                      "button button--shadow button--type-icon size-sm d-flex align-items-center",
                    on: {
                      click: function ($event) {
                        return _vm.dropdownAction("showFilters")
                      },
                    },
                  },
                  [
                    _c(
                      "icon-container",
                      {
                        attrs: {
                          name: "search",
                          width: 14,
                          height: 14,
                          color: "#727d92",
                          "view-box": "0 0 14 14",
                          "is-icon-class": false,
                        },
                      },
                      [_c("icon-search")],
                      1
                    ),
                  ],
                  1
                ),
                _vm.isDropdown === "showFilters"
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "on-clickaway",
                            rawName: "v-on-clickaway",
                            value: _vm.hideDropdown,
                            expression: "hideDropdown",
                          },
                        ],
                        staticClass: "dropdown__menu dropdown__menu--filter",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group form-group--filter" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterText,
                                  expression: "filterText",
                                },
                                { name: "sanitize", rawName: "v-sanitize" },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("filter_by_name"),
                              },
                              domProps: { value: _vm.filterText },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.doFilter.apply(null, arguments)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.filterText = $event.target.value
                                },
                              },
                            }),
                            _vm.filterText
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "icon button-reset",
                                    on: { click: _vm.resetFilter },
                                  },
                                  [
                                    _c(
                                      "icon-container",
                                      {
                                        attrs: {
                                          name: "reset",
                                          "view-box": "0 0 20 20",
                                        },
                                      },
                                      [_c("icon-reset")],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "button-group__item dropdown" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "button button--shadow button__dropdown size-sm",
                    class: { active: _vm.isDropdown === "perPageDropdown" },
                    on: {
                      click: function ($event) {
                        return _vm.dropdownAction("perPageDropdown")
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("view")) +
                        " " +
                        _vm._s(_vm.perPage) +
                        " "
                    ),
                    _c(
                      "icon-container",
                      {
                        attrs: {
                          name: "down",
                          "view-box": "0 0 11 8",
                          "is-icon-class": false,
                        },
                      },
                      [_c("icon-down")],
                      1
                    ),
                  ],
                  1
                ),
                _vm.isDropdown === "perPageDropdown"
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "on-clickaway",
                            rawName: "v-on-clickaway",
                            value: _vm.hideDropdown,
                            expression: "hideDropdown",
                          },
                        ],
                        staticClass: "dropdown__menu dropdown__menu--per-page",
                      },
                      _vm._l(_vm.pageOptions, function (option) {
                        return _c(
                          "div",
                          {
                            key: option.value,
                            staticClass: "dropdown__menu-item",
                            on: {
                              click: function ($event) {
                                return _vm.pageOptionsSelected(option.value)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(option.text) + " ")]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm.tableIsLoading
              ? _c("div", { staticClass: "lds-dual-ring" })
              : _vm._e(),
            _c("vue-table", {
              ref: "vuetable",
              class: { "table-fade": _vm.tableIsLoading },
              attrs: {
                "api-mode": false,
                fields: _vm.fields,
                "track-by": _vm.data.options,
                "wrapper-class": "vuetable-wrapper",
                "data-total": _vm.totalDataCount,
                "data-manager": _vm.dataManager,
                "no-data-template": _vm.$t("no_data_info"),
                "pagination-path": "pagination",
                "per-page": _vm.perPage,
                css: _vm.css.table,
              },
              on: { "vuetable:pagination-data": _vm.onPaginationData },
              scopedSlots: _vm._u([
                {
                  key: "date",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getCurrentLocaleDate(props.rowData.date)) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "type-slot",
                  fn: function (props) {
                    return [
                      _c("div", { staticClass: "text-center" }, [
                        _c("span", {
                          staticClass: "icon-item",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$helpers.iconType(props.rowData.type)
                            ),
                          },
                        }),
                      ]),
                    ]
                  },
                },
                {
                  key: "action-slot",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          props.rowData.action === "not_completed"
                            ? _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "eye",
                                    "view-box": "0 0 576 512",
                                    "is-icon-class": false,
                                  },
                                },
                                [_c("icon-eye")],
                                1
                              )
                            : _vm._e(),
                          props.rowData.action === "completed"
                            ? _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "check",
                                    "view-box": "0 0 512 512",
                                    "is-icon-class": false,
                                  },
                                },
                                [_c("icon-check")],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "duration-slot",
                  fn: function (props) {
                    return [
                      _c("div", { staticClass: "text-center" }, [
                        _c("span", [_vm._v(_vm._s(props.rowData.duration))]),
                      ]),
                    ]
                  },
                },
                {
                  key: "device-slot",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          props.rowData.device === "mobile"
                            ? _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "mobile",
                                    "view-box": "0 0 320 512",
                                    "is-icon-class": false,
                                  },
                                },
                                [_c("icon-mobile")],
                                1
                              )
                            : _vm._e(),
                          props.rowData.device === "desktop"
                            ? _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "desktop",
                                    "view-box": "0 0 576 512",
                                    "is-icon-class": false,
                                  },
                                },
                                [_c("icon-desktop")],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "channel-slot",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          props.rowData.channel === "notification"
                            ? _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "mobile",
                                    "view-box": "0 0 320 512",
                                    "is-icon-class": false,
                                  },
                                },
                                [_c("icon-mobile")],
                                1
                              )
                            : props.rowData.channel === "email"
                            ? _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "envelope",
                                    "view-box": "0 0 512 512",
                                    "is-icon-class": false,
                                  },
                                },
                                [_c("icon-envelope")],
                                1
                              )
                            : props.rowData.channel === "user"
                            ? _c(
                                "icon-container",
                                {
                                  attrs: {
                                    name: "user",
                                    "view-box": "0 0 12 14",
                                    "is-icon-class": false,
                                  },
                                },
                                [_c("icon-user")],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              { staticClass: "pagination__info" },
              [
                _c("vuetable-pagination-info", {
                  ref: "paginationInfo",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "info-template": _vm.$t("pagination_info"),
                    "no-data-template": _vm.$t("no_data_info"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "pagination__items" },
                  [
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }