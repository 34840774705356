var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", { attrs: { fill: "none", d: "M-1-1h226v23H-1V-1z" } }),
    _c("path", {
      attrs: {
        fill: "#fff",
        "fill-rule": "evenodd",
        d: "M80.16 1.67a4.51 4.51 0 11.02 9.02 4.51 4.51 0 01-.02-9.02zM95.93 3.6h32.09a2.58 2.58 0 012.58 2.56 2.58 2.58 0 01-2.58 2.57H95.93a2.52 2.52 0 01-2.52-2.57 2.5 2.5 0 012.52-2.56zM37.96 3v2.14c0 8.62 7.01 15.62 15.67 15.62h116.74c8.66 0 15.67-7 15.67-15.62V3a3 3 0 012.92-3H35.04a3 3 0 012.92 3z",
        "clip-rule": "evenodd",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }