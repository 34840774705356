var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "currentColor" } }, [
    _c("path", {
      attrs: {
        d: "M448 304h-40c-7 0-13 6-13 12v96c0 7 6 12 13 12h40c6 0 12-5 12-12v-96c0-6-6-12-12-12zM349 258h-40c-7 0-12 6-12 12v142c0 7 5 12 12 12h40c6 0 12-5 12-12V270c0-6-6-12-12-12zM151 304h-40c-7 0-12 6-12 12v96c0 7 5 12 12 12h40c7 0 12-5 12-12v-96c0-6-5-12-12-12zM52 258H12c-7 0-12 6-12 12v142c0 7 5 12 12 12h40c7 0 12-5 12-12V270c0-6-5-12-12-12zM250 197h-40c-7 0-12 5-12 12v203c0 7 5 12 12 12h40c7 0 12-5 12-12V209c0-7-5-12-12-12z",
      },
    }),
    _c("path", {
      attrs: {
        d: "M437 245c8 0 15-7 15-15v-49a15 15 0 00-30 0v12L270 40a15 15 0 00-23 2L132 209 31 145a15 15 0 10-16 25l113 72a15 15 0 0020-4L261 74l140 141h-13a15 15 0 000 30h49z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }