var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M2.5 21.6667C3.88071 21.6667 5 20.5475 5 19.1667C5 17.786 3.88071 16.6667 2.5 16.6667C1.11929 16.6667 0 17.786 0 19.1667C0 20.5475 1.11929 21.6667 2.5 21.6667Z",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M2.5 13.3333C3.88071 13.3333 5 12.214 5 10.8333C5 9.45254 3.88071 8.33325 2.5 8.33325C1.11929 8.33325 0 9.45254 0 10.8333C0 12.214 1.11929 13.3333 2.5 13.3333Z",
        fill: "currentColor",
      },
    }),
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M2.5 5C3.88071 5 5 3.88071 5 2.5C5 1.11929 3.88071 0 2.5 0C1.11929 0 0 1.11929 0 2.5C0 3.88071 1.11929 5 2.5 5Z",
        fill: "currentColor",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }