var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.modal.modalVisible && _vm.modal.modalType === "modalAddJourney"
            ? _c("modal-addjourney")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalLoginCode"
            ? _c("modal-logincode")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalAddGroup"
            ? _c("modal-addgroup")
            : _vm._e(),
          _vm.modal.modalVisible && _vm.modal.modalType === "modalSetPassword"
            ? _c("modal-setpassword")
            : _vm._e(),
        ],
        1
      ),
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "child-header-navigation": _vm.userSubNavigation,
            "is-child-header-left-actions": "",
            "add-user": "",
          },
        },
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
          _vm.isVibonsAdmin
            ? _c(
                "dropdown",
                {
                  attrs: {
                    "class-name": "filter",
                    "filter-on": _vm.the_filter.only_active_customer_users,
                  },
                },
                [
                  _c("template", { slot: "icon" }, [
                    _c("i", {
                      staticClass:
                        "fa fa-filter button button--shadow button--type-icon size-sm d-flex align-items-center",
                      staticStyle: {
                        cursor: "pointer",
                        color: "rgb(114, 125, 146)",
                      },
                    }),
                  ]),
                  _c("template", { slot: "body" }, [
                    _c("div", { staticClass: "d-block p-2" }, [
                      _c("div", { staticClass: "d-block" }, [
                        _c(
                          "label",
                          {
                            staticClass: "checkbox",
                            attrs: { for: "filter-only_active_customer_users" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.the_filter.only_active_customer_users,
                                  expression:
                                    "the_filter.only_active_customer_users",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "filter-only_active_customer_users",
                                "true-value": true,
                                "false-value": false,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.the_filter.only_active_customer_users
                                )
                                  ? _vm._i(
                                      _vm.the_filter.only_active_customer_users,
                                      null
                                    ) > -1
                                  : _vm.the_filter.only_active_customer_users,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.the_filter.only_active_customer_users,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.the_filter,
                                          "only_active_customer_users",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.the_filter,
                                          "only_active_customer_users",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.the_filter,
                                      "only_active_customer_users",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("only_active_customer_users")) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.tableIsLoading
          ? _c("div", { staticClass: "lds-dual-ring" })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c("vue-table", {
              ref: "myvuetable",
              class: { "table-fade": _vm.tableIsLoading },
              attrs: {
                "api-url": _vm.apiUrl,
                "http-fetch": _vm.myFetch,
                "api-mode": true,
                "pagination-path": "meta",
                "http-method": "get",
                "append-params": _vm.moreParams,
                "http-options": {
                  headers: {
                    Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                  },
                },
                fields: _vm.fields,
                "no-data-template": _vm.$t("no_data_info"),
                css: _vm.css.table,
                "per-page": _vm.perPage,
                "query-params": _vm.makeQueryParams,
              },
              on: {
                "vuetable:loading": function ($event) {
                  _vm.tableIsLoading = true
                },
                "vuetable:loaded": function ($event) {
                  _vm.tableIsLoading = false
                },
                "vuetable:load-error": _vm.vuetableLoadError,
                "vuetable:pagination-data": _vm.onPaginationData,
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "ui button",
                          on: {
                            click: function ($event) {
                              return _vm.getMobilStatus(
                                props.rowData.id,
                                `ref${props.rowData.id}`
                              )
                            },
                            blur: function ($event) {
                              return _vm.closeTooltip(`ref${props.rowData.id}`)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              class: {
                                disabled:
                                  !props.rowData.has_mobile_device_registered,
                              },
                              attrs: {
                                width: 13,
                                height: 20,
                                name: "cellphone",
                                "view-box": "0 0 320 512",
                                color: props.rowData.is_notifications_enabled
                                  ? "rgb(8, 206, 25)"
                                  : "#727d92",
                              },
                            },
                            [_c("icon-cellphone")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          ref: `ref${props.rowData.id}`,
                          staticClass: "content-tooltip",
                        },
                        [
                          _c("pre", [
                            _vm._v(
                              _vm._s(_vm.tooltipText ? _vm.tooltipText : "⏳")
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "first_name",
                  fn: function (props) {
                    return [
                      _c("span", [
                        _vm._v(" " + _vm._s(props.rowData.first_name) + " "),
                      ]),
                    ]
                  },
                },
                {
                  key: "is_active",
                  fn: function (props) {
                    return [
                      _c(
                        "span",
                        {
                          class: props.rowData.is_active
                            ? "is-active"
                            : "is-passive",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                props.rowData.is_active
                                  ? _vm.$t("active")
                                  : _vm.$t("passive")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "created_at",
                  fn: function (props) {
                    return [
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatDate(props.rowData.created_at))
                          ),
                        ]),
                      ]),
                    ]
                  },
                },
                {
                  key: "customer_name",
                  fn: function (props) {
                    return [
                      _vm.isVibonsAdmin
                        ? _c("span", [
                            _vm._v(
                              " " + _vm._s(props.rowData.customer_name) + " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "actions",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "ui button",
                          on: {
                            click: function ($event) {
                              return _vm.onAction("edit-item", props.rowData)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: 20,
                                height: 20,
                                "view-box": "0 0 24 24",
                                name: "edit",
                                color: "#727d92",
                              },
                            },
                            [_c("icon-edit")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown mr-2",
                          on: {
                            mouseover: function ($event) {
                              return _vm.dropdownAction(`plus${props.rowIndex}`)
                            },
                            mouseleave: _vm.hideDropdownAction,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "card--actions dropdown__toggle" },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    width: "18",
                                    height: "18",
                                    "view-box": "0 0 24 24",
                                    name: "cplus",
                                    color: "#727d92",
                                  },
                                },
                                [_c("icon-circleplus")],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.dropdownIndex === "plus" + props.rowIndex
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "on-clickaway",
                                      rawName: "v-on-clickaway",
                                      value: _vm.hideDropdownAction,
                                      expression: "hideDropdownAction",
                                    },
                                  ],
                                  ref: `plus${props.rowIndex}`,
                                  staticClass: "dropdown__menu",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "add-journey",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: 18,
                                            height: 18,
                                            name: "journey",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-journey")],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("add_to_existent_journey")
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "add-group",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: 18,
                                            height: 18,
                                            name: "user",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-user")],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("add_to_group")) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown",
                          on: {
                            mouseover: function ($event) {
                              return _vm.dropdownAction(
                                `three${props.rowIndex}`
                              )
                            },
                            mouseleave: _vm.hideDropdownAction,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "card--actions dropdown__toggle" },
                            [
                              _c(
                                "icon-container",
                                {
                                  attrs: {
                                    width: 20,
                                    height: 20,
                                    "view-box": "0 0 24 24",
                                    name: "threedots",
                                    color: "#727d92",
                                  },
                                },
                                [_c("icon-threedots")],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.dropdownIndex === "three" + props.rowIndex
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "on-clickaway",
                                      rawName: "v-on-clickaway",
                                      value: _vm.hideDropdownAction,
                                      expression: "hideDropdownAction",
                                    },
                                  ],
                                  ref: `three${props.rowIndex}`,
                                  staticClass: "dropdown__menu",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "notification",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: 18,
                                            height: 18,
                                            "view-box": "0 0 24 24",
                                            name: "edit",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-bell")],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("notification_button")
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.canSeeSetPassword
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "dropdown__button button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onAction(
                                                "set-new-password",
                                                props.rowData
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "icon-container",
                                            {
                                              attrs: {
                                                width: 17,
                                                height: 17,
                                                "view-box": "0 0 512 512",
                                                name: "email",
                                                color: "#727d92",
                                              },
                                            },
                                            [_c("icon-email")],
                                            1
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("set_new_password")
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isVibonsAdmin
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "dropdown__button button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onAction(
                                                "login-code",
                                                props.rowData
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "icon-container",
                                            {
                                              attrs: {
                                                name: "password",
                                                width: 22,
                                                height: 22,
                                                "view-box": "0 0 32 32",
                                                color: "#727d92",
                                              },
                                            },
                                            [_c("icon-password")],
                                            1
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("set_login_code")) +
                                              " "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "usage-detail",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            name: "infocircle",
                                            "view-box": "0 0 512 512",
                                            color: "#727d92",
                                            "is-icon-class": false,
                                          },
                                        },
                                        [_c("icon-infocircle")],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("usage_detail")) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "link-zoom-account",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: 22,
                                            height: 22,
                                            "view-box": "0 0 50 50",
                                            name: "zoom",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-zoom")],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("link_zoom_account")) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown__button button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAction(
                                            "delete-item",
                                            props.rowData
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "icon-container",
                                        {
                                          attrs: {
                                            width: 18,
                                            height: 18,
                                            "view-box": "0 0 24 24",
                                            name: "trash",
                                            color: "#727d92",
                                          },
                                        },
                                        [_c("icon-trash")],
                                        1
                                      ),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("delete")) + " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              { staticClass: "pagination__info" },
              [
                _c("vuetable-pagination-info", {
                  ref: "paginationInfo",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "info-template": _vm.$t("pagination_info"),
                    "no-data-template": _vm.$t("no_data_info"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "pagination__items" },
                  [
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }