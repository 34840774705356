var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isHeader
    ? _c(
        "th",
        {
          staticClass: "vuetable-th-component-checkbox",
          attrs: { scope: "row" },
        },
        [
          _c("input", {
            attrs: { type: "checkbox" },
            domProps: { checked: _vm.isAllItemsInCurrentPageSelected() },
            on: {
              change: function ($event) {
                return _vm.toggleAllCheckbox($event)
              },
            },
          }),
        ]
      )
    : _c("td", { staticClass: "vuetable-td-component-checkbox" }, [
        _c("input", {
          attrs: { type: "checkbox" },
          domProps: { checked: _vm.isSelected(_vm.rowData) },
          on: {
            change: function ($event) {
              return _vm.toggleCheckbox(_vm.rowData, $event)
            },
          },
        }),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }