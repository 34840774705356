var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("g", [
      _c("rect", {
        attrs: {
          fill: "none",
          id: "canvas_background",
          height: "20",
          width: "27",
          y: "0",
          x: "0",
        },
      }),
    ]),
    _c("g", [
      _c("g", { attrs: { stroke: "null" } }, [
        _c("path", {
          attrs: {
            stroke: "null",
            d: "m25.06025,1.349398c0,0.696412 -0.564633,1.261045 -1.261045,1.261045l-22.698803,0c-0.696412,0 -1.261045,-0.564633 -1.261045,-1.261045l0,0c0,-0.696412 0.564633,-1.261045 1.261045,-1.261045l22.698803,0c0.696412,0 1.261045,0.564633 1.261045,1.261045l0,0z",
          },
        }),
        _c("path", {
          attrs: {
            stroke: "null",
            d: "m25.06025,6.393576c0,0.696412 -0.564633,1.261045 -1.261045,1.261045l-22.698803,0c-0.696412,0 -1.261045,-0.564633 -1.261045,-1.261045l0,0c0,-0.696412 0.564633,-1.261045 1.261045,-1.261045l22.698803,0c0.696412,0 1.261045,0.564633 1.261045,1.261045l0,0z",
          },
        }),
        _c("path", {
          attrs: {
            stroke: "null",
            d: "m25.06025,11.437755c0,0.696412 -0.564633,1.261045 -1.261045,1.261045l-22.698803,0c-0.696412,0 -1.261045,-0.564633 -1.261045,-1.261045l0,0c0,-0.696412 0.564633,-1.261045 1.261045,-1.261045l22.698803,0c0.696412,0 1.261045,0.564633 1.261045,1.261045l0,0z",
          },
        }),
        _c("path", {
          attrs: {
            stroke: "null",
            d: "m25.06025,16.481933c0,0.696412 -0.564633,1.261045 -1.261045,1.261045l-22.698803,0c-0.696412,0 -1.261045,-0.564633 -1.261045,-1.261045l0,0c0,-0.696412 0.564633,-1.261045 1.261045,-1.261045l22.698803,0c0.696412,0 1.261045,0.564633 1.261045,1.261045l0,0z",
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }