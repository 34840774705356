<template>
  <div class="page mb-4 pb-4">
    <app-header
      child-header-class="container"
      :child-header-navigation="userSubNavigation"
      is-child-header-left-actions
    >
      <filter-perpage
        @searchFor="setSearchFor"
        @perPage="setPerPage"
      />
      <dropdown
        class-name="filter"
        :filter-on="the_filter.only_active_users"
      >
        <template slot="icon">
          <i
            class="fa fa-filter button button--shadow button--type-icon size-sm d-flex align-items-center"
            style="cursor: pointer;color: rgb(114, 125, 146);"
          />
        </template>
        <template slot="body">
          <div class="d-block p-2">
            <div
              class="d-block"
            >
              <label
                for="filter-only_active_users"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  id="filter-only_active_users"
                  :true-value="true"
                  :false-value="false"
                  v-model="the_filter.only_active_users"
                >
                {{ $t('only_active_users') }}
              </label>
            </div>
          </div>
        </template>
      </dropdown>
    </app-header>

    <div class="container">
      <div class="mb-4 navigation text-capitalize d-flex items-center">
        <router-link
          :to="{
            name: 'ReportJourneys',
          }"
        >
          <icon-container
            :width="40"
            :height="18"
            name="Go Back"
            color="#727d92"
          >
            <icon-goback />
          </icon-container>
        </router-link> {{ journeyName }}
      </div>
      <div
        v-if="tableIsLoading"
        class="lds-dual-ring"
      />
      <div class="table-responsive">
        <vue-table
          :class="{ 'table-fade': tableIsLoading }"
          ref="myvuetable"
          :api-url="apiUrl"
          :http-fetch="myFetch"
          :api-mode="true"
          @vuetable:loading="tableIsLoading = true"
          @vuetable:loaded="tableIsLoading = false"
          @vuetable:load-error="vuetableLoadError"
          pagination-path="meta"
          http-method="post"
          :append-params="moreParams"
          :http-options="{ headers: { Authorization: `Bearer ${$store.state.auth.token}` } }"
          :fields="fields"
          :no-data-template="$t('no_data_info')"
          :css="css.table"
          :per-page="perPage"
          :query-params="makeQueryParams"
          @vuetable:pagination-data="onPaginationData"
        >
          <template
            slot="name"
            slot-scope="props"
          >
            <router-link
              :to="{ path: `/dashboard/journeys/users/${$route.params.journeyId}/user/${props.rowData.id}`, query: {journey:$route.query.journey, name: props.rowData.name, surname:props.rowData.surname, employee_no: props.rowData.employee_no, email: props.rowData.email}}"
            >
              {{ props.rowData.name }}
            </router-link>
          </template>
          <template
            slot="surname"
            slot-scope="props"
          >
            <router-link
              :to="{ path: `/dashboard/journeys/users/${$route.params.journeyId}/user/${props.rowData.id}`, query: {journey:$route.query.journey, name: props.rowData.name, surname:props.rowData.surname, employee_no: props.rowData.employee_no, email: props.rowData.email}}"
            >
              {{ props.rowData.surname }}
            </router-link>
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <button
              class="ui button"
              @click="onAction('user-menu', props.rowData)"
            >
              <icon-container
                :width="20"
                :height="20"
                view-box="0 0 24 24"
                name="threedots"
                color="#727d92"
              >
                <icon-threedots />
              </icon-container>
            </button>
          </template>
        </vue-table>
        <div class="pagination__info">
          <vuetable-pagination-info
            :class="{ 'table-fade': tableIsLoading }"
            ref="paginationInfo"
            :info-template="$t('pagination_info')"
            :no-data-template="$t('no_data_info')"
          />
          <div class="pagination__items">
            <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import { directive as onClickaway } from 'vue-clickaway';
import { mapState } from 'vuex';
import IconContainer from '@/components/elements/Icon.vue';
import Dropdown from '@/components/elements/Dropdown.vue';
import IconThreedots from '@/components/icons/Threedots.vue';
import IconGoBack from '@/components/icons/GoBack.vue';
import AppHeader from '@/components/AppHeader.vue';
import { API_REPORT_JOURNEY_USERS } from '@/helpers/config';
import FilterPerpage from '@/components/FilterPerpage.vue';
import myTableMixin from '@/helpers/myTableMixin.vue';
import VuetablePagination from '../../components/CustomVuetable/VuetablePagination.vue';

export default {
  name: 'ReportJourneyUsers',
  mixins: [myTableMixin],
  data() {
    return {
      moreParams: {
        customer_id: this.$store.state.auth.customerId,
        journey_id: this.$route.params.journeyId,
      },
      the_filter: {
        only_active_users: false,
      },
      journeyName: this.$route.query.journey,
    };
  },

  components: {
    'icon-container': IconContainer,
    'icon-threedots': IconThreedots,
    'icon-goback': IconGoBack,
    'app-header': AppHeader,
    'vue-table': Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    Dropdown,
    'filter-perpage': FilterPerpage,
  },

  directives: {
    'on-clickaway': onClickaway,
  },

  watch: {
    refreshPage(newValue) {
      if (newValue && this.pageToRefresh === 'ReportJourneys') {
        setTimeout(() => {
          this.$refs.myvuetable.refresh();
          this.refreshPage = false;
          this.pageToRefresh = null;
        }, 500);
      }
    },
    the_filter: {
      handler(filter) {
        if (filter.only_active_users) {
          this.$set(this.moreParams, 'only_active_users', 1);
        } else {
          this.$delete(this.moreParams, 'only_active_users');
        }
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    ...mapState([
      'modal',
    ]),

    apiUrl() {
      if (API_REPORT_JOURNEY_USERS) {
        return `${API_REPORT_JOURNEY_USERS}`;
      }
      return null;
    },

    customerId() {
      return this.$store.state.auth.customerId;
    },

    isVibonsAdmin() {
      if (this.$store.state && this.$store.state.auth) {
        return this.$store.state.auth.isVibonsAdmin;
      }
      return false;
    },

    userSubNavigation() {
      return [
        {
          text: this.$t('users'),
          url: `/dashboard/journeys/users/${this.$route.params.journeyId}`,
          query: { journey: this.journeyName },
        },
        {
          text: this.$t('contents_subtitle'),
          url: `/dashboard/journeys/contents/${this.$route.params.journeyId}`,
          query: { journey: this.journeyName },
        },
      ];
    },

    fields() {
      return [
        {
          name: 'name',
          title: this.$t('first_name'),
          sortField: 'users.first_name',
          width: '12%',
        },
        {
          name: 'surname',
          title: this.$t('last_name'),
          sortField: 'users.last_name',
          width: '15%',
        },
        {
          name: 'email',
          title: this.$t('email'),
          width: '20%',
        },
        {
          name: 'assigned_item_count',
          title: this.$t('assigned_item_count'),
          sortField: 'assigned_item_count',
          width: '5%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'active_item_count',
          title: this.$t('active_item_count'),
          width: '5%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'completed_items_count',
          title: this.$t('completed_items_count'),
          sortField: 'completed_items_count',
          width: '5%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'first_viewed_at',
          title: this.$t('first_viewed_at'),
          sortField: 'first_viewed_at',
          width: '11%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'last_viewed_at',
          title: this.$t('last_viewed_at'),
          sortField: 'last_viewed_at',
          width: '11%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'completed_at',
          title: this.$t('completed_at'),
          width: '11%',
          sortField: 'completed_at',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '5%',
          titleClass: 'text-center',
          dataClass: 'text-center',
        }];
    },
  },
  methods: {
    onAction(action, data) {
      const userId = data.id;
      if (action === 'user-menu') {
        if (userId) {
          console.log('User action to do'); // TODO: complete dropdown action menu
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .filter-bp__btn {
  border: none;
  padding: 0;
  cursor: pointer;
  }
::v-deep .swicth-checkbox {
  min-width: 200px;
}
::v-deep .swicth-checkbox label {
    color: #727d92;
}
</style>
