var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c("div", { staticClass: "modal-container" }, [
      _c("div", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
        _c("div", { staticClass: "previewclosebutton" }, [
          _vm.launchedFrom === "Contents" && !_vm.isDisabled
            ? _c(
                "div",
                {
                  staticClass: "d-block btn mx-2",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.editContentItem()
                    },
                  },
                },
                [
                  _c(
                    "icon-container",
                    {
                      attrs: {
                        name: "edit",
                        color: "#fff",
                        width: "18",
                        height: "18",
                        "view-box": "0 0 24 24",
                        "is-icon-class": false,
                      },
                    },
                    [_c("icon-edit")],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "d-block mx-2" }, [
            _c("button", {
              staticClass: "close",
              attrs: { type: "button" },
              domProps: { innerHTML: _vm._s("&times;") },
              on: { click: _vm.closeModal },
            }),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "modal__mobile-preview from-content-page",
            class: { rotatepreview: _vm.showVideo },
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "flag" },
              [
                _c(
                  "icon-container",
                  {
                    attrs: {
                      name: "flag",
                      width: 224,
                      height: 21,
                      "view-box": "0 0 224 21",
                      "is-icon-class": false,
                    },
                  },
                  [_c("icon-flag")],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "mobile-content",
                class: {
                  "bluered-bg":
                    _vm.contentType === "PODCAST" ||
                    _vm.contentType === "KEY_CONTACT_MANAGER" ||
                    _vm.contentType === "KNOWLEDGE_REQUIREMENT_MANAGER" ||
                    _vm.contentType === "FILE_DOWNLOAD" ||
                    _vm.contentType === "CHECKLIST" ||
                    _vm.contentType === "BUDDY_MANAGER",
                  bgBlack:
                    _vm.contentType === "INFOGRAPHIC" ||
                    _vm.contentType === "FLIPBOOK",
                },
                style: _vm.injectedArticleHtml ? "background:#ffffff;" : "",
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showVideo && _vm.showControl,
                        expression: "showVideo && showControl",
                      },
                    ],
                    staticClass: "videopausecontrol",
                  },
                  [
                    _c(
                      "button",
                      {
                        on: {
                          click: _vm.exitVideo,
                          mouseenter: function ($event) {
                            _vm.showControl = true
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticStyle: {
                              fill: "#fff",
                              "fill-opacity": "0.5",
                            },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 24 24",
                            },
                          },
                          [
                            _c("path", {
                              attrs: { d: "M0 0h24v24H0z", fill: "none" },
                            }),
                            _c("path", {
                              attrs: {
                                d: "M12 10.6l5-4.9 1.4 1.4-4.9 5 5 5-1.4 1.4-4.9-4.9-4.9 5-1.4-1.4 5-4.9-4.9-4.9L7.1 5.6z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm.contentType === "FLIPBOOK" && _vm.flipImages.length > 0
                  ? _c("flip-book", {
                      key: _vm.flipImages.length,
                      attrs: { images: _vm.flipImages },
                    })
                  : _vm._e(),
                _vm.content.content_url && _vm.contentType === "INFOGRAPHIC"
                  ? _c("div", { staticClass: "infograph-content" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.content.content_url,
                          alt: "Infographic",
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.showVideo && _vm.contentType === "VIDEO"
                  ? _c("div", { staticClass: "videocontent" }, [
                      _c(
                        "video",
                        {
                          attrs: {
                            id: "videoElement",
                            controls: "",
                            playsinline: "",
                            "webkit-playsinline": "",
                            autoplay: "",
                            volume: "0.5",
                          },
                          on: {
                            mouseover: function ($event) {
                              _vm.showControl = true
                            },
                            mouseleave: function ($event) {
                              _vm.showControl = false
                            },
                            canplay: _vm.updatePaused,
                            playing: _vm.updatePaused,
                            pause: _vm.updatePaused,
                            ended: _vm.exitVideo,
                          },
                        },
                        [
                          _c("source", {
                            attrs: {
                              src: _vm.content.content_url,
                              type: "video/mp4",
                            },
                          }),
                          _vm._v(_vm._s(_vm.$t("not_support_html5")) + " "),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.podcastPreview && _vm.contentType === "PODCAST"
                  ? _c("div", { staticClass: "podcastcontent" }, [
                      _c("div", { staticClass: "contentBox" }, [
                        _c("div", { staticClass: "podcast-icon" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/podcast-mic.png"),
                              alt: "podcast",
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "podcast-title" }, [
                          _vm._v(" " + _vm._s(_vm.content.name) + " "),
                        ]),
                        _vm.content.desc
                          ? _c("div", { staticClass: "podcast-desc" }, [
                              _vm._v(" " + _vm._s(_vm.content.desc) + " "),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "controlBox" }, [
                        _c(
                          "div",
                          {
                            ref: "progressBar",
                            staticClass: "currentProgressBar",
                            on: { mousedown: _vm.podcastSeek },
                          },
                          [
                            _c("div", {
                              staticClass: "currentProgress",
                              style: {
                                width: _vm.currentPodcastProgressBar + "%",
                              },
                            }),
                          ]
                        ),
                        _c("div", { staticClass: "currentTimeContainer" }, [
                          _c("span", { staticClass: "currentTime" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("timeFormat")(_vm.currentPodcastTime)
                              )
                            ),
                          ]),
                          _c("span", { staticClass: "totalTime" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("timeFormat")(_vm.podcastDuration)
                                )
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "controlButtonsContainer" },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/podcast-backward.png"),
                                alt: "Backward",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.backwardPodcast()
                                },
                              },
                            }),
                            _c(
                              "transition",
                              { attrs: { name: "slide-fade", mode: "out-in" } },
                              [
                                !_vm.podcastIsPlaying
                                  ? _c("img", {
                                      key: "play",
                                      attrs: {
                                        src: require("@/assets/img/podcast-play.png"),
                                        alt: "Play",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.togglePodcast()
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.podcastIsPlaying
                                  ? _c("img", {
                                      key: "pause",
                                      attrs: {
                                        src: require("@/assets/img/podcast-pause.png"),
                                        alt: "Pause",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.togglePodcast()
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/podcast-forward.png"),
                                alt: "Forward",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.forwardPodcast()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.contentType === "QUIZ" &&
                _vm.content &&
                _vm.content.quiz &&
                _vm.content.quiz.questions.length > 0
                  ? _c("div", { staticClass: "mobile-content__quiz" }, [
                      _c(
                        "div",
                        { staticClass: "quiz-preview--container" },
                        [
                          _vm._l(
                            _vm.content.quiz.questions,
                            function (questionPreview, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "quiz-preview--question",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "quiz-preview--progress" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.content &&
                                              _vm.content.quiz &&
                                              _vm.content.quiz.questions
                                              ? parseInt(
                                                  (100 /
                                                    _vm.content.quiz.questions
                                                      .length) *
                                                    (index + 1),
                                                  10
                                                ) +
                                                  "% " +
                                                  _vm.$t("completed_")
                                              : ""
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "quiz-progress-bar" },
                                        [
                                          _c("span", {
                                            style: {
                                              width:
                                                (100 /
                                                  _vm.content.quiz.questions
                                                    .length) *
                                                  (index + 1) +
                                                "%",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("h5", [
                                    _vm._v(
                                      _vm._s(
                                        index +
                                          1 +
                                          ". " +
                                          questionPreview.question
                                      )
                                    ),
                                  ]),
                                  questionPreview.type === "standard" &&
                                  !questionPreview.choices[0].image
                                    ? _c(
                                        "ul",
                                        _vm._l(
                                          questionPreview.choices,
                                          function (choice, cindex) {
                                            return _c("li", { key: cindex }, [
                                              choice.choice_text
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "d-flex p-2",
                                                      class: {
                                                        "is-correct":
                                                          choice.is_correct ==
                                                          1,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "optiontick",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$helpers.choiceLetter(
                                                                  cindex
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            choice.choice_text
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  questionPreview.type === "standard" &&
                                  questionPreview.choices[0].image
                                    ? _c(
                                        "ul",
                                        { staticClass: "row" },
                                        _vm._l(
                                          questionPreview.choices,
                                          function (choice, qcindex) {
                                            return _c(
                                              "li",
                                              {
                                                key: qcindex,
                                                staticClass: "col-6 px-1",
                                              },
                                              [
                                                _c(
                                                  "picture",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column",
                                                    class: {
                                                      "is-correct":
                                                        choice.is_correct == 1,
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: choice.image,
                                                        alt: "Choice Image",
                                                      },
                                                    }),
                                                    choice.choice_text
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex p-2",
                                                            staticStyle: {
                                                              "mix-blend-mode":
                                                                "difference",
                                                              "font-size":
                                                                "11px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  choice.choice_text
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  questionPreview.type === "pairs"
                                    ? _c(
                                        "ul",
                                        {
                                          staticClass: "row",
                                          attrs: { id: "pair-choices" },
                                        },
                                        _vm._l(
                                          questionPreview.choices,
                                          function (choice, qpindex) {
                                            return _c("li", { key: qpindex }, [
                                              choice.choice_text
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          choice.choice_text
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "quiz-preview--button" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "button button--secondary size-sm",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.previewQuestionNext(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("next")) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          _c("div", { staticClass: "quiz-preview--question" }, [
                            _c("div", { staticClass: "quiz-finalpage" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex final-score flex-column justify-content-center",
                                },
                                [
                                  _c("span", { staticClass: "score-point" }, [
                                    _vm._v(_vm._s(_vm.$t("score_point"))),
                                  ]),
                                  _c("div", { staticClass: "your-score" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("your_score")) + " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "completed-text text-center mt-4",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("quiz_completed")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center text-center mt-4",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("thanks_feedback")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "quiz-preview--button" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "button button--secondary size-sm",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.previewQuestionNext(-1)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm.contentType === "KEY_CONTACT_MANAGER"
                  ? _c("div", { staticClass: "mobile-content__onboarding" }, [
                      _c(
                        "div",
                        { staticClass: "onboarding-preview--container" },
                        [
                          _c("div", { staticClass: "d-flex" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("keycontacts_report", {
                                    name:
                                      _vm.content &&
                                      _vm.content.target_user &&
                                      _vm.content.target_user.first_name
                                        ? _vm.content.target_user.first_name
                                        : "",
                                  })
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "px-0 subfont" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("keycontacts_report_desc", {
                                    name:
                                      _vm.content &&
                                      _vm.content.target_user &&
                                      _vm.content.target_user.first_name
                                        ? _vm.content.target_user.first_name
                                        : "",
                                  })
                                ) +
                                " "
                            ),
                          ]),
                          _vm._l(
                            _vm.content.manager_contacts,
                            function (personitem) {
                              return _c(
                                "div",
                                {
                                  key: personitem.id,
                                  staticClass: "contacts_block mx-0 p-0",
                                },
                                [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "checkbox-item ml-2 mt-2",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: personitem.is_checked,
                                              expression:
                                                "personitem.is_checked",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            disabled: true,
                                            "true-value": 1,
                                            "false-value": 0,
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              personitem.is_checked
                                            )
                                              ? _vm._i(
                                                  personitem.is_checked,
                                                  null
                                                ) > -1
                                              : _vm._q(
                                                  personitem.is_checked,
                                                  1
                                                ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = personitem.is_checked,
                                                $$el = $event.target,
                                                $$c = $$el.checked ? 1 : 0
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      personitem,
                                                      "is_checked",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      personitem,
                                                      "is_checked",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  personitem,
                                                  "is_checked",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _c("div", { staticClass: "info-block" }, [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              personitem.key_contact_title
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              personitem.key_contact_info
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm.contentType === "KNOWLEDGE_REQUIREMENT_MANAGER"
                  ? _c(
                      "div",
                      { staticClass: "mobile-content__knowledge" },
                      [
                        _c(
                          "div",
                          { staticClass: "knowledge-preview--container" },
                          [
                            _c(
                              "div",
                              { staticClass: "d-block" },
                              [
                                _c("div", { staticClass: "px-0 pagefont" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "knowledge_newhire_questions_for_manager",
                                          {
                                            name:
                                              _vm.content &&
                                              _vm.content.target_user &&
                                              _vm.content.target_user.first_name
                                                ? _vm.content.target_user
                                                    .first_name
                                                : "",
                                          }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", { staticClass: "px-0 subfont" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "knowledge_newhire_questions_desc_for_manager",
                                          {
                                            name:
                                              _vm.content &&
                                              _vm.content.target_user &&
                                              _vm.content.target_user.first_name
                                                ? _vm.content.target_user
                                                    .first_name
                                                : "",
                                          }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._l(
                                  _vm.content.knowledge_requirements,
                                  function (requirement, ix) {
                                    return _c(
                                      "div",
                                      {
                                        key: requirement.id,
                                        staticClass: "contacts_block clickable",
                                        on: {
                                          click: function ($event) {
                                            return _vm.previewKnowledgeReqNext(
                                              ix
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "checkbox-item" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: requirement.is_checked,
                                                  expression:
                                                    "requirement.is_checked",
                                                },
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                disabled: true,
                                                "true-value": 1,
                                                "false-value": 0,
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  requirement.is_checked
                                                )
                                                  ? _vm._i(
                                                      requirement.is_checked,
                                                      null
                                                    ) > -1
                                                  : _vm._q(
                                                      requirement.is_checked,
                                                      1
                                                    ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      requirement.is_checked,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked ? 1 : 0
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          requirement,
                                                          "is_checked",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          requirement,
                                                          "is_checked",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      requirement,
                                                      "is_checked",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "info-block" },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    requirement.knowledge_title
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "arrow-block" },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                attrs: {
                                                  name: "rarrow",
                                                  height: "20",
                                                  width: "20",
                                                  "view-box": "0 0 24 24",
                                                },
                                              },
                                              [_c("icon-rarrow")],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                        _vm._l(
                          _vm.content.knowledge_requirements,
                          function (requirementdetail, ind) {
                            return [
                              _vm.previewKnowledgeStep === ind
                                ? _c(
                                    "div",
                                    {
                                      key: "kr" + requirementdetail.id,
                                      staticClass:
                                        "knowledge-preview--container",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "goal-block p-2" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "blue-box" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("goal", {
                                                      index: ind + 1,
                                                    })
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  requirementdetail.knowledge_title
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "my-2 pagefont" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "knowledge_newhire_goal_msg"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      requirementdetail.support_elements &&
                                      requirementdetail.support_elements
                                        .contacts &&
                                      requirementdetail.support_elements
                                        .contacts.length
                                        ? _c("section", [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex mt-4" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex pagefont no-wspace",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s("A - ") + " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "m-0 pl-2 pagefont",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "knowledge_newhire_sectiona_title"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "contact_section p-2",
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        requirementdetail
                                                          .support_elements
                                                          .contacts[0]
                                                          .key_contact_title
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        requirementdetail
                                                          .support_elements
                                                          .contacts[0]
                                                          .key_contact_email
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        requirementdetail
                                                          .support_elements
                                                          .contacts[0]
                                                          .key_contact_info
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                      requirementdetail.support_elements &&
                                      requirementdetail.support_elements
                                        .documents &&
                                      requirementdetail.support_elements
                                        .documents.length
                                        ? _c(
                                            "section",
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex mt-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex pagefont no-wspace",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            requirementdetail
                                                              .support_elements
                                                              .contacts.length
                                                              ? "B - "
                                                              : "A - "
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "m-0 pl-2 pagefont",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "knowledge_newhire_sectionb_title"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._l(
                                                requirementdetail
                                                  .support_elements.documents,
                                                function (document, dindex) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: `documents${document.id}${dindex}`,
                                                      staticClass:
                                                        "contact_section p-2",
                                                    },
                                                    [
                                                      _vm.$helpers.isValidUrl(
                                                        document.document_url
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "clickable",
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: document.document_url,
                                                                    target:
                                                                      "_blank",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      document.document_url
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  document.document_url
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      requirementdetail.support_elements &&
                                      requirementdetail.support_elements
                                        .assignments &&
                                      requirementdetail.support_elements
                                        .assignments.length
                                        ? _c(
                                            "section",
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex mt-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex pagefont no-wspace",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            requirementdetail
                                                              .support_elements
                                                              .contacts
                                                              .length &&
                                                              requirementdetail
                                                                .support_elements
                                                                .documents
                                                                .length
                                                              ? "C - "
                                                              : requirementdetail
                                                                  .support_elements
                                                                  .contacts
                                                                  .length ===
                                                                  0 &&
                                                                requirementdetail
                                                                  .support_elements
                                                                  .documents
                                                                  .length === 0
                                                              ? "A - "
                                                              : "B - "
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "m-0 pl-2 pagefont",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "knowledge_newhire_sectionc_title"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._l(
                                                requirementdetail
                                                  .support_elements.assignments,
                                                function (assignment, aindex) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: `assignments${assignment.id}${aindex}`,
                                                      staticClass:
                                                        "contact_section p-2",
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              assignment.requirement_title
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-space-between align-content-center ml-10 btnspacer",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button button--secondary size-sm",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewKnowledgeReqNext(
                                                    -1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("continue_")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.contentType === "BUDDY_MANAGER"
                  ? _c("div", { staticClass: "mobile-content__onboarding" }, [
                      _c(
                        "div",
                        { staticClass: "onboarding-preview--container" },
                        [
                          _c("div", { staticClass: "d-flex" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "buddy_newhire_entry_msg_for_manager",
                                    {
                                      name:
                                        _vm.content &&
                                        _vm.content.target_user &&
                                        _vm.content.target_user.first_name
                                          ? _vm.content.target_user.first_name
                                          : "",
                                      buddy:
                                        _vm.content &&
                                        _vm.content.manager_contacts &&
                                        _vm.content.manager_contacts.length
                                          ? _vm.content.manager_contacts[0].key_contact_title.split(
                                              " "
                                            )[0]
                                          : "",
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "contacts_block mx-0 p-0" },
                            [
                              _c("div", { staticClass: "ma-2 px-2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.content.manager_contacts[0]
                                        .key_contact_title
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", { staticClass: "ma-2 px-2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.content.manager_contacts[0]
                                        .key_contact_info
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.contentType === "FILE_DOWNLOAD"
                  ? _c("div", { staticClass: "mobile-content__filedownload" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(" " + _vm._s(_vm.content.desc) + " "),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "mt-4" },
                        [
                          _vm._l(
                            _vm.content.file_download_content.files,
                            function (filedownloaditem, index) {
                              return [
                                filedownloaditem.title
                                  ? _c(
                                      "li",
                                      {
                                        key: index,
                                        staticClass: "filedownload-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.openFileDownloadItem(
                                              filedownloaditem
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "filedownload-item-content",
                                          },
                                          [
                                            filedownloaditem.title
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "filedownload-item--title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          filedownloaditem.title
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            filedownloaditem.desc
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "filedownload-item--desc",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          filedownloaditem.desc
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "filedownload-item--icon",
                                          },
                                          [
                                            _c(
                                              "icon-container",
                                              {
                                                staticClass: "icon-svg",
                                                attrs: {
                                                  name: "downloadfull",
                                                  width: "13",
                                                  height: "14",
                                                  "is-icon-class": false,
                                                },
                                              },
                                              [_c("icon-download")],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm.contentType === "CHECKLIST" &&
                _vm.content.checklist.items.length > 0
                  ? _c("div", { staticClass: "mobile-content__checklist" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(" " + _vm._s(_vm.content.name) + " "),
                      ]),
                      _c(
                        "ul",
                        _vm._l(
                          _vm.content.checklist.items,
                          function (checklistItem, index) {
                            return _c("li", { key: index }, [
                              checklistItem.item_text
                                ? _c("span", [
                                    _vm._v(_vm._s(checklistItem.item_text)),
                                  ])
                                : _vm._e(),
                            ])
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.contentType === "SURVEY" &&
                _vm.content.survey.questions.length > 0
                  ? _c("div", { staticClass: "mobile-content__survey" }, [
                      _c(
                        "div",
                        { staticClass: "survey-preview--container" },
                        [
                          _vm._l(
                            _vm.content.survey.questions,
                            function (surveyQuestion, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "survey-preview--question",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "question-content" },
                                    [
                                      _c("h5", [
                                        _vm._v(
                                          _vm._s(
                                            index +
                                              1 +
                                              ". " +
                                              surveyQuestion.question
                                          )
                                        ),
                                      ]),
                                      _c("p", { staticClass: "subfont" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(surveyQuestion.sub_text) +
                                            " "
                                        ),
                                      ]),
                                      surveyQuestion.type ===
                                        "multiple-choice" ||
                                      surveyQuestion.type === "single-choice"
                                        ? _c(
                                            "ul",
                                            {
                                              class: {
                                                haveImages:
                                                  _vm.$helpers.haveSurveyQuestionImages(
                                                    surveyQuestion.choices
                                                  ),
                                              },
                                            },
                                            [
                                              _vm._l(
                                                surveyQuestion.choices,
                                                function (choice, ssindex) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: ssindex,
                                                      staticClass: "survey",
                                                    },
                                                    [
                                                      choice.imagePreview ||
                                                      choice.image
                                                        ? _c(
                                                            "picture",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-column",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-row",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "optiontick",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$helpers.choiceLetter(
                                                                              ssindex
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src:
                                                                        choice.imagePreview ||
                                                                        choice.image,
                                                                      alt: "Choice Image",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-flex p-2",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "optiontick",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$helpers.choiceLetter(
                                                                          ssindex
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    choice.choice_text
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              surveyQuestion &&
                                              surveyQuestion.options &&
                                              surveyQuestion.options
                                                .has_other_option
                                                ? _c(
                                                    "li",
                                                    {
                                                      key: `other${surveyQuestion.id}`,
                                                      staticClass: "survey",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "d-flex p-2",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "optiontick",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$helpers.choiceLetter(
                                                                    surveyQuestion
                                                                      .choices
                                                                      .length
                                                                  )
                                                                ) + " "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t("other")
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      surveyQuestion.type === "text"
                                        ? _c(
                                            "div",
                                            { staticClass: "survey-textarea" },
                                            [
                                              _c("textarea", {
                                                attrs: {
                                                  name: "",
                                                  id: "",
                                                  placeholder:
                                                    _vm.$t("type_here"),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      surveyQuestion.type === "rating"
                                        ? _c(
                                            "div",
                                            [
                                              surveyQuestion.options.shape ===
                                              "star"
                                                ? [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "rating-box",
                                                      },
                                                      [
                                                        _vm._l(
                                                          Number(
                                                            surveyQuestion
                                                              .options.step
                                                          ),
                                                          function (sindex) {
                                                            return [
                                                              _c("img", {
                                                                key: `star${index}${sindex}`,
                                                                attrs: {
                                                                  src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA5CAYAAABj2ui7AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM3SURBVHgB3VqBdeIwDBX3bgA2uIzABucNSidoNigbwE1Ab4LABu0EoRPQDdIN6AaqUuQXxYTEEMsJ/Pf8BLZjWfa3ZSsBGACIOIVI+AXDYAH3Cpq9OaUDRMIQM/hAaUpGziAChjDQOPJ+QLNmsEIO9wYyKsM61HfT2BQ1LL+c/2qIZiBvKgmlT0r/OdvAvYAMfGFaZmItRnMX6iBj9myU4f8H/h/FXaiCjEjcGaPfG85TPdXEWoNzlq8ib8fyAW4dpc/j2ZqLvGlMd6EGQc8TQ5oMD40YFDUs3yaTyZdT9ubUuT2IWUobymY37S6cdZacqaPqLrQpatfWjuj5eabOlqUBBWgb+JfltqXOjuXtuQtBv6Slzm26C3He3HvUzbXcxW9WkJJYQljY2dh61C3dhaGUUV/WoIHSSEoFhkfn7oiVuwiJcnnMJ46ihEQZSkg4a0PpH/RAy+4JDbr7oDy0P/PvD0qPZ3WTspUYiQxHvPjxuEllor8vvg+mWO2CRYARDg6mdiEomV7yvD0oF2J0RhORpr48iwnY95oArMINg1O2iZJB+jMGyjqMupySVyiIRlmHknoDzBSRlF1rUpb1rYNT0kPxQntEh2TMuQ6kEAjU1hOOwU01UDaFnsDjO0SLDY7hoCFG20BPYHUTKWAMwIbAboA27YD9gR4IdaM3LN8hHGzE7RF6IJSBTyxfIRx2LIcPZYgNIYFAwCqUcRh0k8ELQhNXtJ333bhCUNTS02v9sQvIPGfbtqkW2u+EcPTGo648chVdRg7uLoR7KDzq5Y5xdn0tOp4N4i6uAh6vUCWyljoG60cuw/m1A3vL8xuuE/+yjS0vVrh8KYzI8fT12UKUN97KxSDG/65GdC5x8l1KLlrakAf2Us6c8mHcBVYbQN6QX7iU7GjLHZClU27LOtsKBqziIiuRt8Q6JRO4AHgmXCny/UKCISBmyTCNctG5FVwJ5M8tBQMSjO0uULiHayjp2b5t8+cijTHdBVY72wF7ULJDR8mKjWjf6tJ3F1ino+rawPq6/BlI0IYzqgaUgachej13IRZ8HpKSHnrLdVn73k1LUYYxt+tT/StV/TiCrwPxglfd33vRYRyPRxhZAAAAAElFTkSuQmCC",
                                                                  alt: "star",
                                                                },
                                                              }),
                                                            ]
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              surveyQuestion.options.shape ===
                                                "thumb" ||
                                              surveyQuestion.options.shape ===
                                                "thumbsup"
                                                ? [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "rating-box",
                                                      },
                                                      [
                                                        _vm._l(
                                                          Number(
                                                            surveyQuestion
                                                              .options.step
                                                          ),
                                                          function (tindex) {
                                                            return [
                                                              _c("img", {
                                                                key: `thumb${index}${tindex}`,
                                                                attrs: {
                                                                  src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALtSURBVHgB1VrbddswDIV6+h+PwA3qDaJu4E5gd4K6E0SdoO4EdiaIO4G1gesJ7A2cDVBCAiMI1suJHuQ9hw5JUQouCQIgJICJgYhzWw6Y42rL1hYDIYEEZuE1zkGREStBODIBSWYGvoNXw2Ev+l9EfwK+wwq5FgIvRP9MqNsZfIdSq5m6thPX5l2e9wkmAAseczONouhVDUlF3V8iFgtR/1tx/Z+oG+iAqYg8ifoeesDoRKxaEQnDzZ1Vq0vFMLlnXsE3WBJLsYmvdU6vzqJ5AUWCsG4YK32JAV9A6qRIJA1jpaM8gi+4hwSP34qxK/ABVpDfisSqZbxcDT+8uloJ2tjzlvEUmpy9Wg28Dc9bvbNSqS34ACVU0mF8LFUKfbBUVojFvXquiMfwDkTQM1h4w81v1nO3hiD2nhco4q+0ZTh5eorP0pqo4ONQG7yzntPGxvtxxiEMApZNZ2340ULGHXebisYT9An1TxIYCDxhiSITQx/AskqN4siwrI4HeA94VlxZqtkxMBJQ5MM+i84Y8mOlTsGQldgrC1E3678GsyTVcGeVXGa8DeywaVPVXN/ByJArEmFuwrbM7hluT2TGliXXf9oZ39Cd3P5jy4YqI68EsAo7zUip48isVg03LeSmEquQwEQQcueyu1rLTTM5zhMiDldqd0o+VOSdfEDKf2mSV1Olg/rAd1FfBEuEjcuFm19CXhGC83mXYImwX3NETp2sFt/ojdXC/HWdhAl1RWT2MQuLQiUis/WZegVJhI/PzrcZ+gnZarmN/kA/QRJRceHJdQZltbCcbsosFvWHuCI/RP3tIBcikZOov+XMQiRS+aKUiGRmDBs+l8AioaDD+QcYH4+iXsiDxbn3wK7fqEJ97jS25XskzjhS5oRlqfwqImIh6AhrWp5zseUrba4aK5dCEVYPgTmUPx6gjZ64RpbEZjLU+VjzEEoab5yFwCKvG0PHF/o949nKsoK+gPlZfo3lRMBQIJU6YE2S5D/iNRDHqdhCtAAAAABJRU5ErkJggg==",
                                                                  alt: "thumb",
                                                                },
                                                              }),
                                                            ]
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              surveyQuestion.options.shape ===
                                              "number"
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "d-block",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "rating-number-box",
                                                          },
                                                          _vm._l(
                                                            Number(
                                                              surveyQuestion
                                                                .options.step
                                                            ) + 1,
                                                            function (
                                                              notusedindex,
                                                              nindex
                                                            ) {
                                                              return _c(
                                                                "span",
                                                                {
                                                                  key: `mum${nindex}`,
                                                                  staticClass:
                                                                    "number-cell",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        nindex
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "rating-number-info",
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  surveyQuestion
                                                                    .options
                                                                    .min_value
                                                                )
                                                              ),
                                                            ]),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  surveyQuestion
                                                                    .options
                                                                    .max_value
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "survey-preview--button",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button button--secondary size-sm",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewQuestionNext(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("next")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "survey-preview--progress" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.content &&
                                              _vm.content.survey &&
                                              _vm.content.survey.questions
                                              ? parseInt(
                                                  (100 /
                                                    _vm.content.survey.questions
                                                      .length) *
                                                    (index + 1),
                                                  10
                                                ) +
                                                  "% " +
                                                  _vm.$t("completed_")
                                              : ""
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "survey-progress-bar" },
                                        [
                                          _c("span", {
                                            style: {
                                              width:
                                                _vm.content &&
                                                _vm.content.survey &&
                                                _vm.content.survey.questions
                                                  ? parseInt(
                                                      (100 /
                                                        _vm.content.survey
                                                          .questions.length) *
                                                        (index + 1),
                                                      10
                                                    ) + "%"
                                                  : "100%",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          _c(
                            "div",
                            { staticClass: "survey-preview--question" },
                            [
                              _c("div", { staticClass: "survey-finalpage" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-center text-center mt-4",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("thanks_feedback")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "survey-preview--button" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "button button--secondary size-sm",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.previewQuestionNext(-1)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("close")) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm.contentType === "REQUEST" &&
                _vm.content.request.questions.length > 0
                  ? _c("div", { staticClass: "mobile-content__request" }, [
                      _c(
                        "div",
                        { staticClass: "request-preview--container" },
                        [
                          _vm._l(
                            _vm.content.request.questions,
                            function (requestQuestion, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "request-preview--question",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "question-content" },
                                    [
                                      _c("h5", [
                                        _vm._v(
                                          _vm._s(
                                            `${index + 1}. ` +
                                              requestQuestion.question
                                          )
                                        ),
                                      ]),
                                      _c("p", { staticClass: "subfont ml-3" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(requestQuestion.sub_text) +
                                            " "
                                        ),
                                      ]),
                                      requestQuestion.type === "get_info"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "request-info-input ml-2",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.convertFormat(
                                                      requestQuestion.options
                                                        .format
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      requestQuestion.type === "image" ||
                                      requestQuestion.type === "file"
                                        ? _c(
                                            "div",
                                            { staticClass: "imagefile-box" },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s("+"),
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "request-preview--button",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "button button--secondary size-sm",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewQuestionNext(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("next")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "request-preview--progress",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.content.request.questions
                                              ? parseInt(
                                                  (100 /
                                                    _vm.content.request
                                                      .questions.length) *
                                                    (index + 1),
                                                  10
                                                ) +
                                                  "% " +
                                                  _vm.$t("completed_")
                                              : ""
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "request-progress-bar" },
                                        [
                                          _c("span", {
                                            style: {
                                              width: _vm.content.request
                                                .questions
                                                ? parseInt(
                                                    (100 /
                                                      _vm.content.request
                                                        .questions.length) *
                                                      (index + 1),
                                                    10
                                                  ) + "%"
                                                : "100%",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          _c(
                            "div",
                            { staticClass: "request-preview--question" },
                            [
                              _c("div", { staticClass: "request-finalpage" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-center text-center mt-4",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("thanks_feedback_info")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "request-preview--button" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "button button--secondary size-sm",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.previewQuestionNext(-1)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("close")) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm.contentType !== "INFOGRAPHIC" &&
                _vm.contentType !== "CHECKLIST" &&
                _vm.contentType !== "PODCAST" &&
                _vm.contentType !== "FILE_DOWNLOAD" &&
                _vm.contentType !== "FLIPBOOK" &&
                _vm.contentType !== "KNOWLEDGE_REQUIREMENT_MANAGER" &&
                _vm.contentType !== "BUDDY_MANAGER" &&
                _vm.contentType !== "KEY_CONTACT_MANAGER" &&
                !_vm.showVideo
                  ? _c(
                      "div",
                      {
                        staticClass: "mobile-context",
                        style: _vm.showVideo ? "z-index: -2" : "",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "mobile-content__media",
                            style: {
                              "background-image":
                                "url(" + _vm.content.thumbnail_image + ")",
                            },
                          },
                          [
                            _vm.contentType === "VIDEO" &&
                            _vm.content.content_url
                              ? _c("div", { staticClass: "videocontrols" }, [
                                  _c("button", { on: { click: _vm.play } }, [
                                    _c(
                                      "svg",
                                      {
                                        staticStyle: {
                                          fill: "#fff",
                                          "fill-opacity": "0.5",
                                        },
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          width: "26",
                                          height: "26",
                                          viewBox: "0 0 26 26",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M20.2 11.9L6.9 5.3C6.5 5.1 6 5.1 5.6 5.3 5.2 5.5 5 6 5 6.4L5 19.6C5 20 5.2 20.5 5.6 20.7 5.8 20.8 6.1 20.9 6.3 20.9 6.5 20.9 6.7 20.8 6.9 20.7L20.2 14.1C20.6 13.9 20.9 13.5 20.9 13 20.9 12.5 20.6 12.1 20.2 11.9Z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.contentType !== "INFOGRAPHIC" &&
                            _vm.contentType !== "FLIPBOOK"
                              ? _c(
                                  "small",
                                  { staticClass: "mobile-content__info" },
                                  [_vm._v(" " + _vm._s(_vm.content.info) + " ")]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c("small", { staticClass: "mobile-content__type" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$helpers.getSubTypeName(
                                  _vm.content.sub_type
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _vm.contentType !== "INFOGRAPHIC" &&
                        _vm.contentType !== "FLIPBOOK" &&
                        _vm.contentType !== "BOOK_SUGGESTION" &&
                        _vm.contentType !== "QUOTES"
                          ? _c(
                              "h2",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.showVideo,
                                    expression: "!showVideo",
                                  },
                                ],
                                staticClass: "mobile-content__title",
                              },
                              [_vm._v(" " + _vm._s(_vm.content.name) + " ")]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm.contentType !== "INFOGRAPHIC" &&
                _vm.contentType !== "CHECKLIST" &&
                _vm.contentType !== "FILE_DOWNLOAD" &&
                _vm.contentType !== "FLIPBOOK" &&
                _vm.contentType !== "PODCAST" &&
                _vm.contentType !== "KNOWLEDGE_REQUIREMENT_MANAGER" &&
                _vm.contentType !== "BUDDY_MANAGER" &&
                _vm.contentType !== "KEY_CONTACT_MANAGER"
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.showVideo,
                            expression: "!showVideo",
                          },
                        ],
                        staticClass: "mobile-content__main",
                        style: _vm.showVideo ? "z-index: -2" : "",
                      },
                      [_c("p", [_vm._v(_vm._s(_vm.content.desc))])]
                    )
                  : _vm._e(),
                _vm.contentType === "ARTICLE"
                  ? _c("div", { staticClass: "mobile-content__main" }, [
                      _vm.injectedArticleHtml
                        ? _c("iframe", {
                            directives: [
                              { name: "resize", rawName: "v-resize" },
                            ],
                            attrs: {
                              title: "Article",
                              id: "articleIframe",
                              width: "270",
                              srcdoc: _vm.injectedArticleHtml,
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.contentType === "notification"
                  ? _c("div", { staticClass: "notification-content" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/notif_background.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "notification-content__time" }, [
                        _vm._v(
                          " " + _vm._s(_vm.notification_preview_time) + " "
                        ),
                      ]),
                      _c("div", { staticClass: "notification-content__date" }, [
                        _vm._v(
                          " " + _vm._s(_vm.notification_preview_date) + " "
                        ),
                      ]),
                      _c("div", { staticClass: "notification-content__info" }, [
                        _c(
                          "div",
                          { staticClass: "notification-content__info-content" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "notification-content__info-header",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/journey-app-icon.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(
                                  _vm._s(_vm.$t("snotification_preview")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "notification-content__info-title",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.notification_title) + " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "notification-content__info-body",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.notification_body) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _vm.launchedFrom === "ContentLibrary" && !_vm.isDisabled
          ? _c("div", { staticClass: "add-action-button" }, [
              _c(
                "button",
                {
                  staticClass: "button button--alternative button--icon u-full",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addContentItem()
                    },
                  },
                },
                [
                  _c(
                    "icon-container",
                    {
                      attrs: {
                        name: "circleplus",
                        color: "#fff",
                        width: "18",
                        height: "18",
                        "view-box": "0 0 24 24",
                        "is-icon-class": false,
                      },
                    },
                    [_c("icon-circleplus")],
                    1
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("add_to_journey")) + " "),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }