var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page mb-4 pb-4" },
    [
      !_vm.propUserId
        ? _c(
            "app-header",
            {
              attrs: {
                "child-header-class": "container",
                "child-header-navigation": _vm.userSubNavigation,
                "is-child-header-left-actions": "",
              },
            },
            [
              _c("filter-perpage", {
                on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("main", { staticClass: "container mb-4 pb-4" }, [
        _c("div", { staticClass: "vuetable-wrapper" }, [
          _vm.$route.params.journeyId && !_vm.propUserId
            ? _c(
                "div",
                {
                  staticClass:
                    "mb-4 navigation text-capitalize d-flex items-center",
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: `/dashboard/journeys/users/${_vm.$route.params.journeyId}`,
                          query: { journey: _vm.journeyName },
                        },
                      },
                    },
                    [
                      _c(
                        "icon-container",
                        {
                          attrs: {
                            width: 40,
                            height: 18,
                            name: "Go Back",
                            color: "#727d92",
                          },
                        },
                        [_c("icon-goback")],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.userName) + " " + _vm._s(_vm.userSurname)
                      ),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.userEmployeeNo))]),
                    _c("span", [_vm._v(_vm._s(_vm.userEmail))]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.tableIsLoading
            ? _c("div", { staticClass: "lds-dual-ring" })
            : _vm._e(),
          _vm.userId
            ? _c(
                "div",
                { staticClass: "table-responsive" },
                [
                  _c("vue-table", {
                    ref: "myvuetable",
                    class: { "table-fade": _vm.tableIsLoading },
                    attrs: {
                      "api-url": _vm.apiUrl,
                      "http-fetch": _vm.myFetch,
                      "api-mode": true,
                      "pagination-path": "meta",
                      "http-method": "post",
                      "append-params": _vm.moreParams,
                      "http-options": {
                        headers: {
                          Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                        },
                      },
                      fields: _vm.fields,
                      "no-data-template": _vm.$t("no_data_info"),
                      css: _vm.css.table,
                      "per-page": _vm.perPage,
                      "query-params": _vm.makeQueryParams,
                    },
                    on: {
                      "vuetable:loading": function ($event) {
                        _vm.tableIsLoading = true
                      },
                      "vuetable:loaded": function ($event) {
                        _vm.tableIsLoading = false
                      },
                      "vuetable:load-error": _vm.vuetableLoadError,
                      "vuetable:pagination-data": _vm.onPaginationData,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "name",
                          fn: function (props) {
                            return [
                              _vm.$route.params.journeyId && !_vm.propUserId
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          path: `/dashboard/journeys/users/${_vm.$route.params.journeyId}/user/${_vm.userId}/${props.rowData.id}`,
                                          query: {
                                            journey: _vm.$route.query.journey,
                                            name: _vm.userName,
                                            surname: _vm.userSurname,
                                            employee_no: _vm.userEmployeeNo,
                                            email: _vm.userEmail,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(props.rowData.name) + " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "pointer-action",
                                      on: {
                                        click: function ($event) {
                                          return _vm.popUp(props.rowData)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(props.rowData.name) + " "
                                      ),
                                    ]
                                  ),
                            ]
                          },
                        },
                        {
                          key: "actions",
                          fn: function (props) {
                            return [
                              _c(
                                "button",
                                {
                                  staticClass: "ui button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onAction(
                                        "user-menu",
                                        props.rowData
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "icon-container",
                                    {
                                      attrs: {
                                        width: 20,
                                        height: 20,
                                        "view-box": "0 0 24 24",
                                        name: "threedots",
                                        color: "#727d92",
                                      },
                                    },
                                    [_c("icon-threedots")],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2472002782
                    ),
                  }),
                  _c(
                    "div",
                    { staticClass: "pagination__info" },
                    [
                      _c("vuetable-pagination-info", {
                        ref: "paginationInfo",
                        class: { "table-fade": _vm.tableIsLoading },
                        attrs: {
                          "info-template": _vm.$t("pagination_info"),
                          "no-data-template": _vm.$t("no_data_info"),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "pagination__items" },
                        [
                          _c("vuetable-pagination", {
                            ref: "pagination",
                            attrs: { css: _vm.css.pagination },
                            on: {
                              "vuetable-pagination:change-page":
                                _vm.onChangePage,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }