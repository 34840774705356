var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-center justify-center" },
    _vm._l(_vm.count, function (n) {
      return _c(
        "icon-container",
        {
          key: n,
          attrs: {
            name: "sprint",
            "view-box": "0 0 14 15",
            width: 16,
            height: 18,
            color: n <= _vm.rate ? _vm.colorHex : _vm.defaultHex,
          },
        },
        [_c("sprint-icon")],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }