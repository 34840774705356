var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page mb-4 pb-4" },
    [
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "child-header-navigation": _vm.userSubNavigation,
            "is-child-header-left-actions": "",
          },
        },
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.tableIsLoading
          ? _c("div", { staticClass: "lds-dual-ring" })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c("vue-table", {
              ref: "myvuetable",
              class: { "table-fade": _vm.tableIsLoading },
              attrs: {
                "api-url": _vm.apiUrl,
                "http-fetch": _vm.myFetch,
                "api-mode": true,
                "pagination-path": "meta",
                "http-method": "get",
                "append-params": _vm.moreParams,
                "http-options": {
                  headers: {
                    Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                  },
                },
                fields: _vm.fields,
                "no-data-template": _vm.$t("no_data_info"),
                css: _vm.css.table,
                "per-page": _vm.perPage,
                "query-params": _vm.makeQueryParams,
              },
              on: {
                "vuetable:loading": function ($event) {
                  _vm.tableIsLoading = true
                },
                "vuetable:loaded": function ($event) {
                  _vm.tableIsLoading = false
                },
                "vuetable:load-error": _vm.vuetableLoadError,
                "vuetable:pagination-data": _vm.onPaginationData,
              },
              scopedSlots: _vm._u([
                {
                  key: "date-slot",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getCurrentLocaleDate(props.rowData.created_at)
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "name-slot",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            props.rowData.friendly_name &&
                              props.rowData.friendly_name !== "" &&
                              props.rowData.friendly_name != null
                              ? props.rowData.friendly_name
                              : _vm.getTypeName(props.rowData.type)
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "type-slot",
                  fn: function (props) {
                    return [
                      _c(
                        "span",
                        {
                          attrs: { title: _vm.getTypeName(props.rowData.type) },
                        },
                        [_vm._v(_vm._s(props.rowData.type))]
                      ),
                    ]
                  },
                },
                {
                  key: "status-slot",
                  fn: function (props) {
                    return [
                      _c("div", [
                        props && props.rowData && props.rowData.is_ready
                          ? _c("span", { staticClass: "status-generated" }, [
                              _vm._v(_vm._s(_vm.$t("generated"))),
                            ])
                          : _c("span", { staticClass: "status-generating" }, [
                              _vm._v(_vm._s(_vm.$t("generating"))),
                            ]),
                      ]),
                    ]
                  },
                },
                {
                  key: "action-slot",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "ui button",
                          attrs: { disabled: !props.rowData.is_ready },
                          on: {
                            click: function ($event) {
                              return _vm.onAction("download", props.rowData)
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                width: "13",
                                height: "14",
                                name: "download",
                                color: "#727d92",
                              },
                            },
                            [_c("icon-download")],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              { staticClass: "pagination__info" },
              [
                _c("vuetable-pagination-info", {
                  ref: "paginationInfo",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "info-template": _vm.$t("pagination_info"),
                    "no-data-template": _vm.$t("no_data_info"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "pagination__items" },
                  [
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }