<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{'is-loading': formSending }"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ $t('add_user_to_group') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="tab-content">
              <div class="tab-pane">
                <div class="form-group">
                  <label
                    for="user"
                    class="form-label"
                  >{{ $t('users') }}</label>
                  <vue-tags-input
                    :class="{ 'is-danger': errors.has('add.user') }"
                    v-model="user"
                    :tags="users"
                    @tags-changed="inputUpdateUsers"
                    :autocomplete-items="usersToAdd"
                    :add-only-from-autocomplete="true"
                    :delete-on-backspace="false"
                    :placeholder="$t('adduser_placeholder')"
                  />
                  <input
                    type="hidden"
                    data-vv-scope="add"
                    data-vv-name="user"
                    :data-vv-as="$t('users')"
                    v-model="usersSelected"
                    v-validate="'required'"
                  >
                  <span
                    v-if="errors.has('add.user')"
                    class="help is-danger"
                  >{{ errors.first('add.user') }}</span>
                </div>
                <div
                  v-show="errors.items.length > 0"
                  class="form-group"
                >
                  <div class="alert alert--card alert--error">
                    <p>{{ $t('error_msg_title') }}</p>
                    <ul>
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                      >
                        {{ error.msg }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="button button--primary no-wrap"
                    @click="addUser()"
                    :disabled="isPending"
                  >
                    <span
                      v-if="!isPending"
                    >
                      {{ $t('add') }}
                    </span>
                    <div
                      class="lds-ellipsis"
                      v-if="isPending"
                    >
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import vueTagsInput from '@johmun/vue-tags-input';
import API from '@/services/';
import {
  API_USER_DATATABLES,
  API_USER_GROUP_USER,
} from '@/helpers/config';

export default {
  name: 'ModalGroupAddUser',
  inject: ['$validator'],

  data() {
    return {
      isPending: false,
      formSending: false,
      usersToAdd: [],
      user: '',
      users: [],
      debounce: null,
      usersSelected: null,
    };
  },

  components: {
    vueTagsInput,
  },

  watch: {
    user: 'getUsers',
    users() {
      if (this.users && this.users.length > 0) {
        this.usersSelected = this.users[0].text;
      } else {
        this.usersSelected = null;
      }
      this.$nextTick(() => {
        this.$validator.validate('add.user', this.usersSelected);
      });
    },
  },

  computed: {
    groupId() {
      return this.$store.state.modal.groupId;
    },
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },
      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },
  },

  methods: {

    closeModal() {
      this.$store.commit('modal/toggleModal');
    },

    inputUpdateUsers(newTags) {
      this.users = newTags;
    },

    getUsers() {
      this.usersToAdd = [];
      const formData = { params: {} };
      formData.params.per_page = 10000;
      formData.params.customer_id = this.$store.state.auth.customerId;
      formData.params.search = this.user;
      if (this.user.length < 2) {
        return;
      }
      const searchInput = document.querySelector('.ti-new-tag-input');
      searchInput.classList.add('loading-icon');
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        API.get(API_USER_DATATABLES, formData)
          .then((response) => {
            this.usersToAdd = response.data.data.map(a => (
              {
                text: `${a.first_name} ${a.last_name} [ ${a.email} ]`,
                id: `${a.id}`,
              }
            ));
            searchInput.classList.remove('loading-icon');
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            searchInput.classList.remove('loading-icon');
          });
      }, 300);
    },

    addUser() {
      this.$validator.validate('add.user').then((result) => {
        if (result && this.users) {
          this.formSending = true;
          this.isPending = true;
          const self = this;
          const successMsg = self.$t('added_success');
          this.users.forEach((user) => {
            const formData = new FormData();
            formData.append('group_id', self.groupId);
            formData.append('user_id', user.id);

            API.post(API_USER_GROUP_USER, formData)
              .then((response) => {
                if (response.status === 200) {
                  self.$snotify.success(successMsg);
                  this.$store.commit('modal/REFRESH_PAGE', true);
                }
              })
              .catch((error) => {
                self.formSending = false;
                self.isPending = false;
                self.$helpers.displayError(error);
              });
          });
          if (self.isPending) {
            self.closeModal();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check {
  .form-label {
    font-size: 0.9rem;
    margin-left: 1rem;
    margin-bottom: 0;
    color: #142b58;
  }
}
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}
.modal-body {
  h4 {
    color: #727d92;
  }
}
.blur {
  filter: blur(3px);
}
.no-wrap {
  white-space: nowrap;
}
</style>
