var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("th", {
    staticClass: "vuetable-th-gutter",
    style: { width: _vm.vuetable.scrollBarWidth },
    attrs: { scope: "col" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }