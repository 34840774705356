var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", { attrs: { fill: "currentColor", "fill-rule": "evenodd" } }, [
    _c("path", {
      attrs: {
        d: "M16.1093333 14.8614857H3.74685714V10.1872c0-3.4087619 2.77276191-6.18057143 6.18114286-6.18057143 3.4085714 0 6.1813333 2.77180953 6.1813333 6.18057143v4.6742857zm2.8209524 0h-.9689524V10.1872c0-4.11619048-3.1125714-7.5167619-7.1072381-7.97752381V.92605714c0-.512-.4148571-.92590476-.9260952-.92590476-.51104762 0-.92590476.41390476-.92590476.92590476V2.2096762C5.00742857 2.6704381 1.89504762 6.07100952 1.89504762 10.1872v4.6742857H.92590476C.41485714 14.8614857 0 15.275581 0 15.7873905c0 .512.41485714.9260952.92590476.9260952H18.9302857c.5112381 0 .9259048-.4140952.9259048-.9260952 0-.5118095-.4146667-.9259048-.9259048-.9259048zM10.61742838 18.22104714H9.19590457c-.5112381 0-.92609524.41409524-.92609524.92590476 0 .512.41485715.92609524.92609524.92609524h1.42152381c.5112381 0 .92590476-.41409524.92590476-.92609524 0-.51180952-.41466666-.92590476-.92590476-.92590476",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }