<template>
  <div
    class="modal-container"
  >
    <div
      class="modal"
      :class="{'is-loading': formSending }"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <div class="d-flex flex-column justify-content-center align-items-center py-4">
              <h5 class="modal-title">
                {{ $t('add_content_journey') }}
              </h5>
              <div class="d-block my-2">
                {{ contentDetail.name }} {{ arrowok }} {{ journeyDetail.name }}
              </div>
            </div>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>

          <div class="modal-body">
            <div
              v-if="pageIsLoading"
              class="page-loader"
            >
              <div class="page-is-loading" />
            </div>

            <div class="row">
              <div class="col-12 col-md-4">
                <div class="form-group u-zIndex-3">
                  <label
                    for="beginDate"
                    class="form-label"
                  >{{ $t('begin_day') }}</label>
                  <input
                    class="form-control"
                    :class="{ 'is-danger': errors.has('assign.day') }"
                    type="number"
                    id="dayinput"
                    v-model="day"
                    min="1"
                    data-vv-scope="assign"
                    data-vv-name="day"
                    :data-vv-as="$t('begin_day')"
                    v-validate="'required'"
                  >
                  <span class="daytext">{{ $t('day') }}</span>
                  <span
                    v-if="errors.has('assign.day')"
                    class="help is-danger"
                  >{{ errors.first('assign.day') }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group u-zIndex-3">
                  <label
                    for="beginTime"
                    class="form-label"
                  >{{ $t('begin_time') }}</label>
                  <date-picker
                    :class="{ 'is-danger': errors.has('assign.beginTime') }"
                    v-model="beginActivationDateTime"
                    :format="userLang === 'en' ? 'hh:mm A' : 'HH:mm'"
                    type="time"
                    value-type="date"
                    data-vv-scope="assign"
                    data-vv-name="beginTime"
                    :data-vv-as="$t('begin_time')"
                    v-validate="'required'"
                    :lang="userLang"
                    :time-picker-options="{ start: '08:00', step: '00:15', end: '23:45', format: userLang === 'en' ? 'hh:mm A' : 'HH:mm' }"
                    @change="adjustTime('time')"
                  >
                    <template slot="icon-calendar">
                      <icon-container
                        name="time"
                        view-box="0 0 512 512"
                      >
                        <icon-time />
                      </icon-container>
                    </template>
                  </date-picker>
                  <span
                    v-if="errors.has('assign.beginTime')"
                    class="help is-danger"
                  >{{ errors.first('assign.beginTime') }}</span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <label
                  for="roleType"
                  class="form-label"
                >{{ $t('roleType') }}</label>
                <multi-select
                  :class="{ 'is-danger': errors.has('all.roleType') }"
                  track-by="value"
                  name="roleType"
                  label="label"
                  clear-on-select
                  deselect-label
                  select-label
                  v-model="roleType"
                  data-vv-scope="all"
                  data-vv-name="roleType"
                  :options="roleTypeOptions"
                  class="selectbox selectbox--secondary"
                  v-validate="''"
                  :data-vv-as="$t('roleType')"
                />
                <span
                  v-if="errors.has('all.roleType')"
                  class="help is-danger"
                >{{ errors.first('all.roleType') }}</span>
              </div>
            </div>
            <div
              :class="['panel-title', showPanel ? 'active': '']"
            >
              <label @click="setPanel()">{{ $t('advanced_tab') }}</label>
            </div>
            <collapse-transition
              :duration="500"
              dimension="height"
            >
              <div
                v-show="showPanel"
                class="form-group bglight justify-content-center my-4 p-4 flex-column"
              >
                <div class="row">
                  <div class="col ma-2">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        id="sendEmail"
                        v-model="is_email_enabled"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="sendEmail"
                      >{{ $t('send_email') }}</label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        id="sendPushNotification"
                        v-model="is_notifications_enabled"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label"
                        for="sendPushNotification"
                      >
                        {{ $t('send_push_notif') }}
                      </label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="swicth-checkbox">
                      <input
                        type="checkbox"
                        id="hideLabel"
                        v-model="is_info_labels_hidden"
                        :true-value="1"
                        :false-value="0"
                      >
                      <label
                        class="swicth-label ma-2"
                        for="hideLabel"
                      >{{ $t('hide_title') }}</label><span
                        class="vtooltip"
                        v-tooltip="$t('hide_title_tooltip_msg')"
                      ><em class="fa fa-question-circle" /></span>
                    </div>
                  </div>
                </div>
              </div>
            </collapse-transition>
            <div class="modal-footer">
              <button
                type="button"
                class="button button--primary"
                @click="createContent"
                :disabled="isPending"
              >
                <span
                  v-if="!isPending"
                >
                  {{ $t('create_button') }}
                </span>
                <div
                  class="lds-ellipsis"
                  v-if="isPending"
                >
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/tr';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect';
import CollapseTransition from '@ivanv/vue-collapse-transition';
import * as moment from 'moment';
import IconContainer from '@/components/elements/Icon.vue';
import IconTime from '@/components/icons/Time.vue';
import {
  API_SERVER_TIME_FORMAT,
} from '@/helpers/config';

export default {
  name: 'ModalDateTimeV2',
  inject: ['$validator'],
  data() {
    return {
      beginActivationDateTime: new Date(),
      beginActivationDateTimeOldValue: null,
      is_email_enabled: 1,
      arrowok: '→',
      is_notifications_enabled: 1,
      is_info_labels_hidden: 0,
      showPanel: false,
      formSending: false,
      pageIsLoading: false,
      isValidator: true,
      formIsCompleted: true,
      isPending: false,
      day: 1,
      roleType: {
        value: 3,
        label: 'USER',
      },
      roleTypeOptions: [
        {
          value: 3,
          label: 'USER',
        },
        {
          value: 0,
          label: 'HRBP',
        },
        {
          value: 1,
          label: 'BUDDY',
        },
        {
          value: 2,
          label: 'MANAGER',
        },
        {
          value: 4,
          label: 'OTHER',
        },
      ],
    };
  },

  watch: {
    errors: {
      handler(val) {
        const that = val;
        if (val.items === 0) {
          that.isValidator = false;
          that.formIsCompleted = false;
        } else {
          that.isValidator = true;
          that.formIsCompleted = true;
        }
      },
      deep: true,
    },
    beginActivationDateTime: {
      handler(newValue) {
        this.beginActivationDateTimeOldValue = newValue;
      },
      immediate: true,
    },
  },

  components: {
    'date-picker': DatePicker,
    'collapse-transition': CollapseTransition,
    'icon-container': IconContainer,
    'icon-time': IconTime,
    'multi-select': Multiselect,
  },

  computed: {
    formError: {
      get() {
        return this.$store.state.modal.formError;
      },

      set(newValue) {
        this.$store.state.modal.formError = newValue;
      },
    },

    journeyDetail: {
      get() {
        return this.$store.state.modal.journeyDetail;
      },
      set(newValue) {
        this.$store.state.modal.journeyDetail = newValue;
      },
    },
    contentDetail: {
      get() {
        return this.$store.state.modal.contentDetail;
      },
      set(newValue) {
        this.$store.state.modal.contentDetail = newValue;
      },
    },

    userLang() {
      if (this.$store.state.auth.userInfo) {
        return this.$store.state.auth.userInfo.lang;
      }
      return 'en';
    },
  },

  mounted() {
    this.beginActivationDateTime = new Date();
    this.beginActivationDateTime.setHours(8, 0, 0);
  },

  methods: {
    adjustTime(type) {
      if (this.beginActivationDateTime !== null) {
        if (this.beginActivationDateTimeOldValue && type === 'date') {
          const date = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format('YYYY-MM-DD');
          const time = moment(this.beginActivationDateTimeOldValue, API_SERVER_TIME_FORMAT).format('HH:mm:ss');
          this.beginActivationDateTime = moment(`${date} ${time}`, API_SERVER_TIME_FORMAT).toDate();
        } else if (this.beginActivationDateTimeOldValue && type === 'time') {
          const date = moment(this.beginActivationDateTimeOldValue, API_SERVER_TIME_FORMAT).format('YYYY-MM-DD');
          const time = moment(this.beginActivationDateTime, API_SERVER_TIME_FORMAT).format('HH:mm:ss');
          this.beginActivationDateTime = moment(`${date} ${time}`, API_SERVER_TIME_FORMAT).toDate();
        } else {
          this.beginActivationDateTime.setHours(8, 0, 0);
        }
      }
    },

    setPanel() {
      this.showPanel = !this.showPanel;
    },

    closeModal() {
      this.$store.commit('modal/toggleModal');
    },

    createContent() {
      this.isPending = true;
      const item = {
        as_journey2_id: this.journeyDetail.id,
        as_content_id: this.contentDetail.id,
        beginActivationDateTime: this.beginActivationDateTime,
        day: this.day,
        content: this.contentDetail,
        roleType: this.roleType,
        is_notifications_enabled: this.is_notifications_enabled,
        is_info_labels_hidden: this.is_info_labels_hidden,
        is_email_enabled: this.is_email_enabled,
        // is_static: 0,
        // is_reminder_enabled: 1,
      };// TODO:  is_static & is_reminder_enabled ia really needed!!!?
      const self = this;
      const successMsg = this.$t('added_success');
      this.$store.dispatch('modal/ADD_CONTENT_ITEM_TO_JOURNEY_V2', item).then(() => {
        if (!this.formError) {
          self.$snotify.success(successMsg);
          self.closeModal();
        } else {
          self.formError = false;
          self.isPending = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.page-loader {
    z-index: 100;
    position: relative;
    top: 150px;
}

.page-is-loading {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: absolute;
  left: calc(50% - 32px);
}

.page-is-loading:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #666;
  border-color: #666 transparent #666 transparent;
  animation: page-is-loading 1.2s linear infinite;
}

@keyframes page-is-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.bglight {
  background: #f8f9ff;
  display: flex;
}

.panel-title {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #727d92;
  text-align: center;

}
.panel-title label {
  cursor: pointer;
}
.panel-title label:after {
  content: "\032C";
  left:20px;
  position: relative;
  top: -8px;
  font-size:30px;
  transition: all 1s;
}
 .panel-title.active label:after {
   content: "\032D";
}
.multiselect {
  max-width: 100%;
  min-height: 44px!important;
}

::v-deep div.multiselect__tags {
  min-height: 44px!important;
}

.selectbox {
  min-height: 44px!important;
  .multiselect__tags {
    min-height: 44px!important;
  }
}

.daytext {
  position: absolute;
  top: 40px;
  right: 50px;
  font-size: 12px !important;
  color: #7d889d;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
}
</style>
