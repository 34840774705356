<template>
  <div class="modal-container">
    <div
      class="modal"
      :class="{'is-loading': formSending }"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">
              {{ $t('set_new_password') }}
            </h5>
            <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
            <button
              type="button"
              class="close"
              @click="closeModal"
              v-html="'&times;'"
            />
          </header>
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
                <div
                  class="form-group"
                >
                  <label
                    for="contentPassword"
                    class="form-label"
                  >{{ $t('new_password') }}</label>
                  <input
                    :class="{ 'is-danger': errors.has('newpassword.password') }"
                    type="text"
                    class="form-control"
                    v-model="password"
                    ref="password"
                    data-vv-name="password"
                    :data-vv-as="$t('new_password')"
                    data-vv-scope="newpassword"
                    v-validate="'required|min:6'"
                  >
                  <span
                    v-if="errors.has('newpassword.password')"
                    class="help is-danger"
                  >{{ errors.first('newpassword.password') }}</span>
                </div>
              </div>
              <div class="col-6">
                <div
                  class="form-group"
                >
                  <label
                    for="contentPasswordAgain"
                    class="form-label"
                  >{{ $t('new_password_again') }}</label>
                  <input
                    :class="{ 'is-danger': errors.has('newpassword.passwordagain') }"
                    type="text"
                    class="form-control"
                    v-model="passwordagain"
                    data-vv-name="passwordagain"
                    :data-vv-as="$t('new_password_again')"
                    data-vv-scope="newpassword"
                    v-validate="'required|min:6|confirmed:password'"
                  >
                  <span
                    v-if="errors.has('newpassword.passwordagain')"
                    class="help is-danger"
                  >{{ errors.first('newpassword.passwordagain') }}</span>
                </div>
              </div>
            </div>
            <div
              class="form-group"
              v-show="errors.items.length > 0"
            >
              <div class="alert alert--card alert--error">
                <p>{{ $t('error_msg_title') }}</p>
                <ul>
                  <li
                    v-for="(error, index) in errors"
                    :key="index"
                  >
                    {{ error.msg }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="button button--primary"
                @click="setPassword()"
                :disabled="isPending"
              >
                <span
                  v-if="!isPending"
                >
                  {{ $t('set') }}
                </span>
                <div
                  class="lds-ellipsis"
                  v-if="isPending"
                >
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </button>
              <div class="sentmail">
                {{ $t('or') }}
                <a
                  href="#"
                  @click="sentEmail()"
                >{{ $t('send_reset_password') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/services/';
import {
  API_SEND_PASSWORD_RESET,
  API_USER_SET_PASSWORD,
} from '@/helpers/config';

export default {
  name: 'ModalSetNewPassword',
  inject: ['$validator'],

  data() {
    return {
      isPending: false,
      formSending: false,
      password: null,
      passwordagain: null,
    };
  },

  computed: {
    userEmail() {
      if (this.$store.state.modal.userEmail) {
        return this.$store.state.modal.userEmail;
      }
      return false;
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('modal/toggleModal');
    },

    setPassword() {
      this.$validator.validateAll('newpassword').then((result) => {
        if (result) {
          this.formSending = true;
          this.isPending = true;
          const self = this;
          const successMsg = this.$t('set_password_success');
          const formData = new FormData();
          formData.append('email', this.userEmail);
          formData.append('password', this.password);
          formData.append('password_confirmation', this.passwordagain);

          API.post(API_USER_SET_PASSWORD, formData).then(() => {
            if (!self.formError) {
              self.$snotify.success(successMsg);
              self.closeModal();
            } else {
              self.isPending = false;
              self.formError = false;
              self.formSending = false;
            }
          })
            .catch((error) => {
              this.$helpers.displayError(error);
              self.isPending = false;
              self.formError = false;
              self.formSending = false;
            });
        }
      });
    },

    sentEmail() {
      if (this.userEmail) {
        this.formSending = true;
        this.isPending = true;
        const payload = {
          Username: this.userEmail,
        };

        API.post(API_SEND_PASSWORD_RESET, payload)
          .then((response) => {
            if (response.status === 200) {
              this.$snotify.success(this.$t('password_reset_email_sent'));
              this.closeModal();
            }
          })
          .catch((error) => {
            this.$helpers.displayError(error);
            this.formSending = false;
            this.isPending = false;
          });
      }
    },

  },

};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 10px;
  padding-right: 10px;
}
.form-control {
  &.is-danger {
    border-color: #f27299;
  }
}
.sentmail {
  font-size: 12px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
