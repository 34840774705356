var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-container" }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { "is-loading": _vm.formSending },
        attrs: { tabindex: "-1" },
      },
      [
        _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("header", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.$t("edit_customer")
                        : _vm.$t("add_new_customer")
                    ) +
                    " "
                ),
              ]),
              _c("button", {
                staticClass: "close",
                attrs: { type: "button" },
                domProps: { innerHTML: _vm._s("&times;") },
                on: { click: _vm.closeModal },
              }),
            ]),
            _c("nav", { staticClass: "navigation navigation--secondary" }, [
              _c("div", { staticClass: "container" }, [
                _c(
                  "ul",
                  {
                    staticClass: "navigation__list nav",
                    attrs: { id: "contentCreateTab", role: "tablist" },
                  },
                  [
                    _c("li", { staticClass: "navigation__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: { active: _vm.tabPane === "mainTab" },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("mainTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("main_tab")) + " ")]
                      ),
                    ]),
                    _c("li", { staticClass: "navigation__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: {
                            active: _vm.tabPane === "advancedSettingsTab",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("advancedSettingsTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("advanced_tab")) + " ")]
                      ),
                    ]),
                    _c("li", { staticClass: "navigation__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: { active: _vm.tabPane === "brandingTab" },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("brandingTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("branding_tab")) + " ")]
                      ),
                    ]),
                    _c("li", { staticClass: "navigation__item" }, [
                      _c(
                        "button",
                        {
                          staticClass: "navigation__link",
                          class: { active: _vm.tabPane === "welcomeemailTab" },
                          on: {
                            click: function ($event) {
                              return _vm.tabNavigation("welcomeemailTab")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("welcomeemailTab")) + " ")]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "tab-content" }, [
                _vm.tabPane === "mainTab"
                  ? _c("div", { staticClass: "tab-pane" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "name" },
                                },
                                [_vm._v(_vm._s(_vm.$t("name_uppercase")))]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.name,
                                    expression: "name",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-danger": _vm.errors.has("customer.name"),
                                },
                                attrs: {
                                  type: "text",
                                  id: "name",
                                  "data-vv-scope": "customer",
                                  "data-vv-name": "name",
                                  "data-vv-as": _vm.$t("name_uppercase"),
                                  placeholder: _vm.$t(
                                    "customer_name_placeholder"
                                  ),
                                },
                                domProps: { value: _vm.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.name = $event.target.value
                                  },
                                },
                              }),
                              _vm.errors.has("customer.name")
                                ? _c(
                                    "span",
                                    { staticClass: "help is-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("customer.name")
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "contactName" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("contact_name").toUpperCase())
                                  ),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.contactName,
                                    expression: "contactName",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-danger": _vm.errors.has(
                                    "customer.contactName"
                                  ),
                                },
                                attrs: {
                                  type: "text",
                                  id: "contactName",
                                  "data-vv-name": "contactName",
                                  "data-vv-as": _vm
                                    .$t("contact_name")
                                    .toUpperCase(),
                                  "data-vv-scope": "customer",
                                  placeholder: _vm.$t(
                                    "contact_name_placeholder"
                                  ),
                                },
                                domProps: { value: _vm.contactName },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.contactName = $event.target.value
                                  },
                                },
                              }),
                              _vm.errors.has("customer.contactName")
                                ? _c(
                                    "span",
                                    { staticClass: "help is-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            "customer.contactName"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "e-mail" },
                                },
                                [_vm._v(_vm._s(_vm.$t("email_uppercase")))]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.customerEmail,
                                    expression: "customerEmail",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|email",
                                    expression: "'required|email'",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-danger":
                                    _vm.errors.has("customer.e-mail"),
                                },
                                attrs: {
                                  type: "text",
                                  id: "customerEmail",
                                  "data-vv-scope": "customer",
                                  "data-vv-name": "e-mail",
                                  "data-vv-as": _vm.$t("email_uppercase"),
                                  placeholder: _vm.$t(
                                    "customer_email_placeholder"
                                  ),
                                },
                                domProps: { value: _vm.customerEmail },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.customerEmail = $event.target.value
                                  },
                                },
                              }),
                              _vm.errors.has("customer.e-mail")
                                ? _c(
                                    "span",
                                    { staticClass: "help is-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("customer.e-mail")
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "contentTitle" },
                                },
                                [_vm._v(_vm._s(_vm.$t("language")))]
                              ),
                              _c("multi-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "selectbox selectbox--secondary",
                                class: {
                                  "is-danger":
                                    _vm.errors.has("customer.language"),
                                },
                                attrs: {
                                  "track-by": "value",
                                  label: "value",
                                  "clear-on-select": "",
                                  "deselect-label": "",
                                  "select-label": "",
                                  multiple: true,
                                  "data-vv-scope": "customer",
                                  options: _vm.languageOptions,
                                  "data-vv-name": "language",
                                  "data-vv-as": _vm.$t("language"),
                                },
                                model: {
                                  value: _vm.selectLanguages,
                                  callback: function ($$v) {
                                    _vm.selectLanguages = $$v
                                  },
                                  expression: "selectLanguages",
                                },
                              }),
                              _vm.errors.has("customer.language")
                                ? _c(
                                    "span",
                                    { staticClass: "help is-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("customer.language")
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "swicth-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.status,
                                    expression: "status",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "status",
                                  "true-value": 1,
                                  "false-value": 0,
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.status)
                                    ? _vm._i(_vm.status, null) > -1
                                    : _vm._q(_vm.status, 1),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.status,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? 1 : 0
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.status = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.status = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.status = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "swicth-label",
                                  attrs: { for: "status" },
                                },
                                [_vm._v(_vm._s(_vm.$t("active")))]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.items.length > 0,
                              expression: "errors.items.length > 0",
                            },
                          ],
                          staticClass: "form-group",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "alert alert--card alert--error" },
                            [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("error_msg_title"))),
                              ]),
                              _c(
                                "ul",
                                _vm._l(_vm.errors, function (error, index) {
                                  return _c("li", { key: index }, [
                                    _vm._v(" " + _vm._s(error.msg) + " "),
                                  ])
                                }),
                                0
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: { type: "button", disabled: _vm.isPending },
                            on: {
                              click: function ($event) {
                                _vm.editMode
                                  ? _vm.processCustomer("UPDATE_CUSTOMER")
                                  : _vm.processCustomer("CREATE_CUSTOMER")
                              },
                            },
                          },
                          [
                            !_vm.isPending
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.editMode
                                        ? _vm.$t("update_button")
                                        : _vm.$t("add")
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm.isPending
                              ? _c("div", { staticClass: "lds-ellipsis" }, [
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                  _c("div"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabPane === "advancedSettingsTab",
                        expression: "tabPane === 'advancedSettingsTab'",
                      },
                    ],
                    staticClass: "tab-pane",
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "contentTags" },
                                },
                                [_vm._v(_vm._s(_vm.$t("domain_name")))]
                              ),
                              _c("vue-tags-input", {
                                attrs: {
                                  tags: _vm.domain_names,
                                  "add-on-key": [13, ":", ";", ","],
                                  placeholder: _vm.$t(
                                    "domain_name_placeholder"
                                  ),
                                },
                                on: {
                                  "tags-changed": _vm.domainsChanged,
                                  "before-adding-tag": _vm.checkDomain,
                                },
                                model: {
                                  value: _vm.domain_name,
                                  callback: function ($$v) {
                                    _vm.domain_name = $$v
                                  },
                                  expression: "domain_name",
                                },
                              }),
                              _vm.errors.has("customer.domain_name")
                                ? _c(
                                    "span",
                                    { staticClass: "help is-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            "customer.domain_name"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _vm.editMode
                          ? _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "form-label",
                                      attrs: { for: "journey" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("pre_assigned_journeys"))
                                      ),
                                    ]
                                  ),
                                  _c("vue-tags-input", {
                                    staticClass: "pre-assigned-journey",
                                    attrs: {
                                      tags: _vm.journeys,
                                      "autocomplete-items": _vm.journeysToAdd,
                                      "add-only-from-autocomplete": true,
                                      "delete-on-backspace": false,
                                      placeholder: _vm.$t(
                                        "addjourney_placeholder"
                                      ),
                                    },
                                    on: {
                                      "tags-changed": _vm.inputUpdateJourneys,
                                      "before-adding-tag": _vm.journeyTagAdded,
                                      "before-deleting-tag":
                                        _vm.journeyTagDeleted,
                                    },
                                    model: {
                                      value: _vm.journey,
                                      callback: function ($$v) {
                                        _vm.journey = $$v
                                      },
                                      expression: "journey",
                                    },
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.journeysSelected,
                                        expression: "journeysSelected",
                                      },
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      "data-vv-scope": "add",
                                      "data-vv-name": "journey",
                                      "data-vv-as": _vm.$t("journeys"),
                                    },
                                    domProps: { value: _vm.journeysSelected },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.journeysSelected =
                                          $event.target.value
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "kolayik_token" },
                              },
                              [_vm._v(_vm._s(_vm.$t("kolayik_token")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.kolayik_token,
                                  expression: "kolayik_token",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-danger": _vm.errors.has(
                                  "customer.kolayik_token"
                                ),
                              },
                              attrs: {
                                type: "text",
                                disabled: _vm.editMode && _vm.disableKolayIk,
                                id: "kolayik_token",
                                "data-vv-scope": "customer",
                                "data-vv-name": "kolayik_token",
                                "data-vv-as": _vm.$t("kolayik_token"),
                                placeholder: _vm.$t(
                                  "kolayik_token_placeholder"
                                ),
                              },
                              domProps: { value: _vm.kolayik_token },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.kolayik_token = $event.target.value
                                },
                              },
                            }),
                            _vm.disableKolayIk
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "button button--alert size-sm button-kolayik",
                                    attrs: { type: "button" },
                                    on: { click: _vm.deleteKOLAYIKTOKEN },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")]
                                )
                              : _vm._e(),
                            _vm.errors.has("customer.kolayik_token")
                              ? _c("span", { staticClass: "help is-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first("customer.kolayik_token")
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "auth_token_ttl_minutes" },
                              },
                              [_vm._v(_vm._s(_vm.$t("user_session_minute")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.auth_token_ttl_minutes,
                                  expression: "auth_token_ttl_minutes",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-danger": _vm.errors.has(
                                  "customer.auth_token_ttl_minutes"
                                ),
                              },
                              attrs: {
                                type: "number",
                                id: "auth_token_ttl_minutes",
                                "data-vv-scope": "customer",
                                "data-vv-name": "auth_token_ttl_minutes",
                                "data-vv-as": _vm.$t("user_session_minute"),
                                placeholder: _vm.$t(
                                  "user_session_minute_placeholder"
                                ),
                              },
                              domProps: { value: _vm.auth_token_ttl_minutes },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.auth_token_ttl_minutes =
                                    $event.target.value
                                },
                              },
                            }),
                            _vm.errors.has("customer.auth_token_ttl_minutes")
                              ? _c("span", { staticClass: "help is-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        "customer.auth_token_ttl_minutes"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "contentTitle" },
                                },
                                [_vm._v(_vm._s(_vm.$t("timezone")))]
                              ),
                              _c("multi-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "selectbox selectbox--secondary",
                                class: {
                                  "is-danger":
                                    _vm.errors.has("customer.timezone"),
                                },
                                attrs: {
                                  "clear-on-select": "",
                                  "deselect-label": "",
                                  "select-label": "",
                                  options: _vm.timezoneOptions,
                                  "data-vv-scope": "customer",
                                  "data-vv-name": "timezone",
                                  "data-vv-as": _vm.$t("timezone"),
                                },
                                model: {
                                  value: _vm.selectedTimezone,
                                  callback: function ($$v) {
                                    _vm.selectedTimezone = $$v
                                  },
                                  expression: "selectedTimezone",
                                },
                              }),
                              _vm.errors.has("customer.timezone")
                                ? _c(
                                    "span",
                                    { staticClass: "help is-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("customer.timezone")
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "swicth-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.create_user_account_from_sso,
                                    expression: "create_user_account_from_sso",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "create_user_account_from_sso",
                                  "true-value": 1,
                                  "false-value": 0,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.create_user_account_from_sso
                                  )
                                    ? _vm._i(
                                        _vm.create_user_account_from_sso,
                                        null
                                      ) > -1
                                    : _vm._q(
                                        _vm.create_user_account_from_sso,
                                        1
                                      ),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.create_user_account_from_sso,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? 1 : 0
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.create_user_account_from_sso =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.create_user_account_from_sso =
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.create_user_account_from_sso = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "swicth-label",
                                  attrs: {
                                    for: "create_user_account_from_sso",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("create_user_account_from_sso")
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("div", { staticClass: "swicth-checkbox" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.is_pushnotification_enabled,
                                        expression:
                                          "is_pushnotification_enabled",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "is_pushnotification_enabled",
                                      "true-value": 1,
                                      "false-value": 0,
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.is_pushnotification_enabled
                                      )
                                        ? _vm._i(
                                            _vm.is_pushnotification_enabled,
                                            null
                                          ) > -1
                                        : _vm._q(
                                            _vm.is_pushnotification_enabled,
                                            1
                                          ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.is_pushnotification_enabled,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? 1 : 0
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.is_pushnotification_enabled =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.is_pushnotification_enabled =
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.is_pushnotification_enabled = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "swicth-label",
                                      attrs: {
                                        for: "is_pushnotification_enabled",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("is_pushnotification_enabled")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("div", { staticClass: "swicth-checkbox" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.is_weekly_reports_enabled,
                                        expression: "is_weekly_reports_enabled",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "is_weekly_reports_enabled",
                                      "true-value": 1,
                                      "false-value": 0,
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.is_weekly_reports_enabled
                                      )
                                        ? _vm._i(
                                            _vm.is_weekly_reports_enabled,
                                            null
                                          ) > -1
                                        : _vm._q(
                                            _vm.is_weekly_reports_enabled,
                                            1
                                          ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.is_weekly_reports_enabled,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? 1 : 0
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.is_weekly_reports_enabled =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.is_weekly_reports_enabled =
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.is_weekly_reports_enabled = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "swicth-label",
                                      attrs: {
                                        for: "is_weekly_reports_enabled",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("is_weekly_reports_enabled")
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("transition", { attrs: { name: "accordion" } }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.is_pushnotification_enabled,
                                      expression: "is_pushnotification_enabled",
                                    },
                                  ],
                                },
                                [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-label",
                                        attrs: { for: "pushServerEndpoint" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("pushserver_endpoint"))
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.pushServerEndpoint,
                                          expression: "pushServerEndpoint",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "pushServerEndpoint",
                                        placeholder: _vm.$t(
                                          "pushserver_endpoint_placeholder"
                                        ),
                                      },
                                      domProps: {
                                        value: _vm.pushServerEndpoint,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.pushServerEndpoint =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-label",
                                        attrs: { for: "pushServerUsername" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("pushserver_username"))
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.pushServerUsername,
                                          expression: "pushServerUsername",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "pushServerUsername",
                                        placeholder: _vm.$t(
                                          "pushserver_username_placeholder"
                                        ),
                                      },
                                      domProps: {
                                        value: _vm.pushServerUsername,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.pushServerUsername =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "form-label",
                                        attrs: { for: "pushServerPassword" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("pushserver_password"))
                                        ),
                                      ]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.pushServerPassword,
                                          expression: "pushServerPassword",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "pushServerPassword",
                                        placeholder: _vm.$t(
                                          "pushserver_password_placeholder"
                                        ),
                                      },
                                      domProps: {
                                        value: _vm.pushServerPassword,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.pushServerPassword =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("modules"))),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "swicth-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.lxp_services,
                                    expression: "lxp_services",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "lxp_services",
                                  "true-value": true,
                                  "false-value": false,
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.lxp_services)
                                    ? _vm._i(_vm.lxp_services, null) > -1
                                    : _vm.lxp_services,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.lxp_services,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.lxp_services = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.lxp_services = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.lxp_services = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "swicth-label",
                                  attrs: { for: "lxp_services" },
                                },
                                [_vm._v(_vm._s(_vm.$t("lxp_services")))]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "swicth-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.onboarding_services,
                                    expression: "onboarding_services",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "onboarding_services",
                                  "true-value": true,
                                  "false-value": false,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.onboarding_services
                                  )
                                    ? _vm._i(_vm.onboarding_services, null) > -1
                                    : _vm.onboarding_services,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.onboarding_services,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.onboarding_services = $$a.concat(
                                            [$$v]
                                          ))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.onboarding_services = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.onboarding_services = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "swicth-label",
                                  attrs: { for: "onboarding_services" },
                                },
                                [_vm._v(_vm._s(_vm.$t("onboarding_services")))]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "swicth-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.development_services,
                                    expression: "development_services",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "development_services",
                                  "true-value": true,
                                  "false-value": false,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.development_services
                                  )
                                    ? _vm._i(_vm.development_services, null) >
                                      -1
                                    : _vm.development_services,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.development_services,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.development_services =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.development_services = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.development_services = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "swicth-label",
                                  attrs: { for: "development_services" },
                                },
                                [_vm._v(_vm._s(_vm.$t("development_services")))]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "swicth-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.communication_services,
                                    expression: "communication_services",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "communication_services",
                                  "true-value": true,
                                  "false-value": false,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.communication_services
                                  )
                                    ? _vm._i(_vm.communication_services, null) >
                                      -1
                                    : _vm.communication_services,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.communication_services,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.communication_services =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.communication_services = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.communication_services = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "swicth-label",
                                  attrs: { for: "communication_services" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("communication_services"))
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.tabNavigation("mainTab")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabPane === "brandingTab",
                        expression: "tabPane === 'brandingTab'",
                      },
                    ],
                    staticClass: "tab-pane",
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-8" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "name" },
                              },
                              [_vm._v(_vm._s(_vm.$t("email_sender_name")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.email_sender_name,
                                  expression: "email_sender_name",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-danger": _vm.errors.has(
                                  "customer.email_sender_name"
                                ),
                              },
                              attrs: {
                                type: "text",
                                id: "email_sender_name",
                                "data-vv-scope": "customer",
                                "data-vv-name": "email_sender_name",
                                "data-vv-as": _vm.$t("email_sender_name"),
                                placeholder: _vm.$t(
                                  "customer_email_sender_name_placeholder"
                                ),
                              },
                              domProps: { value: _vm.email_sender_name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.email_sender_name = $event.target.value
                                },
                              },
                            }),
                            _vm.errors.has("customer.email_sender_name")
                              ? _c("span", { staticClass: "help is-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        "customer.email_sender_name"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "splashImage" },
                                },
                                [_vm._v(_vm._s(_vm.$t("email_logo_image")))]
                              ),
                              _c("thumbnail-container", {
                                staticClass: "card card--upload logo",
                                class: {
                                  "is-danger": _vm.errors.has("customer.logo"),
                                },
                                attrs: {
                                  "thumbnail-image": _vm.logoImagePreview,
                                  "vee-rule":
                                    "size:2000|mimes:image/jpeg,image/png",
                                  "vee-scoop-name": "customer",
                                  "vee-name": "logo",
                                },
                                on: { update: _vm.logoImageAdded },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("customer.logo"),
                                      expression: "errors.has('customer.logo')",
                                    },
                                  ],
                                  staticClass: "help is-danger",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("customer.logo"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "Ssouri" },
                              },
                              [_vm._v(_vm._s(_vm.$t("sso_uri")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sso_uri,
                                  expression: "sso_uri",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-danger": _vm.errors.has("customer.sso_uri"),
                              },
                              attrs: {
                                type: "text",
                                id: "sso_uri",
                                "vee-rule": "{url: {require_protocol: true }}",
                                "data-vv-scope": "customer",
                                "data-vv-name": "sso_uri",
                                "data-vv-as": _vm.$t("sso_uri"),
                                placeholder: _vm.$t("sso_uri_placeholder"),
                              },
                              domProps: { value: _vm.sso_uri },
                              on: {
                                blur: function ($event) {
                                  _vm.sso_uri = _vm.$helpers.addhttp(
                                    $event.target.value
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.sso_uri = $event.target.value
                                },
                              },
                            }),
                            _vm.errors.has("customer.sso_uri")
                              ? _c("span", { staticClass: "help is-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("customer.sso_uri"))
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "swicth-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.is_privacy_policy_needed,
                                    expression: "is_privacy_policy_needed",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "is_privacy_policy_needed",
                                  "true-value": 1,
                                  "false-value": 0,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.is_privacy_policy_needed
                                  )
                                    ? _vm._i(
                                        _vm.is_privacy_policy_needed,
                                        null
                                      ) > -1
                                    : _vm._q(_vm.is_privacy_policy_needed, 1),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.is_privacy_policy_needed,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? 1 : 0
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.is_privacy_policy_needed =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.is_privacy_policy_needed = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.is_privacy_policy_needed = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "swicth-label",
                                  attrs: { for: "is_privacy_policy_needed" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("is_privacy_policy_needed"))
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "splashImage" },
                                },
                                [_vm._v(_vm._s(_vm.$t("splash_image")))]
                              ),
                              _c("thumbnail-container", {
                                staticClass: "card card--upload",
                                class: {
                                  "is-danger":
                                    _vm.errors.has("customer.splash"),
                                },
                                attrs: {
                                  "thumbnail-image": _vm.splashImagePreview,
                                  "vee-rule":
                                    "size:5000|mimes:image/jpeg,image/png",
                                  "vee-scoop-name": "customer",
                                  "vee-name": "splash",
                                },
                                on: { update: _vm.splashImageAdded },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("customer.splash"),
                                      expression:
                                        "errors.has('customer.splash')",
                                    },
                                  ],
                                  staticClass: "help is-danger",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("customer.splash"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.tabNavigation("mainTab")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabPane === "welcomeemailTab",
                        expression: "tabPane === 'welcomeemailTab'",
                      },
                    ],
                    staticClass: "tab-pane",
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "name" },
                              },
                              [_vm._v(_vm._s(_vm.$t("subject_line")))]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.welcome_email_subject,
                                  expression: "welcome_email_subject",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-danger": _vm.errors.has(
                                  "customer.welcome_email_subject"
                                ),
                              },
                              attrs: {
                                type: "text",
                                id: "welcome_email_subject",
                                "data-vv-scope": "customer",
                                "data-vv-name": "welcome_email_subject",
                                "data-vv-as": _vm.$t("subject_line"),
                                placeholder: _vm.$t("enter_email_subject"),
                              },
                              domProps: { value: _vm.welcome_email_subject },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.welcome_email_subject =
                                    $event.target.value
                                },
                              },
                            }),
                            _vm.errors.has("customer.welcome_email_subject")
                              ? _c("span", { staticClass: "help is-danger" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        "customer.welcome_email_subject"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-8" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-group h-100 d-flex flex-column",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "name" },
                                },
                                [_vm._v(_vm._s(_vm.$t("welcomeemail_message")))]
                              ),
                              _c("vue-editor", {
                                attrs: {
                                  id: "cbody",
                                  "editor-toolbar": _vm.customToolbar,
                                },
                                model: {
                                  value: _vm.welcome_email_content,
                                  callback: function ($$v) {
                                    _vm.welcome_email_content = $$v
                                  },
                                  expression: "welcome_email_content",
                                },
                              }),
                              _vm.errors.has("customer.welcome_email_content")
                                ? _c(
                                    "span",
                                    { staticClass: "help is-danger" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            "customer.welcome_email_content"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "form-label",
                                  attrs: { for: "welcomeImage" },
                                },
                                [_vm._v(_vm._s(_vm.$t("welcome_image")))]
                              ),
                              _c("thumbnail-container", {
                                staticClass: "card card--upload",
                                class: {
                                  "is-danger": _vm.errors.has(
                                    "customer.welcomeimage"
                                  ),
                                },
                                attrs: {
                                  "thumbnail-image": _vm.welcomeImagePreview,
                                  "vee-rule":
                                    "size:5000|mimes:image/jpeg,image/png",
                                  "vee-scoop-name": "customer",
                                  "vee-name": "welcomeimage",
                                },
                                on: { update: _vm.welcomeImageAdded },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has(
                                        "customer.welcomeimage"
                                      ),
                                      expression:
                                        "errors.has('customer.welcomeimage')",
                                    },
                                  ],
                                  staticClass: "help is-danger",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first("customer.welcomeimage")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.tabNavigation("mainTab")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }