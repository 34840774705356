var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page dashboard" },
    [
      _c("app-header", {
        attrs: {
          "child-header-class": "container-fluid",
          "child-header-navigation": _vm.dashboardSubNavigation,
          "is-child-header-left-actions": "",
          "child-title": _vm.childTitle,
        },
      }),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c(
              "div",
              { staticClass: "chart_holder" },
              [
                _c("div", { staticStyle: { width: "20px" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.right",
                          value: _vm.$t("info_active_user_ratio"),
                          expression: "$t('info_active_user_ratio')",
                          modifiers: { right: true },
                        },
                      ],
                      staticClass: "vtooltip info-icon-left",
                    },
                    [
                      _c(
                        "icon-container",
                        {
                          attrs: {
                            name: "infocircle",
                            "view-box": "0 0 512 512",
                            "is-icon-class": false,
                          },
                        },
                        [_c("icon-infocircle")],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("h5", [
                  _vm._v(_vm._s(this.$t("active_user_ratio").toUpperCase())),
                ]),
                _c("highcharts", {
                  staticClass: "chart",
                  attrs: {
                    options: _vm.activeUsersRatioCharts,
                    "update-args": _vm.updateArgs,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c(
              "div",
              { staticClass: "chart_holder" },
              [
                _c("div", { staticStyle: { width: "20px" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.right",
                          value: _vm.$t("info_average_time_spent"),
                          expression: "$t('info_average_time_spent')",
                          modifiers: { right: true },
                        },
                      ],
                      staticClass: "vtooltip info-icon-left",
                    },
                    [
                      _c(
                        "icon-container",
                        {
                          attrs: {
                            name: "infocircle",
                            "view-box": "0 0 512 512",
                            "is-icon-class": false,
                          },
                        },
                        [_c("icon-infocircle")],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("h5", [
                  _vm._v(_vm._s(_vm.$t("average_time_spent").toUpperCase())),
                ]),
                _c("highcharts", {
                  staticClass: "chart",
                  attrs: {
                    options: _vm.averageTimeSpentRatioCharts,
                    "update-args": _vm.updateArgs,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c(
              "div",
              { staticClass: "chart_holder" },
              [
                _c("div", { staticStyle: { width: "20px" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.right",
                          value: _vm.$t("info_app_download_ratio"),
                          expression: "$t('info_app_download_ratio')",
                          modifiers: { right: true },
                        },
                      ],
                      staticClass: "vtooltip info-icon-left",
                    },
                    [
                      _c(
                        "icon-container",
                        {
                          attrs: {
                            name: "infocircle",
                            "view-box": "0 0 512 512",
                            "is-icon-class": false,
                          },
                        },
                        [_c("icon-infocircle")],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("h5", [
                  _vm._v(_vm._s(_vm.$t("app_download_ratio").toUpperCase())),
                ]),
                _c("highcharts", {
                  staticClass: "chart",
                  attrs: {
                    options: _vm.appDownloadRatioCharts,
                    "update-args": _vm.updateArgs,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c(
              "div",
              { staticClass: "chart_holder" },
              [
                _c("div", { staticStyle: { width: "20px" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.right",
                          value: _vm.$t("info_number_of_contents_created"),
                          expression: "$t('info_number_of_contents_created')",
                          modifiers: { right: true },
                        },
                      ],
                      staticClass: "vtooltip info-icon-left",
                    },
                    [
                      _c(
                        "icon-container",
                        {
                          attrs: {
                            name: "infocircle",
                            "view-box": "0 0 512 512",
                            "is-icon-class": false,
                          },
                        },
                        [_c("icon-infocircle")],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("h5", [
                  _vm._v(
                    _vm._s(_vm.$t("number_of_contents_created").toUpperCase())
                  ),
                ]),
                _c("highcharts", {
                  staticClass: "chart",
                  attrs: {
                    options: _vm.createdContentRatioCharts,
                    "update-args": _vm.updateArgs,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }