var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-pane show active" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", { staticClass: "form-label", attrs: { for: "url" } }, [
        _vm._v(_vm._s(_vm.$t("external_youtube_videos_link"))),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.url,
            expression: "url",
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: { required: true, url: { require_protocol: false } },
            expression: "{required: true, url: {require_protocol: false }}",
          },
        ],
        ref: "urlInput",
        staticClass: "form-control",
        class: { "is-danger": _vm.errors.has("externalYoutubelink.url") },
        attrs: {
          type: "text",
          disabled: _vm.disabled,
          id: "url",
          "data-vv-scope": "externalYoutubelink",
          "data-vv-name": "url",
          "data-vv-as": _vm.$t("external_youtube_videos_link"),
        },
        domProps: { value: _vm.url },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.url = $event.target.value
            },
            _vm.handleUrl,
          ],
        },
      }),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.has("externalYoutubelink.url"),
              expression: "errors.has('externalYoutubelink.url')",
            },
          ],
          staticClass: "help is-danger",
        },
        [_vm._v(_vm._s(_vm.errors.first("externalYoutubelink.url")))]
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.urlValid,
            expression: "urlValid",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("div", { staticClass: "container" }, [
          _vm.tableIsLoading
            ? _c("div", { staticClass: "lds-dual-ring" })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _c("vue-table", {
                ref: "myvuetable",
                class: { "table-fade": _vm.tableIsLoading },
                attrs: {
                  "api-mode": false,
                  data: _vm.videoList,
                  fields: _vm.fields,
                },
                scopedSlots: _vm._u([
                  {
                    key: "actions",
                    fn: function (props) {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "ui button delete",
                            on: {
                              click: function ($event) {
                                return _vm.onAction(
                                  "delete-item",
                                  props.rowData
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "icon-container",
                              {
                                attrs: {
                                  width: 20,
                                  height: 20,
                                  name: "trash",
                                  color: "#727d92",
                                },
                              },
                              [_c("icon-trash")],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.errors.items.length > 0,
            expression: "errors.items.length > 0",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("div", { staticClass: "alert alert--card alert--error" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("error_msg_title")))]),
          _c(
            "ul",
            _vm._l(_vm.errors, function (error, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(error.msg) + " "),
              ])
            }),
            0
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "button button--primary",
          attrs: {
            type: "button",
            disabled: _vm.isPending || (_vm.disabled && !_vm.canEditOnlyItem),
          },
          on: {
            click: function ($event) {
              return _vm.createContent()
            },
          },
        },
        [
          !_vm.isPending
            ? _c("span", [_vm._v(" " + _vm._s(_vm.$t("create_button")) + " ")])
            : _vm._e(),
          _vm.isPending
            ? _c("div", { staticClass: "lds-ellipsis" }, [
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }