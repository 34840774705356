var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "child-header-navigation": _vm.dashboardSubNavigation,
            "is-child-header-left-actions": "",
          },
        },
        [
          _c("filter-perpage", {
            on: { searchFor: _vm.setSearchFor, perPage: _vm.setPerPage },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "container" }, [
        _vm.tableIsLoading
          ? _c("div", { staticClass: "lds-dual-ring" })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c("vue-table", {
              ref: "myvuetable",
              class: { "table-fade": _vm.tableIsLoading },
              attrs: {
                "api-url": _vm.apiUrl,
                "http-fetch": _vm.myFetch,
                "api-mode": true,
                "pagination-path": "meta",
                "http-method": "get",
                "append-params": _vm.moreParams,
                "http-options": {
                  headers: {
                    Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                  },
                },
                fields: _vm.fields,
                "no-data-template": _vm.$t("no_data_info"),
                css: _vm.css.table,
                "per-page": _vm.perPage,
                "query-params": _vm.makeQueryParams,
              },
              on: {
                "vuetable:loading": function ($event) {
                  _vm.tableIsLoading = true
                },
                "vuetable:loaded": function ($event) {
                  _vm.tableIsLoading = false
                },
                "vuetable:load-error": _vm.vuetableLoadError,
                "vuetable:pagination-data": _vm.onPaginationData,
              },
              scopedSlots: _vm._u([
                {
                  key: "date-slot",
                  fn: function (props) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getCurrentLocaleDate(
                              props.rowData.item.activation_dt
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "content-name-slot",
                  fn: function (props) {
                    return [_vm._v(" " + _vm._s(props.rowData.item.name) + " ")]
                  },
                },
                {
                  key: "journey-name-slot",
                  fn: function (props) {
                    return [
                      _vm._v(" " + _vm._s(props.rowData.as_journey_name) + " "),
                    ]
                  },
                },
                {
                  key: "type-name-slot",
                  fn: function (props) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center",
                        },
                        [
                          _c("span", {
                            staticClass: "icon-item mr-2",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$helpers.iconType(props.rowData.type)
                              ),
                            },
                          }),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$helpers.getSubTypeName(props.rowData.type)
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "actions",
                  fn: function (props) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "ui button edit",
                          on: {
                            click: function ($event) {
                              return _vm.onAction(
                                "item-download",
                                props.rowData
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "icon-container",
                            {
                              attrs: {
                                name: "flying",
                                width: "36",
                                height: "24",
                                "view-box": "0 0 19 12",
                              },
                            },
                            [_c("icon-flying")],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              { staticClass: "pagination__info" },
              [
                _c("vuetable-pagination-info", {
                  ref: "paginationInfo",
                  class: { "table-fade": _vm.tableIsLoading },
                  attrs: {
                    "info-template": _vm.$t("pagination_info"),
                    "no-data-template": _vm.$t("no_data_info"),
                  },
                }),
                _c(
                  "div",
                  { staticClass: "pagination__items" },
                  [
                    _c("vuetable-pagination", {
                      ref: "pagination",
                      attrs: { css: _vm.css.pagination },
                      on: {
                        "vuetable-pagination:change-page": _vm.onChangePage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }