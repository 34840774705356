var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", {
      attrs: {
        fill: "#fff",
        d: "M15.42 9.28a4.26 4.26 0 100-8.52 4.26 4.26 0 000 8.52z",
      },
    }),
    _c("path", {
      attrs: {
        fill: "#fff",
        d: "M16 9.24c-.19.02-.38.04-.58.04A4.26 4.26 0 1116 .8a4.26 4.26 0 000 8.44z",
      },
    }),
    _c("path", {
      attrs: {
        fill: "#fff",
        d: "M19.68 5.02a4.26 4.26 0 01-4.84 4.22 4.26 4.26 0 000-8.44 4.25 4.25 0 014.84 4.22zM5.42 18.32a4.26 4.26 0 100-8.51 4.26 4.26 0 000 8.51z",
      },
    }),
    _c("path", {
      attrs: {
        fill: "#fff",
        d: "M6 18.28a4.26 4.26 0 110-8.44 4.26 4.26 0 000 8.44z",
      },
    }),
    _c("path", {
      attrs: {
        fill: "#fff",
        d: "M9.68 14.07a4.26 4.26 0 01-4.84 4.21 4.26 4.26 0 000-8.43 4.25 4.25 0 014.84 4.22zM18.82 22.08a4.26 4.26 0 100-8.52 4.26 4.26 0 000 8.52z",
      },
    }),
    _c("path", {
      attrs: {
        fill: "#fff",
        d: "M19.4 22.04c-.19.02-.38.04-.58.04a4.26 4.26 0 11.58-8.48 4.26 4.26 0 000 8.44z",
      },
    }),
    _c("path", {
      attrs: {
        fill: "#fff",
        d: "M23.08 17.82a4.26 4.26 0 01-4.84 4.22 4.26 4.26 0 000-8.44 4.25 4.25 0 014.84 4.22zM15.42 9.84a4.82 4.82 0 10-.01-9.65 4.82 4.82 0 00.01 9.65zm0-8.51a3.7 3.7 0 110 7.4 3.7 3.7 0 010-7.4zM5.42 9.25a4.82 4.82 0 10.01 9.65 4.82 4.82 0 00-.01-9.65zm0 8.51a3.7 3.7 0 110-7.4 3.7 3.7 0 010 7.4z",
      },
    }),
    _c("path", {
      attrs: {
        fill: "#fff",
        d: "M18.82 13a4.82 4.82 0 10.01 9.65 4.82 4.82 0 00-.01-9.65zm0 8.51a3.7 3.7 0 110-7.4 3.7 3.7 0 010 7.4z",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }