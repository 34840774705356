<template>
  <div
    class="tab-pane show active"
  >
    <div class="form-group">
      <label
        for="url"
        class="form-label"
      >{{ $t('external_youtube_videos_link') }}</label>
      <input
        :class="{ 'is-danger': errors.has('externalYoutubelink.url') }"
        type="text"
        :disabled="disabled"
        class="form-control"
        id="url"
        ref="urlInput"
        v-model="url"
        data-vv-scope="externalYoutubelink"
        data-vv-name="url"
        :data-vv-as="$t('external_youtube_videos_link')"
        v-validate="{required: true, url: {require_protocol: false }}"
        @input="handleUrl"
      >
      <span
        v-show="errors.has('externalYoutubelink.url')"
        class="help is-danger"
      >{{ errors.first('externalYoutubelink.url') }}</span>
    </div>
    <div
      v-show="urlValid"
      class="form-group"
    >
      <div class="container">
        <div
          v-if="tableIsLoading"
          class="lds-dual-ring"
        />
        <div class="table-responsive">
          <vue-table
            :class="{ 'table-fade': tableIsLoading }"
            ref="myvuetable"
            :api-mode="false"
            :data="videoList"
            :fields="fields"
          >
            <template
              slot="actions"
              slot-scope="props"
            >
              <button
                class="ui button delete"
                @click="onAction('delete-item', props.rowData)"
              >
                <icon-container
                  :width="20"
                  :height="20"
                  name="trash"
                  color="#727d92"
                >
                  <icon-trash />
                </icon-container>
              </button>
            </template>
          </vue-table>
        </div>
      </div>
    </div>
    <div
      v-show="errors.items.length > 0"
      class="form-group"
    >
      <div class="alert alert--card alert--error">
        <p>{{ $t('error_msg_title') }}</p>
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.msg }}
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="button button--primary"
        @click="createContent()"
        :disabled="isPending || (disabled && !canEditOnlyItem)"
      >
        <span
          v-if="!isPending"
        >
          {{ $t('create_button') }}
        </span>
        <div
          class="lds-ellipsis"
          v-if="isPending"
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
// import urlMetadata from 'url-metadata'; // CORS_PROXY_URL
import {
  WHITELIST_URLS, CORS_PROXY_URL,
} from '@/helpers/config';
import IconContainer from '@/components/elements/Icon.vue';
import IconTrash from '@/components/icons/Trash.vue';

const { ConcurrencyManager } = require('axios-concurrency');

export default {
  name: 'YoutubeVideos',
  inject: ['$validator'],
  props: {
    name: {
      type: String,
      default: '',
    },
    externalUrl: {
      type: String,
      default: '',
    },
    contentLanguage: {
      type: Object,
      default: () => { ''; },
    },
    // eslint-disable-next-line vue/prop-name-casing
    is_public: {
      type: Number,
      default: 0,
    },

    // eslint-disable-next-line vue/prop-name-casing
    is_notifications_enabled: {
      type: Number,
      default: 0,
    },

    // eslint-disable-next-line vue/prop-name-casing
    is_email_enabled: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/prop-name-casing
    push_notification_title: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/prop-name-casing
    push_notification_body: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/prop-name-casing
    is_reminder_enabled: {
      type: Number,
      default: 0,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canEditOnlyItem: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPending: false,
      thumbnail: null,
      thumbnailErrorField: false,
      tableIsLoading: false,
      title: '',
      url: '',
      urlValid: false,
      updateInfo: false,
      dataHolder: null,
      toastHolder: null,
      videoList: [],
      customer_id: this.$store.state.auth.customerId,
    };
  },
  components: {
    'vue-table': Vuetable,
    'icon-container': IconContainer,
    'icon-trash': IconTrash,
  },
  computed: {
    modalData() {
      return this.$store.state.modal.modalData;
    },
    modalError() {
      return this.$store.state.modal.formError;
    },
    fields() {
      return [
        {
          name: 'title',
          title: this.$t('title'),
          width: '60%',
        },
        {
          name: 'duration',
          title: this.$t('duration'),
          width: '15%',
        },
        {
          name: 'video_id',
          title: this.$t('video_id'),
          width: '25%',
        },
        {
          name: 'actions',
          title: this.$t('actions'),
          width: '5%',
        },
      ];
    },
  },

  watch: {
    modalError: {
      handler(newVal) {
        if (newVal) {
          this.isPending = false;
        }
      },
      immediate: true,
    },

    externalUrl: {
      handler(newVal) {
        if (this.editMode) {
          this.url = newVal;
          this.urlValid = true;
        }
      },
      immediate: true,
    },

    url: {
      handler(newVal) {
        if (!newVal && this.editMode === false) {
          this.urlValid = false;
          this.title = '';
        }
      },
      immediate: true,
    },

    name: {
      handler(newVal) {
        this.title = newVal;
      },
      immediate: true,
    },

    title: {
      handler(newVal) {
        this.$emit('setTitle', newVal);
      },
      immediate: true,
    },

    updateInfo: {
      handler(newVal) {
        this.$parent.updateInfo = newVal;
      },
      immediate: true,
    },
  },

  methods: {
    onAction(action, data) {
      if (action === 'delete-item') {
        this.deleteVideos(data);
      }
    },
    deleteVideos(data) {
      const index = this.videoList.findIndex(element => element.video_id === data.video_id);
      if (index > -1) {
        this.videoList.splice(index, 1);
      }
    },
    async handleUrl(value) {
      const url = value.target.value;
      this.tableIsLoading = true;
      this.isPending = true;
      if (url) {
        await this.$validator.validate('externalYoutubelink.url').then((res) => {
          if (res) {
            if (this.$helpers.isURLWhiteListed(url)) {
              if (this.$refs.urlInput) {
                this.$refs.urlInput.classList.add('loading-icon');
              }
              if (this.$helpers.isYouTubeUrl(url)) {
                this.$helpers.getYouTubeVideos(url).then(async (data) => {
                  if (data && data.length) {
                    this.videoList = data;
                    console.log('videoList yuklendi');
                    this.tableIsLoading = false;
                    try {
                      for (let i = 0; i < this.videoList.length; i += 10) {
                        // eslint-disable-next-line no-await-in-loop
                        await new Promise(async (resolve) => {
                          let a = this.videoList.slice(i, 10 + i);
                          a = a.map(b => b.video_id).join(',');
                          await this.$helpers.getYouTubeInfoByID(a).then(async (ydata) => {
                            if (ydata && ydata.items) {
                              ydata.items.forEach(async (item) => {
                                const promises = this.videoList.map((vid) => {
                                  const $vid = vid;
                                  if ($vid.video_id === item.id) {
                                    $vid.snippet = item.snippet;
                                    $vid.contentDetails = item.contentDetails;
                                    $vid.statistics = item.statistics;
                                  }
                                  return $vid;
                                });
                                await Promise.all(promises);
                              });
                            }
                          });
                          resolve();
                        });
                      }
                      this.isPending = false;
                    } catch (error) {
                      console.log(error);
                      this.displayError(error);
                    }
                  }
                  this.urlValid = true;
                  if (this.$refs.urlInput) {
                    this.$refs.urlInput.classList.remove('loading-icon');
                  }
                }).catch((error) => {
                  this.$helpers.displayError(error);
                  this.urlValid = true;
                  if (this.$refs.urlInput) {
                    this.$refs.urlInput.classList.remove('loading-icon');
                  }
                });
              }
            } else {
            // alert only allowed urls
              const allowedURLS = WHITELIST_URLS.join(', ');
              this.$validator.errors.add({
                field: 'url',
                msg: this.$t('allowed_urls', { urls: allowedURLS }),
                scope: 'externalYoutubelink',
              });
            }
          } else {
            this.isPending = false;
          }
        });
      }
    },

    async checkValidation() {
      let externalIsValid = false;

      await this.$validator.validate('externalYoutubelink.url').then((res) => {
        externalIsValid = res;
      });

      if (externalIsValid) {
        return true;
      }

      return false;
    },

    createContent() {
      this.checkValidation().then(async (res) => {
        if (res) {
          this.isPending = true;
          const that = this;
          const payload = {
            customer_id: this.customer_id,
            thumbnail_filename: this.thumbnail_filename,
            aoptions: this.aoptions,
            contentLanguage: this.contentLanguage,
            is_public: this.is_public,
            is_notifications_enabled: this.is_notifications_enabled,
            is_email_enabled: this.is_email_enabled,
            push_notification_title: this.push_notification_title,
            push_notification_body: this.push_notification_body,
            is_reminder_enabled: this.is_reminder_enabled,
          };
          const videoList = [...this.videoList];
          const manager = ConcurrencyManager(Vue.prototype.$API, 1);

          // eslint-disable-next-line no-unused-vars
          await Promise.all(videoList.map(async (i, index) => {
            const title = i.snippet?.title.split('|')[0].trim();
            const desc = i.snippet?.description.split('\n\n')[0].trim();
            let trimmedDesc = '';
            if (desc) {
              trimmedDesc = desc.length > 900
                ? `${desc.substring(0, 900)}...`
                : desc;
            }
            // const cduration = i.contentDetails.duration || null;
            // let durationFormatted = '';
            // if (cduration) {
            //   durationFormatted = await this.youtubeDurationConvert(cduration);
            // }

            let keywords = i.snippet?.tags || i.snippet?.keywords || [];
            if (keywords) {
              if (typeof keywords === 'object') {
                keywords = Object.keys(keywords).map(key => (keywords[key].toLowerCase()));
              } else if (typeof keywords === 'string') {
                keywords = keywords.split(',').map(key => (key.toLowerCase()));
              }
              keywords = [...new Set(keywords)];
            }
            let thumbnail = null;
            if (i.snippet.thumbnails.high.url) {
              const xblob = await fetch(`${CORS_PROXY_URL}${i.snippet.thumbnails.high.url}`).then(r => r.blob()).catch(() => null);
              if (xblob) {
                thumbnail = new File([xblob], 'thumbnail.jpg');
              }
            }

            payload.name = title;
            payload.desc = trimmedDesc;
            payload.info = this.$t('watches', { num: this.$helpers.abbreviateNumber(i.statistics.viewCount) });
            payload.duration = ''; // durationFormatted;
            payload.tags = keywords;
            payload.thumbnail = thumbnail || '';
            payload.externalUrl = `https://www.youtube.com/embed/${i.video_id}`;
            // console.log(keywords);
            await this.$store.dispatch('modal/CREATE_EXTERNAL_YOUTBE_LINK_CONTENT', payload).then(() => {
              console.log(`${index} video done`);
              that.videoList.splice(index, 1);
            });
          })).then(() => {
            that.isPending = false;
            manager.detach();
            if (that.videoList.length === 0) {
              that.$store.commit('modal/toggleModal', false);
              that.$store.commit('modal/REFRESH_PAGE', true);
            }
          });
        }
      });
    },

    deleteConfirm(toast, data) {
      this.updateInfo = true;
      this.title = data.title || data['og:title'];
      const desc = data.description || data['og:description'];
      this.handleDesc(desc);
      let keywords = data.keywords || data['article:tag'] || data['og:article:tag'] || data.tags;
      if (keywords) {
        if (typeof keywords === 'object') {
          keywords = Object.keys(keywords).map(key => ({ text: keywords[key] }));
        } else if (typeof keywords === 'string') {
          keywords = keywords.split(',').map(k => ({ text: k }));
        }
        this.changeTag(keywords);
      }
      this.$snotify.remove(toast.id);
    },

    deleteCancel(toast) {
      this.$snotify.remove(toast.id);
    },

    eventHandler(event) {
      event.preventDefault();
      const key = event.key || event.keyCode;
      if (key === 'Enter' || key === 13) {
        this.deleteConfirm(this.toastHolder, this.dataHolder);
      } else if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.deleteCancel(this.toastHolder);
      }
    },
  },
};
</script>
<style scoped>
.form-control.is-danger {
  border-color: #f27299;
}
.table-responsive div {
  max-height: 250px;
  overflow-y: auto;
}
</style>
