var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "single-choice",
      class: { selected: _vm.isSelected },
      attrs: { for: _vm.id, disabled: _vm.isReadonly },
    },
    [
      _c("input", {
        attrs: {
          type: _vm.isMultiple ? "checkbox" : "radio",
          id: _vm.id,
          disabled: _vm.isReadonly,
          name: `radio-input-${_vm.qId}`,
        },
        domProps: { value: _vm.option, checked: _vm.isSelected ? true : false },
        on: { change: _vm.changed },
      }),
      _vm._v(" " + _vm._s(_vm.option) + " "),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }