var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page mb-4 pb-4" },
    [
      _c(
        "app-header",
        {
          attrs: {
            "child-header-class": "container",
            "child-header-navigation": _vm.customerSubNavigation,
            "is-child-header-left-actions": "",
          },
        },
        [
          _c("div", { staticClass: "button-group" }, [
            _c("div", { staticClass: "button-group__item dropdown" }, [
              _c(
                "label",
                {
                  staticClass:
                    "button button--shadow button--type-icon size-sm d-flex align-items-center",
                  on: {
                    click: function ($event) {
                      return _vm.dropdownAction("showFilters")
                    },
                  },
                },
                [
                  _c(
                    "icon-container",
                    {
                      attrs: {
                        name: "search",
                        width: 14,
                        height: 14,
                        color: "#727d92",
                        "view-box": "0 0 14 14",
                        "is-icon-class": false,
                      },
                    },
                    [_c("icon-search")],
                    1
                  ),
                ],
                1
              ),
              _vm.isDropdown === "showFilters"
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "on-clickaway",
                          rawName: "v-on-clickaway",
                          value: _vm.hideDropdown,
                          expression: "hideDropdown",
                        },
                      ],
                      staticClass: "dropdown__menu dropdown__menu--filter",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group form-group--filter" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filterText,
                                expression: "filterText",
                              },
                              { name: "sanitize", rawName: "v-sanitize" },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("filter_by_name"),
                            },
                            domProps: { value: _vm.filterText },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.doFilter.apply(null, arguments)
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.filterText = $event.target.value
                              },
                            },
                          }),
                          _vm.filterText
                            ? _c(
                                "button",
                                {
                                  staticClass: "icon button-reset",
                                  on: { click: _vm.resetFilter },
                                },
                                [
                                  _c(
                                    "icon-container",
                                    {
                                      attrs: {
                                        name: "reset",
                                        "view-box": "0 0 20 20",
                                      },
                                    },
                                    [_c("icon-reset")],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "button-group__item dropdown" }, [
              _c(
                "button",
                {
                  staticClass: "button button--shadow button__dropdown size-sm",
                  class: { active: _vm.isDropdown === "perPageDropdown" },
                  on: {
                    click: function ($event) {
                      return _vm.dropdownShow("perPageDropdown")
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("view")) +
                      " " +
                      _vm._s(_vm.perPage) +
                      " "
                  ),
                  _c(
                    "icon-container",
                    {
                      attrs: {
                        name: "down",
                        "view-box": "0 0 11 8",
                        "is-icon-class": false,
                      },
                    },
                    [_c("icon-down")],
                    1
                  ),
                ],
                1
              ),
              _vm.isDropdown === "perPageDropdown"
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "on-clickaway",
                          rawName: "v-on-clickaway",
                          value: _vm.hideDropdown,
                          expression: "hideDropdown",
                        },
                      ],
                      staticClass: "dropdown__menu dropdown__menu--per-page",
                    },
                    _vm._l(_vm.pageOptions, function (option) {
                      return _c(
                        "div",
                        {
                          key: option.value,
                          staticClass: "dropdown__menu-item",
                          on: {
                            click: function ($event) {
                              return _vm.pageOptionsSelected(option.value)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(option.text) + " ")]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "'col-6 d-flex align-content-center'" }, [
          _vm.tableIsLoading
            ? _c("div", { staticClass: "lds-dual-ring" })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "col-7 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "max-width": "200px" } },
                    [
                      _c("label", { staticClass: "form-label text-center" }, [
                        _vm._v(" " + _vm._s(_vm.$t("select_dates")) + " "),
                      ]),
                      _c("date-picker", {
                        attrs: {
                          type:
                            _vm.analyzeType.name === "daily" ? "date" : "month",
                          "value-type": "YYYY-MM-DD",
                          format:
                            _vm.analyzeType.name === "daily"
                              ? _vm.userLang === "en"
                                ? "MM-DD-YYYY"
                                : "DD-MM-YYYY"
                              : "MMMM YYYY",
                          lang: _vm.userLang,
                        },
                        model: {
                          value: _vm.impressionDate,
                          callback: function ($$v) {
                            _vm.impressionDate = $$v
                          },
                          expression: "impressionDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("vue-table", {
                ref: "myvuetable",
                class: { "table-fade": _vm.tableIsLoading },
                attrs: {
                  "api-url": _vm.apiUrl,
                  "api-mode": true,
                  "http-fetch": _vm.myFetch,
                  fields: _vm.fields,
                  "no-data-template": _vm.$t("no_data_info"),
                  "per-page": _vm.perPage,
                  css: _vm.css.table,
                  "pagination-path": "meta",
                  "http-method": "post",
                  "http-options": {
                    headers: {
                      Authorization: `Bearer ${_vm.$store.state.auth.token}`,
                    },
                  },
                  "append-params": _vm.moreParams,
                  "query-params": _vm.makeQueryParams,
                },
                on: { "vuetable:pagination-data": _vm.onPaginationData },
                scopedSlots: _vm._u([
                  {
                    key: "active_user_ratio",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("rate_info", {
                                rate: _vm.activeUserRatio(props.rowData),
                              })
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "download_ratio",
                    fn: function (props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("rate_info", {
                                rate: _vm.appDownloadRatio(props.rowData),
                              })
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "actions",
                    fn: function (props) {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "ui button",
                            on: {
                              click: function ($event) {
                                return _vm.onAction(
                                  "customer-detail",
                                  props.rowData
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "icon-container",
                              {
                                attrs: {
                                  name: "search",
                                  width: 14,
                                  height: 14,
                                  color: "#727d92",
                                  "view-box": "0 0 14 14",
                                  "is-icon-class": false,
                                },
                              },
                              [_c("icon-search")],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "pagination__info" },
                [
                  _c("vuetable-pagination-info", {
                    ref: "paginationInfo",
                    class: { "table-fade": _vm.tableIsLoading },
                    attrs: {
                      "info-template": _vm.$t("pagination_info"),
                      "no-data-template": _vm.$t("no_data_info"),
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "pagination__items" },
                    [
                      _c("vuetable-pagination", {
                        ref: "customerpagination",
                        attrs: { css: _vm.css.pagination },
                        on: {
                          "vuetable-pagination:change-page": _vm.onChangePage,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }